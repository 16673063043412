import React from 'react';

import { type Theme } from '@mui/material';
import { format as formatDate, parseJSON } from 'date-fns';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { EMRA2SLUG, EMRATYPES2TYPEVERBOSE } from '@ivy/constants/emra';
import { type EmraProgramFeatures_TrainingSurveyFragment } from '@ivy/gql/types/graphql';
import { buildInternalLink } from '@ivy/lib/util/route';

import EmrafiedLogo from './EmrafiedLogo';

const typeChecker = (
	value: boolean | string | number | string[] | null | undefined,
	inType: string | string[],
) => {
	if (typeof value === 'string') {
		if (Array.isArray(inType)) {
			return inType.includes(value.toLowerCase());
		}
		return value.toLowerCase() === inType;
	}
	return false;
};

const checkNullUndefined = (value) => value != null || null;
const formatYN = (value) => (value ? 'Yes' : 'No');

export interface ProgramSectionItem {
	label: string;
	tooltipContent?: string;
	getValue: (
		survey: EmraProgramFeatures_TrainingSurveyFragment,
	) => boolean | string | string[] | number | undefined | null;
	isFeatured?: (
		val: boolean | string | string[] | number | undefined | null,
	) => boolean | null;
	shouldShow?: (
		val: boolean | string | string[] | number | undefined | null,
	) => boolean | null;
	formatter?: (
		val: boolean | string | string[] | number | undefined | null,
		program: EmraProgramFeatures_TrainingSurveyFragment,
	) => React.ReactNode;
	notFeaturedText?: string;
}

export enum FullFields {
	CLINICAL_EXPERIENCE = 'Clinical Experience',
	RESEARCH_OR_PROJECT_REQUIREMENTS = 'Research/Project Requirements',
	ADDITIONAL_INFORMATION = 'Additional Information',
	EMRAFIED = 'Emrafied',
}

export interface ProgramSectionConfig {
	section: string;
	items: ProgramSectionItem[];
	display?: (value: string) => boolean | null;
	tooltipContent?: string;
	view?: (theme: Theme) => string;
	fullFeature?: boolean | null;
}

export const getEmraFields = (type): ProgramSectionConfig[] => [
	{
		section: `${EMRATYPES2TYPEVERBOSE[type]} Overview`,
		items: [
			{
				label: 'Specialties',
				getValue: (program) => program.residencySurvey?.specialty.join(', '),
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Program Length',
				getValue: (program) => program.residencySurvey?.programLength,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: (value) => (value ? `${value} year(s)` : null),
			},
			{
				label: 'Visa Sponsorship',
				tooltipContent: 'What Visas are sponsored?',
				getValue: (program) => program.residencySurvey?.visaSponsorship,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Primary Training site',
				tooltipContent:
					'Which of these options best describes the type of hospital where the program is based?',
				getValue: (program) => program.residencySurvey?.primaryTrainingSite,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Secondary Training site',
				tooltipContent:
					'Does the program have an additional site where residents do more than 25% of their shifts?',
				getValue: (program) => program.residencySurvey?.secondaryTrainingSite,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Military Branch',
				tooltipContent: 'Does the program participate in the Military match?',
				getValue: (program) => program.residencySurvey?.militaryBranch,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'NRMP Code',
				getValue: (program) => program.residencySurvey?.nrmpId,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'ACGME ID',
				getValue: (program) => program.residencySurvey?.acgmeId,
				shouldShow: (value) => typeChecker(value, [EMRA2SLUG.RESIDENCY]),
			},
			{
				label: 'EMRAfied',
				tooltipContent: 'Does the program pay for EMRA dues?',
				getValue: (program) => program.residencySurvey?.residency?.emrafied,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: formatYN,
			},

			// CLERKSHIP
			{
				label: 'Rotation Types',
				tooltipContent: 'What are the rotation types?',
				getValue: (program) =>
					program.clerkshipSurvey?.rotationTypes.join(', '),
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
			},
			{
				label: 'DO Students',
				tooltipContent: 'Are D.O. students considered?',
				getValue: (program) => program.clerkshipSurvey?.doStudents,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				formatter: formatYN,
			},
			{
				label: 'IMG Students',
				tooltipContent: 'Are IMG students considered?',
				getValue: (program) => program.clerkshipSurvey?.imgStudents,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				formatter: formatYN,
			},
			{
				label: 'Participates in VSLO',
				tooltipContent:
					'Does the primary adult EM/career track clerkship participate in VSLO?',
				getValue: (program) => program.clerkshipSurvey?.vsloParticipant,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				formatter: formatYN,
			},
			{
				label: "Author of Student's SLOE",
				tooltipContent: "Who writes student's SLOE?",
				getValue: (program) => program.clerkshipSurvey?.authStudentSloe,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
			},

			// Fellowship
			{
				label: 'Subspecialty',
				getValue: (program) => program.fellowshipSurvey?.subspecialty,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
			{
				label: 'Program Length',
				tooltipContent: 'How many years is the fellowship program? (1 - 3)',
				isFeatured: checkNullUndefined,
				getValue: (program) => program.fellowshipSurvey?.programLength,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
				formatter: (value) => (value ? `${value} year(s)` : null),
			},
			{
				label: 'Positions per year',
				tooltipContent:
					'How many fellowship program positions are available per year?',
				getValue: (program) => program.fellowshipSurvey?.positionsPerYear,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
			{
				label: 'Advanced Degrees',
				tooltipContent:
					'What, if any, advanced degrees are available to fellows as part of their training? (select all options that may be available)',
				getValue: (program) =>
					program.fellowshipSurvey?.advancedDegrees.join(', '),
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
			{
				label: 'Degree Granting Institution',
				getValue: (program) =>
					program.fellowshipSurvey?.degreeGrantingInstitution,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},

			{
				label: 'Residency Program',
				getValue: (program) =>
					program.clerkshipSurvey?.residency?.id
						? [
								program.clerkshipSurvey?.residency?.id,
								program.clerkshipSurvey?.residency?.training.slug,
						  ]
						: undefined || program.fellowshipSurvey?.residency?.id
						? [
								program.fellowshipSurvey?.residency?.id,
								program.fellowshipSurvey?.residency?.training.slug,
						  ]
						: undefined,
				shouldShow: (value) =>
					typeChecker(value, [EMRA2SLUG.CLERKSHIP, EMRA2SLUG.FELLOWSHIP]),
				isFeatured: checkNullUndefined,
				formatter: (value, program) =>
					value ? (
						<RouteLink
							to={buildInternalLink(RouteLink.routes.EMRA_RESIDENCY_SHOW, {
								trainingId: value as string,
							})}
							color='light3.main'
							state={{
								backNav: {
									target: 'program',
								},
							}}
						>
							{program.clerkshipSurvey?.residency?.training.name ||
								program.fellowshipSurvey?.residency?.training.name}
						</RouteLink>
					) : null,
			},
			{
				label: 'Director',
				getValue: (program) => program.directorName,
				shouldShow: (value) =>
					typeChecker(value, [
						EMRA2SLUG.RESIDENCY,
						EMRA2SLUG.CLERKSHIP,
						EMRA2SLUG.FELLOWSHIP,
					]),
			},
			{
				label: 'Coordinator',
				getValue: (program) =>
					program.coordinatorEmail && program.coordinatorName
						? JSON.stringify({
								email: program.coordinatorEmail,
								name: program.coordinatorName,
						  })
						: undefined,
				isFeatured: checkNullUndefined,
				shouldShow: (value) =>
					typeChecker(value, [
						EMRA2SLUG.RESIDENCY,
						EMRA2SLUG.CLERKSHIP,
						EMRA2SLUG.FELLOWSHIP,
					]),
				formatter: (value) => {
					if (!value) return null;
					const val = JSON.parse(value as string);
					return (
						<RouteLink
							to={`mailto:${val.email}`}
							openInNewTab
							color='light3.main'
						>
							{val.name}
						</RouteLink>
					);
				},
			},
			{
				label: 'Last Modified',
				tooltipContent:
					'Date of the last time the programs information was updated.',
				getValue: (program) => program.createdAt,
				shouldShow: (value) =>
					typeChecker(value, [
						EMRA2SLUG.RESIDENCY,
						EMRA2SLUG.CLERKSHIP,
						EMRA2SLUG.FELLOWSHIP,
					]),
				formatter: (value) =>
					typeof value === 'string'
						? formatDate(parseJSON(value), 'M/d/yyyy')
						: null,
			},
		],
	},
	{
		section: 'Training & Schedule',
		display: (value) =>
			typeChecker(value, [EMRA2SLUG.RESIDENCY, EMRA2SLUG.FELLOWSHIP]),
		items: [
			{
				label: 'Shift Length (Hours)',
				tooltipContent:
					'What is the most common shift length for which residents are scheduled?',
				getValue: (program) => program.residencySurvey?.shiftLength,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Shifts Per Month',
				tooltipContent:
					'How many shifts do PGY1 residents do per four week block?',
				getValue: (program) =>
					program.residencySurvey?.shiftsPerMonth ||
					program.fellowshipSurvey?.shiftsPerMonth,
				shouldShow: (value) =>
					typeChecker(value, [EMRA2SLUG.RESIDENCY, EMRA2SLUG.FELLOWSHIP]),
			},
			{
				label: 'Moonlighting',
				tooltipContent:
					'Does the program allow moonlighting at any point during residency training?',
				getValue: (program) => program.residencySurvey?.moonlighting,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: formatYN,
			},
			{
				label: 'PGY1 % Off Service',
				tooltipContent:
					'What is the off-service percentage for PGY1 residents?',
				getValue: (program) => program.residencySurvey?.pgy1PercentOffService,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: (value) => (value ? `${value}%` : null),
			},
			{
				label: 'Elective Weeks',
				tooltipContent:
					'How many weeks of elective time do residents have during training?',
				getValue: (program) => program.residencySurvey?.electiveWeeks,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Critical Care Weeks',
				tooltipContent:
					'How many weeks of designated critical care do residents experience during their training?',
				getValue: (program) => program.residencySurvey?.criticalCareWeeks,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},

			// Fellowship
			{
				label: 'Moonlighting Allowed',
				tooltipContent: 'Is moonlighting allowed?',
				getValue: (program) => program.fellowshipSurvey?.moonlightingAllowed,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
			{
				label: 'Compensation',
				tooltipContent: 'What is the annual compensation provided?',
				getValue: (program) => program.fellowshipSurvey?.compensation,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
		],
	},
	{
		section: 'Applications & Interviews',
		display: (value) =>
			typeChecker(value, [EMRA2SLUG.RESIDENCY, EMRA2SLUG.CLERKSHIP]),
		items: [
			{
				label: 'Number of Applications Received',
				tooltipContent: 'How many applications have been received?',
				getValue: (program) => program.residencySurvey?.applicationsReceived,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Available Intern Positions',
				tooltipContent:
					'All residency training positions in EM are exclusively in the match. This number can change from year to year.',
				getValue: (program) =>
					program.residencySurvey?.availableInternPositions,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'Step 1 Failures',
				tooltipContent:
					'Has an interview been granted to an applicant who failed USMLE STEP 1 or COMLEX LEVEL 1 in the last three years?',
				getValue: (program) => program.residencySurvey?.step1Failures,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: formatYN,
			},
			{
				label: 'Step 1 Cutoff',
				tooltipContent:
					'What is the minimum USMLE Step 2 CK score needed to be granted an interview?',
				getValue: (program) => program.residencySurvey?.step1Cutoff,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'USMLE/COMLEX 1',
				tooltipContent: 'What is the USMLE/COMLEX 1 preference?',
				getValue: (program) => program.residencySurvey?.usmleComlex1Preference,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'USMLE/COMLEX 2',
				tooltipContent: 'What is the USMLE/COMLEX 2 preference?',
				getValue: (program) => program.residencySurvey?.usmleComlex2Preference,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'SLOEs for Interview',
				tooltipContent: 'How many SLOEs are required for an interview offer?',
				getValue: (program) => program.residencySurvey?.sloesForInterview,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: 'In-Person Second Looks',
				tooltipContent: 'Will there be in-person second looks?',
				getValue: (program) => program.residencySurvey?.inPersonSecondLooks,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: formatYN,
			},
			{
				label: 'CORD’s APIC Interview',
				tooltipContent:
					'Are the CORD APIC interview season recommendations being followed?',
				getValue: (program) => program.residencySurvey?.cordApicInterview,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
				formatter: formatYN,
			},
			{
				label: '% DO',
				tooltipContent:
					'What percentage of current residents completed medical school at an Osteopathic Medical School? OR What percentage of current residents attended an Osteopathic Medical School?',
				getValue: (program) => program.residencySurvey?.percentOsteopath,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},
			{
				label: '% IMG',
				tooltipContent:
					'What percentage of current residents completed medical school outside of the United States, not including spots funded by outside sources?',
				getValue: (program) => program.residencySurvey?.percentImg,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.RESIDENCY),
			},

			// Clerkship
			{
				label: 'Interview Policy',
				tooltipContent: 'What is the interview policy?',
				getValue: (program) => program.clerkshipSurvey?.interviewPolicy,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
			},
			{
				label: 'USMLE / COMLEX',
				tooltipContent:
					'Is USMLE Step 1 or COMLEX scores required prior to extending a clerkship invitation?',
				getValue: (program) => program.clerkshipSurvey?.comlexRequired,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
			},
			{
				label: 'Housing Availability',
				tooltipContent: 'Is there an option for hospital provided housing?',
				getValue: (program) => program.clerkshipSurvey?.housing,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				formatter: formatYN,
			},
			{
				label: 'Vehicle Recommended',
				tooltipContent:
					'Is it recommend that students have a car during their rotation?',
				getValue: (program) => program.clerkshipSurvey?.vehicleRec,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
			},
			{
				label: 'Flexible Rotation Dates',
				tooltipContent: 'Are rotation dates flexible?',
				getValue: (program) => program.clerkshipSurvey?.flexibleRotation,
				isFeatured: checkNullUndefined,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				formatter: formatYN,
			},
		],
	},
	{
		section: 'Spot Availability',
		tooltipContent: 'Spot Availability Indicator',
		display: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
		items: [
			{
				label: 'January',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability1,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'February',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability2,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'March',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability3,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'April',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability4,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'May',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability5,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'June',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability6,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'July',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability7,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'August',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability8,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'September',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability9,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'October',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability10,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'November',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability11,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
			{
				label: 'December',
				getValue: (program) => program.clerkshipSurvey?.spotAvailability12,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.CLERKSHIP),
				isFeatured: checkNullUndefined,
				formatter: formatYN,
				notFeaturedText: 'Contact Program',
			},
		],
	},
	{
		section: '',
		display: (value) => typeChecker(value, [EMRA2SLUG.RESIDENCY]),
		fullFeature: true,
		view: (theme) => theme.breakpoints.down('lg'),
		items: [
			{
				label: '',
				getValue: (program) => program.residencySurvey?.residency?.emrafied,
				shouldShow: (value) => typeChecker(value, [EMRA2SLUG.RESIDENCY]),
				formatter: (value) => (value ? <EmrafiedLogo /> : null),
			},
		],
	},
	{
		section: FullFields.ADDITIONAL_INFORMATION,
		display: (value) =>
			typeChecker(value, [
				EMRA2SLUG.FELLOWSHIP,
				EMRA2SLUG.CLERKSHIP,
				EMRA2SLUG.RESIDENCY,
			]),
		fullFeature: true,
		items: [
			{
				label: FullFields.ADDITIONAL_INFORMATION,
				getValue: (program) => program.description,
				shouldShow: (value) =>
					typeChecker(value, [
						EMRA2SLUG.FELLOWSHIP,
						EMRA2SLUG.CLERKSHIP,
						EMRA2SLUG.RESIDENCY,
					]),
			},
		],
	},
	{
		section: FullFields.CLINICAL_EXPERIENCE,
		display: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
		tooltipContent:
			'Briefly describe the clinical experience in terms of the hospitals/sites/departments where fellows will work, as well as their opportunity to supervise other learners, non-physician providers, or other health care professionals.',
		fullFeature: true,
		items: [
			{
				label: FullFields.CLINICAL_EXPERIENCE,
				getValue: (program) => program.fellowshipSurvey?.clinicalExp,
				shouldShow: (value) => typeChecker(value, EMRA2SLUG.FELLOWSHIP),
			},
		],
	},
	{
		section: FullFields.RESEARCH_OR_PROJECT_REQUIREMENTS,
		display: (value) => typeChecker(value, [EMRA2SLUG.FELLOWSHIP]),
		fullFeature: true,
		tooltipContent:
			'Does this fellowship have any research or capstone project requirements? If so, please describe.',
		items: [
			{
				label: FullFields.RESEARCH_OR_PROJECT_REQUIREMENTS,
				getValue: (program) => program.fellowshipSurvey?.researchProject,
				shouldShow: (value) => typeChecker(value, [EMRA2SLUG.FELLOWSHIP]),
			},
		],
	},
];
