import { createContext } from 'react';

import { type ApolloError } from '@apollo/client';

export interface AuthContextValue {
	initialized: boolean;
	loading: boolean;
	error?: ApolloError;
}

const AuthContext = createContext<AuthContextValue>(undefined!);

export default AuthContext;
