import React from 'react';

import { SvgIcon } from '@mui/material';

const FeatureIncludedCheckIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle cx='8' cy='8' r='8' fill='currentColor' fillOpacity='0.2' />
			<path
				d='M6.7998 10.4068L5.00884 8.61581C4.80755 8.41452 4.48238 8.41452 4.28109 8.61581C4.0798 8.8171 4.0798 9.14227 4.28109 9.34356L6.43851 11.501C6.6398 11.7023 6.96497 11.7023 7.16626 11.501L12.6269 6.04033C12.8282 5.83904 12.8282 5.51388 12.6269 5.31259C12.4256 5.1113 12.1004 5.1113 11.8992 5.31259L6.7998 10.4068Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FeatureIncludedCheckIcon;
