import React, { useMemo } from 'react';

import { useQuery, useMutation } from '@apollo/client';

import { AuthBoundary, withBoundary } from '@ivy/components/boundaries';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

import { dataResolver } from './apiHelpers';
import FavoritesList from './FavoritesList';

const FavoritesShow_QueryFavoritesQDoc = gql(/* GraphQL */ `
	query FavoritesShow_QueryFavorites($accountId: uuid!) {
		favorite(
			where: {
				_and: {
					account_id: { _eq: $accountId }
					_or: [
						{
							_and: [
								{
									type: { _eq: "RESIDENCY" }
									residency: { training: { active: { _eq: true } } }
								}
							]
						}
						{
							_and: [
								{
									type: { _eq: "FELLOWSHIP" }
									fellowship: { training: { active: { _eq: true } } }
								}
							]
						}
						{
							_and: [
								{
									type: { _eq: "CLERKSHIP" }
									clerkship: { training: { active: { _eq: true } } }
								}
							]
						}
					]
				}
			}
		) {
			id
			type
			...FavoritesShow_Favorite
		}
	}
`);

const FavoritesShow_DeleteFavoriteMDoc = gql(/* GraphQL */ `
	mutation FavoritesShow_DeleteFavorite($id: uuid!) {
		delete_favorite_by_pk(id: $id) {
			id
		}
	}
`);

const FavoritesShow = () => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(FavoritesShow_QueryFavoritesQDoc, {
		variables: {
			accountId: currAcc?.id,
		},
		fetchPolicy: 'network-only',
	});

	const [deleteFav] = useMutation(FavoritesShow_DeleteFavoriteMDoc, {
		refetchQueries: [
			'FavoritesShow_QueryFavorites',
			'EmraProgramShow_Training',
		],
		awaitRefetchQueries: true,
	});

	const rawData = useMemo(() => {
		return data ? dataResolver(data.favorite) : [];
	}, [data]);

	const handleClickCard = (favId: string) => {
		deleteFav({
			variables: {
				id: favId,
			},
		});
	};

	return (
		<NavTemplate
			pageTitle='Favorites'
			pageNoIndex
			showFooter
			showSupportLauncher
			DataLoaderProps={{
				data,
				loading: loading,
				error,
				keys: ['favorite'],
				variant: 'logo',
			}}
		>
			<FavoritesList
				data={rawData}
				onClick={handleClickCard}
				sx={{ mt: 3, mb: 8 }}
			/>
		</NavTemplate>
	);
};

export default withBoundary(FavoritesShow, AuthBoundary);
