import React from 'react';

import { Box, useTheme } from '@mui/material';

const BlobIconWrapper = ({ icon, sx, ...props }) => {
	const theme = useTheme();

	return (
		<Box
			sx={[
				{
					position: 'relative',
				},
				sx,
			]}
			{...props}
		>
			<svg
				width='44'
				height='46'
				viewBox='0 0 44 46'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					opacity='0.3'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M43.6242 20.0976C44.1248 26.41 40.3738 31.3469 36.9154 35.8774C34.0758 39.5973 30.3372 41.6938 26.1243 43.0224C21.2508 44.5594 16.1091 46.6992 11.336 43.9445C6.04425 40.8905 1.93426 34.9048 0.657472 28.3248C-0.601589 21.8362 1.50521 15.4545 4.84686 10.5025C7.89821 5.98071 12.8071 4.43521 17.7144 3.04802C23.2025 1.49665 29.0535 -1.2232 34.2442 2.19252C39.6828 5.77129 43.068 13.0844 43.6242 20.0976Z'
					fill={theme.palette.secondary.main}
					fillOpacity='0.4'
				/>
			</svg>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: 1,
					color: theme.palette.text.icon,
				}}
			>
				{icon}
			</Box>
		</Box>
	);
};

export default BlobIconWrapper;
