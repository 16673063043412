import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import config from '@ivy/config';
import { logOut } from '@ivy/lib/storage/token';

import { getNewToken, isJwtError } from './jwt';

// https://github.com/hasura/graphql-engine/issues/4509
// https://github.com/hasura/graphql-engine/issues/472
const client = createClient({
	url: config.gqlUrl.replace('http://', 'ws://').replace('https://', 'wss://'),
	lazy: true,
	connectionParams: async () => {
		// When our auth expires, this function will be called again to establish a new connection
		try {
			// Will return null if no refresh token to begin with
			const accessToken = await getNewToken();
			if (accessToken) {
				return {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (isJwtError(e)) {
				// Refresh token is expired
				logOut();
			} else {
				console.error('Could not refresh access token', e);
				// We don't capture an exception here as we might have an infinite loop of capturing exceptions
				// as we continously retry, and that will use up our entire Sentry quota.
				// captureException(e);
				// This thrown error will cause another graphql-ws retry after the retryWait period
				throw e;
			}
		}
		// No Authorization header needed if calling the API anonymously
		return undefined;
	},
	// Ping the server every 10 seconds
	keepAlive: 10000,
	// Retry every websocket error.  This is NOT the same as GraphQL errors returned in a JSON response (e.g. a
	// Hasura error from trying to access a field as anonymous when it requires authenticated access).
	// We retry all events because when you switch apps from Mobile Safari, the websocket connection breaks and
	// doesn't restart upon opening back up the app. Same deal if the API restarts.
	// TODO: figure out the errors/events we want to retry on
	shouldRetry: () => true,
	// Retry indefinitely
	// Note that indefinite retries will prevent any websocket errors from populating up to `useSubscription().error`.
	// They usually only populate up there after all attempts have been exhausted.
	retryAttempts: Number.POSITIVE_INFINITY,
	// Note that, in the event of a closed connection because of access token expiration, we will still have to
	// undergo this retry wait
	retryWait: () =>
		new Promise((resolve) =>
			setTimeout(
				resolve,
				// Wait between 1-5 seconds
				1000 + Math.random() * 4000,
			),
		),
});

const wsLink = new GraphQLWsLink(client);

export default wsLink;
