import React, { useMemo, useState, useCallback, Suspense } from 'react';

import { useApolloClient } from '@apollo/client';
import { type CallBackProps, type Status } from 'react-joyride';

import DataLoader from '@ivy/components/molecules/DataLoader';
import {
	type Tours,
	ToursMap,
} from '@ivy/components/providers/TourProvider/Tours';
import { useCurrentAccount } from '@ivy/gql/hooks';
import lazyRetry from '@ivy/lib/util/lazyRetry';

import TourContext, { type TourState } from './TourContext';
import useMobileBreakPopup from './useMobileBreakPopup';

export interface TourProviderProps {
	children?: React.ReactNode;
}

const defaultTourState: TourState = {
	run: false,
	currTour: null,
};

const MasterTour = lazyRetry(() => import('./MainTour'));

const TourProvider = ({ children }: TourProviderProps) => {
	const client = useApolloClient();
	const currAcc = useCurrentAccount();
	const [tourStatus, setTourStatus] = useState<Status | null>(null);
	const [tourState, setTourState] = useState(defaultTourState);
	const [tourSignal, setTourSignal] = useState('');
	const { BreakPopup, popupProps, isOpen } = useMobileBreakPopup({
		enforced: Boolean(tourState.currTour),
	});

	const handleStartTour = useCallback((type: Tours) => {
		setTourState({
			run: true,
			currTour: ToursMap[type],
		});
	}, []);

	const handleSignalTour = useCallback((signal: string) => {
		setTourSignal(signal);
	}, []);

	const handleCallback = (data: CallBackProps) => {
		setTourStatus(data.status);
	};

	const handleClose = useCallback(
		(complete = false) => {
			setTourState(defaultTourState);
			if (complete) {
				tourState.currTour?.onComplete?.(client, { accountId: currAcc?.id });
			}
		},
		[tourState, client, currAcc],
	);

	const tourProviderValue = useMemo(() => {
		return {
			startTour: handleStartTour,
			stopTour: handleClose,
			tourStatus: tourStatus,
			signalTour: handleSignalTour,
		};
	}, [tourStatus, handleStartTour, handleSignalTour, handleClose]);

	const handlePopupClose = () => {
		popupProps.onClose?.();
		handleClose();
	};

	const handlePopupConfirm = () => {
		popupProps.onClose?.();
		if (tourState.currTour?.key) handleStartTour(tourState.currTour?.key);
	};

	return (
		<TourContext.Provider value={tourProviderValue}>
			{tourState.currTour && !isOpen && (
				<Suspense
					fallback={
						<DataLoader loading fullscreen variant='circular' transparent />
					}
				>
					<MasterTour
						run={tourState.run}
						callback={handleCallback}
						steps={tourState.currTour}
						signal={tourSignal}
						onClose={handleClose}
					/>
				</Suspense>
			)}
			{tourState.currTour && (
				<BreakPopup
					{...popupProps}
					onConfirm={handlePopupConfirm}
					onClose={handlePopupClose}
				/>
			)}
			{children}
		</TourContext.Provider>
	);
};

export default TourProvider;
