import React from 'react';

import { East } from '@mui/icons-material';
import { Box, Button, Stack, type SxProps, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

const BACKGROUND_IMG =
	'https://assets.ivyclinicians.io/images/emra-physician-surgery-background.jpeg';

interface ButtonLink {
	text: string;
	link: string;
}

const buttonArr: ButtonLink[] = [
	{
		text: 'RESIDENCY',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'residency',
			slug: 'search',
		}),
	},
	{
		text: 'CLERKSHIP',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'clerkship',
			slug: 'search',
		}),
	},
	{
		text: 'FELLOWSHIP',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'fellowship',
			slug: 'search',
		}),
	},
];

interface FindFutureProps {
	sx?: SxProps;
}

const FindFuture = ({ sx }: FindFutureProps) => {
	return (
		<Box component='section' sx={combineSx({ position: 'relative' }, sx)}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					top: '0',
					left: '50%',
					right: '50%',
					mx: '-50vw',
					height: '100%',
					zIndex: -1,
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						width: '140%',
						height: '100%',
						mx: '-20%',
						opacity: '0.7',
						filter: 'blur(11.869791984558105px)',
						backgroundSize: '145%',
						backgroundPosition: 'center 30%',
						backgroundImage: `url(${BACKGROUND_IMG})`,
					}}
				/>
			</Box>
			<Stack sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
				<Typography
					variant='h3'
					color='primary.contrastText'
					textAlign='center'
					sx={{ mb: 4, letterSpacing: '-0.8px' }}
				>
					Find Your Future in Emergency Medicine
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Stack
						spacing={3}
						direction={{ md: 'row', xs: 'column' }}
						sx={{ width: { md: 'auto', xs: '100%' } }}
					>
						{buttonArr.map((el) => (
							<Button
								key={el.text}
								component={RouteLink}
								to={el.link}
								variant='outlined'
								sx={{
									flex: 1,
									px: 7,
									py: 2,
									fontWeight: 'bold',
									lineHeight: '18px',
									letterSpacing: '1.95px',
									'&:hover': {
										backgroundColor: '#008CFF',
										borderColor: '#008CFF',
										color: '#fff',
									},
								}}
								color='whiteButton'
							>
								{el.text} <East sx={{ ml: 2 }} />
							</Button>
						))}
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default FindFuture;
