import { type UnpreparedIcon } from '@ivy/components/organisms/Map/common';

export const emraFeaturedIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-emra-featured.svg',
	size: {
		width: 30,
		height: 42,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 15,
		y: 42,
	},
} as UnpreparedIcon;

export const emraFeaturedShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-emra-featured-shadow.svg',
	size: {
		width: 50,
		height: 62,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 25,
		y: 55,
	},
} as UnpreparedIcon;

export const emraFeaturedClusterIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-emra-featured-cluster.svg',
	size: {
		width: 40,
		height: 40,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 20,
		y: 40,
	},
} as UnpreparedIcon;

export const emraFeaturedClusterShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-emra-featured-cluster-shadow.svg',
	size: {
		width: 60,
		height: 60,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 30,
		y: 50,
	},
} as UnpreparedIcon;
