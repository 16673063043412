import React from 'react';

import { Box, TextField, Stack, Divider, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import endOfToday from 'date-fns/endOfToday';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ReviewFormValues } from '../reviewForm';

import ButtonToggle from './Inputs/ButtonToggle';

interface StatusValues {
	isCurrentlyWorking: boolean | null;
	lastWorkedDate: Date | null;
}

interface StatusProps {
	formik: FormikProps<ReviewFormValues>;
}

const validation = yup.object({
	isCurrentlyWorking: yup
		.boolean()
		.nullable()
		.required('Please select your employment status.')
		.label('Currently working'),
	lastWorkedDate: yup
		.date()
		// In case of null or InvalidDate object
		.typeError('Must be a valid date')
		.min(new Date(1900, 0), 'Must be after 1900')
		.max(endOfToday(), 'Cannot be in the future')
		.when('isCurrentlyWorking', {
			is: (val: boolean) => !val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.nullable(),
		})
		.label('Last date worked'),
});

const initialValue = {
	isCurrentlyWorking: null,
	lastWorkedDate: null,
};

const Status = ({ formik }: StatusProps) => {
	const { values, isSubmitting, setFieldValue, setFieldTouched } = formik;
	const isNull = values.isCurrentlyWorking === null;
	const errorState = getErrorState(formik, 'isCurrentlyWorking');
	return (
		<Box>
			<Stack
				direction='column'
				sx={{ width: '100%', maxWidth: { sm: '450px', xs: 'auto' } }}
				spacing={3}
			>
				<Stack direction='column'>
					<ButtonToggle
						disabled={isSubmitting}
						value={isNull ? null : !values.isCurrentlyWorking}
						onText='I used to work here'
						offText='I currently work here'
						onClick={(val) => {
							setFieldValue('isCurrentlyWorking', !val, false);
							setFieldValue('lastWorkedDate', null, false);
							setFieldTouched('isCurrentlyWorking');
						}}
					/>
					{!!errorState.error && (
						<Typography
							variant='caption'
							color='error.main'
							sx={{ mx: 1.5, mt: 0.5 }}
						>
							{errorState.helperText}
						</Typography>
					)}
				</Stack>
				{!isNull && !values.isCurrentlyWorking && (
					<Stack
						direction='row'
						justifyContent='flex-end'
						sx={{ width: '100%' }}
					>
						<Divider orientation='vertical' flexItem sx={{ mr: 3 }} />
						<MobileDatePicker
							views={['month', 'year']}
							label='Last date worked'
							value={values.lastWorkedDate}
							onChange={(newVal) => setFieldValue('lastWorkedDate', newVal)}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									{...getErrorState(formik, 'lastWorkedDate')}
									sx={{ maxWidth: { sm: '200px', xs: '100%' } }}
									onBlur={() => setFieldTouched('lastWorkedDate')}
								/>
							)}
							disabled={isSubmitting || !!values.isCurrentlyWorking}
							minDate={new Date(1900, 0)}
							maxDate={endOfToday()}
						/>
					</Stack>
				)}
			</Stack>
		</Box>
	);
};

export default Status;
export { type StatusValues, type StatusProps, validation, initialValue };
