import React, { useCallback } from 'react';

import { Button, type ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { titlelize as formatTitle } from '@ivy/lib/formatting/string';
import { type WhitelabelTo } from '@ivy/lib/util/route';

export interface BackNavConfig {
	link?: WhitelabelTo | -1;
	includeSearchParams?: boolean;
	target?: string;
}

export interface BackNavButtonProps extends ButtonProps {
	backNav?: BackNavConfig;
	titlelize?: boolean;
}

const BackNavButton = ({
	backNav,
	titlelize = false,
	...props
}: BackNavButtonProps) => {
	const navigate = useNavigate();
	const historyBack = useCallback(() => {
		// This will include search params of the previous history entry
		navigate(-1);
	}, [navigate]);

	if (!backNav || !backNav.link) {
		return null;
	}

	return (
		<Button
			// This doesn't work - get a "does not recognize" even when forwardQuery={undefined}
			// component={backNav.link ? RouteLink : undefined}
			// forwardQuery={
			//     backNav.link ? backNav.includeSearchParams : undefined
			// }
			onClick={backNav.link === -1 ? historyBack : undefined}
			{...(backNav.link !== -1
				? {
						component: RouteLink,
						to: backNav.link,
						forwardQuery: backNav.includeSearchParams,
						...props,
				  }
				: props)}
		>
			{backNav.target
				? titlelize
					? formatTitle(backNav.target)
					: `Back to ${backNav.target}`
				: 'Back'}
		</Button>
	);
};

export default BackNavButton;
