import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeleteOutline } from '@mui/icons-material';
import {
	Box,
	FormHelperText,
	TextField,
	Typography,
	Stack,
	Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { TrainingType } from '@ivy/constants/clinician';
import { escapeLike } from '@ivy/gql/queryUtil';
import { gql } from '@ivy/gql/types';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';
import { useDebounce } from '@ivy/lib/hooks';
import { yearsFromNow } from '@ivy/lib/validation/date';

import { type ProfileCompleteFormValues } from '../profileCompleteForm';

interface TrainingObj {
	id: string;
	startYear: Date | null;
	endYear: Date | null;
	field: string;
	type: string;
	program: string;
	present: boolean;
}

interface ResidencyValues {
	fellowship: TrainingObj[];
	residency: TrainingObj[];
	appTraining: TrainingObj[];
}

interface ProgramOptionProps {
	id: string;
	name: string;
	city: string;
	state: string;
}

const createFormObj = (
	type: string,
): {
	modifyText: string;
	field: keyof typeof TrainingType;
} => {
	switch (type) {
		case 'fellowship':
			return {
				modifyText: 'fellowship',
				field: TrainingType.FELLOWSHIP,
			};
		case 'training':
			return {
				modifyText: 'fellowship',
				field: TrainingType.APP,
			};
		default:
			return {
				modifyText: 'residency',
				field: TrainingType.RESIDENCY,
			};
	}
};

interface TrainingFormProps {
	choices: TrainingOptionProps[];
	training: string;
	formik: FormikWithNamespace<TrainingObj>;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

interface TrainingOptionProps {
	name: string;
}

const TrainingForm = withFormikNamespace<
	TrainingFormProps,
	TrainingObj[],
	TrainingObj
>(({ choices, training, formik, arrayHelpers, idx, hideRemove }) => {
	const {
		values,
		touched,
		errors,
		setFieldTouched,
		setFieldValue,
		isSubmitting,
		handleChange,
		handleBlur,
	} = formik;

	const isFellowship = training === TrainingType.FELLOWSHIP;
	return (
		<Stack
			spacing={2}
			sx={{
				width: { sm: 'auto', xs: '100%' },
			}}
		>
			<Autocomplete
				freeSolo
				blurOnSelect
				autoHighlight
				selectOnFocus
				disableClearable
				TextFieldProps={{
					label: isFellowship ? 'Subspecialty' : 'Specialty',
					placeholder: `Enter a ${isFellowship ? 'subspecialty' : 'specialty'}`,
					...getErrorState(formik, 'field'),
				}}
				options={choices}
				getOptionLabel={(option: TrainingOptionProps | string) =>
					typeof option === 'string' ? option : option.name
				}
				inputValue={values.field || ''}
				onInputChange={(_, newVal) => setFieldValue('field', newVal)}
				onBlur={() => setFieldTouched('field')}
				disabled={isSubmitting}
				sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
			/>
			{isFellowship ? (
				// Currently do not have fellowship suggestions as you type
				<TextField
					label='Program'
					name='program'
					value={values.program || ''}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder='Enter a program'
					disabled={isSubmitting}
					error={touched.program && !!errors.program}
					helperText={touched.program && errors.program}
					sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
				/>
			) : (
				<ProgramAutocomplete
					TextFieldProps={{
						label: 'Program',
						error: touched.program && !!errors.program,
						helperText: touched.program && errors.program,
						placeholder: 'Enter a program',
					}}
					value={values.program || ''}
					onChange={(_, newVal) => {
						setFieldValue('program', newVal);
					}}
					onBlur={() => setFieldTouched('program')}
					disabled={isSubmitting}
					field={values.field || ''}
					training={training}
					sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
				/>
			)}
			<Box>
				<Stack
					direction={{ sm: 'row', xs: 'column' }}
					alignItems={{ sm: 'flex-end', xs: 'flex-start' }}
				>
					<DatePicker
						views={['year']}
						label='Start Year'
						value={values.startYear || null}
						onChange={(newVal) => setFieldValue('startYear', newVal)}
						renderInput={(params) => (
							<TextField
								{...params}
								helperText={touched.startYear && errors.startYear}
								error={touched.startYear && !!errors.startYear}
								sx={{ maxWidth: '200px', mb: { sm: 3, xs: 2 }, mr: 3 }}
								onBlur={() => setFieldTouched('startYear')}
							/>
						)}
						disabled={isSubmitting}
						minDate={new Date(1900, 0)}
						maxDate={new Date(yearsFromNow(0), 0)}
					/>
					<Stack sx={{ position: 'relative' }}>
						<DatePicker
							views={['year']}
							label='End Year'
							value={values.endYear || null}
							onChange={(newVal) => setFieldValue('endYear', newVal)}
							renderInput={(params) => (
								<TextField
									{...params}
									helperText={touched.endYear && errors.endYear}
									error={touched.endYear && !!errors.endYear}
									sx={{ maxWidth: '200px', mb: 3 }}
									onBlur={() => setFieldTouched('endYear')}
								/>
							)}
							disabled={values.present || isSubmitting}
							minDate={values.startYear || null}
							maxDate={new Date(yearsFromNow(0), 0)}
						/>
						<LabeledCheckbox
							label={
								training === TrainingType.RESIDENCY
									? "I'm a current resident."
									: training === TrainingType.FELLOWSHIP
									? "I'm a current fellow."
									: "I'm still training here."
							}
							value={values.present}
							onChange={(_, nv) => {
								setFieldValue('endYear', null);
								setFieldValue('present', nv);
							}}
							size='small'
							sx={{
								justifyContent: 'flex-end',
								position: 'absolute',
								bottom: 0,
								right: { sm: 0, xs: 'auto' },
							}}
						/>
					</Stack>
					<Box
						display={hideRemove ? 'none' : 'block'}
						sx={{ my: 'auto', ml: { sm: 2, xs: 0 } }}
					>
						<ControlButton
							startIcon={<DeleteOutline />}
							onClick={() => arrayHelpers.remove(idx)}
							disabled={isSubmitting}
						>
							Remove
						</ControlButton>
					</Box>
				</Stack>
			</Box>
		</Stack>
	);
});

const Residency_SearchTrainingProgramsQDoc = gql(/* GraphQL */ `
	query Residency_SearchTrainingPrograms(
		$search: String!
		$training: trainingtype!
		$fieldName: String!
	) {
		programs: training_program(
			where: {
				name: { _ilike: $search }
				field: { name: { _eq: $fieldName }, training_type: { _eq: $training } }
			}
			order_by: { name: asc }
			limit: 10
		) {
			id
			name
			city
			state
		}
	}
`);

const ProgramAutocomplete = ({
	value,
	onChange,
	field,
	onBlur,
	training,
	disabled,
	TextFieldProps,
	...props
}) => {
	const debouncedValue = useDebounce(value, 250);
	const { data } = useQuery(Residency_SearchTrainingProgramsQDoc, {
		variables: {
			search: escapeLike(debouncedValue),
			training,
			fieldName: field,
		},
		skip: !!disabled || !field || !training,
	});

	const choices: ProgramOptionProps[] = data?.programs || [];

	return (
		<Autocomplete
			freeSolo
			blurOnSelect
			autoHighlight
			selectOnFocus
			disableClearable
			TextFieldProps={TextFieldProps}
			renderInput={(params) => <TextField {...params} {...TextFieldProps} />}
			options={choices}
			renderOption={(params, option) => (
				<Box {...params} key={option.id} component='li'>
					<Box>
						<Typography variant='body1'>{option.name}</Typography>
						{!!option.city && !!option.state && (
							<Typography variant='body2' color='text.secondary'>
								{option.city}, {option.state}
							</Typography>
						)}
					</Box>
				</Box>
			)}
			getOptionLabel={(option: ProgramOptionProps | string) =>
				typeof option === 'string' ? option : option.name
			}
			inputValue={value}
			onInputChange={onChange}
			onBlur={onBlur}
			disabled={disabled}
			{...props}
		/>
	);
};

interface ResidencyProps {
	formik: FormikWithNamespace<TrainingObj[]>;
	onNext: (event: React.SyntheticEvent) => void;
	activeStep: string;
}

const Residency_GetTrainingSpecialtiesQDoc = gql(/* GraphQL */ `
	query Residency_GetTrainingSpecialties {
		specialties: training_specialties {
			name
			training
		}
		fields: training_field(order_by: { name: asc }) {
			id
			name
			training: training_type
		}
	}
`);

const MAX_TRAINING_PER_CATEGORY = 5;

const appValidation = yup.object({
	appTraining: yup
		.array()
		.of(
			yup.object({
				id: yup.string().when('$strict', {
					is: (val: boolean) => val,
					then: (schema) => schema.required(),
					otherwise: (schema) => schema.nullable(),
				}),
				program: yup
					.string()
					.max(200)
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Program'),
				field: yup
					.string()
					.max(200)
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Specialty'),
				startYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(new Date(1900, 0), 'Must be after 1900')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Start year'),
				endYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(yup.ref('startYear'), 'End year cannot come before start year')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.when('present', {
						is: (val: boolean) => val,
						then: (schema) => schema.nullable(),
						otherwise: (schema) =>
							schema.when('$strict', {
								is: (val: boolean) => val,
								then: (sch) => sch.required(),
								otherwise: (sch) => sch.nullable(),
							}),
					})
					.label('End year'),
				present: yup.boolean().required(),
				type: yup.string().required(),
			}),
		)
		.max(
			MAX_TRAINING_PER_CATEGORY,
			`At most ${MAX_TRAINING_PER_CATEGORY} trainings`,
		)
		.required(),
});

const resValidation = yup.object({
	residency: yup
		.array()
		.of(
			yup.object({
				id: yup.string(),
				program: yup.string().max(200).required().label('Program'),
				field: yup.string().max(200).required().label('Specialty'),
				startYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(new Date(1900, 0), 'Must be after 1900')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.required()
					.label('Start year'),
				endYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(yup.ref('startYear'), 'End year cannot come before start year')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.when('present', {
						is: (val: boolean) => val,
						then: (schema) => schema.nullable(),
						otherwise: (schema) => schema.required(),
					})
					.label('End year'),
				present: yup.boolean().required(),
				type: yup.string().required(),
			}),
		)
		.when('$isAPP', {
			is: (isAPP: boolean) => isAPP,
			then: (schema) => schema,
			otherwise: (schema) => schema.min(1, 'At least 1 residency is required'),
		})
		.max(
			MAX_TRAINING_PER_CATEGORY,
			`At most ${MAX_TRAINING_PER_CATEGORY} residencies`,
		)
		.required(),
});

const fellowValidation = yup.object({
	fellowship: yup
		.array()
		.of(
			yup.object({
				id: yup.string().when('$strict', {
					is: (val: boolean) => val,
					then: (schema) => schema.required(),
					otherwise: (schema) => schema.nullable(),
				}),
				program: yup
					.string()
					.max(200)
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Program'),
				field: yup
					.string()
					.max(200)
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Subspecialty'),
				startYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(new Date(1900, 0), 'Must be after 1900')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Start year'),
				endYear: yup
					.date()
					.typeError('Must be a valid date')
					.min(yup.ref('startYear'), 'End year cannot come before start year')
					.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
					.when('present', {
						is: (val: boolean) => val,
						then: (schema) => schema.nullable(),
						otherwise: (schema) =>
							schema.when('$strict', {
								is: (val: boolean) => val,
								then: (sch) => sch.required(),
								otherwise: (sch) => sch.nullable(),
							}),
					})
					.label('End year'),
				present: yup.boolean().required(),
				type: yup.string().required(),
			}),
		)
		.max(
			MAX_TRAINING_PER_CATEGORY,
			`At most ${MAX_TRAINING_PER_CATEGORY} fellowships`,
		)
		.required(),
});

const initialValue = {
	appTraining: [
		{
			id: uuidv4(),
			startYear: null,
			endYear: null,
			field: '',
			present: false,
			program: '',
			type: TrainingType.APP,
		},
	],
	residency: [
		{
			id: uuidv4(),
			startYear: null,
			endYear: null,
			field: '',
			present: false,
			program: '',
			type: TrainingType.RESIDENCY,
		},
	],
	fellowship: [
		{
			id: uuidv4(),
			startYear: null,
			endYear: null,
			field: '',
			present: false,
			program: '',
			type: TrainingType.FELLOWSHIP,
		},
	],
};

const Residency = withFormikNamespace<
	ResidencyProps,
	ProfileCompleteFormValues,
	TrainingObj[]
>(({ formik, onNext, activeStep }) => {
	const { values, errors } = formik;
	const formObj = createFormObj(activeStep);
	const { data, loading, error } = useQuery(
		Residency_GetTrainingSpecialtiesQDoc,
	);

	const resChoices: TrainingOptionProps[] = useMemo(() => {
		if (!data) return [];

		switch (formObj.field) {
			case TrainingType.RESIDENCY:
				return data.fields.filter(
					(el) => el.training === TrainingType.RESIDENCY,
				);
			case TrainingType.FELLOWSHIP:
				return data.specialties.filter(
					(el) => el.training === TrainingType.FELLOWSHIP,
				);
			case TrainingType.APP:
				return data.fields.filter((el) => el.training === TrainingType.APP);
			default:
				return [];
		}
	}, [data, formObj.field]);

	return (
		<Box>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				variant='circular'
			>
				{() => (
					<Box>
						<FieldArray
							name={formik.getFullName()}
							render={(arrayHelpers) => (
								<Grid container direction='column' spacing={1}>
									{values.map((el, idx) => (
										<Grid container item key={el.id}>
											<TrainingForm
												namespace={idx}
												idx={idx}
												choices={resChoices}
												training={formObj.field}
												formik={formik}
												arrayHelpers={arrayHelpers}
												hideRemove={values.length < 2}
											/>
										</Grid>
									))}
									<Grid
										item
										display='flex'
										justifyContent={{ sm: 'flex-start', xs: 'center' }}
									>
										<ModifyButton
											disabled={values.length >= MAX_TRAINING_PER_CATEGORY}
											onClick={() =>
												arrayHelpers.push({
													id: uuidv4(),
													startYear: null,
													endYear: null,
													field: '',
													present: false,
													program: '',
													type: formObj.field,
												})
											}
										>
											Add {formObj.modifyText}
										</ModifyButton>
									</Grid>
									<Grid>
										{typeof errors === 'string' && (
											<FormHelperText error>{errors}</FormHelperText>
										)}
									</Grid>
								</Grid>
							)}
						/>
					</Box>
				)}
			</DataLoader>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Box>
	);
});

export default Residency;
export {
	type ResidencyValues,
	type ResidencyProps,
	type TrainingObj,
	fellowValidation,
	resValidation,
	appValidation,
	initialValue,
};
