import {
	useFormik,
	yupToFormErrors,
	type FormikConfig,
	type FormikValues,
} from 'formik-othebi';
import isFunction from 'lodash/isFunction';
import { type ObjectSchema } from 'yup';
import { type AnyObject } from 'yup/lib/types';

interface FormConfig<Values extends FormikValues> extends FormikConfig<Values> {
	context?: AnyObject;
}

const useForm = <Values extends FormikValues>({
	context,
	validate,
	validationSchema,
	enableReinitialize,
	...formikArgs
}: FormConfig<Values>) => {
	return useFormik({
		...formikArgs,
		enableReinitialize,
		validate:
			validate ||
			(validationSchema
				? (values) => {
						try {
							(
								(isFunction(validationSchema)
									? validationSchema()
									: // eslint-disable-next-line @typescript-eslint/no-explicit-any
									  validationSchema) as ObjectSchema<any>
							).validateSync(values, {
								abortEarly: false,
								context,
							});
							return {};
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} catch (err: any) {
							if (err.name === 'ValidationError') {
								return yupToFormErrors(err);
							}
							throw err;
						}
				  }
				: undefined),
	});
};

export default useForm;
