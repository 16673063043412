import React from 'react';

import { Box, Grid, type SxProps, type Theme, Typography } from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

const NumberedValueProp = ({ no, primary, secondary, ...props }) => {
	return (
		<Box {...props}>
			<Box display='flex'>
				<Box
					sx={{
						mr: 2,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '1.5rem',
							height: '1.5rem',
							borderRadius: '50%',
							bgcolor: 'primary.main',
						}}
					>
						<Typography
							variant='body1'
							sx={{
								fontWeight: 'bold',
								color: 'background.paper',
								lineHeight: '1',
							}}
						>
							{no}
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography variant='h5' component='h3' color='primary.main'>
						{primary}
					</Typography>
					<Typography variant='body1' mt={3} color='text.icon'>
						{secondary}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

interface HowItWorksProps {
	sx?: SxProps<Theme>;
}

const HowItWorks = ({ sx }: HowItWorksProps) => {
	return (
		<Box component='section' sx={sx}>
			<Typography
				variant='h2'
				textAlign={{
					xs: 'center',
					md: 'initial',
				}}
				mb={{
					xs: 5,
					md: 10,
				}}
			>
				How does it work?
			</Typography>
			<Box
				sx={{
					display: {
						md: 'flex',
					},
					justifyContent: {
						md: 'space-between',
					},
					minHeight: {
						lg: '500px',
					},
				}}
			>
				<Box
					sx={{
						height: {
							xs: '180px',
							sm: '325px',
							md: 'auto',
						},
						flexBasis: {
							md: '50%',
						},
						overflow: 'hidden',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						'& .yt-lite': {
							width: '100%',
							height: '100%',
							border: 'none',
						},
					}}
				>
					<LiteYouTubeEmbed id='hytqY94xC1g' title='How Ivy Clinicians Works' />
				</Box>
				<Grid
					container
					spacing={{
						xs: 4,
						md: 6,
					}}
					sx={{
						mt: {
							xs: 4,
							md: 0,
						},
						flexBasis: {
							md: '50%',
						},
					}}
				>
					<Grid item xs={12} md={6}>
						<NumberedValueProp
							no={1}
							primary='Search'
							secondary='See all the emergency departments, employer groups, and job opportunities in the area.'
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<NumberedValueProp
							no={2}
							primary='Filter'
							secondary='Dive into the details of each facility, including ED volume, residency program, reported salaries, and more. Use the filters to find your perfect fit.'
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<NumberedValueProp
							no={3}
							primary='Apply'
							secondary='Click on your preferred EDs and apply! To protect your privacy, your CV is sent ONLY to the employers you contact.'
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<NumberedValueProp
							no={4}
							primary='Interview'
							secondary='Check your email inbox regularly for replies and interview requests from employers.'
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default HowItWorks;
