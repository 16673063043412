import React, { useEffect, useState } from 'react';

import {
	Box,
	Grid,
	Skeleton,
	Typography,
	type Theme,
	useMediaQuery,
} from '@mui/material';

import StepPagination from '@ivy/components/molecules/StepPagination';
import FacilityCard from '@ivy/components/organisms/FacilityCard';
import { MILE_TO_METERS } from '@ivy/constants/location';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

const FacilitySkeleton = () => {
	return (
		<Box
			sx={{
				boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				borderRadius: (theme) =>
					`${(theme.cardShape || theme.shape).borderRadius}px`,
				height: '100%',
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='100%'
				height={175}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='100%'
				height={25}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
		</Box>
	);
};

const FacilitySugggestions_RankedFacilityFDoc = gql(/* GraphQL */ `
	fragment FacilitySuggestions_RankedFacility on ranked_facility {
		id
		distance
		facility {
			...FacilityCard_Facility
		}
	}
`);

export interface FacilitySuggestionsProps {
	rankedFacilities?: FragmentType<
		typeof FacilitySugggestions_RankedFacilityFDoc
	>[];
	loading?: boolean;
	backNavTarget?: string;
}

const FacilitySuggestions = ({
	rankedFacilities: rawRankedFacilities,
	loading = false,
	backNavTarget,
}: FacilitySuggestionsProps) => {
	const rankedFacilities = getFragmentData(
		FacilitySugggestions_RankedFacilityFDoc,
		rawRankedFacilities,
	);
	const [page, setPage] = useState(0);
	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
		noSsr: true,
	});
	const isMd = useMediaQuery(
		(theme: Theme) => theme.breakpoints.between('sm', 'md'),
		{
			noSsr: true,
		},
	);
	const pageSize = isSm ? 1 : isMd ? 2 : 3;

	useEffect(() => {
		// Reset the page every time page size changes or if we reload data to avoid going beyond the bounds
		setPage(0);
	}, [pageSize, rankedFacilities]);

	return (
		<Box display={!loading && !rankedFacilities?.length ? 'none' : undefined}>
			<Grid
				container
				columnSpacing={5}
				sx={{
					height: '473px',
					mb: 3,
				}}
			>
				{loading
					? [...Array(pageSize).keys()].map((el) => (
							<Grid item key={`suggestion-skeleton-${el}`} xs={12 / pageSize}>
								<FacilitySkeleton key={`suggestion-skeleton-${el}`} />
							</Grid>
					  ))
					: rankedFacilities
							?.slice(page * pageSize, (page + 1) * pageSize)
							.map((rankedFacility) => (
								<Grid item key={rankedFacility.id} xs={12 / pageSize}>
									<FacilityCard
										sx={{
											boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
											borderRadius: (theme) =>
												`${(theme.cardShape || theme.shape).borderRadius}px`,
											height: '100%',
										}}
										facility={rankedFacility.facility!}
										orientation='vertical'
										backNavTarget={backNavTarget}
									/>
									<Typography variant='caption' fontWeight='bold' ml={1} mt={1}>
										{rankedFacility.distance / MILE_TO_METERS < 1
											? Math.round(
													(rankedFacility.distance * 10) / MILE_TO_METERS,
											  ) / 10
											: Math.round(
													rankedFacility.distance / MILE_TO_METERS,
											  )}{' '}
										mi. away
									</Typography>
								</Grid>
							))}
			</Grid>
			<Box display='flex' justifyContent='flex-end'>
				<StepPagination
					count={rankedFacilities?.length || 0}
					pageSize={pageSize}
					page={page}
					onChangePage={setPage}
				/>
			</Box>
		</Box>
	);
};

export default FacilitySuggestions;
