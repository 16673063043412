import React, { useCallback } from 'react';

import { Box, TextField, Button, Typography } from '@mui/material';
import { useFormik, FormikProvider, type FormikHelpers } from 'formik-othebi';
import * as yup from 'yup';

import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

interface ReplyFormValues {
	isAnonymous: boolean;
	content: string;
}

const validation = yup.object({
	isAnonymous: yup.boolean().required(),
	content: yup
		.string()
		.required("Your reply can't be empty")
		.max(1024)
		.label('Your reply'),
});

const initialValues = {
	isAnonymous: false,
	content: '',
};

interface ReplyFormProps {
	inputRef?: React.MutableRefObject<HTMLInputElement | null>;
	onSubmit: (vals: ReplyFormValues) => void;
	restrict?: boolean;
	disabled?: boolean;
}

const ReplyForm = ({
	onSubmit,
	inputRef,
	restrict,
	disabled,
}: ReplyFormProps) => {
	const { openSignupPopup, openConfirmEmailPopup } = useAuthPopup();
	const currAcc = useCurrentAccount();

	const handleSubmit = useCallback(
		(vals: ReplyFormValues, actions: FormikHelpers<ReplyFormValues>) => {
			onSubmit(vals);
			actions.resetForm();
		},
		[onSubmit],
	);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validation,
		onSubmit: handleSubmit,
	});

	if (!currAcc) {
		return (
			<Box>
				<Button
					color='primary'
					variant='contained'
					onClick={() =>
						openSignupPopup(true, {
							// Confirm email popup may close silently if user has already confirmed their email
							onSuccess: () => openConfirmEmailPopup(),
							// Since we're opening another auth popup, disable the default behavior to close the auth popup
							// after success
							disableCloseOnSuccess: true,
						})
					}
				>
					Sign up or log in to reply
				</Button>
			</Box>
		);
	} else if (!currAcc.ci?.confirmedEmail) {
		return (
			<Box>
				<Button
					color='primary'
					variant='contained'
					onClick={() => openConfirmEmailPopup()}
				>
					Verify your email before replying
				</Button>
			</Box>
		);
	} else if (restrict) {
		return (
			<Typography variant='caption'>
				* Responses are limited to clinicians and the employer mentioned in this
				review.
			</Typography>
		);
	}

	return (
		<FormikProvider value={formik}>
			<Box>
				<LabeledCheckbox
					label='Reply as anonymous'
					value={formik.values.isAnonymous}
					onChange={(_, val) => formik.setFieldValue('isAnonymous', val)}
					size='small'
					disabled={disabled}
					sx={{ mb: 1.5 }}
				/>
				<TextField
					name='content'
					placeholder='Reply to this review'
					value={formik.values.content}
					onChange={formik.handleChange}
					multiline
					fullWidth
					inputRef={inputRef}
					disabled={disabled}
					InputProps={{
						endAdornment: (
							<Box sx={{ mt: 'auto', ml: { md: 'inherit', xs: 'auto' } }}>
								<Button
									color='primary'
									disabled={disabled}
									onClick={formik.submitForm}
									sx={{ mt: 1, ml: 1 }}
								>
									Send
								</Button>
							</Box>
						),
						sx: {
							borderRadius: '16px !important',

							flexDirection: { md: 'row', xs: 'column' },
						},
					}}
					{...getErrorState(formik, 'content')}
				/>
			</Box>
		</FormikProvider>
	);
};

export default ReplyForm;
export { type ReplyFormValues };
