import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const HeadCountIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6 3C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5C4 5.53043 4.21071 6.03914 4.58579 6.41421C4.96086 6.78929 5.46957 7 6 7C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.46957 7.78929 3.96086 7.41421 3.58579C7.03914 3.21071 6.53043 3 6 3ZM18 3C17.4696 3 16.9609 3.21071 16.5858 3.58579C16.2107 3.96086 16 4.46957 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7C18.5304 7 19.0391 6.78929 19.4142 6.41421C19.7893 6.03914 20 5.53043 20 5C20 4.46957 19.7893 3.96086 19.4142 3.58579C19.0391 3.21071 18.5304 3 18 3ZM12 7C11.4696 7 10.9609 7.21071 10.5858 7.58579C10.2107 7.96086 10 8.46957 10 9C10 9.53043 10.2107 10.0391 10.5858 10.4142C10.9609 10.7893 11.4696 11 12 11C12.5304 11 13.0391 10.7893 13.4142 10.4142C13.7893 10.0391 14 9.53043 14 9C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7ZM6 11C5.46957 11 4.96086 11.2107 4.58579 11.5858C4.21071 11.9609 4 12.4696 4 13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15C6.53043 15 7.03914 14.7893 7.41421 14.4142C7.78929 14.0391 8 13.5304 8 13C8 12.4696 7.78929 11.9609 7.41421 11.5858C7.03914 11.2107 6.53043 11 6 11ZM18 11C17.4696 11 16.9609 11.2107 16.5858 11.5858C16.2107 11.9609 16 12.4696 16 13C16 13.5304 16.2107 14.0391 16.5858 14.4142C16.9609 14.7893 17.4696 15 18 15C18.5304 15 19.0391 14.7893 19.4142 14.4142C19.7893 14.0391 20 13.5304 20 13C20 12.4696 19.7893 11.9609 19.4142 11.5858C19.0391 11.2107 18.5304 11 18 11ZM6 17C4.665 17 2 17.7208 2 19.0508V21H10V19.0508C10 17.7208 7.335 17 6 17ZM18 17C16.665 17 14 17.7208 14 19.0508V21H22V19.0508C22 17.7208 19.335 17 18 17Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default HeadCountIcon;
