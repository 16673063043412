import { type SxProps, type Theme } from '@mui/material';
import isArray from 'lodash/isArray';

export const combineSx = (
	...args: Array<SxProps<Theme> | undefined>
): SxProps<Theme> => {
	return args
		.filter((el) => !!el)
		.map((el) => (isArray(el) ? el : [el]))
		.flat();
};

/**
 * Prepares a string to be used in the `content` CSS property by surrounding it with double quotes and escaping
 * nested double quotes as well as converting newlines to their ISO-10646 equivalent (U+000A).
 * @param value the string to prepare
 */
export const prepareCssContentProperty = (value: string) => {
	// https://www.w3.org/TR/CSS2/syndata.html#strings
	// Replace newline with a space and a line break
	// Note for newline we either need "\\A " with space or "\\00000a". The space in "\\A " prevents things like
	// \\ADwight from being detected as \\AD, which consequentially renders just "wight". The latter is not shorthand
	// and won't be mistaken.
	// HTML collapses whitespace by default, so no need to worry about the extra space
	const inner = value.replaceAll('"', '\\"').replaceAll('\n', '\\00000a');
	return `"${inner}"`;
};

export const scrollShadowsSx = {
	background: `
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom
  `,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
	backgroundAttachment: 'local, local, scroll, scroll',
};
