import React from 'react';

const nestComponents = (
	components: React.ComponentType<{ children?: React.ReactNode }>[],
): React.ComponentType<{ children?: React.ReactNode }> => {
	const WrapperComponent = components[0];
	if (components.length === 1) {
		return WrapperComponent;
	}
	const NestedComponent = nestComponents(components.slice(1));
	return () => (
		<WrapperComponent>
			<NestedComponent />
		</WrapperComponent>
	);
};

const withBoundary = (WrapperComponent, ...args) =>
	nestComponents([...args, WrapperComponent]);

export default withBoundary;
