import { formatInteger } from '@ivy/lib/formatting/number';

export const TRAUMA_MAP = {
	1: 'I',
	2: 'II',
	3: 'III',
	4: 'IV',
	5: 'V',
};

export enum EdVolume {
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high',
	VERY_HIGH = 'very high',
}

export const ED_VOLUME_VERBOSE = {
	[EdVolume.LOW]: 'Low',
	[EdVolume.MEDIUM]: 'Medium',
	[EdVolume.HIGH]: 'High',
	[EdVolume.VERY_HIGH]: 'Very high',
};

export const FACILITY_PLACEHOLDER_IMAGE =
	'https://assets.ivyclinicians.io/placeholders/dalle-er-blur-4px.png';

export const CmsMetric = {
	STAY_LENGTH: 'OP_18b',
	LEFT_WITHOUT_SEEN: 'OP_22',
	SEPSIS: 'SEP_1',
	AMI: 'MORT_30_AMI',
	COPD: 'MORT_30_COPD',
	STROKE: 'MORT_30_STK',
};

export const ED_VOLUME_BOUNDS = {
	[EdVolume.LOW]: [0, 19999],
	[EdVolume.MEDIUM]: [20000, 39999],
	[EdVolume.HIGH]: [40000, 59999],
	[EdVolume.VERY_HIGH]: [60000, Number.POSITIVE_INFINITY],
};

export const ED_VOLUME_RANGE = Object.entries(ED_VOLUME_BOUNDS).reduce(
	(tot, [k, v]) => ({
		...tot,
		[k]:
			v[0] === 0
				? `< ${formatInteger(Math.floor((v[1] + 1) / 1000))}k`
				: v[1] === Number.POSITIVE_INFINITY
				? `${formatInteger(Math.floor(v[0] / 1000))}k+`
				: `${formatInteger(Math.floor(v[0] / 1000))}k - ${formatInteger(
						Math.floor(v[1] / 1000),
				  )}k`,
	}),
	{},
);

export enum ContractChangeRequest {
	ADDITION = 'ADDITION',
	REMOVAL = 'REMOVAL',
}

export enum StrokeCertLevel {
	REHAB = 'REHAB',
	ASRH = 'ASRH',
	PSC = 'PSC',
	TSC = 'TSC',
	CSC = 'CSC',
}

export const STROKELVL2VERBOSE = {
	[StrokeCertLevel.REHAB]: 'Stroke Rehabilitation',
	[StrokeCertLevel.ASRH]: 'Acute Stroke Ready Hospital',
	[StrokeCertLevel.PSC]: 'Primary Stroke Center',
	[StrokeCertLevel.TSC]: 'Thrombectomy-Capable Stroke Center',
	[StrokeCertLevel.CSC]: 'Comprehensive Stroke Center',
};

export const INTRUSIVE_MENTAL_HEALTH_LICENSURE_STATES = [
	'CA',
	'CT',
	'GA',
	'HI',
	'ID',
	'IL',
	'KS',
	'ME',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NY',
	'NC',
	'ND',
	'TX',
	'UT',
	'WA',
];

export enum CmsHospitalType {
	CAH = 'Critical Access Hospitals',
	// TODO: this isn't a category yet, but we expect it will be. Will also need to update in `cms-data-ingestion`
	//  service
	REH = 'Rural Emergency Hospitals',
}

export enum CmsEnrollmentProviderTypeCode {
	REH = '00-24',
	CAH = '00-85',
}

export enum CmsPosProviderSubtypeCode {
	REH = '28',
	CAH = '11',
}

export enum Accreditation {
	ACEP_CUAP = 'ACEP_CUAP',
	ACEP_GEDA = 'ACEP_GEDA',
	ACEP_PACED = 'ACEP_PACED',
	ACEP_EDAP = 'ACEP_EDAP',
}

export const accreditationNames = {
	[Accreditation.ACEP_CUAP]: 'Clinical Ultrasound Accreditation Program (CUAP)',
	[Accreditation.ACEP_GEDA]: 'Geriatric ED Accreditation (GEDA)',
	[Accreditation.ACEP_PACED]: 'Pain and Addiction Care in the ED (PACED)',
	[Accreditation.ACEP_EDAP]: 'ED Accreditation Program (EDAP)',
};

export const accreditationShortNames = {
	[Accreditation.ACEP_CUAP]: 'CUAP',
	[Accreditation.ACEP_GEDA]: 'GEDA',
	[Accreditation.ACEP_PACED]: 'PACED',
	[Accreditation.ACEP_EDAP]: 'EDAP',
};

export const ACCREDITATION2SLUG = Object.entries(
	accreditationShortNames,
).reduce(
	(tot, [k, v]) => ({
		...tot,
		[k]: v.toLowerCase(),
	}),
	{},
) as Record<Accreditation, string>;

export const SLUG2ACCREDITATION = Object.entries(ACCREDITATION2SLUG).reduce(
	(tot, [k, v]) => ({
		...tot,
		[v]: k,
	}),
	{},
) as Record<string, Accreditation>;

export const accreditationLevels = {
	[Accreditation.ACEP_CUAP]: null,
	[Accreditation.ACEP_GEDA]: {
		1: 'Gold - Level 1',
		2: 'Silver - Level 2',
		3: 'Bronze - Level 3',
	},
	[Accreditation.ACEP_PACED]: {
		1: 'Gold - Level 1',
		2: 'Silver - Level 2',
		3: 'Bronze - Level 3',
	},
	[Accreditation.ACEP_EDAP]: null,
};

export const accreditationShortLevels = {
	[Accreditation.ACEP_CUAP]: null,
	[Accreditation.ACEP_GEDA]: {
		1: 'Gold',
		2: 'Silver',
		3: 'Bronze',
	},
	[Accreditation.ACEP_PACED]: {
		1: 'Gold',
		2: 'Silver',
		3: 'Bronze',
	},
	[Accreditation.ACEP_EDAP]: null,
};
