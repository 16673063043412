// TODO: make immediate needs baadge smaler text
import React, { useState, useMemo } from 'react';

import {
	type QueryHookOptions,
	useQuery,
	type DocumentNode,
	type TypedDocumentNode,
	type OperationVariables,
} from '@apollo/client';

import useEntityFilters, {
	type EntityFilterValues,
	type FilterDataStructure,
} from '@ivy/components/organisms/Map/filters/useEntityFilters';
import { useNotifyError } from '@ivy/lib/hooks';

import {
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
} from '../BaseMap';

import MapContext, { type MapContextValue } from './MapContext';

export interface MapProviderProps<
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
> {
	queryDoc: DocumentNode | TypedDocumentNode<TData, TVariables>;
	queryPreviewDoc:
		| DocumentNode
		| TypedDocumentNode<TPreviewData, TPreviewVariables>;
	resolver: (
		data: TData | undefined,
		appliedFilters: EntityFilterValues,
	) => TMapItemObject[] | undefined;
	nearbyResolver?: (
		data: TData | undefined,
		appliedFilters: EntityFilterValues,
	) => TNearbyItemObject[] | undefined;
	previewResolver: (
		data: TPreviewData | undefined,
		appliedFilters: EntityFilterValues,
	) => TMapItemObject | undefined;
	children?: React.ReactNode;
	initialEntityFilters: EntityFilterValues | (() => EntityFilterValues);
	filters: FilterDataStructure[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rootFilters?: Record<string, any>;
}

const MapProvider = <
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
>({
	queryDoc,
	queryPreviewDoc,
	resolver,
	nearbyResolver,
	previewResolver,
	children,
	initialEntityFilters,
	filters,
	rootFilters,
}: MapProviderProps<
	TData,
	TPreviewData,
	TMapItemObject,
	TNearbyItemObject,
	TVariables,
	TPreviewVariables
>) => {
	const [queryOptions, setQueryOptions] =
		useState<QueryHookOptions<TData, TVariables>>();
	const queryResponse = useQuery(queryDoc, {
		skip: !queryOptions,
		...queryOptions,
	});

	const [queryPreviewOptions, setQueryPreviewOptions] =
		useState<QueryHookOptions<TPreviewData, TPreviewVariables>>();
	const queryPreviewResponse = useQuery(queryPreviewDoc, {
		skip: !queryPreviewOptions,
		...queryPreviewOptions,
	});

	useNotifyError(queryResponse.error || queryPreviewResponse.error);

	const entityFilters = useEntityFilters(
		initialEntityFilters,
		filters,
		rootFilters,
	);

	const dataResolver = useMemo(
		() => () => resolver(queryResponse.data, entityFilters.appliedFilters),
		[resolver, queryResponse.data, entityFilters.appliedFilters],
	);
	const dataNearbyResolver = useMemo(
		() =>
			nearbyResolver &&
			(() => nearbyResolver(queryResponse.data, entityFilters.appliedFilters)),
		[nearbyResolver, queryResponse.data, entityFilters.appliedFilters],
	);
	const dataPreviewResolver = useMemo(
		() => () =>
			previewResolver(queryPreviewResponse.data, entityFilters.appliedFilters),
		[previewResolver, queryPreviewResponse.data, entityFilters.appliedFilters],
	);

	const mapProviderValue = useMemo(() => {
		return {
			queryResponse,
			queryOptions,
			setQueryOptions,
			entityFilters,
			dataResolver,
			dataNearbyResolver,
			queryPreviewResponse,
			queryPreviewOptions,
			setQueryPreviewOptions,
			dataPreviewResolver,
		};
	}, [
		queryResponse,
		queryOptions,
		setQueryOptions,
		entityFilters,
		dataResolver,
		dataNearbyResolver,
		queryPreviewResponse,
		queryPreviewOptions,
		setQueryPreviewOptions,
		dataPreviewResolver,
	]);

	return (
		// TODO: add generics
		<MapContext.Provider
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value={mapProviderValue as MapContextValue<any, any, any, any, any>}
		>
			{children}
		</MapContext.Provider>
	);
};

export default MapProvider;
