import React, { useState, useEffect, useMemo } from 'react';

import {
	useQuery,
	type DocumentNode,
	type TypedDocumentNode,
} from '@apollo/client';
import {
	Box,
	Chip,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';
import upperCase from 'lodash/upperCase';

import TrainingFeatured from '@ivy/components/organisms/TrainingFeatured';
import {
	TrainingFeatured_FeaturedClerkshipQDoc,
	TrainingFeatured_FeaturedFellowshipQDoc,
	TrainingFeatured_FeaturedResidencyQDoc,
	resolver,
} from '@ivy/components/organisms/TrainingFeatured/apiHelpers';
import { useSiteSettingsContext } from '@ivy/components/providers/SiteSettingsProvider';
import config from '@ivy/config';
import { EMRATYPES2TYPEVERBOSE, EMRATypes } from '@ivy/constants/emra';
import {
	type TrainingFeatured_FeaturedClerkshipQuery,
	type TrainingFeatured_FeaturedFellowshipQuery,
	type TrainingFeatured_FeaturedResidencyQuery,
} from '@ivy/gql/types/graphql';
import { combineSx } from '@ivy/lib/styling/sx';

interface LatLng {
	lat: number;
	lng: number;
}

export interface FeaturedProgramsProps {
	sx?: SxProps<Theme>;
}

export const docResolverByType = (
	type: keyof typeof EMRATypes,
): DocumentNode | TypedDocumentNode<unknown, unknown> => {
	switch (type) {
		case EMRATypes.CLERKSHIP:
			return TrainingFeatured_FeaturedClerkshipQDoc;
		case EMRATypes.FELLOWSHIP:
			return TrainingFeatured_FeaturedFellowshipQDoc;
		case EMRATypes.RESIDENCY:
		default:
			return TrainingFeatured_FeaturedResidencyQDoc;
	}
};

const FeaturedPrograms = ({ sx }: FeaturedProgramsProps) => {
	const { currSiteSettings } = useSiteSettingsContext();
	const [type, setType] = useState(
		(currSiteSettings?.emraFeaturedTrainingType as keyof typeof EMRATypes) ||
			EMRATypes.RESIDENCY,
	);
	const [coords, setCoords] = useState<LatLng | null>(null);
	const queryDoc = docResolverByType(type);
	const { data, loading } = useQuery(queryDoc, {
		variables: {
			x: coords?.lng,
			y: coords?.lat,
			limit: 30,
		},
		skip: !coords || !type,
	});

	const rawData = useMemo(() => {
		if (!data) return [];

		switch (type) {
			case EMRATypes.CLERKSHIP:
				return (
					data as TrainingFeatured_FeaturedClerkshipQuery
				).featuredClerkship.map(({ distance, clerkship }) =>
					resolver({ distance: distance, ...clerkship }, type),
				);
			case EMRATypes.FELLOWSHIP:
				return (
					data as TrainingFeatured_FeaturedFellowshipQuery
				).featuredFellowship.map(({ distance, fellowship }) =>
					resolver({ distance: distance, ...fellowship }, type),
				);
			case EMRATypes.RESIDENCY:
			default:
				return (
					data as TrainingFeatured_FeaturedResidencyQuery
				).featuredResidency.map(({ distance, residency }) =>
					resolver({ distance: distance, ...residency }, type),
				);
		}
	}, [data, type]);

	useEffect(() => {
		const determineLocation = async () => {
			const resp = await fetch(
				`https://ipinfo.io/json?token=${config.ipApiKey}`,
			);
			if (!resp.ok) {
				console.error(
					`IP lookup failed with status code ${resp.status}.`,
					resp,
				);
				throw new Error(`IP lookup failed with status code ${resp.status}.`);
			}
			let [lat, lng] = (await resp.json()).loc.split(',');
			lat = Number.parseFloat(lat);
			lng = Number.parseFloat(lng);
			setCoords({
				lat,
				lng,
			});
		};
		determineLocation();
	}, []);

	return (
		<Box component='section' sx={combineSx({ position: 'relative' }, sx)}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					top: '0',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#F1F1F1',
				}}
			/>
			<Stack spacing={3}>
				<Typography variant='h3' align='center' mb={5}>
					Featured Programs
				</Typography>
				<Stack
					direction='row'
					spacing={{
						xs: 1.5,
						sm: 6,
					}}
					justifyContent='center'
					mb={{
						xs: 3,
						sm: 10,
					}}
				>
					{Object.entries(EMRATYPES2TYPEVERBOSE).map(([value, label]) => (
						<Chip
							key={value}
							label={upperCase(label)}
							sx={{
								borderStyle: value !== type ? 'none' : undefined,
								color: value !== type ? 'text.icon' : undefined,
								backgroundColor: value !== type ? undefined : 'white',
								fontWeight: 'bold',
								borderRadius: '2px',
								lineHeight: '18px',
								letterSpacing: '1.95px',
							}}
							clickable={value !== type}
							variant={value !== type ? 'outlined' : 'filled'}
							onClick={() => setType(value as EMRATypes)}
						/>
					))}
				</Stack>
				<TrainingFeatured
					rankedTrainings={rawData}
					loading={loading || !rawData?.length}
					hideDistance
				/>
			</Stack>
		</Box>
	);
};

export default FeaturedPrograms;
