import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
	Box,
	Alert,
	Chip,
	Divider,
	Stack,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import Autocomplete from '@ivy/components/molecules/Autocomplete';
import Dropdown from '@ivy/components/molecules/Dropdown';
import FacilitySelect from '@ivy/components/molecules/FacilitySelect';
import FieldLabel from '@ivy/components/molecules/FieldLabel';
import {
	PROFVERBOSE2PROFESSION,
	Profession,
	ClinicianSpecialTypes,
} from '@ivy/constants/clinician';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type SalaryReportFormGeneralStep_OrganizationFragment } from '@ivy/gql/types/graphql';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';
import { useDebounce } from '@ivy/lib/hooks';

import { type SalaryFormValues } from './SalaryReportForm';

const SalaryReportFormGeneralStep_FacilityFDoc = gql(/* GraphQL */ `
	fragment SalaryReportFormGeneralStep_Facility on facility {
		id
		name
	}
`);

const SalaryReportFormGeneralStep_OrganizationFDoc = gql(/* GraphQL */ `
	fragment SalaryReportFormGeneralStep_Organization on organization {
		id
		name
	}
`);

const SalaryReportFormGeneralStep_SearchOrgsQDoc = gql(/* GraphQL */ `
	query SalaryReportFormGeneralStep_SearchOrgs($search: String!) {
		search: search_organization_by_prefix(
			args: { search: $search }
			order_by: [{ rank: desc }, { org: { name: asc } }, { org: { id: asc } }]
			limit: 50
		) {
			id
			org {
				id
				...SalaryReportFormGeneralStep_Organization
			}
		}
	}
`);

export const validation = yup.object({
	organization: yup.object().nullable().label('Employer group'),
	orgOther: yup
		.string()
		.max(100)
		.when('organization', {
			is: (org: SalaryReportFormGeneralStep_OrganizationFragment) => org,
			then: (schema) => schema,
			otherwise: (schema) => schema.required(),
		})
		.label('Employer group'),
	experience: yup
		.number()
		.integer('Must be a whole number')
		.min(0, 'Must be at least 0')
		.max(100, 'Must be less than 100')
		.typeError('Please enter a whole number')
		.nullable()
		.label('Years of experience')
		.when('$isAdmin', (isAdmin, schema) => {
			return !isAdmin ? schema.required() : schema;
		}),
	profession: yup
		.string()
		.oneOf(Object.values(Profession))
		.typeError('Please select a profession')
		.label('Profession')
		.required(),
	...ClinicianSpecialTypes.reduce(
		(o, type) => ({ ...o, [type.key]: yup.boolean().required() }),
		{},
	),
});

export const initialValue = {
	facility: null,
	organization: null,
	orgOther: '',
	experience: null,
	profession: '',
	...ClinicianSpecialTypes.reduce(
		(o, type) => ({ ...o, [type.key]: false }),
		{},
	),
};

interface GeneralStepProps {
	facility?: FragmentType<typeof SalaryReportFormGeneralStep_FacilityFDoc>;
	organization?: FragmentType<
		typeof SalaryReportFormGeneralStep_OrganizationFDoc
	>;
	isAdmin?: boolean;
	formik: FormikProps<SalaryFormValues>;
}

const GeneralStep = ({
	formik,
	isAdmin,
	facility: rawFacility,
	organization: rawOrganization,
}: GeneralStepProps) => {
	const facility = getFragmentData(
		SalaryReportFormGeneralStep_FacilityFDoc,
		rawFacility,
	);
	const organization = getFragmentData(
		SalaryReportFormGeneralStep_OrganizationFDoc,
		rawOrganization,
	);
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search);
	const orgResponse = useQuery(SalaryReportFormGeneralStep_SearchOrgsQDoc, {
		variables: {
			search: debouncedSearch,
		},
		skip: !debouncedSearch,
	});

	const generateChips = () => {
		return ClinicianSpecialTypes.map((type) => {
			return (
				<Chip
					key={type.key}
					color='primary'
					label={type.label}
					variant={formik.values[type.key] ? 'filled' : 'outlined'}
					onClick={() => {
						formik.setFieldValue(type.key, !formik.values[type.key]);
					}}
					sx={{ margin: 0.5 }}
				/>
			);
		});
	};

	return (
		<Box>
			<FieldLabel
				secondary={
					isAdmin ? (
						'Enter general report information'
					) : (
						<>
							<span>
								Thanks for taking a minute to add a salary report to help other
								job-seeking clinicians understand what they can expect to earn.
								Please enter the following information about your current job.
							</span>
							<Alert severity='info' sx={{ width: '100%', mt: 1 }}>
								Your submission is reviewed by Ivy Clinicians, so please make
								sure all information entered is accurate and up-to-date.
							</Alert>
						</>
					)
				}
				gutterBottom
			/>
			<Divider sx={{ mb: 2 }} />
			<Stack width='100%' spacing={2}>
				{(isAdmin || !facility) && (
					<Box>
						<Typography variant='body1' gutterBottom>
							Your facility name*
						</Typography>
						<FacilitySelect
							selectOnFocus
							clearOnBlur
							value={formik.values.facility}
							onChange={(_, nv) => formik.setFieldValue('facility', nv)}
							onBlur={() => formik.setFieldTouched('facility')}
							noOptions={(hasInput) =>
								hasInput ? 'No results found' : 'Start typing for results'
							}
							InputProps={{
								required: true,
								placeholder: 'Search by facility, city, or state',
								error: formik.touched.facility && !!formik.errors.facility,
								helperText: formik.touched.facility && formik.errors.facility,
							}}
						/>
						<Divider sx={{ mt: 2 }} />
					</Box>
				)}
				{(isAdmin || !organization) && (
					<Box>
						<Typography variant='body1' gutterBottom>
							Your employer name*
						</Typography>
						<Stack spacing={1}>
							<Autocomplete<
								SalaryReportFormGeneralStep_OrganizationFragment,
								false,
								false,
								true
							>
								freeSolo
								autoSelect
								autoHighlight
								blurOnSelect
								selectOnFocus
								fullWidth
								TextFieldProps={{
									...getErrorState(formik, 'orgOther'),
									required: true,
									placeholder: 'Search for employer name',
								}}
								getOptionLabel={(option) =>
									typeof option === 'string' ? option : option.name
								}
								options={
									orgResponse.data?.search
										.map((el) => el.org)
										.filter((el): el is NonNullable<typeof el> => !!el)
										.map((el) =>
											getFragmentData(
												SalaryReportFormGeneralStep_OrganizationFDoc,
												el,
											),
										) || []
								}
								// Override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
								filterOptions={(ops) => ops}
								inputValue={search}
								onInputChange={(_, newVal) => setSearch(newVal)}
								value={formik.values.organization ?? formik.values.orgOther}
								onChange={(_, newVal) => {
									if (!newVal) {
										setSearch('');
										formik.setValues({
											...formik.values,
											organization: null,
											orgOther: '',
										});
									} else if (Array.isArray(newVal)) {
										return;
									} else if (typeof newVal === 'string') {
										formik.setValues({
											...formik.values,
											organization: null,
											orgOther: newVal,
										});
									} else {
										formik.setValues({
											...formik.values,
											organization: newVal,
											orgOther: '',
										});
									}
								}}
								onBlur={() => {
									formik.setTouched({
										...formik.touched,
										organization: true,
										orgOther: true,
									});
								}}
								noOptionsText={
									!search ? 'Start typing for results' : 'No results found'
								}
								loading={orgResponse.loading}
								isOptionEqualToValue={(option, value) => {
									return option.id === value.id;
								}}
							/>
						</Stack>
						<Divider sx={{ mt: 2 }} />
					</Box>
				)}
				{isAdmin && (
					<Box>
						<Typography variant='body1' gutterBottom>
							Profession
						</Typography>
						<Dropdown
							required
							options={Object.entries(PROFVERBOSE2PROFESSION).map(
								([verbose, prof]) => ({
									label: verbose,
									value: prof,
								}),
							)}
							disabled={formik.isSubmitting}
							value={formik.values.profession}
							onChange={(nv) => formik.setFieldValue('profession', nv)}
							onBlur={() => formik.setFieldTouched('profession')}
							error={formik.touched.profession && !!formik.errors.profession}
							helperText={formik.touched.profession && formik.errors.profession}
							fullWidth
						/>
						<Divider sx={{ mt: 2 }} />
					</Box>
				)}
				{!isAdmin && (
					<Box>
						<Typography variant='body1' gutterBottom>
							Your specialty*
						</Typography>
						<TextField
							name='specialty'
							value='Emergency Medicine'
							disabled
							fullWidth
						/>
						<Divider sx={{ mt: 2 }} />
					</Box>
				)}
				<Box>
					<Typography variant='body1' gutterBottom>
						Postgraduate years of EM experience*
					</Typography>
					<TextField
						disabled={formik.isSubmitting}
						placeholder='0'
						name='experience'
						type='number'
						inputProps={{
							onWheel: (e) => e.currentTarget.blur(),
							min: 0,
							max: 100,
							step: 1,
							inputMode: 'numeric',
							pattern: '[0-9]*',
						}}
						// https://github.com/jaredpalmer/formik/issues/1869
						value={formik.values.experience ?? ''}
						onChange={(e) =>
							formik.setFieldValue(
								'experience',
								e.target.value === '' ? null : e.target.value,
							)
						}
						onBlur={formik.handleBlur}
						error={formik.touched.experience && !!formik.errors.experience}
						helperText={formik.touched.experience && formik.errors.experience}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>year(s)</InputAdornment>
							),
						}}
					/>
					<Divider sx={{ mt: 2 }} />
				</Box>
				<Box>
					<Stack spacing={1}>
						<Typography variant='body1' gutterBottom>
							Select all that apply.
						</Typography>
						<Box
							display='flex'
							justifyContent='center'
							sx={{ flexFlow: 'row wrap' }}
						>
							{generateChips()}
						</Box>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default GeneralStep;
