import React from 'react';

import { SvgIcon } from '@mui/material';

const BlobSingleLayer = ({ ...props }) => {
	return (
		<SvgIcon
			width='446'
			height='381'
			viewBox='0 0 446 381'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.1'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M170.654 74.0476C217.179 53.5373 261.13 20.1525 311.776 32.8467C366.186 46.4844 417.298 86.7771 438.139 141.092C457.654 191.955 434.552 245.472 411.324 292.291C392.678 329.875 361.566 357.278 323.378 372.406C288.797 386.104 251.809 378.764 214.648 370.201C169.881 359.886 121.793 355.003 90.8969 318.838C52.0633 273.382 13.8811 211.435 31.8632 156.244C49.7163 101.449 119.509 96.5943 170.654 74.0476Z'
				fill='#1EC86A'
			/>
			<path
				opacity='0.1'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M118.648 70.3877C159.76 40.4698 195.624 -1.48297 247.81 0.172523C303.875 1.95105 362.375 30.4773 394.268 79.1312C424.135 124.693 412.918 181.894 400.156 232.577C389.912 273.262 365.326 306.644 331.218 329.533C300.333 350.259 262.63 350.936 224.498 350.456C178.562 349.878 130.532 355.312 92.6647 326.529C45.0679 290.351 -5.39249 237.92 0.465711 180.169C6.2819 122.834 73.4539 103.276 118.648 70.3877Z'
				fill='#1EC86A'
			/>
		</SvgIcon>
	);
};

export default BlobSingleLayer;
