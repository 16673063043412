import React from 'react';

import { HelpOutline } from '@mui/icons-material';
import {
	Box,
	Grid,
	Typography,
	Divider,
	type Theme,
	type SxProps,
	useMediaQuery,
} from '@mui/material';

import Tooltip from '@ivy/components/atoms/Tooltip';
import LabeledTypography, {
	type LabeledTypographyProps,
} from '@ivy/components/molecules/LabeledTypography';
import SectionHeader from '@ivy/components/molecules/SectionHeader';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type EmraProgramFeatures_TrainingSurveyFragment } from '@ivy/gql/types/graphql';

import {
	type ProgramSectionConfig,
	type ProgramSectionItem,
	getEmraFields,
} from './emraProgramFields';

const EmraProgramFeatures_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramFeatures_TrainingSurvey on training_survey {
		id
		directorName: director_name
		coordinatorName: coordinator_name
		coordinatorEmail: coordinator_email
		createdAt: created_at
		description
		residencySurvey: residency_survey {
			id
			residency {
				id
				emrafied
				training {
					id
					name
				}
			}
			nrmpId: nrmp_id
			acgmeId: acgme_id
			programLength: program_length
			primaryTrainingSite: primary_training_site
			secondaryTrainingSite: secondary_training_site
			shiftLength: shift_length
			shiftsPerMonth: shifts_per_month
			pgy1PercentOffService: pgy_1_percent_off_service
			step1Failures: step_1_failures
			step1Cutoff: step_1_cutoff
			availableInternPositions: available_intern_positions
			percentOsteopath: percent_osteopath
			percentImg: percent_img
			moonlighting
			electiveWeeks: elective_weeks
			criticalCareWeeks: critical_care_weeks
			militaryBranch: military_branch
			visaSponsorship: visa_sponsorship
			sloesForInterview: sloes_for_interview
			usmleComlex1Preference: usmle_comlex_1_preference
			usmleComlex2Preference: usmle_comlex_2_preference
			applicationsReceived: applications_received
			inPersonSecondLooks: in_person_second_looks
			cordApicInterview: cord_apic_interview
			specialty: specialty
		}
		clerkshipSurvey: clerkship_survey {
			id
			clerkship {
				id
				training {
					id
					name
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			comlexRequired: comlex_required
			authStudentSloe: auth_student_sloe
			doStudents: do_students
			flexibleRotation: flexible_rotation
			housing
			imgStudents: img_students
			interviewPolicy: interview_policy
			rotationTypes: rotation_types
			vehicleRec: vehicle_rec
			vsloParticipant: vslo_participant
			spotAvailability1: spot_availability_1
			spotAvailability2: spot_availability_2
			spotAvailability3: spot_availability_3
			spotAvailability4: spot_availability_4
			spotAvailability5: spot_availability_5
			spotAvailability6: spot_availability_6
			spotAvailability7: spot_availability_7
			spotAvailability8: spot_availability_8
			spotAvailability9: spot_availability_9
			spotAvailability10: spot_availability_10
			spotAvailability11: spot_availability_11
			spotAvailability12: spot_availability_12
		}
		fellowshipSurvey: fellowship_survey {
			id
			fellowship {
				id
				training {
					id
					name
				}
			}
			residency {
				id
				training {
					id
					name
					slug
				}
			}
			advancedDegrees: advanced_degrees
			clinicalExp: clinical_exp
			compensation
			degreeGrantingInstitution: degree_granting_institution
			moonlightingAllowed: moonlighting_allowed
			positionsPerYear: positions_per_year
			programLength: program_length
			researchProject: research_project
			shiftsPerMonth: shifts_per_month
			subspecialty
		}
	}
`);

const UNKNOWN = 'N/A';

interface FeatureProps
	extends Omit<LabeledTypographyProps, 'label' | 'text'>,
		ProgramSectionItem {
	survey: EmraProgramFeatures_TrainingSurveyFragment;
	type: string;
}

const Feature = ({
	survey,
	getValue,
	isFeatured = () => true,
	formatter = (val) => val || UNKNOWN,
	notFeaturedText,
	...props
}: FeatureProps) => {
	const value = getValue(survey);
	const featured = isFeatured(value);

	return (
		<LabeledTypography
			text={
				featured !== null
					? formatter(value, survey)
					: notFeaturedText || UNKNOWN
			}
			textProps={{
				fontWeight: 'bold',
				whiteSpace: 'pre-wrap',
			}}
			{...props}
		/>
	);
};

interface FeatureSetProps extends ProgramSectionConfig {
	survey: EmraProgramFeatures_TrainingSurveyFragment;
	type: string;
	sx?: SxProps<Theme>;
}

const FeatureSet = React.forwardRef<HTMLDivElement, FeatureSetProps>(
	(
		{
			survey,
			type,
			section,
			display,
			view,
			fullFeature,
			tooltipContent,
			items,
			sx,
		},
		ref,
	) => {
		const viewScope = view || ((theme: Theme) => theme.breakpoints.up('xs'));
		const isViewable = useMediaQuery(viewScope);
		if (!isViewable) return null;

		const hasFeatured = items.some(
			(el) => !el.isFeatured || el.isFeatured(el.getValue(survey)),
		);

		if (display && !display(type)) {
			return null;
		}

		if (fullFeature) {
			const fullFeat = items[0];
			const value = fullFeat.getValue(survey);
			const featured = fullFeat.isFeatured ? fullFeat.isFeatured(value) : true;
			const finalValue =
				featured !== null
					? fullFeat.formatter
						? fullFeat.formatter(value, survey)
						: value
					: fullFeat.notFeaturedText || UNKNOWN;

			if (!value) {
				return null;
			}

			return (
				<Box sx={sx} ref={ref}>
					<SectionHeader
						title={section}
						noBackground
						tooltipContent={tooltipContent}
						sx={{
							h2: {
								fontWeight: 'light',
							},
						}}
					>
						<Typography
							variant='body1'
							sx={{
								color: 'text.icon',
								whiteSpace: 'pre-wrap',
							}}
							component='div'
						>
							{finalValue}
						</Typography>
					</SectionHeader>
				</Box>
			);
		}

		return (
			<Box sx={sx} ref={ref}>
				<Typography
					component='h2'
					variant='h5'
					sx={{
						fontWeight: 'light',
					}}
				>
					{section} {!hasFeatured && '(Not Available)'}
					{tooltipContent && (
						<Tooltip title={tooltipContent} placement='top'>
							<HelpOutline sx={{ fontSize: '0.9rem', ml: 0.5, mb: '-1px' }} />
						</Tooltip>
					)}
				</Typography>
				{hasFeatured && (
					// Note there is a negative 4 margin here, so we assign it to 1 instead
					<Grid container spacing={4} mt={1}>
						{items.map(({ shouldShow, label, ...props }) => {
							if (!shouldShow) {
								return (
									<Grid item key={`feature-${label}`} xs={12} md={6} lg={4}>
										<Feature
											survey={survey}
											type={type}
											label={label}
											{...props}
										/>
									</Grid>
								);
							} else {
								if (shouldShow(type)) {
									return (
										<Grid item key={`feature-${label}`} xs={12} md={6} lg={4}>
											<Feature
												survey={survey}
												type={type}
												label={label}
												{...props}
											/>
										</Grid>
									);
								}
								return null;
							}
						})}
					</Grid>
				)}
				<Divider sx={{ mt: 8 }} />
			</Box>
		);
	},
);

export interface ProgramFeaturesProps {
	survey: FragmentType<typeof EmraProgramFeatures_TrainingSurveyFDoc>;
	type: string;
}

const ProgramFeatures = ({ survey: rawSurvey, type }: ProgramFeaturesProps) => {
	const survey = getFragmentData(
		EmraProgramFeatures_TrainingSurveyFDoc,
		rawSurvey,
	);
	const emraFields = getEmraFields(type);

	return (
		<Box component='section'>
			{emraFields.map((featureSet, idx) => (
				<FeatureSet
					key={featureSet.section}
					survey={survey}
					type={type}
					{...featureSet}
					sx={
						idx
							? {
									mt: {
										xs: 6,
										lg: 10,
									},
							  }
							: undefined
					}
				/>
			))}
		</Box>
	);
};

export default ProgramFeatures;
