import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const HandDotIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M13.0254 2C11.3544 2 10 3.35439 10 5.02539C10 7.50039 15.5 11.9004 15.5 11.9004C15.5 11.9004 21 7.50039 21 5.02539C21 3.35439 19.6456 2 17.9746 2C16.3826 2 15.5 3.14844 15.5 3.14844C15.5 3.14844 14.6174 2 13.0254 2ZM13.0254 4C14.0054 4 15.3984 5.32617 15.3984 5.32617C15.3984 5.32617 16.9926 4 17.9746 4C18.5396 4 19.001 4.45986 19.002 5.00586C18.926 5.72386 17.38 7.57148 15.502 9.27148C13.643 7.58448 12.074 5.71639 12 5.02539C12 4.46039 12.4604 4 13.0254 4ZM20.5859 12C20.2239 12 19.8619 12.1381 19.5859 12.4141L15 17H11C10.724 17 10.5 16.776 10.5 16.5C10.5 16.224 10.724 16 11 16H13C14.105 16 15 15.105 15 14V13H8.65625C7.59525 13 6.57812 13.4209 5.82812 14.1719L2 18L6 22L8 20H15.1719C15.7019 20 16.2109 19.7891 16.5859 19.4141L21.5859 14.4141C22.1379 13.8621 22.1379 12.9661 21.5859 12.4141C21.3099 12.1381 20.9479 12 20.5859 12Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default HandDotIcon;
