import React from 'react';

import NavTemplate from '@ivy/components/templates/NavTemplate';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import EmraFormSection from './EmraFormSection';
import FeaturedPrograms from './FeaturedPrograms';
import FindFuture from './FindFuture';
import Hero from './Hero';
import HowCanWeHelp from './HowCanWeHelp';
import NavigateCareer from './NavigateCareer';
import Resources from './Resources';

const SECTION_Y_SPACING = {
	xs: 8,
	md: 15,
	lg: 21,
};

const EmraLanding = () => {
	const wl = useWhitelabel();

	return (
		<NavTemplate
			pageDescription={wl.productDescription}
			TopBarProps={{
				transparentUntilScroll: true,
				sx: {
					borderStyle: 'none',
				},
			}}
			showFooter
			showSupportLauncher
			pathIsCanonical
			hideBackNav
		>
			<Hero />
			<NavigateCareer
				sx={{
					py: SECTION_Y_SPACING,
				}}
			/>
			<HowCanWeHelp
				sx={{
					py: SECTION_Y_SPACING,
				}}
			/>
			<FeaturedPrograms
				sx={{
					py: SECTION_Y_SPACING,
				}}
			/>
			<FindFuture
				sx={{
					height: { sm: '50vh', xs: '90vh' },
					minHeight: '540px',
				}}
			/>
			<Resources
				sx={{
					py: SECTION_Y_SPACING,
				}}
			/>
			<EmraFormSection />
		</NavTemplate>
	);
};

export default EmraLanding;
