import React from 'react';

import { Navigate } from 'react-router-dom';

import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

const AccountTypeBoundary = ({ accountType, children }) => {
	const currAcc = useCurrentAccount();

	if (currAcc?.type !== accountType) {
		return <Navigate to={RouteUri.ROOT} replace />;
	}

	return children;
};

export default AccountTypeBoundary;
