import React from 'react';

import { Box, type SxProps, Typography, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { type AdAuctionWinner } from '@ivy/gql/types/graphql';
import { convertUriToHttpsUrl } from '@ivy/lib/formatting/url';

export interface AdBoxProps {
	winner: Pick<AdAuctionWinner, 'id' | 'asset' | 'resolvedBidId'>;
	sx?: SxProps<Theme>;
}

/**
 * Displays an image advertisement with a hyperlink.
 */
const AdBox = ({ winner, sx }: AdBoxProps) => {
	return (
		<Box sx={sx}>
			<Typography variant='caption'>Advertisement</Typography>
			<RouteLink
				to={winner.id ? convertUriToHttpsUrl(winner.id) : undefined}
				openInNewTab
			>
				<Box
					component='img'
					alt='advertisement'
					src={winner.asset[0]?.url}
					sx={{
						borderRadius: (theme) =>
							`${theme.cardShape?.borderRadius || theme.shape.borderRadius}px`,
						maxWidth: '100%',
						border: '1px solid black',
					}}
					// Used for Topsort analytics.js
					data-ts-resolved-bid={winner.resolvedBidId}
				/>
			</RouteLink>
		</Box>
	);
};

export default AdBox;
