import React from 'react';

import { createTheme, type ThemeOptions } from '@mui/material';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/reenie-beanie/400.css';
import merge from 'lodash/merge';

import type {} from '@mui/x-data-grid-premium/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { StarIcon, StarEmptyIcon } from '@ivy/components/icons';

// Use 600 as the <b> and 'bold' relative fontweight instead of including 700
// https://stackoverflow.com/questions/35127709/how-is-font-weight-rendered-when-fonts-have-not-been-loaded
// import '@fontsource/montserrat/700.css';

export const FONT_FAMILY = '"Montserrat", "Roboto", "Arial", sans-serif ';

export const theme: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			mobileMenu: 900,
			md: 900,
			gridBreak: 1125,
			lg: 1200,
			xl: 1536,
			xxl: 1920,
		},
	},
	palette: {
		primary: {
			// TODO: cleanup
			main: '#2682C5',
			light: '#3EB1EC',
			dark: '#005694',
			subtle: '#9DE3FF',
			verysubtle: '#f5fcff',
			translucent: '#E9F3F9',
			contrastText: '#fff',
		},
		secondary: {
			main: '#1B8374',
			light: '#20BB7C',
			dark: '#005649',
			subtle: '#BEEBCB',
			contrastText: '#fff',
		},
		tertiary: {
			main: '#6226C5',
			light: '#8837D7',
			dark: '#561A9C',
			contrastText: '#fff',
		},
		secondaryBadge: {
			main: '#EF5350',
			contrastText: '#fff',
		},
		info: {
			main: '#81D3FA',
			light: '#B3E5FC',
			dark: '#4FC3F7',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		success: {
			main: '#54B948',
			light: '#6DC464',
			dark: '#3D9734',
			contrastText: '#fff',
		},
		error: {
			main: '#D32F2F',
			light: '#EF5350',
			dark: '#C62828',
			subtle: '#EF9A9A',
			contrastText: '#fff',
		},
		warning: {
			main: '#ed6c02',
			light: '#ff9800',
			dark: '#e65100',
			contrastText: '#fff',
		},
		background: {
			default: '#fff',
			paper: '#fff',
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.6)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			icon: '#586069',
		},
		dark1: {
			main: '#3a3a3c',
			contrastText: '#fff',
		},
		dark2: {
			main: '#6b7588',
			contrastText: '#fff',
		},
		dark3: {
			main: '#94979E',
			contrastText: '#fff',
		},
		dark4: {
			main: '#c7c9d9',
			contrastText: '#fff',
		},
		light1: {
			main: '#dde5e9',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		light2: {
			main: '#ebebf0',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		light3: {
			main: '#f2f2f5',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		light4: {
			main: '#f9f9f9',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		// #dedede
		divider: 'rgba(0, 0, 0, 0.12)',
		whiteButton: {
			light: 'rgba(255, 255, 255, 0.6)',
			main: '#fff',
			// dark: '#dde5e9',
			dark: '#ebebf0',
			contrastText: '#000000',
		},
	},
	typography: {
		fontFamily: FONT_FAMILY,
		// fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,
	},
	cardShape: {
		borderRadius: 16,
	},
	shape: {
		borderRadius: 8,
	},
	inputShape: {
		borderRadius: 4,
	},
	buttonShape: {
		borderRadius: 999,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
                body {
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
				a[title="Report errors in the road map or imagery to Google"] {
					display: none !important;
				}
				@media only screen and (max-width: 900px) {
                    .gm-bundled-control-on-bottom {
                        bottom: 152px !important;
                    }
                }
                .crisp-client {
                	font-family: ${FONT_FAMILY} !important;
                }
			`,
		},
		MuiCard: {
			defaultProps: {
				raised: false,
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardInfo: {
					backgroundColor: '#E9F3F9',
					border: '1px solid rgba(38, 130, 197, 0.2)',
				},
				icon: {
					fontSize: '1.4em',
					color: '#2682C5 !important',
					marginRight: '8px',
				},
			},
		},
		MuiCardMedia: {
			styleOverrides: {
				root: {
					boxShadow: 'none !important',
					// borderRadius: '8px !important'
				},
			},
		},
		// MuiPaper: {
		// defaultProps: {
		// 	variant: 'outlined'
		// },
		// 	styleOverrides: {
		// 		root: {
		// 			borderRadius: '8px'
		// 		}
		// 	}
		// },
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				disableTouchRipple: true,
				focusRipple: false,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				// disableRipple: true,
				// disableFocusRipple: true
			},
			styleOverrides: {
				root: {
					// // Some egregiously large number
					borderRadius: 999,
					// boxShadow: 'none',
					// // Padding from AirBnb. For 12px font, it's 8px top/bottom, 16px left/right
					// paddingLeft: '1.33em',
					// paddingRight: '1.33em',
					// paddingTop: '0.67em',
					// paddingBottom: '0.67em',
					// textTransform: 'none',
					// maxWidth: '300px'
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: '4px !important',
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
					h6: 'h6',
					subtitle1: 'h6',
					subtitle2: 'h6',
					body1: 'p',
					body2: 'p',
					inherit: 'p',
					caption: 'p',
				},
			},
		},
		MuiDatePicker: {
			defaultProps: {
				// TODO: switch to slotProps when available
				componentsProps: {
					actionBar: {
						actions: (variant) =>
							variant === 'desktop' ? ['clear'] : ['cancel', 'clear', 'accept'],
					},
				},
			},
		},
		MuiDateTimePicker: {
			defaultProps: {
				// TODO: switch to slotProps when available
				componentsProps: {
					actionBar: {
						actions: (variant) =>
							variant === 'desktop' ? ['clear'] : ['cancel', 'clear', 'accept'],
					},
				},
			},
		},
		MuiRating: {
			defaultProps: {
				icon: React.createElement(StarIcon, { fontSize: 'inherit' }, null),
				emptyIcon: React.createElement(
					StarEmptyIcon,
					{
						fontSize: 'inherit',
						color: 'text.icon',
					},
					null,
				),
			},
			styleOverrides: {
				icon: {
					color: 'transparent',
				},
			},
		},
	},
};

const muiTheme = createTheme(theme);

muiTheme.typography = merge(muiTheme.typography, {
	h1: {
		fontSize: '2.25rem',
		lineHeight: 1.1,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '2.75rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '3.5rem',
		},
	},
	h2: {
		fontSize: '2rem',
		lineHeight: 1.1,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '2.5rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '3rem',
		},
	},
	h3: {
		fontSize: '1.75rem',
		lineHeight: 1.1,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '2rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '2.5rem',
		},
	},
	h4: {
		fontSize: '1.25rem',
		lineHeight: 1.2,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '1.5rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '2rem',
		},
	},
	h5: {
		fontSize: '1.1875rem',
		lineHeight: 1.3,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '1.25rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '1.5rem',
		},
	},
	h6: {
		fontSize: '1.125rem',
		lineHeight: 1.3,
		fontWeight: 600,
		[muiTheme.breakpoints.up('md')]: {
			fontSize: '1.1875rem',
		},
		[muiTheme.breakpoints.up('lg')]: {
			fontSize: '1.25rem',
		},
	},
	subtitle1: {
		fontSize: '1.125rem',
		lineHeight: 1.4,
		fontWeight: 500,
	},
	subtitle2: {
		fontSize: '0.875rem',
		lineHeight: 1.4,
		fontWeight: 500,
	},
	body1: {
		fontSize: '1rem',
		lineHeight: 1.4,
		fontWeight: 400,
	},
	body2: {
		fontSize: '0.875rem',
		lineHeight: 1.4,
		fontWeight: 400,
	},
	button: {
		fontWeight: 500,
		textTransform: 'none',
	},
	caption: {
		fontWeight: 400,
		fontSize: '0.75rem',
		lineHeight: 1.4,
		color: 'rgba(0, 0, 0, 0.6)',
	},
});

export { muiTheme };
