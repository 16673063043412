import { createContext } from 'react';

import { type ApolloError, type ApolloQueryResult } from '@apollo/client';

import {
	type BillingProvider_CustomerFragment,
	type BillingProvider_SubscriptionFragment,
	type BillingProvider_GetSubscriptionQuery,
} from '@ivy/gql/types/graphql';

export interface BillingValue<T> {
	data: T | null;
	subscription: BillingProvider_SubscriptionFragment | null;
	customer: BillingProvider_CustomerFragment | null;
	adminAccess: boolean;
	isOrgAdmin: boolean;
	loading: boolean;
	error?: ApolloError;
	refetch: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		variables?: Partial<{ orgId: any }> | undefined,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => Promise<ApolloQueryResult<any>>;
}

export default createContext<
	BillingValue<BillingProvider_GetSubscriptionQuery>
>(undefined!);
