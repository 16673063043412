import React from 'react';

import {
	Box,
	Pagination,
	PaginationItem,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	Profession,
	PROFESSION2PROFVERBOSE,
	PROFESSION2SLUG,
} from '@ivy/constants/clinician';
import { buildInternalLink } from '@ivy/lib/util/route';

interface RenderAltsProps {
	foundProfession: Profession;
	slug: string;
	route: string;
}

const RenderAlts = ({ foundProfession, slug, route }: RenderAltsProps) => {
	return (
		<Stack direction='column' spacing={0.5} mt={2} mb={1}>
			<Typography variant='h6' component='h4'>
				Search by other professions
			</Typography>
			{Object.entries(Profession)
				.filter(([k]) => k !== foundProfession)
				.map(([k, v]) => (
					<Typography
						key={k}
						variant='body1'
						component={RouteLink}
						underline='none'
						to={buildInternalLink(route, {
							profession: PROFESSION2SLUG[v],
							slug,
						})}
						// Keep filters and other state in case open in a new tab or append to crawler queue
						forwardQuery
						replace
					>
						By {PROFESSION2PROFVERBOSE[v]}
					</Typography>
				))}
		</Stack>
	);
};

interface ListFooterProps {
	showAlts?: boolean;
	profession: Profession;
	slug: string;
	showPagination: boolean;
	pageMax: number;
	page: number;
	baseRoute: string;
	pageRoute: string;
	sx?: SxProps<Theme>;
}

const ListFooter = ({
	showAlts,
	profession,
	slug,
	showPagination,
	pageMax,
	page,
	baseRoute,
	pageRoute,
	sx,
}: ListFooterProps) => {
	return (
		<Stack mt={2} mb={1} width='100%' sx={sx}>
			<Box
				display={!showPagination && pageMax <= 1 ? 'none' : 'flex'}
				sx={{ justifyContent: 'center' }}
			>
				<Pagination
					count={pageMax}
					page={page}
					renderItem={(item) => (
						<PaginationItem
							{...(!item.disabled
								? {
										component: RouteLink,
										to: buildInternalLink(pageRoute, {
											profession: PROFESSION2SLUG[profession],
											slug: slug,
											page: `${item.page}`,
										}),
										// Keep filters and other state in case open in a new tab or append to crawler queue
										forwardQuery: true,
										replace: true,
								  }
								: undefined)}
							{...item}
						/>
					)}
				/>
			</Box>
			{slug !== 'search' && showAlts && (
				<RenderAlts
					foundProfession={profession}
					slug={slug}
					route={baseRoute}
				/>
			)}
		</Stack>
	);
};

export default ListFooter;
