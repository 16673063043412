import React from 'react';

import {
	Box,
	Stack,
	type BoxProps,
	type SxProps,
	type Theme,
} from '@mui/material';
import { useElementSize } from 'usehooks-ts';

import { combineSx } from '@ivy/lib/styling/sx';

interface BasePageProps extends BoxProps {
	contentSx?: SxProps<Theme>;
	actionSx?: SxProps<Theme>;
	actions?: React.ReactNode;
	sx?: SxProps<Theme>;
}

const BasePage = ({
	sx,
	contentSx,
	actionSx,
	actions,
	children,
	...props
}: BasePageProps) => {
	const [ref, { height }] = useElementSize();

	return (
		<Stack
			display='flex'
			flexDirection={{ xs: 'column', md: 'row' }}
			sx={combineSx({ width: '100%', height: '100%' }, sx)}
			{...props}
		>
			<Box
				sx={combineSx(
					{
						flex: 1,
						overflowY: 'auto',
						mb: {
							xs: height ? `${height}px` : 0,
							sm: '90px',
							md: 0,
						},
					},
					contentSx,
				)}
			>
				{children}
			</Box>
			{!!actions && (
				<Box
					ref={ref}
					sx={combineSx(
						{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							width: '100%',
							borderTopWidth: '1px',
							borderTopStyle: 'solid',
							borderTopColor: 'divider',
							px: 3,
							height: { xs: 'auto', sm: '90px', md: 'auto' },
							position: 'fixed',
							bottom: 0,
							bgcolor: (theme) => theme.palette.light4.main,
						},
						actionSx,
					)}
				>
					{actions}
				</Box>
			)}
		</Stack>
	);
};

export default BasePage;
