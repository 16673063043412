import React from 'react';

import { Stack, Box, Typography } from '@mui/material';

const PLEDGE_IMG =
	'https://assets.ivyclinicians.io/images/hand-over-heart-pledge-illustration.svg';

const VerifyStep = () => {
	return (
		<Stack
			sx={{
				textAlign: 'center',
				maxWidth: '435px',
				mx: 'auto',
				mt: 3,
			}}
			spacing={2}
		>
			<Typography variant='h5'>
				Is the information you're submitting correct?
			</Typography>
			<Typography>
				By submitting this form, I confirm that I am a physician, PA, or nurse
				practitioner AND the information entered is correct.
			</Typography>
			<Box
				component='img'
				src={PLEDGE_IMG}
				sx={{
					height: '380px',
					pt: 2,
					mb: 2,
				}}
			/>
		</Stack>
	);
};

export default VerifyStep;
