import React from 'react';

import { Box, type SxProps, type Theme, Typography } from '@mui/material';

import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

import EditButton, { type EditButtonProps } from './EditButton';

const FacilityDescription_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityDescription_Facility on facility {
		id
		description
	}
`);

export interface FacilityDescriptionProps {
	facility: FragmentType<typeof FacilityDescription_FacilityFDoc>;
	onEditClick?: EditButtonProps['onClick'];
	sx?: SxProps<Theme>;
}

const FacilityDescription = ({
	facility: rawFacility,
	onEditClick,
	sx,
}: FacilityDescriptionProps) => {
	const facility = getFragmentData(
		FacilityDescription_FacilityFDoc,
		rawFacility,
	);
	const { description } = facility;
	return (
		<Box component='section' sx={sx}>
			<Box
				display='flex'
				alignItems='center'
				mb={{
					xs: 3,
					md: 5,
				}}
			>
				<Typography component='h2' variant='h5'>
					Description
				</Typography>
				{!!onEditClick && (
					<EditButton
						onClick={onEditClick}
						sx={{
							ml: 2,
						}}
					/>
				)}
			</Box>
			<Typography
				variant='body1'
				color={!description ? 'text.secondary' : undefined}
				fontStyle={!description ? 'italic' : undefined}
				whiteSpace='pre-wrap'
			>
				{description || 'No description provided.'}
			</Typography>
		</Box>
	);
};

export default FacilityDescription;
