import CheckboxListSelect from '@ivy/components/molecules/CheckboxListSelect';
import MonthAvailability from '@ivy/components/molecules/MonthAvailability';
import { YNOptions } from '@ivy/components/molecules/YNOptions';
import {
	INTERVIEW_POLICY_OPTS,
	SLOE_OPTS,
	VEHICLE_REC_OPTS,
	USMLE_COMLEX_SCORES_OPTS,
	SUBSPECIALTY_ROTATION_OPTS,
} from '@ivy/constants/filterOptions';

import {
	prepareAvalabilityFilters,
	prepareFilters,
	prepareSimpleFilter,
	prepareProgramEDVisitsFilters,
} from '../../filters/apiHelpers';
import { type FilterDataStructure } from '../../filters/useEntityFilters';
import VolumeFilterOptions from '../VolumeFilterOptions';

export const filters: FilterDataStructure[] = [
	{
		quickBtnId: 'btn-filters-vsloParticipant',
		key: 'vsloParticipant',
		title: 'Participates In VSLO',
		label: 'Participates in VSLO',
		apiResolver: prepareSimpleFilter('latest_survey.survey.vslo_participant'),
		component: YNOptions,
	},
	{
		quickBtnId: 'btn-filters-comlexRequired',
		key: 'comlexRequired',
		title: 'USMLE Step 1/COMLEX Required',
		label: 'USMLE step 1/COMLEX required',
		apiResolver: prepareFilters(
			'latest_survey.survey.comlex_required',
			USMLE_COMLEX_SCORES_OPTS,
		),
		options: USMLE_COMLEX_SCORES_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-rotationTypes',
		key: 'rotationTypes',
		title: 'Rotation Types',
		label: 'Rotation types',
		apiResolver: prepareFilters(
			'latest_survey.survey.rotation_types',
			SUBSPECIALTY_ROTATION_OPTS,
			undefined,
			true,
		),
		options: SUBSPECIALTY_ROTATION_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-edvolume',
		key: 'volume',
		label: 'ED visits',
		title: 'Annual ED Visits',
		apiResolver: prepareProgramEDVisitsFilters,
		component: VolumeFilterOptions,
		initialFilterValue: [],
	},
	{
		key: 'flexRotations',
		title: 'Flexible Rotation Dates',
		tooltipContent: 'Are rotation dates flexible?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.flexible_rotation'),
		component: YNOptions,
	},
	{
		key: 'interviewPolicy',
		title: 'Interview Policy',
		tooltipContent: 'In general, what is the interview policy?',
		apiResolver: prepareFilters(
			'latest_survey.survey.interview_policy',
			INTERVIEW_POLICY_OPTS,
		),
		options: INTERVIEW_POLICY_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'authorOfSLOE',
		title: 'Author of Student’s SLOE',
		tooltipContent: "Who writes student's SLOE?",
		apiResolver: prepareFilters(
			'latest_survey.survey.auth_student_sloe',
			SLOE_OPTS,
		),
		options: SLOE_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'considerDOstudents',
		title: 'Consider D.O. Students',
		tooltipContent: 'Are D.O. students considered?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.do_students'),
		component: YNOptions,
	},
	{
		key: 'considerIMGstudents',
		title: 'Consider IMG Students',
		tooltipContent: 'Are IMG students considered?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.img_students'),
		component: YNOptions,
	},
	{
		key: 'housing',
		title: 'Housing Availability',
		tooltipContent: 'Is there an option for hospital provided housing?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.housing'),
		component: YNOptions,
	},
	{
		key: 'vehicleRec',
		title: 'Vehicle Recommended',
		tooltipContent:
			'Is it recommended for students to have a car during their rotation?',
		apiResolver: prepareFilters(
			'latest_survey.survey.vehicle_rec',
			VEHICLE_REC_OPTS,
		),
		options: VEHICLE_REC_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'spotAvailable',
		title: 'Spot Availability Indicator',
		apiResolver: prepareAvalabilityFilters,
		component: MonthAvailability,
		initialFilterValue: [],
	},
];
