import { loadStripe } from '@stripe/stripe-js';

import config from '@ivy/config';
import { type CheckoutFlow_InfoQuery } from '@ivy/gql/types/graphql';

export const getStripePromise = () => {
	const stripeOptions = config.stripeAccountApiKey
		? { stripeAccount: config.stripeAccountApiKey }
		: {};
	const stripePromise = config.stripePublishableKey
		? loadStripe(config.stripePublishableKey, stripeOptions)
		: null;
	return stripePromise;
};

export const LEON_CALENDAR_LINK = 'https://calendly.com/leon-adelman/zoom-chat';

export enum JobPostingPriceInterval {
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
}

export enum SubscriptionTiers {
	STARTER = 'STARTER',
	PROFESSIONAL = 'PROFESSIONAL',
	ENTERPRISE = 'ENTERPRISE',
}

export enum SubscriptionStatus {
	ACTIVE = 'active',
	PAUSED = 'paused',
	PAST_DUE = 'past_due',
	INCOMPLETE = 'incomplete',
	EXPIRING = 'expiring',
	PROCESSING = 'processing',
	REQUIRES_VERIFICATION = 'requires_verification',
	UNPAID = 'unpaid',
}

export enum CheckoutModes {
	PROCESS = 'process',
	PAYMENT = 'payment',
}

export const getJobPostingValRep = (slots: number) => {
	let jobPostingValRep = slots;

	switch (jobPostingValRep) {
		case 11:
			jobPostingValRep = 15;
			break;
		case 12:
			jobPostingValRep = 20;
			break;
		case 13:
			jobPostingValRep = 30;
			break;
		case 14:
			jobPostingValRep = 40;
			break;
		case 15:
			jobPostingValRep = 50;
			break;
		case 16:
			jobPostingValRep =
				parseInt(billingSliderMarks[billingSliderMarks.length - 2].label) + 1;
			break;
		default:
			break;
	}

	return jobPostingValRep;
};

export const getSliderValue = (slots: number) => {
	let slideVal = slots;

	switch (slideVal) {
		case 15:
			slideVal = 11;
			break;
		case 20:
			slideVal = 12;
			break;
		case 30:
			slideVal = 13;
			break;
		case 40:
			slideVal = 14;
			break;
		case 50:
			slideVal = 15;
			break;
		default:
			break;
	}

	return slideVal;
};

export const resolveJobPostingPrice = (
	data: CheckoutFlow_InfoQuery['jobPostingPrices'],
	interval: keyof typeof JobPostingPriceInterval,
): CheckoutFlow_InfoQuery['jobPostingPrices'][0] | null => {
	const parsedData = data;
	for (let i = 0; i < parsedData.length; i++) {
		const price = parsedData[i];
		if (price.interval === interval) {
			return price;
		}
	}
	return null;
};

export const resolveBasePrice = (
	data: CheckoutFlow_InfoQuery['jobPostingPrices'],
): CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0] | null => {
	const priceInterval = resolveJobPostingPrice(
		data,
		JobPostingPriceInterval.MONTHLY,
	);
	if (!priceInterval) return null;

	const price = priceInterval.tiers.find((tier) => tier?.limit === 1);
	if (price) return price;
	return null;
};

export const calculateDiscountedPrice = (
	prices: CheckoutFlow_InfoQuery['jobPostingPrices'][0],
	slots: number,
	interval: JobPostingPriceInterval,
) => {
	let finalTier:
		| CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0]
		| null = null;
	const jobPostingValRep = slots;
	if (!prices || !jobPostingValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostingValRep <= tier.limit) {
			return interval === JobPostingPriceInterval.MONTHLY
				? tier.amount
				: tier.amount / jobPostingValRep / 12;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}
	if (finalTier) {
		return finalTier.amount;
	}

	return 0;
};

export const calculateDiscountAmount = (
	prices: CheckoutFlow_InfoQuery['jobPostingPrices'][0],
	slots: number,
	interval: JobPostingPriceInterval,
) => {
	let finalTier:
		| CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0]
		| null = null;
	const jobPostingValRep = slots;
	if (!prices || !jobPostingValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostingValRep <= tier.limit) {
			return interval === JobPostingPriceInterval.MONTHLY
				? jobPostingValRep * tier.amount
				: tier.amount;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}
	if (finalTier) {
		return jobPostingValRep * finalTier.amount;
	}

	return 0;
};

export const calculateRegularAmount = (
	prices: CheckoutFlow_InfoQuery['jobPostingPrices'][0],
	basePrice: CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0],
	slots: number,
	interval: JobPostingPriceInterval,
) => {
	let finalTier:
		| CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0]
		| null = null;
	const jobPostingValRep = slots;
	if (!prices || !basePrice || !jobPostingValRep) return 0;

	for (let i = 0; i < prices.tiers.length; i++) {
		const tier = prices.tiers[i];
		if (tier.limit !== null && jobPostingValRep <= tier.limit) {
			return interval === JobPostingPriceInterval.MONTHLY
				? jobPostingValRep * basePrice.amount
				: basePrice.amount * 12 * jobPostingValRep;
		} else if (tier.limit === null) {
			finalTier = tier;
		}
	}

	if (finalTier) {
		return interval === JobPostingPriceInterval.MONTHLY
			? jobPostingValRep * basePrice.amount
			: basePrice.amount * 12 * jobPostingValRep;
	}

	return 0;
};

export const calculateDiscountRate = (
	price: CheckoutFlow_InfoQuery['jobPostingPrices'][0],
	basePrice: CheckoutFlow_InfoQuery['jobPostingPrices'][0]['tiers'][0],
	slots: number,
	interval: JobPostingPriceInterval,
) => {
	const regularAmount = calculateRegularAmount(
		price,
		basePrice,
		slots,
		interval,
	);
	const discountAmount = calculateDiscountAmount(price, slots, interval);
	const percentageDifference =
		((regularAmount - discountAmount) / regularAmount) * 100;
	return percentageDifference.toFixed(1);
};

export const resolveSubscriptionStatus = (
	subscriptionStatus: string,
	paymentStatus?: string | null,
	cancelAt?: number | null,
) => {
	let currStatus = subscriptionStatus as SubscriptionStatus;

	if (currStatus === SubscriptionStatus.ACTIVE && cancelAt) {
		currStatus = SubscriptionStatus.EXPIRING;
	} else if (
		[SubscriptionStatus.PAST_DUE, SubscriptionStatus.INCOMPLETE].includes(
			currStatus,
		) &&
		paymentStatus === 'requires_action'
	) {
		currStatus = SubscriptionStatus.REQUIRES_VERIFICATION;
	} else if (
		[SubscriptionStatus.ACTIVE, SubscriptionStatus.PAST_DUE].includes(
			currStatus,
		) &&
		paymentStatus === 'processing'
	) {
		currStatus = SubscriptionStatus.PROCESSING;
	}

	return currStatus;
};

export const billingSliderMarks = [
	{
		value: 1,
		label: '1',
	},
	{
		value: 2,
		label: '2',
	},
	{
		value: 3,
		label: '3',
	},
	{
		value: 4,
		label: '4',
	},
	{
		value: 5,
		label: '5',
	},
	{
		value: 6,
		label: '6',
	},
	{
		value: 7,
		label: '7',
	},
	{
		value: 8,
		label: '8',
	},
	{
		value: 9,
		label: '9',
	},
	{
		value: 10,
		label: '10',
	},
	{
		value: 11,
		label: '15',
	},
	{
		value: 12,
		label: '20',
	},
	{
		value: 13,
		label: '30',
	},
	{
		value: 14,
		label: '40',
	},
	{
		value: 15,
		label: '50',
	},
	{
		value: 16,
		label: '51+',
	},
];
