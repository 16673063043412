import React from 'react';

import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Checkbox,
} from '@mui/material';

export const COLUMNS = Object.freeze({
	FACILITY: 'facility',
	PROFESSION: 'professions',
	TITLE: 'title',
	RECRUITER: 'recruiter',
	PUBLISHED_DATE: 'publishedAt',
	EXPIRATION_DATE: 'expirationDate',
	IMPRESSIONS: 'impressions',
});

const COLUMN_ITEMS = Object.freeze({
	[COLUMNS.FACILITY]: 'Facility',
	[COLUMNS.PROFESSION]: 'Profession',
	[COLUMNS.TITLE]: 'Title',
	[COLUMNS.PUBLISHED_DATE]: 'Published on',
	[COLUMNS.RECRUITER]: 'Recruiter',
	[COLUMNS.EXPIRATION_DATE]: 'Expires on',
	[COLUMNS.IMPRESSIONS]: 'Impressions',
});

interface ColumnMenuProps {
	value: { [key: string]: boolean };
	onChange: (val: { [key: string]: boolean }) => void;
}

// TODO add toast warnings when table overflows
const ColumnMenu = ({ value, onChange }: ColumnMenuProps) => (
	<List sx={{ p: 0 }}>
		{Object.entries(COLUMN_ITEMS).map(([k, v], idx, self) => (
			<ListItem
				button
				key={k}
				onClick={() => {
					onChange({ [k]: !value[k] });
				}}
				sx={(theme) => ({
					pl: 0,
					py: 1,
					borderBottom:
						idx !== self.length - 1
							? `1px solid ${theme.palette.divider}`
							: 'none',
				})}
			>
				<ListItemIcon>
					<Checkbox checked={value[k]} />
				</ListItemIcon>
				<ListItemText primary={v} />
			</ListItem>
		))}
	</List>
);

export default ColumnMenu;
