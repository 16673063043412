import { type Step } from 'react-joyride';
export const clickElement = (selector) => {
	const element = document.querySelector(selector);
	if (element) {
		element.click();
	} else {
		console.error(`Element not found for selector: ${selector}`);
	}
};

export const resolveTargetSelector = (target: Step['target']) => {
	let selector = '';
	if (typeof target === 'string') {
		selector = target;
	} else if (target instanceof HTMLElement) {
		selector = target.tagName.toLowerCase();
	}
	return selector;
};
