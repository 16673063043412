import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Card,
	CardContent,
	Chip,
	CardHeader,
	IconButton,
	Typography,
	type Theme,
	useMediaQuery,
	Stack,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';

interface ReviewsPromoProps {
	banner: boolean;
	onClose: () => void;
}

const ReviewsPromo = ({ banner, onClose }: ReviewsPromoProps) => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	return (
		<>
			<Card
				sx={{
					bgcolor: 'white',
					borderRadius: (theme) =>
						`${theme.cardShape?.borderRadius || theme.shape.borderRadius}px`,
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					border: '1px solid #CDCFD2',
					width: banner ? 'auto' : '484px',
					mx: banner ? { mobileMenu: 3, xs: 2 } : 0,
				}}
			>
				<CardHeader
					avatar={
						<Chip
							label='NEW'
							sx={(theme) => ({
								bgcolor: theme.palette.tertiary.main,
								fontWeight: 'bold',
								color: '#fff',
							})}
						/>
					}
					action={
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					}
					title={
						<Typography variant='body1' fontWeight='bold' color='text.icon'>
							Ivy Clinicians Announcement
						</Typography>
					}
					sx={{
						display: banner ? 'none' : 'flex',
						px: { mobileMenu: 3, xs: 2 },
						borderBottom: '1px solid #CDCFD2',
					}}
				/>
				<CardContent sx={{ position: 'relative', p: { mobileMenu: 3, xs: 2 } }}>
					<Stack
						direction='row'
						sx={{
							mb: 2,
							mr: 5,
						}}
						justifyContent={banner ? 'flex-start' : 'space-between'}
						alignItems='center'
					>
						<Typography variant='h6' component='h1'>
							Limited Opportunity: Invest in Ivy Clinicians!
						</Typography>
					</Stack>
					<Typography variant={isMobile ? 'body2' : 'body1'} color='text.icon'>
						Interested in improving the Emergency Medicine job market through
						transparency? You are invited to join leading emergency physicians
						from across the US & invest in Ivy. For more info, email{' '}
						<b>Leon Adelman, MD, MBA,</b> at{' '}
						<RouteLink
							to='mailto:leon@ivyclinicians.io'
							sx={(theme) => ({
								color: theme.palette.primary.main,
								textDecorationColor: 'rgba(38, 130, 197, 0.4)',
								cursor: 'pointer',
								fontWeight: 'bold',
								textDecoration: 'none',
								'&:hover': {
									textDecoration: 'underline',
								},
							})}
						>
							leon@ivyclinicians.io
						</RouteLink>
					</Typography>
					<IconButton
						onClick={onClose}
						sx={{
							position: 'absolute',
							top: 8,
							right: 8,
							display: banner ? 'inline-flex' : 'none',
							mb: 'auto',
						}}
					>
						<CloseIcon />
					</IconButton>
				</CardContent>
			</Card>
		</>
	);
};

export default ReviewsPromo;
