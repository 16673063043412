import React, { useState, useCallback, useRef } from 'react';

import {
	Person,
	ThumbUp,
	ThumbDown,
	MoreVert,
	DeleteOutline,
	Block,
	ArrowDropDown,
	ArrowDropUp,
} from '@mui/icons-material';
import {
	Box,
	type Theme,
	useMediaQuery,
	type SxProps,
	Card,
	IconButton,
	CardContent,
	Divider,
	Stack,
	Button,
	Typography,
	Chip,
	type ChipProps,
	Rating,
	Menu,
	Collapse,
	MenuItem,
} from '@mui/material';
import { differenceInDays, formatDistanceToNow, parseJSON } from 'date-fns';
import Shiitake from 'shiitake';

import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	PinIcon,
	ReplyIcon,
	StarIcon,
	ThumbsUpIcon,
} from '@ivy/components/icons';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type ReviewCard_ReviewFragment } from '@ivy/gql/types/graphql';
import { formatInteger } from '@ivy/lib/formatting/number';
import { capitalize } from '@ivy/lib/formatting/string';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

import ReplyForm, { type ReplyFormValues } from './ReplyForm';

export const ReviewCard_ReviewFDoc = gql(/* GraphQL */ `
	fragment ReviewCard_Review on review {
		id
		active
		visible
		createdAt: created_at
		currentlyWorking: currently_working
		ratingFacility: rating_facility
		ratingJob: rating_job
		ratingOrg: rating_org
		mostHelpful: most_helpful
		lastWorkedDate: last_worked_date
		employmentDate: employment_date
		selfReview: self_review
		hasActiveContract: has_active_contract
		reporterName: reporter_name
		facilityContent: review_facility_content
		orgContent: review_org_content
		facility {
			id
			slug
			name
		}
		org: organization {
			id
			name
			slug
		}
		replies(
			where: {
				_and: [
					{ active: { _eq: true } }
					{
						_or: [
							{ visible: { _eq: true } }
							{ _and: [{ visible: { _eq: false }, self_reply: { _eq: true } }] }
						]
					}
				]
			}
			order_by: [{ created_at: asc }]
		) {
			id
			createdAt: created_at
			isOrgResponse: is_org_response
			reporterName: reporter_name
			selfReply: self_reply
			content
		}
		votes(where: { account_id: { _eq: $accountId } })
			@include(if: $isClinician) {
			id
			value
		}
		votes_agg: votes_aggregate {
			aggregate {
				count
				sum {
					value
				}
			}
		}
		replies_agg: replies_aggregate(
			where: {
				_and: [
					{ active: { _eq: true } }
					{
						_or: [
							{ visible: { _eq: true } }
							{ _and: [{ visible: { _eq: false }, self_reply: { _eq: true } }] }
						]
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
	}
`);

interface RenderTagProps {
	self?: boolean | null;
	helpful?: boolean | null;
	employerResponse?: boolean | null;
	sx?: SxProps<Theme>;
}

const RenderTag = ({ self, helpful, employerResponse, sx }: RenderTagProps) => {
	if (helpful || self || employerResponse) {
		const tagProps = {
			label: '',
			icon: (
				<Person
					sx={{
						color: 'inherit',
					}}
				/>
			),
		} as ChipProps;

		if (self) {
			tagProps.label = 'You wrote this';
			tagProps.icon = (
				<Person
					sx={{
						color: 'inherit',
					}}
				/>
			);
		} else if (helpful) {
			tagProps.label = 'Most helpful';
			tagProps.icon = (
				<ThumbsUpIcon
					sx={{
						color: 'inherit',
					}}
				/>
			); // TODO change icon to
		} else if (employerResponse) {
			tagProps.label = 'Employer response';
			tagProps.icon = (
				<PinIcon
					sx={{
						color: 'inherit',
					}}
				/>
			);
		}

		return (
			<Chip
				{...tagProps}
				size='small'
				sx={combineSx(
					{
						p: 0.5,
						color: 'text.icon',
						fontWeight: 'bold',
					},
					sx,
				)}
			/>
		);
	}

	return null;
};

const RenderVoteCount = ({
	agg,
}: {
	agg: ReviewCard_ReviewFragment['votes_agg'];
}) => {
	const sum = agg!.aggregate!.sum!.value || 0;
	const color =
		sum === 0 ? 'text.icon' : sum > 0 ? 'primary.main' : 'error.main';

	return (
		<Typography
			variant='caption'
			color={color}
			fontWeight={sum === 0 ? 'normal' : 'bold'}
		>
			{formatInteger(sum)}
		</Typography>
	);
};

const RenderDate = ({ date }: { date: string }) => {
	const daysAgo = differenceInDays(new Date(), parseJSON(date));
	const difference = capitalize(
		formatDistanceToNow(parseJSON(date), {
			addSuffix: true,
		})
			.replace('about', '')
			.replace('almost', ''),
	);

	return (
		<Typography variant='caption' color='text.icon'>
			{daysAgo === 0 ? 'Today' : difference}
		</Typography>
	);
};

interface CardMenuProps {
	disabled?: boolean;
	type: string;
	sx?: SxProps<Theme>;
	onDelete?: () => void;
	onReport?: () => void;
}

const CardMenu = ({
	disabled,
	type,
	sx,
	onDelete,
	onReport,
}: CardMenuProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={combineSx({ p: 0.5 }, sx)}
				disabled={disabled}
			>
				<MoreVert fontSize='small' />
			</IconButton>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
					horizontal: 'right',
					vertical: 'bottom',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{onDelete && (
					<MenuItem onClick={onDelete} disabled={disabled}>
						<Box sx={{ maxWidth: '145px' }}>
							<Typography
								variant='body2'
								fontWeight='bold'
								color='primary.main'
							>
								<DeleteOutline sx={{ fontSize: '16px', mb: '-3px' }} /> Delete{' '}
								{type}
							</Typography>
							<Typography variant='caption' whiteSpace='pre-wrap'>
								Your {type} will be removed from the platform
							</Typography>
						</Box>
					</MenuItem>
				)}
				{onReport && (
					<MenuItem onClick={onReport} disabled={disabled}>
						<Box sx={{ maxWidth: '145px' }}>
							<Typography variant='body2' fontWeight='bold' color='text.icon'>
								<Block
									sx={{ fontSize: '16px', mb: '-3px', color: 'primary.main' }}
								/>{' '}
								Report {type}
							</Typography>
							<Typography variant='caption' whiteSpace='pre-wrap'>
								We won’t let them know you reported this
							</Typography>
						</Box>
					</MenuItem>
				)}
			</Menu>
		</>
	);
};

interface ReplyProps {
	reply: ReviewCard_ReviewFragment['replies'][0];
	disabled?: boolean;
	onDelete?: () => void;
	onReport?: () => void;
}

const Reply = ({ reply, disabled, onDelete, onReport }: ReplyProps) => {
	const { createdAt, reporterName, selfReply, isOrgResponse, content } = reply;
	return (
		<Stack spacing={2}>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				alignItems={{ md: 'center', xs: 'inherit' }}
				spacing={{ md: 2, xs: 1 }}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: { md: 'row', xs: 'column-reverse' },
						alignItems: { md: 'center', xs: 'inherit' },
					}}
				>
					<Typography variant='caption' color='text.icon' fontWeight='bold'>
						{reporterName}
					</Typography>
					<Stack
						direction='row'
						sx={{
							mb: { md: 0, xs: 1 },
						}}
					>
						<RenderTag
							self={selfReply}
							employerResponse={isOrgResponse}
							sx={{ mr: 'auto', ml: { md: 2, xs: 0 } }}
						/>
						{(!!onDelete || !!onReport) && (
							<CardMenu
								sx={{
									display: { md: 'none', xs: 'inline-flex' },
									ml: 'auto !important',
								}}
								type='reply'
								disabled={disabled}
								onDelete={onDelete}
								onReport={onReport}
							/>
						)}
					</Stack>
				</Box>
				<RenderDate date={createdAt} />
				{(!!onDelete || !!onReport) && (
					<CardMenu
						sx={{
							display: { md: 'inline-flex', xs: 'none' },
							ml: 'auto !important',
						}}
						type='reply'
						disabled={disabled}
						onDelete={onDelete}
						onReport={onReport}
					/>
				)}
			</Stack>
			<Typography
				variant='body1'
				sx={{
					color: 'text.icon',
					whiteSpace: 'pre-wrap',
				}}
			>
				{content}
			</Typography>
		</Stack>
	);
};

interface ReviewCardProps {
	review: FragmentType<typeof ReviewCard_ReviewFDoc>;
	viewOnly?: boolean;
	hideFacility?: boolean;
	defaultHideComments?: boolean;
	restrictReply?: boolean;
	minimize?: boolean;
	sx?: SxProps<Theme>;
	disabled?: boolean;
	onDelete?: () => void;
	onReport?: () => void;
	onVote?: (id: string, value: number) => void;
	onDeleteVote?: (id: string) => void;
	onSubmitReply?: (vals: ReplyFormValues) => void;
	onDeleteReply?: (id: string) => void;
	onReportReply?: (id: string) => void;
}

const ReviewCard = ({
	review: rawReview,
	viewOnly,
	hideFacility,
	defaultHideComments = true,
	restrictReply,
	minimize,
	sx,
	disabled,
	onDelete,
	onReport,
	onVote,
	onDeleteVote,
	onSubmitReply,
	onDeleteReply,
	onReportReply,
}: ReviewCardProps) => {
	const review = getFragmentData(ReviewCard_ReviewFDoc, rawReview);
	const {
		visible,
		employmentDate,
		mostHelpful,
		ratingJob,
		reporterName,
		hasActiveContract,
		selfReview,
		replies,
		votes,
		votes_agg,
		replies_agg,
	} = review;
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	const [hide, setHide] = useState(
		minimize || hideFacility || (isMobile && !viewOnly),
	);
	const [hideComments, setHideComments] = useState(defaultHideComments);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { openSignupPopup } = useAuthPopup();
	const currAcc = useCurrentAccount();
	const selfVote = votes && votes?.length > 0 ? votes[0] : null;
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;

	const handleClickHide = useCallback(() => {
		setHide((prev) => !prev);
	}, [setHide]);

	const handleClickHideComments = useCallback(() => {
		setHideComments((prev) => !prev);
	}, [setHideComments]);

	const handleClickReply = useCallback(() => {
		setHideComments(false);
		setTimeout(() => focusInput(), 300);
	}, [setHideComments]);

	const focusInput = () => {
		if (inputRef.current) inputRef.current.focus();
	};

	const handleVoteClick = useCallback(
		(value: number) => {
			if (currAcc) {
				if (selfVote && selfVote.value === value) {
					onDeleteVote && onDeleteVote(selfVote.id);
				} else {
					onVote && onVote(review.id, value);
				}
			} else {
				openSignupPopup(true);
			}
		},
		[currAcc, openSignupPopup, onVote, onDeleteVote, review, selfVote],
	);

	const disableVoting = !!selfReview || !isClinicianOrAnon;

	return (
		<Card
			sx={combineSx(
				{
					width: '100%',
					bgcolor: 'white',
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				},
				sx,
			)}
		>
			<CardContent sx={{ p: { md: 3, xs: 2 } }}>
				<Stack>
					<Stack
						direction={{ md: 'row', xs: 'column' }}
						alignItems={{ md: 'center', xs: 'inherit' }}
						spacing={{ md: 2, xs: 1 }}
					>
						<Stack
							direction='row'
							spacing={2}
							sx={{ mb: { md: 0, xs: 1 } }}
							alignItems='center'
							justifyContent='space-between'
						>
							<Stack direction='row' spacing={2}>
								{ratingJob && (
									<Stack direction='row' alignItems='center'>
										<Typography
											variant='body2'
											color='text.icon'
											fontWeight='bold'
											sx={{
												mr: 1,
											}}
										>
											Overall rating
										</Typography>
										<StarIcon
											sx={{
												mr: 1,
												fontSize: '18px',
											}}
										/>{' '}
										<Typography
											variant='body2'
											color='text.icon'
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{ratingJob}
										</Typography>
									</Stack>
								)}
								<RenderTag self={selfReview} helpful={mostHelpful} />
							</Stack>
							{(!!onDelete || !!onReport) && (
								<CardMenu
									sx={{
										display: viewOnly
											? 'none'
											: { md: 'none', xs: 'inline-flex' },
									}}
									type='review'
									disabled={disabled}
									onDelete={onDelete}
									onReport={onReport}
								/>
							)}
						</Stack>
						<Divider
							orientation='vertical'
							variant='middle'
							flexItem
							sx={{ display: { md: 'block', xs: 'none' } }}
						/>
						<Typography variant='caption' color='text.icon' fontWeight='bold'>
							{reporterName}
						</Typography>
						<Divider
							orientation='vertical'
							variant='middle'
							flexItem
							sx={{ display: { md: 'block', xs: 'none' } }}
						/>
						<RenderDate date={employmentDate} />
					</Stack>
					<Divider flexItem sx={{ mt: 3, mb: { md: 5, xs: 3 } }} />
					<Stack>
						<Stack spacing={3}>
							<Box>
								<Stack
									direction={{ md: 'row', xs: 'column' }}
									spacing={{ md: 2, xs: 1 }}
								>
									<Typography
										variant='body1'
										color='text.icon'
										fontWeight='bold'
									>
										<RouteLink
											to={buildInternalLink(RouteLink.routes.ORG_SHOW, {
												orgId: [review.org.id, review.org.slug],
											})}
											underline='hover'
											color='inherit'
										>
											{review.org.name}
										</RouteLink>
									</Typography>
									<Stack direction='row' alignItems='center' spacing={1}>
										<Rating
											size='small'
											defaultValue={review.ratingOrg}
											readOnly
											sx={{ fontSize: '18px' }}
										/>
										<Typography
											variant='body2'
											color='text.icon'
											fontWeight='bold'
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{review.ratingOrg}
										</Typography>
									</Stack>
								</Stack>
								{hideFacility && (
									<Typography
										variant='caption'
										color='text.icon'
										sx={{ mt: 0.5 }}
									>
										<RouteLink
											to={buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
												facilityId: [review.facility.id, review.facility.slug],
											})}
											underline='hover'
											color='inherit'
										>
											{review.facility.name}
										</RouteLink>
									</Typography>
								)}
								{!viewOnly && !hasActiveContract && visible && (
									<Chip
										label='No longer staffs this facility'
										sx={{
											height: 'auto',
											fontSize: '12px',
											p: 0.5,
											bgcolor: '#C526264D',
											mt: 0.5,
											color: 'text.icon',
										}}
									/>
								)}
							</Box>
							<Typography
								variant='body1'
								component='div'
								color='text.icon'
								sx={{
									whiteSpace: 'pre-wrap',
								}}
							>
								{hide ? (
									<Shiitake lines={isMobile ? 8 : 4} throttleRate={200}>
										{review.orgContent}
									</Shiitake>
								) : (
									review.orgContent
								)}
							</Typography>
						</Stack>
						{!hide && (
							<Stack
								spacing={3}
								sx={{
									mt: 5,
									display: 'flex',
									pl: hideFacility ? { md: 3, xs: 2 } : 0,
									borderLeft: hideFacility ? '1px solid #CDCFD2' : '',
								}}
							>
								{hideFacility && (
									<Chip
										label='This job review also contains a section on the facility:'
										sx={(theme) => ({
											mr: 'auto',
											height: 'auto',
											bgcolor: 'light4.main',
											color: 'text.icon',
											p: 1,
											borderRadius: `${theme.shape.borderRadius}px`,
											whiteSpace: 'normal',
											span: {
												whiteSpace: 'inherit',
											},
										})}
									/>
								)}
								<Stack
									direction={{ md: 'row', xs: 'column' }}
									spacing={{ md: 2, xs: 1 }}
								>
									<Typography
										variant='body1'
										color='text.icon'
										fontWeight='bold'
									>
										<RouteLink
											to={buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
												facilityId: review.facility.id,
											})}
											underline='hover'
											color='inherit'
										>
											{review.facility.name}
										</RouteLink>
									</Typography>
									<Stack direction='row' alignItems='center' spacing={1}>
										<Rating
											size='small'
											defaultValue={review.ratingFacility}
											readOnly
											sx={{ fontSize: '18px' }}
										/>
										<Typography
											variant='body2'
											color='text.icon'
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{review.ratingFacility}
										</Typography>
									</Stack>
								</Stack>
								<Typography
									variant='body1'
									color='text.icon'
									sx={{
										whiteSpace: 'pre-wrap',
									}}
								>
									{review.facilityContent}
								</Typography>
							</Stack>
						)}
						<Box sx={{ mt: 5 }}>
							<Button
								endIcon={hide ? <ArrowDropDown /> : <ArrowDropUp />}
								onClick={handleClickHide}
							>
								<Typography
									variant='caption'
									fontWeight='bold'
									color='primary.main'
								>
									{hide ? 'Read' : 'Hide'} the full job review
								</Typography>
							</Button>
							<Divider
								flexItem
								sx={{ mt: 1, mb: 3, display: viewOnly ? 'none' : 'block' }}
							/>
						</Box>
					</Stack>
					{!viewOnly && (
						<Stack>
							<Stack
								direction={{ md: 'row', xs: 'column-reverse' }}
								alignItems={{ md: 'flex-end', xs: 'flex-start' }}
								justifyContent={{ md: 'space-between' }}
							>
								<Stack spacing={1} direction='row'>
									<Button
										endIcon={hideComments ? <ArrowDropDown /> : <ArrowDropUp />}
										onClick={handleClickHideComments}
									>
										<Typography
											variant='caption'
											fontWeight='bold'
											color='primary.main'
										>
											{replies_agg?.aggregate?.count || 0} comments
										</Typography>
									</Button>
									{!!onSubmitReply && (isClinicianOrAnon || !restrictReply) && (
										<Button
											startIcon={
												<ReplyIcon
													sx={{
														height: '16px',
														width: '16px',
													}}
												/>
											}
											onClick={handleClickReply}
										>
											<Typography
												variant='caption'
												fontWeight='bold'
												color='primary.main'
											>
												Reply
											</Typography>
										</Button>
									)}
								</Stack>
								<Stack direction='row' spacing={5}>
									<Stack spacing={2} direction='row' alignItems='center'>
										<Typography variant='caption' color='text.icon'>
											Was this helpful?
										</Typography>
										<IconButton
											sx={{
												p: 0.5,
												opacity: disableVoting || disabled ? 0.3 : 1,
											}}
											disabled={disableVoting || disabled}
											onClick={() => handleVoteClick(1)}
										>
											<ThumbUp
												fontSize='small'
												color={
													selfVote && selfVote.value === 1
														? 'primary'
														: 'disabled'
												}
											/>
										</IconButton>
										{(votes_agg?.aggregate?.count || 0) > 0 && (
											<RenderVoteCount agg={votes_agg} />
										)}
										<IconButton
											sx={{
												p: 0.5,
												opacity: disableVoting || disabled ? 0.3 : 1,
											}}
											disabled={disableVoting || disabled}
											onClick={() => handleVoteClick(-1)}
										>
											<ThumbDown
												fontSize='small'
												color={
													selfVote && selfVote.value === -1
														? 'primary'
														: 'disabled'
												}
											/>
										</IconButton>
									</Stack>
									{(!!onDelete || !!onReport) && (
										<CardMenu
											sx={{
												display: viewOnly
													? 'none'
													: { md: 'inline-flex', xs: 'none' },
											}}
											type='review'
											disabled={disabled}
											onDelete={onDelete}
											onReport={onReport}
										/>
									)}
								</Stack>
							</Stack>
						</Stack>
					)}
				</Stack>
				<Collapse in={!hideComments}>
					{(replies?.length || 0) > 0 && (
						<Stack
							spacing={{ md: 8, xs: 4 }}
							sx={{
								pl: { md: 3, xs: 2 },
								borderLeft: '1px solid #CDCFD2',
								mt: { md: 8, xs: 4 },
							}}
						>
							{replies?.map((reply) => (
								<Stack key={reply.id} spacing={3}>
									<Reply
										reply={reply}
										disabled={disabled}
										onDelete={
											reply.selfReply && onDeleteReply
												? () => onDeleteReply(reply.id)
												: undefined
										}
										onReport={
											!reply.selfReply && onReportReply
												? () => onReportReply(reply.id)
												: undefined
										}
									/>
									<Divider
										flexItem
										sx={{ alignSelf: 'auto', height: '100%' }}
									/>
								</Stack>
							))}
						</Stack>
					)}
					{onSubmitReply && (
						<Stack
							sx={{
								mt: { md: 8, xs: 4 },
							}}
						>
							<ReplyForm
								inputRef={inputRef}
								onSubmit={onSubmitReply}
								restrict={restrictReply}
								disabled={disabled}
							/>
						</Stack>
					)}
				</Collapse>
			</CardContent>
		</Card>
	);
};

export default ReviewCard;
export { type ReviewCardProps };
