import React, { useEffect, useState } from 'react';

import {
	Box,
	Grid,
	Skeleton,
	Typography,
	type Theme,
	useMediaQuery,
} from '@mui/material';

import StepPagination from '@ivy/components/molecules/StepPagination';
import MapCard from '@ivy/components/organisms/MapCard';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { MILE_TO_METERS } from '@ivy/constants/location';

import { type FeaturedCard } from './apiHelpers';

const TrainingSkeleton = () => {
	return (
		<Box
			sx={{
				boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				borderRadius: (theme) =>
					`${(theme.cardShape || theme.shape).borderRadius}px`,
				height: '100%',
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='100%'
				height={175}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='100%'
				height={25}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
			<Skeleton
				variant='rectangular'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mb: 1,
				}}
				width='60%'
				height={16}
			/>
		</Box>
	);
};

export interface TrainingFeaturedProps {
	rankedTrainings?: FeaturedCard[];
	loading?: boolean;
	hideDistance?: boolean;
}

const TrainingFeatured = ({
	rankedTrainings,
	loading = false,
	hideDistance = false,
}: TrainingFeaturedProps) => {
	const redirect = useRedirect();
	const [page, setPage] = useState(0);
	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
		noSsr: true,
	});
	const isMd = useMediaQuery(
		(theme: Theme) => theme.breakpoints.between('sm', 'md'),
		{
			noSsr: true,
		},
	);
	const pageSize = isSm ? 1 : isMd ? 2 : 3;

	useEffect(() => {
		// Reset the page every time page size changes or if we reload data to avoid going beyond the bounds
		setPage(0);
	}, [pageSize, rankedTrainings]);

	return (
		<Box display={!loading && !rankedTrainings?.length ? 'none' : undefined}>
			<Grid
				container
				columnSpacing={5}
				sx={{
					height: '475px',
					mb: 3,
				}}
			>
				{loading
					? [...Array(pageSize).keys()].map((el) => (
							<Grid item key={`suggestion-skeleton-${el}`} xs={12 / pageSize}>
								<TrainingSkeleton key={`suggestion-skeleton-${el}`} />
							</Grid>
					  ))
					: rankedTrainings
							?.slice(page * pageSize, page * pageSize + pageSize)
							.map((el) => (
								<Grid item key={el.id} xs={12 / pageSize}>
									<MapCard
										sx={{
											boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
											borderRadius: (theme) =>
												`${(theme.cardShape || theme.shape).borderRadius}px`,
											height: '100%',
										}}
										entity={el}
										orientation='vertical'
										onClick={() => {
											if (!el.pathname) {
												return;
											}
											redirect(el.pathname, {
												state: {
													backNav: {
														target: 'home',
													},
												},
											});
										}}
									/>
									{!hideDistance && (
										<Typography variant='caption' fontWeight='bold' ml={1}>
											{el.distance / MILE_TO_METERS < 1
												? Math.round((el.distance * 10) / MILE_TO_METERS) / 10
												: Math.round(el.distance / MILE_TO_METERS)}{' '}
											mi. away
										</Typography>
									)}
								</Grid>
							))}
			</Grid>
			<Box display='flex' justifyContent='flex-end'>
				<StepPagination
					count={rankedTrainings?.length || 0}
					pageSize={pageSize}
					page={page}
					onChangePage={setPage}
				/>
			</Box>
		</Box>
	);
};

export default TrainingFeatured;
