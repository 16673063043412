import React from 'react';

import { Box, Typography, type SxProps, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { ArrowRightIcon } from '@ivy/components/icons';
import { RouteUri } from '@ivy/constants/routes';
import { combineSx } from '@ivy/lib/styling/sx';

export interface ExplainerCtaProps {
	sx?: SxProps<Theme>;
}

const ExplainerCta = ({ sx }: ExplainerCtaProps) => {
	return (
		<Box
			sx={combineSx(
				{
					borderRadius: (theme) =>
						`${theme.cardShape?.borderRadius || theme.shape.borderRadius}px`,
					bgcolor: 'primary.translucent',
					position: 'relative',
					overflow: 'hidden',
					p: {
						xs: 3,
						sm: 5,
					},
				},
				sx,
			)}
		>
			<Box
				component='img'
				src='https://assets.ivyclinicians.io/images/intro-cta-background.png'
				sx={{
					height: '135%',
					position: 'absolute',
					bottom: 0,
					left: '60%',
					zIndex: 1,
				}}
			/>
			<Box width='60%'>
				<Typography variant='body1' color='text.icon' mb={2}>
					Ivy Clinicians is emergency medicine's job board for{' '}
					<b>physicians, nurse practitioners, and physician assistants.</b>
				</Typography>
				<Typography variant='body1'>
					<RouteLink fontWeight='bold' to={RouteUri.ROOT} underline='hover'>
						<Box component='span' sx={{ verticalAlign: 'middle' }}>
							Search jobs
						</Box>
						<ArrowRightIcon
							sx={{
								ml: 1,
								fontSize: 'inherit',
								color: 'inherit',
								verticalAlign: 'middle',
							}}
						/>
					</RouteLink>
				</Typography>
			</Box>
		</Box>
	);
};

export default ExplainerCta;
