import React from 'react';

import { SvgIcon } from '@mui/material';

const ThumbsUpIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			{...props}
		>
			<path
				d='M7.33301 1.3335L6.81608 3.61214L5.00879 5.61995C4.78812 5.86462 4.66634 6.18255 4.66634 6.51188V12.6668C4.66634 13.4035 5.26301 14.0002 5.99967 14.0002H11.3538C11.8478 14.0002 12.2951 13.7093 12.4958 13.258L14.4971 8.75407C14.6084 8.50474 14.6663 8.23443 14.6663 7.9611V7.3335C14.6663 6.60016 14.0663 6.00016 13.333 6.00016H8.66634C8.66634 6.00016 9.33301 4.41968 9.33301 3.24235C9.33301 1.41435 7.33301 1.3335 7.33301 1.3335ZM1.33301 6.00016V14.0002H3.33301V6.00016H1.33301Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default ThumbsUpIcon;
