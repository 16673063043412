import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='19'
			height='19'
			viewBox='0 0 19 19'
			fill='none'
			{...props}
		>
			<path
				d='M0.849609 3.31483C0.849609 2.73762 1.05232 2.26143 1.45772 1.88626C1.86312 1.51107 2.39016 1.32349 3.0388 1.32349C3.67587 1.32349 4.1913 1.50818 4.58513 1.8776C4.99053 2.25855 5.19324 2.75493 5.19324 3.36678C5.19324 3.92089 4.99633 4.38264 4.6025 4.75206C4.19711 5.13301 3.66428 5.32348 3.00405 5.32348H2.98668C2.3496 5.32348 1.83417 5.13301 1.44034 4.75206C1.04651 4.3711 0.849609 3.89202 0.849609 3.31483ZM1.07548 18.4663V6.89924H4.93262V18.4663H1.07548ZM7.06969 18.4663H10.9268V12.0075C10.9268 11.6034 10.9732 11.2917 11.0658 11.0724C11.228 10.6799 11.4741 10.348 11.8042 10.0767C12.1344 9.80544 12.5484 9.6698 13.0465 9.6698C14.3438 9.6698 14.9925 10.5414 14.9925 12.2845V18.4663H18.8496V11.8343C18.8496 10.1258 18.4442 8.82998 17.6334 7.94686C16.8226 7.06374 15.7512 6.62219 14.4191 6.62219C12.9249 6.62219 11.7608 7.26288 10.9268 8.54426V8.5789H10.9095L10.9268 8.54426V6.89924H7.06969C7.09285 7.26864 7.10444 8.41727 7.10444 10.3451C7.10444 12.273 7.09285 14.98 7.06969 18.4663Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
