import { useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import { HasuraRole } from '@ivy/constants/headers';
import {
	isAuthenticatedVar,
	currentAccountBaseVar,
	type CurrAcc,
	currentAccountRolesVar,
} from '@ivy/gql/reactives';

export const useAnonymousQuery = (query, options) => {
	return useQuery(query, {
		...options,
		context: {
			...options.context,
			headers: {
				'X-Hasura-Role': HasuraRole.ANON,
			},
		},
	});
};

export const useAccountType = () => {
	const currAcc = useReactiveVar(currentAccountBaseVar);

	return currAcc?.type || null;
};

export const useAuthInfoLoaded = () => {
	const isAuth = useReactiveVar(isAuthenticatedVar);
	const currAcc = useReactiveVar(currentAccountBaseVar);

	return isAuth && !!currAcc;
};

export const useCurrentAccount = (): CurrAcc | null => {
	const currAccBase = useReactiveVar(currentAccountBaseVar);
	const currAccRoles = useReactiveVar(currentAccountRolesVar);
	const currAcc = useMemo(() => {
		return currAccBase
			? {
					...currAccBase,
					roles: currAccRoles?.roles || [],
			  }
			: null;
	}, [currAccBase, currAccRoles]);

	return currAcc;
};

export const useCurrentOrgId = () => {
	const currAcc = useCurrentAccount();
	return currAcc?.orgUser?.org?.id || null;
};
