import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, type Theme } from '@mui/material';
import { Navigate } from 'react-router-dom';

import ProximaBold from '@ivy/assets/fonts/ProximaNovaBold.woff2';
import ProximaRegular from '@ivy/assets/fonts/ProximaNovaRegular.woff';
import ProximaSemiBold from '@ivy/assets/fonts/ProximaNovaSemiBold.woff2';
import ProximaThin from '@ivy/assets/fonts/ProximaNovaThin.woff2';
import RouteLink from '@ivy/components/atoms/RouteLink';
import AcepFooter from '@ivy/components/organisms/AcepFooter';
import BaseFooter, {
	SocialMediaType,
} from '@ivy/components/organisms/BaseFooter';
import Footer from '@ivy/components/organisms/Footer';
import config from '@ivy/config';
import { Profession } from '@ivy/constants/clinician';
import { RouteUri } from '@ivy/constants/routes';
import { type CurrAcc } from '@ivy/gql/reactives';
import { getHomePageMenuItems, type MenuNode } from '@ivy/lib/helpers/menu';
import lazyRetry from '@ivy/lib/util/lazyRetry';
import { buildInternalLink } from '@ivy/lib/util/route';
import { bubbleTheme } from '@ivy/theme/bubble';
import { muiTheme } from '@ivy/theme/theme';
import AccountSetting from '@ivy/views/account/AccountSettings';
import FavoritesShow from '@ivy/views/account/FavoritesShow';
import SuppressionList from '@ivy/views/account/SuppressionList';
import FinalizeSignup from '@ivy/views/auth/FinalizeSignup';
import ForgotPassword from '@ivy/views/auth/ForgotPassword';
import ResetPassword from '@ivy/views/auth/ResetPassword';
import BlogList from '@ivy/views/blog/BlogList';
import BlogShow from '@ivy/views/blog/BlogShow';
import CandidateShow from '@ivy/views/candidate/CandidateShow';
import ConnectionRedirect from '@ivy/views/candidate/ConnectionRedirect';
import JobApplicationList from '@ivy/views/candidate/JobApplicationList';
import EditClinician from '@ivy/views/clinician/EditClinician';
import EmraLanding from '@ivy/views/emra/EmraLanding';
import EmraProgramShow from '@ivy/views/emra/EmraProgramShow';
import EmraSearch from '@ivy/views/emra/EmraSearch';
import AccreditationSearch from '@ivy/views/facility/AccreditationSearch';
import FacilitySearch from '@ivy/views/facility/FacilitySearch';
// Lazy imports mui-datagrid
import FacilityShow from '@ivy/views/facility/FacilityShow';
import AboutUs from '@ivy/views/landing/AboutUs';
import Faq from '@ivy/views/landing/Faq';
import FaqAll from '@ivy/views/landing/Faq/FaqAll';
import ForClinicians from '@ivy/views/landing/ForClinicians';
import ForEmployers from '@ivy/views/landing/ForEmployers';
import ReviewShow from '@ivy/views/landing/Review';
import SalaryShow from '@ivy/views/landing/Salary';
import CommunityGuidelines from '@ivy/views/legal/CommunityGuidelines';
import PrivacyPolicy from '@ivy/views/legal/PrivacyPolicy';
import TermsOfService from '@ivy/views/legal/TermsOfService/TermsOfService';
import CheckoutFlow from '@ivy/views/org/CheckoutFlow';
import Sitemap from '@ivy/views/sitemap/SitemapShow';
// Large import - tiptap (read only)
const OrgShow = lazyRetry(() => import('@ivy/views/org/OrgShow'));

// Large import - tiptap
const JobPostingCreate = lazyRetry(
	() => import('@ivy/views/job/JobPostingCreate'),
);
// Large import - tiptap
const JobPostingEdit = lazyRetry(() => import('@ivy/views/job/JobPostingEdit'));
// Large import - tiptap, DOMPurify
const JobPostingShow = lazyRetry(() => import('@ivy/views/job/JobPostingShow'));
// Large import - mui-datagrid
const AdminManagement = lazyRetry(
	() => import('@ivy/views/admin/AdminManagement'),
);
const AdminClinicianShow = lazyRetry(
	() => import('@ivy/views/admin/AdminClinicianShow'),
);
// Large import - mui-datagrid
const DataList = lazyRetry(() => import('@ivy/views/data/DataList'));
const Dashboard = lazyRetry(() => import('@ivy/views/org/Dashboard'));
// Large import - mui-datagrid
const AcepOrgSurveyCreate = lazyRetry(
	() => import('@ivy/views/acep/AcepOrgSurveyCreate'),
);
const AcepAdmin = lazyRetry(() => import('@ivy/views/acep/AcepAdmin'));
const AcepLanding = lazyRetry(() => import('@ivy/views/acep/AcepLanding'));
const EmraTrainingSurveyCreate = lazyRetry(
	() => import('@ivy/views/emra/EmraTrainingSurvey/EmraTrainingSurvey'),
);
const EmraResidencyCreate = lazyRetry(
	() => import('@ivy/views/emra/residency/EmraResidencyCreate'),
);
const EmraResidencyEdit = lazyRetry(
	() => import('@ivy/views/emra/residency/EmraResidencyEdit'),
);
const EmraClerkshipCreate = lazyRetry(
	() => import('@ivy/views/emra/clerkship/EmraClerkshipCreate'),
);
const EmraClerkshipEdit = lazyRetry(
	() => import('@ivy/views/emra/clerkship/EmraClerkshipEdit'),
);
const EmraFellowshipCreate = lazyRetry(
	() => import('@ivy/views/emra/fellowship/EmraFellowshipCreate'),
);
const EmraFellowshipEdit = lazyRetry(
	() => import('@ivy/views/emra/fellowship/EmraFellowshipEdit'),
);
const EmraAdmin = lazyRetry(() => import('@ivy/views/emra/admin/EmraAdmin'));
const EmraResidencySurveyShow = lazyRetry(
	() => import('@ivy/views/emra/residency/EmraResidencySurveyShow'),
);
const EmraClerkshipSurveyShow = lazyRetry(
	() => import('@ivy/views/emra/clerkship/EmraClerkshipSurveyShow'),
);
const EmraFellowshipSurveyShow = lazyRetry(
	() => import('@ivy/views/emra/fellowship/EmraFellowshipSurveyShow'),
);

export type WhitelabelConfigName = 'emra' | 'acep' | 'default';

export interface RouteConfig {
	path: string;
	element: React.ReactNode;
	lazy?: boolean;
}

export interface WhitelabelConfig {
	name: WhitelabelConfigName;
	productName: string;
	productDescription: string;
	logo: string;
	logoVertical?: string;
	logoUrl: string;
	baseUrl: string;
	menu: {
		disabled: boolean;
		getLandingMenuItems?: (currAcc: CurrAcc | null) => MenuNode[];
		sso?: string;
	};
	theme: Theme;
	footer: React.ReactNode;
	enabledRoutes: RouteConfig[];
}

// The font imports above will only actually be downloaded if used in CSS source URLs
const ACEP_FONT_FAMILY = '"Helvetica Neue", Helvetica, Roboto, sans-serif';
const EMRA_FONT_FAMILY = '"Proxima Nova", sans-serif';

const WHITELABEL_CONFIGURATIONS: WhitelabelConfig[] = [
	{
		name: 'emra',
		productName: 'EMRA Match',
		productDescription:
			'EMRA Match is the premier source for emergency medicine trainees seeking the right learning experiences in clerkships, residencies, and fellowships.',
		logo: 'https://assets.ivyclinicians.io/images/emra-match-logo.png',
		logoUrl: '/',
		baseUrl: 'https://www.match.emra.org',
		menu: {
			disabled: false,
			getLandingMenuItems: () => [],
			sso: `${config.apiUrl}/v1/auth/sso/login/emra`,
		},
		// Don't use the Theme object, use ThemeOptions object to get the right Typography
		// MUI applies the fontFamily to all typography h1/h2/h3 configs and we can't override it
		// unless we specify each variant manually or use ThemeOptions instead
		theme: createTheme(bubbleTheme, {
			palette: {
				primary: {
					main: '#005488',
					light: '#008CFF',
				},
				secondary: {
					main: '#865D90',
					light: '#4A6198',
					dark: '#3F3871',
				},
				background: {
					default: '#fff',
				},
				light1: {
					main: '#EFEFEF',
				},
				light3: {
					main: '#50BA70',
				},
			},
			typography: {
				fontFamily: EMRA_FONT_FAMILY,
				fontWeightLight: 300,
				fontWeightRegular: 400,
				fontWeightMedium: 500,
				fontWeightBold: 700,
				// Bug makes us specify fontFamily again for each variant
				// https://github.com/mui/material-ui/issues/35939
				h1: {
					fontFamily: EMRA_FONT_FAMILY,
					fontWeight: 500,
				},
				h2: {
					fontFamily: EMRA_FONT_FAMILY,
					fontWeight: 500,
				},
				...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: EMRA_FONT_FAMILY,
							fontWeight: 500,
						},
					}),
					{},
				),
				...['body1', 'body2', 'caption'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: EMRA_FONT_FAMILY,
							fontWeight: 400,
						},
					}),
					{},
				),
				...['button', 'subtitle1', 'subtitle2'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: EMRA_FONT_FAMILY,
							fontWeight: 400,
						},
					}),
					{},
				),
				overline: {
					fontFamily: EMRA_FONT_FAMILY,
				},
			},
			buttonShape: {
				borderRadius: 4,
			},
			components: {
				MuiButton: {
					defaultProps: {
						disableElevation: true,
					},
					styleOverrides: {
						root: {
							borderRadius: 4,
						},
					},
				},
				MuiFormLabel: {
					styleOverrides: {
						root: ({ theme }) => ({
							'& .MuiTypography-root': {
								...theme.typography.body1,
								fontWeight: 'bold',
								color: theme.palette.text.primary,
							},
						}),
					},
				},
				MuiCssBaseline: {
					styleOverrides: `
						@font-face {
							font-family: 'Proxima Nova';
							font-style: normal;
							font-display: swap;
							font-weight: 300;
							src: url(${ProximaThin}) format('woff2');
						}
						@font-face {
							font-family: 'Proxima Nova';
							font-style: normal;
							font-display: swap;
							font-weight: 400;
							src: url(${ProximaRegular}) format('woff');
						}
						@font-face {
							font-family: 'Proxima Nova';
							font-style: normal;
							font-display: swap;
							font-weight: 500;
							src: url(${ProximaSemiBold}) format('woff2');
						}
						@font-face {
							font-family: 'Proxima Nova';
							font-style: normal;
							font-display: swap;
							font-weight: 700;
							src: url(${ProximaBold}) format('woff2');
						}
				    `,
				},
			},
		}),
		enabledRoutes: [
			{
				path: RouteUri.ROOT,
				element: <EmraLanding />,
				lazy: true,
			},
			{
				path: RouteUri.SETTING,
				element: <AccountSetting />,
			},
			{
				path: RouteUri.FAVORITES,
				element: <FavoritesShow />,
			},
			{
				path: RouteUri.EMRA_TRAINING_SURVEY_CREATE,
				element: <EmraTrainingSurveyCreate />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_RESIDENCY_SURVEY_SHOW,
				element: <EmraResidencySurveyShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_CLERKSHIP_SURVEY_SHOW,
				element: <EmraClerkshipSurveyShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_FELLOWSHIP_SURVEY_SHOW,
				element: <EmraFellowshipSurveyShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_RESIDENCY_CREATE,
				element: <EmraResidencyCreate />,
				lazy: true,
			},
			{
				path: `${RouteUri.EMRA_RESIDENCY_SHOW}/*`,
				element: <EmraProgramShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_RESIDENCY_EDIT,
				element: <EmraResidencyEdit />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_CLERKSHIP_CREATE,
				element: <EmraClerkshipCreate />,
				lazy: true,
			},
			{
				path: `${RouteUri.EMRA_CLERKSHIP_SHOW}/*`,
				element: <EmraProgramShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_CLERKSHIP_EDIT,
				element: <EmraClerkshipEdit />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_FELLOWSHIP_CREATE,
				element: <EmraFellowshipCreate />,
				lazy: true,
			},
			{
				path: `${RouteUri.EMRA_FELLOWSHIP_SHOW}/*`,
				element: <EmraProgramShow />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_SEARCH_PAGE + '?',
				element: <EmraSearch />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_FELLOWSHIP_EDIT,
				element: <EmraFellowshipEdit />,
				lazy: true,
			},
			{
				path: RouteUri.EMRA_ADMIN,
				element: <EmraAdmin />,
				lazy: true,
			},
		],
		footer: (
			<BaseFooter
				logo='https://assets.ivyclinicians.io/images/emra-match-logo.png'
				logo2='https://assets.ivyclinicians.io/images/emra-logo.png'
				description='EMRA Match is the premier source for emergency medicine trainees seeking the right learning experiences in clerkships, residencies, and fellowships.'
				socials={[
					{
						type: SocialMediaType.FACEBOOK,
						link: 'https://www.facebook.com/emresidents',
					},
					{
						type: SocialMediaType.TWITTER,
						link: 'https://twitter.com/emresidents',
					},
					{
						type: SocialMediaType.INSTAGRAM,
						link: 'https://www.instagram.com/emresidents/',
					},
					{
						type: SocialMediaType.LINKEDIN,
						link: 'https://www.linkedin.com/company/emresidents/',
					},
				]}
				sections={[
					{
						header: 'Features',
						links: [
							{
								label: 'Residency',
								link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
									type: 'residency',
									slug: 'search',
								}),
							},
							{
								label: 'Clerkship',
								link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
									type: 'clerkship',
									slug: 'search',
								}),
							},
							{
								label: 'Fellowship',
								link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
									type: 'fellowship',
									slug: 'search',
								}),
							},
						],
					},
					{
						header: 'Company',
						links: [
							{
								label: 'About',
								link: 'https://www.emra.org/about-emra',
								openInNewTab: true,
							},
							{
								label: 'Contact us',
								link: 'https://www.emra.org/about-emra/meet-your-staff--board/emra-staff',
								openInNewTab: true,
							},
						],
					},
				]}
				privacyPolicy={{
					pathname: RouteLink.routes.PRIVACY_POLICY,
					whitelabel: 'default',
				}}
				address='4950 W. Royal Lane Irving, TX 75063'
				phone='972.550.0920'
				email='emra@emra.org'
			/>
		),
	},
	{
		name: 'acep',
		productName: 'ACEP Open Book',
		productDescription:
			'Uncover details about emergency medicine employers in the pages of ACEP Open Book.',
		logo: 'https://assets.ivyclinicians.io/images/acep-logo.png',
		logoUrl: 'https://www.acep.org',
		baseUrl: 'https://www.openbook.acep.org',
		menu: {
			disabled: true,
		},
		// Don't use the Theme object, use ThemeOptions object to get the right Typography
		// MUI applies the fontFamily to all typography h1/h2/h3 configs and we can't override it
		// unless we specify each variant manually or use ThemeOptions instead
		theme: createTheme(bubbleTheme, {
			palette: {
				primary: {
					main: '#005EB8',
					light: '#0074E0',
					dark: '#2682C5',
				},
				background: {
					default: '#fff',
				},
				light1: {
					main: '#EFEFEF',
				},
			},
			typography: {
				fontFamily: ACEP_FONT_FAMILY,
				fontWeightLight: 300,
				fontWeightRegular: 400,
				fontWeightMedium: 500,
				fontWeightBold: 700,
				// Bug makes us specify fontFamily again for each variant
				// https://github.com/mui/material-ui/issues/35939
				h1: {
					fontFamily: ACEP_FONT_FAMILY,
					fontWeight: 700,
				},
				h2: {
					fontFamily: ACEP_FONT_FAMILY,
					fontWeight: 700,
				},
				...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: '"Helvetica Neue", Helvetica, Roboto, sans-serif',
							fontWeight: 700,
						},
					}),
					{},
				),
				...['body1', 'body2', 'caption'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: '"Helvetica Neue", Helvetica, Roboto, sans-serif',
							fontWeight: 400,
						},
					}),
					{},
				),
				...['button', 'subtitle1', 'subtitle2'].reduce(
					(tot, variant) => ({
						...tot,
						[variant]: {
							fontFamily: '"Helvetica Neue", Helvetica, Roboto, sans-serif',
							fontWeight: 500,
						},
					}),
					{},
				),
				overline: {
					fontFamily: ACEP_FONT_FAMILY,
				},
			},
			components: {
				MuiFormLabel: {
					styleOverrides: {
						root: ({ theme }) => ({
							'& .MuiTypography-root': {
								...theme.typography.body1,
								fontWeight: 'bold',
								color: theme.palette.text.primary,
							},
						}),
					},
				},
			},
		}),
		enabledRoutes: [
			{
				path: RouteUri.ROOT,
				element: <AcepLanding />,
				lazy: true,
			},
			{
				path: `${RouteUri.ORG_INDEX}/*`,
				element: (
					<OrgShow
						showAcepSurvey={true}
						showAcepSurveyCta={true}
						showAcepSurveyDisclaimer={true}
						showDescription={false}
						showWhitelabelCta={true}
						showSurveyName={true}
						showFacilitiesTab={false}
						showReviewsTab={false}
						showSalariesTab={false}
						professions={[Profession.PHYSICIAN]}
						getPageDescription={(org) =>
							`Dive into the details of ${org.name} in ACEP Open Book, including the group's emergency departments, ownership structure, operations, and adherence to ACEP policies.`
						}
						pageNoIndex
					/>
				),
				lazy: true,
			},
			{
				path: RouteUri.ACEP_ORG_SURVEY_CREATE,
				element: <AcepOrgSurveyCreate />,
				lazy: true,
			},
		],
		footer: <AcepFooter />,
	},
	{
		name: 'default',
		productName: 'Ivy Clinicians',
		// TODO: add info about reviews and salaries
		productDescription:
			"Ivy Clinicians is emergency medicine's job board for physicians, nurse practitioners, and physician assistants. Compare your salary, browse employer reviews, and apply to positions at over 5,000 emergency departments.",
		logo: 'https://assets.ivyclinicians.io/logo/logo-horizontal.svg',
		logoVertical: 'https://assets.ivyclinicians.io/logo/logo.svg',
		logoUrl: RouteUri.ROOT,
		baseUrl: 'https://www.ivyclinicians.io',
		menu: {
			disabled: false,
			getLandingMenuItems: getHomePageMenuItems,
		},
		theme: muiTheme,
		footer: (
			<Footer
				sx={{
					// Mirror padding for container
					px: {
						xs: 2,
						sm: 3,
					},
					pb: {
						xs: 2,
						sm: 3,
					},
				}}
			/>
		),
		enabledRoutes: [
			{
				path: RouteUri.SETTING,
				element: <AccountSetting />,
			},
			{
				path: RouteUri.SUPPRESSION_LIST,
				element: <SuppressionList />,
			},
			{
				path: RouteUri.ADMIN,
				element: <AdminManagement />,
				lazy: true,
			},
			{
				path: RouteUri.ADMIN_CLINICIAN_SHOW,
				element: <AdminClinicianShow />,
				lazy: true,
			},
			{
				path: RouteUri.DATA,
				element: <DataList />,
				lazy: true,
			},
			{
				// Accessible to logged in users so they can change their password
				path: RouteUri.RESET_PASSWORD,
				element: <ResetPassword />,
			},
			{
				path: RouteUri.FINALIZE_SIGNUP,
				element: <FinalizeSignup />,
			},
			{
				path: RouteUri.FORGOT_PASSWORD,
				element: <ForgotPassword />,
			},
			{
				path: RouteUri.BLOG_LIST,
				element: <BlogList />,
			},
			{
				path: RouteUri.BLOG_SHOW,
				element: <BlogShow />,
			},
			{
				path: RouteUri.EDIT_CLINICIAN,
				element: <EditClinician />,
			},
			{
				path: RouteUri.CANDIDATE_SHOW,
				element: <CandidateShow />,
			},
			{
				path: RouteUri.JOB_APPLICATION_LIST,
				element: <JobApplicationList />,
			},
			{
				path: RouteUri.SEARCH_PAGE + '?',
				element: <FacilitySearch />,
			},
			{
				path: RouteUri.ACCREDITATION_SEARCH_PAGE + '?',
				element: <AccreditationSearch />,
			},
			{
				path: `${RouteUri.FACILITY_INDEX}/*`,
				element: <FacilityShow />,
			},
			{
				path: RouteUri.CHECKOUT_FLOW,
				element: <CheckoutFlow />,
			},

			{
				path: RouteUri.JOB_POSTING_SHOW,
				element: <JobPostingShow />,
				lazy: true,
			},
			{
				path: RouteUri.JOB_POSTING_CREATE,
				element: <JobPostingCreate />,
				lazy: true,
			},
			{
				path: RouteUri.JOB_POSTING_EDIT,
				element: <JobPostingEdit />,
				lazy: true,
			},
			{
				path: RouteUri.ROOT,
				element: <ForClinicians />,
			},
			{
				path: RouteUri.FOR_EMPLOYERS,
				element: <ForEmployers />,
			},
			{
				path: RouteUri.SALARY,
				element: <SalaryShow />,
				lazy: true,
			},
			{
				path: RouteUri.REVIEW,
				element: <ReviewShow />,
				lazy: true,
			},
			{
				path: RouteUri.ABOUT_US,
				element: <AboutUs />,
			},
			{
				path: RouteUri.TOS,
				element: <TermsOfService />,
			},
			{
				path: RouteUri.PRIVACY_POLICY,
				element: <PrivacyPolicy />,
			},
			{
				path: RouteUri.SITEMAP_PAGE + '?',
				element: <Sitemap />,
			},
			{
				path: RouteUri.COMMUNITY_GUIDELINES,
				element: <CommunityGuidelines />,
			},
			{
				path: RouteUri.FAQ_LIST,
				element: <Faq />,
			},
			{
				path: RouteUri.FAQ_ALL,
				element: <FaqAll />,
			},
			{
				path: `${RouteUri.MANAGEMENT}/*`,
				element: <Dashboard />,
				lazy: true,
			},
			{
				path: `${RouteUri.ORG_INDEX}/*`,
				element: (
					<OrgShow
						showAcepSurvey={true}
						showAcepSurveyCta={false}
						showAcepSurveyDisclaimer={false}
						showDescription={true}
						showWhitelabelCta={false}
						showSurveyName={false}
						showFacilitiesTab={true}
						showReviewsTab={true}
						showSalariesTab={true}
						getPageDescription={(org) =>
							org.description ||
							`Explore salaries, reviews, and job opportunities for emergency physicians, nurse practitioners, and physician assistants at ${org.name}.`
						}
					/>
				),
				lazy: true,
			},
			{
				path: RouteUri.ACEP_ADMIN,
				element: <AcepAdmin />,
				lazy: true,
			},
			// Redirects for deprecated functionality
			{
				path: '/reviews',
				element: <Navigate to={RouteUri.REVIEW} replace />,
			},
			{
				path: RouteUri.CONNECTION,
				element: <ConnectionRedirect />,
			},
			{
				path: RouteUri.CLN_CONNECTION_LIST,
				element: <ConnectionRedirect />,
			},
			{
				path: RouteUri.ORG_CONNECTION_LIST,
				element: <ConnectionRedirect />,
			},
			{
				path: RouteUri.ORG_CONNECTION_SHOW,
				element: <ConnectionRedirect />,
			},
		],
	},
];

export default WHITELABEL_CONFIGURATIONS;
