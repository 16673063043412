import React from 'react';

import {
	Box,
	Chip,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

interface StepTitleProps {
	title: string;
	chip?: string;
	subtitle?: React.ReactNode;
	sx?: SxProps<Theme>;
	chipSx?: SxProps<Theme>;
}

const StepTitle = ({ chip, title, subtitle, sx, chipSx }: StepTitleProps) => {
	return (
		<Stack
			spacing={{ xs: 1, md: 2 }}
			sx={combineSx(
				{ maxWidth: { md: '448px', sm: '340px', xs: '280px' } },
				sx,
			)}
		>
			{chip && (
				<Box display='flex' justifyContent='center' sx={{ mb: -0.5 }}>
					<Chip size='small' label={chip} sx={combineSx({ px: 1 }, chipSx)} />
				</Box>
			)}
			<Typography variant='h6' component='h1' textAlign='center'>
				{title}
			</Typography>
			{subtitle && (
				<Typography variant='body2' textAlign='center'>
					{subtitle}
				</Typography>
			)}
		</Stack>
	);
};

export default StepTitle;
