import React from 'react';

import { Box, TextField, Typography } from '@mui/material';
import { type FormikProps } from 'formik-othebi';

export interface FacilityDescriptionFormValues {
	description: string;
}

export interface FacilityDescriptionFormProps {
	formik: FormikProps<FacilityDescriptionFormValues>;
}

const FacilityDescriptionForm = ({ formik }: FacilityDescriptionFormProps) => {
	return (
		<Box pt={1}>
			<Typography variant='subtitle1' component='h2' gutterBottom>
				Facility Description
			</Typography>
			<TextField
				fullWidth
				multiline
				minRows={10}
				name='description'
				placeholder='Tell clinicians about this facility.'
				value={formik.values.description}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.description && !!formik.errors.description}
				helperText={formik.touched.description && formik.errors.description}
				disabled={formik.isSubmitting}
			/>
		</Box>
	);
};

export default FacilityDescriptionForm;
