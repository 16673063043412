import React from 'react';

import { Stack, TextField, type SxProps, type Theme } from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik-othebi';

import AddressAutocomplete from '@ivy/components/molecules/AddressAutocomplete';
import FieldLabel from '@ivy/components/molecules/FieldLabel';
import { getInputProps } from '@ivy/lib/forms/formikHelpers';

export interface BillingFormBodyValues {
	name: string;
	email: string;
	streetAddress1: string;
	streetAddress2: string;
	city: string;
	state: string;
	zipcode: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BillingFormBodyProps<T extends FormikValues> {
	formik: FormikProps<T>;
	sx?: SxProps<Theme>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BillingFormBody = <T extends FormikValues>({
	formik,
	sx,
}: BillingFormBodyProps<T>) => {
	return (
		<Stack spacing={5} sx={sx}>
			<Stack direction='column'>
				<FieldLabel primary='Contact info' mb={3} />
				<TextField
					label='Billing email'
					{...getInputProps(formik, 'email', {})}
					placeholder='Enter billing email address'
				/>
			</Stack>
			<Stack direction='column'>
				<FieldLabel primary='Billing address' mb={3} />
				<Stack direction='column' spacing={2}>
					<TextField
						label='Full name'
						{...getInputProps(formik, 'name', {})}
						placeholder='Enter full name'
					/>
					<AddressAutocomplete
						value={{
							streetAddress1: formik.values.streetAddress1,
							streetAddress2: formik.values.streetAddress2,
							city: formik.values.city,
							state: formik.values.state,
							zipcode: formik.values.zipcode,
						}}
						touched={{
							streetAddress1: formik.touched.streetAddress1 as
								| boolean
								| undefined,
							streetAddress2: formik.touched.streetAddress2 as
								| boolean
								| undefined,
							city: formik.touched.city as boolean | undefined,
							state: formik.touched.state as boolean | undefined,
							zipcode: formik.touched.zipcode as boolean | undefined,
						}}
						error={{
							streetAddress1: formik.errors.streetAddress1 as
								| string
								| undefined,
							streetAddress2: formik.errors.streetAddress2 as
								| string
								| undefined,
							city: formik.errors.city as string | undefined,
							state: formik.errors.state as string | undefined,
							zipcode: formik.errors.zipcode as string | undefined,
						}}
						onChange={(nv, shouldValidate) =>
							formik.setValues(
								{
									...formik.values,
									...nv,
								},
								shouldValidate,
							)
						}
						onBlur={(touched, shouldValidate) =>
							formik.setTouched(
								{
									...formik.touched,
									...touched,
								},
								shouldValidate,
							)
						}
						spacing={2}
						disabled={formik.isSubmitting}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default BillingFormBody;
