import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EntityFilterValues = Record<string, any>;

export interface FilterCompProps {
	options: {
		label: string;
		value: string;
		disabling?: boolean | undefined;
	}[];
	value: string | boolean | (boolean | null | undefined | string)[] | null;
	onChange: (
		ev: React.SyntheticEvent,
		nv: string | boolean | (boolean | null | undefined | string)[] | null,
	) => void;
}

export interface FilterDataStructure {
	quickBtnId?: string;
	label?: string;
	key: string;
	title: string;
	tooltipContent?: React.ReactNode;
	options?: {
		label: string;
		value: string;
		disabling?: boolean | undefined;
	}[];
	apiResolver: (
		value: string | boolean | (boolean | null | undefined | string)[] | null,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => { [k: string]: any };
	component: React.ElementType;
	initialFilterValue?:
		| string
		| boolean
		| (boolean | null | undefined | string)[]
		| null;
	isSlider?: boolean;
}

const createInitialFilters = (filters: FilterDataStructure[]) => {
	return filters.reduce((accum, current) => {
		accum[current.key] = current.initialFilterValue || null;
		return accum;
	}, {});
};

const useEntityFilters = (
	defaultFilters: EntityFilterValues | (() => EntityFilterValues),
	filters: FilterDataStructure[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rootFilters?: Record<string, any>,
) => {
	const initialFilters = createInitialFilters(filters);

	const [rawFilters, setRawFilters] = useState<EntityFilterValues>(() => {
		if (!defaultFilters) {
			return initialFilters;
		}
		return merge(
			{},
			initialFilters,
			isFunction(defaultFilters) ? defaultFilters() : defaultFilters,
		);
	});
	const [appliedFilters, setAppliedFilters] = useState(rawFilters);

	const apiFilters = useMemo(() => {
		const fltrs = {
			_and: Object.entries(appliedFilters)
				.map(([key, data]) => {
					const dataStructure = find(filters, { key });
					return dataStructure?.apiResolver(data);
				})
				.filter((el) => el && !isEmpty(el)),
		};
		if (rootFilters) {
			fltrs._and.push(rootFilters);
		}
		return fltrs;
	}, [appliedFilters, filters, rootFilters]);

	const serializedFilters = useMemo(() => {
		return JSON.stringify(
			pickBy(appliedFilters, (value) =>
				Array.isArray(value)
					? !!value.length
					: ![null, undefined, NaN, ''].includes(value),
			),
		);
	}, [appliedFilters]);

	const applyFilters = useCallback(
		(nv?: EntityFilterValues) => {
			if (nv) {
				setRawFilters(nv);
				setAppliedFilters(nv);
			} else {
				setAppliedFilters(rawFilters);
			}
		},
		[rawFilters, setAppliedFilters],
	);

	const resetFilters = useCallback(() => {
		setRawFilters(initialFilters);
		setAppliedFilters(initialFilters);
	}, [setRawFilters, setAppliedFilters, initialFilters]);

	const discardFilters = useCallback(() => {
		setRawFilters(appliedFilters);
	}, [appliedFilters, setRawFilters]);

	const evaluateSliderValue = (v, k, currFilters: FilterDataStructure[]) => {
		// check if current value is array and has more than 0 entries
		if (!(isArray(v) && v.length > 0)) return 0;

		// find the slider filterObject with a key property equal to given key
		const filterObject = find(currFilters, { key: k });

		// check if filterObject is truthy, then check if v is equal to initialValue of slider
		const isEqualValues = filterObject
			? isEqual(
					[
						filterObject!.options![0]!.value,
						filterObject!.options![filterObject!.options!.length - 1].value,
					],
					v,
			  )
			: false;

		return isEqualValues ? 0 : 1;
	};

	const filtersCount = useMemo(() => {
		return Object.entries(appliedFilters).reduce((tot, [k, v]) => {
			const count = filters.reduce((acc, filter) => {
				acc[filter.key] = filter.isSlider;
				return acc;
			}, {})[k] // Check if it's an isSlider filter because sliders get counted twice
				? evaluateSliderValue(v, k, filters)
				: isObject(v) // Existing logic for other types of filters
				? Object.values(v).filter((el) => el).length
				: isArray(v)
				? v.length
				: v !== null
				? 1
				: 0;
			return {
				...tot,
				[k]: count,
			};
		}, {}) as { [k in keyof EntityFilterValues]: number };
	}, [appliedFilters, filters]);

	return {
		filters,
		rawFilters,
		setRawFilters,
		apiFilters,
		appliedFilters,
		applyFilters,
		discardFilters,
		resetFilters,
		filtersCount,
		serializedFilters,
	};
};

export default useEntityFilters;
