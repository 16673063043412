import React, { useState, useRef } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
	Button,
	type SxProps,
	type ButtonProps,
	type Theme,
} from '@mui/material';

import QuickColumnPopover, {
	type QuickColumnPopoverProps,
} from '@ivy/components/molecules/QuickColumnButton/QuickColumnPopover';
import { combineSx } from '@ivy/lib/styling/sx';

interface QuickColumnButtonProps
	extends Omit<ButtonProps, 'onChange' | 'value'> {
	onApply: (nv?: Record<string, boolean>) => void;
	onDiscard: () => void;
	value: Record<string, boolean>;
	onChange: (val: Record<string, boolean>) => void;
	label: React.ReactNode;
	initialValue?: Record<string, boolean>;
	renderInput: (
		val: Record<string, boolean>,
		func: (val: Record<string, boolean>) => void,
	) => JSX.Element;
	badgeContent?: number;
	footerComponent?: React.ReactNode;
	hideFooter: boolean;
	title?: string;
	sx?: SxProps<Theme>;
	PopoverProps?: QuickColumnPopoverProps;
}

const QuickColumnButton = ({
	onApply,
	onDiscard,
	initialValue,
	value,
	onChange,
	label,
	title,
	renderInput,
	badgeContent,
	footerComponent,
	hideFooter,
	sx,
	PopoverProps,
	...props
}: QuickColumnButtonProps) => {
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [open, setOpen] = useState(false);

	const handleChange = (nv: Record<string, boolean>) => {
		onChange({
			...value,
			...nv,
		});
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onDiscard();
	};

	const handleReset = () => {
		onApply({
			...initialValue,
		});
		setOpen(false);
	};

	const handleApply = () => {
		onApply();
		setOpen(false);
	};

	return (
		<>
			<Button
				ref={anchorRef}
				variant='outlined'
				endIcon={<ExpandMore />}
				size='small'
				sx={combineSx(
					{
						color: 'text.primary',
						borderColor: 'divider',
						bgcolor: 'white',
						...(badgeContent
							? {
									color: 'primary.main',
									bgcolor: 'primary.translucent',
									borderColor: 'primary.main',
							  }
							: {}),
					},
					sx,
				)}
				onClick={handleOpen}
				{...props}
			>
				{label}
				{!!badgeContent && ` (${badgeContent})`}
			</Button>

			<QuickColumnPopover
				{...PopoverProps}
				title={title}
				anchorEl={anchorRef.current}
				open={open}
				onClose={handleClose}
				onSubmit={handleApply}
				onReset={handleReset}
				footerComponent={footerComponent}
				hideFooter={hideFooter}
			>
				{renderInput(value, handleChange)}
			</QuickColumnPopover>
		</>
	);
};

export default QuickColumnButton;
