import React from 'react';

import { Stack, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import SectionHeader, {
	type SectionHeaderProps,
} from '@ivy/components/molecules/SectionHeader';
import { gql, type FragmentType, getFragmentData } from '@ivy/gql/types';
import { buildInternalLink } from '@ivy/lib/util/route';

export const EmraProgramTrainingSites_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramTrainingSites_TrainingSurvey on training_survey {
		id
		type
		trainingSites: training_sites(order_by: { order: asc }) {
			id
			primary
			facility {
				id
				name
				slug
			}
			facilityOther: facility_other
		}
		trainingSitesAgg: training_sites_aggregate {
			aggregate {
				count
			}
		}
	}
`);

export interface EmraProgramTrainingSitesProps
	extends Omit<SectionHeaderProps, 'title'> {
	survey: FragmentType<typeof EmraProgramTrainingSites_TrainingSurveyFDoc>;
}

const EmraProgramTrainingSites = ({
	survey: rawSurvey,
}: EmraProgramTrainingSitesProps) => {
	const survey = getFragmentData(
		EmraProgramTrainingSites_TrainingSurveyFDoc,
		rawSurvey,
	);

	if (!survey.trainingSitesAgg.aggregate?.count) {
		return null;
	}

	return (
		<SectionHeader
			title='Training Sites'
			sx={{
				h2: {
					fontWeight: 'light',
				},
			}}
		>
			<Stack spacing={3}>
				{survey.trainingSites.map((trainingSite, indx) =>
					trainingSite.facility ? (
						<Typography
							variant='body1'
							fontWeight='bold'
							noWrap
							key={trainingSite.id}
						>
							<RouteLink
								to={{
									pathname: buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
										facilityId: [
											trainingSite.facility.id,
											trainingSite.facility.slug,
										],
									}),
									whitelabel: 'default',
								}}
								underline='hover'
								color='light3.main'
								openInNewTab
							>
								{trainingSite.facility.name || ''}
							</RouteLink>
						</Typography>
					) : (
						<Typography
							variant='body1'
							fontWeight='bold'
							noWrap
							key={`training-facility-other-${indx}`}
							color='light3.main'
						>
							{trainingSite?.facilityOther || ''}
						</Typography>
					),
				)}
			</Stack>
		</SectionHeader>
	);
};

export default EmraProgramTrainingSites;
