import React from 'react';

import {
	MenuItem,
	type SxProps,
	TextField,
	type TextFieldProps,
	type Theme,
} from '@mui/material';

import { stateAbbreviations } from '@ivy/constants/location';
import { combineSx } from '@ivy/lib/styling/sx';

interface StateSelectorProps
	extends Omit<TextFieldProps, 'name' | 'sx' | 'label'> {
	name?: string;
	sx?: SxProps<Theme>;
	label?: string;
}

const StateSelector = ({
	name,
	sx,
	label = 'State',
	...props
}: StateSelectorProps) => {
	return (
		<TextField
			name={name}
			label={label}
			select
			sx={combineSx(
				{
					minWidth: '90px',
				},
				sx,
			)}
			{...props}
		>
			{stateAbbreviations.map((state) => (
				<MenuItem key={state} value={state}>
					{state}
				</MenuItem>
			))}
		</TextField>
	);
};

export default StateSelector;
export { type StateSelectorProps };
