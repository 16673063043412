import React, { useState } from 'react';

import Check from '@mui/icons-material/Check';
import {
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import {
	type FormikErrors,
	type FormikProps,
	setNestedObjectValues,
} from 'formik-othebi';
import intersection from 'lodash/intersection';

import { type SubStepObj } from '@ivy/lib/forms/formFormatHelpers';

interface SubStepProps<T, K = void> {
	formik: FormikProps<T>;
	initialStep: string;
	subSteps: { [key: string]: SubStepObj<T, K> };
	extra: K;
}

export const renameLabel = (
	main = '',
	sub?: string | number | boolean,
	required = false,
) => {
	if (typeof sub === 'boolean') {
		return (
			<>
				{main}
				{sub ? (
					<>
						{': '}
						<Typography variant='caption' component='span'>
							<Check
								sx={{
									color: (theme) => theme.palette.success.main,
									position: 'relative',
									top: '3px',
								}}
							/>
						</Typography>
					</>
				) : (
					<i>{` (optional)`}</i>
				)}
			</>
		);
	} else {
		return (
			<>
				{main}
				{sub ? `: ${sub}` : required ? '*' : <i>{` (optional)`}</i>}
			</>
		);
	}
};

const formatSteps = <T, K>(
	steps: {
		[key: string]: SubStepObj<T, K>;
	},
	extra?: K,
): { keys: string[]; values: SubStepObj<T, K>[] } => {
	const updatedSteps = Object.keys(steps)
		.filter((key) => {
			const obj = steps[key];
			if (obj && obj.shouldShow && extra) {
				return obj.shouldShow(extra);
			}
			return true;
		})
		.reduce((result: { [key: string]: SubStepObj<T, K> }, key) => {
			result[key] = steps[key];
			return result;
		}, {});

	return {
		keys: Object.keys(updatedSteps),
		values: Object.values(updatedSteps),
	};
};

async function checkIsValid<T>(
	formik: FormikProps<T>,
	fields: string[],
): Promise<boolean> {
	const errors = await formik.validateForm();
	const filteredErrors = Object.keys(errors)
		.filter((key) => fields.includes(key))
		.reduce((obj: FormikErrors<T>, key) => {
			obj[key] = errors[key];
			return obj;
		}, {} as FormikErrors<T>);
	if (Object.keys(filteredErrors).length === 0) {
		return true;
	} else {
		formik.setTouched(setNestedObjectValues(filteredErrors, true));
		return false;
	}
}

const SubStep = <T, K>({
	formik,
	initialStep = '',
	subSteps,
	extra,
}: SubStepProps<T, K>) => {
	const currSteps = formatSteps(subSteps, extra);
	const foundIndex = currSteps.keys.indexOf(initialStep);
	const initialIndex = foundIndex !== -1 ? foundIndex : 0;
	const [activeStep, setActiveStep] = useState(initialIndex);

	const handleNext = async (newIndex: number) => {
		const stepObj = currSteps.values[activeStep];
		if (stepObj?.validate) {
			const fields = Object.keys(stepObj.validate?.fields);
			await checkIsValid(formik, [...fields, ...Object.keys(formik.touched)]);
		}
		setActiveStep(newIndex);
		formik.setSubmitting(false);
	};

	const errorStateFields = intersection(
		Object.keys(formik.errors),
		Object.keys(formik.touched),
	);

	return (
		<Stepper activeStep={activeStep} orientation='vertical'>
			{currSteps.values.map((step, index) => (
				<Step key={currSteps.keys[index]}>
					<StepLabel
						error={
							!!step.validate &&
							Object.keys(step.validate?.fields).some((field) =>
								errorStateFields.includes(field),
							) &&
							activeStep !== index
						}
						onClick={() => handleNext(index)}
						sx={{
							'& .MuiStepLabel-labelContainer': {
								overflow: 'hidden',
							},
							'& .MuiStepLabel-label': {
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							},
						}}
					>
						{step.label(formik.values, extra)}
					</StepLabel>
					<StepContent>
						<step.component
							formik={formik}
							onNext={() => handleNext(activeStep + 1)}
							activeStep={currSteps.keys[index]}
							namespace={step.namespace}
							extra={extra}
						/>
					</StepContent>
				</Step>
			))}
		</Stepper>
	);
};

export default SubStep;
export { type SubStepProps };
