import React, { Suspense } from 'react';

import { Box, Tabs, Tab, Grid, type SxProps, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { PracticeIcon, StructureIcon } from '@ivy/components/icons';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import {
	ORGNIZATION_PLACEHOLDER_IMAGE,
	OWNERSHIP_DESCRIPTION_SUCCINT,
} from '@ivy/constants/organization';
import { RouteUri } from '@ivy/constants/routes';
import { type FragmentType, getFragmentData, gql } from '@ivy/gql/types';
import { formatInteger } from '@ivy/lib/formatting/number';
import { buildInternalLink } from '@ivy/lib/util/route';

import CardGridSkeleton from './CardGridSkeleton';
import SnippetCard from './SnippetCard';

export const FeaturedList_FacilityFDoc = gql(/* GraphQL */ `
	fragment FeauredList_Facility on facility {
		id
		name
		slug
		ranking_review
		adultTraumaLvl: adult_trauma_lvl
		freestandingEr: freestanding_er
		facilityType @client
		cmsFacilityPos: cms_facility_pos {
			id
			providerSubtype: provider_subtype
		}
		cmsFacilityEnrollment: cms_facility_enrollment {
			id
			providerTypeCode: provider_type_code
		}
		cmsFacility: cms_facility {
			id
			hospitalType: hospital_type
		}
		ahaFacility: aha_facility_22 {
			id: ID
		}
		picture {
			id
			publicUrl: public_url
		}
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			org {
				id
				ownershipStructure: ownership_structure
				name
				slug
			}
		}
		reviewsAgg: reviews_aggregate(
			where: {
				active: { _eq: true }
				visible: { _eq: true }
				has_active_contract: { _eq: true }
			}
		) {
			agg: aggregate {
				avg {
					ratingJob: rating_job
				}
				count
			}
		}
	}
`);

export const FeaturedList_OrganizationFDoc = gql(/* GraphQL */ `
	fragment FeauredList_Organization on organization {
		id
		name
		slug
		ranking_review
		logo {
			id
			publicUrl: public_url
		}
		coverPhoto: cover_photo {
			id
			publicUrl: public_url
		}
		ownershipStructure: ownership_structure
		contractsAgg: contracts_aggregate(where: { active: { _eq: true } }) {
			aggregate {
				count
			}
		}
		reviewsAgg: reviews_aggregate(where: { active: { _eq: true } }) {
			agg: aggregate {
				avg {
					ratingOrg: rating_org
				}
				count
			}
		}
	}
`);

interface FeaturedListProps {
	facilities: FragmentType<typeof FeaturedList_FacilityFDoc>[];
	orgs: FragmentType<typeof FeaturedList_OrganizationFDoc>[];
	loading: boolean;
	sx?: SxProps<Theme>;
}

const GRID_SIZE = 9;

const FeaturedList = ({
	facilities: rawFacilities,
	orgs: rawOrgs,
	loading,
	sx,
}: FeaturedListProps) => {
	const [value, setValue] = React.useState('org');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const facilities = rawFacilities
		? getFragmentData(FeaturedList_FacilityFDoc, rawFacilities)
		: [];
	const orgs = rawOrgs
		? getFragmentData(FeaturedList_OrganizationFDoc, rawOrgs)
		: [];

	const listItems = value === 'fac' ? facilities : orgs;

	return (
		<Box component='section' sx={sx}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#fff',
				}}
			/>
			<Tabs
				value={value}
				onChange={handleChange}
				sx={{
					pt: { md: 8, xs: 11 },
					mb: 5,
				}}
			>
				<Tab value='org' label='Featured employers' />
				{/*<Tab value='fac' label='Featured facilities' />*/}
			</Tabs>
			<Suspense fallback={<CardGridSkeleton size={GRID_SIZE} />}>
				{loading && <CardGridSkeleton size={GRID_SIZE} />}
				<Box
					sx={{
						flex: '1 1 auto',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Grid
						container
						spacing={3}
						sx={{
							justifyContent: 'center',
						}}
					>
						<>
							{value === 'fac'
								? facilities.map((item, index) => (
										<Grid item key={item.id} xs={12} md={4}>
											<SnippetCard
												title={item?.name}
												subtitle={
													!item.contracts.length
														? null
														: item.contracts[0].org.name
												}
												rating={item?.reviewsAgg?.agg?.avg?.ratingJob || 0}
												ratingCount={item?.reviewsAgg?.agg?.count}
												image={
													index < 3
														? item?.picture?.publicUrl ||
														  FACILITY_PLACEHOLDER_IMAGE
														: undefined
												}
												infoArray={[
													{
														iconComp: (
															<PracticeIcon sx={{ fontSize: '24px' }} />
														),
														text: item.facilityType,
													},
													{
														iconComp: (
															<StructureIcon
																sx={{ fontSize: '24px', fill: 'none' }}
															/>
														),
														text: !item.contracts.length
															? null
															: item.contracts.length === 1
															? OWNERSHIP_DESCRIPTION_SUCCINT[
																	item.contracts[0].org.ownershipStructure
															  ] || 'N/A'
															: 'Multiple',
													},
												]}
												to={buildInternalLink(RouteUri.FACILITY_REVIEW_LIST, {
													facilityId: [item.id, item.slug],
												})}
												state={{
													backNav: {
														target: 'reviews',
													},
												}}
											/>
										</Grid>
								  ))
								: orgs.map((item, index) => (
										<Grid item key={item.id} xs={12} md={4}>
											<SnippetCard
												logo={
													item.logo?.publicUrl || ORGNIZATION_PLACEHOLDER_IMAGE
												}
												title={item?.name}
												rating={item?.reviewsAgg?.agg?.avg?.ratingOrg || 0}
												ratingCount={item?.reviewsAgg?.agg?.count}
												image={
													index < 3
														? item?.coverPhoto?.publicUrl ||
														  ORGNIZATION_PLACEHOLDER_IMAGE
														: undefined
												}
												infoArray={[
													{
														iconComp: (
															<PracticeIcon sx={{ fontSize: '24px' }} />
														),
														text: item.contractsAgg.aggregate?.count
															? `${formatInteger(
																	item.contractsAgg.aggregate.count,
															  )} ${
																	item.contractsAgg.aggregate?.count === 1
																		? 'facility'
																		: 'facilities'
															  }`
															: 'N/A',
													},
													{
														iconComp: (
															<StructureIcon
																sx={{ fontSize: '24px', fill: 'none' }}
															/>
														),
														text:
															OWNERSHIP_DESCRIPTION_SUCCINT[
																item.ownershipStructure
															] || 'N/A',
													},
												]}
												to={buildInternalLink(
													RouteLink.routes.ORG_REVIEW_LIST,
													{
														orgId: [item.id, item.slug],
													},
												)}
												state={{
													backNav: {
														target: 'reviews',
													},
												}}
											/>
										</Grid>
								  ))}
							{/* Left justify the remainder with empty grid items */}
							{[
								...new Array(GRID_SIZE - (listItems.length % GRID_SIZE)).keys(),
							].map((el, idx) => (
								<Grid
									item
									key={idx}
									xs={12}
									md={4}
									sx={{
										padding: '0px !important',
									}}
								/>
							))}
						</>
					</Grid>
				</Box>
			</Suspense>
		</Box>
	);
};

export default FeaturedList;
