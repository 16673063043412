import { useEffect } from 'react';

import { useSnackbar } from 'notistack';

const useNotifyError = (error, verbose = false) => {
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (!error) {
			return;
		}
		console.error(error);
		enqueueSnackbar(verbose ? error.message : 'An unknown error occurred.', {
			variant: 'error',
		});
	}, [error, enqueueSnackbar, verbose]);
};

export default useNotifyError;
