import React from 'react';

import { KeyboardBackspace } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import BackNavButton, {
	type BackNavButtonProps,
} from '@ivy/components/atoms/BackNavButton';
import BaseTemplate from '@ivy/components/templates/BaseTemplate';
import { use100vh } from '@ivy/lib/hooks';

export interface SpotlightTemplateProps {
	pageTitle?: string;
	pageNoIndex?: boolean;
	pageDescription?: string;
	defaultBackNav?: BackNavButtonProps['backNav'];
	preventBackNav?: boolean;
	children?: React.ReactNode;
}

const SpotlightTemplate = ({
	defaultBackNav,
	preventBackNav = false,
	children,
	pageTitle,
	pageNoIndex,
	pageDescription,
}: SpotlightTemplateProps) => {
	const vh = use100vh();
	const location = useLocation();
	const backNav = preventBackNav
		? null
		: location.state?.['backNav'] ||
		  defaultBackNav || {
				link: -1,
		  };
	// Can't use useElementSize b/c it rounds and it's 0.5px off, so we use flex=1 instead

	return (
		<BaseTemplate
			pageTitle={pageTitle}
			pageNoIndex={pageNoIndex}
			pageDescription={pageDescription}
			globalStyles={{
				body: {
					backgroundColor: '#fff',
				},
			}}
		>
			<Box height={vh} bgcolor='background.paper'>
				{backNav && (
					<Box p={1}>
						<BackNavButton
							backNav={backNav}
							startIcon={<KeyboardBackspace />}
						/>
					</Box>
				)}
				<Box height={backNav ? 'calc(100% - 52.5px)' : '100%'}>{children}</Box>
			</Box>
		</BaseTemplate>
	);
};

export default SpotlightTemplate;
