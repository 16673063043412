import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import Autocomplete from '@ivy/components/molecules/Autocomplete';
import Dropdown from '@ivy/components/molecules/Dropdown';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { useDebounce } from '@ivy/lib/hooks';
import { getInvitee } from '@ivy/lib/storage/invitee';

const DefaultInputComponent = ({ onChange, ...props }) => {
	const handleChange = (ev) => {
		onChange(ev.target.value);
	};

	return <TextField multiline minRows={5} onChange={handleChange} {...props} />;
};

const ReportCorrection_SearchOrgsQDoc = gql(/* GraphQL */ `
	query ReportCorrection_SearchOrgs($search: String!) {
		search: search_organization_by_prefix(
			args: { search: $search }
			order_by: [{ rank: desc }, { org: { name: asc } }, { org: { id: asc } }]
			limit: 50
		) {
			id
			org {
				id
				name
			}
		}
	}
`);

const OrgInput = ({
	error,
	helperText,
	value,
	onChange,
	required,
	...props
}) => {
	const debouncedSearch = useDebounce(value);
	const { data, loading } = useQuery(ReportCorrection_SearchOrgsQDoc, {
		variables: {
			search: debouncedSearch,
		},
		skip: !debouncedSearch,
	});

	const handleChange = (_, nv) => {
		onChange(nv);
	};

	return (
		<Autocomplete
			freeSolo
			autoSelect
			autoHighlight
			blurOnSelect
			selectOnFocus
			disableClearable
			TextFieldProps={{
				error,
				helperText,
				label: 'Employer Group',
				required,
			}}
			options={
				data?.search.filter(({ org }) => org).map(({ org }) => org!.name) || []
			}
			// Override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
			filterOptions={(ops) => ops}
			inputValue={value}
			onInputChange={handleChange}
			loading={loading}
			{...props}
		/>
	);
};

const CORRECTION_TEMPLATES = [
	{
		id: 'employer',
		problem: 'The employer group listed is incorrect.',
		details: 'If you know who staffs this site, please enter their name below.',
		detailsRequired: false,
		InputComponent: OrgInput,
	},
	{
		id: 'facility',
		problem: 'The facility information is incorrect.',
		details: 'Please explain what should be corrected.',
		detailsRequired: true,
	},
	{
		id: 'recruiter',
		problem: 'The recruiter or point of contact is incorrect.',
		details:
			'If you know who the correct recruiter and employer group is, please enter their information below.',
		detailsRequired: false,
	},
	{
		id: 'other',
		problem: 'Other',
		details: 'Please explain more.',
		detailsRequired: true,
	},
];

const CORRECTION_SCHEMA = yup.object({
	problem: yup
		.string()
		.oneOf(CORRECTION_TEMPLATES.map((el) => el.problem))
		.required()
		.label('Problem'),
	details: yup
		.string()
		.when('problem', {
			is: (val) =>
				!!val &&
				CORRECTION_TEMPLATES.find((el) => el.problem === val)?.detailsRequired,
			then: (schema) => schema.required(),
		})
		.label('Details'),
});

const ReportCorrection_SubmitReportMDoc = gql(/* GraphQL */ `
	mutation ReportCorrection_SubmitCorrection(
		$input: facility_correction_insert_input!
	) {
		insert_facility_correction_one(object: $input) {
			id
		}
	}
`);

const ReportCorrection_FacilityFDoc = gql(/* GraphQL */ `
	fragment ReportCorrection_Facility on facility {
		id
		name
	}
`);

interface ReportCorrectionProps extends PopupProps {
	facility: FragmentType<typeof ReportCorrection_FacilityFDoc>;
	defaultSelection?: string;
}

const ReportCorrection = ({
	facility: rawFacility,
	onClose,
	defaultSelection,
	...props
}: ReportCorrectionProps) => {
	const facility = getFragmentData(ReportCorrection_FacilityFDoc, rawFacility);
	const currAcc = useCurrentAccount();
	const { enqueueSnackbar } = useSnackbar();
	const [reportCorrection, { error }] = useMutation(
		ReportCorrection_SubmitReportMDoc,
	);
	const formik = useFormik({
		initialValues: {
			problem: defaultSelection
				? CORRECTION_TEMPLATES.find((el) => el.id === defaultSelection)
						?.problem || ''
				: '',
			details: '',
		},
		validationSchema: CORRECTION_SCHEMA,
		onSubmit: async (values, actions) => {
			try {
				const invitee = getInvitee();
				await reportCorrection({
					variables: {
						input: {
							reporter_id: currAcc?.id,
							reporter_email: invitee?.inviteEmail,
							reporter_org_id: invitee?.inviteOrgId,
							facility_id: facility.id,
							problem: values.problem,
							details: values.details,
						},
					},
				});
				enqueueSnackbar('Your correction has been submitted!', {
					variant: 'success',
				});
				actions.setSubmitting(false);
				onClose && onClose();
			} catch (e) {
				captureException(e, {
					extra: {
						values,
					},
				});
				actions.setSubmitting(false);
			}
		},
		validateOnBlur: false,
	});

	const template =
		!!formik.values.problem &&
		CORRECTION_TEMPLATES.find((el) => el.problem === formik.values.problem);

	const InputComponent =
		template && template?.InputComponent
			? template.InputComponent
			: DefaultInputComponent;

	return (
		<Popup
			title='Report Incorrect Information'
			onClose={onClose}
			{...props}
			actions={
				<>
					<Button
						variant='outlined'
						disabled={formik.isSubmitting}
						onClick={onClose}
						sx={{ mr: 'auto' }}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						disabled={formik.isSubmitting}
						onClick={formik.submitForm}
					>
						Submit
					</Button>
				</>
			}
		>
			<Typography variant='body1'>
				Thanks for taking the time to help us keep the information for{' '}
				<b>{facility.name}</b> updated!
			</Typography>
			<Typography variant='body1' fontWeight='bold' gutterBottom mt={2}>
				Please select which option best describes the problem.
			</Typography>
			<Dropdown
				fullWidth
				options={CORRECTION_TEMPLATES.map((el) => ({
					label: el.problem,
					value: el.problem,
				}))}
				value={formik.values.problem}
				onChange={(nv) => formik.setFieldValue('problem', nv)}
				onBlur={() => formik.setFieldTouched('problem')}
				error={formik.touched.problem && !!formik.errors.problem}
				helperText={formik.touched.problem && formik.errors.problem}
				required
			/>
			{!!template && (
				<>
					<Typography variant='body1' gutterBottom mt={2}>
						{template.details}
					</Typography>
					<InputComponent
						fullWidth
						name='details'
						value={formik.values.details}
						onChange={(nv) => formik.setFieldValue('details', nv)}
						onBlur={() => formik.setFieldTouched('details')}
						error={formik.touched.details && !!formik.errors.details}
						helperText={formik.touched.details && formik.errors.details}
						required={template.detailsRequired}
					/>
				</>
			)}
			{!!error && (
				<Typography variant='caption' color='error.main' component='p'>
					An error occurred, please try again.
				</Typography>
			)}
		</Popup>
	);
};

export default ReportCorrection;
