import React from 'react';

import { Box, Button, useTheme, useMediaQuery } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useAuthInfoLoaded } from '@ivy/gql/hooks';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import AuthAvatar from './AuthAvatar';

const AuthButtons = () => {
	const { openLoginPopup, openSignupPopup } = useAuthPopup();
	const wl = useWhitelabel();
	const theme = useTheme();
	const dense = useMediaQuery(theme.breakpoints.down('lg'), { noSsr: true });

	return (
		<Box id='auth-header' display='flex'>
			<Button
				variant='outlined'
				color='primary'
				fullWidth
				{...(wl.menu.fullSso
					? {
							component: RouteLink,
							to: wl.menu.fullSso,
					  }
					: {
							onClick: () => openLoginPopup(),
					  })}
				size={dense ? 'small' : undefined}
				sx={{
					whiteSpace: 'nowrap',
					mr: {
						xs: 1,
						md: 2,
					},
					flex: '1 1 50%',
				}}
			>
				Log in
			</Button>
			<Button
				variant='contained'
				color='secondary'
				fullWidth
				{...(wl.menu.fullSso
					? {
							component: RouteLink,
							to: wl.menu.fullSso,
					  }
					: {
							onClick: () => openSignupPopup(),
					  })}
				size={dense ? 'small' : undefined}
				sx={{
					whiteSpace: 'nowrap',
					flex: '1 1 50%',
				}}
			>
				Sign up
			</Button>
		</Box>
	);
};

const AuthHeader = ({ ...props }) => {
	const isAuth = useAuthInfoLoaded();

	return <Box {...props}>{isAuth ? <AuthAvatar /> : <AuthButtons />}</Box>;
};

export default AuthHeader;
