import React from 'react';

import { useQuery } from '@apollo/client';
import { Stack, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import ReviewList from '@ivy/components/organisms/ReviewList';
import ReviewRating from '@ivy/components/organisms/ReviewRating';
import { gql } from '@ivy/gql/types';

const FacilityReviews_FacilityQDoc = gql(/* GraphQL */ `
	query FacilityReviews_Facility($facilityId: uuid!) {
		facility: facility_by_pk(id: $facilityId) {
			id
			agg: reviews_aggregate(
				where: {
					active: { _eq: true }
					visible: { _eq: true }
					has_active_contract: { _eq: true }
				}
			) {
				aggregate {
					avg {
						rating: rating_job
					}
					count
				}
			}
			ratings: facility_ratings {
				value: rating_job
				count
			}
			contracts(where: { active: { _eq: true } }) {
				id
				isOwner: is_contract_owner
			}
		}
	}
`);

export interface FacilityReviewsProps {
	facilityId: string;
}

const ROW_SPACING = {
	xs: 8,
	lg: 15,
};

const FacilityReviews = ({ facilityId }: FacilityReviewsProps) => {
	const [searchParams] = useSearchParams();
	const { data, loading, error } = useQuery(FacilityReviews_FacilityQDoc, {
		variables: {
			facilityId: facilityId,
		},
		// Reflect updates to the connection status (via job posting page) or job posting additions/deletions
		// Don't use cache-and-network then cache-first -> infinite loop problem
		fetchPolicy: 'network-only',
	});

	return (
		<DataLoader variant='circular' data={data} loading={loading} error={error}>
			{() => (
				<Box
					sx={{
						my: Object.entries(ROW_SPACING).reduce(
							(tot, [k, v]) => ({
								...tot,
								[k]: v / 2,
							}),
							{},
						),
					}}
				>
					<Stack spacing={{ md: 15, xs: 8 }}>
						<Box component='section'>
							<ReviewRating
								avgRating={data!.facility!.agg.aggregate!.avg!.rating || 0}
								count={data!.facility!.agg.aggregate!.count || 0}
								ratings={data!.facility!.ratings}
							/>
						</Box>
						<Box component='section'>
							<ReviewList
								where={{
									facility_id: {
										_eq: facilityId,
									},
									active: { _eq: true },
								}}
								singleReviewId={searchParams.get('reviewId') || undefined}
								refetchQueries={['FacilityReviews_Facility']}
							/>
						</Box>
					</Stack>
				</Box>
			)}
		</DataLoader>
	);
};

export default FacilityReviews;
