import React, { useState } from 'react';

import { type FetchPolicy, useQuery } from '@apollo/client';
import {
	Box,
	Typography,
	type TextFieldProps,
	type ChipTypeMap,
} from '@mui/material';

import Autocomplete, {
	type AutocompleteProps,
} from '@ivy/components/molecules/Autocomplete';
import { gql, getFragmentData } from '@ivy/gql/types';
import {
	type Search_Facility_By_Prefix_Result_Bool_Exp,
	type FacilitySelect_FacilityFragment,
} from '@ivy/gql/types/graphql';
import { useDebounce } from '@ivy/lib/hooks';
import { adminContext } from '@ivy/views/admin/adminUtil';

const FacilitySelect_FacilityFDoc = gql(`
	fragment FacilitySelect_Facility on facility {
		id
		name
		city
		state
	}
`);

const FacilitySelect_SearchFacilityQDoc = gql(/* GraphQL */ `
	query FacilitySelect_SearchFacility(
		$search: String!
		$preload: Boolean
		$filters: search_facility_by_prefix_result_bool_exp
	) {
		search: search_facility_by_prefix(
			args: { search: $search, preload_empty_search: $preload }
			order_by: [
				{ rank: desc }
				{ facility: { state: asc } }
				{ facility: { city: asc } }
				{ facility: { name: asc } }
				{ facility: { id: asc } }
			]
			where: $filters
			limit: 50
		) {
			id
			facility {
				id
				...FacilitySelect_Facility
			}
		}
	}
`);

interface FacilitySelectProps<
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
	ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
		AutocompleteProps<
			FacilitySelect_FacilityFragment,
			false,
			DisableClearable,
			FreeSolo,
			ChipComponent
		>,
		'renderInput' | 'options' | 'filterOptions' | 'multiple' | 'renderTags'
	> {
	admin?: boolean;
	InputProps?: TextFieldProps;
	noOptions?: (val: boolean) => React.ReactNode;
	filters?: Search_Facility_By_Prefix_Result_Bool_Exp;
	preloadEmptySearch?: boolean;
	fetchPolicy?: FetchPolicy;
	nextFetchPolicy?: FetchPolicy;
}

const FacilitySelect = <
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
	ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
	onChange,
	noOptions,
	admin,
	filters,
	preloadEmptySearch,
	fetchPolicy,
	nextFetchPolicy,
	...props
}: FacilitySelectProps<DisableClearable, FreeSolo, ChipComponent>) => {
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search);
	const { data, loading } = useQuery(FacilitySelect_SearchFacilityQDoc, {
		variables: {
			search: debouncedSearch,
			preload: preloadEmptySearch,
			filters,
		},
		skip: !preloadEmptySearch && !debouncedSearch,
		context: admin ? adminContext : undefined,
		fetchPolicy,
		nextFetchPolicy,
	});

	const options: FacilitySelect_FacilityFragment[] =
		data?.search.map((el) =>
			getFragmentData(FacilitySelect_FacilityFDoc, el.facility!),
		) || [];

	return (
		<Autocomplete<
			FacilitySelect_FacilityFragment,
			false,
			DisableClearable,
			FreeSolo
		>
			fullWidth
			getOptionLabel={(option) =>
				typeof option === 'string'
					? option
					: `${option.name} - ${option.city}, ${option.state}`
			}
			// Prevent MUI from filtering out our suggestions since we also suggest based on city/state, which may
			// not be in the option label
			// Also override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
			filterOptions={(ops) => ops}
			renderOption={(params, option) =>
				typeof option === 'string' ? (
					<li {...params}>{option}</li>
				) : (
					// Override key
					<Box {...params} key={`facility-select-${option.id}`} component='li'>
						<Box>
							<Typography variant='body1'>{option.name}</Typography>
							<Typography variant='caption' component='p'>
								{option.city}, {option.state}
							</Typography>
						</Box>
					</Box>
				)
			}
			options={options}
			inputValue={search}
			onInputChange={(_, newVal) => setSearch(newVal)}
			loading={loading}
			onChange={(ev, nv, ...args) => {
				if (!nv) {
					setSearch('');
				}
				onChange?.(ev, nv, ...args);
			}}
			isOptionEqualToValue={(option, val) => option.id === val.id}
			noOptionsText={noOptions ? noOptions(!!search) : undefined}
			{...props}
		/>
	);
};

export default FacilitySelect;
