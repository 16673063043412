import CheckboxListSelect from '@ivy/components/molecules/CheckboxListSelect';
import {
	SUBSPECIALTY_OPTS,
	PROGRAM_LENGTH_YRS_OPTS,
	FELLOWSHIP_SHIFTS_PER_MONTH_OPTS,
	ADVANCED_DEGREE_OPTS,
	MOONLIGHTING_OPTS,
} from '@ivy/constants/filterOptions';

import {
	prepareBoundsFilters,
	prepareFilters,
	prepareProgramEDVisitsFilters,
} from '../../filters/apiHelpers';
import SliderFilterOption from '../../filters/SliderFilterOption';
import { type FilterDataStructure } from '../../filters/useEntityFilters';
import VolumeFilterOptions from '../VolumeFilterOptions';

export const filters: FilterDataStructure[] = [
	{
		quickBtnId: 'btn-filters-advanceDegrees',
		key: 'advanceDegrees',
		title: 'Advanced Degrees Offered',
		label: 'Advanced degrees offered',
		apiResolver: prepareFilters(
			'latest_survey.survey.advanced_degrees',
			ADVANCED_DEGREE_OPTS,
			undefined,
			true,
		),
		options: ADVANCED_DEGREE_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-moonlightingAllowed',
		key: 'moonlightingAllowed',
		title: 'Is Moonlighting Allowed',
		label: 'Is moonlighting allowed',
		apiResolver: prepareFilters(
			'latest_survey.survey.moonlighting_allowed',
			MOONLIGHTING_OPTS,
		),
		options: MOONLIGHTING_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-trainingOps',
		key: 'trainingOps',
		title: 'Training Opportunities',
		label: 'Training opportunities',
		apiResolver: prepareFilters(
			'latest_survey.survey.subspecialty',
			SUBSPECIALTY_OPTS,
		),
		options: SUBSPECIALTY_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-edvolume',
		key: 'volume',
		label: 'ED visits',
		title: 'Annual ED Visits',
		apiResolver: prepareProgramEDVisitsFilters,
		component: VolumeFilterOptions,
		initialFilterValue: [],
	},
	{
		key: 'programLength',
		title: 'Program Length (Years)',
		tooltipContent: 'How many years is the fellowship program?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.program_length',
			PROGRAM_LENGTH_YRS_OPTS,
			Number.parseInt,
		),
		options: PROGRAM_LENGTH_YRS_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
	{
		key: 'shiftsPerMonth',
		title: 'Shifts per Month',
		tooltipContent:
			'How many clinical shifts per month are required during the fellowship?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.shifts_per_month',
			FELLOWSHIP_SHIFTS_PER_MONTH_OPTS,
			Number.parseInt,
		),
		options: FELLOWSHIP_SHIFTS_PER_MONTH_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
];
