import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';

import { OrgOrUnauthBoundary, withBoundary } from '@ivy/components/boundaries';
import { steps } from '@ivy/components/forms/CheckoutForm';
import DataLoader from '@ivy/components/molecules/DataLoader';
import FormPage from '@ivy/components/organisms/FormPage';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import BillingProvider, {
	useBillingContext,
} from '@ivy/components/providers/BillingProvider';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import {
	CheckoutModes,
	getStripePromise,
	SubscriptionStatus,
	type JobPostingPriceInterval,
} from '@ivy/lib/services/billing';

const stripePromise = getStripePromise();

const CheckoutFlow_InfoQDoc = gql(/* GraphQL */ `
	query CheckoutFlow_Info {
		jobPostingPrices: get_payment_prices {
			id
			interval
			tiers {
				amount
				limit
			}
		}
	}
`);

export const CheckoutFlow_GetActiveLegacyJobPostingsCountQDoc = gql(
	/* GraphQL */ `
		query CheckoutFlow_GetActiveJobPostingsCount($orgId: uuid!) {
			jobPosting: job_posting(
				where: {
					_and: { active: { _eq: true }, contract: { org_id: { _eq: $orgId } } }
				}
			) {
				id
			}
		}
	`,
);

const CheckoutFlow = () => {
	const currAcc = useCurrentAccount();
	const [searchParams, setSearchParams] = useSearchParams();
	const theme = useTheme();
	const stripe = useStripe();
	const elements = useElements();
	const redirect = useRedirect();
	const {
		customer,
		subscription,
		isOrgAdmin,
		adminAccess,
		loading: billingLoading,
		error: billingError,
	} = useBillingContext();
	const { openSignupPopup } = useAuthPopup();
	const { data, loading, error } = useQuery(CheckoutFlow_InfoQDoc);
	const {
		data: legacyJobPostingData,
		loading: legacyJobPostingLoading,
		error: legacyJobPostingError,
	} = useQuery(CheckoutFlow_GetActiveLegacyJobPostingsCountQDoc, {
		variables: { orgId: currAcc?.orgUser?.org.id },
		skip: !currAcc?.orgUser || !currAcc.isOrgUser,
	});

	useEffect(() => {
		if (billingLoading) return;
		const mode = searchParams.get('mode');
		if (elements && customer?.checkoutBankHold)
			elements?.update({ paymentMethodTypes: ['us_bank_account'] });
		if (
			((subscription?.status === SubscriptionStatus.INCOMPLETE ||
				subscription?.status === SubscriptionStatus.UNPAID) &&
				subscription.invoice?.status === 'open') ||
			(subscription?.status === SubscriptionStatus.PAST_DUE &&
				subscription.invoice?.paymentStatus !== 'processing')
		) {
			setSearchParams(
				{
					mode: CheckoutModes.PAYMENT,
				},
				{ replace: true },
			);
		} else if (!subscription && mode && mode !== CheckoutModes.PROCESS) {
			setSearchParams(
				{
					mode: '',
				},
				{ replace: true },
			);
		}
	}, [
		subscription,
		billingLoading,
		elements,
		customer?.checkoutBankHold,
		searchParams,
		setSearchParams,
	]);

	const defaultStep = adminAccess ? searchParams.get('formStep') || '' : '';
	const legacyCount = legacyJobPostingData?.jobPosting.length;

	return (
		<NavTemplate
			backgroundColor={theme.palette.light4.main}
			fullscreen
			pageTitle='Checkout'
			pageDescription='Purchase job posting subscriptions on Ivy.'
			pageNoIndex
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading || billingLoading || legacyJobPostingLoading}
				error={error || billingError || legacyJobPostingError}
			>
				{() => (
					<FormPage
						key={defaultStep}
						initialStep={defaultStep}
						steps={steps}
						refetchQueries={[
							'PaymentView_GetPaymentMethods',
							'InvoicesView_GetInvoices',
						]}
						extra={{
							jobPostingPrices: data!.jobPostingPrices,
							paymentMethods: customer?.paymentMethods || [],
							stripeClient: stripe,
							stripeElements: elements,
							currAcc: currAcc,
							currSubscription: subscription,
							currCustomer: customer,
							redirect: redirect,
							mode: searchParams.get('mode') || undefined,
							promo: searchParams.get('promo') || undefined,
							isOrgAdmin: isOrgAdmin,
							adminAccess: adminAccess,
							legacyJobPostingCount:
								!customer && legacyCount && legacyCount > 0
									? legacyCount
									: undefined,
							openSignupPopup: openSignupPopup,
						}}
						defaultValues={{
							slots: Number(searchParams.get('slots')) || 2,
							interval:
								(searchParams.get('interval') as JobPostingPriceInterval) ||
								undefined,
							name: customer?.fullName || currAcc?.orgUser?.org.name || '',
							email: customer?.email || currAcc?.ci?.email || '',
							streetAddress1: customer?.address1 || '',
							streetAddress2: customer?.address2 || '',
							city: customer?.city || '',
							state: customer?.state || '',
							zipcode: customer?.zipcode || '',
							paymentId: customer?.defaultPaymentId || '',
							newPaymentEntry: !customer?.defaultPaymentId,
						}}
						formikContext={{
							mode: searchParams.get('mode') || undefined,
							subscription: subscription,
						}}
					/>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

const CheckoutFlowWithProvider = () => {
	return (
		<BillingProvider>
			<Elements
				stripe={stripePromise}
				options={{
					mode: 'setup',
					currency: 'usd',
					paymentMethodTypes: ['card', 'us_bank_account'],
					paymentMethodOptions: {
						us_bank_account: {
							verification_method: 'automatic',
						},
					},
				}}
			>
				<CheckoutFlow />
			</Elements>
		</BillingProvider>
	);
};

export default withBoundary(CheckoutFlowWithProvider, OrgOrUnauthBoundary);
