import React, { useState } from 'react';

import { Close } from '@mui/icons-material';
import {
	Box,
	Stack,
	IconButton,
	Typography,
	type SxProps,
	type Theme,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const keyframes = {
	'@keyframes landing-load': {
		'0%': {
			transform: 'translateY(60px)',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0px)',
			opacity: 1,
		},
	},
};

const generateKeyframes = (timing: number) => ({
	...keyframes,
	animation: `landing-load 600ms ease-in-out ${timing}ms`,
	animationFillMode: 'forwards',
	transform: 'translateY(60px)',
	opacity: 0,
});

export interface DisclaimerToastProps {
	sx?: SxProps<Theme>;
	children?: React.ReactNode;
	sessionKey?: string;
}

const CorrectionToast = ({
	sx,
	children,
	sessionKey,
}: DisclaimerToastProps) => {
	const [open, setOpen] = useState(
		sessionKey && sessionStorage.getItem(sessionKey)
			? JSON.parse(sessionStorage.getItem(sessionKey)!)
			: true,
	);

	const handleClickClose = () => {
		if (sessionKey) {
			sessionStorage.setItem(sessionKey, JSON.stringify(false));
		}
		setOpen(false);
	};

	if (!open) {
		return null;
	}

	return (
		<Box
			display='flex'
			sx={combineSx(
				{
					position: 'fixed',
					width: '100%',
					justifyContent: 'center',
					padding: '16px',
					bottom: 0,
					left: 0,
					zIndex: (theme) => theme.zIndex.fab - 10,
					...generateKeyframes(300),
				},
				sx,
			)}
		>
			<Stack
				direction='row'
				alignItems='center'
				sx={{
					flex: '0 1 max-content',
					bgcolor: 'primary.translucent',
					p: 2,
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				}}
			>
				<Typography variant='body2' component='div' color='text.icon'>
					{children}
				</Typography>
				<IconButton
					size='small'
					sx={{
						color: '#bdbdbd',
					}}
					onClick={handleClickClose}
				>
					<Close />
				</IconButton>
			</Stack>
		</Box>
	);
};

export default CorrectionToast;
