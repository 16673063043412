import React, { useCallback, Suspense } from 'react';

import {
	type IHeaderOverride,
	type ILoadingRendererConfig,
	type INoRendererConfig,
} from '@cyntler/react-doc-viewer';
import { CloudDownload, Close } from '@mui/icons-material';
import {
	Dialog,
	DialogContent,
	Box,
	IconButton,
	useTheme,
	type DialogProps,
	Button,
	CircularProgress,
	Typography,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { use100vh } from '@ivy/lib/hooks';
import lazyRetry from '@ivy/lib/util/lazyRetry';
const DocViewer = lazyRetry(
	() => import('@cyntler/react-doc-viewer'),
	'DocViewerPopup_DocViewer',
);

export interface DocViewerPopupProps extends Omit<DialogProps, 'onClose'> {
	url: string;
	onClose: () => void;
}

const DocViewerPopup = ({
	open,
	onClose,
	url,
	...props
}: DocViewerPopupProps) => {
	const vh = use100vh();
	const theme = useTheme();

	const renderHeader: IHeaderOverride = useCallback(() => {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					bgcolor: 'background.paper',
					p: 1,
				}}
			>
				<Button
					component={RouteLink}
					openInNewTab
					to={url}
					startIcon={<CloudDownload />}
				>
					Download
				</Button>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Box>
		);
	}, [onClose, url]);

	const renderLoader: ILoadingRendererConfig['overrideComponent'] =
		useCallback(() => {
			return (
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					width='100%'
					height='100%'
				>
					<CircularProgress color='primary' />
					<Typography variant='caption' align='center' mt={2}>
						If this document takes too long to load, you can{' '}
						<RouteLink to={url} openInNewTab>
							download the file.
						</RouteLink>
					</Typography>
				</Box>
			);
		}, [url]);

	const noRendererComponent: INoRendererConfig['overrideComponent'] =
		useCallback(() => {
			return (
				<Box
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					width='100%'
					height='100%'
				>
					<Typography variant='body1' align='center' mb={2}>
						Unable to display file.
					</Typography>
					<Button
						variant='contained'
						color='primary'
						component={RouteLink}
						openInNewTab
						to={url}
						startIcon={<CloudDownload />}
					>
						Download
					</Button>
				</Box>
			);
		}, [url]);

	return (
		<Suspense
			fallback={
				open ? (
					<DataLoader loading fullscreen variant='circular' transparent />
				) : null
			}
		>
			<Dialog
				open={open}
				onClose={onClose}
				fullWidth
				maxWidth='lg'
				sx={{
					'& .MuiDialog-paper': {
						width: {
							xs: '100%',
							sm: 'calc(100% - 64px)',
						},
						maxHeight: {
							xs: '100%',
							sm: 'calc(100% - 64px)',
						},
						margin: {
							xs: 0,
							sm: 4,
						},
					},
				}}
				{...props}
			>
				<DialogContent
					sx={{
						height: {
							xs: vh,
							sm: '90vh',
						},
						padding: 0,
					}}
				>
					<Box
						sx={{
							height: '100%',
							'& #pdf-download': {
								display: 'none',
							},
							'& #pdf-zoom-reset': {
								display: 'none',
							},
							'& #pdf-toggle-pagination': {
								display: 'none',
							},
							'& .textLayer': {
								display: 'none',
							},
							'& .annotationLayer': {
								display: 'none',
							},
						}}
					>
						<DocViewer
							documents={[
								{
									uri: url,
								},
							]}
							theme={{
								primary: theme.palette.background.paper,
								secondary: theme.palette.primary.main,
								tertiary: theme.palette.primary.subtle,
								textPrimary: theme.palette.text.primary,
								// text_secondary: "#5296d8",
								// text_tertiary: "#00000099",
								disableThemeScrollbar: true,
							}}
							style={{
								width: '100%',
								height: '100%',
							}}
							config={{
								header: {
									overrideComponent: renderHeader,
								},
								loadingRenderer: {
									overrideComponent: renderLoader,
								},
								noRenderer: {
									overrideComponent: noRendererComponent,
								},
								pdfVerticalScrollByDefault: true,
							}}
						/>
					</Box>
				</DialogContent>
			</Dialog>
		</Suspense>
	);
};

export default DocViewerPopup;
