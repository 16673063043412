import React from 'react';

import { School } from '@mui/icons-material';
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
} from '@mui/lab';
import { ListItemText } from '@mui/material';
import { RiStethoscopeLine, RiHeartPulseFill } from 'react-icons/ri';

import EmptySectionText from '@ivy/components/atoms/EmptySectionText';
import { TrainingType } from '@ivy/constants/clinician';
import { SCHOOL_FOR_DEGREE } from '@ivy/constants/clinician';
import { type ClinicianProfile_ClinicianFragment } from '@ivy/gql/types/graphql';
import { bulletJoin, titlelize } from '@ivy/lib/formatting/string';

// https://stackoverflow.com/questions/63213925/material-ui-remove-timelineitem-missingoppositecontentbefore-element
// https://github.com/mui-org/material-ui/blob/v4.11.0/packages/material-ui-lab/src/TimelineItem/TimelineItem.js#L36
// Would need to adapt to use { styled } from '@mui/material/styles' with
// https://github.com/mui-org/material-ui/blob/master/packages/mui-lab/src/TimelineItem/TimelineItem.js
// const StyledTimelineItem = withStyles({
// 	missingOppositeContent: {
// 		'&:before': {
// 			display: 'none'
// 		}
// 	}
// })(TimelineItem);

// const StyledTimelineItem = styled(TimelineItem)(({ ownerState }) => ({
// 	...(!ownerState.hasOppositeContent && {
// 		'&:before': {
// 			display: 'none'
// 		}
// 	})
// }));

const formatStartEnd = (startYear: number, endYear?: number | null) => {
	return `${startYear} - ${endYear || 'Present'}`;
};

export interface EdTrainingTimelineProps {
	clinician: ClinicianProfile_ClinicianFragment;
}

const EdTrainingTimeline = ({ clinician }: EdTrainingTimelineProps) => {
	const {
		id: clinicianId,
		gradYr,
		schoolName,
		currStudent,
		profDegree,
		training = [],
	} = clinician;
	const schoolType = SCHOOL_FOR_DEGREE[profDegree];
	const elements: {
		id: string;
		primaryText: string;
		secondaryText: string;
		icon: React.ComponentType<{ style: object }>;
		endYear?: number | null;
		type: 'school' | TrainingType;
	}[] = [];
	if (schoolName) {
		elements.push({
			id: clinicianId,
			primaryText: schoolName,
			secondaryText: bulletJoin([
				schoolType,
				currStudent ? 'Current Student' : `Class of ${gradYr}`,
			]),
			icon: School,
			endYear: gradYr,
			type: 'school',
		});
	}
	training.forEach(({ id, program, type, field, startYear, endYear }) => {
		elements.push({
			id: id,
			primaryText: program,
			secondaryText: bulletJoin([
				type === TrainingType.APP
					? 'Postgraduate Specialty Training'
					: titlelize(type),
				field,
				formatStartEnd(startYear, endYear),
			]),
			icon:
				type === TrainingType.FELLOWSHIP ? RiHeartPulseFill : RiStethoscopeLine,
			endYear: endYear,
			type,
		});
	});

	// Sort from most recent to oldest
	elements.sort((a, b) => {
		// Return negative if a is more recent than b
		// Return positive if b is more recent than a

		if (a.endYear != null && b.endYear != null && a.endYear !== b.endYear) {
			// Return positive if b more recent than a, otherwise negative
			return b.endYear - a.endYear;
		}
		// If the end year is null, put it as the newest (null means present)
		// Check for null or undefined
		if (a.endYear == null && b.endYear != null) {
			return -1;
		}
		if (b.endYear == null && a.endYear != null) {
			return 1;
		}

		// Have null end years or equal end years - sort by some other property
		const typePreference = [
			TrainingType.FELLOWSHIP,
			TrainingType.RESIDENCY,
			'school',
		];
		// Equal end years, sort by typePreference and then lexicographically
		const aIndex = typePreference.indexOf(a.type);
		const bIndex = typePreference.indexOf(b.type);
		// E.g. fellowship (0) - school (2) = -2, meaning the fellowship is more recent
		// May be 0, so in that case, will be indeterministic
		return aIndex - bIndex;
	});

	if (!elements.length) {
		return (
			<EmptySectionText
				variant='body2'
				text='No education or training listed.'
			/>
		);
	}

	return (
		<Timeline
			sx={{
				margin: 0,
				p: 0,
			}}
		>
			{elements.map(({ id, primaryText, secondaryText, icon: Icon }, idx) => (
				<TimelineItem
					sx={{
						'&:before': {
							display: 'none',
						},
					}}
					key={`clinician-timeline-item-${id}`}
				>
					<TimelineSeparator>
						<TimelineConnector
							sx={{
								visibility: idx === 0 ? 'hidden' : undefined,
							}}
						/>
						<TimelineDot
							sx={{
								bgcolor: 'secondary.main',
							}}
						>
							<Icon
								style={{
									height: '24px',
									width: '24px',
								}}
							/>
						</TimelineDot>
						<TimelineConnector
							sx={{
								visibility: idx === elements.length - 1 ? 'hidden' : undefined,
							}}
						/>
					</TimelineSeparator>
					<TimelineContent sx={{ py: '12px', px: 2 }}>
						<ListItemText
							primary={primaryText}
							primaryTypographyProps={{
								variant: 'body1',
								// fontWeight: 'bold'
							}}
							secondary={secondaryText}
							secondaryTypographyProps={{
								variant: 'body2',
							}}
						/>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};

export default EdTrainingTimeline;
