import React from 'react';

import { Navigate } from 'react-router-dom';

import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

const AdminBoundary = ({ children }: { children?: React.ReactNode }) => {
	const currAcc = useCurrentAccount();

	if (!currAcc?.superuser) {
		return <Navigate to={RouteUri.ROOT} replace />;
	}
	return children;
};

export default AdminBoundary;
