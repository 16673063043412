import RouteLink from '@ivy/components/atoms/RouteLink';
import { type TourStructure } from '@ivy/components/providers/TourProvider/MainTour';
import { gql } from '@ivy/gql/types';

import {
	orgUserOnboardingTourSteps,
	mobileOrgUserOnboardingTourSteps,
} from './Steps';

import type { ApolloClient } from '@apollo/client';

export enum Tours {
	ORG_USER_ONBOARDING = 'orgUserOnboardingTour',
}

export enum TourSignals {
	FACILITY_TAB_VISIBLE = 'facilityTabVisible',
}

const OrgUserOnboardingTour_QDoc = gql(/* GraphQL */ `
	mutation OrgUserOnboardingTour($accountId: uuid!) {
		update_account_by_pk(
			_set: { onboarding_tour_completed: true }
			pk_columns: { id: $accountId }
		) {
			id
		}
	}
`);

const handleOrgUserComplete = async (
	client: ApolloClient<object>,
	params: { accountId: string },
) => {
	await client.mutate({
		mutation: OrgUserOnboardingTour_QDoc,
		variables: { accountId: params.accountId },
	});
};

const OrgUserOnboardingTour: TourStructure = {
	key: Tours.ORG_USER_ONBOARDING,
	origin: RouteLink.routes.MANAGEMENT_HOME,
	tourSteps: orgUserOnboardingTourSteps,
	mobileTourSteps: mobileOrgUserOnboardingTourSteps,
	onComplete: handleOrgUserComplete,
};

export const ToursMap = {
	[Tours.ORG_USER_ONBOARDING]: OrgUserOnboardingTour,
};
