import React, { useState, useRef } from 'react';

import { type ApolloClient } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Autocomplete,
	Stack,
	Box,
	TextField,
	Button,
	Typography,
	Grid,
	MenuItem,
	IconButton,
	InputAdornment,
	Menu,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import FormattedInput from '@ivy/components/atoms/FormattedInput';
import RouteLink from '@ivy/components/atoms/RouteLink';
import SupportLink from '@ivy/components/atoms/SupportLink';
import { PRODUCT_NAME } from '@ivy/constants/brand';
import { RouteUri } from '@ivy/constants/routes';
import { gql } from '@ivy/gql/types';
import { type OrgSignupForm_SearchOrgsQuery } from '@ivy/gql/types/graphql';
import { getInputProps } from '@ivy/lib/forms/formikHelpers';
import { useDebounce } from '@ivy/lib/hooks';
import { getAcqParams } from '@ivy/lib/hooks/useAcqParams';
import { clearInvitee } from '@ivy/lib/storage/invitee';
import { PASSWORD_SCHEMA } from '@ivy/lib/validation/password';
import { formatPhoneNumber, PHONE_REGEX } from '@ivy/lib/validation/phone';

import { type SubmitFunction } from './onboardingForm';

interface OrganizationOptionData {
	label: string;
	value: string;
	org: OrgSignupForm_SearchOrgsQuery['search'][0]['org'] | null;
}

const OrgSignupForm_SearchOrgsQDoc = gql(/* GraphQL */ `
	query OrgSignupForm_SearchOrgs($search: String!) {
		search: search_organization_by_prefix(
			args: { search: $search }
			order_by: [{ rank: desc }, { org: { name: asc } }, { org: { id: asc } }]
			limit: 50
		) {
			id
			org {
				id
				name
				domainSignups: domain_signups
				domains {
					id
					name
				}
			}
		}
	}
`);

const OrgSignupForm_SelectedOrgQDoc = gql(/* GraphQL */ `
	query OrgSignupForm_SelectedOrg($orgId: uuid!) {
		org: organization_by_pk(id: $orgId) {
			id
			name
		}
	}
`);

const OrganizatonAutocomplete = ({
	formik,
	currAccLoading,
}: {
	formik: FormikProps<OrganizationFormValues>;
	currAccLoading: boolean;
}) => {
	const {
		values,
		touched,
		errors,
		setFieldValue,
		setFieldTouched,
		isSubmitting,
	} = formik;
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search);
	const { data, loading, error } = useQuery(OrgSignupForm_SearchOrgsQDoc, {
		variables: {
			search: debouncedSearch,
		},
		skip: !debouncedSearch,
	});
	const { data: selectedData } = useQuery(OrgSignupForm_SelectedOrgQDoc, {
		variables: {
			orgId: values.orgId,
		},
		skip: !values.orgId,
	});

	const emptyDomains = values.organization?.org?.domains.length === 0;
	const domainSignups = values.organization?.org?.domainSignups;

	const orgOptions =
		data?.search
			.filter((el) => el.org)
			.map((el) => ({
				label: el.org!.name,
				value: el.org!.id,
				org: el.org!,
			})) || [];

	if (values.orgId) {
		return (
			<TextField
				fullWidth
				label='Employer'
				required
				value={selectedData?.org?.name}
				disabled
			/>
		);
	}

	return (
		<Autocomplete
			selectOnFocus
			clearOnBlur
			autoHighlight
			fullWidth
			// Override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
			filterOptions={(ops) => ops}
			renderInput={({ ...params }) => (
				<TextField
					{...params}
					required
					label='Employer'
					placeholder='Select employer group'
					error={
						(touched.organization && !!errors.organization) ||
						!!error ||
						domainSignups === false ||
						emptyDomains
					}
					helperText={
						(touched.organization && errors.organization) ||
						(!!error && 'An unknown error occurred, please try again.') ||
						// Strict comparison to false to avoid undefined
						// In this situation, they changed the setting so that domain signups are not allowed
						// Opt: Give them the admin details to contact for an invitation
						(domainSignups === false && (
							<>
								This organization can only be accessed via invitation. Contact
								your group's administrator to be invited, or{' '}
								<SupportLink>contact support</SupportLink> for additional help.
							</>
						)) ||
						// In this situation, they either deleted all email domains or we couldn't find any for them
						// If they have an admin, we should contact their admin. Otherwise, we should create a magic
						// signup link for the user to complete registration. Or, if we find a suitable email domain,
						// add that to their account.
						// Opt: Make it impossible to have domainSignups turned on with empty email domains
						(emptyDomains && (
							<>
								This organization can only be accessed via invitation.{' '}
								<SupportLink>Contact support</SupportLink> to complete signup.
							</>
						))
					}
				/>
			)}
			getOptionLabel={(option) => option?.label}
			options={[...orgOptions] as OrganizationOptionData[]}
			inputValue={search}
			onInputChange={(_, newVal) => setSearch(newVal)}
			value={values.organization}
			onChange={(_, newVal) => {
				setFieldValue('organization', newVal);
				const domainLength = newVal?.org?.domains.length || 0;
				if (domainLength > 0) {
					const domain = newVal?.org?.domains[0]?.name || '';
					setFieldValue('domain', domain, false);
				}
			}}
			onBlur={() => setFieldTouched('organization')}
			noOptionsText={
				!search
					? 'Start typing for results'
					: "Don't see your employer group? Try searching for a parent or subsidiary company, or contact support below to get added."
			}
			loading={loading}
			disabled={isSubmitting || currAccLoading}
			isOptionEqualToValue={(option, value) => option?.value === value?.value}
		/>
	);
};

interface EmailInputProps {
	formik: FormikProps<OrganizationFormValues>;
	onClickReset: () => void;
	currAccLoading: boolean;
}

const formatEmailPrefix = (val: string) =>
	!val ? val : val.replace(/[\s@]/g, '');

const EmailInput = ({
	formik,
	onClickReset,
	currAccLoading,
}: EmailInputProps) => {
	const { values, setFieldValue, isSubmitting } = formik;
	const chosenOrg = values.organization?.org;
	const domainBtnRef = useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const handleClickDomain = (ev: React.SyntheticEvent) => {
		ev.stopPropagation();
		setMenuOpen(true);
	};

	const handleClose = () => {
		setMenuOpen(false);
	};

	const handleSelect = (opt: string) => () => {
		setFieldValue('domain', opt);
		handleClose();
	};

	const hasDomains = !!chosenOrg?.domains && chosenOrg.domains.length > 0;

	if (values.email) {
		return (
			<Stack>
				<TextField
					fullWidth
					label='Email'
					required
					value={values.email}
					disabled
				/>
				<Typography variant='body2'>
					Not your email?{' '}
					<Button
						size='small'
						onClick={onClickReset}
						sx={{ p: 0 }}
						disabled={isSubmitting || currAccLoading}
					>
						Reset here
					</Button>
				</Typography>
			</Stack>
		);
	}

	return (
		<Stack>
			<FormattedInput
				// Don't add type='email' here since this is just the prefix
				fullWidth
				required
				label='Email'
				name='emailPrefix'
				placeholder='Enter email'
				format={formatEmailPrefix}
				{...getInputProps(formik, 'emailPrefix', {
					disabled: !chosenOrg || !hasDomains || currAccLoading,
				})}
				InputProps={{
					sx: {
						'& .MuiInputBase-input': {
							minWidth: '75px',
							flex: '1 1',
						},
					},
					endAdornment:
						chosenOrg && hasDomains ? (
							<InputAdornment
								position='end'
								sx={{
									flex: '0 1 max-content',
									height: 'auto',
									maxHeight: 'none',
								}}
							>
								{chosenOrg.domains.length === 1 ? (
									<Box display='flex' alignItems='center'>
										<Typography variant='body1'>@</Typography>
										<Typography
											variant='body1'
											sx={{
												whiteSpace: 'initial',
												wordBreak: 'break-all',
											}}
											align='left'
										>
											{values.domain}
										</Typography>
									</Box>
								) : (
									<>
										<Button
											ref={domainBtnRef}
											disabled={isSubmitting || currAccLoading}
											onClick={handleClickDomain}
											endIcon={menuOpen ? <ExpandLess /> : <ExpandMore />}
											sx={(theme) => ({
												...theme.typography.body1,
												color: 'text.secondary',
											})}
										>
											<Box display='flex' alignItems='center'>
												<Typography variant='body1'>@</Typography>
												<Typography
													variant='body1'
													sx={{
														whiteSpace: 'initial',
														wordBreak: 'break-all',
													}}
													align='left'
												>
													{values.domain}
												</Typography>
											</Box>
										</Button>
										<Menu
											anchorEl={domainBtnRef.current}
											open={menuOpen}
											onClose={handleClose}
											anchorOrigin={{
												horizontal: 'center',
												vertical: 'bottom',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
											}}
										>
											{chosenOrg
												? chosenOrg.domains.map(
														({ id, name }: { id: string; name: string }) => (
															<MenuItem
																key={`org-domain-item-${id}`}
																onClick={handleSelect(name)}
																selected={name === values.domain}
																sx={{
																	whiteSpace: 'initial',
																	wordBreak: 'break-word',
																}}
															>
																@{name}
															</MenuItem>
														),
												  )
												: []}
										</Menu>
									</>
								)}
							</InputAdornment>
						) : undefined,
				}}
			/>
			<Typography
				variant='caption'
				component='p'
				display={
					chosenOrg?.domains && chosenOrg?.domains.length > 1 ? 'block' : 'none'
				}
			>
				You can select an alternative email domain by clicking on it.
			</Typography>
		</Stack>
	);
};

interface OrganizationSignupStepProps {
	formik: FormikProps<OrganizationFormValues>;
	onClickReset: () => void;
	currAccLoading: boolean;
}

interface OrganizationFormValues {
	organization: OrganizationOptionData | null;
	firstName: string;
	lastName: string;
	phone: string;
	password: string;
	emailPrefix: string;
	domain: string;

	// Invitee values
	orgId: string;
	email: string;
}

const OrganizationSignupStep_SignUpOrgUserMDoc = gql(/* GraphQL */ `
	mutation OrganizationSignupStep_SignUpOrgUser($input: SignUpOrgUserInput!) {
		registration: sign_up_org_user(input: $input) {
			success
		}
	}
`);

// TODO: add schema to object
const validation = yup.object({
	firstName: yup.string().max(50).required().label('First name'),
	lastName: yup.string().max(50).required().label('Last name'),
	phone: yup
		.string()
		.matches(PHONE_REGEX, 'Must be of the format (XXX) XXX-XXXX')
		.required()
		.label('Phone number'),
	password: PASSWORD_SCHEMA,
	emailPrefix: yup
		.string()
		.label('Email')
		.when('email', {
			is: (val: boolean) => !val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema,
		}),
	domain: yup
		.string()
		.label('Domain')
		.when('email', {
			is: (val: boolean) => !val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema,
		}),
	organization: yup
		.object()
		.typeError('Employer group is required')
		.label('Employer group')
		.when('orgId', {
			is: (val: boolean) => !val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.nullable(),
		}),

	orgId: yup.string(),
	email: yup.string(),
});

const initialValue = {
	organization: null,
	firstName: '',
	lastName: '',
	phone: '',
	password: '',
	emailPrefix: '',
	// Shouldn't ever get to this step if no domains to choose from
	domain: '',

	orgId: '',
	email: '',
};

const submit: SubmitFunction = async (
	values,
	actions,
	client: ApolloClient<object>,
	handleSuccess,
	handleError,
) => {
	try {
		const orgId = values.orgId || values.organization?.org?.id;
		const fullEmail = values.email || `${values.emailPrefix}@${values.domain}`;

		await client.mutate({
			mutation: OrganizationSignupStep_SignUpOrgUserMDoc,
			variables: {
				input: {
					org_id: orgId,
					email: fullEmail,
					password: values.password,
					first_name: values.firstName,
					last_name: values.lastName,
					phone: values.phone,
					...getAcqParams(),
				},
			},
		});
		clearInvitee();

		handleSuccess('Check your inbox for a link to confirm your email.');
	} catch (e) {
		handleError(e, {
			extra: {
				// Password will be redacted in Sentry's beforeSend
				values,
			},
		});
	}
};

const OrganizationSignupStep = ({
	formik,
	onClickReset,
	currAccLoading,
}: OrganizationSignupStepProps) => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (ev: React.MouseEvent) => {
		ev.preventDefault();
	};

	return (
		<Stack
			sx={{
				justifyContent: {
					sm: 'space-between',
					xs: 'normal',
				},
				alignItems: 'center',
				margin: 'auto',
				height: '100%',
			}}
		>
			<Typography
				variant='h3'
				component='h1'
				sx={{
					textAlign: 'center',
					maxWidth: '683px',
					mx: 'auto',
					mb: { sm: 10, xs: 5 },
				}}
			>
				Sign up to find EM clinicians
			</Typography>
			<Grid container spacing={{ sm: 5, xs: 4 }}>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='First name'
						name='firstName'
						placeholder='Enter your first name'
						{...getInputProps(formik, 'firstName', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='Last name'
						name='lastName'
						placeholder='Enter your last name'
						{...getInputProps(formik, 'lastName', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<OrganizatonAutocomplete
						formik={formik}
						currAccLoading={currAccLoading}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<EmailInput
						formik={formik}
						onClickReset={onClickReset}
						currAccLoading={currAccLoading}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='Password'
						name='password'
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
						placeholder='Enter 8 or more characters'
						{...getInputProps(formik, 'password', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormattedInput
						fullWidth
						required
						label='Phone'
						name='phone'
						type='tel'
						placeholder='Enter your phone number'
						format={formatPhoneNumber}
						{...getInputProps(formik, 'phone', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography
						sx={{ typography: { sm: 'body1', xs: 'caption' } }}
						color='text.icon'
					>
						By clicking Sign Up, I agree to the {PRODUCT_NAME}{' '}
						<Typography
							component={RouteLink}
							to={RouteUri.TOS}
							variant='inherit'
							fontWeight='bold'
							color='primary.main'
							openInNewTab
						>
							Terms of Service
						</Typography>
						{' and '}
						<Typography
							component={RouteLink}
							to={RouteUri.PRIVACY_POLICY}
							variant='inherit'
							fontWeight='bold'
							color='primary.main'
							openInNewTab
						>
							Privacy Policy
						</Typography>
						.
					</Typography>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default OrganizationSignupStep;
export {
	type OrganizationFormValues,
	type OrganizationOptionData,
	type OrganizationSignupStepProps,
	validation,
	initialValue,
	submit,
};
