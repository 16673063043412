import React, { useEffect, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Box, Button, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import CheckboxSelect from '@ivy/components/molecules/CheckboxSelect';
import DataLoader from '@ivy/components/molecules/DataLoader';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import {
	SuppressionCategory,
	SuppressionCategoryDescription,
} from '@ivy/constants/account';
import { gql } from '@ivy/gql/types';

const SuppressionList_PreferencesQDoc = gql(/* GraphQL */ `
	query SuppressionList_Preferences($token: String!) {
		suppressions: email_suppressions(token: $token) {
			id
			category
		}
	}
`);

const SuppressionList_SavePreferencesMDoc = gql(/* GraphQL */ `
	mutation SuppressionList_SavePreferences(
		$token: String!
		$categories: [String!]!
	) {
		set_email_suppressions(token: $token, categories: $categories) {
			id
		}
	}
`);

const DEFAULT_TRUE = Object.keys(SuppressionCategoryDescription).reduce(
	(tot, k) => ({
		...tot,
		[k]: true,
	}),
	{},
);

const DEFAULT_FALSE = Object.keys(SuppressionCategoryDescription).reduce(
	(tot, k) => ({
		...tot,
		[k]: false,
	}),
	{},
);

const SuppressionList = () => {
	const [prefs, setPrefs] = useState(DEFAULT_TRUE);
	const [unsubAll, setUnsubAll] = useState(false);
	const [dirty, setDirty] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { token } = useParams();
	const { data, loading, error } = useQuery(SuppressionList_PreferencesQDoc, {
		variables: {
			token: token || '',
		},
		skip: !token,
	});

	const [savePrefs, { loading: saving }] = useMutation(
		SuppressionList_SavePreferencesMDoc,
		{
			variables: {
				token: token || '',
				categories: unsubAll
					? [SuppressionCategory.ALL]
					: Object.entries(prefs)
							.filter(([_, v]) => !v)
							.map(([k]) => k),
			},
		},
	);

	useEffect(() => {
		if (!data) {
			return;
		}
		setPrefs(
			Object.assign(
				{},
				DEFAULT_TRUE,
				data.suppressions
					.filter((el) =>
						Object.keys(SuppressionCategoryDescription).includes(el.category),
					)
					.reduce(
						(tot, { category }) => ({
							...tot,
							[category]: false,
						}),
						{},
					),
			),
		);
		setUnsubAll(
			!!data.suppressions.find((el) => el.category === SuppressionCategory.ALL),
		);
	}, [data, setPrefs, setUnsubAll]);

	const handleChangePrefs = (nv) => {
		setPrefs(nv);
		setDirty(true);
	};

	const handleChangeUnsubAll = (_, nv) => {
		setUnsubAll(nv);
		setDirty(true);
	};

	const handleClickSubmit = async () => {
		try {
			await savePrefs();
			setDirty(false);
			enqueueSnackbar('Your preferences have been saved.', {
				variant: 'success',
			});
		} catch (e) {
			enqueueSnackbar('Unable to save your preferences, please try again.', {
				variant: 'error',
			});
		}
	};

	return (
		<NavTemplate
			pageTitle='Notification Preferences'
			pageNoIndex
			showSupportLauncher
		>
			<Box
				sx={{
					minHeight: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DataLoader data={data} loading={loading} error={error} fullscreen>
					{() => (
						<Box>
							<Box
								sx={{
									mb: 2,
								}}
							>
								<CheckboxSelect
									label='Which emails would you like to receive?'
									options={Object.entries(SuppressionCategoryDescription).map(
										([k, v]) => ({
											label: v,
											value: k,
										}),
									)}
									value={unsubAll ? DEFAULT_FALSE : prefs}
									onChange={handleChangePrefs}
									disabled={unsubAll}
								/>
							</Box>
							<Divider />
							<Box
								sx={{
									mt: 2,
									mb: 4,
								}}
							>
								<LabeledCheckbox
									label='Unsubscribe me from all communication'
									LabelProps={{ color: 'text.primary' }}
									value={unsubAll}
									onChange={handleChangeUnsubAll}
								/>
							</Box>
							<Box>
								<Button
									variant='contained'
									onClick={handleClickSubmit}
									disabled={!dirty || saving}
								>
									Submit
								</Button>
							</Box>
						</Box>
					)}
				</DataLoader>
			</Box>
		</NavTemplate>
	);
};

export default SuppressionList;
