import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import isBoolean from 'lodash/isBoolean';

import { Platform } from '@ivy/constants/site';
import { gql, getFragmentData } from '@ivy/gql/types';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import SettingsModal from './SettingsModal';
import SiteSettingsContext from './SiteSettingsContext';

export interface SiteSettingsProps {
	children?: React.ReactNode;
}

const SiteSettingsProvider_SearchSiteQDoc = gql(/* GraphQL */ `
	query SiteSettingsProvider_SearchSite($platform: platform!) {
		site(where: { platform: { _eq: $platform } }) {
			id
			...SiteSettingsProvider_Site
		}
	}
`);

const SiteSettingsProvider_SiteFDoc = gql(/* GraphQL */ `
	fragment SiteSettingsProvider_Site on site {
		id
		platform
		emraFeaturedTrainingType: emra_featured_training_type
	}
`);

export const resolvePlatform = (name: string): string => {
	let plat = '';
	switch (name) {
		case 'emra':
			plat = Platform.EMRA_MATCH;
			break;
		default:
			plat = Platform.DEFAULT;
			break;
	}
	return plat;
};

const SiteSettingsProvider = ({ children }: SiteSettingsProps) => {
	const [modalOpen, setModal] = useState(false);
	const wl = useWhitelabel();
	const { data, loading, error } = useQuery(
		SiteSettingsProvider_SearchSiteQDoc,
		{
			fetchPolicy: 'network-only',
			variables: { platform: resolvePlatform(wl.name) },
		},
	);

	const handleToggleModal = (open: boolean) => {
		isBoolean(open) ? setModal(open) : setModal((val) => !val);
	};

	const res = data?.site
		? getFragmentData(SiteSettingsProvider_SiteFDoc, data.site)[0]
		: null;

	const mapProviderValue = useMemo(() => {
		return {
			toggleSiteSettings: handleToggleModal,
			currSiteSettings: res,
			loading,
			error,
		};
	}, [res, loading, error]);

	return (
		<SiteSettingsContext.Provider value={mapProviderValue}>
			{children}
			<SettingsModal
				open={modalOpen}
				onClose={() => handleToggleModal(false)}
				loading={loading}
				data={res}
			/>
		</SiteSettingsContext.Provider>
	);
};

export default SiteSettingsProvider;
