import React from 'react';

import { Typography, Stack, type Theme, useMediaQuery } from '@mui/material';
import { type FormikProps } from 'formik-othebi';

import { StarIcon } from '@ivy/components/icons';
import ReviewCard from '@ivy/components/molecules/ReviewCard';
import { useCurrentAccount } from '@ivy/gql/hooks';

import { type ReviewFormValues } from './reviewForm';

interface PreviewProps {
	formik: FormikProps<ReviewFormValues>;
}

const PreviewStep = ({ formik }: PreviewProps) => {
	const { values } = formik;
	const currAcc = useCurrentAccount();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	const currentDate = new Date().toISOString();
	const reviewObj = {
		active: true,
		createdAt: currentDate,
		currentlyWorking: values.isCurrentlyWorking,
		ratingJob: values.jobRating,
		ratingFacility: values.facilityRating.rating,
		ratingOrg: values.employerRating.rating,
		mostHelpful: false,
		lastWorkedDate: values.lastWorkedDate,
		employmentDate: values.isCurrentlyWorking
			? currentDate
			: values.lastWorkedDate?.toISOString(),
		reporterName: values.isAnonymous
			? 'Anonymous'
			: currAcc?.pi?.fullName || '',
		facilityContent: values.facilityRating.content,
		orgContent: values.employerRating.content,
		facility: {
			name: values.facility?.name,
		},
		org: {
			name: values.org?.name,
		},
	};

	return (
		<Stack direction='column' sx={{ width: '100%' }} spacing={5}>
			<Typography
				variant={isMobile ? 'body2' : 'body1'}
				fontWeight={{ sm: 'bold', xs: 'inherit' }}
				sx={{ mt: { sm: 0, xs: 5 } }}
				color='text.icon'
			>
				This is what your review will look like. If you’re happy with the result
				go ahead and hit the submit button. If not, you can go back and edit.
			</Typography>
			<ReviewCard
				// @ts-ignore
				review={reviewObj}
				viewOnly
			/>
			<Stack
				direction='row'
				alignItems='center'
				sx={{ display: { sm: 'flex', xs: 'none' } }}
			>
				<Typography
					variant='h6'
					sx={{
						display: { sm: 'flex', xs: 'none' },
						alignItems: 'center',
						mr: 1,
					}}
				>
					Overall job rating
				</Typography>
				<StarIcon sx={{ mr: 1, fontSize: '18px' }} />{' '}
				<Typography
					variant='body2'
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{values.jobRating}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default PreviewStep;
