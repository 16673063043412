import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Typography, type TypographyProps } from '@mui/material';
import { useSnackbar } from 'notistack';

import { gql } from '@ivy/gql/types';
import { useErrorReporting, useNotifyError } from '@ivy/lib/hooks';

const ResendVerification_ResendMDoc = gql(/* GraphQL */ `
	mutation ResendVerification_Resend($email: String!) {
		resend: resend_verification(email: $email) {
			success
		}
	}
`);

export interface ResendVerificationProps extends TypographyProps {
	email: string;
	text: string;
	disabled?: boolean;
}

const ResendVerification = ({
	email,
	text,
	disabled = false,
	...props
}: ResendVerificationProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const [resend, { data, error, loading }] = useMutation(
		ResendVerification_ResendMDoc,
		{
			variables: {
				email,
			},
		},
	);
	useNotifyError(error, true);
	useErrorReporting(error, {
		email,
	});

	const handleClick = () => {
		return resend();
	};

	useEffect(() => {
		if (data?.resend.success) {
			enqueueSnackbar(`Another email has been sent to ${email}.`, {
				variant: 'success',
			});
		}
	}, [data, enqueueSnackbar, email]);

	return (
		<Typography
			component='span'
			{...props}
			sx={{
				verticalAlign: 'middle',
			}}
		>
			{text}
			<Button
				onClick={handleClick}
				disabled={disabled || loading}
				sx={{
					fontSize: 'inherit',
				}}
			>
				Resend
			</Button>
		</Typography>
	);
};

export default ResendVerification;
