import React from 'react';

import {
	Box,
	Button,
	type ButtonProps,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import FeatureLineItem from '@ivy/components/molecules/FeatureLineItem';
import { combineSx } from '@ivy/lib/styling/sx';

import AcepBubble from './AcepBubble';

export interface FeatureLineItemProps {
	included?: boolean;
	text: React.ReactNode;
}

interface FreePlanProps {
	sx?: SxProps<Theme>;
	onClickCta?: ButtonProps['onClick'];
}

const FreePlan = ({ sx, onClickCta }: FreePlanProps) => {
	return (
		<Box
			id='FreePlan_Container'
			component='section'
			sx={combineSx(
				{
					py: 10,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					bgcolor: '#CDEFDC',
					position: {
						md: 'relative',
					},
				},
				sx,
			)}
		>
			<Box
				sx={{
					px: 3,
					display: 'flex',
					flexDirection: 'column',
					maxWidth: '1400px',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						alignItems: 'flex-start',
					}}
				>
					<Box
						sx={{
							flexBasis: {
								md: '60%',
							},
						}}
					>
						<Typography
							variant='h2'
							sx={{
								mb: 5,
								textAlign: {
									xs: 'center',
									md: 'initial',
								},
							}}
						>
							Our{' '}
							<Box
								component='span'
								sx={{
									background:
										'linear-gradient(96deg, #1B8374 11.22%, #1EC86A 87.8%)',
									backgroundClip: 'text',
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								}}
							>
								free employer directory
							</Box>{' '}
							is packed with features
						</Typography>
						<Typography
							variant='body1'
							sx={{
								mb: 5,
								color: 'text.icon',
								textAlign: {
									xs: 'center',
									md: 'initial',
								},
							}}
						>
							Connect with clinicians interested in working with your group.
							Keep your list of emergency departments and recruiters up-to-date
							so clinicians searching your area can easily find you. Build your
							network and stay ahead of future vacancies.
						</Typography>
					</Box>
					<Box
						sx={{
							display: {
								xs: 'none',
								md: 'block',
							},
							flexBasis: {
								md: '40%',
							},
							ml: {
								md: 10,
							},
						}}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
						width: ' 100%',
						alignItems: {
							xs: 'center',
							md: 'flex-start',
						},
					}}
				>
					<Box
						sx={{
							position: {
								xs: 'relative',
								md: 'initial',
							},
							flexBasis: {
								md: '60%',
							},
							width: {
								xs: '100%',
								md: 'initial',
							},
							maxWidth: {
								xs: '500px',
								md: 'none',
							},
							aspectRatio: '1 / 0.4831',
						}}
					>
						<Box
							component='img'
							src='https://assets.ivyclinicians.io/images/for-employers-free-plan.png'
							sx={{
								borderRadius: '32px',
								width: '100%',
								maxWidth: {
									xs: '500px',
									md: 'none',
								},
							}}
						/>
						<AcepBubble
							sx={(theme) => ({
								[theme.breakpoints.down('md')]: {
									float: 'right',
									transform: 'translate(0, -30%)',
								},
								[theme.breakpoints.up('md')]: {
									position: 'absolute',
									top: 0,
									right: 8,
								},
							})}
						/>
					</Box>
					<Box
						sx={{
							flexBasis: {
								md: '40%',
							},
							ml: {
								xs: 'none',
								md: 10,
							},
							mt: {
								xs: 15,
								md: 0,
							},
						}}
					>
						<Stack spacing={{ xs: 2, md: 3 }} sx={{ mb: { xs: 3, md: 5 } }}>
							<Typography fontWeight='bold' variant='body1'>
								Free features include
							</Typography>
							<FeatureLineItem
								included
								text='List your facilities on our public employer directory'
							/>
							<FeatureLineItem
								included
								text='Your facilities are preloaded with ED visits, EHR, trauma level, and more'
							/>
							<FeatureLineItem
								included
								text='Update your employer profile to stand out'
							/>
							<FeatureLineItem
								included
								text='Connect with clinicians to build your network'
							/>
							<FeatureLineItem included text='No fees or hirings costs' />
							<FeatureLineItem text='Job postings not included' />
						</Stack>
						<Button
							variant='outlined'
							size='large'
							color='secondary'
							onClick={onClickCta}
						>
							Get started with our free employer directory
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default FreePlan;
