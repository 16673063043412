import React from 'react';

import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Checkbox,
} from '@mui/material';

export const COLUMNS = Object.freeze({
	FACILITY: 'facility',
	ORGANIZATION: 'organization',
	STATE: 'state',
	PROFESSION: 'profession',
	WORKER: 'worker',
	CREATEDAT: 'createdAt',
	BONUS: 'bonus',
	DETAILS: 'details',
	SHIFTS: 'shifts',
	BASE: 'base',
});

const COLUMN_ITEMS = Object.freeze({
	[COLUMNS.CREATEDAT]: 'Submitted on',
	[COLUMNS.FACILITY]: 'Facility name',
	[COLUMNS.ORGANIZATION]: 'Employer',
	[COLUMNS.STATE]: 'State',
	[COLUMNS.PROFESSION]: 'Profession',
	[COLUMNS.DETAILS]: 'Details',
	[COLUMNS.SHIFTS]: 'Shifts per month',
	[COLUMNS.WORKER]: 'Contract type',
	[COLUMNS.BASE]: 'Compensation',
});

// TODO add toast warnings when table overflows
const ColumnMenu = ({ value, onChange }) => (
	<List sx={{ p: 0 }}>
		{Object.entries(COLUMN_ITEMS).map(([k, v], idx, self) => (
			<ListItem
				button
				key={`column-select-${k}`}
				onClick={() => {
					onChange({ [k]: !value[k] });
				}}
				sx={(theme) => ({
					pl: 0,
					py: 1,
					borderBottom:
						idx !== self.length - 1
							? `1px solid ${theme.palette.divider}`
							: 'none',
				})}
			>
				<ListItemIcon>
					<Checkbox checked={value[k]} />
				</ListItemIcon>
				<ListItemText primary={v as string} />
			</ListItem>
		))}
	</List>
);

export default ColumnMenu;
