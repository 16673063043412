import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import RouteLink from '@ivy/components/atoms/RouteLink';
import ReviewSalaryCta from '@ivy/components/organisms/ReviewSalaryCta';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type EmraProgramSocials_TrainingSurveyFragment } from '@ivy/gql/types/graphql';
import {
	WebsiteLinkIcon,
	TwitterLinkIcon,
	MultimediaLinkIcon,
	VimeoLinkIcon,
	FacebookLinkIcon,
	InstragramLinkIcon,
	InterviewLinkIcon,
	YoutubeLinkIcon,
} from '@ivy/views/emra/components/icons';

const EmraProgramSocials_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramSocials_TrainingSurvey on training_survey {
		id
		vimeo
		website
		youtube
		multimediaURLs: multimedia_urls
		instagram
		facebook
		twitter
		residencySurvey: residency_survey {
			id
			residency {
				id
				pdInterview: pd_interview
			}
		}
		...ReviewSalaryCta_TrainingSurvey
	}
`);

enum SOCIAL_TYPES {
	WEBSITE = 'website',
	FACEBOOK = 'facebook',
	TWITTER = 'twitter',
	INSTAGRAM = 'instagram',
	VIMEO = 'vimeo',
	YOUTUBE = 'youtube',
	MULTIMEDIA = 'multimediaURLs',
	PD_INTERVIEW = 'residencySurvey.residency.pdInterview',
}

interface EmraProgramSocialProps {
	survey: FragmentType<typeof EmraProgramSocials_TrainingSurveyFDoc>;
	showCta?: boolean;
}

const checkFieldValues = (
	object: EmraProgramSocials_TrainingSurveyFragment,
) => {
	const fieldsToCheck = [
		SOCIAL_TYPES.WEBSITE,
		SOCIAL_TYPES.FACEBOOK,
		SOCIAL_TYPES.TWITTER,
		SOCIAL_TYPES.VIMEO,
		SOCIAL_TYPES.YOUTUBE,
		SOCIAL_TYPES.INSTAGRAM,
		SOCIAL_TYPES.MULTIMEDIA,
		SOCIAL_TYPES.PD_INTERVIEW,
	];

	return fieldsToCheck.some((field) => {
		if (!has(object, field)) return false;
		const val = get(object, field);
		return isArray(val) ? !isEmpty(val) : val !== '';
	});
};

const EmraProgramSocial = ({
	survey: rawSurvey,
	showCta,
}: EmraProgramSocialProps) => {
	const survey = getFragmentData(
		EmraProgramSocials_TrainingSurveyFDoc,
		rawSurvey,
	);

	const hasValues = checkFieldValues(survey);

	const renderIcon = (type: string): React.ReactNode => {
		let icon;
		switch (type) {
			case SOCIAL_TYPES.WEBSITE:
				icon = <WebsiteLinkIcon />;
				break;
			case SOCIAL_TYPES.FACEBOOK:
				icon = <FacebookLinkIcon />;
				break;
			case SOCIAL_TYPES.TWITTER:
				icon = <TwitterLinkIcon />;
				break;
			case SOCIAL_TYPES.INSTAGRAM:
				icon = <InstragramLinkIcon />;
				break;
			case SOCIAL_TYPES.VIMEO:
				icon = <VimeoLinkIcon />;
				break;
			case SOCIAL_TYPES.YOUTUBE:
				icon = <YoutubeLinkIcon />;
				break;
			case SOCIAL_TYPES.PD_INTERVIEW:
				icon = (
					<InterviewLinkIcon
						sx={{
							color: 'text.icon',
						}}
					/>
				);
				break;
			case SOCIAL_TYPES.MULTIMEDIA:
			default:
				icon = <MultimediaLinkIcon />;
				break;
		}
		return icon;
	};

	return (
		<Box component='section'>
			{hasValues && (
				<>
					<Typography
						variant='h5'
						component='h2'
						sx={{
							mb: 2,
							display: { lg: 'block', xs: 'none' },
							fontWeight: 'light',
						}}
					>
						Socials
					</Typography>
					<Stack spacing={2} direction='row'>
						{!!survey.website && (
							<RouteLink to={survey.website} openInNewTab>
								{renderIcon(SOCIAL_TYPES.WEBSITE)}
							</RouteLink>
						)}
						{!!survey.facebook && (
							<RouteLink to={survey.facebook} openInNewTab>
								{renderIcon(SOCIAL_TYPES.FACEBOOK)}
							</RouteLink>
						)}
						{!!survey.twitter && (
							<RouteLink to={survey.twitter} openInNewTab>
								{renderIcon(SOCIAL_TYPES.TWITTER)}
							</RouteLink>
						)}
						{!!survey.vimeo && (
							<RouteLink to={survey.vimeo} openInNewTab>
								{renderIcon(SOCIAL_TYPES.VIMEO)}
							</RouteLink>
						)}
						{!!survey.youtube && (
							<RouteLink to={survey.youtube} openInNewTab>
								{renderIcon(SOCIAL_TYPES.YOUTUBE)}
							</RouteLink>
						)}
						{!!survey.instagram && (
							<RouteLink to={survey.instagram} openInNewTab>
								{renderIcon(SOCIAL_TYPES.INSTAGRAM)}
							</RouteLink>
						)}
						{!!survey.residencySurvey?.residency?.pdInterview && (
							<RouteLink
								to={survey.residencySurvey.residency.pdInterview}
								openInNewTab
							>
								{renderIcon(SOCIAL_TYPES.PD_INTERVIEW)}
							</RouteLink>
						)}
						{survey.multimediaURLs.map((url, idx) => (
							<RouteLink key={`multimedia-url-${idx}`} to={url} openInNewTab>
								{renderIcon(SOCIAL_TYPES.MULTIMEDIA)}
							</RouteLink>
						))}
					</Stack>
				</>
			)}
			{showCta && <ReviewSalaryCta survey={survey} sx={{ mt: 8 }} />}
		</Box>
	);
};

export default EmraProgramSocial;
