import React from 'react';

import { startOfMonth } from 'date-fns';
import { BiHandicap } from 'react-icons/bi';
import {
	FaTruck,
	FaUmbrellaBeach,
	FaBabyCarriage,
	FaUserGraduate,
	FaUserShield,
	FaUserFriends,
	FaBusinessTime,
	FaHospitalUser,
	FaPiggyBank,
	FaBlind,
	FaLifeRing,
	FaDiagnoses,
	FaGlasses,
	FaSignature,
	FaTooth,
} from 'react-icons/fa';
import { GiSchoolBag } from 'react-icons/gi';

import {
	WorkerClassification,
	TermLength,
	WorkSchedule,
} from '@ivy/constants/clinician';
import { dateFromNow } from '@ivy/lib/validation/date';

// TODO: combine with constants in clinician
export const WORKER_CLASSIFICATION_OPTIONS = [
	{
		label: 'Employee (W-2)',
		value: WorkerClassification.EMPLOYEE,
	},
	{
		label: 'Independent Contractor (1099)',
		value: WorkerClassification.CONTRACTOR,
	},
	{
		label: 'Partnership (K-1)',
		value: WorkerClassification.PARTNERSHIP,
	},
];

export const VISA_OPTIONS = [
	{
		label: 'J-1',
		value: 'j1Visa',
	},
	{
		label: 'H-1B',
		value: 'h1bVisa',
	},
	{
		label: 'None',
		value: 'none',
	},
];

export const TERM_OPTIONS = [
	{
		label: 'Short-term (less than 1 year)',
		value: TermLength.SHORT,
	},
	{
		label: 'Long-term (at least 1 year)',
		value: TermLength.LONG,
	},
	{
		label: 'Either',
		value: TermLength.EITHER,
	},
];

export const SCHEDULE_OPTIONS = [
	{
		label: 'Full-time',
		value: WorkSchedule.FULL,
	},
	{
		label: 'Part-time',
		value: WorkSchedule.PART,
	},
	{
		label: 'Either',
		value: WorkSchedule.EITHER,
	},
];

export const YNOPTS = [
	{
		label: 'Yes',
		value: 'y',
		syntheticValue: true,
	},
	{
		label: 'No',
		value: 'n',
		syntheticValue: false,
	},
];

export const JOB_POSTING_FEATURES = [
	{
		label: 'Signing Bonus',
		value: 'hasSigningBonus',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaSignature />,
	},
	{
		label: 'Relocation Allowance',
		value: 'hasRelocationAssist',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaTruck />,
	},
	{
		label: 'Performance or Productivity Bonus',
		value: 'hasPerfBonus',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaDiagnoses />,
	},
	{
		label: 'Equity, Ownership, & Partnership Opportunities',
		value: 'equity',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaUserFriends />,
	},
	{
		label: 'Employer-Provided Malpractice Insurance',
		value: 'malpracticeInsurance',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaUserShield />,
	},
	{
		label: 'Employer-Provided Tail Coverage',
		value: 'tailCoverage',
		aud: [
			WorkerClassification.EMPLOYEE,
			WorkerClassification.CONTRACTOR,
			WorkerClassification.PARTNERSHIP,
		],
		icon: <FaBusinessTime />,
	},
	{
		label: 'Health Insurance',
		value: 'healthInsurance',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaHospitalUser />,
	},
	{
		label: 'Dental Insurance',
		value: 'dentalInsurance',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaTooth />,
	},
	{
		label: 'Vision Insurance',
		value: 'visionInsurance',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaGlasses />,
	},
	{
		label: 'HSA/FSA',
		value: 'healthAccount',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaPiggyBank />,
	},
	{
		label: 'Disability Insurance',
		value: 'disabilityInsurance',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <BiHandicap />,
	},
	{
		label: 'Life Insurance',
		value: 'lifeInsurance',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaLifeRing />,
	},
	{
		label: 'Employer-Sponsored Retirement Plan',
		value: 'retirement',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaBlind />,
	},
	{
		label: 'Paid Vacation',
		value: 'paidVacation',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaUmbrellaBeach />,
	},
	{
		label: 'Paid Parental Leave',
		value: 'paidParental',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaBabyCarriage />,
	},
	{
		label: 'Student Loan Assistance',
		value: 'studentLoan',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <FaUserGraduate />,
	},
	{
		label: 'CME',
		value: 'cme',
		aud: [WorkerClassification.EMPLOYEE, WorkerClassification.PARTNERSHIP],
		icon: <GiSchoolBag />,
	},
];

export const JOB_POSTING_EXPIRING_SOON_WINDOW_DAYS = 14;

export const JOB_POSTING_DEFAULT_EXPIRATION_MONTHS = 4;

export const generateNewExpirationDate = (
	months = JOB_POSTING_DEFAULT_EXPIRATION_MONTHS,
) => {
	return startOfMonth(dateFromNow({ months }));
};
