import React from 'react';

import { Box, CircularProgress, type SxProps, type Theme } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface LoadingOverlayProps {
	loading?: boolean;
	sx?: SxProps<Theme>;
}

const LoadingOverlay = ({ loading, sx }: LoadingOverlayProps) => {
	return (
		<Box
			display={loading ? 'flex' : 'none'}
			justifyContent='center'
			alignItems='center'
			position='absolute'
			top={0}
			right={0}
			width='100%'
			height='100%'
			bgcolor='black'
			sx={combineSx(
				{
					opacity: '0.6',
					flex: '0',
				},
				sx,
			)}
		>
			<CircularProgress color='primary' />
		</Box>
	);
};

export default LoadingOverlay;
