import React from 'react';

import { SvgIcon } from '@mui/material';

const LocationIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<path
				d='M8.49951 0.166748C4.14576 0.166748 0.624512 3.55758 0.624512 7.75008C0.624512 13.4376 8.49951 21.8334 8.49951 21.8334C8.49951 21.8334 16.3745 13.4376 16.3745 7.75008C16.3745 3.55758 12.8533 0.166748 8.49951 0.166748ZM2.87451 7.75008C2.87451 4.76008 5.39451 2.33341 8.49951 2.33341C11.6045 2.33341 14.1245 4.76008 14.1245 7.75008C14.1245 10.8701 10.8845 15.5392 8.49951 18.4534C6.15951 15.5609 2.87451 10.8376 2.87451 7.75008Z'
				fill='currentColor'
			/>
			<path
				d='M8.49951 10.4584C10.0528 10.4584 11.312 9.24585 11.312 7.75008C11.312 6.25431 10.0528 5.04175 8.49951 5.04175C6.94621 5.04175 5.68701 6.25431 5.68701 7.75008C5.68701 9.24585 6.94621 10.4584 8.49951 10.4584Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default LocationIcon;
