import React from 'react';

import { Box, Typography, type SxProps, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { ArrowRightIcon } from '@ivy/components/icons';
import { RouteUri } from '@ivy/constants/routes';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';

const ReviewSalaryCta_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment ReviewSalaryCta_TrainingSurvey on training_survey {
		id
		primaryTrainingSites: training_sites(where: { primary: { _eq: true } }) {
			id
			primary
			facility {
				id
				slug
			}
		}
	}
`);

export interface ReviewSalaryCtaProps {
	survey: FragmentType<typeof ReviewSalaryCta_TrainingSurveyFDoc>;
	sx?: SxProps<Theme>;
}

const ReviewSalaryCta = ({ survey: rawSurvey, sx }: ReviewSalaryCtaProps) => {
	const survey = getFragmentData(ReviewSalaryCta_TrainingSurveyFDoc, rawSurvey);
	const fac = survey?.primaryTrainingSites[0]?.facility;

	const pathTo = fac
		? buildInternalLink(RouteUri.FACILITY_SHOW, {
				facilityId: [fac.id, fac.slug],
		  })
		: RouteUri.ROOT;

	return (
		<Box
			sx={combineSx(
				{
					borderRadius: (theme) =>
						`${theme.cardShape?.borderRadius || theme.shape.borderRadius}px`,
					bgcolor: 'primary.translucent',
					position: 'relative',
					overflow: 'hidden',
					px: {
						xs: 3,
						sm: 5,
					},
					py: {
						xs: 5,
						sm: 9,
					},
				},
				sx,
			)}
		>
			<Box
				component='img'
				src='https://assets.ivyclinicians.io/images/review-salary-cta-background.png'
				sx={{
					height: '135%',
					position: 'absolute',
					bottom: 0,
					left: '60%',
					zIndex: 1,
				}}
			/>
			<Box width='60%'>
				<Typography variant='body1' color='text.icon' mb={3}>
					Interested in learning more about this program's training sites?
				</Typography>
				<Typography variant='body1'>
					<RouteLink
						fontWeight='bold'
						to={{
							pathname: pathTo,
							whitelabel: 'default',
						}}
						underline='hover'
						openInNewTab
					>
						<Box component='span' sx={{ verticalAlign: 'middle' }}>
							Go to Ivy Clinicians
						</Box>
						<ArrowRightIcon
							sx={{
								ml: 1,
								fontSize: 'inherit',
								color: 'inherit',
								verticalAlign: 'middle',
							}}
						/>
					</RouteLink>
				</Typography>
			</Box>
		</Box>
	);
};

export default ReviewSalaryCta;
