import React from 'react';

import { OpenInNew } from '@mui/icons-material';
import {
	Box,
	type SxProps,
	type Theme,
	SvgIcon,
	Typography,
	Stack,
	useMediaQuery,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { combineSx } from '@ivy/lib/styling/sx';

export interface AcepBubbleProps {
	sx?: SxProps<Theme>;
}

const AcepBubble = ({ sx }) => {
	const isLtMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), {
		noSsr: true,
	});
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'relative',
					width: {
						xs: '240px',
						md: '398px',
					},
					height: {
						xs: '200px',
						md: '332px',
					},
				},
				sx,
			)}
		>
			<SvgIcon
				width='398'
				height='332'
				viewBox='0 0 398 332'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: '100%',
					height: '100%',
				}}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M190.659 0.136498C237.608 1.77206 277.632 23.9088 313.009 50.6128C351.802 79.8954 393.166 111.933 397.5 156.27C402.093 203.26 374.53 248.365 335.57 281.408C296.959 314.156 244.661 334.757 190.659 331.701C139.446 328.802 98.6951 298.332 63.5766 266.013C29.8871 235.008 1.08921 198.777 0.036467 156.27C-1.03611 112.962 21.6271 71.5397 58.022 41.7298C93.5 12.6708 141.8 -1.5656 190.659 0.136498Z'
					fill='white'
				/>
			</SvgIcon>
			<Stack
				alignItems='center'
				sx={{
					zIndex: 1,
					p: 2,
				}}
			>
				<Typography
					color='text.icon'
					align='center'
					variant={isLtMd ? 'caption' : 'body1'}
					sx={{
						color: '#ADB0B4',
					}}
				>
					Ivy powers
				</Typography>
				<RouteLink
					to={{
						pathname: RouteLink.routes.ROOT,
						whitelabel: 'acep',
					}}
					openInNewTab
				>
					<Box
						component='img'
						alt='ACEP Open Book'
						src='https://assets.ivyclinicians.io/images/acep-open-book-logo.png'
						sx={{
							height: {
								xs: '24px',
								md: '46px',
							},
							mb: {
								xs: 1,
								md: 2,
							},
						}}
					/>
				</RouteLink>
				<Typography
					color='text.icon'
					align='center'
					variant={isLtMd ? 'caption' : 'body1'}
					mb={{
						xs: 1,
						md: 2,
					}}
				>
					Your group and facility information also appear on ACEP Open Book.
				</Typography>
				<Typography align='center' variant={isLtMd ? 'caption' : 'body1'}>
					<RouteLink
						to={{
							pathname: RouteLink.routes.ROOT,
							whitelabel: 'acep',
						}}
						openInNewTab
					>
						<Box component='span'>Learn more here</Box>
						<OpenInNew
							sx={{
								fontSize: '1em',
								ml: 0.5,
								verticalAlign: 'middle',
							}}
						/>
					</RouteLink>
				</Typography>
			</Stack>
		</Box>
	);
};

export default AcepBubble;
