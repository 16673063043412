import React, { useState, useCallback } from 'react';

import { ErrorOutline } from '@mui/icons-material';
import { Box, Tabs, Tab } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';

import RouteLink from '@ivy/components/atoms/RouteLink';
import PlanStatusPanel from '@ivy/components/organisms/PlanStatusPanel';
import { JOB_POSTING_EXPIRING_SOON_WINDOW_DAYS } from '@ivy/constants/posting';
import { useCurrentOrgId } from '@ivy/gql/hooks';
import {
	type JobPostingList_SearchJobPostingsQuery,
	type Relevant_Job_Posting_Bool_Exp,
} from '@ivy/gql/types/graphql';
import { formatInteger } from '@ivy/lib/formatting/number';
import { buildInternalLink } from '@ivy/lib/util/route';
import { dateFromNow } from '@ivy/lib/validation/date';

import JobPostingList, {
	FiltersProvider,
	useFilterContext,
} from './JobPostingList';
import SearchBar from './SearchBar';

export enum JobPostingStatus {
	ACTIVE = 'active',
	EXPIRING_SOON = 'expiring-soon',
	INACTIVE = 'inactive',
}

const resolveTab = (
	tabValue: string,
	orgId: string,
): { filter: Relevant_Job_Posting_Bool_Exp; value: JobPostingStatus } => {
	if (tabValue === JobPostingStatus.INACTIVE) {
		return {
			filter: {
				job_posting: {
					_and: [
						{ active: { _eq: false } },
						{
							latest_publication: {
								unpublished_at: { _gte: dateFromNow({ days: -30 }) },
							},
						},
						{ contract: { org_id: { _eq: orgId } } },
					],
				},
			},
			value: JobPostingStatus.INACTIVE,
		};
	} else if (tabValue === JobPostingStatus.EXPIRING_SOON) {
		return {
			filter: {
				job_posting: {
					_and: [
						{ active: { _eq: true } },
						{
							expiration_date: {
								_lte: dateFromNow({
									days: JOB_POSTING_EXPIRING_SOON_WINDOW_DAYS,
								}).toISOString(),
							},
						},
						{ contract: { org_id: { _eq: orgId } } },
					],
				},
			},
			value: JobPostingStatus.EXPIRING_SOON,
		};
	} else {
		return {
			filter: {
				job_posting: {
					_and: [
						{ active: { _eq: true } },
						{ contract: { org_id: { _eq: orgId } } },
					],
				},
			},
			value: JobPostingStatus.ACTIVE,
		};
	}
};

const JobPostingsTabs = () => {
	const { filtersCount } = useFilterContext();
	const currentOrgId = useCurrentOrgId();
	const navigate = useNavigate();

	const params = useParams();
	const [search, setSearch] = useQueryParam(
		'search',
		withDefault(StringParam, ''),
		{
			updateType: 'replaceIn',
		},
	);
	const [jobPostingData, setJobPostingData] =
		useState<JobPostingList_SearchJobPostingsQuery>();
	const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);

	const handleChangeSearch = useCallback(
		(term: string) => {
			setSearch(term);
		},
		[setSearch],
	);

	const filtersActive = Object.values(filtersCount).some((el) => !!el);
	const activeTab = resolveTab(
		params.tab || JobPostingStatus.ACTIVE,
		currentOrgId,
	);

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Box
				display='flex'
				flexDirection={{ xs: 'column', gridBreak: 'row' }}
				justifyContent='space-between'
				sx={{
					mb: 2,
				}}
			>
				<Box>
					<Box
						sx={{
							mb: 2,
						}}
					>
						<Tabs
							value={activeTab.value}
							onChange={(_, nt) =>
								navigate(
									buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
										tab: nt,
									}),
								)
							}
						>
							<Tab
								label={`Active (${formatInteger(
									jobPostingData?.aggActive.aggregate?.count || 0,
								)})`}
								value={JobPostingStatus.ACTIVE}
								component={RouteLink}
								to={buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
									tab: JobPostingStatus.ACTIVE,
								})}
							/>
							<Tab
								label={
									<Box display='inline'>
										{!!jobPostingData?.aggExpiringSoon.aggregate?.count && (
											<ErrorOutline
												color='warning'
												sx={{ verticalAlign: 'middle', mr: 1 }}
												fontSize='small'
											/>
										)}
										Expiring Soon (
										{formatInteger(
											jobPostingData?.aggExpiringSoon.aggregate?.count || 0,
										)}
										)
									</Box>
								}
								value={JobPostingStatus.EXPIRING_SOON}
								component={RouteLink}
								to={buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
									tab: JobPostingStatus.EXPIRING_SOON,
								})}
							/>
							<Tab
								label={`Inactive (${formatInteger(
									jobPostingData?.aggInactive.aggregate?.count || 0,
								)})`}
								value={JobPostingStatus.INACTIVE}
								component={RouteLink}
								to={buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
									tab: JobPostingStatus.INACTIVE,
								})}
							/>
						</Tabs>
					</Box>
					<Box>
						<SearchBar
							searchTerm={search}
							onTextChange={handleChangeSearch}
							setFiltersPopupOpen={setFiltersPopupOpen}
							filtersActive={filtersActive}
						/>
					</Box>
				</Box>
				<PlanStatusPanel
					showManagePlan
					showJobPostingButton
					variant='contained'
					sx={{
						width: { xs: 'auto', gridBreak: 'fit-content' },
						height: { xs: 'auto', sm: '100%' },
						mt: { xs: 3, gridBreak: 'auto' },
						p: 3,
					}}
				/>
			</Box>
			<JobPostingList
				searchTerm={search}
				filtersPopupOpen={filtersPopupOpen}
				setFiltersPopupOpen={setFiltersPopupOpen}
				onCompletedQuery={setJobPostingData}
				where={activeTab.filter}
				tableProps={{
					autoHeight: true,
				}}
				orgId={currentOrgId}
				selectable
				tabValue={activeTab.value}
				sx={{
					mb: 10,
				}}
			/>
		</Box>
	);
};

const JobPostingWithProvider = () => {
	return (
		<FiltersProvider>
			<JobPostingsTabs />
		</FiltersProvider>
	);
};

export default JobPostingWithProvider;
