import React from 'react';

import { gql, useMutation } from '@apollo/client';
import {
	Button,
	TextField,
	Typography,
	Stack,
	FormHelperText,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import * as yup from 'yup';

import { ShieldIcon } from '@ivy/components/icons';

const validationSchema = yup.object({
	email: yup.string().email('Must be a valid email').required().label('Email'),
});

const SEND_PASSWORD_RESET_MUTATION = gql`
	mutation sendPasswordReset($email: String!) {
		sendReset: send_password_reset(email: $email) {
			success
		}
	}
`;

const ForgotPasswordForm = () => {
	const [sendReset, { data, error }] = useMutation(
		SEND_PASSWORD_RESET_MUTATION,
	);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: validationSchema,
		onSubmit: async ({ email }, actions) => {
			try {
				await sendReset({
					variables: {
						email,
					},
				});
			} catch (e) {
				console.error(e);
				captureException(e, {
					extra: {
						email,
					},
				});
			} finally {
				actions.setSubmitting(false);
			}
		},
	});

	return (
		<Stack
			alignItems='center'
			spacing={1}
			py={0.5}
			px={1}
			width='100%'
			maxWidth='600px'
		>
			{data?.sendReset.success ? (
				<>
					<Typography variant='h6' align='center'>
						Check your email for a link to reset your password.
					</Typography>
					<ShieldIcon width='50' height='80' />
					<Typography variant='body1' align='center'>
						For security purposes, we've emailed you a link to complete your
						password reset. It may take a minute to arrive.
					</Typography>
				</>
			) : (
				<>
					<Typography variant='body1' align='center'>
						Enter your email.
					</Typography>
					<TextField
						fullWidth
						name='email'
						label='Email'
						type='email'
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.email && !!formik.errors.email}
						helperText={formik.touched.email && formik.errors.email}
						sx={{
							maxWidth: '350px',
						}}
					/>
					<FormHelperText error sx={{ display: !error && 'none' }}>
						{error?.message}
					</FormHelperText>
					<Button
						variant='contained'
						disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
						onClick={formik.handleSubmit}
						fullWidth
						size='large'
						sx={{ maxWidth: '300px' }}
					>
						Reset password
					</Button>
				</>
			)}
		</Stack>
	);
};

export default ForgotPasswordForm;
