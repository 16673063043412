import React from 'react';

import {
	Box,
	type SxProps,
	SvgIcon,
	type Theme,
	Typography,
} from '@mui/material';

import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';

export interface TestimonialProps {
	facCount: number;
	sx?: SxProps<Theme>;
}

const Testimonial = ({ facCount, sx }: TestimonialProps) => {
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					alignItems: 'center',
				},
				sx,
			)}
		>
			<Box
				sx={{
					position: 'relative',
				}}
			>
				<Box
					component='img'
					src='https://assets.ivyclinicians.io/images/eugene.png'
					width='125px'
					height='125px'
					sx={{
						objectFit: 'cover',
						borderRadius: '100%',
					}}
				/>
				<SvgIcon
					width='103'
					height='103'
					viewBox='0 0 103 103'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					sx={{
						position: 'absolute',
						top: '0',
						right: '0',
						width: '25%',
						height: 'auto',
					}}
				>
					<circle cx='51.5' cy='51.5' r='51.5' fill='#3EB1EC' />
					<path
						d='M43.35 29.3C45.05 29.3 46.3 30.05 47.1 31.55C48 32.95 48.05 34.45 47.25 36.05C45.45 40.05 44.35 43.4 43.95 46.1C43.55 48.7 43.5 50.95 43.8 52.85C44.2 54.75 44.65 56.6 45.15 58.4C45.65 60.1 45.9 62.05 45.9 64.25C45.9 66.75 45 68.9 43.2 70.7C41.4 72.4 39.25 73.25 36.75 73.25C33.65 73.25 31.25 71.95 29.55 69.35C27.85 66.75 27 63.5 27 59.6C27 57 27.55 54 28.65 50.6C29.75 47.2 31.1 43.9 32.7 40.7C34.4 37.4 36.2 34.7 38.1 32.6C40 30.4 41.75 29.3 43.35 29.3ZM66.45 29.3C68.15 29.3 69.4 30.05 70.2 31.55C71.1 32.95 71.15 34.45 70.35 36.05C68.55 40.05 67.45 43.4 67.05 46.1C66.65 48.7 66.6 50.95 66.9 52.85C67.3 54.75 67.75 56.6 68.25 58.4C68.75 60.1 69 62.05 69 64.25C69 66.75 68.1 68.9 66.3 70.7C64.5 72.4 62.35 73.25 59.85 73.25C56.75 73.25 54.35 71.95 52.65 69.35C50.95 66.75 50.1 63.5 50.1 59.6C50.1 57 50.65 54 51.75 50.6C52.85 47.2 54.2 43.9 55.8 40.7C57.5 37.4 59.3 34.7 61.2 32.6C63.1 30.4 64.85 29.3 66.45 29.3Z'
						fill='#FAFAFC'
					/>
				</SvgIcon>
			</Box>
			<Box
				sx={{
					p: 3,
					borderRadius: '16px',
					ml: 2,
					bgcolor: 'background.paper',
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				}}
			>
				<Typography variant='body2' color='primary.main' mb={1}>
					Ivy is the only job board where you'll find the employer at all{' '}
					<b>{formatInteger(facCount)} emergency departments</b>. With one quick
					search, you can see all the job options available in the area and
					apply to multiple employers in a single click.
				</Typography>
				<Typography variant='body2' fontWeight='bold' color='primary.main'>
					Eugene Gicheru, MD, FACEP
				</Typography>
			</Box>
		</Box>
	);
};

export default Testimonial;
