import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import {
	steps,
	type ProfileCompleteFormValues,
} from '@ivy/components/forms/ProfileCompleteForm';
import DataLoader from '@ivy/components/molecules/DataLoader';
import FormPopup, {
	type MasterFormPopupProps,
} from '@ivy/components/organisms/FormPopup';
import { TrainingType } from '@ivy/constants/clinician';
import { CLN_SKILLS } from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { ProfileCompleteForm_ClinicianFragmentDoc } from '@ivy/gql/types/graphql';
import { useNotifyError } from '@ivy/lib/hooks';

const ProfileCompletePopup_ClinicianProfileQDoc = gql(/* GraphQL */ `
	query ProfileCompletePopup_ClinicianProfile($accountId: uuid!) {
		clinician: clinician_by_pk(id: $accountId) {
			id
			...ProfileCompleteForm_Clinician
		}
	}
`);

interface ProfileCompletePopupHelperProps
	extends Omit<
		MasterFormPopupProps<ProfileCompleteFormValues>,
		'steps' | 'defaultValues' | 'strictDisable' | 'extra'
	> {
	clinician: NonNullable<
		FragmentType<typeof ProfileCompleteForm_ClinicianFragmentDoc>
	>;
}

const ProfileCompletePopupHelper = ({
	open,
	onClose,
	clinician: rawClinician,
	...props
}: ProfileCompletePopupHelperProps) => {
	const clinician = getFragmentData(
		ProfileCompleteForm_ClinicianFragmentDoc,
		rawClinician,
	);

	const defaultFormValues = useMemo(() => {
		const defaultValues = {};

		const {
			gradYr,
			currStudent,
			training,
			schoolName,
			creds,
			specialties,
			boardCerts,
			appCerts,
			spExp,
			cv,
			licenses,
			profileComplete,
			isAPP,
			account,
			fsmbBio,
		} = clinician;

		const residencies = training.filter(
			({ type }) => type === TrainingType.RESIDENCY,
		);
		const appTraining = training.filter(
			({ type }) => type === TrainingType.APP,
		);
		const fellowships = training.filter(
			({ type }) => type === TrainingType.FELLOWSHIP,
		);

		Object.assign(defaultValues, {
			fsmbBio,
			schoolName,
			creds,
			cv,
			picture: account.picture,
			boardCerts: isAPP ? [] : boardCerts.length ? boardCerts : undefined,
			appCerts: !isAPP ? [] : appCerts.length ? appCerts : undefined,
			spExp: !isAPP ? [] : spExp.length ? spExp : undefined,
			gradYr: gradYr != null ? new Date(gradYr, 0) : undefined,
			// Since new clinician records have gradYr as null and currStudent thus as true, we should
			// only take that at face value if we know the user has checked this box.  In other words,
			// the profile is complete and we're an APP.
			currStudent: profileComplete && isAPP && currStudent,
			licenses: licenses.length
				? licenses.map(({ id, state }) => ({
						id,
						state,
				  }))
				: undefined,
			fellowship: isAPP
				? []
				: fellowships.length
				? fellowships.map(
						({ id, program, field, startYear, endYear, present }) => ({
							id,
							program,
							field,
							startYear: new Date(startYear, 0),
							endYear: present ? null : endYear ? new Date(endYear, 0) : null,
							present,
							type: TrainingType.FELLOWSHIP,
						}),
				  )
				: undefined,
			appTraining: !isAPP
				? []
				: appTraining.length
				? appTraining.map(
						({ id, program, field, startYear, endYear, present }) => ({
							id,
							program,
							field,
							startYear: new Date(startYear, 0),
							endYear: present ? null : endYear ? new Date(endYear, 0) : null,
							present,
							type: TrainingType.APP,
						}),
				  )
				: undefined,
			residency: isAPP
				? []
				: residencies.length
				? residencies.map(
						({ id, program, field, startYear, endYear, present }) => ({
							id,
							program,
							field,
							startYear: new Date(startYear, 0),
							endYear: present ? null : endYear ? new Date(endYear, 0) : null,
							present,
							type: TrainingType.RESIDENCY,
						}),
				  )
				: undefined,
			specialties: isAPP
				? []
				: specialties.length
				? specialties.map((el) => ({
						id: el.id,
						name: el.name,
						primary: el.primary,
				  }))
				: undefined,
			skills: CLN_SKILLS.reduce(
				(total, { key }) => ({
					...total,
					[key]: clinician[key],
				}),
				{},
			),
		});

		return Object.entries(defaultValues).reduce(
			(curr, [k, v]) =>
				v !== undefined
					? {
							...curr,
							[k]: v,
					  }
					: curr,
			{},
		) as ProfileCompleteFormValues;
	}, [clinician]);

	return (
		<FormPopup
			open={open}
			initialValues={defaultFormValues}
			onClose={onClose}
			extra={{ clinician }}
			steps={steps}
			strictDisable={!clinician?.profileComplete}
			onClickLater={onClose}
			formikContext={{ isAPP: clinician.isAPP }}
			displayMobileTitle
			refetchQueries={[
				'AuthProvider_CurrentAccount',
				'EditClinician_Clinician',
			]}
			{...props}
		/>
	);
};

type ProfileCompletePopupProps = Omit<
	ProfileCompletePopupHelperProps,
	'clinician'
>;

const ProfileCompletePopup = (props: ProfileCompletePopupProps) => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(
		ProfileCompletePopup_ClinicianProfileQDoc,
		{
			variables: {
				accountId: currAcc?.id,
			},
			// Use network only to reflect any changes they just made
			fetchPolicy: 'network-only',
		},
	);
	useNotifyError(error);

	return (
		<DataLoader
			data={data}
			loading={loading}
			error={error}
			fullscreen
			variant='circular'
			transparent
		>
			{!!data?.clinician && (
				<ProfileCompletePopupHelper clinician={data!.clinician!} {...props} />
			)}
		</DataLoader>
	);
};

export default ProfileCompletePopup;
export { type ProfileCompletePopupProps };
