import React, { useMemo } from 'react';

import { Edit as EditIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	type SxProps,
	type Theme,
	Stack,
	Typography,
	Divider,
	IconButton,
} from '@mui/material';
import { type FormikProps, type FormikValues } from 'formik-othebi';

import { useForm } from '@ivy/lib/hooks';
import { ADDRESS_SCHEMA } from '@ivy/lib/validation/address';

import BillingFormBody from './BillingFormBody';

const defaultValues = {
	name: '',
	email: '',
	streetAddress1: '',
	streetAddress2: '',
	city: '',
	state: '',
	zipcode: '',
} as FormikValues;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BillingDisplayProps<T extends FormikValues> {
	editMode?: boolean;
	onSubmit?: (vals: T) => void;
	onCancel?: () => void;
	onEdit?: () => void;
	initialValues?: Partial<T>;
	sx?: SxProps<Theme>;
	formik?: FormikProps<T>;
	showFormButtons?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BillingDisplay = <T extends FormikValues>({
	editMode,
	initialValues,
	onSubmit,
	onCancel,
	onEdit,
	sx,
	formik,
	showFormButtons,
}: BillingDisplayProps<T>) => {
	const initialFormValues = useMemo(() => {
		return Object.assign(
			{},
			defaultValues,
			Object.entries(initialValues ?? {}).reduce(
				(curr, [k, v]) =>
					v !== undefined
						? {
								...curr,
								[k]: v,
						  }
						: curr,
				{},
			),
		);
	}, [initialValues]) as T;

	let localFormik = useForm<T>({
		initialValues: initialFormValues,
		onSubmit: async (values, actions) => {
			onSubmit?.(values);
			actions.resetForm();
		},
		enableReinitialize: true,
		validationSchema: ADDRESS_SCHEMA,
	}) as FormikProps<T>;

	if (formik) localFormik = formik;

	const handleCancel = () => {
		localFormik.resetForm();
		onCancel && onCancel();
	};

	const handleEdit = () => {
		onEdit && onEdit();
	};

	const handleSubmit = () => {
		localFormik.submitForm();
	};

	const renderDisplayAdress = () => {
		if (
			!initialFormValues.streetAddress1 &&
			!initialFormValues.streetAddress2 &&
			!initialFormValues.city &&
			!initialFormValues.state &&
			!initialFormValues.zipcode
		) {
			return 'N/A';
		}
		return `${initialFormValues.streetAddress1}${
			initialFormValues.streetAddress2
				? ` ${initialFormValues.streetAddress2}`
				: ''
		}, ${initialFormValues.city}, ${initialFormValues.state} ${
			initialFormValues.zipcode
		}`;
	};

	return (
		<Box sx={sx}>
			{!editMode ? (
				<Stack
					sx={(theme) => ({
						border: `1px solid ${theme.palette.divider}`,
						borderRadius: `${theme.shape.borderRadius}px`,
						p: 3,
					})}
					spacing={2}
				>
					<Box sx={{ display: 'flex' }}>
						<Typography variant='body2' sx={{ mr: 2, minWidth: '75px' }}>
							Contact
						</Typography>
						<Typography
							variant='body2'
							sx={{
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
							}}
						>
							{initialFormValues.name ? `${initialFormValues.name} ` : ''}
							{initialFormValues.email ? `(${initialFormValues.email})` : 'N/A'}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								position: 'relative',
								ml: 'auto',
								width: '32px',
								mr: -1,
							}}
						>
							<IconButton
								sx={{
									ml: 'auto',
									display: onEdit ? 'flex' : 'none',
									position: 'absolute',
									right: 0,
									bottom: '-4px',
									width: '32px',
									height: '32px',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								onClick={handleEdit}
							>
								<EditIcon
									sx={{
										fontSize: '14px',
									}}
								/>
							</IconButton>
						</Box>
					</Box>
					<Divider />
					<Box sx={{ display: 'flex' }}>
						<Typography variant='body2' sx={{ mr: 2, minWidth: '75px' }}>
							Billing
						</Typography>
						<Typography variant='body2'>{renderDisplayAdress()}</Typography>
					</Box>
				</Stack>
			) : (
				<Box>
					<BillingFormBody formik={localFormik} />
					<Stack
						spacing={{ xs: 2, md: 3 }}
						direction='row'
						sx={{
							mt: 5,
							display: showFormButtons ? 'block' : 'none',
						}}
					>
						<Button
							disabled={localFormik.isSubmitting}
							variant='outlined'
							onClick={handleCancel}
						>
							Cancel
						</Button>
						<Button
							disabled={
								!localFormik.dirty ||
								!localFormik.isValid ||
								localFormik.isSubmitting
							}
							variant='contained'
							onClick={handleSubmit}
						>
							Save changes
						</Button>
					</Stack>
				</Box>
			)}
		</Box>
	);
};

export default BillingDisplay;
