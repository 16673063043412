import React from 'react';

import { useQuery } from '@apollo/client';
import {
	Box,
	Button,
	Typography,
	ListItemButton,
	Chip,
	Grid,
	Stack,
	useTheme,
	useMediaQuery,
	type SxProps,
	type Theme,
} from '@mui/material';

import EmptyListPlaceholder from '@ivy/components/atoms/EmptyListPlaceholder';
import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import ApplyToJobButton, {
	type ApplyToJobButtonProps,
} from '@ivy/components/organisms/ApplyToJobButton';
import {
	Profession,
	PROFESSION2PROFSUCCINT,
	PROFESSION2PROFVERBOSE,
	WORK_SCHEDULE_DISPLAY,
	WorkSchedule,
} from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { buildInternalLink } from '@ivy/lib/util/route';

const FacilityJobs_FacilityQDoc = gql(/* GraphQL */ `
	query FacilityJobs_Facility($facilityId: uuid!) {
		facility: facility_by_pk(id: $facilityId) {
			id
			contracts(
				where: { active: { _eq: true } }
				order_by: { created_at: asc }
			) {
				id
				isOwner: is_contract_owner
				...ApplyToJobButton_Contract
			}
			postings(order_by: { created_at: desc }) {
				id
			}
			...FacilityJobs_Facility
		}
	}
`);

const FacilityJobs_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityJobs_Facility on facility {
		id
		postings(order_by: { created_at: desc }) {
			id
			slug
			lastModified: last_modified
			lastModifiedDate @client
			title
			hasRate: has_rate
			ratePeriod: rate_period
			isExactRate: is_exact_rate
			exactRate: exact_rate
			minRate: min_rate
			maxRate: max_rate
			wageTrunc @client
			schedule
			professions {
				id
				profession
			}
		}
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			id
			isOwner: is_contract_owner
			...ApplyToJobButton_Contract
		}
	}
`);

const ROW_SPACING = {
	xs: 8,
	lg: 15,
};

const PostingListItem = ({ posting, profession, sx }) => {
	const { title, wageTrunc, schedule } = posting;
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	return (
		<ListItemButton
			key={posting.id}
			component={RouteLink}
			to={buildInternalLink(RouteLink.routes.JOB_POSTING_SHOW, {
				postingId: [posting.id, posting.slug],
			})}
			state={{
				backNav: {
					target: 'facility',
				},
			}}
			sx={[
				{
					display: 'block',
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					p: {
						xs: 2,
						md: 3,
					},
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				},
				sx,
			]}
		>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				mb={2}
			>
				<Typography
					noWrap
					variant='body1'
					fontWeight='bold'
					flex='1 1'
					width='0px'
				>
					{title}
				</Typography>
				{[WorkSchedule.FULL, WorkSchedule.EITHER].includes(schedule) && (
					<Chip
						size={isMobile ? 'small' : undefined}
						label={WORK_SCHEDULE_DISPLAY[WorkSchedule.FULL]}
						sx={(theme) => ({
							flex: '0 0',
							ml: 1,
							bgcolor: 'primary.subtle',
							'& .MuiChip-label': {
								...theme.typography.caption,
								color: theme.palette.text.primary,
							},
						})}
					/>
				)}
				{[WorkSchedule.PART, WorkSchedule.EITHER].includes(schedule) && (
					<Chip
						size={isMobile ? 'small' : undefined}
						label={WORK_SCHEDULE_DISPLAY[WorkSchedule.PART]}
						sx={(theme) => ({
							flex: '0 0',
							ml: 1,
							bgcolor: 'secondary.subtle',
							'& .MuiChip-label': {
								...theme.typography.caption,
								color: theme.palette.text.primary,
							},
						})}
					/>
				)}
			</Box>
			<Typography variant='body1' mb={1} noWrap>
				{PROFESSION2PROFVERBOSE[profession]}
			</Typography>
			<Typography
				variant='body1'
				noWrap
				visibility={!wageTrunc ? 'hidden' : undefined}
			>
				{wageTrunc || 'Missing salary'}
			</Typography>
		</ListItemButton>
	);
};

const JobPostingButton = ({ ownerContractId }: { ownerContractId: string }) => {
	return (
		<Button
			size='large'
			variant='contained'
			component={RouteLink}
			to={{
				pathname: RouteLink.routes.JOB_POSTING_CREATE,
				search: `contractId=${encodeURIComponent(ownerContractId)}`,
			}}
			state={{
				backNav: {
					target: 'facility',
				},
			}}
			sx={{
				mt: 1,
			}}
		>
			Post a job
		</Button>
	);
};

const getJobPostingCount = (postings, profession) => {
	return postings.filter((posting) =>
		posting.professions.some((el) => el.profession === profession),
	).length;
};

const PostingsList = ({
	profession,
	facility: rawFacility,
	ownerContractId,
	onClickApply,
	onClickShowEmployers,
	sx,
}: {
	profession: string;
	facility: FragmentType<typeof FacilityJobs_FacilityFDoc>;
	ownerContractId: string;
	sx?: SxProps<Theme>;
	onClickApply: ApplyToJobButtonProps['onClickApply'];
	onClickShowEmployers: ApplyToJobButtonProps['onClickShowEmployers'];
}) => {
	const facility = getFragmentData(FacilityJobs_FacilityFDoc, rawFacility);
	const currAcc = useCurrentAccount();
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));
	const postings =
		facility.postings?.filter((posting) =>
			posting.professions.some((el) => el.profession === profession),
		) || [];

	return (
		<Box sx={sx}>
			<Typography variant='h6' mb={2}>
				{`${
					isXs
						? PROFESSION2PROFSUCCINT[profession]
						: PROFESSION2PROFVERBOSE[profession]
				} ${
					postings.length > 0
						? `(${getJobPostingCount(postings, profession)})`
						: ''
				}`}
			</Typography>
			<Box>
				{postings.length ? (
					<Grid
						container
						spacing={3}
						sx={{
							justifyContent: 'center',
						}}
					>
						{postings.map((posting, idx, self) => (
							<Grid item key={posting.id} xs={12} lg={6}>
								<PostingListItem
									profession={profession}
									posting={posting}
									key={`posting-${posting.id}`}
									sx={{
										mb: idx === self.length - 1 ? 0 : 3,
									}}
								/>
							</Grid>
						))}
						{/* Left justify the remainder with empty grid items */}
						{[...new Array(postings.length % 2).keys()].map((el, idx) => (
							<Grid
								item
								key={idx}
								xs={12}
								lg={6}
								sx={{
									padding: '0px !important',
								}}
							/>
						))}
					</Grid>
				) : ownerContractId ? (
					<Box>
						<Typography
							variant='body1'
							color='text.secondary'
							fontStyle='italic'
							mb={2}
						>
							Facilities with job postings are shown first in search results.
							Create your first posting for this facility.
						</Typography>

						<JobPostingButton ownerContractId={ownerContractId} />
					</Box>
				) : (
					<Box>
						<Typography
							variant='body1'
							color='text.icon'
							fontWeight='bold'
							gutterBottom
						>
							Interested in working here?
						</Typography>
						<Typography variant='body1' color='text.icon' mb={2}>
							Apply directly to learn about the latest openings or to keep your
							CV on file for future openings.
						</Typography>
						{(!currAcc || currAcc.isClinician) && (
							<Box mt={1}>
								<ApplyToJobButton
									variant='normal'
									btnId='pg-facility--btn-connect__empty-job'
									size='large'
									contracts={facility.contracts}
									onClickApply={onClickApply}
									onClickShowEmployers={onClickShowEmployers}
								/>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export interface FacilityJobsProps {
	facilityId: string;
	onClickApply: ApplyToJobButtonProps['onClickApply'];
	onClickShowEmployers: ApplyToJobButtonProps['onClickShowEmployers'];
}

const FacilityJobs = ({
	facilityId,
	onClickApply,
	onClickShowEmployers,
}: FacilityJobsProps) => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(FacilityJobs_FacilityQDoc, {
		variables: {
			facilityId: facilityId,
		},
		// Reflect updates to the connection status (via job posting page) or job posting additions/deletions
		// Don't use cache-and-network then cache-first -> infinite loop problem
		fetchPolicy: 'network-only',
	});
	const ownerContractId = data?.facility?.contracts.find(
		(contract) => contract.isOwner,
	)?.id;

	return (
		<DataLoader variant='circular' data={data} loading={loading} error={error}>
			{() => (
				<Box
					sx={{
						my: Object.entries(ROW_SPACING).reduce(
							(tot, [k, v]) => ({
								...tot,
								[k]: v / 2,
							}),
							{},
						),
					}}
					component='section'
				>
					{!loading && !data!.facility!.postings?.length ? (
						<Box
							display='flex'
							sx={{ alignItems: 'center', flexDirection: 'column' }}
						>
							<EmptyListPlaceholder
								type='jobs'
								emptyText={
									ownerContractId
										? 'Facilities with job postings are shown first in search results. Create your first posting for this facility.'
										: 'Apply directly to learn about the latest openings or to keep your CV on file for future openings.'
								}
							/>
							{ownerContractId && (
								<JobPostingButton ownerContractId={ownerContractId} />
							)}
							{(!currAcc || currAcc.isClinician) && (
								<Box mt={1}>
									<ApplyToJobButton
										variant='normal'
										btnId='pg-facility--btn-connect__empty-job'
										size='large'
										contracts={data!.facility!.contracts}
										onClickApply={onClickApply}
										onClickShowEmployers={onClickShowEmployers}
									/>
								</Box>
							)}
						</Box>
					) : (
						<Stack spacing={10}>
							{Object.values(Profession).map((prof, idx) => (
								<PostingsList
									key={`posting-list-${idx}`}
									profession={prof}
									ownerContractId={ownerContractId}
									facility={data!.facility!}
									onClickApply={onClickApply}
									onClickShowEmployers={onClickShowEmployers}
								/>
							))}
						</Stack>
					)}
				</Box>
			)}
		</DataLoader>
	);
};

export default FacilityJobs;
