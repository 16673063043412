// TODO: add verified badges next to employer names
import React from 'react';

import {
	Box,
	Popover,
	type PopoverProps,
	Typography,
	Divider,
} from '@mui/material';

import { EmployerIcon } from '@ivy/components/icons';
import ApplyToJobButton, {
	type ApplyToJobButtonProps,
} from '@ivy/components/organisms/ApplyToJobButton';
import { OWNERSHIP_DESCRIPTION } from '@ivy/constants/organization';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

const EmployerListPopover_ContractFDoc = gql(/* GraphQL */ `
	fragment EmployerListPopover_Contract on facility_contract {
		id
		org {
			id
			ownershipStructure: ownership_structure
			name
		}
		...ApplyToJobButton_Contract
	}
`);

export interface EmployerListPopoverProps extends PopoverProps {
	contracts: FragmentType<typeof EmployerListPopover_ContractFDoc>[];
	onClickApply: ApplyToJobButtonProps['onClickApply'];
}

const EmployerListPopover = ({
	contracts: rawContracts,
	onClickApply,
	...props
}: EmployerListPopoverProps) => {
	const contracts = getFragmentData(
		EmployerListPopover_ContractFDoc,
		rawContracts,
	);
	const currAcc = useCurrentAccount();
	return (
		<Popover
			PaperProps={{
				sx: {
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					width: '500px',
					maxWidth: 'calc(100% - 32px)',
				},
			}}
			{...props}
		>
			<Box
				p={{
					xs: 2,
					sm: 3,
				}}
				borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
			>
				<Typography variant='h6'>Employer Groups</Typography>
			</Box>
			<Box
				p={{
					xs: 2,
					sm: 3,
				}}
			>
				{contracts.map((contract, idx, self) => {
					const { org } = contract;
					return (
						<React.Fragment key={contract.id}>
							<Box display='flex' alignItems='center'>
								<EmployerIcon
									sx={{
										display: {
											xs: 'none',
											sm: 'block',
										},
										fontSize: '40px',
										mr: 2,
										flex: '0 0 auto',
									}}
								/>
								<Box pr={2} mr='auto'>
									<Typography variant='body1' fontWeight='bold'>
										{org.name}
									</Typography>
									<Typography variant='caption' component='p'>
										{OWNERSHIP_DESCRIPTION[contract.org.ownershipStructure]}
									</Typography>
								</Box>
								{(!currAcc || currAcc.isClinician) && (
									<Box flex='0 0 auto'>
										<ApplyToJobButton
											contracts={[contract]}
											onClickApply={onClickApply}
											btnId='pg-facility--btn-connect__selected'
											size='small'
											variant='short'
										/>
									</Box>
								)}
							</Box>
							{idx !== self.length - 1 && (
								<Divider
									sx={{
										my: {
											xs: 2,
											sm: 3,
										},
									}}
								/>
							)}
						</React.Fragment>
					);
				})}
			</Box>
		</Popover>
	);
};

export default EmployerListPopover;
