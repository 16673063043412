import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';

import DataLoader from '@ivy/components/molecules/DataLoader';
import ClinicianProfile from '@ivy/components/organisms/ClinicianProfile';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const CandidateShow_ClinicianQDoc = gql(/* GraphQL */ `
	query CandidateShow_CandidateQDoc($candidateId: uuid!) {
		candidate: candidate_by_pk(id: $candidateId) {
			id
			maskedEmail: masked_email
			clinician {
				id
				...ClinicianProfile_Clinician
				creds
				account {
					id
					pi: personal_info {
						id
						firstName: first_name
						lastName: last_name
						fullName: full_name
					}
				}
			}
		}
		jobApps: job_application(
			where: { batch: { candidate_id: { _eq: $candidateId } } }
			order_by: [
				{ created_at: desc }
				{ contract: { facility: { name: asc } } }
			]
		) {
			id
			...ClinicianProfile_JobApplication
		}
	}
`);

const CandidateShow = () => {
	const { candidateId } = useParams();
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(CandidateShow_ClinicianQDoc, {
		variables: {
			candidateId,
		},
	});
	const [footerRef, { height: footerHeight }] = useElementSize();
	const { openSignupPopup } = useAuthPopup();

	return (
		<NavTemplate
			maxWidth='md'
			defaultBackNav={
				currAcc?.isOrgUser
					? {
							link: RouteUri.CANDIDATE_LIST,
							target: 'candidates',
					  }
					: undefined
			}
			pageTitle={
				data?.candidate
					? `${data.candidate?.clinician.account.pi?.fullName}, ${data.candidate?.clinician.creds}`
					: 'View Applicant'
			}
			pageNoIndex
			showSupportLauncher
		>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				keys={['candidate']}
				variant='logo'
			>
				{() => (
					<Box
						sx={{
							mt: 2,
							pb: !currAcc ? `${footerHeight}px` : undefined,
						}}
					>
						<ClinicianProfile
							clinician={data!.candidate!.clinician}
							isOwner={false}
							jobApps={data!.jobApps}
							email={data!.candidate!.maskedEmail}
						/>
						<Paper
							sx={{
								display: currAcc ? 'none' : undefined,
								bgcolor: 'primary.translucent',
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
								borderRadius: 0,
								p: {
									xs: 2,
									sm: 3,
								},
							}}
							elevation={3}
							ref={footerRef}
						>
							<Typography variant='body1' align='center'>
								Sign up to connect with{' '}
								{data!.candidate!.clinician.account.pi?.firstName} through Ivy's{' '}
								job board and <b>free</b> employer directory.
							</Typography>
							<Box display='flex' justifyContent='center' mt={2}>
								<Button
									size='large'
									variant='contained'
									onClick={() => openSignupPopup()}
								>
									Get started
								</Button>
							</Box>
						</Paper>
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default CandidateShow;
