import React from 'react';

import { Stack, Typography } from '@mui/material';

import { withBoundary, UnauthBoundary } from '@ivy/components/boundaries';
import SupportContact from '@ivy/components/molecules/SupportContact';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';

import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
	return (
		<SpotlightTemplate
			pageTitle='Forgot Password'
			pageNoIndex
			pageDescription="Forgot your password? Not to worry. We'll get you back up and running in a few simple steps."
		>
			<Stack height='100%' justifyContent='center' alignItems='center' p={1}>
				<Stack alignItems='center' mt='auto' width='100%'>
					{/* Re-align with textAlign in event of overflow */}
					<Typography
						variant='h4'
						component='h1'
						align='center'
						sx={{
							verticalAlign: 'middle',
						}}
					>
						Forgot your password?
					</Typography>
					<ForgotPasswordForm />
				</Stack>
				<SupportContact
					sx={{
						mt: 'auto',
						mb: 4,
						justifySelf: 'flex-end',
					}}
				/>
			</Stack>
		</SpotlightTemplate>
	);
};

export default withBoundary(ForgotPassword, UnauthBoundary);
