import React from 'react';

import { Typography } from '@mui/material';
import Helmet from 'react-helmet';

import Screen from '@ivy/components/atoms/Screen';

/**
 * Indicates to the user that a page or entity was not found. Also sets the `noindex` meta tag so that crawlers
 * do not index the page.
 */
const NotFoundScreen = ({ fullscreen = false }) => {
	return (
		<Screen fullscreen={fullscreen}>
			<Helmet>{<meta name='robots' content='noindex' />}</Helmet>
			<Typography variant='h3' align='center'>
				Not found
			</Typography>
		</Screen>
	);
};

export default NotFoundScreen;
