import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='21'
			height='20'
			viewBox='0 0 21 20'
			fill='none'
			{...props}
		>
			<path
				d='M12.635 17.3851L12.6441 17.3915C13.4311 17.8926 14.3656 18.1102 15.2931 18.0084C16.2205 17.9065 17.0855 17.4913 17.745 16.8313L18.318 16.2584C18.4449 16.1315 18.5457 15.9808 18.6144 15.815C18.6831 15.6492 18.7185 15.4714 18.7185 15.2919C18.7185 15.1124 18.6831 14.9347 18.6144 14.7688C18.5457 14.603 18.4449 14.4524 18.318 14.3255L15.9014 11.9107C15.7745 11.7838 15.6239 11.683 15.458 11.6143C15.2922 11.5456 15.1145 11.5102 14.935 11.5102C14.7555 11.5102 14.5777 11.5456 14.4119 11.6143C14.2461 11.683 14.0954 11.7838 13.9685 11.9107C13.7123 12.1669 13.3648 12.3108 13.0025 12.3108C12.6402 12.3108 12.2928 12.1669 12.0365 11.9107L8.17259 8.04586C7.91644 7.78963 7.77255 7.44217 7.77255 7.07987C7.77255 6.71757 7.91644 6.3701 8.17259 6.11388C8.29956 5.987 8.40028 5.83633 8.469 5.67051C8.53771 5.50468 8.57308 5.32694 8.57308 5.14744C8.57308 4.96794 8.53771 4.79019 8.469 4.62437C8.40028 4.45854 8.29956 4.30788 8.17259 4.18099L5.75694 1.76625C5.50071 1.51011 5.15325 1.36621 4.79095 1.36621C4.42865 1.36621 4.08118 1.51011 3.82496 1.76625L3.2511 2.33919C2.59125 2.99883 2.17619 3.86385 2.0745 4.79131C1.97281 5.71878 2.19058 6.65318 2.69182 7.44012L2.69729 7.44923C5.34447 11.3658 8.71791 14.7386 12.635 17.3851V17.3851Z'
				stroke='currentColor'
				strokeWidth='1.4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
