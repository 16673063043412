import React, { useState, useCallback, useRef } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Button,
	Menu,
	MenuItem,
	InputAdornment,
	TextField,
} from '@mui/material';

import { formatInteger, parseInteger } from '@ivy/lib/formatting/number';

const UnitInput = ({
	unitOptions,
	valueAmount,
	valueUnit,
	onChangeAmount,
	onChangeUnit,
	startAdornmentNode,
	disabled,
	fixedUnit,
	...props
}) => {
	const anchorRef = useRef();
	const [open, setOpen] = useState(false);

	const handleClick = useCallback(
		(e) => {
			e.stopPropagation();
			setOpen(true);
		},
		[setOpen],
	);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const handleSelect = useCallback(
		(val) => () => {
			onChangeUnit(val);
			handleClose();
		},
		[onChangeUnit, handleClose],
	);

	const handleChange = useCallback(
		(e) => {
			const { value } = e.target;
			// We get an empty string instead of null here for the variable value, which
			// will also give NaN and ultimately be forwarded as null, which is what we want
			const parsed = parseInteger(value);
			onChangeAmount(Number.isNaN(parsed) ? null : parsed);
		},
		[onChangeAmount],
	);

	const displayEndAdornment = () => {
		const AdornmentComp = fixedUnit ? (
			unitOptions.find((el) => el.value === valueUnit)?.label
		) : (
			<>
				<Button
					disabled={disabled}
					ref={anchorRef}
					onClick={handleClick}
					endIcon={open ? <ExpandLess /> : <ExpandMore />}
					sx={(theme) => ({
						...theme.typography.body1,
						color: 'text.secondary',
					})}
				>
					{unitOptions.find((el) => el.value === valueUnit)?.label}
				</Button>
				<Menu
					anchorEl={anchorRef.current}
					open={open}
					onClose={handleClose}
					anchorOrigin={{
						horizontal: 'center',
						vertical: 'bottom',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					{unitOptions.map(({ label, value }) => (
						<MenuItem
							key={`unit-option-${value}`}
							onClick={handleSelect(value)}
							selected={valueUnit === value}
						>
							{label}
						</MenuItem>
					))}
				</Menu>
			</>
		);
		return <InputAdornment position='end'>{AdornmentComp}</InputAdornment>;
	};

	return (
		<TextField
			fullWidth
			disabled={disabled}
			value={valueAmount != null ? formatInteger(valueAmount) : ''}
			onChange={handleChange}
			InputProps={{
				startAdornment: startAdornmentNode ? (
					<InputAdornment position='start'>{startAdornmentNode}</InputAdornment>
				) : undefined,
				endAdornment: displayEndAdornment(),
			}}
			{...props}
		/>
	);
};

export default UnitInput;
