import { useRef, useEffect } from 'react';

import { captureException } from '@sentry/react';
import { type Extras } from '@sentry/types';

const useErrorReporting = (error?: Error, extra?: Extras) => {
	const extraRef = useRef<Extras | undefined>(extra);

	useEffect(() => {
		extraRef.current = extra;
	}, [extra]);

	useEffect(() => {
		if (!error) {
			return;
		}
		console.error(error);
		if (extraRef.current) {
			captureException(error, {
				extra: extraRef.current,
			});
		} else {
			captureException(error);
		}
		// Don't run this effect is extra changes, so use a ref
	}, [error]);
};

export default useErrorReporting;
