import React from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';

import FileUpload from '@ivy/components/molecules/FileUpload';
import { type ApplyToJobExtra } from '@ivy/components/organisms/ApplyToJobPopup/applyToJobForm';
import { type MessageAndCvStepValues } from '@ivy/components/organisms/ApplyToJobPopup/MessageAndCvStep/MessageAndCvStep';
import { type FileUpload_FileFragment } from '@ivy/gql/types/graphql';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';

export interface CvSubStepValues {
	cv: FileUpload_FileFragment | null;
}

export const validation = yup.object({
	cv: yup.object().nullable().label('CV'),
});

export const initialValue = {
	cv: null,
};

const CvSubStep = ({
	formik,
}: SubFormStepProps<MessageAndCvStepValues, ApplyToJobExtra>) => {
	return (
		<Box>
			<FileUpload
				files={formik.values.cv ? [formik.values.cv] : []}
				onChange={(nv) => formik.setFieldValue('cv', nv[0] || null)}
				formErrorMessage={formik.touched.cv ? formik.errors.cv : undefined}
				boxSx={{
					px: { sm: 8, xs: 1.5 },
					py: { sm: 10, xs: 3 },
					width: { sm: '355px', xs: '100%' },
				}}
				sx={{ width: { sm: 'auto', xs: '100%' } }}
				maxFileSize={15}
				fileTypes={['.docx', '.doc', '.pdf']}
			/>
			<Typography
				variant='caption'
				color='primary.main'
				sx={{
					mt: 1,
				}}
			>
				<InfoOutlined
					fontSize='small'
					sx={{ verticalAlign: 'middle', mr: 1 }}
				/>
				<Box
					component='span'
					sx={{
						verticalAlign: 'middle',
					}}
				>
					Recruiters will always request your CV, so although it's not required,
					it's best to add it now!
				</Box>
			</Typography>
		</Box>
	);
};

export default CvSubStep;
