import React from 'react';

import LegalDocument from '@ivy/components/organisms/LegalDocument';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';

const TermsOfServicePage = () => {
	return (
		<SpotlightTemplate
			pageTitle='Terms of Service'
			pageDescription='Terms of Service for accessing the Ivy Clinicians platform.'
			pageNoIndex
		>
			<LegalDocument title='Terms of Service' />
		</SpotlightTemplate>
	);
};

export default TermsOfServicePage;
