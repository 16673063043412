import type React from 'react';

import { type ApolloClient } from '@apollo/client';
import { type FormikHelpers, type FormikProps } from 'formik-othebi';
import * as yup from 'yup';
import { type ObjectSchema } from 'yup';

import { type GraphicContentObjProps } from '@ivy/components/organisms/FormPopup/SideGraphic';
import { mergeValidationSchemas } from '@ivy/lib/forms/formikHelpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SubmitFunction<T extends Record<string, any>, K = void> {
	(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		validation: ObjectSchema<any> | null,
		values: T,
		actions: FormikHelpers<T>,
		client: ApolloClient<object>,
		onSuccess: (text?: string) => void,
		onError: (
			error: unknown,
			options?: { [key: string]: object },
			text?: string,
		) => void,
		params: K,
		refetchQueries?: string[],
	): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InterceptFunction<T extends Record<string, any>, K = void> {
	(values: T, actions: FormikHelpers<T>, extra: K, onSuccess: () => void): void;
}

interface FormStepProps<T, K = void> {
	formik: FormikProps<T>;
	initialStep: string;
	extra: K;
}

interface SideContentProps<T, K = void> {
	formik: FormikProps<T>;
	extra: K;
}

interface SubFormStepProps<T, K = void> {
	formik: FormikProps<T>;
	onNext: () => void;
	activeStep: string;
	namespace?: string;
	extra: K;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FormStepObj<T extends Record<string, any>, K = void> {
	key: string;
	title: string;
	subText?: string;
	submit?: SubmitFunction<T, K>;
	intercept?: InterceptFunction<T, K>;
	shouldShow?: (extra: K) => boolean;
	component: React.ElementType<FormStepProps<T, K>>;
	prevStep?: (values: T, extra: K) => string;
	nextStep?: (values: T, extra: K) => string;
	finalStep?: (values: T, extra: K) => boolean;
	proceedText?: (values: T, extra: K) => string | undefined;
	closeStep?: (values: T, extra: K) => boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validate?: ObjectSchema<any>;
	initialValue?: Partial<T>;
	graphicContent?:
		| GraphicContentObjProps
		| ((extra: K) => GraphicContentObjProps);
	sideContent?: React.ElementType<SideContentProps<T, K>>;
	footerComp?: (extra: K) => JSX.Element | undefined;
}

interface SubStepObj<T, K = void> {
	key: string;
	component: React.ElementType<SubFormStepProps<T, K>>;
	shouldShow?: (extra: K) => boolean;
	label: (values: T, extra: K) => React.ReactNode | string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validate?: ObjectSchema<any>;
	initialValue?: Partial<T>;
	namespace?: string;
}

export function createFormSteps<T extends { key: string }>(
	arr: T[],
): { [key: string]: T } {
	return arr.reduce(
		(result, obj) => ({
			...result,
			[obj.key]: obj,
		}),
		{},
	);
}

export function extractInitialValues(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: { initialValue?: Record<string, any> }[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> {
	return arr.reduce((result, obj) => {
		return { ...result, ...obj.initialValue };
	}, {});
}

export function extractValidation(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	arr: { validate?: ObjectSchema<any> }[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ObjectSchema<any> {
	return arr.reduce((result, obj) => {
		return obj.validate ? mergeValidationSchemas(result, obj.validate) : result;
	}, yup.object({}));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatForm<T extends Record<string, any>, K>(
	steps: SubStepObj<T, K>[],
): {
	initialValues: object;
	formSteps: { [p: string]: SubStepObj<T, K> };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validation: ObjectSchema<any>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatForm<T extends Record<string, any>, K>(
	steps: FormStepObj<T, K>[],
): {
	initialValues: object;
	formSteps: { [p: string]: FormStepObj<T, K> };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validation: ObjectSchema<any>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatForm<T extends Record<string, any>, K>(
	steps: SubStepObj<T, K>[] | FormStepObj<T, K>[],
) {
	const formSteps = createFormSteps<SubStepObj<T, K> | FormStepObj<T, K>>(
		steps,
	);
	const initialValues = extractInitialValues(steps);
	const validation = extractValidation(steps);
	return { formSteps, initialValues, validation };
}

export {
	type FormStepProps,
	type SideContentProps,
	type SubFormStepProps,
	type FormStepObj,
	type SubStepObj,
	type SubmitFunction,
	type InterceptFunction,
};
