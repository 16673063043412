import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import FacilitySuggestions from '@ivy/components/organisms/FacilitySuggestions/FacilitySuggestions';
import { type Profession } from '@ivy/constants/clinician';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

const MILE_TO_METERS = 1609.34;
// 50 miles radius for suggestions, 1609.34 meters in a mile
const SUGGESTION_RADIUS = 50 * MILE_TO_METERS;

// Opt: Instead of showing the closest facilities in a radius (to keep the section populated), we could instead
// remove the radius and just show the closest jobs (not facilities) up to a limit
const FacilityNearby_RankedFacilitiesQDoc = gql(/* GraphQL */ `
	query FacilityNearby_RankedFacilities(
		$facilityId: uuid!
		$profession: profession!
		$radius: Int!
	) {
		rankedFacilities: suggest_ranked_facilities(
			args: { fid: $facilityId, prof: $profession, radius_m: $radius }
			order_by: [
				{ max_norm_rate: desc_nulls_last }
				{ has_posting: desc }
				{ featured: desc }
				{ distance: asc }
			]
		) {
			...FacilitySuggestions_RankedFacility
		}
	}
`);

const FacilityNearby_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityNearby_Facility on facility {
		id
	}
`);

interface FacilitySuggestionsProps {
	facility: FragmentType<typeof FacilityNearby_FacilityFDoc>;
	profession: Profession;
}

const FacilityNearby = ({
	facility: rawFacility,
	profession,
}: FacilitySuggestionsProps) => {
	const facility = getFragmentData(FacilityNearby_FacilityFDoc, rawFacility);
	const { data, loading } = useQuery(FacilityNearby_RankedFacilitiesQDoc, {
		variables: {
			facilityId: facility.id,
			profession,
			radius: SUGGESTION_RADIUS,
		},
	});

	return (
		<Box
			component='section'
			display={!loading && !data?.rankedFacilities.length ? 'none' : undefined}
		>
			<Typography
				component='h2'
				variant='h5'
				sx={{
					mb: {
						xs: 3,
						md: 5,
					},
				}}
			>
				Discover Nearby Facilities
			</Typography>
			<FacilitySuggestions
				rankedFacilities={data?.rankedFacilities}
				loading={loading}
				backNavTarget='facility'
			/>
		</Box>
	);
};

export default FacilityNearby;
