import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useSearchParams,
} from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { RouteUri } from '@ivy/constants/routes';
import { usePrevious } from '@ivy/lib/hooks';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';
import { type RouteConfig } from '@ivy/lib/whitelabel/whitelabelConfig';
import NotFound from '@ivy/views/core/NotFound';

const useScrollToTop = () => {
	// Scroll restoration when changing pages (only pathnames, not queryparams)
	// https://v5.reactrouter.com/web/guides/scroll-restoration
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
};

const useCloseAuthPopups = () => {
	const { pathname } = useLocation();
	const prevPathname = usePrevious(pathname);
	const { closeAuthPopup } = useAuthPopup();

	useLayoutEffect(() => {
		// Need this to run before all other effects, so use layout effect
		if (pathname !== prevPathname) {
			closeAuthPopup();
		}
	}, [pathname, prevPathname, closeAuthPopup]);
};

/**
 * Parses the `locationState` query param from the URL and applies it to the `Location` object
 * that's returned from `useLocation`.
 *
 * This is particularly useful for setting the `backNav` state through a URL, which may be necessary
 * when opening a link in a new tab.
 */
const useLocationState = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		// See if `locationState` included as a search param
		const locationState = searchParams.get('locationState');
		if (locationState) {
			searchParams.delete('locationState');
			try {
				const parsed = JSON.parse(locationState);
				setSearchParams(searchParams, {
					replace: true,
					state: parsed,
				});
			} catch (e: unknown) {
				// Bad JSON, still remove it (SyntaxError)
				setSearchParams(searchParams, {
					replace: true,
				});
			}
		}
	}, [searchParams, setSearchParams]);
};

export const DEFAULT_ROUTE_CONFIG: RouteConfig[] = [
	{
		path: '/wp/*',
		element: <Navigate to={RouteUri.ROOT} />,
	},
	// Fallback
	{
		path: '*',
		element: <NotFound />,
	},
];

const AppRoutes = () => {
	useScrollToTop();
	useCloseAuthPopups();
	useLocationState();
	const wl = useWhitelabel();

	const routes = useMemo(() => {
		return [...wl.enabledRoutes, ...DEFAULT_ROUTE_CONFIG];
	}, [wl]);

	return (
		<Routes>
			{routes.map(({ path, element, lazy }) => (
				<Route
					key={path}
					path={path}
					element={
						lazy ? (
							<React.Suspense
								fallback={<DataLoader variant='logo' fullscreen loading />}
							>
								{element}
							</React.Suspense>
						) : (
							element
						)
					}
				/>
			))}
		</Routes>
	);
};

export default AppRoutes;
