import React from 'react';

import { Add } from '@mui/icons-material';
import {
	Box,
	Button,
	Typography,
	Divider,
	Stack,
	type SxProps,
	type Theme,
	Skeleton,
	Chip,
	type TypographyProps,
} from '@mui/material';
import { format } from 'date-fns';

import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	BriefcaseIcon,
	HandDotIcon,
	HandHeartIcon,
} from '@ivy/components/icons';
import { useBillingContext } from '@ivy/components/providers/BillingProvider';
import { RouteUri } from '@ivy/constants/routes';
import {
	type BillingProvider_CustomerFragment,
	type BillingProvider_SubscriptionFragment,
} from '@ivy/gql/types/graphql';
import {
	SubscriptionStatus,
	SubscriptionTiers,
	resolveSubscriptionStatus,
} from '@ivy/lib/services/billing';
import { combineSx } from '@ivy/lib/styling/sx';

const planStatusChipMap: Record<
	SubscriptionStatus,
	{ label: string; bgcolor: string }
> = {
	[SubscriptionStatus.ACTIVE]: {
		label: 'Active',
		bgcolor: 'primary.main',
	},
	[SubscriptionStatus.PAUSED]: {
		label: 'Paused',
		bgcolor: 'secondary.main',
	},
	[SubscriptionStatus.REQUIRES_VERIFICATION]: {
		label: 'Pending',
		bgcolor: 'secondary.main',
	},
	[SubscriptionStatus.PAST_DUE]: {
		label: 'Past Due',
		bgcolor: 'warning.main',
	},
	[SubscriptionStatus.EXPIRING]: {
		label: 'Expiring',
		bgcolor: 'warning.main',
	},
	[SubscriptionStatus.PROCESSING]: {
		label: 'Processing',
		bgcolor: 'secondary.main',
	},
	[SubscriptionStatus.INCOMPLETE]: {
		label: 'Incomplete',
		bgcolor: 'warning.main',
	},
	[SubscriptionStatus.UNPAID]: {
		label: 'Unpaid',
		bgcolor: 'error.main',
	},
};

interface HandIconProps {
	isFree: boolean;
	sx?: SxProps<Theme>;
}

const HandIcon = ({ isFree, sx }: HandIconProps) => {
	return isFree ? (
		<HandHeartIcon
			sx={combineSx(
				{
					color: 'secondary.main',
				},
				sx,
			)}
		/>
	) : (
		<HandDotIcon
			sx={combineSx(
				{
					color: 'tertiary.main',
				},
				sx,
			)}
		/>
	);
};

interface InfoProps {
	subscription?: BillingProvider_SubscriptionFragment | null;
	customer?: BillingProvider_CustomerFragment | null;
	adminAccess?: boolean;
	sx?: SxProps<Theme>;
	showDueDate?: boolean;
	showManagePlan?: boolean;
}

const Info = ({
	subscription,
	customer,
	adminAccess,
	showDueDate,
	showManagePlan,
	sx,
}: InfoProps) => {
	const isFree = !subscription;
	const currStatus = subscription
		? resolveSubscriptionStatus(
				subscription.status,
				subscription.invoice?.paymentStatus,
				subscription.cancelAt,
		  )
		: null;

	const messageDate = subscription?.invoice?.invoiceDate
		? format(new Date(subscription.invoice.invoiceDate), 'MMM d, yyyy')
		: null;

	const cancelDate = subscription?.cancelAt
		? format(new Date(subscription.cancelAt), 'MMM d, yyyy')
		: null;

	const renderPaymentInfo = () => {
		if (!subscription) return null;

		if (
			subscription.status === SubscriptionStatus.ACTIVE &&
			subscription.cancelAt
		) {
			return (
				<Typography
					variant='body1'
					sx={{
						display: showDueDate ? 'block' : 'none',
					}}
				>
					Subscription ends on{' '}
					{cancelDate ? (
						<Typography component='span' fontWeight='bold' sx={{ mr: 1 }}>
							{cancelDate}
						</Typography>
					) : (
						'unknown date'
					)}
				</Typography>
			);
		} else if (adminAccess) {
			const paymentTotal = subscription.invoice?.total
				? subscription.invoice.total + (customer?.balance || 0)
				: 0;
			const minimumPaymentTotal = Math.max(paymentTotal, 0);
			return (
				<Typography
					variant='body1'
					sx={{
						display: showDueDate ? 'block' : 'none',
					}}
				>
					Next payment of{' '}
					<Typography component='span' fontWeight='bold'>
						$
						{minimumPaymentTotal.toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</Typography>{' '}
					is{' '}
					{messageDate ? (
						<Typography component='span'>
							due on{' '}
							<Typography component='span' fontWeight='bold'>
								{messageDate}
							</Typography>
						</Typography>
					) : (
						<Typography component='span' fontWeight='bold'>
							due{cancelDate ? ` by ${cancelDate}` : ''}
						</Typography>
					)}
					{cancelDate
						? `. Your subscription will be canceled if payment isn’t made.`
						: ''}
				</Typography>
			);
		}
	};

	const renderHelperText = () => {
		const TypographyProps: TypographyProps = {
			variant: 'body2',
			sx: {
				bgcolor: 'primary.translucent',
				color: 'text.icon',
				px: 2,
				py: 1,
				borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				mb: 2,
			},
		};

		switch (currStatus) {
			case SubscriptionStatus.REQUIRES_VERIFICATION:
				return (
					<Typography {...TypographyProps}>
						Your account is in the process of being verified with microdeposits
						or other verification methods. An email has been sent to you with
						further instructions.
					</Typography>
				);
			case SubscriptionStatus.PROCESSING:
				return (
					<Typography {...TypographyProps}>
						Your payment is currently processing. We will email you once it has
						been completed.
					</Typography>
				);

			default:
				return null;
		}
	};

	return (
		<Box sx={sx}>
			{isFree ? (
				<Box>
					{adminAccess ? (
						<Typography
							component={RouteLink}
							color='inherit'
							underline='hover'
							to={RouteUri.CHECKOUT_FLOW}
							variant='body1'
							noWrap
						>
							<Typography component='span' fontWeight='bold' color='secondary'>
								Upgrade now
							</Typography>{' '}
							to post jobs
						</Typography>
					) : (
						<Typography color='inherit' variant='body1'>
							This is the current plan your organization is on. To post job
							postings, please upgrade to a paid plan.
						</Typography>
					)}
				</Box>
			) : (
				<Stack spacing={2}>
					<Stack spacing={1}>
						<Typography variant='body1'>
							<Typography component='span' fontWeight='bold'>
								<BriefcaseIcon
									sx={{ fontSize: 'inherit', mr: 0.5, mb: '-2px' }}
								/>
								{subscription.usedSlots}/{subscription.slots}
							</Typography>{' '}
							job {subscription.slots === 1 ? 'slot' : 'slots'} used
						</Typography>
						<Typography
							component={RouteLink}
							underline='hover'
							color='secondary'
							fontWeight='bold'
							to={RouteUri.MANAGEMENT_SUBSCRIPTION}
							variant='caption'
							noWrap
							sx={{
								display: showManagePlan ? 'block' : 'none',
							}}
						>
							Manage plan
						</Typography>
					</Stack>
					{renderPaymentInfo()}
					{!showManagePlan ? renderHelperText() : undefined}
				</Stack>
			)}
		</Box>
	);
};

const PanelSkeleton = () => {
	return (
		<>
			<Box>
				<Skeleton
					variant='circular'
					height={20}
					width={20}
					sx={{
						mr: 1,
						display: { xs: 'none', gridBreak: 'block' },
					}}
				/>
			</Box>
			<Box sx={{ flex: 1 }}>
				<Typography
					variant='h6'
					sx={{
						display: 'inline-flex',
						alignItems: 'flex-end',
						mb: { xs: 0, gridBreak: 1 },
					}}
				>
					<Skeleton
						variant='circular'
						height={20}
						width={20}
						sx={{
							mr: 1,
							display: { xs: 'block', gridBreak: 'none' },
						}}
					/>
					<Skeleton variant='rectangular' height={20} width={120} />
				</Typography>
				<Divider sx={{ my: 2, display: { xs: 'block', gridBreak: 'none' } }} />
				<Stack spacing={1}>
					<Skeleton variant='rectangular' height={20} width={120} />
					<Skeleton variant='rectangular' height={10} width={70} />
				</Stack>
			</Box>
		</>
	);
};

interface PlanStatusPanelProps {
	sx?: SxProps<Theme>;
	variant?: 'contained' | 'outlined';
	showJobPostingButton?: boolean;
	showStatus?: boolean;
	showDueDate?: boolean;
	showManagePlan?: boolean;
}

const PlanStatusPanel = ({
	sx,
	variant,
	showJobPostingButton,
	showStatus,
	showDueDate,
	showManagePlan,
}: PlanStatusPanelProps) => {
	const { subscription, adminAccess, customer, loading } = useBillingContext();
	const isFree = !subscription;
	const isEnterprise = subscription?.tier === SubscriptionTiers.ENTERPRISE;
	const isStarter = subscription?.tier === SubscriptionTiers.STARTER;
	const currStatus = subscription
		? resolveSubscriptionStatus(
				subscription.status,
				subscription.invoice?.paymentStatus,
				subscription.cancelAt,
		  )
		: null;

	const renderInterval = () => {
		const interval = subscription?.interval;
		if (interval === 'MONTHLY') {
			return 'monthly plan';
		} else if (interval === 'ANNUALLY') {
			return 'annual plan';
		} else {
			return null;
		}
	};

	const renderChip = () => {
		const { bgcolor = 'transparent', label = '' } = currStatus
			? planStatusChipMap[currStatus]
			: {};
		return (
			<Chip
				label={label}
				sx={{
					ml: 1,
					fontSize: 'caption.fontSize',
					color: 'primary.contrastText',
					bgcolor: bgcolor,
				}}
			/>
		);
	};

	const displayErrorChip =
		showJobPostingButton && currStatus === SubscriptionStatus.INCOMPLETE;

	return (
		<Box
			id='PlanStatusPanel_Container'
			display='flex'
			flexDirection='row'
			sx={combineSx(
				{
					border:
						variant === 'contained' || variant === 'outlined'
							? '1px solid #CDCFD2'
							: undefined,
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					bgcolor: variant === 'contained' ? 'light4.main' : undefined,
					minWidth: { xs: 'none', sm: '450px' },
				},
				sx,
			)}
		>
			{loading ? (
				<PanelSkeleton />
			) : (
				<>
					<Box>
						<HandIcon
							isFree={isFree}
							sx={{
								mr: 1,
								display: { xs: 'none', gridBreak: 'block' },
							}}
						/>
					</Box>
					<Box sx={{ flex: 1, mr: 3 }}>
						<Box sx={{ display: 'flex' }}>
							<Typography
								variant='h6'
								sx={{
									display: 'inline-flex',
									alignItems: 'flex-end',
									mb: { xs: 0, gridBreak: 1 },
								}}
							>
								<HandIcon
									isFree={isFree}
									sx={{
										mr: 1,
										display: { xs: 'block', gridBreak: 'none' },
									}}
								/>
								{isFree
									? 'Free plan'
									: isEnterprise
									? 'Enterprise plan'
									: isStarter
									? 'Starter plan'
									: 'Pro plan'}
								<Typography
									variant='caption'
									sx={{
										ml: 1,
										mb: 0.5,
										display: isFree || displayErrorChip ? 'none' : 'block',
									}}
								>
									({renderInterval()})
								</Typography>
							</Typography>
							{showStatus || displayErrorChip ? renderChip() : null}
						</Box>
						<Divider
							sx={{ my: 2, display: { xs: 'block', gridBreak: 'none' } }}
						/>
						<Info
							subscription={subscription}
							customer={customer}
							adminAccess={adminAccess}
							showDueDate={showDueDate}
							showManagePlan={showManagePlan}
						/>
					</Box>
					{showJobPostingButton ? (
						<Box
							sx={{
								display: { xs: 'none', gridBreak: 'flex' },
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button
								disabled={
									!!subscription &&
									subscription.active &&
									subscription.usedSlots !== null &&
									subscription.slots !== null &&
									subscription.usedSlots >= subscription.slots
								}
								color='secondary'
								component={RouteLink}
								to={RouteLink.routes.JOB_POSTING_CREATE}
								state={{
									backNav: {
										target: 'jobs postings',
									},
								}}
								variant='contained'
								startIcon={<Add />}
								sx={{ px: 3, py: 2 }}
							>
								Add job posting
							</Button>
						</Box>
					) : null}
				</>
			)}
		</Box>
	);
};

export default PlanStatusPanel;
