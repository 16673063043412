import React from 'react';

import { Navigate } from 'react-router-dom';

import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

const UnauthBoundary = ({ children }: { children?: React.ReactNode }) => {
	const currAcc = useCurrentAccount();

	if (currAcc) {
		return <Navigate to={RouteUri.ROOT} replace />;
	}

	return children;
};

export default UnauthBoundary;
