import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import {
	Box,
	useTheme,
	Alert,
	useMediaQuery,
	type SxProps,
	type Theme,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import {
	SalaryListWithProvider as SalaryList,
	INITIAL_COLUMNS,
	COLUMNS,
} from '@ivy/components/organisms/SalaryList';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { type SalaryList_SearchSalaryReportsQuery } from '@ivy/gql/types/graphql';

const FacilitySalary_FacilityQDoc = gql(/* GraphQL */ `
	query FacilitySalary_Facility($facilityId: uuid!) {
		facility: facility_by_pk(id: $facilityId) {
			id
			name
		}
	}
`);

export interface FacilitySalaryProps {
	facilityId: string;
	onClickSalary: (event: React.SyntheticEvent) => void;
	sx?: SxProps<Theme>;
}

const ROW_SPACING = {
	xs: 8,
	lg: 15,
};

const FacilitySalary = ({
	facilityId,
	onClickSalary,
	sx,
}: FacilitySalaryProps) => {
	const currAcc = useCurrentAccount();
	const shouldBlur =
		!currAcc ||
		(!currAcc.clinician?.reportedSalary &&
			!currAcc.superuser &&
			!currAcc.accessSalary);
	const [salaryData, setSalaryData] =
		useState<SalaryList_SearchSalaryReportsQuery>();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery(theme.breakpoints.up('gridBreak'), {
		noSsr: true,
	});

	const { data, loading, error } = useQuery(FacilitySalary_FacilityQDoc, {
		variables: {
			facilityId: facilityId,
		},
		// Reflect updates to the connection status (via job posting page) or job posting additions/deletions
		// Don't use cache-and-network then cache-first -> infinite loop problem
		fetchPolicy: 'network-only',
	});

	return (
		<DataLoader variant='circular' data={data} loading={loading} error={error}>
			{() => (
				<Box
					sx={{
						my: Object.entries(ROW_SPACING).reduce(
							(tot, [k, v]) => ({
								...tot,
								[k]: v / 2,
							}),
							{},
						),
					}}
				>
					<Box component='section' sx={sx}>
						<Alert
							severity='info'
							sx={{
								width: 'fit-content',
								display: {
									gridBreak: 'flex',
									xs: 'none',
								},
								mb: 3,
							}}
						>
							Salary data is self-reported by physicians, physician assistants,
							and nurse practitioners.
						</Alert>
						<SalaryList
							onClickAddSalary={onClickSalary}
							where={{
								salary_report_fmt: {
									facility_id: {
										_eq: facilityId,
									},
								},
							}}
							hideSearchBar
							tableProps={{
								sx: {
									height: `calc((80px * ${salaryData?.agg?.aggregate!
										.count}) + 93px)`,
									maxHeight: '75vh',
									minHeight: '750px',
								},
								hideFooterDisclaimer: true,
							}}
							sx={{
								'#salary-filter-container': {
									mb: 2,
								},
							}}
							onCompletedQuery={setSalaryData}
							restrictMobileResults
							providerProps={{
								defaultColumns: {
									...INITIAL_COLUMNS,
									[COLUMNS.FACILITY]: false,
									[COLUMNS.STATE]: false,
									[COLUMNS.ORGANIZATION]: true,
								},
							}}
						/>
						{salaryData?.agg?.aggregate!.count && !shouldBlur ? (
							<RouteLink
								to={{
									pathname: RouteLink.routes.SALARY,
									search: `search=${encodeURIComponent(
										data!.facility!.name.toLowerCase(),
									)}`,
								}}
								sx={{
									display: isDesktopOrLaptop ? 'none' : 'inline-block',
									color: theme.palette.primary.main,
									textDecorationColor: 'rgba(38, 130, 197, 0.4)',
									textDecoration: 'none',
									cursor: 'pointer',
									mt: 3,
								}}
							>
								See all salaries
							</RouteLink>
						) : null}
					</Box>
				</Box>
			)}
		</DataLoader>
	);
};

export default FacilitySalary;
