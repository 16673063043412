import React from 'react';

import { Box, LinearProgress } from '@mui/material';

import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

const LoadingLogo = () => {
	const { logo } = useWhitelabel();
	return (
		<Box
			sx={{
				width: '200px',
			}}
		>
			<Box
				component='img'
				src={logo}
				sx={{
					height: 'auto',
					width: '100%',
				}}
			/>
			<Box
				sx={{
					marginTop: '15px',
				}}
			>
				<LinearProgress color='primary' />
			</Box>
		</Box>
	);
};

export default LoadingLogo;
