import React from 'react';

import { Navigate } from 'react-router-dom';

import { type Role } from '@ivy/constants/account';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

export interface RoleBoundaryProps {
	role: Role;
	children?: React.ReactElement;
}

const RoleBoundary = ({ role, children }: RoleBoundaryProps) => {
	const currAcc = useCurrentAccount();

	if (
		!currAcc?.roles.map((el) => el.role).includes(role) &&
		!currAcc?.superuser
	) {
		return <Navigate to={RouteUri.ROOT} replace />;
	}

	return children ?? null;
};

export const createRoleBoundary =
	(role: Role) => (props: { children?: React.ReactElement }) => {
		return <RoleBoundary role={role} {...props} />;
	};

export default RoleBoundary;
