import React from 'react';

import {
	Box,
	Stack,
	Typography,
	type SxProps,
	type Theme,
} from '@mui/material';

interface EmrafiedLogoProps {
	sx?: SxProps<Theme>;
}

const EmrafiedLogo = ({ sx }: EmrafiedLogoProps) => {
	return (
		<Stack justifyContent='center' alignItems='center' spacing={2} sx={sx}>
			<Box
				component='img'
				src='https://storage.googleapis.com/ivy-assets/images/emrafied.png'
				sx={{
					width: '150px',
					height: 'auto',
				}}
			/>
			<Typography component='div' variant='body1' color='text.icon'>
				This program pays 100% of your EMRA dues.
			</Typography>
		</Stack>
	);
};

export default EmrafiedLogo;
