import React from 'react';

import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import FileUpload from '@ivy/components/molecules/FileUpload';
import { gql } from '@ivy/gql/types';

export interface FacilityGalleryFormValues {
	picture: {
		id: string;
		filename: string;
		publicUrl: string;
	};
}

export interface FacilityGalleryFormProps {
	formik: FormikProps<FacilityGalleryFormValues>;
}

const FacilityGalleryForm = ({ formik }: FacilityGalleryFormProps) => {
	return (
		<Box>
			<Box>
				<Typography variant='subtitle1' component='h2' mb={1}>
					Gallery Photo
				</Typography>
				<FileUpload
					files={formik.values.picture ? [formik.values.picture] : []}
					onChange={(newFiles) =>
						newFiles.length
							? formik.setFieldValue('picture', newFiles[0])
							: formik.setFieldValue('picture', null)
					}
					fileTypes={['.png', '.jpg', '.jpeg']}
					compressor={async (file) => {
						const { default: imageCompression } = await import(
							'browser-image-compression'
						);
						return imageCompression(file, {
							maxSizeMB: 3,
							maxWidthOrHeight: 1000,
						});
					}}
					disabled={formik.isSubmitting}
					formErrorMessage={
						formik.touched.picture &&
						formik.errors.picture &&
						typeof formik.errors.picture === 'string'
							? formik.errors.picture
							: undefined
					}
				/>
			</Box>
		</Box>
	);
};

const FacilityGalleryForm_UpdateOverviewMDoc = gql(/* GraphQL */ `
	mutation FacilityOverviewForm_UpdateOverview(
		$facilityId: uuid!
		$pictureId: uuid!
	) {
		change_facility_picture(args: { fid: $facilityId, file_id: $pictureId }) {
			id
		}
	}
`);

FacilityGalleryForm.formProps = {
	title: 'Edit Overview',
	getFormikProps: ({ picture }) => ({
		initialValues: {
			picture,
		} as FacilityGalleryFormValues,
		validationSchema: yup.object({
			picture: yup
				.object({
					id: yup.string().required(),
				})
				// In case of null values
				.typeError('Cover photo is required')
				.required()
				.label('Cover photo'),
		}),
	}),
	onSubmit:
		({ client, facility, onError, onSuccess }) =>
		async (values, actions) => {
			try {
				const response = await client.mutate({
					mutation: FacilityGalleryForm_UpdateOverviewMDoc,
					variables: {
						facilityId: facility.id,
						pictureId: values.picture ? values.picture.id : null,
					},
					refetchQueries: ['FacilityShow_Facility'],
					awaitRefetchQueries: true,
				});
				actions.setSubmitting(false);
				onSuccess(response);
			} catch (e) {
				actions.setSubmitting(false);
				onError(e);
				captureException(e, {
					extra: {
						values,
					},
				});
				return;
			}
		},
};

export default FacilityGalleryForm;
