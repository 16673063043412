import React from 'react';

export const LowIcon = ({ ...props }) => {
	return (
		<svg
			width='20'
			height='50'
			viewBox='0 0 20 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M16.6696 7.09798C16.6696 11.0181 13.4917 14.196 9.57161 14.196C5.65151 14.196 2.47363 11.0181 2.47363 7.09798C2.47363 3.17787 5.65151 0 9.57161 0C13.4917 0 16.6696 3.17787 16.6696 7.09798Z'
				fill='currentColor'
			/>
			<rect
				y='14.8421'
				width='19.7895'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
			<rect
				x='3.29785'
				y='27.2105'
				width='13.193'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
		</svg>
	);
};

export const MediumIcon = ({ ...props }) => {
	return (
		<svg
			width='54'
			height='50'
			viewBox='0 0 54 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M41.6696 9.09798C41.6696 13.0181 38.4917 16.196 34.5716 16.196C30.6515 16.196 27.4736 13.0181 27.4736 9.09798C27.4736 5.17787 30.6515 2 34.5716 2C38.4917 2 41.6696 5.17787 41.6696 9.09798Z'
				fill='currentColor'
			/>
			<rect
				x='25'
				y='16.8421'
				width='19.7895'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
			<rect
				x='28.2979'
				y='29.2105'
				width='13.193'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
			<g opacity='0.8'>
				<path
					d='M23.5419 6.19185C23.5419 9.61152 20.7697 12.3837 17.3501 12.3837C13.9304 12.3837 11.1582 9.61152 11.1582 6.19185C11.1582 2.77219 13.9304 0 17.3501 0C20.7697 0 23.5419 2.77219 23.5419 6.19185Z'
					fill='currentColor'
				/>
				<rect
					x='9'
					y='12.9473'
					width='17.2632'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
				<rect
					x='11.877'
					y='23.7368'
					width='11.5088'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
};

export const HighIcon = ({ ...props }) => {
	return (
		<svg
			width='54'
			height='50'
			viewBox='0 0 54 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g opacity='0.8'>
				<path
					d='M17.5419 6.19185C17.5419 9.61152 14.7697 12.3837 11.3501 12.3837C7.93039 12.3837 5.1582 9.61152 5.1582 6.19185C5.1582 2.77219 7.93039 0 11.3501 0C14.7697 0 17.5419 2.77219 17.5419 6.19185Z'
					fill='currentColor'
				/>
				<rect
					x='3'
					y='12.9473'
					width='17.2632'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
				<rect
					x='5.87695'
					y='23.7368'
					width='11.5088'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M48.4133 9.28573C48.4133 12.205 46.0468 14.5715 43.1275 14.5715C40.2083 14.5715 37.8418 12.205 37.8418 9.28573C37.8418 6.3665 40.2083 4 43.1275 4C46.0468 4 48.4133 6.3665 48.4133 9.28573Z'
					fill='currentColor'
				/>
				<rect
					x='36'
					y='15.0526'
					width='14.7368'
					height='14.7368'
					rx='2.45614'
					fill='currentColor'
				/>
				<rect
					x='38.4561'
					y='24.2631'
					width='9.82456'
					height='14.7368'
					rx='2.45614'
					fill='currentColor'
				/>
			</g>
			<path
				d='M35.6696 9.09798C35.6696 13.0181 32.4917 16.196 28.5716 16.196C24.6515 16.196 21.4736 13.0181 21.4736 9.09798C21.4736 5.17787 24.6515 2 28.5716 2C32.4917 2 35.6696 5.17787 35.6696 9.09798Z'
				fill='currentColor'
			/>
			<rect
				x='19'
				y='16.8421'
				width='19.7895'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
			<rect
				x='22.2979'
				y='29.2105'
				width='13.193'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
		</svg>
	);
};

export const VeryHighIcon = ({ ...props }) => {
	return (
		<svg
			width='54'
			height='50'
			viewBox='0 0 54 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g opacity='0.8'>
				<path
					d='M14.5419 6.19185C14.5419 9.61152 11.7697 12.3837 8.35006 12.3837C4.93039 12.3837 2.1582 9.61152 2.1582 6.19185C2.1582 2.77219 4.93039 0 8.35006 0C11.7697 0 14.5419 2.77219 14.5419 6.19185Z'
					fill='currentColor'
				/>
				<rect
					y='12.9473'
					width='17.2632'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
				<rect
					x='2.87695'
					y='23.7368'
					width='11.5088'
					height='17.2632'
					rx='2.87719'
					fill='currentColor'
				/>
			</g>
			<g opacity='0.7'>
				<path
					d='M45.4133 9.28573C45.4133 12.205 43.0468 14.5715 40.1275 14.5715C37.2083 14.5715 34.8418 12.205 34.8418 9.28573C34.8418 6.3665 37.2083 4 40.1275 4C43.0468 4 45.4133 6.3665 45.4133 9.28573Z'
					fill='currentColor'
				/>
				<rect
					x='33'
					y='15.0526'
					width='14.7368'
					height='14.7368'
					rx='2.45614'
					fill='currentColor'
				/>
				<rect
					x='35.4561'
					y='24.2631'
					width='9.82456'
					height='14.7368'
					rx='2.45614'
					fill='currentColor'
				/>
			</g>
			<path
				d='M51.8655 22.7755C51.8655 24.8607 50.1751 26.551 48.09 26.551C46.0048 26.551 44.3145 24.8607 44.3145 22.7755C44.3145 20.6904 46.0048 19 48.09 19C50.1751 19 51.8655 20.6904 51.8655 22.7755Z'
				fill='currentColor'
			/>
			<rect
				x='43'
				y='26.8946'
				width='10.5263'
				height='10.5263'
				rx='1.75439'
				fill='currentColor'
			/>
			<rect
				x='44.7549'
				y='33.4738'
				width='7.01754'
				height='10.5263'
				rx='1.75439'
				fill='currentColor'
			/>
			<path
				d='M32.6696 9.09798C32.6696 13.0181 29.4917 16.196 25.5716 16.196C21.6515 16.196 18.4736 13.0181 18.4736 9.09798C18.4736 5.17787 21.6515 2 25.5716 2C29.4917 2 32.6696 5.17787 32.6696 9.09798Z'
				fill='currentColor'
			/>
			<rect
				x='16'
				y='16.8421'
				width='19.7895'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
			<rect
				x='19.2979'
				y='29.2105'
				width='13.193'
				height='19.7895'
				rx='3.29825'
				fill='currentColor'
			/>
		</svg>
	);
};
