import React from 'react';

import { captureException } from '@sentry/react';

import ErrorScreen from '@ivy/components/molecules/ErrorScreen';

interface ErrorBoundaryProps {
	children?: React.ReactNode;
	fullscreen?: boolean;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		captureException(error, {
			extra: {
				errorInfo,
			},
		});
	}

	render() {
		if (this.state.hasError) {
			return <ErrorScreen fullscreen={this.props.fullscreen} />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
