import type React from 'react';
import { createContext } from 'react';

import { type QueryHookOptions, type QueryResult } from '@apollo/client';
import { type OperationVariables } from '@apollo/client';

import type useEntityFilters from '@ivy/components/organisms/Map/filters/useEntityFilters';

import {
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
} from '../BaseMap';

export interface MapContextValue<
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
	TVariables extends OperationVariables = OperationVariables,
	TPreviewVariables extends OperationVariables = OperationVariables,
> {
	queryResponse: QueryResult<TData, TVariables>;
	setQueryOptions: React.Dispatch<
		React.SetStateAction<QueryHookOptions<TData, TVariables> | undefined>
	>;
	dataResolver: () => TMapItemObject[] | undefined;
	dataNearbyResolver?: () => TNearbyItemObject[] | undefined;

	queryPreviewResponse: QueryResult<TPreviewData, TPreviewVariables>;
	setQueryPreviewOptions: React.Dispatch<
		React.SetStateAction<
			QueryHookOptions<TPreviewData, TPreviewVariables> | undefined
		>
	>;
	dataPreviewResolver: () => TMapItemObject | undefined;
	entityFilters: ReturnType<typeof useEntityFilters>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default createContext<MapContextValue<any, any, any, any>>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	undefined as any,
);
