import React from 'react';

import { Box, SvgIcon } from '@mui/material';

const DottedBlob = ({ stroke, ...props }) => {
	return (
		<Box {...props}>
			<SvgIcon
				width='628'
				height='535'
				viewBox='0 0 628 535'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={{
					height: '100%',
					width: 'auto',
				}}
			>
				<path
					opacity='0.3'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M110.111 61.014C171.181 4.79542 253.223 2.89049 328.645 1.30779C390.572 0.00826886 443.167 20.9889 491.754 53.0054C547.959 90.0417 612.613 124.918 623.652 195.702C635.889 274.177 608.062 364.401 550.65 430.67C494.035 496.018 410.673 526.322 331.863 532.505C259.899 538.151 203.312 500.823 148.374 462.23C86.9334 419.069 10.4191 381.689 2.75357 301.309C-5.27792 217.092 42.2601 123.475 110.111 61.014Z'
					stroke={stroke}
					strokeWidth='2'
					strokeDasharray='34 34'
					fill='none'
				/>
			</SvgIcon>
		</Box>
	);
};

export default DottedBlob;
