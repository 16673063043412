import {
	WorkerClassification,
	ClinicianSpecialTypes,
	type PaymentRatePeriod,
	type Profession,
} from '@ivy/constants/clinician';
import {
	type FacilitySelect_FacilityFragment,
	type SalaryReportFormGeneralStep_OrganizationFragment,
} from '@ivy/gql/types/graphql';

//form steps
import BenefitsStep, {
	validation as benefitValidation,
	initialValue as benefitInitialValue,
} from './BenefitsStep';
import CompStep, {
	validation as compValidation,
	initialValue as compensationInitialValue,
} from './CompensationStep';
import GeneralStep, {
	validation as generalValidation,
	initialValue as generalInitialValue,
} from './GeneralStep';
import SubmitStep from './SubmitStep';
import VerifyStep from './VerifyStep';

export const salaryReportFormSteps = {
	general: {
		component: GeneralStep,
		validation: generalValidation,
		nextStep: () => 'compensation',
		finalStep: false,
	},
	compensation: {
		component: CompStep,
		validation: compValidation,
		prevStep: () => 'general',
		nextStep: (values) =>
			values.workerClassification === WorkerClassification.CONTRACTOR
				? 'verify'
				: 'benefits',
		finalStep: false,
	},
	benefits: {
		component: BenefitsStep,
		validation: benefitValidation,
		prevStep: () => 'compensation',
		nextStep: () => 'verify',
		finalStep: false,
	},
	verify: {
		component: VerifyStep,
		prevStep: (values) =>
			values.workerClassification === WorkerClassification.CONTRACTOR
				? 'compensation'
				: 'benefits',
		nextStep: () => 'summary',
		finalStep: true,
	},
	summary: {
		component: SubmitStep,
		closeStep: true,
	},
};

type MapSchemaTypes = {
	string: string;
	integer: number;
	boolean: boolean;
};

type MapSchema<T extends Record<string, keyof MapSchemaTypes>> = {
	-readonly [K in keyof T]: MapSchemaTypes[T[K]];
};
const typeSchema = ClinicianSpecialTypes.reduce(
	(o, type) => ({ ...o, [type.key]: 'boolean' }),
	{},
);
type ClinicianSpecialTypesInterface = MapSchema<typeof typeSchema>;

export interface SalaryFormValues extends ClinicianSpecialTypesInterface {
	facility: FacilitySelect_FacilityFragment | null;
	organization: SalaryReportFormGeneralStep_OrganizationFragment | null;
	orgOther: string;
	experience: number | null;
	shftsMonth: number | null;
	hrsShift: number | null;
	profession: Profession | string;
	workerClassification: WorkerClassification | string;
	base: {
		pay: string | null;
		period: PaymentRatePeriod;
	};
	night: {
		included: boolean;
		pay: string | null;
		period: PaymentRatePeriod;
		unknown: boolean;
	};
	weekend: {
		included: boolean;
		pay: string | null;
		period: PaymentRatePeriod;
		unknown: boolean;
	};
	bonus: {
		included: boolean;
		value: string;
	};
	studentLoan: {
		included: boolean;
		pay: string | null;
		period: PaymentRatePeriod;
		unknown: boolean;
	};
	cme: {
		included: boolean;
		pay: string | null;
		period: PaymentRatePeriod;
		unknown: boolean;
	};
	retirement: {
		included: boolean;
		value: string;
	};
}

export const salaryInitialValues = {
	...generalInitialValue,
	...compensationInitialValue,
	...benefitInitialValue,
} as SalaryFormValues;
