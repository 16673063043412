export const AccountType = Object.freeze({
	CLINICIAN: 'CLINICIAN',
	ORG: 'ORG_USER',
});

export const AccountSignupStatus = Object.freeze({
	TAKEN: 'TAKEN',
	UNCLAIMED: 'UNCLAIMED',
	UNREGISTERED: 'UNREGISTERED',
	PENDING: 'PENDING',
	BLOCKED: 'BLOCKED',
});

export enum SuppressionCategory {
	PRODUCT = 'PRODUCT',
	PROMO = 'PROMO',
	REVIEW_NOTIFICATIONS = 'REVIEW_NOTIFICATIONS',
	ALL = 'ALL',
}

export const SuppressionCategoryDescription = {
	[SuppressionCategory.PRODUCT]: 'Product Updates',
	[SuppressionCategory.PROMO]: 'Promotional',
	[SuppressionCategory.REVIEW_NOTIFICATIONS]: 'Review Notifications',
};

export enum Role {
	ACEP_ADMIN = 'ACEP_ADMIN',
	EMRA_ADMIN = 'EMRA_ADMIN',
	ORG_ADMIN = 'ORG_ADMIN',
}

export enum OrganizationRole {
	ORG_ADMIN = 'ORG_ADMIN',
	RECRUITER = 'RECRUITER',
}

export const ORGROLE2ORGROLEVERBOSE: Readonly<{
	[k in OrganizationRole]: string;
}> = Object.freeze({
	[OrganizationRole.ORG_ADMIN]: 'Admin',
	[OrganizationRole.RECRUITER]: 'Recruiter',
});
