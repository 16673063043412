import React from 'react';

import { Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { RouteUri } from '@ivy/constants/routes';
import { gql } from '@ivy/gql/types';
import {
	type SubmitFunction,
	type FormStepObj,
} from '@ivy/lib/forms/formFormatHelpers';
import { mergeValidationSchemas } from '@ivy/lib/forms/formikHelpers';
import { filterValidData } from '@ivy/lib/forms/yupHelpers';

import GeneralStep, {
	validation as generalValidation,
	initialValues as generalInitialValue,
	type GeneralStepValues,
} from './GeneralStep';
import PreviewStep from './PreviewStep';
import ReviewStep, {
	validation as reviewValidation,
	initialValues as reviewInitialValue,
	type ReviewStepValues,
} from './ReviewStep';

type ReviewFormValues = GeneralStepValues & ReviewStepValues;

interface ExtraParams {
	fullName: string;
}

const ReviewForm_CreateReviewMDoc = gql(/* GraphQL */ `
	mutation ReviewForm_CreateReview($input: review_insert_input!) {
		insert_review_one(object: $input) {
			id
		}
	}
`);

const reviewSubmit: SubmitFunction<ReviewFormValues, ExtraParams> = async (
	validation,
	values,
	actions,
	client,
	onSuccess,
	onError,
	params,
	refetchQueries,
) => {
	const validData = validation ? filterValidData(values, validation) : values;

	try {
		await client.mutate({
			mutation: ReviewForm_CreateReviewMDoc,
			variables: {
				input: {
					currently_working: validData.isCurrentlyWorking,
					last_worked_date: validData.lastWorkedDate,
					is_anonymous: validData.isAnonymous,
					facility_id: validData.facility?.id,
					org_id: validData.org?.id,
					rating_job: validData.jobRating,
					rating_facility: validData.facilityRating.rating,
					rating_org: validData.employerRating.rating,
					review_org_content: validData.employerRating.content,
					review_facility_content: validData.facilityRating.content,
				},
			},
			refetchQueries,
			awaitRefetchQueries: !!refetchQueries,
		});
		actions?.setSubmitting(false);

		onSuccess('Your review has been submitted!');
	} catch (e) {
		actions?.setSubmitting(false);
		onError(e, { extra: values });
		captureException(e, {
			extra: {
				values,
			},
		});
	}
};

const mainGraphic = {
	img: 'https://assets.ivyclinicians.io/images/people-talking-illustration.svg',
	imgSx: {
		maxWidth: 'min(370px, 100%)',
	},
	title: `Thank you for helping others make an informed decision 💙`,
	buttonText: 'Add job review',
	footer: (
		<Stack direction='column' spacing={3}>
			<Typography variant='body1' fontWeight='bold'>
				Some groud rules
			</Typography>
			<Stack spacing={2}>
				<Stack direction='row' spacing={1}>
					<Typography variant='body2'>☝️</Typography>
					<Typography variant='body2'>
						First, make sure you have experience working for this facility and
						employer.
					</Typography>
				</Stack>
				<Stack direction='row' spacing={1}>
					<Typography variant='body2'>🚫</Typography>
					<Typography variant='body2'>
						No hate speech or defamation of individuals, please. Be nice.
					</Typography>
				</Stack>
				<Stack direction='row' spacing={1}>
					<Typography variant='body2'>👤</Typography>
					<Typography variant='body2'>
						Lastly, write about your own personal experiences, not someone
						else’s.
					</Typography>
				</Stack>
			</Stack>
			<Typography variant='body2'>
				Read the full{' '}
				<RouteLink
					sx={{
						cursor: 'pointer',
						textDecoration: 'underline',
						color: 'inherit',
					}}
					to={RouteUri.COMMUNITY_GUIDELINES}
					openInNewTab
				>
					community guidelines here.
				</RouteLink>
			</Typography>
		</Stack>
	),
};

const steps: FormStepObj<ReviewFormValues, ExtraParams>[] = [
	{
		key: 'general',
		title: 'General',
		component: GeneralStep,
		validate: generalValidation,
		initialValue: generalInitialValue,
		nextStep: () => 'review',
		graphicContent: mainGraphic,
	},
	{
		key: 'review',
		title: 'Review',
		component: ReviewStep,
		validate: reviewValidation,
		initialValue: reviewInitialValue,
		prevStep: () => 'general',
		nextStep: () => 'preview',
		graphicContent: mainGraphic,
	},
	{
		key: 'preview',
		title: 'Preview',
		component: PreviewStep,
		submit: reviewSubmit,
		validate: mergeValidationSchemas(generalValidation, reviewValidation),
		prevStep: () => 'review',
		finalStep: () => true,
		closeStep: () => true,
		graphicContent: mainGraphic,
	},
];

export { type ReviewFormValues, type ExtraParams, steps };
