import usa from 'usa-states';

const usastates = new usa.UsaStates();

export const stateAbbreviations = usastates.states
	.map((el) => el.abbreviation)
	.sort();

export const stateAbbreviationsToName = usastates.states.reduce(
	(total, curr) => {
		total[curr.abbreviation] = curr.name;
		return total;
	},
	{},
);

export const stateNamesToAbbreviations = usastates.states.reduce(
	(total, curr) => {
		total[curr.name] = curr.abbreviation;
		return total;
	},
	{},
);

export const MILE_TO_METERS = 1609.34;
