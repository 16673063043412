import React from 'react';

import NotFoundScreen from '@ivy/components/organisms/NotFoundScreen';
import BaseTemplate from '@ivy/components/templates/BaseTemplate';

const NotFound = () => {
	return (
		<BaseTemplate pageTitle='Not Found' pageNoIndex>
			<NotFoundScreen fullscreen />
		</BaseTemplate>
	);
};

export default NotFound;
