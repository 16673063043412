import React from 'react';

import Circle from '@mui/icons-material/Circle';
import {
	Box,
	Stack,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
	Button,
	Grid,
	SvgIcon,
} from '@mui/material';
import Carousel from 'react-multi-carousel';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { combineSx } from '@ivy/lib/styling/sx';

import 'react-multi-carousel/lib/styles.css';

export interface ResourcesProps {
	sx?: SxProps<Theme>;
}

interface ResourceBlob {
	img: string;
	topics: string[];
	title: string;
	subtext: string;
	link: string;
}

const resourcesArr: ResourceBlob[] = [
	{
		img: 'https://assets.ivyclinicians.io/images/emra-article-pic-1.png',
		link: 'https://www.emra.org/emresident/article/guide-to-abem-certification',
		topics: ['Board certification'],
		title: "Residents' Guide to ABEM Certification",
		subtext:
			'Becoming board certified in Emergency Medicine by the American Board of Emergency Medicine is a simple process requiring 3 steps for residents who are in their final years of training. The ABEM Reside',
	},
	{
		img: 'https://assets.ivyclinicians.io/images/emra-article-pic-2.png',
		link: 'https://www.emra.org/emresident/article/what-i-have-to-leave-residency-planning-for-your-real-world-future',
		topics: [
			'Career Planning',
			'Contracts',
			'Job Search',
			'Wellness',
			'Financial',
		],
		title:
			'What? I Have to Leave Residency? Planning Your Future “Real World” Future',
		subtext:
			'What? I Have to Leave Residency? Planning for Your "Real World" Future It happens every late fall and spring.',
	},
	{
		img: 'https://assets.ivyclinicians.io/images/emra-article-pic-3.png',
		link: 'https://www.emra.org/emresident/article/the-cv-conundrum',
		topics: ['Curriculum Vitae'],
		title: 'The CV Conundrum',
		subtext:
			"The CV Conundrum How is it that one document causes so much angst and stress in its creation? Perhaps it's because that one document plays such an important role in our futures. It seems unfair.",
	},
];

const ButtonWave = ({ ...props }) => {
	return (
		<Box
			sx={{
				position: 'relative',
				bottom: '36px',
				textAlign: 'left',
				margin: '0px auto',
				ml: '-1px',
				height: '5px',
			}}
			{...props}
		>
			<SvgIcon
				viewBox='0 0 12 12'
				width='120'
				height='85'
				id='green'
				sx={{
					stroke: 'url(#EmraLanding_ResourcesGradient)',
					width: '86px',
					height: '86px',
					transform: 'rotate(135deg)',
					overflow: 'hidden',
					path: {
						fill: 'none',
						strokeLinecap: 'round',
						strokeLinejoin: 'round',
						transition: '0.2s',
						strokeWidth: '0.03em',
						cursor: 'pointer',
					},
				}}
			>
				<defs>
					<linearGradient id='EmraLanding_ResourcesGradient'>
						<stop stopColor='#C1D82F' offset='0%'></stop>
						<stop stopColor='#50BA70' offset='100%'></stop>
					</linearGradient>
				</defs>
				<path id='MyPath' d='M3,3 Q1,1 5,5 T10,10'></path>
			</SvgIcon>
		</Box>
	);
};

interface ResourceElementProps extends ResourceBlob {
	sx?: SxProps<Theme>;
}

const ResourceElement = ({
	img,
	topics,
	title,
	subtext,
	link,
	sx,
}: ResourceElementProps) => {
	return (
		<Stack
			spacing={3}
			sx={combineSx(
				{
					flex: 'auto',
					mb: 2,
				},
				sx,
			)}
		>
			<RouteLink to={link}>
				<Box
					component='img'
					src={img || FACILITY_PLACEHOLDER_IMAGE}
					sx={{
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						objectFit: 'cover',
						height: '275px',
						width: '100%',
					}}
				/>
			</RouteLink>
			<Stack>
				<Typography
					variant='caption'
					fontStyle='italic'
					sx={{
						color: '#865D90',
						fontFamily: 'Georgia',
						fontWeight: '400',
						lineHeight: '24px',
						letterSpacing: '-0.28px',
					}}
				>
					{topics.join(', ')}
				</Typography>
				<Typography
					component='h4'
					variant='h6'
					sx={{
						color: '#231F20',
						fontSize: '32px',
						fontWeight: '400',
						letterSpacing: '-0.64px',
					}}
				>
					<RouteLink to={link} underline='hover' color='inherit'>
						{title}
					</RouteLink>
				</Typography>
			</Stack>
			<Typography
				variant='body1'
				sx={{
					color: '#231F20',
					fontSize: '16px',
					fontWeight: '400',
					lineHeight: '24px',
					letterSpacing: '-0.32px',
				}}
			>
				{subtext}
			</Typography>
			<Box sx={{ display: 'flex' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						flexDirection: 'column',
						alignItems: 'flex-start',
						color: '#231F20',
						mr: 'auto',
						fontSize: '15px',
						fontWeight: '700',
						lineHeight: '18px',
						letterSpacing: '1.95px',
						textTransform: 'uppercase',
						textDecoration: 'none',
						textAlign: 'left',
						'&:hover': {
							'#MyPath': {
								d: 'path("M 1 1 Q 4 2 5 5 T 10 10")',
							},
						},
					}}
				>
					<RouteLink openInNewTab to={link} underline='none' color='inherit'>
						Learn More
						<ButtonWave />
					</RouteLink>
				</Box>
			</Box>
		</Stack>
	);
};

interface CustomDotProps {
	index?: number;
	onClick?: () => void;
	active?: boolean;
}

const CustomDot = ({ index, onClick, active }: CustomDotProps) => (
	<Circle
		key={index}
		className={active ? 'active' : ''}
		onClick={(e) => {
			if (onClick) onClick();
			e.preventDefault();
		}}
		sx={{
			height: '8px',
			wight: '8px',
			color: '#D9D9D9',
			'&.active': {
				color: '#50BA70',
			},
		}}
	/>
);

interface ResourceListProps {
	list: ResourceBlob[];
}

const ResourceList = ({ list }: ResourceListProps) => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md'),
	);

	return isMobile ? (
		<Box
			component={Carousel}
			swipeable
			draggable
			showDots
			infinite
			autoPlay
			autoPlaySpeed={10000}
			arrows={false}
			keyBoardControl={true}
			transitionDuration={800}
			responsive={{ mobile: { breakpoint: { max: 900, min: 0 }, items: 1 } }}
			containerClass='carousel-with-custom-dots'
			sx={{
				width: '100%',
				height: '100%',
				'.react-multi-carousel-track': {
					mb: 8,
				},
			}}
			customDot={<CustomDot />}
		>
			{list.map((el) => (
				<ResourceElement key={el.title} {...el} />
			))}
		</Box>
	) : (
		<Grid container columnSpacing={{ md: 5, lg: 10 }}>
			{list.map((el) => (
				<Grid key={el.title} item xs={12} sm={6} md={4}>
					<ResourceElement {...el} />
				</Grid>
			))}
		</Grid>
	);
};

const Resources = ({ sx }: ResourcesProps) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					py: 11,
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					top: 0,
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#f1f1f1',
				}}
			/>
			<Stack
				sx={{
					width: '100%',
					height: '100%',
					overflow: 'hidden',
				}}
				spacing={4}
			>
				<Typography
					variant='h4'
					component='h3'
					sx={{ display: { md: 'block', xs: 'none' } }}
				>
					Resources
				</Typography>
				<Box sx={{ width: '100%' }}>
					<ResourceList list={resourcesArr} />
				</Box>
			</Stack>
			<Button
				size='large'
				variant='outlined'
				to='https://www.emra.org/emresident'
				component={RouteLink}
				openInNewTab
				sx={{
					mt: 10,
					bgcolor: '#ffff',
					color: '#231F20',
					px: 3,
					py: 2,
					borderRadius: 0,
					borderImageSource: 'linear-gradient(to right, #C1D82F, #005488)',
					borderWidth: '4px',
					borderImageSlice: 1,
					fontWeight: 'bold',
					lineHeight: '18px',
					letterSpacing: '1.95px',
					'&:hover': {
						bgcolor: '#000',
						color: '#fff',
						border: '4px solid #000',
					},
				}}
			>
				VIEW MORE RESOURCES
			</Button>
		</Box>
	);
};

export default Resources;
