import React from 'react';

import { useQuery } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Stack,
	Typography,
	Chip,
	Divider,
	type SxProps,
	type Theme,
	Skeleton,
} from '@mui/material';
import { format } from 'date-fns';

import { gql } from '@ivy/gql/types';
import { formatInteger } from '@ivy/lib/formatting/number';
import { type SideContentProps } from '@ivy/lib/forms/formFormatHelpers';
import { useStringifiedMemo } from '@ivy/lib/hooks';
import { useDebounce } from '@ivy/lib/hooks';
import {
	JobPostingPriceInterval,
	calculateDiscountAmount,
	resolveBasePrice,
	resolveJobPostingPrice,
	calculateDiscountRate,
	CheckoutModes,
	billingSliderMarks,
	calculateDiscountedPrice,
} from '@ivy/lib/services/billing';
import { combineSx } from '@ivy/lib/styling/sx';

import { type CheckoutFormValues, type ExtraParams } from '../CheckoutForm';

const CheckoutSummary_SubscriptionInvoicePreviewQDoc = gql(/* GraphQL */ `
	query CheckoutSummary_SubscriptionInvoicePreview(
		$interval: String!
		$quantity: Int!
		$address: CustomerAddressInput!
		$promo: String
		$orgId: String
	) {
		subscriptionInvoicePreview: get_subscription_invoice_preview(
			interval: $interval
			quantity: $quantity
			address: $address
			promo: $promo
			org_id: $orgId
		) {
			subtotal
			total
			tax
		}
	}
`);

interface LineItemProps {
	label: string;
	labelSx?: SxProps<Theme>;
	value?: string;
	valueSx?: SxProps<Theme>;
	sx?: SxProps<Theme>;
}

const LineItem = ({ label, labelSx, value, valueSx, sx }: LineItemProps) => {
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					flexDirecton: 'row',
					justifyContent: 'space-between',
				},
				sx,
			)}
		>
			{label && (
				<Typography variant='body1' sx={labelSx}>
					{label}
				</Typography>
			)}
			{value && (
				<Typography variant='body1' sx={valueSx}>
					{value}
				</Typography>
			)}
		</Box>
	);
};

const CheckoutSummary = ({
	formik,
	extra,
}: SideContentProps<CheckoutFormValues, ExtraParams>) => {
	const { values } = formik;
	const memoizedObj = useStringifiedMemo({
		interval: values.interval,
		quantity: values.slots,
	});
	const debounceObj = useDebounce(memoizedObj, 500);
	const unsyncedQuery =
		values.slots !== debounceObj.quantity ||
		values.interval !== debounceObj.interval;
	const prices = resolveJobPostingPrice(
		extra.jobPostingPrices,
		values.interval,
	);
	const basePrice = resolveBasePrice(extra.jobPostingPrices);
	const { data, loading } = useQuery(
		CheckoutSummary_SubscriptionInvoicePreviewQDoc,
		{
			variables: {
				interval: debounceObj.interval,
				quantity: debounceObj.quantity,
				promo: extra.promo,
				orgId: extra.currAcc?.orgUser?.org.id,
				address: {
					address_1: values.streetAddress1,
					address_2: values.streetAddress2,
					city: values.city,
					state: values.state,
					zipcode: values.zipcode,
				},
			},
			fetchPolicy: 'network-only',
			skip: !debounceObj || !values.slots,
		},
	);

	const isMonthly = values.interval === JobPostingPriceInterval.MONTHLY;
	const enterpriseQuote =
		values.slots >
			parseInt(billingSliderMarks[billingSliderMarks.length - 2].label) &&
		extra.mode !== CheckoutModes.PROCESS;

	const upcomingData = data?.subscriptionInvoicePreview || null;

	const expectedAmount = prices
		? calculateDiscountAmount(prices, values.slots, values.interval)
		: 0;

	const expectedSlots = values.slots;
	const customerBalance = extra.currCustomer?.balance || 0;

	const discountRate =
		prices && basePrice
			? calculateDiscountRate(prices, basePrice, values.slots, values.interval)
			: 0;

	const renderLineItems = () => {
		if (enterpriseQuote) {
			return (
				<>
					<LineItem
						label='Subtotal'
						value='-'
						valueSx={{ fontWeight: 'bold' }}
					/>
					<LineItem
						label='You will receive a custom quote based on the number of job postings requested.'
						labelSx={{ fontWeight: 'bold' }}
					/>
				</>
			);
		}

		const now = new Date();

		if (
			extra.mode !== CheckoutModes.PAYMENT &&
			extra.currSubscription?.interval === values.interval &&
			extra.currSubscription.slots === values.slots
		) {
			return (
				<>
					<LineItem
						label='Choose a different plan to see updated pricing.'
						labelSx={{ fontWeight: 'bold' }}
					/>
				</>
			);
		} else if (extra.mode === CheckoutModes.PAYMENT) {
			return (
				<>
					<LineItem
						label='Account Credits'
						value={`-$${Math.abs(customerBalance).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}`}
						valueSx={{ fontWeight: 'bold', color: 'secondary.main' }}
						sx={{
							display: customerBalance < 0 ? 'flex' : 'none',
						}}
					/>
					<Divider
						sx={{
							display: extra.mode !== CheckoutModes.PAYMENT ? 'block' : 'none',
						}}
					/>
					<LineItem
						label='Total'
						value={`$${extra.currSubscription?.invoice?.total.toLocaleString(
							undefined,
							{
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							},
						)}`}
						valueSx={{ fontWeight: 'bold' }}
					/>
					<Divider
						sx={{
							display: extra.mode !== CheckoutModes.PAYMENT ? 'block' : 'none',
						}}
					/>
					<LineItem
						label={`Your account has credits that will be applied to this purchase and future purchases.`}
						labelSx={{ fontWeight: 'bold' }}
						sx={{
							display: customerBalance < 0 ? 'flex' : 'none',
						}}
					/>
				</>
			);
		}

		if (!upcomingData) return null;

		const subtotal = Math.max(upcomingData.subtotal, 0);
		const tax = upcomingData.tax ? Math.max(upcomingData.tax, 0) : 0;
		const total = Math.max(upcomingData.total, 0);
		const proratedAmount = expectedAmount - subtotal;
		const finalTotal = Math.max(total + customerBalance, 0);
		const unusedBalance = extra.currSubscription?.invoice?.total ?? 0;

		return (
			<>
				<LineItem
					label='Subtotal'
					value={`$${expectedAmount.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`}
					valueSx={{ fontWeight: 'bold' }}
				/>
				<LineItem
					label='Tax'
					value={`$${tax.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`}
					valueSx={{ fontWeight: 'bold' }}
				/>
				<LineItem
					label={
						extra.promo
							? unusedBalance > 0
								? 'Prorated & Discounted'
								: 'Discount'
							: 'Prorated'
					}
					value={`-$${proratedAmount.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`}
					valueSx={{ fontWeight: 'bold', color: 'secondary.main' }}
					sx={{
						display: proratedAmount > 0 ? 'flex' : 'none',
					}}
				/>
				<LineItem
					label='Account Credits'
					value={`-$${Math.abs(customerBalance).toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`}
					valueSx={{ fontWeight: 'bold', color: 'secondary.main' }}
					sx={{
						display: customerBalance < 0 ? 'flex' : 'none',
					}}
				/>
				<Divider
					sx={{
						display: extra.mode !== CheckoutModes.PAYMENT ? 'block' : 'none',
					}}
				/>
				<LineItem
					label='Total'
					value={`$${finalTotal.toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}`}
					valueSx={{ fontWeight: 'bold' }}
				/>
				<Divider
					sx={{
						display: extra.mode !== CheckoutModes.PAYMENT ? 'block' : 'none',
					}}
				/>
				<LineItem
					label={`Your account has credits that will be applied to this purchase and future purchases.`}
					labelSx={{ fontWeight: 'bold' }}
					sx={{
						display: customerBalance < 0 ? 'flex' : 'none',
					}}
				/>
				<LineItem
					label={`$${formatInteger(
						extra.promo
							? unusedBalance > 0
								? subtotal + unusedBalance
								: subtotal
							: expectedAmount,
					)} will be charged ${
						isMonthly
							? `on the ${format(now, 'do')} of every month`
							: `${format(now, 'MMMM do')} each year`
					}.`}
					labelSx={{ fontWeight: 'bold' }}
				/>
			</>
		);
	};

	const renderTitle = () => {
		if (extra.mode === CheckoutModes.PAYMENT) {
			return (
				<Typography variant='h6' sx={{ display: 'flex' }}>
					Checkout
				</Typography>
			);
		} else {
			const title =
				enterpriseQuote || values.slots > 50
					? 'Enterprise'
					: values.slots === 1
					? 'Starter'
					: 'Professional';

			return (
				<Typography variant='h6' sx={{ display: 'flex' }}>
					{title} Plan
					{(expectedSlots !== 1 || !isMonthly) && !enterpriseQuote ? (
						<Chip
							label={`-${discountRate}%`}
							sx={{
								height: 'auto',
								fontSize: '12px',
								fontWeight: 'bold',
								bgcolor: 'rgba(30, 200, 106, 0.20)',
								color: 'text.icon',
								ml: 2,
								mb: 'auto',
								span: {
									px: 1,
									py: 0.25,
								},
							}}
						/>
					) : null}
				</Typography>
			);
		}
	};

	return (
		<Box
			display='flex'
			sx={{
				width: { xl: '500px', md: '400px', xs: '100%' },
				bgcolor: 'background.paper',
				py: { xl: 10, sm: 5, xs: 3 },
				px: { sm: 5, xs: 3 },
				pb: { sm: 5, xs: 3 },
			}}
		>
			<Stack direction='column'>
				<Stack spacing={2}>
					{renderTitle()}
					<Divider sx={{ mb: 2 }} />
					{!prices ? (
						[...Array(2).keys()].map((el) => (
							<Skeleton
								key={el}
								variant='rectangular'
								sx={{
									borderRadius: (theme) => `${theme.shape.borderRadius}px`,
								}}
								width='100%'
								height={22.5}
							/>
						))
					) : (
						<>
							<LineItem
								label={
									extra.mode === CheckoutModes.PAYMENT
										? 'Current Plan'
										: enterpriseQuote
										? `51+ monthly slots`
										: `$${formatInteger(
												calculateDiscountedPrice(
													prices,
													values.slots,
													values.interval,
												),
										  )} x ${values.slots} monthly ${
												values.slots === 1 ? 'slot' : 'slots'
										  }`
								}
								value={
									extra.mode === CheckoutModes.PAYMENT
										? `${
												extra.currSubscription
													? formatInteger(extra.currSubscription.slots)
													: 0
										  } ${
												extra.currSubscription?.interval ===
												JobPostingPriceInterval.MONTHLY
													? 'monthly'
													: 'annually'
										  }`
										: `${
												enterpriseQuote
													? '-'
													: isMonthly
													? `$${calculateDiscountAmount(
															prices,
															values.slots,
															values.interval,
													  ).toLocaleString(undefined, {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
													  })} `
													: `$${(
															calculateDiscountAmount(
																prices,
																values.slots,
																values.interval,
															) / 12
													  ).toLocaleString(undefined, {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
													  })}`
										  }`
								}
								valueSx={{ fontWeight: 'bold' }}
							/>
							{!isMonthly && prices && !enterpriseQuote && (
								<LineItem
									label='x12 months'
									value={`$${calculateDiscountAmount(
										prices,
										values.slots,
										JobPostingPriceInterval.ANNUALLY,
									).toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}`}
									valueSx={{ fontWeight: 'bold' }}
								/>
							)}
						</>
					)}
					<Divider sx={{ mb: 2 }} />
					<Stack spacing={2}>
						{loading || unsyncedQuery
							? [...Array(2).keys()].map((el) => (
									<Skeleton
										key={el}
										variant='rectangular'
										sx={{
											borderRadius: (theme) => `${theme.shape.borderRadius}px`,
										}}
										width='100%'
										height={22.5}
									/>
							  ))
							: renderLineItems()}
					</Stack>
					<Box>
						<Divider sx={{ mb: 3 }} />
						<Typography
							variant='caption'
							sx={{
								display: 'flex',
								visibility: isMonthly ? 'visible' : 'hidden',
								justifyContent: 'flex-start',
								width: '100%',
							}}
						>
							<InfoOutlined
								fontSize='inherit'
								sx={{
									color: 'text.icon',
									mr: 0.5,
									mt: 0.3,
								}}
							/>
							Save an additional 10% by switching to annually.
						</Typography>
					</Box>
				</Stack>
				<Box sx={{ mt: { xs: 0, md: 'auto' } }}>
					<Typography variant='caption' sx={{ mt: 3 }}>
						*Job posting slots are the number of job listings you can have
						active simultaneously. You can deactivate postings to make room for
						new ones whenever you need.
					</Typography>
				</Box>
			</Stack>
		</Box>
	);
};

export default CheckoutSummary;
