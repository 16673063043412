import React from 'react';

import { Check, Clear } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import {
	FeatureExcludedCheckIcon,
	FeatureIncludedCheckIcon,
} from '@ivy/components/icons';

export interface FeatureLineItemProps {
	included?: boolean;
	text: React.ReactNode;
}

export const FeatureLineItem = ({ included, text }: FeatureLineItemProps) => {
	return (
		<Box display='flex' sx={{ flexDirection: 'row', alignItems: 'center' }}>
			{included ? (
				<>
					<Check
						fontSize='small'
						color='secondary'
						sx={{
							verticalAlign: 'bottom',
							mr: 2,
							display: {
								xs: 'none',
								md: 'block',
							},
						}}
					/>
					<FeatureIncludedCheckIcon
						fontSize='small'
						color='secondary'
						sx={{
							verticalAlign: 'bottom',
							mr: 2,
							display: {
								xs: 'block',
								md: 'none',
							},
						}}
					/>
				</>
			) : (
				<>
					<Clear
						fontSize='small'
						sx={{
							verticalAlign: 'bottom',
							mr: 2,
							display: {
								xs: 'none',
								md: 'block',
							},
						}}
					/>
					<FeatureExcludedCheckIcon
						fontSize='small'
						sx={{
							verticalAlign: 'bottom',
							mr: 2,
							display: {
								xs: 'block',
								md: 'none',
							},
						}}
					/>
				</>
			)}
			<Typography>{text}</Typography>
		</Box>
	);
};

export default FeatureLineItem;
