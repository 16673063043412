import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Search, ErrorOutline } from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';

import {
	type FacilitySearchInputFormValues,
	type Suggestion,
} from '@ivy/components/organisms/FacilitySearchInput/facilitySearchInputTypes';
import { combineSx } from '@ivy/lib/styling/sx';

import PlaceInput from './PlaceInput';
import ProfessionSelect from './ProfessionSelect';

export interface FacilitySearchInputDesktopProps {
	formik: FormikProps<FacilitySearchInputFormValues>;
	search: string;
	setSearch: (nv: string) => void;
	suggestions: Suggestion[];
	loadingSuggestions?: boolean;
	disabled?: boolean;
	disabledSubmit?: boolean;
	hideProfession?: boolean;
	sx?: SxProps<Theme>;
	buttonId?: string;
	placeholder: string;
}

const FacilitySearchInputDesktop = ({
	formik,
	search,
	setSearch,
	suggestions,
	loadingSuggestions,
	disabled,
	disabledSubmit,
	hideProfession = false,
	sx,
	buttonId,
	placeholder,
}: FacilitySearchInputDesktopProps) => {
	const [profSelectOpen, setProfSelectOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [wrapper, setWrapper] = useState();

	const handleSearchChange = useCallback(
		(ev: React.SyntheticEvent, newValue: string) => {
			setSearch(newValue);
		},
		[setSearch],
	);

	const handleChangeProf = useCallback(
		(nv: string) => {
			formik.setFieldTouched('profession', true, false);
			formik.setFieldValue('profession', nv);
		},
		[formik],
	);

	const handleChangeLocation = useCallback(
		(ev: React.SyntheticEvent, suggestion: Suggestion | null) => {
			// Without this, input will focus after being blurred b/c of the handleClickLocationInput handler
			ev.stopPropagation();
			formik.setFieldTouched('location', true, false);
			formik.setFieldValue('location', suggestion ? suggestion.location : null);
			if (suggestion) {
				inputRef.current?.blur();
			}
		},
		[formik, inputRef],
	);

	const handleClickLocationInput = useCallback(() => {
		if (disabled) {
			return;
		}
		// Requires the openOnFocus AutoComplete prop to show "Current Location"
		inputRef.current?.focus();
	}, [inputRef, disabled]);

	const handleOpenProfSelect = useCallback(
		(ev: React.SyntheticEvent) => {
			ev.preventDefault();
			ev.stopPropagation();
			if (disabled) {
				return;
			}
			setProfSelectOpen(true);
		},
		[disabled, setProfSelectOpen],
	);

	const handleCloseProfSelect = useCallback(() => {
		setProfSelectOpen(false);
	}, [setProfSelectOpen]);

	const placeInputValue = useMemo(() => {
		// Memoizing this prevents a bug where we can't modify input after selecting
		return formik.values.location
			? {
					location: formik.values.location,
			  }
			: null;
	}, [formik.values.location]);

	// TODO: hover functionality
	return (
		<Box
			sx={combineSx(
				{
					display: 'inline-block',
					border: (theme) => `1px solid ${theme.palette.divider}`,
					// Match the button shape of the search button at the end
					borderRadius: (theme) =>
						`${theme.buttonShape?.borderRadius || theme.shape.borderRadius}px`,
					px: 4,
					bgcolor: 'background.paper',
				},
				sx,
			)}
		>
			<Box display='flex' alignItems='center' ref={setWrapper} py={2}>
				<Box
					sx={{
						flex: !hideProfession ? '0 1 calc(50% - 16.5px)' : '1 1 auto',
						WebkitTapHighlightColor: 'transparent',
						cursor: !disabled ? 'text' : undefined,
					}}
					onClick={handleClickLocationInput}
				>
					<Box>
						<Typography
							variant='caption'
							color='text.primary'
							fontWeight='bold'
						>
							Search
							{formik.touched.location && formik.errors.location && (
								<ErrorOutline
									sx={{
										color: 'error.main',
										fontSize: '16px',
										ml: 0.5,
										position: 'relative',
										top: '3px',
									}}
								/>
							)}
						</Typography>
					</Box>
					<Box>
						<PlaceInput
							disabled={disabled || formik.isSubmitting}
							fullWidth
							ref={inputRef}
							popperAnchor={wrapper}
							TextFieldProps={{
								placeholder,
								InputProps: {
									sx: (theme) => ({
										padding: '0px !important',
										'& .MuiAutocomplete-input': {
											p: '0px !important',
											...theme.typography.body2,
											...(formik.touched.location && formik.errors.location
												? {
														'::placeholder': {
															color: 'error.main',
															opacity: 1,
														},
												  }
												: undefined),
										},
										'& .MuiOutlinedInput-notchedOutline': {
											border: 'none',
										},
									}),
								},
							}}
							options={suggestions}
							inputValue={search}
							onInputChange={handleSearchChange}
							value={placeInputValue}
							onChange={handleChangeLocation}
							noOptionsText={
								!search ? 'Start typing for results' : 'No results found'
							}
							loading={loadingSuggestions}
							isOptionEqualToValue={(option, value) =>
								option.location.id === value.location.id
							}
						/>
					</Box>
				</Box>
				{!hideProfession && (
					<>
						<Divider
							orientation='vertical'
							flexItem
							sx={{
								mx: 2,
							}}
						/>
						<Box
							sx={{
								flex: '0 1 calc(50% - 16.5px)',
								// flexBasis: 'calc(50% - 16.5px)',
								cursor: !disabled ? 'pointer' : undefined,
								WebkitTapHighlightColor: 'transparent',
								minWidth: '45px',
							}}
							onClick={
								// Buggy behavior where can't close and this gets called when clicking out of the popper
								!profSelectOpen ? handleOpenProfSelect : undefined
							}
						>
							<Typography
								variant='caption'
								color='text.primary'
								fontWeight='bold'
							>
								Profession
								{formik.touched.profession && formik.errors.profession && (
									<ErrorOutline
										sx={{
											color: 'error.main',
											fontSize: '16px',
											ml: 0.5,
											position: 'relative',
											top: '3px',
										}}
									/>
								)}
							</Typography>
							<Box>
								<ProfessionSelect
									disabled={disabled || formik.isSubmitting}
									value={formik.values.profession}
									onChange={handleChangeProf}
									SelectProps={{
										onOpen: handleOpenProfSelect,
										onClose: handleCloseProfSelect,
										open: profSelectOpen,
									}}
									error={
										formik.touched.profession && !!formik.errors.profession
									}
								/>
							</Box>
						</Box>
					</>
				)}
				<Box display='flex' alignItems='center' ml={2} flex='0 0' mr={-2}>
					<Button
						size='large'
						color='primary'
						variant='contained'
						startIcon={<Search />}
						disabled={disabled || formik.isSubmitting || disabledSubmit}
						onClick={formik.submitForm}
						id={buttonId}
					>
						Search
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default FacilitySearchInputDesktop;
