const INVITE_EMAIL_IDENTIFIER = '_ivy_invite_email';
const INVITE_ORG_ID_IDENTIFIER = '_ivy_invite_org_id';

export const getInvitee = () => {
	const inviteEmail = localStorage.getItem(INVITE_EMAIL_IDENTIFIER);
	const inviteOrgId = localStorage.getItem(INVITE_ORG_ID_IDENTIFIER);

	if (inviteEmail && inviteOrgId) {
		return { inviteEmail, inviteOrgId };
	}

	return null;
};

export const clearInvitee = () => {
	localStorage.removeItem(INVITE_EMAIL_IDENTIFIER);
	localStorage.removeItem(INVITE_ORG_ID_IDENTIFIER);
};

export const storeInvitee = ({ inviteEmail, inviteOrgId }) => {
	localStorage.setItem(INVITE_EMAIL_IDENTIFIER, inviteEmail);
	localStorage.setItem(INVITE_ORG_ID_IDENTIFIER, inviteOrgId);
};
