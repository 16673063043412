import React from 'react';

import { SvgIcon } from '@mui/material';

const HourglassIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			{...props}
		>
			<path
				d='M3.33203 2V3.33333H4.66536V4.22917C4.66536 5.11285 5.01619 5.96103 5.64193 6.58594L7.05599 8L5.64193 9.41406C5.01619 10.039 4.66536 10.8871 4.66536 11.7708V12.6667H3.33203V14H12.6654V12.6667H11.332V11.7708C11.332 10.8872 10.9803 10.0389 10.3555 9.41406L8.94141 8L10.3555 6.58594C10.9808 5.96057 11.332 5.11285 11.332 4.22917V3.33333H12.6654V2H3.33203ZM5.9987 3.33333H9.9987V4.22917C9.9987 4.76015 9.78872 5.26727 9.41276 5.64323L9.05599 6H6.94271L6.58464 5.64193C6.20904 5.26683 5.9987 4.76015 5.9987 4.22917V3.33333ZM7.9987 8.94271L9.41276 10.3568C9.50794 10.452 9.5921 10.5561 9.66536 10.6667H6.33203C6.40517 10.5567 6.48961 10.453 6.58464 10.3581L7.9987 8.94271Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default HourglassIcon;
