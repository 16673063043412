import React from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	Stack,
	type Theme,
	Typography,
} from '@mui/material';

import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';

interface CounterProps {
	value: number;
	sx?: SxProps<Theme>;
}

const Counter = ({ value }: CounterProps) => {
	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<SvgIcon
				width='228'
				height='214'
				viewBox='0 0 228 214'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={{
					width: '100%',
					height: 'auto',
				}}
			>
				<path
					opacity='0.2'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M45.3045 67.1686C62.0099 43.3701 73.6479 14.0317 102.19 5.26621C132.854 -4.15067 169.787 0.453716 196.051 20.874C220.645 39.9964 225.166 73.0224 227.644 102.807C229.633 126.717 222.51 149.33 208.293 168.029C195.419 184.96 175.145 192.307 154.424 199.107C129.463 207.298 104.483 219.13 78.6652 210.567C46.2148 199.804 9.20614 180.777 1.68458 148.446C-5.78304 116.347 26.9404 93.3299 45.3045 67.1686Z'
					fill='#8DA5E2'
				/>
			</SvgIcon>
			<Stack
				direction='row'
				spacing={0.5}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{value
					.toString()
					.split('')
					.map((digit, idx) => (
						<Box
							key={idx}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								bgcolor: '#B5C6FC',
								boxShadow: '2px 1px 0px #8DA5E2',
								borderRadius: (theme) => `${theme.shape.borderRadius}px`,
								width: 51,
								height: 71,
							}}
						>
							<Typography
								variant='h4'
								component='p'
								color='primary.contrastText'
							>
								{digit}
							</Typography>
						</Box>
					))}
			</Stack>
		</Box>
	);
};

interface ValuePropositionProps {
	image: React.ReactNode | string;
	header: string;
	description: React.ReactNode | string;
	sx?: SxProps<Theme>;
}

const ValueProposition = ({
	image,
	header,
	description,
	sx,
}: ValuePropositionProps) => {
	return (
		<Box
			sx={combineSx(
				{
					maxWidth: {
						xs: '300px',
						md: 'none',
					},
				},
				sx,
			)}
		>
			<Box
				sx={{
					mb: 5,
					width: '100%',
					// width: '230px',
					// maxWidth: '100%'
				}}
			>
				{typeof image === 'string' ? (
					<Box
						component='img'
						src={image}
						sx={{
							width: '100%',
							height: 'auto',
						}}
					/>
				) : (
					image
				)}
			</Box>
			<Typography
				align='center'
				variant='h5'
				component='h3'
				sx={{
					mb: 3,
				}}
			>
				{header}
			</Typography>
			<Typography align='center' variant='body1' color='text.icon'>
				{description}
			</Typography>
		</Box>
	);
};

export interface WhyIvyProps {
	facCount: number;
	orgCount: number;
	sx?: SxProps<Theme>;
}

const WhyIvy = ({ facCount, orgCount, sx }: WhyIvyProps) => {
	return (
		<Box component='section' sx={sx}>
			<Box>
				<Typography
					variant='h2'
					sx={{
						textAlign: {
							xs: 'center',
							md: 'initial',
						},
						mb: 10,
					}}
				>
					Why Ivy?
				</Typography>
			</Box>
			<Stack
				direction={{
					xs: 'column',
					md: 'row',
				}}
				spacing={{
					xs: 8,
					md: 5,
					lg: 10,
				}}
				alignItems={{
					xs: 'center',
					md: 'initial',
				}}
			>
				<ValueProposition
					image={
						<Counter
							value={facCount}
							sx={{
								width: '100%',
							}}
						/>
					}
					header='Find every ED & employer'
					description={
						<>
							All <b>{formatInteger(facCount)} emergency departments</b> and{' '}
							<b>{formatInteger(orgCount)} EM employers</b> are featured on Ivy,
							including details such as ED visits, EHR, and employer ownership
							structure.
						</>
					}
					sx={{
						flexBasis: '25%',
					}}
				/>
				<ValueProposition
					image='https://assets.ivyclinicians.io/images/clinician-value-time.png'
					header='Easy to apply'
					description='Your credentials are automatically imported to save you time, including your education and licenses. Apply to multiple employers in a single click.'
					sx={{
						flexBasis: '25%',
					}}
				/>
				<ValueProposition
					image='https://assets.ivyclinicians.io/images/clinician-value-salary.png'
					header='Compare your salary'
					description="Ever wondered if you're paid fairly? Check out our anonymously submitted salary reports with detailed pay, location, and employer breakdowns."
					sx={{
						flexBasis: '25%',
					}}
				/>
				<ValueProposition
					image='https://assets.ivyclinicians.io/images/clinician-value-privacy.png'
					header='Private and secure'
					description='No spam, unwanted email messages, or phone calls. Your information is only shared with the employers you apply to.'
					sx={{
						flexBasis: '25%',
					}}
				/>
			</Stack>
		</Box>
	);
};

export default WhyIvy;
