import * as yup from 'yup';

import { stateAbbreviations } from '@ivy/constants/location';

const MAX_CHAR_COUNT = 100;

export const ADDRESS_SCHEMA = yup.object({
	name: yup.string().max(MAX_CHAR_COUNT).required().label('Your name'),
	email: yup
		.string()
		.email()
		.max(MAX_CHAR_COUNT)
		.required()
		.label('Billing email'),
	streetAddress1: yup
		.string()
		.max(MAX_CHAR_COUNT)
		.required()
		.label('Street address 1'),
	streetAddress2: yup.string().max(MAX_CHAR_COUNT).label('Street address 2'),
	city: yup.string().max(MAX_CHAR_COUNT).required().label('City'),
	state: yup.string().oneOf(stateAbbreviations).required().label('State'),
	zipcode: yup
		.string()
		.matches(
			/^[0-9]{5}(?:-[0-9]{4})?$/,
			'Must be in the format 12345 or 12345-6789',
		)
		.required()
		.label('Zip code'),
});
