import React from 'react';

import { Close, HelpOutline } from '@mui/icons-material';
import {
	Box,
	Button,
	IconButton,
	Stack,
	Popover,
	Typography,
	type ButtonProps,
	type PopoverProps,
} from '@mui/material';

import Tooltip from '@ivy/components/atoms/Tooltip';

export interface QuickFilterPopoverProps
	extends Omit<PopoverProps, 'onClose' | 'onReset' | 'onSubmit'> {
	onReset?: ButtonProps['onClick'];
	onClose?: (ev: React.SyntheticEvent) => void;
	onSubmit: ButtonProps['onClick'];
	title?: string;
	children?: React.ReactNode;
	footerComponent: React.ReactNode;
	hideFooter?: boolean;
	tooltipContent?: React.ReactNode;
}

const QuickFilterPopover = ({
	onReset,
	onClose,
	onSubmit,
	title,
	tooltipContent,
	children,
	footerComponent,
	hideFooter = false,
	...props
}: QuickFilterPopoverProps) => {
	return (
		<Popover
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		>
			<Box
				pt={2}
				sx={{
					display: !title ? 'none' : 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					pb: 1,
					borderBottomStyle: 'solid',
					borderBottomColor: 'divider',
					borderBottomWidth: '1px',
					flexShrink: '0',
					position: 'sticky',
					top: 0,
					// Must be above label of TextFields
					zIndex: 2,
					bgcolor: 'background.paper',
					// Prevent sharp edges when becomes stickied
					borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
					borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
				}}
			>
				<IconButton
					size='small'
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
						mr: 1,
						mt: 1,
						zIndex: 3,
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>
				{title ? (
					<Typography variant='subtitle1' align='center' flexShrink='0'>
						{title}
					</Typography>
				) : (
					''
				)}
				{tooltipContent && (
					<Tooltip
						title={tooltipContent}
						placement='top'
						sx={{
							ml: 1,
						}}
					>
						<HelpOutline fontSize='small' />
					</Tooltip>
				)}
			</Box>

			<Stack spacing={2} p={2} alignItems='center'>
				{children}
			</Stack>
			<Box
				sx={{
					borderTopStyle: 'solid',
					borderTopColor: 'divider',
					borderTopWidth: '1px',
					position: 'sticky',
					bottom: 0,
					zIndex: 2,
					bgcolor: 'background.paper',
					borderBottomLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
					borderBottomRightRadius: (theme) => `${theme.shape.borderRadius}px`,
				}}
			>
				<Box display='flex' alignItems='center' p={1}>
					<Box
						sx={{
							display: hideFooter ? 'none' : undefined,
							pr: 2,
							mr: 'auto',
						}}
					>
						{footerComponent || (
							<Button size='small' onClick={onClose}>
								Cancel
							</Button>
						)}
					</Box>
					<Box
						sx={{
							pl: !hideFooter ? 1 : undefined,
							pr: 1,
							mr: hideFooter ? 'auto' : undefined,
						}}
					>
						<Button variant='outlined' size='small' onClick={onReset}>
							Reset
						</Button>
					</Box>
					<Box>
						<Button variant='contained' onClick={onSubmit} size='small'>
							Apply
						</Button>
					</Box>
				</Box>
			</Box>
		</Popover>
	);
};

export default QuickFilterPopover;
