import React, { useState, useCallback, useMemo } from 'react';

import CompleteSignupPopup from '@ivy/components/organisms/CompleteSignupPopup';
import ConfirmEmailPopup from '@ivy/components/organisms/ConfirmEmailPopup';
import LoginPopup from '@ivy/components/organisms/LoginPopup';
import OnboardingPopup from '@ivy/components/organisms/OnboardingPopup';
import ProfileCompletePopup from '@ivy/components/organisms/ProfileCompletePopup';
import { useAuthContext } from '@ivy/components/providers/AuthProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';

import AuthPopupContext, {
	type AuthPopupProviderCallbacks,
} from './AuthPopupContext';
import { AuthPopupType } from './constants';

const AuthPopupProvider = ({ children }) => {
	const { loading } = useAuthContext();
	// Whether or not to show the CompleteProfilePage after signing up
	const [disableCompletionPopup, setDisableCompletionPopup] = useState(false);
	const [authPopup, setAuthPopup] = useState<AuthPopupType | null>(null);
	const [callbacks, setCallbacks] = useState<AuthPopupProviderCallbacks | null>(
		null,
	);
	const currAcc = useCurrentAccount();

	const openLoginPopup = useCallback(
		(
			disableCompleteProfile = false,
			cb?: AuthPopupProviderCallbacks | null,
		) => {
			// Strict comparison to undefined, null is allowed
			if (cb !== undefined) {
				setCallbacks(cb);
			}
			// Since a user may choose to go from the login popup to the signup popup, the ability to still
			// set the disableCompleteProfile prop is important. The LoginPopup and SignupPopup have an agreement
			// to keep the value unchanged if the user moves between the two instead of resetting it.
			if (disableCompleteProfile !== undefined) {
				setDisableCompletionPopup(disableCompleteProfile);
			}
			setAuthPopup(AuthPopupType.LOGIN);
		},
		[setAuthPopup, setCallbacks],
	);

	const openSignupPopup = useCallback(
		(
			disableCompleteProfile = false,
			cb?: AuthPopupProviderCallbacks | null,
		) => {
			// Strict comparison to undefined, null is allowed
			if (cb !== undefined) {
				setCallbacks(cb);
			}
			if (disableCompleteProfile !== undefined) {
				setDisableCompletionPopup(disableCompleteProfile);
			}
			setAuthPopup(AuthPopupType.SIGNUP);
		},
		[setDisableCompletionPopup, setAuthPopup, setCallbacks],
	);

	const openSignupCompletePopup = useCallback(
		(cb?: AuthPopupProviderCallbacks | null) => {
			// Strict comparison to undefined, null is allowed
			if (cb !== undefined) {
				setCallbacks(cb);
			}
			setAuthPopup(AuthPopupType.SIGNUP_COMPLETE);
		},
		[setAuthPopup, setCallbacks],
	);

	const openProfilePopup = useCallback(
		(cb?: AuthPopupProviderCallbacks | null) => {
			// Strict comparison to undefined, null is allowed
			if (cb !== undefined) {
				setCallbacks(cb);
			}
			setAuthPopup(AuthPopupType.COMPLETE_CLINICIAN);
		},
		[setAuthPopup, setCallbacks],
	);

	const openConfirmEmailPopup = useCallback(
		(cb?: AuthPopupProviderCallbacks | null) => {
			// Strict comparison to undefined, null is allowed
			if (cb !== undefined) {
				setCallbacks(cb);
			}
			setAuthPopup(AuthPopupType.CONFIRM_EMAIL);
		},
		[setAuthPopup, setCallbacks],
	);

	const closeAuthPopup = useCallback(() => {
		setAuthPopup(null);
	}, [setAuthPopup]);

	const handleSuccess = useCallback(() => {
		callbacks?.onSuccess?.();
		if (!callbacks?.disableCloseOnSuccess) {
			closeAuthPopup();
		}
		setCallbacks(null);
	}, [callbacks, closeAuthPopup, setCallbacks]);

	const handleFailure = useCallback(() => {
		callbacks?.onFailure?.();
		if (!callbacks?.disableCloseOnFailure) {
			closeAuthPopup();
		}
		setCallbacks(null);
	}, [callbacks, closeAuthPopup, setCallbacks]);

	const authPopupValue = useMemo(() => {
		return {
			disableCompletionPopup,
			authPopup,
			setAuthPopup,
			openLoginPopup,
			openSignupPopup,
			openSignupCompletePopup,
			openConfirmEmailPopup,
			closeAuthPopup,
			openProfilePopup,
		};
	}, [
		disableCompletionPopup,
		authPopup,
		setAuthPopup,
		openLoginPopup,
		openSignupPopup,
		openSignupCompletePopup,
		openConfirmEmailPopup,
		closeAuthPopup,
		openProfilePopup,
	]);

	return (
		<AuthPopupContext.Provider value={authPopupValue}>
			{authPopup === AuthPopupType.LOGIN && (
				// This is allowed to render even if currAcc exists since it has a useEffect that watches for when
				// currAcc is refetched before closing after a successful login
				<LoginPopup
					open
					onClose={handleFailure}
					currAccLoading={loading}
					onSuccess={handleSuccess}
				/>
			)}
			{authPopup === AuthPopupType.SIGNUP && (
				// This is allowed to render even if currAcc exists since it has a useEffect that watches for when
				// currAcc is refetched before closing after a successful signup
				<OnboardingPopup
					open
					onClose={handleFailure}
					currAccLoading={loading}
					onSuccess={
						!disableCompletionPopup ? openSignupCompletePopup : handleSuccess
					}
				/>
			)}
			{authPopup === AuthPopupType.SIGNUP_COMPLETE && (
				<CompleteSignupPopup
					open
					// Don't use cb?.onSuccess, that should be called only after successfully completing both
					// CompleteSignupPopup and ProfileCompletePopup
					onClose={handleFailure}
				/>
			)}
			{authPopup === AuthPopupType.CONFIRM_EMAIL && (
				<ConfirmEmailPopup
					open
					currAccLoading={loading}
					onClose={handleFailure}
					onSuccess={handleSuccess}
				/>
			)}
			{authPopup === AuthPopupType.COMPLETE_CLINICIAN &&
				!!currAcc?.isClinician && (
					<ProfileCompletePopup
						open
						onSuccess={handleSuccess}
						onClose={handleFailure}
					/>
				)}
			{children}
		</AuthPopupContext.Provider>
	);
};

export default AuthPopupProvider;
