import React from 'react';

import { Stack, Box, Typography } from '@mui/material';

import SupportLink from '@ivy/components/atoms/SupportLink';

const LAPTOP_ALT_IMG =
	'https://assets.ivyclinicians.io/images/coffee-laptop-alt-illustration.svg';

const VerifyStep = () => {
	return (
		<Stack
			sx={{
				textAlign: 'center',
				maxWidth: '435px',
				mx: 'auto',
				mt: 3,
			}}
			spacing={2}
		>
			<Typography variant='h5'>✅ All done. Thank you!</Typography>
			<Typography>
				This survey is in beta-testing mode. We anticipate imperfections in this
				phase.{' '}
				<SupportLink>
					Please message us with ideas or suggestions for improvement.
				</SupportLink>{' '}
				Ivy reserves the right to review for validity all entered survey info.
			</Typography>
			<Box
				component='img'
				src={LAPTOP_ALT_IMG}
				sx={{
					height: '380px',
					mb: 2,
				}}
			/>
		</Stack>
	);
};

export default VerifyStep;
