import React from 'react';

import { Box, Paper, Typography } from '@mui/material';

const PaperSection = ({
	title,
	actions,
	children,
	headerSx,
	contentSx,
	sx,
}) => {
	return (
		<Paper variant='outlined' sx={sx}>
			<Box
				sx={[
					{
						display: !title ? 'none' : 'flex',
						pl: {
							xs: 1,
							sm: 2,
						},
						pr: {
							xs: 1,
							sm: 2,
						},
						pt: actions ? 1 : 2,
						pb: 1,
						borderBottomWidth: '1px',
						borderBottomStyle: 'solid',
						borderBottomColor: 'divider',
						justifyContent: 'space-between',
						alignItems: 'center',
					},
					headerSx,
				]}
			>
				<Box flexShrink={1} overflow='hidden'>
					{typeof title === 'string' ? (
						<Typography variant='h5' component='h1' noWrap>
							{title}
						</Typography>
					) : (
						title
					)}
				</Box>
				<Box display='flex' flexShrink={0}>
					{actions}
				</Box>
			</Box>
			<Box
				sx={[
					{
						pl: {
							xs: 1,
							sm: 2,
						},
						pr: {
							xs: 1,
							sm: 2,
						},
						pt: title ? 1 : 2,
						pb: 1,
					},
					contentSx,
				]}
			>
				{children}
			</Box>
		</Paper>
	);
};

export default PaperSection;
