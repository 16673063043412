import { useContext, type Context } from 'react';

import {
	type BaseMapItemObject,
	type NearbyBaseMapItemObject,
} from '../BaseMap';

import MapContext, { type MapContextValue } from './MapContext';

const useMapContext = <
	TData,
	TPreviewData,
	TMapItemObject extends BaseMapItemObject = BaseMapItemObject,
	TNearbyItemObject extends NearbyBaseMapItemObject = NearbyBaseMapItemObject,
>() =>
	useContext(
		MapContext as Context<
			MapContextValue<TData, TPreviewData, TMapItemObject, TNearbyItemObject>
		>,
	);

export default useMapContext;
