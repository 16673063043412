import { useMemo } from 'react';

import { type AdAuctionInput } from '@ivy/gql/types/graphql';
import { type UseBannerAd_CreateAdAuctionMutation } from '@ivy/gql/types/graphql';

import useBannerAd, { type UseBannerAdOptions } from './useBannerAd';

/**
 * Runs an ad auction. This hook is intended to be used when there is a list of items being rendered
 * with ads in-between items. Generally, the returned `adList` will be the same length of the items
 * with an ad at each item index where an ad should be inserted. If there are not enough items to reach
 * the desired spacing, an `ad` will be available at the last index to place after all items.
 *
 * For example, if your list of items is [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] and your ad spacing is 3, the returned
 * `adList` will be [undefined, undefined, ad_1, undefined, undefined, ad_2, undefined, undefined, ad_3, undefined].
 *
 * @param auction the auction to run
 * @param spacing how often to insert ads between items
 * @param length length of the items
 * @param options options passed to `useBannerAd`
 */
const useBannerAdList = (
	auction: Omit<AdAuctionInput, 'slots'>,
	spacing: number,
	length: number,
	options?: UseBannerAdOptions,
) => {
	// Place ads every `spacing` positions, but always show at least 1 at the end in case there are not enough items
	const numAdSlots = Math.max(Math.floor(length / spacing), 1);
	const { data, ...other } = useBannerAd(
		[
			{
				...auction,
				slots: numAdSlots,
			},
		],
		{
			...options,
			skip: options?.skip || !length,
		},
	);

	const adList = useMemo(() => {
		return [...Array(length).keys()].map((idx) => {
			let ad:
				| UseBannerAd_CreateAdAuctionMutation['createAdAuction']['results'][number]['winners'][number]
				| undefined;
			if ((idx + 1) % spacing === 0) {
				// Place an ad every `spacing` positions
				ad = data?.[0].winners[(idx + 1) / spacing - 1];
			} else if (length < spacing && idx === length - 1) {
				// Place an ad at the end if there are not enough positions to reach the desired spacing
				ad = data?.[0].winners[0];
			}
			return ad;
		});
	}, [data, spacing, length]);

	return {
		adList,
		...other,
	};
};

export default useBannerAdList;
