import React, {
	useCallback,
	useMemo,
	useState,
	useRef,
	useLayoutEffect,
} from 'react';

import { ArrowBackIos } from '@mui/icons-material';
import {
	useTheme,
	AppBar,
	type AppBarProps,
	Box,
	Toolbar,
	Button,
	useMediaQuery,
	Collapse,
	type Theme,
	Popover,
	type PopoverProps,
	ListItemButton,
	Typography,
	type SxProps,
} from '@mui/material';

import BackNavButton, {
	type BackNavButtonProps,
} from '@ivy/components/atoms/BackNavButton';
import PopperArrow from '@ivy/components/atoms/PopperArrow';
import RouteLink from '@ivy/components/atoms/RouteLink';
import AuthHeader from '@ivy/components/organisms/AuthHeader';
import MobileMenu from '@ivy/components/organisms/MobileMenu';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { type MenuNode, type MenuNodeLeaf } from '@ivy/lib/helpers/menu';
import { useDisplayPromotion } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

export interface TopBarProps extends AppBarProps {
	backNav?: BackNavButtonProps['backNav'];
	disableShrink?: boolean;
	transparentUntilScroll?: boolean;
}

interface SubmenuItemProps {
	item: MenuNodeLeaf;
	onClose?: () => void;
	sx?: SxProps<Theme>;
}

const SubmenuItem = ({ item, onClose, sx }: SubmenuItemProps) => {
	const handleClick: React.MouseEventHandler = useCallback(
		(ev) => {
			item.onClick && item.onClick(ev);
			onClose && onClose();
		},
		[item, onClose],
	);

	return (
		<ListItemButton
			component={RouteLink}
			to={item.route}
			openInNewTab={item.openInNewTab}
			onClick={handleClick}
			sx={combineSx(
				{
					py: 2,
				},
				sx,
			)}
		>
			<Typography variant='button' fontWeight='bold' color='primary.main'>
				{item.label}
			</Typography>
		</ListItemButton>
	);
};

interface SubmenuPopoverProps extends PopoverProps {
	submenu: MenuNodeLeaf[];
	onClose?: () => void;
}

const SubmenuPopover = ({
	submenu,
	onClose,
	...props
}: SubmenuPopoverProps) => {
	return (
		<Popover
			PaperProps={{
				sx: {
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				},
			}}
			onClose={onClose}
			{...props}
		>
			{submenu.map((item, idx, self) => (
				<SubmenuItem
					item={item}
					// idx doesn't change, so okay to use here
					key={idx}
					onClose={onClose}
					sx={{
						borderBottom:
							idx === self.length - 1
								? undefined
								: (theme) => `1px solid ${theme.palette.divider}`,
					}}
				/>
			))}
		</Popover>
	);
};

const MenuItem = ({
	item,
	promo,
}: {
	item: MenuNode;
	promo?: React.ReactNode;
}) => {
	const [open, setOpen] = useState(false);
	const anchorEl = useRef<HTMLButtonElement | null>(null);
	const dense = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'), {
		noSsr: true,
	});

	const handleClick = useCallback(() => {
		setOpen(true);
	}, [setOpen]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	return (
		<Box sx={{ position: 'relative' }}>
			<Button
				ref={anchorEl}
				{...('route' in item &&
					!!item.route && {
						component: RouteLink,
						to: item.route,
						openInNewTab: item.openInNewTab,
						onClick: item.onClick,
					})}
				{...('submenu' in item && {
					onClick: handleClick,
				})}
				sx={{
					whiteSpace: 'nowrap',
					mr: {
						xs: 1,
						lg: 2,
					},
				}}
				startIcon={'icon' in item && item.icon}
				size={dense ? 'small' : undefined}
			>
				{item.label}
			</Button>
			{'submenu' in item && (
				<SubmenuPopover
					submenu={item.submenu}
					open={open}
					onClose={handleClose}
					anchorEl={anchorEl.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				/>
			)}
			{!!promo && (
				<PopperArrow
					open={!!anchorEl.current}
					anchorEl={anchorEl.current}
					placement='bottom'
				>
					{promo}
				</PopperArrow>
			)}
		</Box>
	);
};

// Note using position = 'sticky' will also require disableShrink since it glitches on the shrink threshold
const TopBar = ({
	backNav,
	sx,
	position,
	disableShrink,
	transparentUntilScroll = false,
	...props
}: TopBarProps) => {
	const whitelabel = useWhitelabel();
	const theme = useTheme();
	const currAcc = useCurrentAccount();
	const {
		showPromo,
		showMenuItemPromo,
		closePromotion,
		PromoComponent,
		primaryRoute,
	} = useDisplayPromotion();
	const items = useMemo(
		() => whitelabel.menu.getLandingMenuItems?.(currAcc) || [],
		[currAcc, whitelabel.menu],
	);
	const [bgcolor, setBgcolor] = useState(
		transparentUntilScroll ? 'transparent' : theme.palette.light4.main,
	);
	const isMobileMenu = useMediaQuery(theme.breakpoints.down('mobileMenu'), {
		noSsr: true,
	});
	const toolbarRef = useRef<HTMLDivElement>(null);
	const displayMobilePromotion = !isMobileMenu && showMenuItemPromo;

	useLayoutEffect(() => {
		if (disableShrink || (isMobileMenu && !transparentUntilScroll)) {
			return;
		}
		let handleScroll;
		if (isMobileMenu) {
			// Must have transparentUntilScroll to get to this point in the code
			handleScroll = () => {
				if (!toolbarRef.current) {
					return;
				}
				if (
					document.body.scrollTop > 0 ||
					document.documentElement.scrollTop > 0
				) {
					setBgcolor(theme.palette.light4.main);
				} else {
					setBgcolor('transparent');
				}
			};
		} else {
			handleScroll = () => {
				if (!toolbarRef.current) {
					return;
				}
				if (
					document.body.scrollTop > (transparentUntilScroll ? 0 : 104) ||
					document.documentElement.scrollTop >
						(transparentUntilScroll ? 0 : 104)
				) {
					toolbarRef.current.style.height = '64px';
					if (transparentUntilScroll) {
						setBgcolor(theme.palette.light4.main);
					}
				} else {
					toolbarRef.current.style.height = '104px';
					if (transparentUntilScroll) {
						setBgcolor('transparent');
					}
				}
			};
		}
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isMobileMenu, toolbarRef, disableShrink, transparentUntilScroll, theme]);

	return (
		<>
			<AppBar
				// Note this renders a <header> tag
				variant='outlined'
				// Must specify to avoid warning
				elevation={0}
				position={position || 'fixed'}
				sx={combineSx(
					{
						bgcolor: bgcolor,
						transition: 'margin 250ms ease 0s',
					},
					sx,
				)}
				{...props}
			>
				<Toolbar
					ref={toolbarRef}
					disableGutters
					sx={{
						pl: {
							xs: 1,
							md: 3,
						},
						[theme.breakpoints.down('mobileMenu')]: {
							// Add !important so that JS can't overwrite
							height: '64px !important',
						},
						[theme.breakpoints.up('mobileMenu')]: {
							height: '104px',
						},
						transition: {
							mobileMenu: '0.4s',
						},
					}}
				>
					<BackNavButton
						titlelize
						startIcon={<ArrowBackIos />}
						size='small'
						sx={{
							mr: 1,
							display: {
								mobileMenu: 'none',
							},
							'& .MuiButton-startIcon': {
								mr: 0,
							},
						}}
						backNav={backNav}
					/>
					<RouteLink
						to={RouteLink.routes.ROOT}
						sx={{
							flex: '0 1 200px',
							mr: {
								xs: 1,
								mobileMenu: 2,
							},
						}}
					>
						<Box
							component='img'
							alt={whitelabel.productName}
							sx={{
								width: '100%',
								height: 'auto',
							}}
							src={whitelabel.logo}
						/>
					</RouteLink>
					{!whitelabel.menu.disabled && (
						<>
							<MobileMenu
								id='TopBar_MobileMenu'
								items={items}
								iconButtonProps={{
									sx: {
										ml: 'auto',
										mr: 1,
										display: {
											mobileMenu: 'none',
										},
									},
								}}
							/>
							<Box
								component='nav'
								sx={{
									display: {
										// Will still appear on robots crawl even when the mobile slideout hasn't appeared
										xs: 'none',
										mobileMenu: 'flex',
									},
									alignItems: 'center',
									flex: '0',
									ml: 'auto',
									pl: {
										xs: 2,
										md: 3,
									},
									pr: {
										xs: 2,
										md: 3,
									},
								}}
							>
								{items.map((item) => (
									<MenuItem
										item={item}
										key={item.label}
										promo={
											displayMobilePromotion &&
											'route' in item &&
											primaryRoute === item.route &&
											!!PromoComponent && (
												<PromoComponent onClose={closePromotion} />
											)
										}
									/>
								))}
								<AuthHeader />
							</Box>
						</>
					)}
				</Toolbar>
			</AppBar>
			{!!PromoComponent && (
				<Collapse
					in={showPromo && !displayMobilePromotion}
					timeout={700}
					sx={{
						// Underneath Appbar
						zIndex: theme.zIndex.appBar - 1,
						display: showPromo && !displayMobilePromotion ? 'block' : 'none',
						mt: {
							xs: '64px',
							mobileMenu: '104px',
						},
					}}
				>
					<PromoComponent banner onClose={closePromotion} />
				</Collapse>
			)}
		</>
	);
};

export default TopBar;
