import React from 'react';

import { Checkbox, type CheckboxProps, SvgIcon, useTheme } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const MobileCheckbox = ({ sx, ...props }: CheckboxProps) => {
	const theme = useTheme();

	return (
		<Checkbox
			sx={combineSx(
				{
					p: 0,
				},
				sx,
			)}
			icon={
				<SvgIcon fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
						fill={theme.palette.primary.main}
					/>
					<path d='M5 5H19V19H5V5Z' fill='white' />
				</SvgIcon>
			}
			checkedIcon={
				<SvgIcon fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3Z'
						fill={theme.palette.primary.dark}
					/>
					<path
						d='M9.99996 14L17.5 6.5L19 8L9.99996 17L4.99996 12L6.49996 10.5L9.99996 14Z'
						fill='white'
					/>
				</SvgIcon>
			}
			indeterminateIcon={
				<SvgIcon fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
						fill={theme.palette.primary.dark}
					/>
					<path d='M7 11H17V13H7V11Z' fill='white' />
				</SvgIcon>
			}
			{...props}
		/>
	);
};

export default MobileCheckbox;
