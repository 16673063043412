import React, { useState, useRef } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import {
	Button,
	ButtonGroup,
	MenuItem,
	type ButtonProps,
	type MenuItemProps,
	type SxProps,
	type Theme,
	Menu,
} from '@mui/material';
import { useDeepCompareEffect } from 'use-deep-compare';

interface OptionProps extends Omit<MenuItemProps, 'children' | 'onClick'> {
	title: string;
	value: string;
}

export interface SplitButtonProps extends Omit<ButtonProps, 'sx' | 'onClick'> {
	options: OptionProps[];
	onClick?: (
		ev: React.MouseEvent<HTMLButtonElement>,
		optionValue: string,
	) => void;
	sx?: SxProps<Theme>;
}

const SplitButton = ({
	options,
	variant,
	color,
	disabled,
	onClick,
	...props
}: SplitButtonProps) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const multi = options.length > 1;
	const [optionValue, setOptionValue] = useState<string>(options[0].value);

	useDeepCompareEffect(() => {
		setOptionValue(options[0].value);
	}, [setOptionValue, options]);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	return (
		<React.Fragment>
			<ButtonGroup ref={anchorRef}>
				<Button
					variant={variant}
					color={color}
					disabled={disabled}
					onClick={(ev) => onClick?.(ev, optionValue)}
					{...props}
				>
					{options.find((el) => el.value === optionValue)?.title}
				</Button>
				{multi ? (
					<Button
						size='small'
						variant={variant}
						color={color}
						disabled={disabled}
						onClick={handleToggle}
					>
						<ArrowDropDown />
					</Button>
				) : null}
			</ButtonGroup>
			{multi ? (
				<Menu
					open={open}
					onClose={() => setOpen(false)}
					anchorEl={anchorRef.current}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					{options
						.filter(({ value }) => value !== optionValue)
						.map(({ title: itemTitle, value, ...itemProps }) => (
							<MenuItem
								key={value}
								onClick={() => {
									setOptionValue(value);
									setOpen(false);
								}}
								{...itemProps}
							>
								{itemTitle}
							</MenuItem>
						))}
				</Menu>
			) : null}
		</React.Fragment>
	);
};

export default SplitButton;
