import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import { gql } from '@ivy/gql/types';
import { type Employer_SearchOrganizationQuery } from '@ivy/gql/types/graphql';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';
import { useDebounce } from '@ivy/lib/hooks';

import { type ReviewFormValues } from '../reviewForm';
interface EmployerValues {
	org: NonNullable<Employer_SearchOrganizationQuery['search'][0]['org']> | null;
}

interface EmployerProps {
	formik: FormikProps<ReviewFormValues>;
	onNext: (event: React.SyntheticEvent) => void;
}

const validation = yup.object({
	org: yup
		.object()
		.nullable()
		.required('Employer is required')
		.label('Employer'),
});

const initialValue = {
	org: null,
};

const Employer_SearchOrganizationQDoc = gql(/* GraphQL */ `
	query Employer_SearchOrganization($search: String!) {
		search: search_organization_by_prefix(
			args: { search: $search }
			order_by: [{ rank: desc }, { org: { name: asc } }, { org: { id: asc } }]
			limit: 50
		) {
			id
			org {
				id
				name
			}
		}
	}
`);

const Employer = ({ formik, onNext }: EmployerProps) => {
	const { values, setFieldValue, setFieldTouched, isSubmitting } = formik;
	const [searchOrgName, setSearchOrgName] = useState('');
	const debouncedOrgName = useDebounce(searchOrgName);
	const orgSearchResponse = useQuery(Employer_SearchOrganizationQDoc, {
		variables: {
			search: debouncedOrgName,
		},
		skip: !debouncedOrgName,
	});

	return (
		<Box>
			<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ width: '100%' }}>
				<Stack
					direction='column'
					sx={{ width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
				>
					<Autocomplete
						autoSelect
						autoHighlight
						blurOnSelect
						selectOnFocus
						fullWidth
						value={values.org}
						TextFieldProps={{
							required: true,
							fullWidth: true,
							placeholder: 'Search for employer name',
							...getErrorState(formik, 'org'),
						}}
						getOptionLabel={(option) =>
							typeof option === 'string' ? option : option.name
						}
						options={
							orgSearchResponse.data?.search
								.filter((el) => el.org)
								.map((el) => el.org!) || []
						}
						filterOptions={(ops) => ops}
						inputValue={searchOrgName}
						onInputChange={(_, newVal) => setSearchOrgName(newVal)}
						disabled={isSubmitting}
						onChange={(_, newVal) => setFieldValue('org', newVal)}
						onBlur={() => setFieldTouched('org')}
						noOptionsText={
							!searchOrgName ? 'Start typing for results' : 'No results found'
						}
						loading={orgSearchResponse.loading}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
				</Stack>
				<Box
					sx={{
						ml: { sm: 3, xs: 0 },
					}}
				>
					<NavButton variant='text' onClick={onNext}>
						Next ⏎
					</NavButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default Employer;
export { type EmployerValues, type EmployerProps, validation, initialValue };
