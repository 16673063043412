import type React from 'react';

import { type FormikProps } from 'formik-othebi';
import get from 'lodash/get';
import * as yup from 'yup';

export const getError = <T>(formik: FormikProps<T>, field: string): boolean => {
	return get(formik.touched, field) && !!get(formik.errors, field);
};

export const getHelperText = <T>(
	formik: FormikProps<T>,
	field: string,
): string | undefined => {
	return get(formik.touched, field) && get(formik.errors, field)
		? get(formik.errors, field)?.toString()
		: undefined;
};

export const getErrorState = <T>(formik: FormikProps<T>, field: string) => {
	return {
		error: getError(formik, field),
		helperText: getHelperText(formik, field),
	};
};

interface GetInputPropsOptions<OnChangeNoEvent extends boolean = false> {
	disabled?: boolean;
	useNewValueArgument?: boolean;
	onChangeNoEvent?: OnChangeNoEvent;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	parseInput?: (value: string) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prepareValue?: (value: any) => string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	defaultValue?: any;
}

const getInputPropsDefaultOptions = {};

interface InputProps<OnChangeNoEvent extends boolean = false> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
	onChange: OnChangeNoEvent extends true
		? // eslint-disable-next-line @typescript-eslint/no-explicit-any
		  (nv?: any) => void
		: (
				ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				nv?: any,
		  ) => void;
	// Not assigning types here since we have some components that don't use the FocusEvent
	onBlur: () => void;
	disabled?: boolean;
	error?: boolean;
	helperText?: string;
}

export const getBlurProps =
	<T>(
		formik: FormikProps<T>,
		field: string | string[],
		isTouched?: boolean,
		shouldValidate?: boolean,
	) =>
	() => {
		if (Array.isArray(field)) {
			formik.setTouched(
				{
					...formik.touched,
					...field.reduce(
						(tot, k) => ({
							...tot,
							[k]: isTouched ?? true,
						}),
						{},
					),
				},
				shouldValidate,
			);
		} else {
			formik.setFieldTouched(field, isTouched, shouldValidate);
		}
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInputProps = <T, OnChangeNoEvent extends boolean = false>(
	formik: FormikProps<T>,
	field: string,
	options: GetInputPropsOptions<OnChangeNoEvent> = getInputPropsDefaultOptions,
): InputProps<OnChangeNoEvent> => {
	const value = get(formik.values, field, options?.defaultValue);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (newVal: any) => {
		const parsedNewVal = options?.parseInput
			? options.parseInput(newVal)
			: newVal;
		formik.setFieldValue(field, parsedNewVal);
	};

	return {
		value: options?.prepareValue ? options.prepareValue(value) : value,
		onChange: options?.onChangeNoEvent
			? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			  (nv?: any) => handleChange(nv)
			: // eslint-disable-next-line @typescript-eslint/no-explicit-any
			  (ev, nv?: any) => {
					const newVal = options?.useNewValueArgument ? nv : ev.target.value;
					return handleChange(newVal);
			  },
		onBlur: getBlurProps(formik, field),
		...getErrorState(formik, field),
		disabled: options.disabled || formik.isSubmitting,
	};
};

export const numberFormatter = {
	parseInput: (value: string) => {
		const parsed = Number.parseInt(value);
		if (Number.isNaN(parsed)) {
			return null;
		}
		return parsed;
	},
	prepareValue: (value: number | null) => {
		// Strict compare to account for 0 being falsy
		return value === null ? '' : value.toString();
	},
};

export const mergeValidationSchemas = (...schemas) => {
	const [first, ...rest] = schemas;

	return rest.reduce(
		(mergedSchemas, schema) => mergedSchemas.concat(schema),
		first,
	);
};

export const registerYupMethods = () => {
	yup.addMethod(
		yup.mixed,
		'requiredFlag',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function (contextFlag: string, value: any, errorMessage?: string) {
			return this.when(`$${contextFlag}`, {
				is: (vl) => vl === value,
				then: (schema) => schema.required(errorMessage),
				otherwise: (schema) => schema,
			});
		},
	);

	yup.addMethod(
		yup.array,
		'minFlag',
		function (
			minVal: number,
			contextFlag: string,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value: any,
			errorMessage?: string,
		) {
			return this.when(`$${contextFlag}`, {
				is: (vl) => vl === value,
				then: (schema) => schema.min(minVal, errorMessage),
				otherwise: (schema) => schema,
			});
		},
	);

	yup.addMethod(
		yup.string,
		'oneOfOrEmpty',
		function (arrayOfValues: string[], errorMessage?: string) {
			return this.oneOf([...arrayOfValues, ''], errorMessage);
		},
	);

	yup.addMethod(
		yup.number,
		'oneOfOrNull',
		function (arrayOfValues: number[], errorMessage?: string) {
			return this.oneOf([...arrayOfValues, null], errorMessage);
		},
	);
};
