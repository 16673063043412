import React from 'react';

import { SvgIcon } from '@mui/material';

const BriefcaseIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<path
				d='M13.166 4.50008V2.33341H8.83268V4.50008H13.166ZM2.33268 6.66675V18.5834H19.666V6.66675H2.33268ZM19.666 4.50008C20.8685 4.50008 21.8327 5.46425 21.8327 6.66675V18.5834C21.8327 19.7859 20.8685 20.7501 19.666 20.7501H2.33268C1.13018 20.7501 0.166016 19.7859 0.166016 18.5834L0.176849 6.66675C0.176849 5.46425 1.13018 4.50008 2.33268 4.50008H6.66602V2.33341C6.66602 1.13091 7.63018 0.166748 8.83268 0.166748H13.166C14.3685 0.166748 15.3327 1.13091 15.3327 2.33341V4.50008H19.666Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default BriefcaseIcon;
