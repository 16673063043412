import React from 'react';

import {
	Box,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	Divider,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import Dropdown from '@ivy/components/molecules/Dropdown';
import FieldLabel from '@ivy/components/molecules/FieldLabel';
import WageInput from '@ivy/components/molecules/WageInput';
import {
	PaymentRatePeriod,
	WorkerClassification,
} from '@ivy/constants/clinician';

import { type SalaryFormValues } from './SalaryReportForm';

const WORKER_CLASSIFICATION_OPTIONS = [
	{
		label: 'W-2',
		value: WorkerClassification.EMPLOYEE,
	},
	{
		label: '1099',
		value: WorkerClassification.CONTRACTOR,
	},
	{
		label: 'K-1',
		value: WorkerClassification.PARTNERSHIP,
	},
];

export const validation = yup.object({
	workerClassification: yup
		.string()
		.oneOf(Object.values(WorkerClassification))
		.label('Contract Type')
		.required(),
	base: yup.object({
		pay: yup
			.number()
			.integer('Must be a whole number')
			.min(1, 'Must be at least $1')
			.max(10000000, 'Must be less than $10,000,000')
			.typeError('Please enter a whole number')
			.nullable()
			.label('Base + RVU Pay')
			.required(),
		period: yup.string().oneOf(Object.values(PaymentRatePeriod)).required(),
	}),
	night: yup.object({
		included: yup.boolean().required(),
		pay: yup
			.number()
			.integer('Must be a whole number')
			.min(1, 'Must be at least $1')
			.max(999, 'Must be less than $1,000')
			.typeError('Please enter a whole number')
			.nullable()
			.label('Nightly Differential')
			.when(['included', 'unknown'], {
				is: (included, unknown) => included && !unknown,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
		period: yup.string().oneOf(Object.values(PaymentRatePeriod)).required(),
		unknown: yup.boolean().nullable(),
	}),
	weekend: yup.object({
		included: yup.boolean().required(),
		pay: yup
			.number()
			.integer('Must be a whole number')
			.min(1, 'Must be at least $1')
			.max(999, 'Must be less than $1,000')
			.typeError('Please enter a whole number')
			.nullable()
			.label('Weekend Differential')
			.when(['included', 'unknown'], {
				is: (included, unknown) => included && !unknown,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
		period: yup.string().oneOf(Object.values(PaymentRatePeriod)).required(),
		unknown: yup.boolean().nullable(),
	}),
	bonus: yup.object({
		included: yup.boolean().required(),
		value: yup
			.string()
			.label('Bonus')
			.when('included', {
				is: (included) => included,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
	}),
	shftsMonth: yup
		.number()
		.integer('Must be a whole number')
		.min(1, 'Must be at least 1')
		.max(31, 'Must be less than 32')
		.label('Shifts per month')
		.typeError('Please enter a whole number')
		.nullable()
		.when('$isAdmin', (isAdmin, schema) => {
			return !isAdmin ? schema.required() : schema;
		}),
	hrsShift: yup
		.number()
		.integer('Must be a whole number')
		.min(1, 'Must be at least 1')
		.max(24, 'Must be less than 25')
		.label('Hours per shift')
		.typeError('Please enter a whole number')
		.nullable()
		.when('$isAdmin', (isAdmin, schema) => {
			return !isAdmin ? schema.required() : schema;
		}),
});

export const initialValue = {
	workerClassification: '',
	base: {
		pay: null,
		period: PaymentRatePeriod.HOUR,
	},
	night: {
		included: false,
		pay: null,
		period: PaymentRatePeriod.HOUR,
		unknown: false,
	},
	weekend: {
		included: false,
		pay: null,
		period: PaymentRatePeriod.HOUR,
		unknown: false,
	},
	bonus: {
		included: false,
		value: '',
	},
	shftsMonth: null,
	hrsShift: null,
};

interface CompensationStepProps {
	formik: FormikProps<SalaryFormValues>;
}

const CompensationStep = ({ formik }: CompensationStepProps) => {
	return (
		<Box>
			<FieldLabel
				gutterBottom
				secondary='To protect your identity, your responses will be rounded to the nearest $5 per hour and $10,000 per year.'
			/>
			<Divider sx={{ mb: 2 }} />
			<Stack width='100%' spacing={2}>
				<Box>
					<Typography variant='body1' gutterBottom>
						Your contract type*
					</Typography>
					<Dropdown
						placeholder='Select contract type'
						fullWidth
						required
						disabled={formik.isSubmitting}
						options={WORKER_CLASSIFICATION_OPTIONS}
						onChange={(nv) => formik.setFieldValue('workerClassification', nv)}
						onBlur={() => formik.setFieldTouched('workerClassification')}
						value={formik.values.workerClassification}
						error={
							formik.touched.workerClassification &&
							!!formik.errors.workerClassification
						}
						helperText={
							formik.touched.workerClassification &&
							formik.errors.workerClassification
						}
					/>
					<Divider sx={{ mt: 2 }} />
				</Box>
				<Box>
					<Stack spacing={1}>
						<Box>
							<Typography variant='body1' gutterBottom>
								Your base + RVU pay*
							</Typography>
							<WageInput
								placeholder='0'
								fullWidth
								valueAmount={formik.values.base?.pay}
								onChangeAmount={(nv) => formik.setFieldValue('base.pay', nv)}
								valueUnit={formik.values.base?.period}
								onChangeUnit={(nv) => {
									formik.setFieldValue('base.period', nv);
								}}
								disabled={formik.isSubmitting}
								onBlur={() => formik.setFieldTouched('base.pay')}
								error={formik.touched.base?.pay && !!formik.errors.base?.pay}
								helperText={formik.touched.base?.pay && formik.errors.base?.pay}
							/>
						</Box>
						<Box>
							<Typography variant='body1' gutterBottom>
								Your shifts per month*
							</Typography>
							<TextField
								disabled={formik.isSubmitting}
								name='shftsMonth'
								type='number'
								placeholder='0'
								inputProps={{
									onWheel: (e) => e.currentTarget.blur(),
									min: 0,
									max: 31,
									step: 1,
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								// https://github.com/jaredpalmer/formik/issues/1869
								value={formik.values.shftsMonth ?? ''}
								onChange={(e) =>
									formik.setFieldValue(
										'shftsMonth',
										e.target.value === '' ? null : e.target.value,
									)
								}
								onBlur={formik.handleBlur}
								error={formik.touched.shftsMonth && !!formik.errors.shftsMonth}
								helperText={
									formik.touched.shftsMonth && formik.errors.shftsMonth
								}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											shift(s) per month
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Box>
							<Typography variant='body1' gutterBottom>
								Your average hours per shift*
							</Typography>
							<TextField
								disabled={formik.isSubmitting}
								name='hrsShift'
								type='number'
								placeholder='0'
								inputProps={{
									onWheel: (e) => e.currentTarget.blur(),
									min: 0,
									max: 24,
									step: 1,
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
								// https://github.com/jaredpalmer/formik/issues/1869
								value={formik.values.hrsShift ?? ''}
								onChange={(e) =>
									formik.setFieldValue(
										'hrsShift',
										e.target.value === '' ? null : e.target.value,
									)
								}
								onBlur={formik.handleBlur}
								error={formik.touched.hrsShift && !!formik.errors.hrsShift}
								helperText={formik.touched.hrsShift && formik.errors.hrsShift}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											hour(s) per shift
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</Stack>
					<Divider sx={{ mt: 2 }} />
				</Box>
				<Box>
					<Typography variant='body1' gutterBottom>
						Select any forms of additional compensation that you received.
					</Typography>
					{/* {TODO make reusable component for optional inputs} */}
					<Stack spacing={2}>
						<Box>
							<LabeledCheckbox
								label='Night differential'
								sx={{ p: 0, mr: 1, mb: 1 }}
								value={formik.values.night.included}
								disabled={formik.isSubmitting}
								onChange={(_, nv) => {
									formik.setFieldTouched('night.pay', false, false);
									formik.setFieldTouched('night.unknown', false, false);
									formik.setFieldValue('night.included', nv, false);
									formik.setFieldValue('night.pay', null, false);
									formik.setFieldValue('night.unknown', false);
								}}
							/>
							{formik.values.night?.included && (
								<Box pl={3} mb={2}>
									<WageInput
										placeholder='0'
										fullWidth
										valueAmount={formik.values.night?.pay}
										onChangeAmount={(nv) =>
											formik.setFieldValue('night.pay', nv)
										}
										valueUnit={formik.values.night?.period}
										onChangeUnit={(nv) =>
											formik.setFieldValue('night.period', nv)
										}
										disabled={
											formik.isSubmitting || formik.values.night.unknown
										}
										onBlur={() => formik.setFieldTouched('night.pay')}
										fixedUnit
										error={
											formik.touched.night?.pay && !!formik.errors.night?.pay
										}
										helperText={
											formik.touched.night?.pay && formik.errors.night?.pay
										}
									/>
									<LabeledCheckbox
										label='Not sure of the amount'
										sx={{ p: 0, mr: 1, mt: 1 }}
										value={formik.values.night.unknown}
										disabled={formik.isSubmitting}
										onChange={(_, nv) => {
											formik.setFieldTouched('night.pay', false, false);
											formik.setFieldTouched('night.period', false, false);
											formik.setFieldTouched('night.unknown', true, false);
											formik.setFieldValue('night.pay', null, false);
											formik.setFieldValue('night.unknown', nv);
										}}
									/>
								</Box>
							)}
						</Box>
						<Box>
							<LabeledCheckbox
								label='Weekend differential'
								sx={{ p: 0, mr: 1, mb: 1 }}
								value={formik.values.weekend.included}
								disabled={formik.isSubmitting}
								onChange={(_, nv) => {
									formik.setFieldTouched('weekend.pay', false, false);
									formik.setFieldTouched('weekend.unknown', false, false);
									formik.setFieldValue('weekend.included', nv, false);
									formik.setFieldValue('weekend.pay', null, false);
									formik.setFieldValue('weekend.unknown', false);
								}}
							/>
							{formik.values.weekend?.included && (
								<Box pl={3} mb={2}>
									<WageInput
										placeholder='0'
										fullWidth
										valueAmount={formik.values.weekend?.pay}
										onChangeAmount={(nv) =>
											formik.setFieldValue('weekend.pay', nv)
										}
										valueUnit={formik.values.weekend?.period}
										onChangeUnit={(nv) =>
											formik.setFieldValue('weekend.period', nv)
										}
										fixedUnit
										disabled={
											formik.isSubmitting || formik.values.weekend.unknown
										}
										onBlur={() => formik.setFieldTouched('weekend.pay')}
										error={
											formik.touched.weekend?.pay &&
											!!formik.errors.weekend?.pay
										}
										helperText={
											formik.touched.weekend?.pay && formik.errors.weekend?.pay
										}
									/>
									<LabeledCheckbox
										label='Not sure of the amount'
										sx={{ p: 0, mr: 1, mt: 1 }}
										value={formik.values.weekend.unknown}
										disabled={formik.isSubmitting}
										onChange={(_, nv) => {
											formik.setFieldTouched('weekend.pay', false, false);
											formik.setFieldTouched('weekend.period', false, false);
											formik.setFieldTouched('weekend.unknown', true, false);
											formik.setFieldValue('weekend.pay', null, false);
											formik.setFieldValue('weekend.unknown', nv);
										}}
									/>
								</Box>
							)}
						</Box>
						<Box>
							<LabeledCheckbox
								label='Bonus and/or profit sharing'
								sx={{ p: 0, mr: 1, mb: 1 }}
								value={formik.values.bonus.included}
								disabled={formik.isSubmitting}
								onChange={(_, nv) => {
									formik.setFieldTouched('bonus.pay', false, false);
									formik.setFieldTouched('bonus.unknown', false, false);
									formik.setFieldValue('bonus.included', nv, false);
									formik.setFieldValue('bonus.pay', null, false);
									formik.setFieldValue('bonus.unknown', false);
								}}
							/>
							{formik.values.bonus?.included && (
								<Box pl={3}>
									<TextField
										fullWidth
										name='bonus.value'
										placeholder='Enter amount'
										value={formik.values.bonus?.value}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										disabled={formik.isSubmitting}
										error={
											formik.touched.bonus?.value &&
											!!formik.errors.bonus?.value
										}
										helperText={
											formik.touched.bonus?.value && formik.errors.bonus?.value
										}
										inputProps={{
											maxLength: 150,
										}}
									/>
								</Box>
							)}
						</Box>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

CompensationStep.propTypes = {};

export default CompensationStep;
