import React, { useCallback } from 'react';

import {
	FirstPage,
	LastPage,
	NavigateNext,
	NavigateBefore,
} from '@mui/icons-material';
import { Typography, Stack, IconButton } from '@mui/material';

import { formatInteger } from '@ivy/lib/formatting/number';

interface StepPaginationProps {
	page: number;
	pageSize: number;
	count?: number;
	onChangePage: (pg: number) => void;
	disabled?: boolean;
	disableCounter?: boolean;
	disableFirstLast?: boolean;
}

const StepPagination = ({
	page,
	pageSize,
	count = 0,
	onChangePage,
	disabled,
	disableCounter = false,
	disableFirstLast = false,
}: StepPaginationProps) => {
	// page is zero-indexed, so same applies to maxPage
	const maxPage = !count ? 0 : Math.ceil(count / pageSize) - 1;
	const start = !count ? 0 : page * pageSize + 1;
	const end = !count ? 0 : page === maxPage ? count : start + pageSize - 1;

	const onClickFirst = useCallback(() => {
		onChangePage(0);
	}, [onChangePage]);

	const onClickBefore = useCallback(() => {
		onChangePage(page - 1);
	}, [page, onChangePage]);

	const onClickNext = useCallback(() => {
		onChangePage(page + 1);
	}, [page, onChangePage]);

	const onClickLast = useCallback(() => {
		onChangePage(maxPage);
	}, [maxPage, onChangePage]);

	return (
		<Stack direction='row' spacing={1} alignItems='center'>
			{!disableCounter && (
				<Typography
					variant='body2'
					fontWeight='bold'
					color='text.icon'
					sx={{
						WebkitTapHighlightColor: 'transparent',
						userSelect: 'none',
						WebkitUserSelect: 'none',
					}}
				>
					{start === end
						? start
						: `${formatInteger(start)}-${formatInteger(end)}`}{' '}
					of {formatInteger(count)}
				</Typography>
			)}
			{!disableFirstLast && (
				<IconButton disabled={!page || disabled} onClick={onClickFirst}>
					<FirstPage />
				</IconButton>
			)}
			<IconButton disabled={!page || disabled} onClick={onClickBefore}>
				<NavigateBefore />
			</IconButton>
			<IconButton disabled={page === maxPage || disabled} onClick={onClickNext}>
				<NavigateNext />
			</IconButton>
			{!disableFirstLast && (
				<IconButton
					disabled={page === maxPage || disabled}
					onClick={onClickLast}
				>
					<LastPage />
				</IconButton>
			)}
		</Stack>
	);
};

export default StepPagination;
