import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='16'
			height='19'
			viewBox='0 0 16 19'
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			{...props}
		>
			<g id='Mark'>
				<path
					id='Vector'
					d='M15.2443 8.65601C15.2443 14.0348 9.73507 17.3486 8.59018 17.9798C8.52675 18.0148 8.4555 18.0331 8.38307 18.0331C8.31063 18.0331 8.23938 18.0148 8.17596 17.9798C7.03021 17.3486 1.52274 14.0348 1.52274 8.65601C1.52274 4.36804 4.09552 1.36646 8.3835 1.36646C12.6715 1.36646 15.2443 4.36804 15.2443 8.65601Z'
					stroke='currentColor'
					strokeWidth='1.4'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M4.95316 8.22726C4.95316 9.13705 5.31457 10.0096 5.95789 10.6529C6.60121 11.2962 7.47374 11.6576 8.38354 11.6576C9.29333 11.6576 10.1659 11.2962 10.8092 10.6529C11.4525 10.0096 11.8139 9.13705 11.8139 8.22726C11.8139 7.31746 11.4525 6.44493 10.8092 5.80161C10.1659 5.15829 9.29333 4.79688 8.38354 4.79688C7.47374 4.79688 6.60121 5.15829 5.95789 5.80161C5.31457 6.44493 4.95316 7.31746 4.95316 8.22726V8.22726Z'
					stroke='currentColor'
					strokeWidth='1.4'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
