import React from 'react';

import {
	FormControl,
	FormLabel,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Typography,
} from '@mui/material';

const CheckboxSelect = ({
	label,
	row,
	options,
	value,
	onChange,
	checkboxLabelTypographyProps,
	error,
	helperText,
	disabled,
	labelColor,
	required,
	disabledKeys = [],
	...props
}) => {
	const handleChange = (ev) => {
		onChange &&
			onChange({
				...value,
				[ev.target.name]: ev.target.checked,
			});
	};

	return (
		<FormControl component='fieldset' required={required} {...props}>
			{!!label && (
				<FormLabel
					component='legend'
					required={required}
					sx={{
						mb: 1,
					}}
				>
					<Typography component='span' variant='body1' color={labelColor}>
						{label}
					</Typography>
				</FormLabel>
			)}
			<FormGroup row={row}>
				{options.map(({ label, value: key }, idx, self) => (
					<FormControlLabel
						name={key}
						control={
							// Cast null/undefined to false to avoid uncontrolled -> controlled errors
							<Checkbox
								checked={!!value[key]}
								onChange={handleChange}
								disabled={disabled || disabledKeys.includes(key)}
							/>
						}
						label={label}
						key={key}
						componentsProps={{
							typography: checkboxLabelTypographyProps,
						}}
						sx={{
							mb: idx !== self.length - 1 ? 1 : undefined,
						}}
					/>
				))}
			</FormGroup>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default CheckboxSelect;
