export enum EMRATypes {
	RESIDENCY = 'RESIDENCY',
	CLERKSHIP = 'CLERKSHIP',
	FELLOWSHIP = 'FELLOWSHIP',
}
export const EMRATYPES2TYPEVERBOSE: Readonly<{ [k in EMRATypes]: string }> =
	Object.freeze({
		[EMRATypes.RESIDENCY]: 'Residency',
		[EMRATypes.CLERKSHIP]: 'Clerkship',
		[EMRATypes.FELLOWSHIP]: 'Fellowship',
	});

export const EMRATYPESVERBOSE2TYPES: Readonly<{ [k: string]: EMRATypes }> =
	Object.freeze(
		Object.entries(EMRATYPES2TYPEVERBOSE).reduce(
			(tot, [k, v]) => ({
				...tot,
				[v]: k,
			}),
			{},
		),
	);

export const EMRA2SLUG: Readonly<{ [k in EMRATypes]: string }> = Object.freeze({
	[EMRATypes.RESIDENCY]: 'residency',
	[EMRATypes.CLERKSHIP]: 'clerkship',
	[EMRATypes.FELLOWSHIP]: 'fellowship',
});

export const EMRAPlural: Readonly<{ [k in EMRATypes]: string }> = Object.freeze(
	{
		[EMRATypes.RESIDENCY]: 'Residencies',
		[EMRATypes.CLERKSHIP]: 'Clerkships',
		[EMRATypes.FELLOWSHIP]: 'Fellowships',
	},
);

export const SLUG2EMRA: Readonly<{ [k: string]: EMRATypes }> = Object.freeze(
	Object.entries(EMRA2SLUG).reduce(
		(tot, [k, v]) => ({
			...tot,
			[v]: k,
		}),
		{},
	),
);

export enum TrainingSurveyStatus {
	ACCEPTED = 'ACCEPTED',
	REJECTED = 'REJECTED',
}
