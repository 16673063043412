export const getToday = () => {
	const td = new Date();
	td.setHours(0, 0, 0, 0);
	return td;
};

export const isDateBeforeToday = (dt: Date) => {
	const td = getToday();
	const compareDt = new Date(dt);
	compareDt.setHours(0, 0, 0, 0);
	return compareDt < td;
};
