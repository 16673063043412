import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Chip,
	Box,
	TextField,
	Typography,
	Grid,
	FormHelperText,
	IconButton,
	InputAdornment,
	Stack,
	InputLabel,
	FormControl,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { PRODUCT_NAME } from '@ivy/constants/brand';
import { ProfDegree } from '@ivy/constants/clinician';
import { RouteUri } from '@ivy/constants/routes';
import { isAuthenticatedVar } from '@ivy/gql/reactives';
import { gql } from '@ivy/gql/types';
import { getInputProps } from '@ivy/lib/forms/formikHelpers';
import { getAcqParams } from '@ivy/lib/hooks/useAcqParams';
import { storeAccessToken, storeRefreshToken } from '@ivy/lib/storage/token';
import { PASSWORD_SCHEMA } from '@ivy/lib/validation/password';

import { type SubmitFunction } from './onboardingForm';

interface ClinicianFormValues {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	profDegree: ProfDegree | string;
}

interface ClinicianSignupStepProps {
	formik: FormikProps<ClinicianFormValues>;
	currAccLoading: boolean;
}

const validation = yup.object({
	firstName: yup.string().max(50).required().label('First name'),
	lastName: yup.string().max(50).label('Last name').required(),
	profDegree: yup
		.string()
		.oneOf(Object.values(ProfDegree))
		.required()
		.label('Profession'),
	email: yup
		.string()
		.email('Must be a valid email address')
		.required()
		.label('Email'),
	password: PASSWORD_SCHEMA,
});

const profDegreeOptions = Object.values(ProfDegree).map((value) => ({
	label: value,
	value: value,
}));

const initialValue = {
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	profDegree: '',
};

const ClinicianSignupStep_RegisterClinicianMDoc = gql(/* GraphQL */ `
	mutation ClinicianSignupStep_RegisterClinician(
		$input: RegisterClinicianInput!
	) {
		signup: register_clinician(input: $input) {
			access: access_token
			refresh: refresh_token
		}
	}
`);

const submit: SubmitFunction = async (
	values,
	actions,
	client,
	handleSuccess,
	handleError,
) => {
	try {
		const { data } = await client.mutate({
			mutation: ClinicianSignupStep_RegisterClinicianMDoc,
			variables: {
				input: {
					email: values.email,
					password: values.password,
					first_name: values.firstName,
					last_name: values.lastName,
					prof_degree: values.profDegree,
					...getAcqParams(),
				},
			},
		});
		// Tokens must be set before changing isAuthenticatedVar for logout listener to work properly
		// Convention is refresh before access
		if (data) {
			storeRefreshToken(data.signup.refresh);
			storeAccessToken(data.signup.access);
			isAuthenticatedVar(true);
			handleSuccess('Check your inbox for a link to confirm your email.');
		}
	} catch (e) {
		handleError(e, {
			extra: {
				// Password will be redacted in Sentry's beforeSend
				values,
			},
		});
	}
};

const ClinicianSignupStep = ({
	formik,
	currAccLoading,
}: ClinicianSignupStepProps) => {
	const { values, touched, errors, setFieldValue } = formik;
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (ev: React.MouseEvent) => {
		ev.preventDefault();
	};

	const generateChips = () => {
		return profDegreeOptions.map((op) => {
			const selected = values.profDegree === op.value;
			return (
				<Chip
					key={op.value}
					label={op.label}
					color={selected ? 'primary' : undefined}
					variant={selected ? 'filled' : 'outlined'}
					onClick={() => {
						setFieldValue('profDegree', op.value);
					}}
					sx={{
						height: 'auto',
						borderRadius: '40px',
						span: {
							px: { sm: 3, xs: 2 },
							py: 2,
						},
						m: 1,
					}}
				/>
			);
		});
	};

	return (
		<Stack
			sx={{
				justifyContent: {
					sm: 'space-between',
					xs: 'normal',
				},
				alignItems: 'center',
				height: '100%',
			}}
		>
			<Typography
				variant='h3'
				component='h1'
				sx={{
					textAlign: 'center',
					maxWidth: '570px',
					mx: 'auto',
					mb: { sm: 10, xs: 5 },
				}}
			>
				Sign up to find your next EM job opportunity
			</Typography>
			<Grid container spacing={{ sm: 5, xs: 4 }}>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='Email'
						name='email'
						type='email'
						placeholder='Enter your email'
						{...getInputProps(formik, 'email', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='Password'
						name='password'
						placeholder='Enter 8 or more characters'
						type={showPassword ? 'text' : 'password'}
						{...getInputProps(formik, 'password', {
							disabled: currAccLoading,
						})}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge='end'
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='First Name'
						name='firstName'
						placeholder='Enter your first name'
						{...getInputProps(formik, 'firstName', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						required
						label='Last Name'
						name='lastName'
						placeholder='Enter your last name'
						{...getInputProps(formik, 'lastName', {
							disabled: currAccLoading,
						})}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl>
						<InputLabel
							variant='standard'
							error={touched.profDegree && !!errors.profDegree}
							required
						>
							Profession
						</InputLabel>
						<Box>{generateChips()}</Box>
						<FormHelperText error sx={{ mx: '14px' }}>
							{touched.profDegree && errors.profDegree}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Typography
						sx={{ typography: { sm: 'body1', xs: 'caption' } }}
						color='text.icon'
					>
						Your privacy is Ivy’s priority. Ivy only shares the information you
						want shared with the employers you choose. Ivy never shares your
						email or phone number without your permission. By clicking Sign Up,
						I agree to the {PRODUCT_NAME}{' '}
						<Typography
							component={RouteLink}
							to={RouteUri.TOS}
							variant='inherit'
							fontWeight='bold'
							color='primary.main'
							openInNewTab
						>
							Terms of Service
						</Typography>
						{' and '}
						<Typography
							component={RouteLink}
							to={RouteUri.PRIVACY_POLICY}
							variant='inherit'
							fontWeight='bold'
							color='primary.main'
							openInNewTab
						>
							Privacy Policy
						</Typography>
						.
					</Typography>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default ClinicianSignupStep;
export {
	type ClinicianFormValues,
	type ClinicianSignupStepProps,
	validation,
	initialValue,
	submit,
};
