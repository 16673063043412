import { useEffect, useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';

import { gql } from '@ivy/gql/types';
import { type AdAuctionInput } from '@ivy/gql/types/graphql';
import { useStringifiedMemo } from '@ivy/lib/hooks';
import { isCrawler } from '@ivy/lib/util/userAgent';

import { AdContext } from './AdProvider';

const UseBannerAd_CreateAdAuctionMDoc = gql(`
    mutation UseBannerAd_CreateAdAuction($input: CreateAdAuctionInput!) {
        createAdAuction(input: $input) {
            results {
                winners {
                    id
                    resolvedBidId
                    asset {
                        url
                    }
                }
            }
        }
    }
`);

export interface UseBannerAdOptions {
	/** Only run the auction when `refetch` is called */
	lazy?: boolean;
	/** Skip running the ad auction */
	skip?: boolean;
}

/**
 * Runs an ad auction
 */
const useBannerAd = (
	auctions: AdAuctionInput[],
	options?: UseBannerAdOptions,
) => {
	const { disabled } = useContext(AdContext);
	// Don't fetch any ads if they are disabled, the user agent is a crawler, or skip is set
	const restricted = disabled || isCrawler || options?.skip;

	// Memoize since array pointer may change across renders
	const memoAuctions = useStringifiedMemo(auctions);

	const [submit, { data, loading, error }] = useMutation(
		UseBannerAd_CreateAdAuctionMDoc,
		{
			variables: {
				input: {
					auctions: memoAuctions,
				},
			},
		},
	);

	useEffect(() => {
		if (!options?.lazy && !restricted) {
			submit();
		}
		// Fetch on init and refetch if auctions change
	}, [submit, memoAuctions, options?.lazy, restricted]);

	const refetch = useCallback(() => {
		if (!restricted) {
			submit();
		}
	}, [submit, restricted]);

	if (restricted) {
		// Note that if skip turned from false -> true -> false, then data would still be populated. Thus, we override
		// it and return undefined.
		return {
			data: undefined,
			loading: false,
			error: undefined,
			refetch,
		};
	}

	return {
		data: data?.createAdAuction.results,
		loading,
		error,
		refetch,
	};
};

export default useBannerAd;
