import React from 'react';

import { Replay } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import Screen from '@ivy/components/atoms/Screen';
import SupportLink from '@ivy/components/atoms/SupportLink';
import { reloadPage } from '@ivy/lib/util/route';

export interface ErrorScreenProps {
	errorMessage?: string;
	fullscreen?: boolean;
}

const ErrorScreen = ({
	errorMessage,
	fullscreen = false,
}: ErrorScreenProps) => {
	return (
		<Screen fullscreen={fullscreen}>
			<Stack spacing={2} alignItems='center'>
				<Typography align='center' variant='h3'>
					An error occurred
				</Typography>
				<Typography variant='subtitle1' align='center'>
					Please try again or <SupportLink>contact support</SupportLink> if the
					issue persists.
				</Typography>
				{!!errorMessage && (
					<Typography variant='body1' align='center'>
						<b>Details:</b> {errorMessage}
					</Typography>
				)}
				<Button
					variant='contained'
					size='large'
					color='primary'
					startIcon={<Replay />}
					onClick={() => reloadPage(true)}
				>
					Reload
				</Button>
			</Stack>
		</Screen>
	);
};

export default ErrorScreen;
