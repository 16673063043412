import React from 'react';

import {
	Box,
	Stack,
	type SxProps,
	Typography,
	type Theme,
	IconButton,
	Divider,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import {
	EmailIcon,
	FacebookIcon,
	InstagramIcon,
	LinkedInIcon,
	YoutubeIcon,
	TwitterIcon,
	GPSIcon,
	PhoneIcon,
} from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';
import { type WhitelabelTo } from '@ivy/lib/util/route';

export enum SocialMediaType {
	TWITTER = 'twitter',
	FACEBOOK = 'facebook',
	INSTAGRAM = 'instagram',
	LINKEDIN = 'linkedin',
	YOUTUBE = 'youtube',
}

interface FooterItemObject {
	header: string;
	links: {
		label: string;
		link: string;
		openInNewTab?: boolean;
	}[];
}

interface BaseFooterProps {
	logo: string;
	logo2: string;
	description: string;
	socials: {
		type: SocialMediaType;
		link: string;
	}[];
	sections: FooterItemObject[];
	termsAndConditions?: WhitelabelTo;
	privacyPolicy?: WhitelabelTo;
	sx?: SxProps<Theme>;
	email?: string;
	phone?: string;
	address?: string;
}

const BaseFooter = ({
	logo,
	logo2,
	description,
	socials,
	termsAndConditions,
	privacyPolicy,
	sections,
	email,
	phone,
	address,
	sx,
}: BaseFooterProps) => {
	const getSocialIcon = (type: SocialMediaType) => {
		switch (type) {
			case SocialMediaType.FACEBOOK:
				return <FacebookIcon />;
			case SocialMediaType.INSTAGRAM:
				return <InstagramIcon />;
			case SocialMediaType.LINKEDIN:
				return <LinkedInIcon />;
			case SocialMediaType.TWITTER:
				return <TwitterIcon />;
			case SocialMediaType.YOUTUBE:
				return <YoutubeIcon />;
			default:
				return null;
		}
	};

	return (
		<Box
			sx={combineSx(
				{
					position: 'relative',
					pt: { xs: 8, sm: 13 },
					pb: 3,
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					top: 0,
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: 'light1.main',
				}}
			/>
			<Box sx={{ color: 'secondary.dark', mb: { xs: 8, sm: 8 } }}>
				<Stack direction='row' justifyContent='space-between'>
					<Stack spacing={3} sx={{ maxWidth: '320px' }}>
						<Box>
							<Box component='img' src={logo} sx={{ height: '40px' }} />
							<Box component='img' src={logo2} sx={{ height: '40px', ml: 3 }} />
						</Box>
						<Typography
							sx={{
								fontWeight: '400',
								lineHeight: '30px',
							}}
						>
							{description}
						</Typography>
						<Stack spacing={1.5} direction='row'>
							{socials.map((el) => {
								return (
									<IconButton
										key={el.type}
										size='small'
										component={RouteLink}
										to={el.link}
										openInNewTab
										sx={{
											color: 'secondary.dark',
										}}
									>
										{getSocialIcon(el.type)}
									</IconButton>
								);
							})}
						</Stack>
					</Stack>
					{sections.map((el) => {
						return (
							<Box
								key={el.header}
								sx={{ display: { xs: 'none', md: 'block' }, maxWidth: '200px' }}
							>
								<Typography fontWeight='bold' sx={{ mb: 5 }}>
									{el.header}
								</Typography>
								<Stack spacing={2.25}>
									{el.links.map((sel) => {
										return (
											<Typography key={sel.label}>
												<RouteLink
													color='inherit'
													to={sel.link}
													underline='hover'
													openInNewTab={sel.openInNewTab}
												>
													{sel.label}
												</RouteLink>
											</Typography>
										);
									})}
								</Stack>
							</Box>
						);
					})}
					<Box sx={{ display: { xs: 'none', md: 'block' }, maxWidth: '200px' }}>
						<Typography fontWeight='bold' sx={{ mb: 5 }}>
							Contact us
						</Typography>
						<Stack spacing={2.25}>
							{email && (
								<Box sx={{ display: 'flex' }}>
									<EmailIcon sx={{ fill: 'none', mr: 0.5 }} />
									<Typography>
										<RouteLink
											underline='hover'
											color='inherit'
											to={`mailto:${email}`}
										>
											{email}
										</RouteLink>
									</Typography>
								</Box>
							)}
							{phone && (
								<Box sx={{ display: 'flex' }}>
									<PhoneIcon sx={{ fill: 'none', mr: 0.5 }} />
									<Typography>{phone}</Typography>
								</Box>
							)}
							{address && (
								<Box sx={{ display: 'flex' }}>
									<GPSIcon sx={{ fill: 'none', mr: 0.5 }} />
									<Typography>{address}</Typography>
								</Box>
							)}
						</Stack>
					</Box>
				</Stack>
			</Box>
			<Divider sx={{ mb: 3 }} />
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent='space-between'
				sx={{ color: 'secondary.dark' }}
			>
				<Typography sx={{ mb: { xs: 3, sm: 0 } }}>
					Copyright © {new Date().getFullYear()}
				</Typography>
				<Box
					sx={{
						fontSize: '16px',
						lineHeight: '30px',
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }}>
						All Rights Reserved
						<Box sx={{ mx: 0.5, display: { xs: 'none', sm: 'block' } }}>|</Box>
						<Stack direction='row'>
							{termsAndConditions && (
								<RouteLink
									to={termsAndConditions}
									underline='hover'
									color='inherit'
								>
									Terms and Conditions
								</RouteLink>
							)}
							{termsAndConditions && privacyPolicy ? (
								<Box sx={{ mx: 0.5 }}>|</Box>
							) : (
								''
							)}{' '}
							{privacyPolicy && (
								<RouteLink to={privacyPolicy} underline='hover' color='inherit'>
									Privacy Policy
								</RouteLink>
							)}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default BaseFooter;
