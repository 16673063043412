import React, { useCallback, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { Profession, PROFESSION2SLUG } from '@ivy/constants/clinician';
import {
	Accreditation,
	ACCREDITATION2SLUG,
	accreditationNames,
} from '@ivy/constants/facility';
import {
	SiteMapCategories,
	SitemapTabs,
	SitemapTitles,
} from '@ivy/constants/sitemap';
import { buildInternalLink } from '@ivy/lib/util/route';

const SitemapShow_GmapsStateQDoc = gql(/* GraphQL */ `
	query SitemapShow_Gmaps(
		$limit: Int!
		$offset: Int!
		$filters: gmaps_link_bool_exp!
	) {
		gmaps_link(
			where: $filters
			limit: $limit
			offset: $offset
			order_by: { label: asc }
		) {
			id
			slug
			label
		}
		agg: gmaps_link_aggregate(where: $filters) {
			aggregate {
				count
			}
		}
	}
`);

const DEFAULT_PAGE_SIZE = 300;

interface LinkPaginationProps {
	page: number;
	pageSize: number;
	count: number;
	category: string;
	onChangePage: (pg: number) => void;
}

const LinkPagination = ({
	page,
	pageSize,
	count,
	category,
	onChangePage,
}: LinkPaginationProps) => {
	const totalPages = Math.ceil(count / pageSize);

	return (
		<Box>
			<Typography
				variant='h4'
				component='h1'
				sx={{
					pt: 8,
					mb: 3,
				}}
			>
				More Facilities
			</Typography>
			<Grid container spacing={5}>
				{[...new Array(totalPages).keys()].map((el) => {
					const pageNumber = el + 1;
					return (
						<Grid key={pageNumber} item sx={{ width: '64px' }}>
							<Typography
								variant='body1'
								component={RouteLink}
								to={buildInternalLink(RouteLink.routes.SITEMAP_PAGE, {
									category: category,
									page: `${pageNumber}`,
								})}
								color='inherit'
								disabled={page === pageNumber}
								onClick={() => onChangePage(pageNumber)}
							>
								{pageNumber}
							</Typography>
						</Grid>
					);
				})}
				{[...new Array(pageSize - (totalPages % pageSize)).keys()].map(
					(el, idx) => (
						<Grid
							item
							key={idx}
							sx={{
								padding: '0px !important',
								width: '64px',
							}}
						/>
					),
				)}
			</Grid>
		</Box>
	);
};

const SitemapList = () => {
	const pageSize = DEFAULT_PAGE_SIZE;
	const navigate = useNavigate();
	const { category, page: urlPage } = useParams();
	const [page, setPage] = useState(() =>
		urlPage ? Number.parseInt(urlPage) : 1,
	);

	const { data, loading, error, fetchMore } = useQuery(
		SitemapShow_GmapsStateQDoc,
		{
			variables: {
				filters:
					category === SiteMapCategories.STATE
						? {
								// Find places that have administrative_area_level_1 as one of the types
								types: {
									type: {
										_eq: 'administrative_area_level_1',
									},
								},
								// Has at least one active facility in the state
								facilities_in_state: {
									contracts: {
										active: {
											_eq: true,
										},
									},
								},
						  }
						: category === SiteMapCategories.CITY
						? {
								// Find places where administrative_area_level_1 is not one of the types
								_not: {
									types: {
										type: {
											_eq: 'administrative_area_level_1',
										},
									},
								},
								// Has at least one active facility in the city
								facilities_in_city: {
									contracts: {
										active: {
											_eq: true,
										},
									},
								},
						  }
						: {},
				limit: pageSize,
				offset: (page - 1) * pageSize,
			},
			skip:
				!category ||
				!(
					[SiteMapCategories.STATE, SiteMapCategories.CITY] as string[]
				).includes(category),
		},
	);

	const handlePageChange = useCallback(
		async (newPage: number) => {
			await fetchMore({
				variables: {
					offset: (newPage - 1) * pageSize,
				},
			});
			window.scrollTo(0, 0);
			setPage(newPage);
			navigate({
				pathname: RouteLink.routes.SITEMAP_PAGE.replace(
					':category',
					category!,
				).replace(':page', `${newPage!}`),
			});
		},
		[fetchMore, pageSize, setPage, category, navigate],
	);

	const handleChangeTab = () => {
		setPage(1);
	};

	const renderTitle = () => {
		return category ? SitemapTitles[category] ?? '' : '';
	};

	return (
		<DataLoader
			variant='logo'
			data={data}
			loading={loading}
			error={error}
			skipCheckData
		>
			{() => (
				<Stack
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						flexDirection: 'column',
						mb: 8,
					}}
					spacing={5}
				>
					<Box
						sx={{
							mt: { md: 8, xs: 5 },
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						}}
					>
						<Tabs value={category} onChange={handleChangeTab}>
							{SitemapTabs.map((tabItem) => (
								<Tab
									key={tabItem.label}
									label={tabItem.label}
									value={tabItem.category}
									component={RouteLink}
									to={RouteLink.routes.SITEMAP_INDEX.replace(
										':category',
										tabItem.category,
									)}
								/>
							))}
						</Tabs>
					</Box>
					<Box>
						<Typography
							variant='h4'
							component='h1'
							sx={{
								pt: 8,
								mb: 3,
							}}
						>
							{renderTitle()}
						</Typography>
						{!!category &&
							(
								[SiteMapCategories.STATE, SiteMapCategories.CITY] as string[]
							).includes(category) && (
								<>
									<Grid container spacing={3}>
										{data?.gmaps_link?.map(({ label, slug }) => {
											return (
												<Grid key={slug} item xs={12} sm={6} md={4}>
													<Typography
														key={`search-link-${label}`}
														variant='body1'
														component={RouteLink}
														to={buildInternalLink(
															RouteLink.routes.SEARCH_INDEX,
															{
																profession:
																	PROFESSION2SLUG[Profession.PHYSICIAN],
																slug: slug,
															},
														)}
														color='inherit'
													>
														Search for facilities in {label}
													</Typography>
												</Grid>
											);
										})}
										{[
											...new Array(
												pageSize - ((data?.gmaps_link?.length ?? 0) % pageSize),
											).keys(),
										].map((el, idx) => (
											<Grid
												item
												key={idx}
												xs={12}
												sm={6}
												md={4}
												sx={{
													padding: '0px !important',
												}}
											/>
										))}
									</Grid>
									<Box display='flex'>
										{!!category && data?.agg.aggregate?.count > pageSize && (
											<LinkPagination
												count={data?.agg.aggregate?.count}
												pageSize={pageSize}
												page={page}
												category={category!}
												onChangePage={handlePageChange}
											/>
										)}
									</Box>
								</>
							)}
						{category === SiteMapCategories.OTHER && (
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6} md={4}>
									<Typography
										variant='body1'
										component={RouteLink}
										to={buildInternalLink(
											RouteLink.routes.ACCREDITATION_SEARCH_INDEX,
											{
												accreditation: 'all',
												profession: PROFESSION2SLUG[Profession.PHYSICIAN],
												slug: 'search',
											},
										)}
										color='inherit'
									>
										ACEP Accreditation Programs Map
									</Typography>
								</Grid>
								{Object.values(Accreditation).map((el) => (
									<Grid key={el} item xs={12} sm={6} md={4}>
										<Typography
											variant='body1'
											component={RouteLink}
											to={buildInternalLink(
												RouteLink.routes.ACCREDITATION_SEARCH_INDEX,
												{
													accreditation: ACCREDITATION2SLUG[el],
													profession: PROFESSION2SLUG[Profession.PHYSICIAN],
													slug: 'search',
												},
											)}
											color='inherit'
										>
											ACEP {accreditationNames[el]} Map
										</Typography>
									</Grid>
								))}
							</Grid>
						)}
					</Box>
				</Stack>
			)}
		</DataLoader>
	);
};

export default SitemapList;
