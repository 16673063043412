import React from 'react';

import { Box, type SxProps, Typography } from '@mui/material';
import { type Theme } from '@mui/material/styles';

import { NoIcon, YesIcon } from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';

interface OptionProps {
	selected: boolean;
	icon: React.ReactNode;
	label: string;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	sx?: SxProps<Theme>;
}

const Option = ({ label, selected, onClick, icon, sx }: OptionProps) => {
	return (
		<Box
			component='button'
			display='flex'
			flexDirection='column'
			alignItems='center'
			color={selected ? 'primary.main' : 'text.disabled'}
			onClick={onClick}
			sx={combineSx(
				{
					border: 'none',
					bgcolor: 'transparent',
					p: 0,
					cursor: 'pointer',
					WebkitTapHighlightColor: 'transparent',
				},
				sx,
			)}
		>
			{icon}
			<Typography variant='body1' mt={2}>
				{label}
			</Typography>
		</Box>
	);
};

interface YNOptionsProps {
	value: boolean;
	onChange: (
		ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		val: boolean | null,
	) => void;
}

const YNOptions = ({ value, onChange }: YNOptionsProps) => {
	const handleClick = (
		ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		optionValue: boolean,
	) => {
		if (value === optionValue) {
			onChange(ev, null);
			return;
		}
		onChange(ev, optionValue);
	};

	// Note use of strict equality to boolean values to account for null
	return (
		<Box display='flex'>
			<Option
				label='Yes'
				selected={value === true}
				onClick={(ev) => handleClick(ev, true)}
				icon={<YesIcon />}
			/>
			<Option
				label='No'
				selected={value === false}
				onClick={(ev) => handleClick(ev, false)}
				icon={<NoIcon />}
				sx={{
					ml: 3,
				}}
			/>
		</Box>
	);
};

export default YNOptions;
