import React, { useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Stack,
	Grid,
	Typography,
	type Theme,
	useMediaQuery,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { format as formatDate } from 'date-fns';
import { useSnackbar } from 'notistack';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { type ApplyToJobButtonProps } from '@ivy/components/organisms/ApplyToJobButton';
import EditSectionPopup from '@ivy/components/organisms/EditSectionPopup';
import EmployerInfo from '@ivy/components/organisms/EmployerInfo';
import ExplainerCta from '@ivy/components/organisms/ExplainerCta';
import { type Profession } from '@ivy/constants/clinician';
import { ContractChangeRequest } from '@ivy/constants/facility';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

import EmployerListPopover from '../EmployerListPopover';

import CorrectionToast from './CorrectionToast';
import FacilityDescription from './FacilityDescription';
import { forms as facilityForms } from './facilityEditForms';
import FacilityFeatures from './FacilityFeatures';
import FacilityGallery from './FacilityGallery';
import FacilityNearby from './FacilityNearby';

const FacilityProfile_FacilityQDoc = gql(/* GraphQL */ `
	query FacilityProfile_Facility($facilityId: uuid!, $isOrgUser: Boolean!) {
		facility: facility_by_pk(id: $facilityId) {
			id
			description
			contracts(
				where: { active: { _eq: true } }
				order_by: { created_at: asc }
			) {
				id
				...EmployerInfo_Contract
				...EmployerListPopover_Contract
				isOwner: is_contract_owner
				lastApplied: last_applied
				lastAppliedDate @client
			}
			contractChanges: contracts(
				where: {
					change_request: { _is_null: false }
					is_contract_owner: { _eq: true }
				}
				order_by: { change_requested_at: desc_nulls_last }
				limit: 1
			) @include(if: $isOrgUser) {
				id
				active
				changeRequest: change_request
			}
			postings(order_by: { created_at: desc }) {
				id
			}
			...FacilityNearby_Facility
			...FacilityFeatures_Facility
			...FacilityDescription_Facility
			...FacilityGallery_Facility
			...CorrectionToast_Facility
		}
	}
`);

export interface FacilityProfileProps {
	facilityId: string;
	onClickApply: ApplyToJobButtonProps['onClickApply'];
	// TODO: update from components
	profession: Profession;
}

const ROW_SPACING = {
	xs: 8,
	lg: 15,
};

const FacilityProfile = ({
	facilityId,
	onClickApply,
	profession,
}: FacilityProfileProps) => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(FacilityProfile_FacilityQDoc, {
		variables: {
			isOrgUser: !!currAcc?.isOrgUser,
			facilityId: facilityId,
		},
		// Reflect updates to the connection status (via job posting page) or job posting additions/deletions
		// Don't use cache-and-network then cache-first -> infinite loop problem
		fetchPolicy: 'network-only',
	});

	const [empAnchor, setEmpAnchor] = useState(null);
	const [editFormId, setEditFormId] = useState<string | null>(null);
	const { enqueueSnackbar } = useSnackbar();
	const client = useApolloClient();

	const handleFormError = useCallback(
		(err) => {
			console.error(err);
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
			captureException(err);
		},
		[enqueueSnackbar],
	);

	const handleFormSuccess = useCallback(() => {
		enqueueSnackbar('Your changes have been saved.', {
			variant: 'success',
		});
		setEditFormId(null);
	}, [enqueueSnackbar, setEditFormId]);

	const handleClickShowEmployers = useCallback(
		(ev) => {
			ev.stopPropagation();
			setEmpAnchor(ev.currentTarget);
		},
		[setEmpAnchor],
	);

	const handleCloseShowEmployers = useCallback(() => {
		setEmpAnchor(null);
	}, [setEmpAnchor]);

	const {
		title: formTitle = undefined,
		component: FormComponent = undefined,
		getFormikProps = undefined,
		onSubmit = undefined,
	} = editFormId != null ? facilityForms[editFormId] : {};

	const canEdit = data?.facility?.contracts.some(
		(contract) => contract.isOwner,
	);
	const contractChange = data?.facility?.contractChanges?.[0];

	const maxLastApplied = data?.facility?.contracts.reduce(
		(reducedVal, { lastAppliedDate }) => {
			if (!lastAppliedDate) {
				return reducedVal;
			}
			if (!reducedVal) {
				return lastAppliedDate;
			}
			return lastAppliedDate > reducedVal ? lastAppliedDate : reducedVal;
		},
		null,
	);

	const isLteMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	return (
		<DataLoader variant='circular' data={data} loading={loading} error={error}>
			{() => (
				<>
					{(canEdit || !!contractChange || !!maxLastApplied) && (
						<Box
							sx={{
								bgcolor: 'primary.translucent',
								p: 1,
								mt: 2,
								borderRadius: (theme) => `${theme.shape.borderRadius}px`,
							}}
						>
							<Typography variant='body2' color='text.icon'>
								<InfoOutlined
									fontSize='small'
									sx={{
										color: 'inherit',
										verticalAlign: 'middle',
										mr: 1,
									}}
								/>
								{maxLastApplied ? (
									`Applied on ${formatDate(maxLastApplied, 'MMM d, yyyy')}.`
								) : (
									<>
										{contractChange?.changeRequest ===
											ContractChangeRequest.ADDITION &&
											'This facility has recently been added to your account. It can take up to 24 hours for changes to be reflected across the platform.'}
										{contractChange?.changeRequest ===
											ContractChangeRequest.REMOVAL &&
											'This facility was recently removed from your group. It can take up to 24 hours for changes to be reflected across the platform.'}
										{!contractChange &&
											'This facility is affiliated with your group.'}
										<RouteLink
											to={RouteLink.routes.MANAGEMENT_FACILITY}
											state={{
												backNav: {
													target: 'facility',
												},
											}}
											sx={{
												fontWeight: 'bold',
												position: 'relative',
												bottom: '1px',
												p: 0,
												ml: 1,
											}}
										>
											Manage facilities
										</RouteLink>
									</>
								)}
							</Typography>
						</Box>
					)}
					<Box
						sx={{
							my: Object.entries(ROW_SPACING).reduce(
								(tot, [k, v]) => ({
									...tot,
									[k]: v / 2,
								}),
								{},
							),
						}}
					>
						{!!editFormId && canEdit && (
							<EditSectionPopup
								formikProps={getFormikProps(data!.facility!)}
								onSubmit={onSubmit({
									client,
									facility: data!.facility!,
									onError: handleFormError,
									onSuccess: handleFormSuccess,
								})}
								open={!!editFormId}
								formTitle={formTitle}
								onClose={() => setEditFormId(null)}
								renderForm={(formik) => (
									<FormComponent formik={formik} facility={data!.facility!} />
								)}
							/>
						)}
						<EmployerListPopover
							anchorEl={empAnchor}
							contracts={data!.facility!.contracts}
							open={!!empAnchor}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							onClickApply={onClickApply}
							onClose={handleCloseShowEmployers}
						/>
						<Box
							sx={(theme) => ({
								[theme.breakpoints.up('md')]: {
									display: 'flex',
									alignItems: 'center',
								},
							})}
						>
							{!isLteMd && <ExplainerCta sx={{ width: '50%' }} />}
						</Box>
						<Box
							sx={{
								display: 'flex',
								mt: ROW_SPACING,
							}}
						>
							<Stack
								sx={{
									maxWidth: {
										xs: '100%',
										md: 'none',
									},
								}}
								spacing={ROW_SPACING}
							>
								{isLteMd && (
									// We use JavaScript breakpoint here because using CSS will still
									// render a margin between this Stack and the stuff below even if display is none
									<ExplainerCta />
								)}
								<FacilityFeatures
									facility={data!.facility!}
									onClickShowEmployers={handleClickShowEmployers}
								/>
								{(canEdit || !!data!.facility!.description) && (
									<FacilityDescription
										facility={data!.facility!}
										onEditClick={
											canEdit ? () => setEditFormId('description') : undefined
										}
									/>
								)}
								<Box component='section'>
									<Grid
										container
										rowSpacing={6}
										columnSpacing={4}
										sx={{
											// Fix spacing
											// Nest inside another div so the Stack spacing margin doesn't interfere
											ml: -4,
											mt: -6,
											justifyContent: 'space-between',
										}}
									>
										{data!.facility!.contracts.map((contract) => (
											<Grid item xs={12} md={6} key={contract.id}>
												<EmployerInfo
													onClickApply={onClickApply}
													contract={contract}
												/>
											</Grid>
										))}
									</Grid>
								</Box>
								<FacilityGallery
									facility={data!.facility!}
									onEditClick={
										canEdit ? () => setEditFormId('gallery') : undefined
									}
								/>
								<FacilityNearby
									facility={data!.facility!}
									profession={profession}
								/>
							</Stack>
						</Box>
					</Box>
					<CorrectionToast facility={data!.facility!} />
				</>
			)}
		</DataLoader>
	);
};

export default FacilityProfile;
