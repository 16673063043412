import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
	useRef,
	Suspense,
} from 'react';

import { type ApolloError, NetworkStatus, useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import {
	Box,
	type SxProps,
	type Theme,
	Button,
	Skeleton,
	Grid,
	Chip,
	Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';

import EmptyListPlaceholder from '@ivy/components/atoms/EmptyListPlaceholder';
import { HourglassIcon } from '@ivy/components/icons';
import EmptyIcon from '@ivy/components/icons/EmptyIcon';
import ReviewCard, {
	type ReplyFormValues,
} from '@ivy/components/molecules/ReviewCard';
import StepPagination from '@ivy/components/molecules/StepPagination';
import ReportReviewPopup from '@ivy/components/organisms/ReportReviewPopup';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { type Review_Order_By, Order_By } from '@ivy/gql/types/graphql';
import {
	type ReviewList_SearchReviewQuery,
	type ReviewList_SearchReviewQueryVariables,
} from '@ivy/gql/types/graphql';
import { useNotifyError } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

// We're including inactive contracts in filters + connections
export const ReviewList_SearchReviewQDoc = gql(/* GraphQL */ `
	query ReviewList_SearchReview(
		$limit: Int!
		$offset: Int!
		$orderBy: [review_order_by!]
		$filters: review_bool_exp!
		$accountId: uuid
		$isClinician: Boolean!
	) {
		reviews: review(
			where: $filters
			limit: $limit
			offset: $offset
			order_by: $orderBy
		) {
			id
			orgId: org_id
			visible
			selfReview: self_review
			...ReviewCard_Review
		}
		agg: review_aggregate(where: $filters) {
			aggregate {
				count
			}
		}
	}
`);

const ReviewList_UpdateReviewMDoc = gql(/* GraphQL */ `
	mutation ReviewList_UpdateReview($reviewId: uuid!, $active: Boolean!) {
		update_review_by_pk(
			pk_columns: { id: $reviewId }
			_set: { active: $active }
		) {
			id
			active
		}
	}
`);

const ReviewList_CreateReviewVoteMDoc = gql(/* GraphQL */ `
	mutation ReviewList_CreateReviewVote($reviewId: uuid!, $value: Int) {
		insert_review_vote_one(
			object: { review_id: $reviewId, value: $value }
			on_conflict: {
				constraint: uq_review_vote_review_id_account_id
				update_columns: [value]
			}
		) {
			id
		}
	}
`);

const ReviewList_DeleteReviewVoteMDoc = gql(/* GraphQL */ `
	mutation ReviewList_DeleteReviewVote($reviewVoteId: uuid!) {
		delete_review_vote_by_pk(id: $reviewVoteId) {
			id
		}
	}
`);

const ReviewList_CreateReviewReplyMDoc = gql(/* GraphQL */ `
	mutation ReviewList_CreateReviewReply($input: review_reply_insert_input!) {
		insert_review_reply_one(object: $input) {
			id
		}
	}
`);

const ReviewList_DeleteReviewReplyMDoc = gql(/* GraphQL */ `
	mutation ReviewList_DeleteReviewReply($replyId: uuid!) {
		delete_review_reply_by_pk(id: $replyId) {
			id
		}
	}
`);

interface SkeletonProps {
	size?: number;
	sx?: SxProps<Theme>;
}

const CardSkeleton = ({ sx, size = 0 }: SkeletonProps) => (
	<Grid container spacing={3} sx={combineSx({ justifyContent: 'center' }, sx)}>
		{[...Array(size).keys()].map((el) => (
			<Grid item key={el} xs={12}>
				<Skeleton
					variant='rectangular'
					sx={(theme) => ({
						width: '100%',
						height: {
							md: '450px',
							xs: '570px',
							borderRadius: `${theme.shape.borderRadius}px`,
						},
					})}
				/>
			</Grid>
		))}
	</Grid>
);

const DEFAULT_PAGE_SIZE = 30;

const DEFAULT_ORDER_BY = [
	{
		// Pending reviews first, then approved reviews
		visible: Order_By.Asc,
	},
	{
		employment_date: Order_By.Desc,
	},
];

export interface ReviewListProps {
	onCompletedQuery?: (val?: ReviewList_SearchReviewQuery) => void;
	onErrorQuery?: (val?: ApolloError) => void;
	singleReviewId?: string;
	where?: ReviewList_SearchReviewQueryVariables['filters'];
	sx?: SxProps<Theme>;
	hideFacility?: boolean;
	refetchQueries?: string[];
}

interface ReportValues {
	reviewId?: string;
	reviewReplyId?: string;
}

const ReviewList = ({
	onCompletedQuery,
	onErrorQuery,
	singleReviewId,
	hideFacility,
	refetchQueries = [],
	where,
	sx,
}: ReviewListProps) => {
	const currAcc = useCurrentAccount();
	const [page, setPage] = useState(0);
	const [singleReview, setSingleReview] = useState<string | null>(
		singleReviewId || null,
	);
	const [showReport, setShowReport] = useState<ReportValues>({
		reviewId: undefined,
		reviewReplyId: undefined,
	});
	const { enqueueSnackbar } = useSnackbar();
	const pageSize = DEFAULT_PAGE_SIZE;
	const listRef = useRef<HTMLDivElement>(null);

	const [updateReview, { loading: loadingUpdateReview }] = useMutation(
		ReviewList_UpdateReviewMDoc,
		{
			refetchQueries: ['ReviewList_SearchReview', ...refetchQueries],
			awaitRefetchQueries: true,
		},
	);
	const [createReviewVote, { loading: loadingCreateVote }] = useMutation(
		ReviewList_CreateReviewVoteMDoc,
		{
			refetchQueries: ['ReviewList_SearchReview', ...refetchQueries],
			awaitRefetchQueries: true,
		},
	);
	const [deleteReviewVote, { loading: loadingDeleteVote }] = useMutation(
		ReviewList_DeleteReviewVoteMDoc,
		{
			refetchQueries: ['ReviewList_SearchReview', ...refetchQueries],
			awaitRefetchQueries: true,
		},
	);
	const [createReviewReply, { loading: loadingCreateReply }] = useMutation(
		ReviewList_CreateReviewReplyMDoc,
		{
			refetchQueries: ['ReviewList_SearchReview', ...refetchQueries],
			awaitRefetchQueries: true,
		},
	);
	const [deleteReviewReply, { loading: loadingDeleteReply }] = useMutation(
		ReviewList_DeleteReviewReplyMDoc,
		{
			refetchQueries: ['ReviewList_SearchReview', ...refetchQueries],
			awaitRefetchQueries: true,
		},
	);

	const orderBy: Review_Order_By[] = [];

	const {
		data,
		loading: loadingQuery,
		error,
		fetchMore,
		networkStatus,
	} = useQuery(ReviewList_SearchReviewQDoc, {
		variables: {
			filters: {
				_or: [
					// Approved review
					{
						visible: {
							_eq: true,
						},
					},
					// Pending self review
					{
						visible: {
							_eq: false,
						},
						self_review: {
							_eq: true,
						},
					},
				],
				_and: singleReview
					? [{ id: { _eq: singleReview } }, where || {}]
					: [where || {}],
			},
			orderBy: [
				...orderBy,
				// Use the default ordering as a tiebreaker
				...DEFAULT_ORDER_BY,
			],
			limit: pageSize,
			offset: page * pageSize,
			accountId: currAcc?.id,
			isClinician: !!currAcc?.isClinician,
		},
		notifyOnNetworkStatusChange: true,
	});
	useNotifyError(error);

	useEffect(() => {
		const onCompleted = (_data?: ReviewList_SearchReviewQuery) => {
			if (onCompletedQuery) onCompletedQuery(_data);
		};
		const onError = (_error?: ApolloError) => {
			if (onErrorQuery) onErrorQuery(_error);
		};
		if (onCompleted && !loadingQuery && !error) {
			onCompleted(data);
		} else if (onError && !loadingQuery && error) {
			onError(error);
		}
	}, [loadingQuery, data, error, onCompletedQuery, onErrorQuery]);

	const reviews = useMemo(() => {
		return data?.reviews.map((el) => el) || [];
	}, [data]);

	const handleDeleteCard = async (reviewId: string) => {
		try {
			await updateReview({
				variables: {
					reviewId: reviewId,
					active: false,
				},
			});
			enqueueSnackbar('Your review has been removed.', {
				variant: 'success',
			});
		} catch (e) {
			captureException(e, {
				extra: {
					reviewId: reviewId,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		}
	};

	const handleReportCard = async (reviewId: string, reviewReplyId?: string) => {
		setShowReport({ reviewId, reviewReplyId });
	};

	const handleCloseReport = useCallback(() => {
		setShowReport({ reviewId: undefined, reviewReplyId: undefined });
	}, [setShowReport]);

	const handleVote = async (reviewId: string, value: number) => {
		try {
			await createReviewVote({
				variables: {
					reviewId: reviewId,
					value: value,
				},
			});
		} catch (e) {
			captureException(e, {
				extra: {
					reviewId: reviewId,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		}
	};

	const handleDeleteVote = async (reviewVoteId: string) => {
		try {
			await deleteReviewVote({
				variables: {
					reviewVoteId: reviewVoteId,
				},
			});
		} catch (e) {
			captureException(e, {
				extra: {
					reviewVoteId: reviewVoteId,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		}
	};

	const handleSubmitReply = async (vals: ReplyFormValues, reviewId: string) => {
		const { isAnonymous, content } = vals;
		try {
			await createReviewReply({
				variables: {
					input: {
						content: content,
						is_anonymous: isAnonymous,
						review_id: reviewId,
					},
				},
			});
			enqueueSnackbar('Your reply has been submitted', {
				variant: 'success',
			});
		} catch (e) {
			captureException(e, {
				extra: {
					reviewId: reviewId,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		}
	};

	const handleDeleteReply = async (replyId: string) => {
		try {
			await deleteReviewReply({
				variables: {
					replyId: replyId,
				},
			});
			enqueueSnackbar('Your reply has been removed.', {
				variant: 'success',
			});
		} catch (e) {
			captureException(e, {
				extra: {
					replyId: replyId,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
		}
	};

	const handlePageChange = useCallback(
		async (newPage: number) => {
			await fetchMore({
				variables: {
					offset: newPage * pageSize,
				},
			});
			if (listRef?.current) {
				listRef.current?.scrollIntoView();
				window.scrollBy(0, -300);
			}
			setPage(newPage);
		},
		[fetchMore, pageSize, setPage],
	);

	const handleResetSingleReview = () => {
		setSingleReview(null);
	};

	const canReply = (orgId: string) => {
		if (currAcc) {
			if (currAcc.isClinician) {
				return true;
			} else if (currAcc.isOrgUser) {
				return currAcc.orgUser?.org.id === orgId;
			}
		} else {
			return false;
		}
	};

	return (
		<Box sx={sx}>
			<Suspense fallback={<CardSkeleton size={pageSize} />}>
				<>
					{[NetworkStatus.loading, NetworkStatus.setVariables].includes(
						networkStatus,
					) && <CardSkeleton size={pageSize} />}
					<Box
						sx={{
							flex: '1 1 auto',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<Grid container spacing={3} mb={5}>
							{reviews.some((el) => !el.visible) && (
								<Grid item key='pending-chip' xs={12}>
									<Chip
										sx={{
											px: 1,
											py: 0.5,
											bgcolor: 'rgba(38, 130, 197, 0.20)',
											height: 'auto',
											whiteSpace: 'normal',
											span: {
												whiteSpace: 'inherit',
											},
										}}
										icon={
											<HourglassIcon
												sx={{
													color: 'text.icon',
												}}
											/>
										}
										label='Review pending approval. Our team does its best to review submissions within 48 hours.'
									/>
								</Grid>
							)}
						</Grid>
						{!loadingQuery && !reviews.length ? (
							singleReview ? (
								<Box
									sx={{
										display: 'flex',
										width: '100%',
										height: '100%',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<EmptyIcon
										sx={{
											fontSize: '120px',
										}}
									/>
									<Typography variant='body2' color='text.icon'>
										This review has been deleted.
									</Typography>
									<Button
										variant='contained'
										sx={{ mt: 2 }}
										onClick={handleResetSingleReview}
									>
										View all reviews
									</Button>
								</Box>
							) : (
								<EmptyListPlaceholder type='reviews' />
							)
						) : (
							<Grid
								container
								spacing={3}
								sx={{
									justifyContent: 'center',
								}}
								ref={listRef}
							>
								{singleReview && (
									<Grid item xs={12}>
										<Box display='flex' justifyContent='flex-start'>
											<Button
												variant='contained'
												onClick={handleResetSingleReview}
											>
												View all reviews
											</Button>
										</Box>
									</Grid>
								)}
								{reviews.map((review) => (
									<Grid item key={review.id} xs={12}>
										<ReviewCard
											review={review}
											onDelete={
												review.selfReview
													? () => handleDeleteCard(review.id)
													: undefined
											}
											onReport={
												!review.selfReview
													? () => handleReportCard(review.id)
													: undefined
											}
											onVote={handleVote}
											onDeleteVote={handleDeleteVote}
											onSubmitReply={(vals) =>
												handleSubmitReply(vals, review.id)
											}
											onDeleteReply={handleDeleteReply}
											onReportReply={(replyId) =>
												handleReportCard(review.id, replyId)
											}
											hideFacility={hideFacility}
											defaultHideComments={!singleReview}
											restrictReply={!canReply(review.orgId)}
											sx={!review.visible ? { opacity: 0.5 } : undefined}
											disabled={
												loadingQuery ||
												loadingCreateReply ||
												loadingCreateVote ||
												loadingDeleteVote ||
												loadingDeleteReply ||
												loadingUpdateReview
											}
										/>
									</Grid>
								))}
								{!singleReview && (
									<Grid item xs={12}>
										<Box display='flex' justifyContent='flex-end'>
											<StepPagination
												count={data?.agg.aggregate?.count || 0}
												pageSize={pageSize}
												page={page}
												onChangePage={handlePageChange}
												disabled={loadingQuery}
											/>
										</Box>
									</Grid>
								)}
							</Grid>
						)}
					</Box>
				</>
			</Suspense>
			{!!showReport.reviewId && (
				<ReportReviewPopup
					open
					onClose={handleCloseReport}
					reviewId={showReport.reviewId}
					reviewReplyId={showReport.reviewReplyId}
				/>
			)}
		</Box>
	);
};

export default ReviewList;
