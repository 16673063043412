// We tried making this support generic types beyond strings but ran into an issue
// where if the browser autofills, the type will be a string regardless of the generic
// It can even be any arbitrary string that's isn't part of the enum if the generic is a string enum.
// Thus, the types need to be limited to string.
// https://github.com/mui/material-ui/issues/33399
// ev.target.value might be a string if browser does auto-complete
import React from 'react';

import {
	MenuItem,
	Checkbox,
	ListItemText,
	TextField,
	type StandardTextFieldProps,
	type SelectProps as MuiSelectProps,
	type SelectChangeEvent,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

interface DropdownOption {
	label: string;
	value: string;
}

type DropdownValue<Multiple extends boolean> = Multiple extends true
	? string[]
	: string;

export interface DropdownProps<Multiple extends boolean>
	extends Omit<StandardTextFieldProps, 'value' | 'onChange' | 'SelectProps'> {
	value?: DropdownValue<Multiple>;
	onChange: (val: DropdownValue<Multiple>) => void;
	multiple?: Multiple;
	renderValue?: MuiSelectProps['renderValue'];
	options?: DropdownOption[];
	selectAll?: boolean;
	placeholder?: string;
	SelectProps?: MuiSelectProps<DropdownValue<Multiple>>;
}

const Dropdown = <Multiple extends boolean = false>({
	multiple = false as Multiple,
	value,
	onChange,
	renderValue,
	options = [],
	selectAll = false,
	placeholder = '',
	SelectProps,
	sx,
	...props
}: DropdownProps<Multiple>) => {
	const getMultiRenderValue = (): string => {
		if (!multiple || !Array.isArray(value)) {
			return '';
		}
		if (value.length === options.length) {
			return 'All';
		} else if (value.length === 0) {
			return placeholder;
			// If return 'None', need to enable some option for the label not to come back down from the top to overlap
			// return 'None';
		} else if (value.length === 1) {
			return options.find((opt) => opt.value === value[0])?.label || '';
		} else {
			return 'Multiple';
		}
	};

	const handleChange = (ev: SelectChangeEvent<DropdownValue<Multiple>>) => {
		if (!onChange) {
			return;
		}
		// On autofill
		if (multiple && typeof ev.target.value === 'string') {
			onChange(ev.target.value.split(',') as DropdownValue<Multiple>);
		} else {
			onChange(ev.target.value as DropdownValue<Multiple>);
		}
	};

	const handleSelectAll = () => {
		if (!onChange || !multiple || !Array.isArray(value)) {
			return;
		}
		if (value.length === options.length) {
			// De-select all
			onChange([] as string[] as DropdownValue<Multiple>);
		} else {
			onChange(options.map((el) => el.value) as DropdownValue<Multiple>);
		}
	};

	return (
		<TextField
			select
			SelectProps={
				{
					displayEmpty: multiple || !!placeholder,
					multiple: multiple,
					value: value,
					onChange: handleChange,
					renderValue:
						renderValue ||
						(multiple
							? getMultiRenderValue
							: placeholder && !value
							? () => placeholder
							: undefined),
					...SelectProps,
				} as MuiSelectProps<unknown>
			}
			sx={combineSx(
				// Set opacity if rendering the placeholder
				!renderValue && !multiple && placeholder && !value
					? {
							'& .MuiInputBase-input': {
								opacity: 0.42,
							},
					  }
					: undefined,
				sx,
			)}
			{...props}
		>
			{multiple && selectAll && (
				<MenuItem
					onClick={handleSelectAll}
					selected={(value as (number | string)[]).length === options.length}
					sx={{
						borderBottomStyle: 'solid',
						borderBottomWidth: '1px',
						borderBottomColor: 'divider',
					}}
				>
					<Checkbox
						checked={(value as DropdownValue<true>).length === options.length}
						indeterminate={
							(value as DropdownValue<true>).length !== options.length &&
							!!(value as DropdownValue<true>).length
						}
					/>
					<ListItemText
						primary={
							(value as DropdownValue<true>).length === options.length
								? 'Deselect All'
								: 'Select All'
						}
						primaryTypographyProps={{
							fontWeight: 'bold',
						}}
					/>
				</MenuItem>
			)}
			{options.map((opt) => {
				if (multiple) {
					return (
						<MenuItem key={opt.value} value={opt.value}>
							<Checkbox
								checked={(value as DropdownValue<true>).indexOf(opt.value) > -1}
							/>
							<ListItemText primary={opt.label} />
						</MenuItem>
					);
				}
				return (
					<MenuItem value={opt.value} key={opt.value}>
						{opt.label}
					</MenuItem>
				);
			})}
		</TextField>
	);
};

export default Dropdown;
