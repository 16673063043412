import React from 'react';

import { SvgIcon } from '@mui/material';

const FeatureExcludedCheckIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<circle
				cx='8.5'
				cy='8.98633'
				r='8'
				fill='currentColor'
				fillOpacity='0.2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.64794 6.13253C5.74168 6.03889 5.86877 5.9863 6.00127 5.9863C6.13377 5.9863 6.26085 6.03889 6.3546 6.13253L8.50127 8.27919L10.6479 6.13253C10.6937 6.0834 10.7489 6.044 10.8103 6.01667C10.8716 5.98935 10.9378 5.97465 11.0049 5.97347C11.0721 5.97228 11.1387 5.98463 11.201 6.00978C11.2633 6.03493 11.3198 6.07235 11.3673 6.11983C11.4148 6.16731 11.4522 6.22387 11.4773 6.28613C11.5025 6.34839 11.5149 6.41507 11.5137 6.48221C11.5125 6.54935 11.4978 6.61555 11.4705 6.67689C11.4431 6.73822 11.4037 6.79342 11.3546 6.83919L9.20794 8.98586L11.3546 11.1325C11.4037 11.1783 11.4431 11.2335 11.4705 11.2949C11.4978 11.3562 11.5125 11.4224 11.5137 11.4895C11.5149 11.5567 11.5025 11.6233 11.4773 11.6856C11.4522 11.7479 11.4148 11.8044 11.3673 11.8519C11.3198 11.8994 11.2633 11.9368 11.201 11.9619C11.1387 11.9871 11.0721 11.9995 11.0049 11.9983C10.9378 11.9971 10.8716 11.9824 10.8103 11.9551C10.7489 11.9277 10.6937 11.8883 10.6479 11.8392L8.50127 9.69253L6.3546 11.8392C6.25982 11.9275 6.13445 11.9756 6.00492 11.9733C5.87538 11.971 5.7518 11.9185 5.66018 11.8269C5.56858 11.7353 5.5161 11.6117 5.51382 11.4822C5.51153 11.3527 5.55962 11.2273 5.64794 11.1325L7.7946 8.98586L5.64794 6.83919C5.5543 6.74545 5.50171 6.61836 5.50171 6.48586C5.50171 6.35336 5.5543 6.22628 5.64794 6.13253Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FeatureExcludedCheckIcon;
