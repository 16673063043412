import React from 'react';

import {
	SvgIcon,
	Box,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const TESTIMONIAL_IMAGE =
	'https://assets.ivyclinicians.io/images/empty-organization-profile.png';

const Blob = ({ ...props }) => {
	return (
		<SvgIcon
			width='1130'
			height='1393'
			viewBox='0 0 1130 1393'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M896.563 1210.71C773.797 1324.64 620.168 1409.49 453.843 1389.81C286.941 1370.07 147.866 1257.36 63.2506 1112.15C-18.681 971.545 -7.72789 809.765 22.6848 649.898C64.0439 432.492 67.1197 170.503 262.25 66.097C478.724 -49.7293 766.71 -4.94385 957.295 149.828C1131.37 291.195 1137.96 542.96 1125.14 766.843C1115.13 941.649 1024.91 1091.61 896.563 1210.71Z'
				fill='#1B8374'
			/>
		</SvgIcon>
	);
};

interface GraphicProps {
	sx?: SxProps<Theme>;
}

const OrgUserGraphic = ({ sx }: GraphicProps) => {
	return (
		<Stack
			direction='row'
			sx={combineSx(
				{
					margin: 'auto',
					height: '100%',
					width: '100%',
				},
				sx,
			)}
		>
			<Box
				sx={{
					display: 'flex',
					position: 'relative',
					backgroundColor: 'black',
					alignItems: 'center',
					justifyCenter: 'center',
				}}
			>
				<Blob
					sx={{
						position: 'absolute',
						height: '200%',
						minHeight: '1500px',
						width: 'auto',
						left: '32px',
					}}
				/>
			</Box>
			<Box
				sx={{
					maxWidth: '590px',
					display: {
						lg: 'flex',
						xs: 'none',
					},
					pt: 3,
					ml: 17,
					zIndex: 1,
					position: 'relative',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						position: 'relative',
						minHeight: '400px',
						maxHeight: '800px',
						height: '100%',
						left: { xs: 0, xl: '25px' },
					}}
				>
					<Box
						component='img'
						src={TESTIMONIAL_IMAGE}
						sx={{
							position: 'absolute',
							left: 0,
							height: '100%',
							aspectRatio: 16 / 9,
							width: 'auto',
							borderRadius: '18.521px',
							boxShadow: '0px 31.21px 20.65px 0px #0000000B',
						}}
					/>
				</Box>
				<Typography
					variant='h4'
					component='p'
					fontFamily='Reenie Beanie'
					textAlign='center'
					sx={{
						color: '#CDCFD2',
						mt: { xs: 2, md: 5, xl: 7 },
						ml: { xs: 4, xl: 15 },
					}}
				>
					Clinicians will see a rather empty view when they visit your profile.
					Fill it out to engage more clinicians.
				</Typography>
			</Box>
		</Stack>
	);
};

export default OrgUserGraphic;
