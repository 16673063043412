import React from 'react';

import { SvgIcon } from '@mui/material';

const BlowhornIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='46'
			height='37'
			viewBox='0 0 46 37'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.2'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.2602 0.0175646C30.1542 0.102873 36.4535 0.340623 40.3971 4.74452C44.5771 9.41237 45.5126 16.1144 44.1664 22.2473C42.8074 28.4391 39.4213 34.8926 33.3797 36.7154C27.857 38.3817 23.6561 32.2821 18.339 30.0398C12.2751 27.4827 3.48703 29.1232 0.932377 23.0323C-1.76497 16.6013 2.57469 9.15753 7.52993 4.26881C11.8491 0.00764311 18.209 -0.0700203 24.2602 0.0175646Z'
				fill='#2682C5'
			/>
			<path
				d='M28.1847 2.96967C27.431 3.2532 26.9946 4.01542 27.0631 4.78382L8.31884 19.0709C7.84309 18.6077 7.13671 18.4175 6.47966 18.6647C5.57567 19.0047 5.11087 20.0301 5.45094 20.9341L6.69167 24.2323C7.03174 25.1362 8.05713 25.601 8.96112 25.261C9.60743 25.0178 10.0249 24.427 10.0895 23.7776L15.045 23.6586L15.042 24.9539C15.0444 24.9604 15.046 24.9646 15.0485 24.9711C15.1031 26.4065 16.3223 27.5362 17.7577 27.4815L20.3331 27.3951C21.7685 27.3404 22.8982 26.1213 22.8435 24.6859L22.7972 23.468L33.997 23.2157C34.4509 23.8489 35.2846 24.1431 36.0426 23.858C36.9466 23.5179 37.4114 22.4925 37.0713 21.5885L30.4541 3.99839C30.114 3.0944 29.0887 2.62961 28.1847 2.96967ZM28.5982 4.06906C28.9074 3.95274 29.2384 4.10276 29.3547 4.41196L35.9719 22.0021C36.0883 22.3113 35.9382 22.6423 35.629 22.7586C35.3241 22.8733 35.0015 22.7324 34.879 22.4329C34.8766 22.4264 34.875 22.4221 34.8726 22.4157L28.2553 4.82554C28.2489 4.80836 28.2424 4.79118 28.236 4.77401C28.1543 4.47873 28.3062 4.17892 28.5982 4.06906ZM27.4338 5.97777L33.4824 22.0563L9.8412 22.5964L8.90419 20.1056L27.4338 5.97777ZM6.89323 19.7641C7.18526 19.6542 7.49713 19.7795 7.63033 20.0554C7.63894 20.0718 7.64325 20.0898 7.64971 20.107L8.89044 23.4051C8.89691 23.4223 8.90337 23.4395 8.90983 23.4566C8.9915 23.7519 8.83956 24.0517 8.54754 24.1616C8.23834 24.2779 7.90738 24.1279 7.79106 23.8187L6.55033 20.5205C6.43401 20.2113 6.58403 19.8804 6.89323 19.7641ZM16.2113 23.6316L21.6136 23.5014L21.66 24.7193C21.692 25.5211 21.085 26.1881 20.2825 26.218L17.7135 26.3216C16.9203 26.3504 16.2723 25.7289 16.232 24.9376C16.2317 24.9304 16.2258 24.9277 16.2256 24.9205L16.2113 23.6316Z'
				fill='#005694'
			/>
		</SvgIcon>
	);
};

export default BlowhornIcon;
