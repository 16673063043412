import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeleteOutline } from '@mui/icons-material';
import { Box, FormHelperText, Stack, Grid } from '@mui/material';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { gql } from '@ivy/gql/types';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

interface APPCertObj {
	id?: string;
	name: string;
}

interface APPCertOption {
	name: string;
	degrees: string[];
}

interface APPCertsValues {
	appCerts: APPCertObj[];
}

interface CertFormProps {
	choices?: APPCertOption[];
	formik: FormikWithNamespace<APPCertObj>;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

const CertForm = withFormikNamespace<CertFormProps, APPCertObj[], APPCertObj>(
	({ formik, arrayHelpers, choices = [], idx, hideRemove }) => {
		const { values, setFieldTouched, setFieldValue, isSubmitting } = formik;

		return (
			<Stack
				direction={{ sm: 'row', xs: 'column' }}
				alignItems='flex-end'
				flex={1}
			>
				<Autocomplete
					freeSolo
					blurOnSelect
					autoHighlight
					selectOnFocus
					TextFieldProps={{
						placeholder: 'Search for course or certification',
						...getErrorState(formik, 'name'),
					}}
					options={choices}
					getOptionLabel={(option: APPCertOption | string) =>
						typeof option === 'string' ? option : option.name
					}
					inputValue={values.name}
					onInputChange={(_, newVal) => {
						// Formik is validating on old fields, so it thinks name is '' (also cast to undefined, but that's
						// a tangent) when it's initially added to the array
						setFieldValue('name', newVal);
					}}
					onBlur={() => setFieldTouched('name')}
					fullWidth
					sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
				/>
				<Box
					display={hideRemove ? 'none' : 'block'}
					sx={{
						width: { sm: 'auto', xs: '100%' },
						ml: { sm: 2, xs: 0 },
						mt: { sm: 0, xs: 1 },
					}}
				>
					<ControlButton
						startIcon={<DeleteOutline />}
						onClick={() => arrayHelpers.remove(idx)}
						disabled={isSubmitting}
					>
						Remove
					</ControlButton>
				</Box>
			</Stack>
		);
	},
);

interface APPCertsProps
	extends Omit<
		SubFormStepProps<ProfileCompleteFormValues, ExtraParams>,
		'formik'
	> {
	formik: FormikWithNamespace<APPCertObj[]>;
}

const APPCerts_GetAPPCertificationsQDoc = gql(/* GraphQL */ `
	query APPCerts_GetAPPCertifications {
		appCerts: app_certs {
			name
			degrees
		}
	}
`);

const MAX_NO_APP_CERTS = 10;

const validation = yup.object({
	appCerts: yup
		.array()
		.of(
			yup
				.object({
					id: yup.string().when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					}),
					name: yup
						.string()
						.max(200)
						.when('$strict', {
							is: (val: boolean) => val,
							then: (schema) => schema.required(),
							otherwise: (schema) => schema.nullable(),
						})
						.label('Certification'),
				})
				.required(),
		)
		.max(MAX_NO_APP_CERTS, `At most ${MAX_NO_APP_CERTS} certifications`)
		.test('no-dups', 'No duplicates allowed', (value) => {
			// Yup is dumb and transforms empty strings '' that are required to undefined, so filter those out
			const filtered = value?.filter((el) => !!el.name) || [];
			return (
				new Set(filtered.map((el) => el?.name?.toLowerCase())).size ===
				filtered.length
			);
		})
		.required(),
});

const initialValue = {
	appCerts: [
		{
			id: uuidv4(),
			name: '',
			degrees: [],
		},
	],
};

const ClinicianAppCertsForm = withFormikNamespace<
	APPCertsProps,
	ProfileCompleteFormValues,
	APPCertObj[]
>(({ formik, extra, onNext }) => {
	const { clinician } = extra;
	const { profDegree } = clinician;
	const { values, errors } = formik;

	// Note we don't have a place to show errors.appCerts if it is a string
	const { data, loading, error } = useQuery(APPCerts_GetAPPCertificationsQDoc);
	const choices = useMemo(() => {
		if (!data) {
			return [];
		}
		const finalProfDegrees = [profDegree];
		if (!finalProfDegrees) {
			return data.appCerts;
		}
		return data?.appCerts.filter(
			(el) =>
				finalProfDegrees.some((degree) => el.degrees.includes(degree)) &&
				values.every((appCert) => appCert.name !== el.name),
		);
	}, [data, values, profDegree]);

	return (
		<Box>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				variant='circular'
			>
				{() => (
					<Stack>
						<FieldArray
							name={formik.getFullName()}
							render={(arrayHelpers) => (
								<Grid container direction='column' spacing={1}>
									{values.map((certObj, idx) => (
										<Grid container item key={certObj.id}>
											<CertForm
												choices={choices}
												formik={formik}
												arrayHelpers={arrayHelpers}
												namespace={idx}
												idx={idx}
												hideRemove={values.length < 2}
											/>
										</Grid>
									))}
									<Grid
										item
										display='flex'
										justifyContent={{ sm: 'flex-start', xs: 'center' }}
									>
										<ModifyButton
											onClick={() =>
												arrayHelpers.push({
													id: uuidv4(),
													name: '',
													degrees: [],
												})
											}
										>
											Add Certification
										</ModifyButton>
									</Grid>
									<Grid item>
										{typeof errors === 'string' && (
											<FormHelperText error>{errors}</FormHelperText>
										)}
									</Grid>
								</Grid>
							)}
						/>
					</Stack>
				)}
			</DataLoader>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Box>
	);
});

export default ClinicianAppCertsForm;
export { type APPCertsValues, type APPCertsProps, validation, initialValue };
