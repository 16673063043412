import React from 'react';

import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import SupportContact from '@ivy/components/molecules/SupportContact';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';

import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {
	const { token } = useParams();

	return (
		<SpotlightTemplate
			pageTitle='Reset Password'
			pageNoIndex
			pageDescription='Reset your password for Ivy Clinicians.'
		>
			<Stack height='100%' justifyContent='center' alignItems='center' p={1}>
				<Stack mt='auto' alignItems='center' width='100%'>
					<Typography
						variant='h4'
						component='h1'
						align='center'
						sx={{
							verticalAlign: 'middle',
						}}
					>
						Change your password
					</Typography>
					<ResetPasswordForm token={token} />
				</Stack>
				<SupportContact
					sx={{
						mt: 'auto',
						mb: 4,
						justifySelf: 'flex-end',
					}}
				/>
			</Stack>
		</SpotlightTemplate>
	);
};

export default ResetPassword;
