import React from 'react';

import {
	Box,
	Chip,
	Typography,
	Stack,
	type SxProps,
	SvgIcon,
	type Theme,
} from '@mui/material';
import { useElementSize } from 'usehooks-ts';

import { FormButton } from '@ivy/components/organisms/FormPopup';
import { combineSx } from '@ivy/lib/styling/sx';

const BlobHigh = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='303'
			height='264'
			viewBox='0 0 303 264'
			fill='none'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M122.925 0.447565C160.167 -3.20032 193.683 16.0375 223.205 36.7867C258.199 61.3824 303.632 87.06 302.993 127.008C302.357 166.835 254.209 188.999 220.361 214.672C190.8 237.092 161.498 261.205 122.925 263.583C81.8815 266.112 35.3287 257.516 11.1562 227.566C-11.6029 199.368 6.49834 161.408 12.696 127.008C17.4569 100.583 23.9838 75.5746 42.3362 54.503C63.8206 29.8354 88.2969 3.8394 122.925 0.447565Z'
				fill='#C4C4C4'
			/>
		</SvgIcon>
	);
};

const BlobMid = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='559'
			height='541'
			viewBox='0 0 559 541'
			fill='none'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M226.782 0.91717C295.489 -6.55822 357.323 32.8648 411.787 75.3849C476.347 125.787 560.166 178.407 558.988 260.271C557.813 341.884 468.986 387.305 406.541 439.914C352.004 485.86 297.945 535.272 226.782 540.145C151.062 545.329 65.1774 527.713 20.5818 466.339C-21.4061 408.553 11.9887 330.765 23.4227 260.271C32.206 206.119 44.2473 154.871 78.1054 111.69C117.742 61.14 162.898 7.86786 226.782 0.91717Z'
				fill='#C4C4C4'
			/>
		</SvgIcon>
	);
};

const BlobLow = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='559'
			height='541'
			viewBox='0 0 559 541'
			fill='none'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M226.782 0.91717C295.489 -6.55822 357.323 32.8648 411.787 75.3849C476.347 125.787 560.166 178.407 558.988 260.271C557.813 341.884 468.986 387.305 406.541 439.914C352.004 485.86 297.945 535.272 226.782 540.145C151.062 545.329 65.1774 527.713 20.5818 466.339C-21.4061 408.553 11.9887 330.765 23.4227 260.271C32.206 206.119 44.2473 154.871 78.1054 111.69C117.742 61.14 162.898 7.86786 226.782 0.91717Z'
				fill='#C4C4C4'
			/>
		</SvgIcon>
	);
};

const GraphicFx = () => {
	return (
		<>
			<BlobHigh
				sx={{
					position: 'absolute',
					height: '264px',
					width: '303px',
					top: '-90px',
					right: '-100px',
					opacity: 0.05,
				}}
			/>
			<BlobMid
				sx={{
					position: 'absolute',
					width: '559px',
					height: '541px',
					top: '230px',
					left: '-40px',
					opacity: 0.05,
				}}
			/>
			<BlobLow
				sx={{
					position: 'absolute',
					width: '559px',
					height: '541px',
					bottom: '-220px',
					right: '-160px',
					opacity: 0.05,
				}}
			/>
		</>
	);
};

interface GraphicContentObjProps {
	img: string;
	imgSx?: SxProps<Theme>;
	title: string;
	subtitle?: React.ReactNode;
	chip?: string;
	buttonText: string;
	footer?: React.ReactNode;
	buttonCrownText?: string;
}

interface SideGraphicProps {
	onClick?: (event: React.SyntheticEvent) => void;
	sx?: SxProps<Theme>;
	imgSx?: SxProps<Theme>;
	content: GraphicContentObjProps;
}

const SideGraphic = ({ onClick, sx, imgSx, content }: SideGraphicProps) => {
	const [ref, { height }] = useElementSize<HTMLDivElement>();

	return (
		<Box
			sx={combineSx(
				{
					width: '100%',
					position: 'relative',
					background: 'linear-gradient(163deg, #22A499 0%,  #2682C5 100%)',
					overflow: 'hidden',
				},
				sx,
			)}
		>
			<GraphicFx />
			<Stack
				sx={{
					height: '100%',
					// Don't allow any overflow from the chip to expand this past the allowed borders
					width: '100%',
					// Place above GraphicFx so that the blobs don't interfere with scroll events
					position: 'relative',
					zIndex: 1,
				}}
			>
				<Stack
					spacing={3}
					justifyContent='space-between'
					sx={{
						maxWidth: 'min(530px, 100%)',
						height: '100%',
						margin: 'auto',
						// May need scrolling on smaller screens to reach action button
						overflowY: 'auto',
						pt: {
							xs: 8,
							md: 10,
						},
						px: {
							xs: 3,
							md: 5,
						},
						pb: 3,
					}}
				>
					<Typography
						variant='h3'
						component='h1'
						color='primary.contrastText'
						sx={{
							flex: '0 0 auto',
						}}
					>
						{content.title}
					</Typography>
					{!!content.chip && (
						<Box
							sx={{
								flex: '0 0 auto',
							}}
						>
							<Chip
								sx={(theme) => ({
									backgroundColor: 'primary.translucent',
									py: 1,
									px: 2,
									...theme.typography.body2,
								})}
								label={content.chip}
							/>
						</Box>
					)}
					{!!content.subtitle && (
						<Typography
							component='div'
							variant='body1'
							color='primary.contrastText'
							sx={{
								flex: '0 0 auto',
							}}
						>
							{content.subtitle}
						</Typography>
					)}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative',
							flex: '1 1 0',
							overflow: 'hidden',
						}}
						ref={ref}
					>
						{height > 150 && (
							<Box
								component='img'
								src={content.img}
								sx={combineSx(
									{
										mx: 'auto',
										maxHeight: '100%',
										maxWidth: '100%',
									},
									imgSx,
									content.imgSx,
								)}
							/>
						)}
					</Box>
					{content.footer && (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								position: 'relative',
								flex: '0 0 auto',
								color: 'primary.contrastText',
							}}
						>
							{content.footer}
						</Box>
					)}
					{!!onClick && (
						<Stack flex='0 0 auto' spacing={2} alignItems='center' mt='auto'>
							{!!content.buttonCrownText && (
								<Typography variant='body2' color='#ffffff'>
									{content.buttonCrownText}
								</Typography>
							)}
							<FormButton
								variant='contained'
								size='large'
								onClick={onClick}
								color='whiteButton'
								sx={{ color: 'primary.main', fontWeight: 'bold' }}
							>
								{content.buttonText}
							</FormButton>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export default SideGraphic;
export { type SideGraphicProps, type GraphicContentObjProps };
