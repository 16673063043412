import React from 'react';

import { Box, Stack } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import FacilitySelect from '@ivy/components/molecules/FacilitySelect';
import { type FacilitySelect_FacilityFragment } from '@ivy/gql/types/graphql';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ReviewFormValues } from '../reviewForm';

interface FacilityValues {
	facility: FacilitySelect_FacilityFragment | null;
}

interface FacilityProps {
	formik: FormikProps<ReviewFormValues>;
	onNext: (event: React.SyntheticEvent) => void;
}

const validation = yup.object({
	facility: yup
		.object()
		.typeError('Facility is required')
		.required()
		.label('Facility'),
});

const initialValue = {
	facility: null,
};

const Facility = ({ formik, onNext }: FacilityProps) => {
	const { values, setFieldValue, setFieldTouched } = formik;
	return (
		<Box>
			<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ width: '100%' }}>
				<Stack
					direction={{ sm: 'row', xs: 'column' }}
					sx={{ width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
				>
					<FacilitySelect
						selectOnFocus
						clearOnBlur
						value={values.facility}
						onChange={(_, nv) => setFieldValue('facility', nv)}
						onBlur={() => setFieldTouched('facility')}
						noOptions={(hasInput) =>
							hasInput ? 'No results found' : 'Start typing for results'
						}
						InputProps={{
							required: true,
							placeholder: 'Search by facility, city, or state',
							...getErrorState(formik, 'facility'),
						}}
					/>
				</Stack>
				<Box
					sx={{
						ml: { sm: 3, xs: 0 },
					}}
				>
					<NavButton variant='text' onClick={onNext}>
						Next ⏎
					</NavButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default Facility;
export { type FacilityValues, type FacilityProps, validation, initialValue };
