import React from 'react';

import {
	Button,
	type ButtonProps,
	type SxProps,
	type Theme,
} from '@mui/material';

import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type ApplyToJobButton_ContractFragment } from '@ivy/gql/types/graphql';

const ApplyToJobButton_ContractFDoc = gql(/* GraphQL */ `
	fragment ApplyToJobButton_Contract on facility_contract {
		id
		active
		lastApplied: last_applied
		lastAppliedDate @client
		org {
			id
			active
		}
		...ApplyToJobPopup_Contract
	}
`);

export interface ApplyToJobButtonProps {
	contracts: FragmentType<typeof ApplyToJobButton_ContractFDoc>[];
	onClickApply: (
		contracts: readonly ApplyToJobButton_ContractFragment[],
	) => React.MouseEventHandler<HTMLButtonElement | undefined>;
	onClickShowEmployers?: React.MouseEventHandler<HTMLButtonElement | undefined>;
	fullWidth?: boolean;
	btnId?: string;
	variant?: 'short' | 'normal';
	sx?: SxProps<Theme>;
	size?: ButtonProps['size'];
}

const ApplyToJobButton = (props: ApplyToJobButtonProps) => {
	const {
		contracts: rawContracts,
		onClickApply,
		onClickShowEmployers,
		fullWidth,
		sx,
		btnId,
		variant = 'normal',
		size,
	} = props;
	const contracts = getFragmentData(
		ApplyToJobButton_ContractFDoc,
		rawContracts,
	);

	if (!contracts?.length) {
		return null;
	}

	const multiContract = contracts.length > 1;

	let btnProps: ButtonProps;
	if (contracts.some((el) => el.lastAppliedDate)) {
		if (multiContract) {
			btnProps = {
				children:
					variant === 'short' ? 'View Multiple' : 'View multiple EM practices',
				onClick: onClickShowEmployers,
				variant: 'outlined',
			};
		} else {
			btnProps = {
				children:
					variant === 'short' ? 'Applied' : 'Applied to this EM practice',
				disabled: true,
				variant: 'contained',
			};
		}
	} else {
		if (multiContract) {
			btnProps = {
				children: variant === 'short' ? 'Apply' : 'Apply to these EM practices',
				variant: 'contained',
				onClick: onClickApply(contracts.filter((el) => el.org.active)),
				id: btnId,
			};
		} else if (contracts.every((el) => !el.org.active)) {
			btnProps = {
				children: variant === 'short' ? 'Inactive' : 'Does not participate',
				variant: 'contained',
				id: btnId,
				disabled: true,
			};
		} else {
			btnProps = {
				children: variant === 'short' ? 'Apply' : 'Apply to this EM practice',
				variant: 'contained',
				onClick: onClickApply(contracts),
				id: btnId,
			};
		}
	}

	return (
		<Button
			fullWidth={fullWidth}
			color={'primary'}
			size={size}
			sx={sx}
			{...btnProps}
		/>
	);
};

export default ApplyToJobButton;
