import { useRef, useEffect } from 'react';

const usePrevious = <T>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]);

	// Return previous value (happens before update in useEffect above)
	return ref.current;
};

export default usePrevious;
