export enum SiteMapCategories {
	STATE = 'state',
	CITY = 'city',
	OTHER = 'other',
}

export const SitemapTabs = [
	{
		category: SiteMapCategories.STATE,
		label: 'By state',
	},
	{
		category: SiteMapCategories.CITY,
		label: 'By city',
	},
	{
		category: SiteMapCategories.OTHER,
		label: 'Other',
	},
];

export const SitemapTitles = {
	[SiteMapCategories.STATE]: 'Browse facilities by state',
	[SiteMapCategories.CITY]: 'Browse facilities by city',
	[SiteMapCategories.OTHER]: 'Other pages',
};
