import React from 'react';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { combineSx } from '@ivy/lib/styling/sx';

const BaseFormPopup = ({ PaperProps, actionSx, ...props }: PopupProps) => {
	return (
		<Popup
			PaperProps={{
				...PaperProps,
				sx: combineSx(
					{
						width: '100vw',
						height: '100%',
						maxWidth: 'none',
						maxHeight: {
							sm: 'calc(100vh - 48px)',
							xs: '100%',
						},
						m: { md: 3, xs: 0 },
					},
					PaperProps?.sx,
				),
			}}
			actionSx={combineSx(
				{
					p: 3,
					py: 1,
				},
				actionSx,
			)}
			{...props}
		/>
	);
};

export default BaseFormPopup;
