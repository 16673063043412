import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const ApplicantsIcon = ({ sx, ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='24'
			height='23'
			viewBox='0 0 24 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			sx={combineSx({ fill: 'none' }, sx)}
			{...props}
		>
			<path
				d='M19.68 0.0997095C17.8272 0.0997095 16.32 1.60691 16.32 3.45971C16.32 4.34569 16.6671 5.15004 17.2294 5.75096L13.2122 10.6597H7.64158C7.40741 9.03415 6.00941 7.77971 4.32002 7.77971C2.46722 7.77971 0.960022 9.28691 0.960022 11.1397C0.960022 12.9925 2.46722 14.4997 4.32002 14.4997C6.00941 14.4997 7.40741 13.2453 7.64158 11.6197H13.201L17.056 16.7263C16.5963 17.3011 16.32 18.0281 16.32 18.8197C16.32 20.6725 17.8272 22.1797 19.68 22.1797C21.5328 22.1797 23.04 20.6725 23.04 18.8197C23.04 16.9669 21.5328 15.4597 19.68 15.4597C18.9663 15.4597 18.3057 15.6853 17.761 16.0663L14.0503 11.1519L17.9775 6.35096C18.4776 6.64662 19.0581 6.81971 19.68 6.81971C21.5328 6.81971 23.04 5.31251 23.04 3.45971C23.04 1.60691 21.5328 0.0997095 19.68 0.0997095Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default ApplicantsIcon;
