import React from 'react';

import { Box, TextField, Typography, Stack, Rating } from '@mui/material';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import { CHAR_LIMIT } from '@ivy/constants/review';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ExtraParams } from '../reviewForm';

import { type ReviewStepValues } from './ReviewStep';

interface RatingObj {
	content: string;
	rating: number;
}

interface RatingValues {
	employerRating: RatingObj;
	facilityRating: RatingObj;
}

interface RatingProps
	extends Omit<SubFormStepProps<ReviewStepValues, ExtraParams>, 'formik'> {
	formik: FormikWithNamespace<RatingObj>;
}

const employerValidation = yup.object({
	employerRating: yup
		.object({
			content: yup
				.string()
				.required('Please fill out this entry.')
				.max(CHAR_LIMIT)
				.label('Content'),
			rating: yup
				.number()
				.typeError('Rating is required')
				.required('Please enter a rating from 1 to 5.')
				.min(1)
				.max(5)
				.label('Rating'),
		})
		.required(),
});

const facilityValidation = yup.object({
	facilityRating: yup
		.object({
			content: yup
				.string()
				.required('Please fill out this entry.')
				.max(CHAR_LIMIT)
				.label('Content'),
			rating: yup
				.number()
				.typeError('Rating is required')
				.required('Please enter a rating from 1 to 5.')
				.min(1)
				.max(5)
				.label('Rating'),
		})
		.required(),
});

const initialValue = {
	employerRating: {
		content: '',
		rating: 0,
	},
	facilityRating: {
		content: '',
		rating: 0,
	},
};

const EntityRating = withFormikNamespace<
	RatingProps,
	ReviewStepValues,
	RatingObj
>(({ formik, onNext, activeStep }) => {
	const {
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldTouched,
		isSubmitting,
	} = formik;
	const errorState = getErrorState(formik, 'rating');

	const isEmployerStep = activeStep === 'employerRating';

	return (
		<Box>
			<Stack
				direction='column'
				sx={{ width: { sm: '90%', xs: '100%' } }}
				spacing={3}
			>
				<Stack direction='column'>
					<Rating
						size='large'
						value={values.rating}
						onChange={(_, newValue) => {
							setFieldValue('rating', newValue, false);
							setFieldTouched('rating');
						}}
					/>
					{!!errorState.error && (
						<Typography
							variant='caption'
							color='error.main'
							sx={{ mx: 1.5, mt: 0.5 }}
						>
							{errorState.helperText}
						</Typography>
					)}
				</Stack>
				<Stack direction='column'>
					<Typography variant='subtitle2' sx={{ mb: 2 }}>
						Tell us about your experience working for this{' '}
						{isEmployerStep ? 'employer' : 'facility'}.
					</Typography>
					<TextField
						multiline
						minRows={3}
						required
						disabled={isSubmitting}
						name='content'
						value={values.content}
						onChange={handleChange}
						onBlur={handleBlur}
						fullWidth
						{...getErrorState(formik, 'content')}
					/>
					<Typography variant='caption' textAlign='end' sx={{ mt: 1 }}>
						{Math.max(CHAR_LIMIT - values.content.length, 0)} characters left
					</Typography>
				</Stack>
				{isEmployerStep && (
					<Box>
						<NavButton variant='text' onClick={onNext}>
							Next ⏎
						</NavButton>
					</Box>
				)}
			</Stack>
		</Box>
	);
});

export default EntityRating;
export {
	type RatingValues,
	type RatingProps,
	employerValidation,
	facilityValidation,
	initialValue,
};
