import React from 'react';

import { Navigate } from 'react-router-dom';

import { RouteUri } from '@ivy/constants/routes';
import { useAuthInfoLoaded } from '@ivy/gql/hooks';

const AuthBoundary = ({ children }: { children?: React.ReactNode }) => {
	const isAuth = useAuthInfoLoaded();

	if (!isAuth) {
		return (
			<Navigate
				to={RouteUri.ROOT}
				// Avoid "blocked" back behavior
				replace
			/>
		);
	}

	return children;
};

export default AuthBoundary;
