import type React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import InvestPromo from '@ivy/components/molecules/InvestPromo';
import ReviewPromo from '@ivy/components/molecules/ReviewPromo';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';
import { type WhitelabelConfigName } from '@ivy/lib/whitelabel/whitelabelConfig';

interface DisplayPromotionProps {
	showPromo: boolean;
	closePromotion: () => void;
	showMenuItemPromo?: boolean;
	primaryRoute?: string;
	PromoComponent?: React.ElementType;
}

interface PromotionObj {
	[key: string]: {
		sessionKey: string;
		primaryRoute: string;
		component: React.ElementType;
		routeWhitelist?: string[];
		anchorMenuItem?: boolean;
		whitelabels?: WhitelabelConfigName[];
	};
}

const promotions: PromotionObj = {
	review: {
		sessionKey: '_review_promotion_show_promo',
		primaryRoute: RouteUri.REVIEW,
		component: ReviewPromo,
		routeWhitelist: [RouteUri.ROOT, RouteUri.SALARY],
		anchorMenuItem: true,
		whitelabels: ['default'],
	},
	invest: {
		sessionKey: '_invest_promotion_show_promo',
		primaryRoute: RouteUri.ROOT,
		component: InvestPromo,
		routeWhitelist: [RouteUri.ABOUT_US],
		whitelabels: ['default'],
	},
};

// Put the key of the promotion that should be activated here
const useDisplayPromotion = (activePromotion?: string) => {
	const promo = activePromotion ? promotions[activePromotion] : null;
	const sessionState = useMemo(() => {
		if (!promo) return false;

		const state = localStorage.getItem(promo.sessionKey);
		if (state) return JSON.parse(state);
		return true;
	}, [promo]);

	const location = useLocation();
	const currAcc = useCurrentAccount();
	const wl = useWhitelabel();
	const [showPromo, setShowPromo] = useState(false);
	const isPathInWhitelist = promo?.routeWhitelist?.includes(location.pathname);
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;

	useEffect(() => {
		if (!promo || !sessionState) return;

		let timeoutId;
		const isPrimaryRoute = location.pathname === promo.primaryRoute;

		if (
			isClinicianOrAnon &&
			(isPathInWhitelist || isPrimaryRoute) &&
			(!promo.whitelabels || promo.whitelabels.includes(wl.name))
		) {
			timeoutId = setTimeout(() => {
				setShowPromo(true);
			}, 2000);
		}

		return () => clearTimeout(timeoutId);
	}, [
		location,
		isClinicianOrAnon,
		isPathInWhitelist,
		promo,
		sessionState,
		wl.name,
	]);

	const closePromotion = useCallback(() => {
		setShowPromo(false);
		if (promo) {
			localStorage.setItem(promo.sessionKey, 'false');
		}
	}, [setShowPromo, promo]);

	return {
		showPromo,
		closePromotion,
		showMenuItemPromo: promo?.anchorMenuItem && isPathInWhitelist && showPromo,
		primaryRoute: promo?.primaryRoute,
		PromoComponent: promo?.component,
	} as DisplayPromotionProps;
};

export default useDisplayPromotion;
