import React from 'react';

import { DeleteOutline } from '@mui/icons-material';
import { Box, FormHelperText, Grid, Stack } from '@mui/material';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import StateSelector from '@ivy/components/molecules/StateSelector';
import { stateAbbreviations } from '@ivy/constants/location';

import { type ProfileCompleteFormValues } from '../profileCompleteForm';

interface LicenseObjectValues {
	id: string;
	state: string;
}

interface LicenseValues {
	licenses: LicenseObjectValues[];
}

interface LicenseFormProps {
	formik: FormikWithNamespace<LicenseObjectValues>;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

const LicenseForm = withFormikNamespace<
	LicenseFormProps,
	LicenseObjectValues[],
	LicenseObjectValues
>(({ formik, arrayHelpers, idx, hideRemove }) => {
	const { values, isSubmitting, touched, handleChange, handleBlur, errors } =
		formik;

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			alignItems='flex-start'
			flex={1}
		>
			<StateSelector
				label=''
				name='state'
				placeholder='Select your license'
				value={values.state}
				onChange={handleChange}
				onBlur={handleBlur}
				helperText={touched.state && errors.state}
				error={touched.state && !!errors.state}
				fullWidth
				sx={{ maxWidth: { sm: '300px', xs: 'none' } }}
			/>
			<Box
				display={hideRemove ? 'none' : 'block'}
				sx={{
					width: { sm: 'auto', xs: '100%' },
					ml: { sm: 2, xs: 0 },
					mt: { sm: 0, xs: 1 },
				}}
			>
				<ControlButton
					startIcon={<DeleteOutline />}
					onClick={() => arrayHelpers.remove(idx)}
					disabled={isSubmitting}
				>
					Remove
				</ControlButton>
			</Box>
		</Stack>
	);
});

interface LicenseProps {
	formik: FormikWithNamespace<LicenseObjectValues[]>;
	onNext: (event: React.SyntheticEvent) => void;
}

const MAX_LICENSES = stateAbbreviations.length;

const validation = yup.object({
	licenses: yup
		.array()
		.of(
			yup.object({
				id: yup.string(),
				state: yup
					.string()
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.oneOf(stateAbbreviations).required(),
						otherwise: (schema) =>
							schema.oneOf(['', ...stateAbbreviations]).nullable(),
					})
					.label('License'),
			}),
		)
		.test('no-dups', 'No duplicates allowed', (value) => {
			const filtered = value?.filter((el) => !!el.state) || [];
			return (
				new Set(filtered.map((el) => el?.state?.toLowerCase())).size ===
				filtered.length
			);
		})
		.max(MAX_LICENSES, `At most ${MAX_LICENSES} licenses`)
		.required(),
});

const initialValue = {
	licenses: [
		{
			id: uuidv4(),
			state: '',
		},
	],
};

const License = withFormikNamespace<
	LicenseProps,
	ProfileCompleteFormValues,
	LicenseObjectValues[]
>(({ formik, onNext }) => {
	const { values, errors, touched } = formik;
	return (
		<Stack>
			<FieldArray
				name={formik.getFullName()}
				render={(arrayHelpers) => (
					<Grid container direction='column' spacing={1}>
						{values.map((obj, idx) => (
							<Grid container item key={obj.id}>
								<LicenseForm
									formik={formik}
									arrayHelpers={arrayHelpers}
									namespace={idx}
									idx={idx}
									hideRemove={values.length < 2}
								/>
							</Grid>
						))}
						<Grid
							item
							display='flex'
							justifyContent={{ sm: 'flex-start', xs: 'center' }}
						>
							<ModifyButton
								disabled={values.length >= MAX_LICENSES}
								onClick={() =>
									arrayHelpers.push({
										id: uuidv4(),
										state: '',
									})
								}
							>
								Add license
							</ModifyButton>
						</Grid>
						<Grid item>
							{touched && typeof errors === 'string' && (
								<FormHelperText error>{errors}</FormHelperText>
							)}
						</Grid>
					</Grid>
				)}
			/>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Stack>
	);
});

export default License;
export { type LicenseValues, type LicenseProps, validation, initialValue };
