import React from 'react';

import { Stack, Button, type StackProps } from '@mui/material';

interface ButtonToggleProps {
	offText?: string;
	onText?: string;
	disabled: boolean;
	value?: boolean | null;
	onClick: (val: boolean) => void;
	props?: StackProps;
}

const buttonSx = {
	minWidth: '0px',
	height: '55px',
	width: 'auto',
	py: 3,
	px: 3,
	mb: 0,
	whiteSpace: 'nowrap',
};

const ButtonToggle = ({
	offText = 'Off',
	onText = 'On',
	disabled,
	value,
	onClick,
	props,
}: ButtonToggleProps) => {
	const isBoolean = typeof value === 'boolean';
	const handleClick = (val: boolean) => {
		onClick(val);
	};

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			spacing={{ sm: 3, xs: 1 }}
			{...props}
		>
			<Button
				variant={isBoolean && !value ? 'contained' : 'outlined'}
				size='large'
				disabled={disabled}
				onClick={() => handleClick(false)}
				sx={buttonSx}
			>
				{offText}
			</Button>
			<Button
				variant={isBoolean && value ? 'contained' : 'outlined'}
				size='large'
				disabled={disabled}
				onClick={() => handleClick(true)}
				sx={buttonSx}
			>
				{onText}
			</Button>
		</Stack>
	);
};

export default ButtonToggle;
