import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

const DialogCloseButton = ({ hide, ...props }) => {
	return (
		<Box
			position='absolute'
			right={0}
			margin={1}
			sx={{
				zIndex: (theme) => theme.zIndex.modal + 1,
			}}
		>
			<IconButton
				{...props}
				sx={{
					display: hide ? 'none' : undefined,
				}}
			>
				<Close />
			</IconButton>
		</Box>
	);
};

export default DialogCloseButton;
