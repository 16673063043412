import React from 'react';

import { SvgIcon } from '@mui/material';

const GradientBlob = ({ ...props }) => {
	return (
		<SvgIcon
			width='630'
			height='534'
			viewBox='0 0 630 534'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M133.937 40.1506C200.805 -9.03095 282.561 -1.92644 357.701 4.77233C419.398 10.2726 469.374 36.8949 514.156 74.0471C565.96 117.024 626.399 158.781 629.608 230.349C633.165 309.693 595.61 396.32 531.276 455.893C467.836 514.638 381.654 535.616 302.64 533.118C230.491 530.837 178.34 487.529 127.966 443.143C71.6297 393.503 -0.323274 347.957 0.873064 267.222C2.12651 182.632 59.6453 94.7929 133.937 40.1506Z'
				fill='url(#paint0_linear_350_31681)'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_350_31681'
					x1='18.9042'
					y1='183.191'
					x2='430.291'
					y2='505.951'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#2682C5' />
					<stop offset='1' stopColor='#1EC86A' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};

export default GradientBlob;
