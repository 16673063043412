import React from 'react';

import {
	Box,
	type SxProps,
	type Theme,
	Typography,
	Stack,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const navGIF =
	'https://assets.ivyclinicians.io/images//emra-search-instructions.gif';

interface NavigateCareerProps {
	sx?: SxProps<Theme>;
}

const NavigateCareer = ({ sx }: NavigateCareerProps) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
				}}
			/>
			<Stack
				sx={{
					py: 11,
					alignItems: 'center',
					width: '100%',
					height: '100%',
					overflow: 'hidden',
				}}
				direction={{ md: 'row', xs: 'column-reverse' }}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						height: { md: '100%', xs: '30%' },
						width: { md: 'auto', xs: '100%' },
						flexBasis: { md: '50%' },
						mr: { xl: 15, lg: 10, md: 5 },
						justifyContent: 'center',
					}}
				>
					<Box
						component='img'
						src={navGIF}
						sx={{
							bottom: { md: 'auto', xs: '-14%' },
							width: '100%',
							maxWidth: '602.79px',
							borderRadius: '16px',
							mt: { xs: 8, md: 0 },
						}}
					/>
				</Box>
				<Stack
					spacing={5}
					sx={{
						alignItems: 'flex-start',
						justifyContent: 'center',
						flex: 1,
						flexBasis: { md: '50%' },
					}}
				>
					<Typography
						variant='h3'
						sx={{
							width: '100%',
							fontSize: { xs: '32px', sm: '40px' },
							lineHeight: '40px',
							letterSpacing: '-0.8px',
						}}
					>
						Navigating the Next Step in Your Career Can Be Overwhelming
					</Typography>
					<Typography
						variant='h4'
						color='#231F20'
						fontWeight='light'
						sx={{
							fontSize: { xs: '24px', sm: '32px' },
							lineHeight: '40px',
							letterSpacing: '-0.64px',
						}}
					>
						Our goal is to provide ready access to easily filterable information
						that will help you determine which programs best fit you.
					</Typography>
					<Typography
						variant='subtitle1'
						component='h5'
						color='#231F20'
						fontWeight='light'
						sx={{
							display: { xs: 'none', sm: 'block' },
							fontSize: '24px',
							lineHeight: '32px',
							letterSpacing: '-0.48px',
						}}
					>
						Imagine yourself at the very beginning of your journey with little
						to no compass. No one can tell you what to do next.
					</Typography>
					<Typography
						variant='subtitle1'
						component='h5'
						color='#231F20'
						fontWeight='light'
						sx={{
							fontSize: { xs: '15px', sm: '24px' },
							lineHeight: '32px',
							letterSpacing: '-0.48px',
						}}
					>
						We understand how hard it can be and we are here to help by
						providing the necessary information.
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
};

export default NavigateCareer;
