import React from 'react';

import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { captureException } from '@sentry/react';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';

export interface ConfirmationDialogProps extends PopupProps {
	text: React.ReactNode;
	error?: boolean | Error;
	loading?: boolean;
	onSubmit: () => void;
	verbose?: boolean;
}

const ConfirmationDialog = ({
	text,
	error,
	onSubmit,
	onClose,
	loading = false,
	verbose = false,
	...props
}: ConfirmationDialogProps) => {
	const handleSubmit = async () => {
		try {
			await onSubmit();
			onClose?.();
		} catch (e) {
			console.error(e);
			captureException(e);
		}
	};

	return (
		<Popup
			disableCloseButton
			onClose={onClose}
			shrink
			actions={
				<>
					<Button
						variant='outlined'
						color='primary'
						onClick={onClose}
						disabled={loading}
						sx={{ mr: 'auto' }}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleSubmit}
						disabled={loading}
					>
						Yes
					</Button>
				</>
			}
			{...props}
		>
			<Box
				width='100%'
				height='100%'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Typography variant='body1'>{text}</Typography>
				{!!error && (
					<FormHelperText error sx={{ mt: 2 }}>
						{verbose && error instanceof Error
							? error.message
							: 'An error occurred, please try again'}
					</FormHelperText>
				)}
			</Box>
		</Popup>
	);
};

export default ConfirmationDialog;
