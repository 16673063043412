import React from 'react';

import {
	Box,
	type Theme,
	LinearProgress,
	linearProgressClasses,
	type SxProps,
	Divider,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import unionBy from 'lodash/unionBy';

import { StarIcon } from '@ivy/components/icons';

interface RatingObj {
	count?: number | null;
	value?: number | null;
}

interface StarRatingsProps {
	ratingArray: RatingObj[];
	total: number;
}

const defaultRatingArray: RatingObj[] = [
	{ value: 1, count: 0 },
	{ value: 2, count: 0 },
	{ value: 3, count: 0 },
	{ value: 4, count: 0 },
	{ value: 5, count: 0 },
];

const StarRatings = ({ ratingArray, total }: StarRatingsProps) => {
	const sortedArray = ratingArray
		.slice()
		.sort((a, b) => (b.value && a.value ? b.value - a.value : 0));

	const valText = (val?: number | null) => {
		let txt = '';
		switch (val) {
			case 5:
				txt = 'Excellent';
				break;
			case 4:
				txt = 'Good';
				break;
			case 3:
				txt = 'Average';
				break;
			case 2:
				txt = 'Poor';
				break;
			default:
				txt = 'Terrible';
				break;
		}
		return txt;
	};

	return (
		<Stack spacing={{ sm: 4, xs: 3 }} sx={{ flex: 1 }}>
			{sortedArray.map((rating) => (
				<Stack
					key={rating.value}
					direction='row'
					spacing={2}
					width='100%'
					alignItems='center'
				>
					<Typography variant='body2' sx={{ fontSize: '14px', width: '100px' }}>
						<StarIcon sx={{ fontSize: '16px', mb: '-3px' }} /> {rating.value}{' '}
						{valText(rating.value)}
					</Typography>
					<LinearProgress
						variant='determinate'
						value={rating.count ? rating.count * (100 / total) : 0}
						sx={{
							flex: 1,
							height: '8px',
							borderRadius: '32px',
							backgroundColor: '#CDCFD2',
							[`& .${linearProgressClasses.bar}`]: {
								borderRadius: '32px',
								backgroundColor: (theme) => theme.palette.primary.main,
							},
						}}
					/>
					<Box sx={{ fontSize: '14px' }}>
						<Typography variant='body2' color='primary.main'>
							{rating.count} reviews
						</Typography>
					</Box>
				</Stack>
			))}
		</Stack>
	);
};

interface ReviewRatingProps {
	avgRating: number;
	count: number;
	ratings?: RatingObj[];
	sx?: SxProps<Theme>;
}

const ReviewRating = ({
	avgRating,
	count,
	ratings = [],
	sx,
}: ReviewRatingProps) => {
	const theme = useTheme();
	const mergedRatings = unionBy(ratings, defaultRatingArray, 'value');

	return (
		<Box sx={sx}>
			<Stack
				direction={{ sm: 'row', xs: 'column' }}
				spacing={{ md: 8, sm: 4, xs: 5 }}
			>
				<Stack spacing={2} sx={{ maxWidth: '205px' }}>
					<Typography
						variant='body1'
						fontWeight='bold'
						color={theme.palette.text.icon}
					>
						Clinician star rating
					</Typography>
					<Box>
						<Typography
							variant='h4'
							display='flex'
							sx={{ alignItems: 'center' }}
						>
							{avgRating.toFixed(1) || '0.0'}
							<StarIcon sx={{ ml: 1, fontSize: '24px' }} />
						</Typography>
						<Typography variant='body2'>
							based on{' '}
							<Box
								component='span'
								sx={{ color: 'primary.main', fontWeight: 'bold' }}
							>
								{count} {count === 1 ? 'review' : 'reviews'}
							</Box>
						</Typography>
					</Box>
					<Typography variant='caption' color='text.icon'>
						These ratings and reviews are the subjective opinions of clinicians
						on our platform and not of Ivy.
					</Typography>
				</Stack>
				<Divider
					orientation='vertical'
					variant='middle'
					flexItem
					sx={{ display: { sm: 'block', xs: 'none' } }}
				/>
				<StarRatings ratingArray={mergedRatings} total={count} />
			</Stack>
		</Box>
	);
};

export default ReviewRating;
export { type ReviewRatingProps };
