import { useCallback, useEffect, useMemo, useRef } from 'react';

type OnImpressionFunction = (node: HTMLElement) => void;
type OnClickFunction = (node: HTMLElement, event: Event) => void;

/**
 * Watches elements tagged with the given `selector` and triggers callbacks if they come into view (impression)
 * or if they are clicked, including children.
 *
 * In the scenario where the container is not clickable or clicking certain parts of the container should not count
 * as a click (e.g. they lead elsewhere), more granular click tracking can be enabled by using the `clickableSelector`
 * to tag the correct elements. In this case, the `selector` node will not trigger the click callback by itself, but
 * only its children with the `clickableSelector` attributes.
 */
const useAnalyticsObserver = (
	selector: string,
	clickableSelector: string,
	onImpression: OnImpressionFunction,
	onClick: OnClickFunction,
) => {
	// Use refs in case the function changes without having to teardown and redo the entire observer structure
	const onImpressionRef = useRef<OnImpressionFunction>(onImpression);
	const onClickRef = useRef<OnClickFunction>(onClick);
	useEffect(() => {
		onImpressionRef.current = onImpression;
	});
	useEffect(() => {
		onClickRef.current = onClick;
	});
	const unregisterRef = useRef<(() => void)[]>([]);

	const unregisterEventHandlers = useCallback(() => {
		unregisterRef.current.forEach((el) => el());
		unregisterRef.current = [];
	}, [unregisterRef]);

	// Handles a click
	const interactionHandler = useCallback(
		(event: Event) => {
			if (!(event.currentTarget instanceof HTMLElement)) {
				return;
			}
			const container = event.currentTarget.closest(`[${selector}]`);
			if (container && container instanceof HTMLElement) {
				onClickRef.current(container, event);
			}
		},
		[onClickRef, selector],
	);

	// Handles an impression
	const intersectionObserver = useMemo(() => {
		return window.IntersectionObserver
			? new IntersectionObserver(
					(entries) => {
						for (const entry of entries) {
							if (entry.isIntersecting) {
								const node = entry.target;
								if (node instanceof HTMLElement) {
									onImpressionRef.current(node);
									if (intersectionObserver) {
										// Don't trigger another callback if we see this element again
										// Note this doesn't work for virtualized scrolling
										intersectionObserver.unobserve(node);
									}
								}
							}
						}
					},
					{
						// https://support.google.com/admanager/answer/4524488?hl=en
						threshold: 0.5,
					},
			  )
			: undefined;
	}, [onImpressionRef]);

	// Adds a click handler, either to the node itself or, if children exist with the `clickableSelector`, to those
	// instead
	const addClickHandler = useCallback(
		(node: HTMLElement) => {
			const clickables = node.querySelectorAll(`[${clickableSelector}]`);
			const elements = clickables.length === 0 ? [node] : clickables;
			elements.forEach((e) => {
				e.addEventListener('click', interactionHandler);
				unregisterRef.current.push(() =>
					e.removeEventListener('click', interactionHandler),
				);
			});
		},
		[clickableSelector, interactionHandler, unregisterRef],
	);

	// Prepares a node to be observed for impressions or clicks
	const processChild = useCallback(
		(node: HTMLElement) => {
			if (intersectionObserver) {
				intersectionObserver.observe(node);
			} else {
				// No intersection observer available, simply mark everything as being viewed instead of waiting
				// for it to scroll into view
				onImpressionRef.current(node);
			}
			addClickHandler(node);
		},
		[intersectionObserver, onImpressionRef, addClickHandler],
	);

	// Prepares all matching children elements for impressions or clicks
	const checkChildren = useCallback(
		(node: Element | Document) => {
			const matchedNodes = node.querySelectorAll(`[${selector}]`);
			matchedNodes.forEach((match) => {
				if (match instanceof HTMLElement) {
					processChild(match);
				}
			});
		},
		[selector, processChild],
	);

	// Handles when the DOM changes and we need to observe new elements
	const mutationCallback = useCallback(
		(mutationsList: MutationRecord[]) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					// Here we could just do a mutation.target.querySelectorAll, but that could also bring the
					// nodes we have already seen before. Instead we just check the added nodes.
					const newParents = new Set<HTMLElement>();
					for (let i = 0; i < mutation.addedNodes.length; i++) {
						const newNode = mutation.addedNodes[i];
						if (newNode?.nodeType === Node.ELEMENT_NODE) {
							const parent = newNode.parentElement;
							if (parent && !newParents.has(parent)) {
								newParents.add(parent);
							}
						}
						for (const node of newParents) {
							checkChildren(node);
						}
					}
				} else if (mutation.type === 'attributes') {
					if (!(mutation.target instanceof HTMLElement)) {
						continue;
					}
					processChild(mutation.target);
				}
			}
		},
		[checkChildren, processChild],
	);

	useEffect(() => {
		// Attach intersection observers and click handlers
		checkChildren(document);
		// Use a mutation observer to continue adding intersection observers and click handlers
		const mutationObserver = new MutationObserver(mutationCallback);
		mutationObserver.observe(document, {
			attributes: true,
			childList: true,
			subtree: true,
			attributeFilter: [selector],
		});
		// Clean up resources to prevent memory leaks
		return () => {
			// Remove all event handlers
			unregisterEventHandlers();
			// Stop observing intersections
			intersectionObserver?.disconnect();
			// Stop observing mutations
			mutationObserver.disconnect();
		};
	}, [
		checkChildren,
		selector,
		unregisterEventHandlers,
		interactionHandler,
		intersectionObserver,
		mutationCallback,
	]);
};

export default useAnalyticsObserver;
