import React from 'react';

import { Helmet } from 'react-helmet';

import ErrorBoundary from '@ivy/components/boundaries/ErrorBoundary';
import ErrorScreen from '@ivy/components/molecules/ErrorScreen';
import LoadingScreen from '@ivy/components/molecules/LoadingScreen';
import { useAuthContext } from '@ivy/components/providers/AuthProvider';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import AppRoutes from './AppRoutes';

const App = () => {
	const { initialized, error } = useAuthContext();
	const wl = useWhitelabel();

	return (
		<div className='App'>
			<Helmet>
				{/* This acts as the default title, and it's necessary to have here in order to replace */}
				{/* the title when a page unmounts. */}
				<title>{wl.productName}</title>
				<meta name='description' content={wl.productDescription} />
			</Helmet>
			<ErrorBoundary fullscreen>
				{error ? (
					<ErrorScreen fullscreen />
				) : !initialized ? (
					<LoadingScreen fullscreen variant='logo' />
				) : (
					<AppRoutes />
				)}
			</ErrorBoundary>
		</div>
	);
};

export default App;
