import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ReviewFormValues } from '../reviewForm';

import ButtonToggle from './Inputs/ButtonToggle';

interface IdentityValues {
	isAnonymous: boolean | null;
}

interface IdentityProps {
	formik: FormikProps<ReviewFormValues>;
	onNext: (event: React.SyntheticEvent) => void;
}

const validation = yup.object({
	isAnonymous: yup
		.boolean()
		.nullable()
		.required('Please select how you want your name displayed.')
		.label('Identity'),
});

const initialValue = {
	isAnonymous: null,
};

const Identity = ({ formik, onNext }: IdentityProps) => {
	const { values, setFieldValue, setFieldTouched, isSubmitting } = formik;
	const errorState = getErrorState(formik, 'isAnonymous');
	return (
		<Box>
			<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ width: '100%' }}>
				<Stack direction='column'>
					<ButtonToggle
						offText='Full name'
						onText='Anonymous'
						disabled={isSubmitting}
						value={values.isAnonymous}
						onClick={(val) => {
							setFieldValue('isAnonymous', val, false);
							setFieldTouched('isAnonymous');
						}}
					/>
					{!!errorState.error && (
						<Typography
							variant='caption'
							color='error.main'
							sx={{ mx: 1.5, mt: 0.5 }}
						>
							{errorState.helperText}
						</Typography>
					)}
				</Stack>
				<Box
					sx={{
						ml: { sm: 3, xs: 0 },
					}}
				>
					<NavButton variant='text' onClick={onNext}>
						Next ⏎
					</NavButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default Identity;
export { type IdentityValues, type IdentityProps, validation, initialValue };
