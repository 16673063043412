import React from 'react';

import { Stack, Typography, Card, CardContent, Box } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

interface SelectFormValues {
	tab: string;
}

interface SelectStepProps {
	formik: FormikProps<SelectFormValues>;
}

enum PopupType {
	CLINICIAN = 'CLINICIAN',
	ORGANIZATION = 'ORGANIZATION',
}

const TABS = [
	{
		label: 'I am a clinician',
		img: 'https://assets.ivyclinicians.io/images/clinician-xray-illustration.svg',
		value: PopupType.CLINICIAN,
	},
	{
		label: 'I am an employer',
		img: 'https://assets.ivyclinicians.io/images/women-clipboard-illustration.svg',
		value: PopupType.ORGANIZATION,
	},
];

const validation = yup.object({
	tab: yup.string().oneOf(Object.values(PopupType)).required(),
});

const initialValue = {
	tab: '',
};

const SelectStep = ({ formik }: SelectStepProps) => {
	const select = formik.values.tab;

	return (
		<Stack
			sx={{
				justifyContent: {
					sm: 'space-between',
					xs: 'normal',
				},
				alignItems: 'center',
				height: '100%',
			}}
		>
			<Typography
				variant='h3'
				component='h1'
				sx={{
					textAlign: 'center',
					maxWidth: '683px',
					mx: 'auto',
					mb: { sm: 10, xs: 5 },
				}}
			>
				Are you signing up as a clinician or employer?
			</Typography>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				spacing={{ md: 5, xs: 3 }}
				sx={{ width: '100%', maxWidth: { md: 'none', sm: '400px' } }}
			>
				{TABS.map(({ label, value, img }) => (
					<Card
						key={label}
						className={value === select ? 'active' : ''}
						onClick={() => {
							formik.setFieldTouched('tab', true, false);
							formik.setFieldValue('tab', value, false);
						}}
						sx={{
							bgcolor: 'white',
							boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
							'&.active': {
								bgcolor: 'primary.translucent',
							},
							'&:hover': {
								bgcolor: 'light2.main',
								cursor: 'pointer',
							},
							width: '100%',
						}}
					>
						<CardContent
							sx={{
								display: 'flex',
								p: {
									md: 8,
									sm: 4,
								},
								justifyContent: 'center',
								flexDirection: 'column',
								alignItems: 'center',
								'&:last-child': {
									pb: {
										md: 8,
										sm: 4,
										xs: 3,
									},
								},
							}}
						>
							<Box
								component='img'
								src={img}
								sx={{
									height: {
										md: '163px',
										xs: '120px',
									},
									mb: 5,
								}}
							/>
							<Typography
								variant='h5'
								component='p'
								sx={{ textAlign: 'center' }}
							>
								{label}
							</Typography>
						</CardContent>
					</Card>
				))}
			</Stack>
		</Stack>
	);
};

export default SelectStep;
export {
	type SelectFormValues,
	type SelectStepProps,
	initialValue,
	validation,
	PopupType,
};
