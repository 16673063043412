import { ApolloError, Observable, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { captureException } from '@sentry/react';

import { logOut } from '@ivy/lib/storage/token';

import { getNewToken, isJwtError } from './jwt';

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const setIsRefreshing = (value: boolean) => {
	isRefreshing = value;
};

const addPendingRequest = (pendingRequest: () => void) => {
	pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
	pendingRequests.map((callback) => callback());
	pendingRequests = [];
};

// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
const errorLink = onError(({ graphQLErrors, operation, forward, response }) => {
	if (graphQLErrors) {
		if (graphQLErrors.some((el) => !isJwtError(el))) {
			// By default, we capture any exception from a query or mutation (no support for subscriptions right now)
			// Cast to a regular error so that Sentry doesn't complain (send commented beforeSend code)
			const formalError = new ApolloError({ graphQLErrors });
			console.error(formalError);
			// Without setTimeout, the breadcrumb/event timestamps are
			// (event) captureException - 1693462036.592
			// (breadcrumb) http mutation 1693462036.595
			// Thus, the "http mutation" breadcrumb often doesn't get sent unless we use a timeout even though
			// this errorLink is called after SentryLink
			setTimeout(() => captureException(formalError), 100);
		}
		for (const err of graphQLErrors) {
			if (isJwtError(err)) {
				if (!isRefreshing) {
					setIsRefreshing(true);

					return (
						fromPromise(
							getNewToken()
								.then((el) => {
									// New requests can now proceed and no more can be added to the pending queue
									setIsRefreshing(false);
									// Resolve previously blocked requests
									resolvePendingRequests();
									return el;
								})
								.catch((e) => {
									if (!isJwtError(e)) {
										console.error('Could not refresh access token', e);
										captureException(e);
									}
									// Regardless of JWT error or not, perform a logout
									logOut();
									// Don't setIsRefreshing(false) so that other requests stay blocked and won't complete
								}),
						)
							// Retry the operation if refresh was successful
							// https://www.apollographql.com/docs/react/api/link/apollo-link-error/#options
							// QueryManager will throw the JWTExpired error of the first request if the secondary
							// refresh was not successful, but it will not return the error silently to the
							// useQuery/useMutation caller. Have not found a way to return it to the caller.
							// All other requests will be blocked until logout is finished.
							.flatMap((el) =>
								el
									? forward(operation)
									: response
									? Observable.of(response)
									: Observable.of(),
							)
					);
				} else {
					// Will only emit pending requests once this Promise/Observable is resolved
					return fromPromise(
						new Promise<void>((resolve) => addPendingRequest(() => resolve())),
					).flatMap(() => forward(operation));
				}
			}
		}
	}
});

export default errorLink;
