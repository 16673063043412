import React from 'react';

import { Box, type SxProps, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { combineSx } from '@ivy/lib/styling/sx';

import EmraProgramForm from '../components/EmraProgramForm';

export interface EmraFormSectionProps {
	sx?: SxProps<Theme>;
}

const EmraFormSection = ({ sx }: EmraFormSectionProps) => {
	return (
		<Box component='section' sx={combineSx({ position: 'relative' }, sx)}>
			<EmraProgramForm toPath={RouteLink.routes.EMRA_TRAINING_SURVEY_CREATE} />
		</Box>
	);
};

export default EmraFormSection;
