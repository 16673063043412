import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='11'
			height='19'
			viewBox='0 0 11 19'
			fill='none'
			{...props}
		>
			<path
				d='M7.31345 18.395V10.1844H10.2081L10.6415 6.98452H7.31337V4.94155C7.31337 4.01512 7.58355 3.38381 8.97899 3.38381L10.7587 3.38301V0.521091C10.4509 0.482165 9.39435 0.39502 8.16537 0.39502C5.59935 0.39502 3.84261 1.88619 3.84261 4.62474V6.98452H0.940491V10.1844H3.84261V18.3949H7.31345V18.395Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
