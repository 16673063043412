import React, { useState } from 'react';

import {
	ClickAwayListener,
	type SxProps,
	Tooltip as MuiTooltip,
	type TooltipProps as MuiTooltipProps,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export type TooltipProps = Omit<
	MuiTooltipProps,
	| 'disableTouchListener'
	| 'onOpen'
	| 'onClose'
	| 'open'
	| 'onClick'
	| 'leaveDelay'
>;

const Tooltip = ({ slotProps, ...props }: TooltipProps) => {
	const [open, setOpen] = useState(false);
	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<MuiTooltip
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				open={open}
				// The preferred mobile functionality is a click to open/close instead of a long touch
				onClick={() => setOpen(true)}
				disableTouchListener
				// In case there is a clickable link, give the user time to move their cursor to the popper
				leaveDelay={300}
				slotProps={{
					...slotProps,
					tooltip: {
						...slotProps?.tooltip,
						sx: combineSx(
							{
								p: 1,
								color: 'text.primary',
								bgcolor: 'background.paper',
								boxShadow: '0px 0px 32px rgba(142, 149, 155, 0.4)',
							},
							slotProps?.tooltip?.sx,
						) as SxProps,
					},
				}}
				{...props}
			/>
		</ClickAwayListener>
	);
};

export default Tooltip;
