import React from 'react';

import { useTheme } from '@mui/material';

const GradientIcon = ({ iconComponent: Icon, ...props }) => {
	const theme = useTheme();

	return (
		<>
			<svg width='0' height='0'>
				<linearGradient id='brand-gradient' x1='0%' x2='100%' y1='100%' y2='0%'>
					<stop stopColor={theme.palette.primary.main} offset='0%' />
					<stop stopColor={theme.palette.secondary.light} offset='100%' />
				</linearGradient>
			</svg>
			<Icon
				style={{
					fill: 'url(#brand-gradient)',
				}}
				{...props}
			/>
		</>
	);
};

export default GradientIcon;
