import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik, yupToFormErrors } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import {
	salaryReportFormSteps as formSteps,
	salaryInitialValues as initialFormValues,
} from '@ivy/components/forms/SalaryReportForm';
import { type SalaryFormValues } from '@ivy/components/forms/SalaryReportForm/SalaryReportForm';
import Popup from '@ivy/components/molecules/Popup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import {
	DEGREE2PROFESSION,
	ClinicianSpecialTypes,
} from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import {
	SalaryReportFormGeneralStep_OrganizationFragmentDoc,
	FacilitySelect_FacilityFragmentDoc,
} from '@ivy/gql/types/graphql';
import { mergeValidationSchemas } from '@ivy/lib/forms/formikHelpers';

const SalaryReportPopup_FacilityFDoc = gql(/* GraphQL */ `
	fragment SalaryReportPopup_Facility on facility {
		id
		name
		...SalaryReportFormGeneralStep_Facility
		...FacilitySelect_Facility
		contracts(where: { active: { _eq: true } }, order_by: { created_at: asc }) {
			org {
				id
				...SalaryReportFormGeneralStep_Organization
			}
		}
	}
`);

const SalaryReportPopup_OrganizationFDoc = gql(/* GraphQL */ `
	fragment SalaryReportPopup_Organization on organization {
		id
		name
		...SalaryReportFormGeneralStep_Organization
	}
`);

const SalaryReportPopup_CreatedSalaryReportMDoc = gql(/* GraphQL */ `
	mutation SalaryReportPopup_CreatedSalaryReport(
		$input: salary_report_insert_input!
	) {
		insert_salary_report_one(object: $input) {
			id
		}
	}
`);

const facilityValidation = yup.object({
	facility: yup
		.object()
		.label('Facility')
		.typeError('Facility is required')
		.required(),
});

interface SalaryReportPopupProps {
	onClose: () => void;
	open: boolean;
	facility?: FragmentType<typeof SalaryReportPopup_FacilityFDoc>;
	organization?: FragmentType<typeof SalaryReportPopup_OrganizationFDoc>;
	isAdmin?: boolean;
	refetchQueries?: string[];
}

const SalaryReportPopup = ({
	facility: rawFacility,
	organization: rawOrganization,
	open,
	onClose,
	isAdmin,
	refetchQueries = [],
}: SalaryReportPopupProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const facility = getFragmentData(SalaryReportPopup_FacilityFDoc, rawFacility);
	const organization = getFragmentData(
		SalaryReportPopup_OrganizationFDoc,
		rawOrganization,
	);
	const [currStepKey, setCurrStepKey] = useState('general');
	const currAcc = useCurrentAccount();
	const formRef = useRef<HTMLFormElement>(null);

	const [editPosting] = useMutation(SalaryReportPopup_CreatedSalaryReportMDoc, {
		refetchQueries: ['AuthProvider_CurrentAccount', ...refetchQueries],
		awaitRefetchQueries: true,
	});
	const { authPopup, openSignupPopup, openConfirmEmailPopup } = useAuthPopup();

	const step = formSteps[currStepKey];

	// adds default values
	const defaultFormValues: SalaryFormValues = useMemo(() => {
		const defaultValues: Partial<SalaryFormValues> = {};

		const orgObj = facility?.contracts[0]?.org;
		if (orgObj) {
			defaultValues.organization = getFragmentData(
				SalaryReportFormGeneralStep_OrganizationFragmentDoc,
				orgObj,
			);
		}

		if (facility) {
			defaultValues.facility = getFragmentData(
				FacilitySelect_FacilityFragmentDoc,
				facility,
			);
		}

		if (organization) {
			defaultValues.organization = getFragmentData(
				SalaryReportFormGeneralStep_OrganizationFragmentDoc,
				organization,
			);
		}

		let profession = '';
		if (!isAdmin && currAcc) {
			profession = DEGREE2PROFESSION[currAcc?.clinician?.profDegree];
		}

		if (profession) {
			defaultValues.profession = profession;
		}

		return Object.assign(
			{} as Partial<SalaryFormValues>,
			initialFormValues,
			defaultValues,
		);
	}, [currAcc, isAdmin, facility, organization]);

	const schema = mergeValidationSchemas(
		isAdmin || !facility ? facilityValidation : yup.object({}),
		step.validation,
	);

	const formik = useFormik({
		initialValues: defaultFormValues,
		validate: (values) => {
			try {
				schema.validateSync(values, {
					abortEarly: false,
					context: { isAdmin: !!isAdmin },
				});
				return {};
			} catch (err) {
				return yupToFormErrors(err);
			}
		},
		onSubmit: async (values, actions) => {
			if (formRef?.current) formRef.current?.scrollIntoView();
			if (step.closeStep) {
				onClose && onClose();
			} else if (step.finalStep) {
				handleFormSubmit(values, actions);
			} else {
				const futureStep = step.nextStep(values);

				// skip validation step if admin
				if (isAdmin && formSteps[futureStep].finalStep) {
					handleFormSubmit(values, actions);
					setCurrStepKey(formSteps[futureStep].nextStep());
				} else {
					setCurrStepKey(futureStep);
					actions.setTouched({});
					actions.setSubmitting(false);
				}
			}
		},
	});

	useEffect(() => {
		if (!open) return;

		if (!currAcc) {
			// Don't show ProfileCompletePopup after signup, go right into this form
			openSignupPopup(true, {
				// Close this form if we fail to authenticate
				onFailure: onClose,
			});
		} else if (!currAcc.isClinician) {
			// Sign up or log in as a non-clinician
			onClose?.();
		} else if (!currAcc.ci?.confirmedEmail) {
			// Failure to confirm email
			openConfirmEmailPopup({
				onFailure: onClose,
			});
		}
	}, [open, currAcc, openSignupPopup, openConfirmEmailPopup, onClose]);

	const handleBack = (values) => {
		if (formRef?.current) formRef.current?.scrollIntoView();
		setCurrStepKey(step.prevStep(values));
	};

	const handleFormSubmit = async (values, actions) => {
		const data = {
			facility_id: values.facility.id,
			org_id: values.organization?.id || null,
			org_other: values.orgOther,
			profession: values.profession,
			worker: values.workerClassification,
			experience: values.experience || null,
			admin: values.admin || null,
			traveler: values.traveler || null,
			shifts_per_month: values.shftsMonth || null,
			hrs_per_shift: values.hrsShift || null,
			base_amt: values.base.pay,
			base_period: values.base.period,
			night_diff: values.night.included,
			night_diff_amt: values.night.pay || null,
			weekend_diff: values.weekend.included,
			weekend_diff_amt: values.weekend.pay || null,
			bonus: values.bonus.included,
			bonus_amt: values.bonus.value || null,
			student_loan: values.studentLoan.included,
			student_loan_amt: values.studentLoan.pay || null,
			cme: values.cme.included,
			cme_amt: values.cme.pay || null,
			retirement: values.retirement.included,
			retirement_amt: values.retirement.value || null,
			...ClinicianSpecialTypes.reduce(
				(o, type) => ({ ...o, [type.apiKey]: values[type.key] }),
				{},
			),
		};
		try {
			await editPosting({
				variables: {
					input: data,
				},
			});
			enqueueSnackbar('Your salary posting has been created.', {
				variant: 'success',
			});
			if (step.finalStep) setCurrStepKey(step.nextStep());
			actions.setSubmitting(false);
		} catch (e) {
			// Return cleanly to formik
			console.error(e);
			captureException(e, {
				extra: {
					values,
				},
			});
			enqueueSnackbar('An error occurred, please try again.', {
				variant: 'error',
			});
			actions.setSubmitting(false);
		}
	};

	if (
		!currAcc ||
		!currAcc.isClinician ||
		!currAcc.ci?.confirmedEmail ||
		authPopup
	) {
		return null;
	}

	return (
		<Popup
			open={open}
			title='EM Salary Report'
			onClose={onClose}
			disableBackdropClickClose
			actions={
				step.closeStep ? (
					<Button
						sx={{ ml: 'auto' }}
						variant='contained'
						onClick={formik.submitForm}
						disabled={formik.isSubmitting}
					>
						Close
					</Button>
				) : (
					<>
						<Button onClick={onClose} sx={{ mr: 'auto' }}>
							Cancel
						</Button>
						{step.prevStep && (
							<Button
								variant='outlined'
								sx={{ mr: 1, display: step.closeStep ? 'none' : 'block' }}
								onClick={() => handleBack(formik.values)}
								disabled={formik.isSubmitting}
							>
								Back
							</Button>
						)}
						<Button
							variant='contained'
							onClick={formik.submitForm}
							disabled={formik.isSubmitting}
						>
							{step.finalStep ? 'Verify' : 'Next'}
						</Button>
					</>
				)
			}
		>
			<form ref={formRef}>
				<Box>
					<step.component
						formik={formik}
						isAdmin={isAdmin}
						facility={facility}
						organization={organization}
					/>
				</Box>
			</form>
		</Popup>
	);
};

export default SalaryReportPopup;
