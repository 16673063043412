import React from 'react';

import { Button, type ButtonProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const ControlButton = ({ sx, ...props }: ButtonProps) => {
	return (
		<Button
			sx={combineSx(
				{
					px: { sm: 3, xs: 1 },
					py: { sm: 2, xs: 1 },
				},
				sx,
			)}
			{...props}
		/>
	);
};

export default ControlButton;
