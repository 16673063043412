import React from 'react';

import { Button, type ButtonProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export type NavButtonProps = ButtonProps;

const NavButton = ({ sx, ...props }: ButtonProps) => {
	return (
		<Button
			sx={combineSx(
				{
					whiteSpace: 'nowrap',
					ml: -1,
					mt: 1,
				},
				sx,
			)}
			{...props}
		/>
	);
};

export default NavButton;
