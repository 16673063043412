import React from 'react';
import { useCallback, useMemo } from 'react';

import { type FormikProps, type FormikValues, isString } from 'formik-othebi';
import get from 'lodash/get';

type FormikWithoutNamespace<Values extends FormikValues = FormikValues> =
	FormikProps<Values>;

export interface FormikWithNamespace<Values extends FormikValues = FormikValues>
	extends FormikWithoutNamespace<Values> {
	getName: (name?: string) => string;
	getFullName: (name?: string) => string;
}

const useFormikNamespace = <
	Values extends FormikValues = FormikValues,
	SubValues extends FormikValues = FormikValues,
>(
	formik: FormikWithoutNamespace<Values> | FormikWithNamespace<Values>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	namespace?: any,
) => {
	const getName = useCallback(
		(name?: string) => {
			if (namespace === undefined) {
				return name || '';
			}
			return name ? `${namespace}.${name}` : namespace;
		},
		[namespace],
	);

	const getFullName = useCallback(
		(name?: string) => {
			if (namespace === undefined) {
				return name || '';
			}
			if ('getFullName' in formik && formik.getFullName) {
				return name
					? `${formik.getFullName(namespace)}.${name}`
					: formik.getFullName(namespace);
			}
			return getName(name);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		['getFullName' in formik && formik.getFullName, getName, namespace],
	);

	const setFieldValue = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(name: string, newVal: any, shouldValidate?: boolean) => {
			// May be called recursively
			return formik.setFieldValue(getName(name), newVal, shouldValidate);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.setFieldValue, getName],
	);

	const setFieldTouched = useCallback(
		(name: string, touched?: boolean, shouldValidate?: boolean) => {
			// May be called recursively
			return formik.setFieldTouched(getName(name), touched, shouldValidate);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.setFieldTouched, getName],
	);

	const handleChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(eventOrPath: string | React.ChangeEvent<any>) => {
			// https://github.com/jaredpalmer/formik/blob/master/packages/formik/src/Formik.tsx
			if (isString(eventOrPath)) {
				return (event) => formik.handleChange(getName(eventOrPath))?.(event);
			}
			return formik.handleChange(getName(eventOrPath.target.name))?.(
				eventOrPath,
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.handleChange, getName],
	);

	const handleBlur = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(eventOrPath: string | React.ChangeEvent<any>) => {
			if (isString(eventOrPath)) {
				// Recursively expand name
				return (event) => formik.handleBlur(getName(eventOrPath))?.(event);
			}
			return formik.handleBlur(getName(eventOrPath.target.name))?.(eventOrPath);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik.handleBlur, getName],
	);

	return useMemo(() => {
		return {
			...formik,
			getFullName,
			getName,
			setFieldTouched,
			setFieldValue,
			handleChange,
			handleBlur,
			values:
				namespace !== undefined
					? (get(
							formik.values,
							namespace,
					  ) as FormikWithoutNamespace<SubValues>['values'])
					: formik.values,
			// Note that formik uses {} even if SubValues is an array since it can still be treated the same way
			touched:
				namespace !== undefined
					? (get(
							formik.touched,
							namespace,
							{},
					  ) as FormikWithoutNamespace<SubValues>['touched'])
					: formik.touched,
			errors:
				namespace !== undefined
					? (get(
							formik.errors,
							namespace,
							{},
					  ) as FormikWithoutNamespace<SubValues>['errors'])
					: formik.errors,
		} as unknown as FormikWithNamespace<SubValues>;
	}, [
		formik,
		namespace,
		getFullName,
		getName,
		setFieldTouched,
		setFieldValue,
		handleChange,
		handleBlur,
	]);
};

const withFormikNamespace = <
	Props extends {
		formik: FormikWithNamespace<SubValues>;
	},
	Values extends FormikValues = FormikValues,
	SubValues extends FormikValues = FormikValues,
>(
	Component: React.ComponentType<Props>,
) => {
	return ({
		formik,
		namespace,
		...props
	}: Omit<Props, 'formik'> & {
		formik: FormikWithoutNamespace<Values> | FormikWithNamespace<Values>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		namespace?: any;
	}) => {
		const formikWithNamespace = useFormikNamespace<Values, SubValues>(
			formik,
			namespace,
		);

		const newProps = {
			formik: formikWithNamespace,
			...props,
		} as unknown as Props;

		return <Component {...newProps} />;
	};
};

export default withFormikNamespace;
