import React from 'react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import {
	Box,
	Stack,
	Typography,
	useMediaQuery,
	type Theme,
} from '@mui/material';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';

import { ArrowRightIcon } from '@ivy/components/icons';
import config from '@ivy/config';

interface PictureObj {
	id: string;
	publicUrl: string;
	filename: string;
}

interface GalleryProps<T extends PictureObj> {
	pictures: T[];
}

const Gallery = <T extends PictureObj>({ pictures }: GalleryProps<T>) => {
	const isLteMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	let firstPicture: T | undefined = undefined;
	let remainingPictures: T[] = [];
	let notPictures: T[] = [];

	if (pictures.length > 0) {
		const limit = isLteMd ? 5 : 6;
		firstPicture = pictures[0];
		remainingPictures = pictures.slice(1, limit);
		notPictures = pictures.filter(
			(picture) =>
				!remainingPictures.includes(picture) && picture !== firstPicture,
		);
	}

	if (!firstPicture) {
		return null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				'.lg-react-element': {
					display: 'flex',
					width: '100%',
					flexFlow: 'row wrap',
				},
				mx: '-4px',
				a: {
					'&:first-of-type': {
						width: '100%',
						px: '4px',
					},
					'&:not(:first-of-type)': {
						width: { md: '20%', xs: '25%' },
						px: '4px',
					},
					'&:hover': {
						opacity: 0.9,
						cursor: 'pointer',
						transition: 'opacity 0.1s linear',
					},
				},
			}}
		>
			<LightGallery
				licenseKey={config.lightGalleryKey}
				speed={500}
				plugins={[lgThumbnail, lgZoom]}
				autoplayFirstVideo={false}
				download={false}
				enableThumbDrag
				mobileSettings={{
					showCloseIcon: true,
					download: false,
					rotate: false,
				}}
			>
				<a key={firstPicture.id} href={firstPicture.publicUrl}>
					<Box
						display='flex'
						alignItems='center'
						mb={2}
						data-src={firstPicture.publicUrl}
					>
						{/* This is so that the thumbnail shows up */}
						<Box
							component='img'
							src={firstPicture.publicUrl}
							sx={{ display: 'none' }}
						/>
						<Box
							sx={{
								position: 'relative',
								width: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
								aspectRatio: '4/3',
							}}
						>
							<Box
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
									left: 0,
									bottom: 0,
									backgroundImage: `url(${firstPicture.publicUrl})`,
									backgroundPosition: 'center',
									backgroundSize: 'auto',
									backgroundRepeat: 'no-repeat',
									backgroundColor: 'black',
									borderRadius: '8px',
								}}
							/>
						</Box>
					</Box>
				</a>
				{remainingPictures.map((img, index) =>
					index === remainingPictures.length - 1 && notPictures.length > 0 ? (
						<a key={img.id} href={img.publicUrl}>
							<Box display='flex' alignItems='center' data-src={img.publicUrl}>
								{/* This is so that the thumbnail shows up */}
								<Box
									component='img'
									src={img.publicUrl}
									sx={{ display: 'none' }}
								/>
								<Box
									sx={{
										position: 'relative',
										width: '100%',
										boxSizing: 'border-box',
										overflow: 'auto',
										aspectRatio: '4/3',
										borderRadius: '8px',
									}}
								>
									<Box
										sx={{
											position: 'absolute',
											top: 0,
											right: 0,
											left: 0,
											bottom: 0,
											backgroundImage: `url(${img.publicUrl})`,
											backgroundPosition: 'center',
											backgroundSize: 'cover',
										}}
									/>
									<Box
										sx={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											backgroundColor: 'rgba(255, 255, 255, 0.60)',
											backdropFilter: 'blur(4.761904239654541px)',
										}}
									>
										<Stack
											direction='column'
											alignItems='center'
											width='50%'
											sx={{ color: 'primary.main' }}
										>
											<Typography
												variant='body1'
												textAlign='center'
												mb={1}
												sx={{ display: { xs: 'none', md: 'initial' } }}
											>
												See all {pictures.length} photos
											</Typography>
											<ArrowRightIcon />
										</Stack>
									</Box>
								</Box>
							</Box>
						</a>
					) : (
						<a key={img.id} href={img.publicUrl}>
							<Box display='flex' alignItems='center' data-src={img.publicUrl}>
								{/* This is so that the thumbnail shows up */}
								<Box
									component='img'
									src={img.publicUrl}
									sx={{ display: 'none' }}
								/>
								<Box
									sx={{
										position: 'relative',
										width: '100%',
										boxSizing: 'border-box',
										overflow: 'auto',
										aspectRatio: '4/3',
										borderRadius: '8px',
									}}
								>
									<Box
										sx={{
											position: 'absolute',
											top: 0,
											right: 0,
											left: 0,
											bottom: 0,
											backgroundImage: `url(${img.publicUrl})`,
											backgroundPosition: 'center',
											backgroundSize: 'cover',
										}}
									/>
								</Box>
							</Box>
						</a>
					),
				)}
				{notPictures.map((img) => (
					<a key={img.id} href={img.publicUrl}>
						{/* This is so that the thumbnail shows up */}
						<Box
							display='flex'
							alignItems='center'
							mb={2}
							data-src={img.publicUrl}
							sx={{ display: 'none' }}
						>
							<Box component='img' src={img.publicUrl} />
						</Box>
					</a>
				))}
			</LightGallery>
		</Box>
	);
};

export default Gallery;
