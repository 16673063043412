import React from 'react';

import { Backdrop, Box } from '@mui/material';

export interface ScreenProps {
	fullscreen?: boolean;
	transparent?: boolean;
	children: React.ReactNode;
}

const Screen = ({
	children,
	fullscreen = false,
	transparent = false,
}: ScreenProps) => {
	if (fullscreen) {
		return (
			<Backdrop
				open
				sx={{
					bgcolor: transparent
						? undefined
						: (theme) => theme.palette.background.default,
					zIndex: (theme) => theme.zIndex.drawer + 3,
					p: 2,
				}}
			>
				<Box>{children}</Box>
			</Backdrop>
		);
	}
	return (
		<Box
			p={2}
			width='100%'
			height='100%'
			display='flex'
			justifyContent='center'
			alignItems='center'
		>
			<Box>{children}</Box>
		</Box>
	);
};

export default Screen;
