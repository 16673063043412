import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingScreen from '@ivy/components/molecules/LoadingScreen';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const ConnectionShowRedirect_CandidateQDoc = gql(/* GraphQL */ `
	query ConnectionShowRedirect_Candidate($connectionId: uuid!) {
		candidate(
			where: { job_application_batches: { id: { _eq: $connectionId } } }
			limit: 1
		) {
			id
		}
	}
`);

const ConnectionRedirect = () => {
	const currAcc = useCurrentAccount();
	const { connectionId } = useParams();
	const { data } = useQuery(ConnectionShowRedirect_CandidateQDoc, {
		variables: {
			connectionId,
		},
		skip: !connectionId,
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (connectionId) {
			if (!data) {
				return;
			}
			if (data.candidate[0]?.id) {
				navigate(
					RouteUri.CANDIDATE_SHOW.replace(':candidateId', data.candidate[0].id),
				);
				return;
			}
		}
		navigate(
			currAcc
				? currAcc.isClinician
					? RouteUri.JOB_APPLICATION_LIST
					: currAcc.isOrgUser
					? RouteUri.CANDIDATE_LIST
					: RouteUri.ROOT
				: RouteUri.ROOT,
		);
	}, [connectionId, navigate, currAcc, data]);

	return <LoadingScreen variant='logo' fullscreen />;
};

export default ConnectionRedirect;
