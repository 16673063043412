import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			{...props}
		>
			<path
				d='M16.0002 3.33337C12.6408 3.33337 9.41894 4.66789 7.04348 7.04335C4.66802 9.41882 3.3335 12.6406 3.3335 16C3.3335 19.3594 4.66802 22.5813 7.04348 24.9567C9.41894 27.3322 12.6408 28.6667 16.0002 28.6667C19.3596 28.6667 22.5814 27.3322 24.9568 24.9567C27.3323 22.5813 28.6668 19.3594 28.6668 16C28.6668 12.6406 27.3323 9.41882 24.9568 7.04335C22.5814 4.66789 19.3596 3.33337 16.0002 3.33337Z'
				fill='#039BE5'
			/>
			<path
				d='M17.7145 19.3574H20.9925L21.5072 16.0274H17.7139V14.2074C17.7139 12.8241 18.1659 11.5974 19.4599 11.5974H21.5392V8.69139C21.1739 8.64206 20.4012 8.53406 18.9412 8.53406C15.8925 8.53406 14.1052 10.1441 14.1052 13.8121V16.0274H10.9712V19.3574H14.1052V28.5101C14.7259 28.6034 15.3545 28.6667 15.9999 28.6667C16.5832 28.6667 17.1525 28.6134 17.7145 28.5374V19.3574Z'
				fill='white'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
