import { createOneToOneOptions } from '@ivy/lib/formatting/filter';

const NOT_APPLICABLE = 'Not Applicable';
const DOES_NOT_PARTICIPATE = 'Does Not Participate';
const DOES_NOT_SPONSOR = 'Does Not Sponsor';

export const PROGRAM_LENGTH_OPTIONS = [3, 4];
export const RESIDENCY_PROGRAM_OPTS = PROGRAM_LENGTH_OPTIONS.map((el) => ({
	label: `${el} years`,
	value: `${el}`,
}));

export const SPECIALTY_OPTIONS = [
	'EM',
	'EM/IM',
	'EM/IM/CC',
	'EM/FM',
	'EM/Peds',
	'EM/Anesthesia',
];
export const SPECIALTY_OPTS = createOneToOneOptions(SPECIALTY_OPTIONS);

export const PRIMARY_TRAINING_SITE_OPTIONS = [
	'Community',
	'County',
	'University',
];
export const PRIMARY_TRAINING_SITE_OPTS = createOneToOneOptions(
	PRIMARY_TRAINING_SITE_OPTIONS,
);

export const SECONDARY_TRAINING_SITE_OPTIONS = [
	'Community',
	'County',
	'University',
	'Children',
	NOT_APPLICABLE,
];
export const SECONDARY_TRAINING_SITE_OPTS = createOneToOneOptions(
	SECONDARY_TRAINING_SITE_OPTIONS,
);

export const SHIFT_LENGTH_OPTIONS = ['9 or less', '10', '12', 'Variable'];
export const SHIFT_LENGTH_OPTS = createOneToOneOptions(SHIFT_LENGTH_OPTIONS);

export const SHIFTS_PER_MONTH_OPTIONS = [
	'15 or less',
	'16-18',
	'19-21',
	'22 or more',
];
export const SHIFTS_PER_MONTH_OPTS = createOneToOneOptions(
	SHIFTS_PER_MONTH_OPTIONS,
);

export const STEP_1_CUTOFF_OPTIONS = [
	'All Applicants Considered',
	'No Failures Accepted',
	'Declined to Disclose',
];
export const STEP_1_CUTOFF_OPTS = createOneToOneOptions(STEP_1_CUTOFF_OPTIONS);

export const USMLE_COMLEX_1_OPTIONS = [
	'USMLE Step 1 Required',
	'USMLE Step 1 or COMLEX Level 1 Accepted',
];
export const USMLE_COMLEX_1_OPTS = createOneToOneOptions(
	USMLE_COMLEX_1_OPTIONS,
);

export const USMLE_COMLEX_2_OPTIONS = [
	'USMLE Step 2 Required',
	'USMLE Step 2 or COMLEX Level 2 Accepted',
];
export const USMLE_COMLEX_2_OPTS = createOneToOneOptions(
	USMLE_COMLEX_2_OPTIONS,
);

export const PERCENT_OSTEOPATH_OPTIONS = [
	'0%',
	'1-10%',
	'11-20%',
	'21-40%',
	'41-60%',
	'61% or more',
];
export const PERCENT_OSTEOPATH_OPTS = createOneToOneOptions(
	PERCENT_OSTEOPATH_OPTIONS,
);

export const PERCENT_IMG_OPTIONS = [
	'0%',
	'1-10%',
	'11-20%',
	'21-40%',
	'41-60%',
	'61% or more',
];
export const PERCENT_IMG_OPTS = createOneToOneOptions(PERCENT_IMG_OPTIONS);

export const MILITARY_BRANCH_OPTIONS = [
	'Air Force',
	'Army',
	'Army and Air Force',
	'Navy',
	DOES_NOT_PARTICIPATE,
];
export const MILITARY_BRANCH_OPTS = createOneToOneOptions(
	MILITARY_BRANCH_OPTIONS,
);

export const VISA_SPONSORSHIP_OPTIONS = [
	'J-1',
	'H-1B',
	'Other',
	DOES_NOT_SPONSOR,
];

export const VISA_SPONSORSHIP_OPTS = createOneToOneOptions(
	VISA_SPONSORSHIP_OPTIONS,
);

export const PGY1_PERCENT_OPTS = [
	{
		label: '0%',
		value: '0',
	},
	{
		label: '20%',
		value: '20',
	},
	{
		label: '40%',
		value: '40',
	},
	{
		label: '60%',
		value: '60',
	},
	{
		label: '80%',
		value: '80',
	},
	{
		label: '90%',
		value: '90',
	},
];

const AVAILABLE_INTERN_POSITIONS = ['0', '5', '10', '15', '20', '25', '30'];
export const AVAILABLE_INTERN_POSITIONS_OPTS = createOneToOneOptions(
	AVAILABLE_INTERN_POSITIONS,
);

const ELECTIVE_WEEKS = ['0', '5', '10', '15', '50'];
export const ELECTIVE_WEEKS_OPTS = createOneToOneOptions(ELECTIVE_WEEKS);

const CRITICAL_CARE_TRAINING_WEEKS = ['0', '15', '20', '25', '50'];
export const CRITICAL_CARE_TRAINING_WEEKS_OPTS = createOneToOneOptions(
	CRITICAL_CARE_TRAINING_WEEKS,
);

export const SLOE_FOR_INTERVIEW_OPTS = [
	{
		label: 'None',
		value: '0',
	},
	{
		label: 'One',
		value: '1',
	},
	{
		label: 'Two',
		value: '2',
	},
];
