import React, { useCallback } from 'react';

import { Box, type SxProps, type Theme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DisclaimerToast from '@ivy/components/molecules/DisclaimerToast';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';

import ReportCorrection from './ReportCorrection';

const CorrectionToast_FacilityFDoc = gql(/* GraphQL */ `
	fragment CorrectionToast_Facility on facility {
		id
		...ReportCorrection_Facility
	}
`);

export interface CorrectionToastProps {
	onClickClose?: () => void;
	facility: FragmentType<typeof CorrectionToast_FacilityFDoc>;
	sx?: SxProps<Theme>;
}

const REPORT_QUERY_PARAM = 'showReportIncorrect';

const CorrectionToast = ({
	facility: rawFacility,
	sx,
}: CorrectionToastProps) => {
	const facility = getFragmentData(CorrectionToast_FacilityFDoc, rawFacility);
	const currAcc = useCurrentAccount();
	const [searchParams, setSearchParams] = useSearchParams();
	const showCorrection = searchParams.has(REPORT_QUERY_PARAM);

	const handleOpenCorrection = useCallback(() => {
		if (searchParams.has(REPORT_QUERY_PARAM)) {
			return;
		}
		searchParams.append(REPORT_QUERY_PARAM, 'default');
		setSearchParams(searchParams, {
			replace: true,
		});
	}, [searchParams, setSearchParams]);

	const handleCloseCorrection = useCallback(() => {
		if (!searchParams.has(REPORT_QUERY_PARAM)) {
			return;
		}
		searchParams.delete(REPORT_QUERY_PARAM);
		setSearchParams(searchParams, {
			replace: true,
		});
	}, [searchParams, setSearchParams]);

	return (
		<>
			<DisclaimerToast sx={sx}>
				💡 Facilities and contracts change frequently.{' '}
				{currAcc?.isOrgUser ? (
					<>
						Add or remove facilities from your group on the{' '}
						<RouteLink
							to={RouteLink.routes.MANAGEMENT_FACILITY}
							state={{
								backNav: {
									target: 'facility',
								},
							}}
						>
							facilities page
						</RouteLink>{' '}
						or{' '}
						<Box
							component='span'
							sx={{
								color: (theme) => theme.palette.primary.main,
								textDecoration: 'underline',
								textDecorationColor: 'rgba(38, 130, 197, 0.4)',
								cursor: 'pointer',
							}}
							onClick={handleOpenCorrection}
						>
							report incorrect information.
						</Box>
					</>
				) : (
					<>
						<Box
							component='span'
							sx={{
								color: (theme) => theme.palette.primary.main,
								textDecoration: 'underline',
								textDecorationColor: 'rgba(38, 130, 197, 0.4)',
								cursor: 'pointer',
							}}
							onClick={handleOpenCorrection}
						>
							Click here
						</Box>{' '}
						to correct the information.
					</>
				)}
			</DisclaimerToast>
			{showCorrection && (
				<ReportCorrection
					open
					onClose={handleCloseCorrection}
					facility={facility}
					defaultSelection={searchParams.get(REPORT_QUERY_PARAM) || undefined}
				/>
			)}
		</>
	);
};

export default CorrectionToast;
