import React from 'react';

import { Box, type SxProps, type Theme, Typography } from '@mui/material';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { ArrowRightIcon } from '@ivy/components/icons';
import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';
import { type WhitelabelTo } from '@ivy/lib/util/route';

interface FeatureShowcaseProps {
	image: string;
	alt?: string;
	headline: string;
	description: string;
	iconColor?: string;
	link: WhitelabelTo;
}

const FeatureShowcase = ({
	image,
	alt,
	headline,
	description,
	iconColor,
	link,
}: FeatureShowcaseProps) => {
	return (
		<Box>
			<Box
				component='img'
				src={image}
				alt={alt}
				sx={{
					width: '100%',
					mb: 5,
				}}
			/>
			<Typography
				variant='h6'
				component='h3'
				sx={{
					mb: 3,
				}}
			>
				<RouteLink
					to={link}
					underline='hover'
					sx={{
						color: 'inherit',
						display: 'inline-flex',
						alignItems: 'center',
					}}
				>
					<Box component='span'>{headline}</Box>
					<ArrowRightIcon
						sx={{
							color: iconColor,
							fontSize: '1em',
							ml: 1,
						}}
					/>
				</RouteLink>
			</Typography>
			<Typography variant='body1' color='text.icon'>
				{description}
			</Typography>
		</Box>
	);
};

interface CommunityProps {
	salaryCount: number;
	sx?: SxProps<Theme>;
}

const Community = ({ salaryCount, sx }: CommunityProps) => {
	return (
		<Box
			component='section'
			sx={combineSx(
				(theme) => ({
					[theme.breakpoints.down('md')]: {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 5,
					},
					[theme.breakpoints.up('md')]: {
						display: 'grid',
						gridTemplateColumns: 'repeat(2, 1fr)',
						gridTemplateRows: 'auto auto',
						alignItems: 'center',
						columnGap: 5,
						rowGap: 8,
					},
				}),
				sx,
			)}
		>
			<Typography
				variant='h2'
				textAlign={{
					xs: 'center',
					md: 'initial',
				}}
			>
				Discover how you can support your community 🫶
			</Typography>
			<Typography
				variant='h6'
				component='p'
				sx={{
					fontWeight: (theme) => theme.typography.body1.fontWeight,
					color: 'text.icon',
					textAlign: {
						xs: 'center',
						md: 'initial',
					},
				}}
			>
				Clinicians are using Ivy to share their insights into compensation,
				employers, and facilities. Use real experiences from your peers to
				navigate your next job search with confidence.
			</Typography>
			<FeatureShowcase
				image='https://assets.ivyclinicians.io/images/for-clinicians-salary-preview.png'
				headline='Compare your salary'
				description={`Access over ${formatInteger(
					salaryCount,
				)} reported clinician salaries by sharing your salary anonymously. Your info helps your peers get paid fairly.`}
				iconColor='primary.main'
				link={RouteLink.routes.SALARY}
			/>
			<FeatureShowcase
				image='https://assets.ivyclinicians.io/images/for-clinicians-review-preview.png'
				headline='Review employers and facilities'
				description='Share valuable insights and experiences about your job to help fellow clinicians make an informed decision about potential employers.'
				iconColor='secondary.main'
				link={RouteLink.routes.REVIEW}
			/>
		</Box>
	);
};

export default Community;
