import React from 'react';

const NoIcon = ({ ...props }) => {
	return (
		<svg
			width='32'
			height='34'
			viewBox='0 0 32 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect
				width='7'
				height='16'
				transform='matrix(1 0 0 -1 0 18.001)'
				fill='currentColor'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 31.4998C17.5 27.9986 8.5 18.4998 8.5 18.4998V1.99979C8.5 1.50098 12.5 0.000976562 14 -0.000217438C15.4738 -0.00139236 25.6355 0.5145 25.9905 0.532543C26.1568 0.511749 26.3269 0.50098 26.5 0.50098C28.433 0.50098 30 1.84412 30 3.50098C30 4.17334 29.7419 4.79404 29.3061 5.29429C30.8922 5.83546 32 7.06759 32 8.50098C32 9.687 31.618 10.9235 30.7487 11.702C31.5137 12.2523 32 13.078 32 14.001C32 14.9444 31.492 16.1103 30.6976 16.9394C31.4842 17.3901 32 18.1454 32 19.001C32 20.3817 30.6569 21.501 29 21.501C28.9224 21.501 28.8455 21.4985 28.7694 21.4937C28.6834 21.4978 28.5937 21.4998 28.5 21.4998C28.0789 21.4998 27.3625 21.4603 26.533 21.4146L26.5329 21.4146L26.5327 21.4145L26.5326 21.4145C24.4075 21.2974 21.5411 21.1393 21 21.4998C20.6892 21.7069 20.7446 22.8405 20.8184 24.3514C20.9232 26.4977 21.0653 29.4053 20.2477 31.4998C19.7865 32.6813 16.5 35.001 17 31.4998Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default NoIcon;
