import React from 'react';

import { Box, type SxProps, type Theme } from '@mui/material';
import isEqual from 'lodash/isEqual';

import LoadingOverlay from '@ivy/components/molecules/LoadingOverlay';
import { combineSx } from '@ivy/lib/styling/sx';

import { type MapListItemObject } from './common';
import EntityResultsList, {
	type EntityResultsListProps,
} from './EntityResultsList';
import { type NearbyMapListItemObject } from './NearbyCarousel';

export interface DesktopSearchResultsProps<T, V>
	extends Omit<EntityResultsListProps<T, V>, 'sx'> {
	open: boolean;
	sx?: SxProps<Theme>;
	loading?: boolean;
}

const DesktopSearchResults = <
	T extends MapListItemObject,
	V extends NearbyMapListItemObject,
>({
	open,
	loading,
	sx,
	...props
}: DesktopSearchResultsProps<T, V>) => {
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
				},
				sx,
			)}
		>
			<LoadingOverlay
				loading={loading}
				sx={{
					zIndex: 1,
				}}
			/>
			{open && (
				// Use conditional render for performance
				<EntityResultsList {...props} />
			)}
		</Box>
	);
};

export default React.memo(DesktopSearchResults, (prev, next) =>
	isEqual(prev, next),
) as typeof DesktopSearchResults;
