import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { Role } from '@ivy/constants/account';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { getFragmentData, gql } from '@ivy/gql/types';
import { useNotifyError } from '@ivy/lib/hooks';

import BillingContext from './BillingContext';

export interface BillingProps {
	children?: React.ReactNode;
}

export const BillingProvider_SubscriptionFDoc = gql(/* GraphQL */ `
	fragment BillingProvider_Subscription on CustomerSubscriptionGQL {
		id
		active
		cancelAt: cancel_at
		interval
		slots
		status
		tier
		discounted
		usedSlots: used_slots
		invoice {
			id
			invoiceDate: invoice_date
			paymentIntent: payment_intent
			paymentStatus: payment_status
			status
			subtotal
			total
		}
	}
`);

export const BillingProvider_CustomerFDoc = gql(/* GraphQL */ `
	fragment BillingProvider_Customer on CustomerGQL {
		address1: address_1
		address2: address_2
		balance
		city
		defaultPaymentId: default_payment_id
		checkoutBankHold: checkout_bank_hold
		email
		fullName: full_name
		id
		state
		zipcode
		subscription {
			...BillingProvider_Subscription
		}
		paymentMethods: payment_methods {
			id
			createdAt: created_at
			type
			usBank: us_bank {
				accountType: account_type
				bankName: bank_name
				last4: last_4
			}
			card {
				brand
				expMonth: exp_month
				expYear: exp_year
				last4: last_4
			}
		}
	}
`);

const BillingProvider_GetSubscriptionQDoc = gql(/* GraphQL */ `
	query BillingProvider_GetSubscription($orgId: uuid!) {
		organization: organization_by_pk(id: $orgId) {
			id
			customer {
				...BillingProvider_Customer
			}
		}
		orgAdmins: org_user_aggregate(
			where: {
				org_id: { _eq: $orgId }
				account: {
					access_info: { active: { _eq: true } }
					roles: { role: { _eq: "ORG_ADMIN" } }
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`);

const BillingProvider = ({ children }: BillingProps) => {
	const currAcc = useCurrentAccount();

	const orgAdmin = !!currAcc?.roles.some((el) => el.role === Role.ORG_ADMIN);

	const { data, loading, error, refetch } = useQuery(
		BillingProvider_GetSubscriptionQDoc,
		{
			variables: {
				orgId: currAcc?.orgUser?.org.id,
			},
			fetchPolicy: 'network-only',
			skip: !currAcc,
		},
	);
	useNotifyError(error);

	const numOfAdmins = useMemo(() => {
		if (!data) return 0;
		return data.orgAdmins.aggregate?.count || 0;
	}, [data]);

	// Only people that can create a subscription are admins or anyone in the group if there are no admins, in which
	// case they will be granted the admin role
	const adminAccess = !numOfAdmins || orgAdmin;

	const customer = data?.organization?.customer
		? getFragmentData(
				BillingProvider_CustomerFDoc,
				data?.organization?.customer,
		  )
		: null;

	const subscription = customer?.subscription
		? getFragmentData(BillingProvider_SubscriptionFDoc, customer.subscription)
		: null;

	const billingProviderValue = useMemo(() => {
		return {
			data: data || null,
			subscription: subscription,
			customer: customer,
			adminAccess: adminAccess,
			isOrgAdmin: orgAdmin,
			loading: loading,
			error: error,
			refetch: refetch,
		};
	}, [
		data,
		customer,
		subscription,
		refetch,
		loading,
		error,
		adminAccess,
		orgAdmin,
	]);

	return (
		<BillingContext.Provider value={billingProviderValue}>
			{children}
		</BillingContext.Provider>
	);
};

export default BillingProvider;
