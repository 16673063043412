import React, { useState, useMemo } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';

import {
	HighIcon,
	MediumIcon,
	LowIcon,
	VeryHighIcon,
} from '@ivy/components/icons';
import {
	ED_VOLUME_RANGE,
	ED_VOLUME_VERBOSE,
	EdVolume,
} from '@ivy/constants/facility';
import { usePrevious } from '@ivy/lib/hooks';

interface OptionProps {
	selected: boolean;
	icon: React.ReactNode;
	label: string;
	selectedLabel: string;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

// TODO: evenly space like AirBnb, see mobile view as well
const Option = ({
	selected,
	icon,
	label,
	selectedLabel,
	onClick,
}: OptionProps) => {
	const [hovering, setHovering] = useState(false);
	const prevSelected = usePrevious(selected);

	const shouldHighlight = useMemo(() => {
		// This fixes behavior on mobile where an element stays highlighted after deselecting it since it's technically
		// still being hovered
		// Not hovering or just turned off the option, don't highlight
		return hovering && (selected || !prevSelected);
	}, [prevSelected, selected, hovering]);

	return (
		<Box
			component='button'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '132px',
				color:
					shouldHighlight || selected ? 'primary.main' : 'rgba(161, 161, 161)',
				borderStyle: 'solid',
				borderWidth: '2px',
				borderColor:
					shouldHighlight || selected ? 'primary.main' : 'text.disabled',
				borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				bgcolor: selected ? 'primary.translucent' : '#fff',
				py: 2,
				px: 0,
				cursor: 'pointer',
			}}
			onClick={onClick}
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
		>
			{icon}
			<Typography variant='body1' fontWeight='bold' color='inherit' mt={2}>
				{shouldHighlight || selected ? selectedLabel : label}
			</Typography>
		</Box>
	);
};

export const VOLUME_OPTIONS = [
	{
		label: ED_VOLUME_VERBOSE[EdVolume.LOW],
		selectedLabel: ED_VOLUME_RANGE[EdVolume.LOW],
		value: EdVolume.LOW,
		icon: <LowIcon />,
	},
	{
		label: ED_VOLUME_VERBOSE[EdVolume.MEDIUM],
		selectedLabel: ED_VOLUME_RANGE[EdVolume.MEDIUM],
		value: EdVolume.MEDIUM,
		icon: <MediumIcon />,
	},
	{
		label: ED_VOLUME_VERBOSE[EdVolume.HIGH],
		selectedLabel: ED_VOLUME_RANGE[EdVolume.HIGH],
		value: EdVolume.HIGH,
		icon: <HighIcon />,
	},
	{
		label: ED_VOLUME_VERBOSE[EdVolume.VERY_HIGH],
		selectedLabel: ED_VOLUME_RANGE[EdVolume.VERY_HIGH],
		value: EdVolume.VERY_HIGH,
		icon: <VeryHighIcon />,
	},
];

export interface VolumeFilterOptionsProps {
	value: string[];
	onChange: (ev: React.SyntheticEvent, nv: string[]) => void;
}

const VolumeFilterOptions = ({ value, onChange }: VolumeFilterOptionsProps) => {
	const handleClick = (
		ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		optionValue: EdVolume,
	) => {
		const arr = value.slice();
		const index = arr.indexOf(optionValue);
		if (index !== -1) {
			arr.splice(index, 1);
		} else {
			arr.push(optionValue);
		}
		onChange(ev, arr);
	};

	return (
		<Grid container spacing={1} wrap='wrap'>
			<Grid item>
				<Stack direction='row' spacing={1}>
					{VOLUME_OPTIONS.slice(0, 2).map(
						({ label, selectedLabel, value: optionValue, icon }) => (
							<Option
								key={optionValue}
								label={label}
								selectedLabel={selectedLabel}
								onClick={(ev) => handleClick(ev, optionValue)}
								icon={icon}
								selected={value.indexOf(optionValue) !== -1}
							/>
						),
					)}
				</Stack>
			</Grid>
			<Grid item>
				<Stack direction='row' spacing={1}>
					{VOLUME_OPTIONS.slice(2, 4).map(
						({ label, selectedLabel, value: optionValue, icon }) => (
							<Option
								key={optionValue}
								label={label}
								selectedLabel={selectedLabel}
								onClick={(ev) => handleClick(ev, optionValue)}
								icon={icon}
								selected={value.indexOf(optionValue) !== -1}
							/>
						),
					)}
				</Stack>
			</Grid>
		</Grid>
	);
};

export default VolumeFilterOptions;
