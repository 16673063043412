import React from 'react';

import { SvgIcon } from '@mui/material';

const VimeoLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			{...props}
		>
			<path
				d='M30 9.53337C29.8667 12.2667 28 15.9334 24.2667 20.6C20.4667 25.5334 17.2667 27.9334 14.6 27.9334C13 27.9334 11.6 26.4667 10.4667 23.4667C9.73333 20.7334 8.93333 18 8.2 15.2667C7.33333 12.2667 6.46667 10.8 5.53333 10.8C5.33333 10.8 4.6 11.1334 3.33333 12L2 10.4667C3.4 9.26671 4.66667 8.40004 6 7.20004C7.86667 5.60004 9.2 4.80004 10.1333 4.73337C12.3333 4.53337 13.6667 6.00004 14.1333 9.13337C14.6667 12.5334 15.1333 14.3334 15.3333 15.1334C15.9333 17.9334 16.6667 19.4 17.4 19.4C18 19.4 18.8667 18.4667 20.0667 16.6667C21.2667 14.8 21.8667 13.4667 21.9333 12.4667C22.1333 10.8667 21.4667 10.0667 20.0667 10.0667C19.4 10.0667 18.6667 10.4 17.9333 10.6667C19.3333 6.20004 22 3.86671 25.8667 4.00004C28.8 4.06671 30.1333 5.93337 30 9.53337Z'
				fill='#29B6F6'
			/>
		</SvgIcon>
	);
};

export default VimeoLinkIcon;
