import React from 'react';

import { renameLabel, SubStep } from '@ivy/components/organisms/FormPopup';
import {
	formatForm,
	type FormStepProps,
	type SubStepObj,
} from '@ivy/lib/forms/formFormatHelpers';

import { type ReviewFormValues, type ExtraParams } from '../reviewForm';

import Employer, {
	type EmployerValues,
	validation as employerValidation,
	initialValue as employerInitialValue,
} from './Employer';
import Facility, {
	type FacilityValues,
	validation as facilityValidation,
	initialValue as facilityInitialValue,
} from './Facility';
import Identity, {
	type IdentityValues,
	validation as idValidation,
	initialValue as idInitialValue,
} from './Identity';
import Job, {
	type JobValues,
	validation as jobValidation,
	initialValue as jobInitialValue,
} from './Job';
import Status, {
	type StatusValues,
	validation as statusValidation,
	initialValue as statusInitialValue,
} from './Status';

type GeneralStepValues = IdentityValues &
	FacilityValues &
	EmployerValues &
	JobValues &
	StatusValues;

const steps: SubStepObj<ReviewFormValues, ExtraParams>[] = [
	{
		key: 'identity',
		label: (values, extra) =>
			renameLabel(
				'Review author identity',
				values.isAnonymous === null
					? undefined
					: values.isAnonymous
					? 'Anonymous'
					: extra.fullName,
				true,
			),
		component: Identity,
		validate: idValidation,
		initialValue: idInitialValue,
	},
	{
		key: 'facility',
		label: (values) =>
			renameLabel('Facility name', values.facility?.name, true),
		component: Facility,
		validate: facilityValidation,
		initialValue: facilityInitialValue,
	},
	{
		key: 'employer',
		label: (values) => renameLabel('Employer name', values.org?.name, true),
		component: Employer,
		validate: employerValidation,
		initialValue: employerInitialValue,
	},
	{
		key: 'job',
		label: (values) =>
			renameLabel('Overall job rating', values.jobRating, true),
		component: Job,
		validate: jobValidation,
		initialValue: jobInitialValue,
	},
	{
		key: 'status',
		label: (values) =>
			renameLabel(
				'Employment status',
				values.isCurrentlyWorking === null
					? undefined
					: values.isCurrentlyWorking
					? 'Currently working'
					: 'Previous employee',
				true,
			),
		component: Status,
		validate: statusValidation,
		initialValue: statusInitialValue,
	},
];

const { formSteps, initialValues, validation } = formatForm(steps);
const GeneralStep = (props: FormStepProps<ReviewFormValues, ExtraParams>) => (
	<SubStep {...props} subSteps={formSteps} />
);
export default GeneralStep;
export { type GeneralStepValues, validation, initialValues, formSteps };
