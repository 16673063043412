import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Paper,
	Stack,
	Button,
	Box,
	IconButton,
	Typography,
	type LinearProgressProps,
	type Theme,
	LinearProgress,
	linearProgressClasses,
	type ButtonProps,
	type SxProps,
} from '@mui/material';
import { type TooltipRenderProps } from 'react-joyride';

interface TooltipProgressBarProps extends LinearProgressProps {
	stepIndex: number;
	size: number;
	sx?: SxProps<Theme>;
}

const TooltipProgressBar = ({
	stepIndex,
	size,
	sx,
	...props
}: TooltipProgressBarProps) => {
	const tourSize = size - 1;
	const currentStep = stepIndex;
	const currentProgress = (currentStep / tourSize) * 100;

	return (
		<Box display='flex' flexDirection='row' alignItems='center' sx={sx}>
			<Typography
				variant='caption'
				sx={{ mr: 1 }}
			>{`${currentStep}/${tourSize}`}</Typography>
			<LinearProgress
				variant='determinate'
				value={currentProgress}
				sx={{
					flex: 1,
					height: { xs: 8, md: 16 },
					borderRadius: 4,
					backgroundColor: (theme) => theme.palette.light1.main,
					[`& .${linearProgressClasses.bar}`]: {
						borderRadius: 4,
						bgcolor: (theme) => theme.palette.tertiary.main,
					},
				}}
				{...props}
			/>
		</Box>
	);
};

interface BaseTooltipProps extends TooltipRenderProps {
	progressBarProps?: TooltipProgressBarProps;
}

const BaseTooltip = ({
	backProps,
	closeProps,
	continuous,
	index,
	primaryProps,
	skipProps,
	step,
	tooltipProps,
	size,
	isLastStep,
	progressBarProps,
}: BaseTooltipProps) => {
	const buttonProps: ButtonProps = {
		size: 'large',
		sx: { flex: 1, py: { md: 1.5, xs: 1 } },
		color: 'tertiary',
	};
	return (
		<Paper sx={{ minWidth: { xs: '300px', md: '560px' } }} {...tooltipProps}>
			<Box
				display='flex'
				justifyContent='flex-end'
				sx={{ pt: 1, px: 1, pb: 0 }}
			>
				<IconButton {...closeProps}>
					<CloseIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					pt: { xs: 0, sm: 1, md: 3 },
					pb: { xs: 2, sm: 4, md: 7 },
					px: { xs: 1, sm: 3, md: 10 },
				}}
			>
				{step.title && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							mb: { xs: 1, md: 2 },
						}}
					>
						{step.title}
					</Box>
				)}
				<Box>{step.content}</Box>
				<Stack
					direction='column'
					spacing={{ xs: 1, md: 4 }}
					sx={{ mt: { xs: 3, md: 6 } }}
					alignItems='center'
				>
					{index > 0 && (
						<TooltipProgressBar
							stepIndex={index}
							size={size}
							sx={{ maxWidth: { xs: 'none', md: '300px' }, width: '100%' }}
							{...progressBarProps}
						/>
					)}
					<Stack
						direction={{ xs: 'column', md: 'row' }}
						spacing={{ xs: 1, md: 2 }}
						sx={{ maxWidth: { xs: 'none', md: '480px' }, width: '100%' }}
					>
						{index === 0 && (
							<Button {...buttonProps} variant='outlined' {...skipProps}>
								Skip tour
							</Button>
						)}
						{index > 1 && (
							<Button {...buttonProps} variant='outlined' {...backProps}>
								{backProps.title}
							</Button>
						)}
						{continuous && (
							<Button {...buttonProps} variant='contained' {...primaryProps}>
								{index === 0
									? 'Take a quick tour'
									: isLastStep
									? 'End tour'
									: primaryProps.title}
							</Button>
						)}
					</Stack>
				</Stack>
			</Box>
		</Paper>
	);
};

export default BaseTooltip;
