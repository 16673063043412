import React from 'react';

import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Typography,
	Divider,
	Avatar,
	Stack,
	Rating,
	CardActionArea,
} from '@mui/material';

import RouteLink, {
	type RouteLinkProps,
} from '@ivy/components/atoms/RouteLink';
import { formatInteger } from '@ivy/lib/formatting/number';

interface infoObj {
	iconComp: React.ReactNode;
	text: string;
}

interface SnippetCardProps extends RouteLinkProps {
	title?: string;
	subtitle?: string | null;
	rating?: number;
	ratingCount?: number;
	image?: string;
	logo?: string;
	captionText?: string;
	onClick?: () => void;
	infoArray?: infoObj[];
}

const SnippetCard = ({
	title,
	subtitle,
	rating,
	ratingCount,
	image,
	logo,
	captionText,
	onClick,
	infoArray,
	...props
}: SnippetCardProps) => {
	return (
		<Card
			sx={{
				bgcolor: 'white',
				borderRadius: '24px',
				boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
				'&:hover': {
					bgcolor: 'light2.main',
					cursor: 'pointer',
					transform: 'scale(1.01)',
					'.chips::before': {
						background: (theme) =>
							`linear-gradient(to left, ${theme.palette.light2.main}, transparent 20px)`,
					},
				},
			}}
			onClick={onClick}
		>
			<CardActionArea component={RouteLink} {...props}>
				{!!image && <CardMedia sx={{ height: '149px' }} image={image} />}
				<CardContent sx={{ p: 3 }}>
					<Stack spacing={1}>
						<Stack direction='row'>
							{logo && (
								<Avatar
									sx={{ width: '40px', height: '40px', mr: 1 }}
									src={logo}
								/>
							)}
							<Box
								sx={{
									flex: 1,
									width: logo || captionText ? 'calc(80% - 40px)' : '100%',
								}}
							>
								<Stack direction='column'>
									<Typography
										variant='body1'
										fontWeight='bold'
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{title}
									</Typography>
									{!!subtitle && (
										<Typography
											variant='body2'
											fontWeight='bold'
											color='text.icon'
											sx={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											{subtitle}
										</Typography>
									)}
								</Stack>
								<Stack direction='row' alignItems='center' spacing={1}>
									<Rating
										size='small'
										defaultValue={rating}
										precision={0.1}
										readOnly
										sx={{ fontSize: '18px' }}
									/>
									<Typography
										variant='caption'
										color='primary.main'
										sx={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										{formatInteger(ratingCount || 0)}{' '}
										{ratingCount === 1 ? 'review' : 'reviews'}
									</Typography>
								</Stack>
							</Box>
							{captionText && (
								<Box sx={{ ml: 'auto' }}>
									<Typography
										variant='caption'
										fontWeight='bold'
										ml={1}
										textAlign='right'
									>
										{captionText}
									</Typography>
								</Box>
							)}
						</Stack>
						<Divider flexItem variant='middle' />
						{!!infoArray?.length && (
							<Stack
								direction={{ lg: 'row', xs: 'column' }}
								justifyContent={{
									lg: 'space-evenly',
								}}
								spacing={1}
							>
								{infoArray.map((info, index, self) => (
									<React.Fragment key={index}>
										<Box
											sx={{
												display: 'flex',
												justifyContent: {
													lg: 'center',
												},
												alignItems: 'center',
												flex: `1 1 ${100 / self.length}%`,
												overflow: 'hidden',
											}}
										>
											<Typography
												variant='body2'
												color='text.icon'
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}
												noWrap
											>
												{info.iconComp}
												<Typography
													component='span'
													variant='inherit'
													noWrap
													sx={{
														ml: 0.5,
													}}
												>
													{info.text}
												</Typography>
											</Typography>
										</Box>
										{index < infoArray.length - 1 && (
											<Box
												sx={{
													flex: '0 0',
													display: {
														xs: 'hidden',
														lg: 'initial',
													},
												}}
											>
												<Divider orientation='vertical' />
											</Box>
										)}
									</React.Fragment>
								))}
							</Stack>
						)}
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default SnippetCard;
