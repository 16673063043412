import React from 'react';

import { Box, Link, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import { type ApplyToJobExtra } from '@ivy/components/organisms/ApplyToJobPopup/applyToJobForm';
import { type MessageAndCvStepValues } from '@ivy/components/organisms/ApplyToJobPopup/MessageAndCvStep/MessageAndCvStep';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { getInputProps } from '@ivy/lib/forms/formikHelpers';
import { prepareCssContentProperty } from '@ivy/lib/styling/sx';

export interface MessageSubStepValues {
	message: string;
}

export const validation = yup.object({
	message: yup.string().max(3000).required().label('Message'),
});

export const initialValue = {
	message: '',
};

const MessageSubStep = ({
	formik,
	onNext,
	extra,
}: SubFormStepProps<MessageAndCvStepValues, ApplyToJobExtra>) => {
	const { contracts, prevIntro, multiFac, multiRec } = extra;
	const currAcc = useCurrentAccount();
	const charLeft = Math.max(3000 - formik.values.message.length, 0);
	const signature = `${currAcc?.pi?.fullName}, ${currAcc?.clinician?.creds}`;
	return (
		<Box>
			<Typography
				variant='body2'
				color='text.icon'
				sx={{
					mt: 2,
				}}
			>
				Send a personalized message introducing yourself to the{' '}
				{multiRec ? 'recruiters' : 'recruiter'} for{' '}
				{multiFac ? 'these facilities' : 'this facility'}.
			</Typography>
			{!!prevIntro && (
				<Typography
					variant='body2'
					sx={{
						mt: 1,
					}}
				>
					<Link
						underline='hover'
						onClick={() => formik.setFieldValue('message', prevIntro)}
						sx={{
							cursor: 'pointer',
						}}
					>
						Click here to paste the message from your last job application.
					</Link>
				</Typography>
			)}
			<TextField
				fullWidth
				placeholder='Write your message here'
				multiline
				rows={8}
				{...getInputProps(formik, 'message')}
				sx={{
					mt: 5,
					'& .MuiInputBase-root': {
						display: 'block',
					},
					'& .MuiInputBase-root::before': {
						// Escape any quotations in content
						content: multiRec
							? '"Hello,"'
							: prepareCssContentProperty(
									`Hello ${contracts[0].recruiter.account.pi?.fullName},`,
							  ),
						fontWeight: 'bold',
					},
					'& .MuiInputBase-root::after': {
						whiteSpace: 'pre-wrap',
						// Include spaces between \\A and adjacent words to prevent more concat to other special
						// characters, e.g. "\\ADwight" is displayed as "wight". Whitespace is collapsed by default.
						content: prepareCssContentProperty(`Sincerely,\n${signature}`),
						fontWeight: 'bold',
					},
				}}
			/>
			<Typography variant='caption' align='right' sx={{ mt: 1 }}>
				{charLeft} {charLeft === 1 ? 'character' : 'characters'} left
			</Typography>
			<NavButton variant='text' onClick={onNext}>
				Next ⏎
			</NavButton>
		</Box>
	);
};

export default MessageSubStep;
