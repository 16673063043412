import React, { createContext, useEffect, useState } from 'react';

export interface AdContextValue {
	apiKey?: string;
	disabled: boolean;
}

export const AdContext = createContext<AdContextValue>(undefined!);

export interface AdProviderProps {
	apiKey?: string;
	disabled?: boolean;
	children?: React.ReactNode;
}

const AdProvider = ({
	apiKey,
	disabled = false,
	children,
}: AdProviderProps) => {
	const [init, setInit] = useState(false);

	useEffect(() => {
		if (apiKey && !disabled) {
			window.TS = {
				token: apiKey,
				url: 'https://api.topsort.com',
			};
		}
		setInit(true);
	}, [apiKey, disabled, setInit]);

	useEffect(() => {
		if (!init || disabled) {
			return;
		}
		import('@topsort/analytics.js');
	}, [init, disabled]);

	return (
		<AdContext.Provider value={{ apiKey, disabled }}>
			{children}
		</AdContext.Provider>
	);
};

export default AdProvider;
