import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const OwnershipIcon = ({ sx, ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			sx={combineSx(
				{
					fill: 'transparent',
				},
				sx,
			)}
			{...props}
		>
			<rect width='40' height='40' rx='4' fill='#1EC86A' fillOpacity='0.1' />
			<mask id='path-2-inside-1_505_51063' fill='white'>
				<rect x='15.832' y='9' width='8.33333' height='8.33333' rx='1' />
			</mask>
			<rect
				x='15.832'
				y='9'
				width='8.33333'
				height='8.33333'
				rx='1'
				stroke='#1EC86A'
				strokeWidth='4'
				mask='url(#path-2-inside-1_505_51063)'
			/>
			<mask id='path-3-inside-2_505_51063' fill='white'>
				<rect x='10' y='23.166' width='8.33333' height='8.33333' rx='1' />
			</mask>
			<rect
				x='10'
				y='23.166'
				width='8.33333'
				height='8.33333'
				rx='1'
				stroke='#1EC86A'
				strokeWidth='4'
				mask='url(#path-3-inside-2_505_51063)'
			/>
			<mask id='path-4-inside-3_505_51063' fill='white'>
				<rect x='21.668' y='23.166' width='8.33333' height='8.33333' rx='1' />
			</mask>
			<rect
				x='21.668'
				y='23.166'
				width='8.33333'
				height='8.33333'
				rx='1'
				stroke='#1EC86A'
				strokeWidth='4'
				mask='url(#path-4-inside-3_505_51063)'
			/>
			<path
				d='M20.0013 17.334V20.2507H14.168M14.168 20.2507V24.4173M14.168 20.2507H25.8346V24.0007'
				stroke='#1EC86A'
				strokeWidth='2'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};
export default OwnershipIcon;
