import React from 'react';

import { Check, Replay } from '@mui/icons-material';
import {
	Button,
	IconButton,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import { SelectAllIcon } from '@ivy/components/icons';
import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';

import SplitButton, { type SplitButtonProps } from './SplitButton';

interface SelectAllButtonProps {
	minimized?: boolean;
	disabled?: boolean;
	onClick: () => void;
}

const SelectAllButton = ({
	minimized,
	onClick,
	disabled,
}: SelectAllButtonProps) => {
	return minimized ? (
		<IconButton
			color='primary'
			disabled={disabled}
			onClick={onClick}
			sx={{
				mr: 1,
			}}
		>
			<SelectAllIcon />
		</IconButton>
	) : (
		<Button
			color='primary'
			startIcon={<SelectAllIcon />}
			disabled={disabled}
			onClick={onClick}
			sx={{
				mr: 2,
				width: 'max-content',
			}}
		>
			Select All
		</Button>
	);
};

interface ResetButtonProps {
	minimized?: boolean;
	disabled?: boolean;
	onClick: () => void;
}

const ResetButton = ({ minimized, onClick, disabled }: ResetButtonProps) => {
	return minimized ? (
		<IconButton
			color='primary'
			disabled={disabled}
			onClick={onClick}
			sx={{
				mr: 1,
			}}
		>
			<Replay />
		</IconButton>
	) : (
		<Button
			color='primary'
			startIcon={<Replay />}
			disabled={disabled}
			onClick={onClick}
			sx={{
				mr: 2,
				width: 'max-content',
			}}
		>
			Reset selection
		</Button>
	);
};

export interface SelectionFloaterProps<T> {
	sx?: SxProps<Theme>;
	selected: T[];
	onClickReset?: () => void;
	onClickSelectAll?: () => void;
	minimized?: boolean;
	options: SplitButtonProps['options'];
	onClick?: SplitButtonProps['onClick'];
}

const SelectionFloater = <T,>({
	selected,
	onClickReset,
	onClickSelectAll,
	onClick,
	sx,
	minimized = false,
	options,
}: SelectionFloaterProps<T>) => {
	return (
		<Stack
			direction='row'
			sx={combineSx(
				{
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					alignItems: 'center',
					borderRadius: '999px',
					px: minimized ? 3 : 5,
					py: minimized ? 2 : 3,
					bgcolor: (theme) => theme.palette.light4.main,
				},
				sx,
			)}
		>
			{onClickSelectAll ? (
				<SelectAllButton minimized={minimized} onClick={onClickSelectAll} />
			) : null}
			{onClickReset ? (
				<ResetButton
					minimized={minimized}
					disabled={!selected.length}
					onClick={onClickReset}
				/>
			) : null}
			<Typography
				variant={minimized ? 'body2' : 'body1'}
				sx={{
					width: 'max-content',
					display: 'flex',
					alignItems: 'center',
					color: selected.length ? 'primary.main' : 'text.disabled',
					fontWeight: 'bold',
					mr: 3,
				}}
			>
				<Check
					sx={{
						color: 'inherit',
						mr: 0.5,
					}}
				/>
				{formatInteger(selected.length)} selected
			</Typography>
			<SplitButton
				variant='contained'
				color='primary'
				size='large'
				disabled={!selected.length}
				onClick={onClick}
				options={options}
				sx={{
					width: 'max-content',
				}}
			/>
		</Stack>
	);
};

export default SelectionFloater;
