import React from 'react';

import { Typography, Box } from '@mui/material';

import NavTemplate from '@ivy/components/templates/NavTemplate';

import AccountSettingsComp from './AccountSettingsComp';

const AccountSettings = () => {
	return (
		<NavTemplate
			maxWidth='lg'
			pageTitle='Account Settings'
			pageNoIndex
			pageDescription='Update your account settings.'
			showSupportLauncher
		>
			<Box sx={{ my: 5 }}>
				<Typography variant='h4' component='h1' gutterBottom>
					Account Settings
				</Typography>
				<AccountSettingsComp />
			</Box>
		</NavTemplate>
	);
};

export default AccountSettings;
