import React from 'react';

import { Box, Link, Rating, Typography, FormHelperText } from '@mui/material';

const StarRating = ({
	label,
	variant,
	onChange,
	error,
	helperText,
	sx,
	...props
}) => {
	const handleClear = (ev) => {
		ev.target.value = null;
		onChange(ev, null);
	};

	return (
		<Box display='inline-flex' flexDirection='column' sx={sx}>
			{!!label && (
				<Typography variant={variant} color='text.secondary' align='center'>
					{label}
				</Typography>
			)}
			<Box display='inline-block' alignSelf='center'>
				<Box display=''>
					<Rating onChange={onChange} {...props} />
				</Box>
				<Link
					component='div'
					variant='caption'
					onClick={handleClear}
					sx={{
						float: 'right',
						cursor: 'pointer',
					}}
				>
					Clear
				</Link>
			</Box>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</Box>
	);
};

export default StarRating;
