import React from 'react';

import { gql, useQuery } from '@apollo/client';
import {
	Box,
	Typography,
	Grid,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import SupportLink from '@ivy/components/atoms/SupportLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { IVY_LOGO_TOP } from '@ivy/constants/brand';

const TEAMMATES_QUERY = gql`
	query AboutUsTeammates {
		core: teammates(where: { type: CORE }, orderBy: order_ASC) {
			id
			bio
			titles
			suffix
			first
			last
			linkedin
			picture {
				url
			}
		}
		bod: teammates(where: { type: BOD }, orderBy: order_ASC) {
			id
			titles
			suffix
			first
			last
			picture {
				url
			}
		}
		formal: teammates(where: { type: FORMAL_ADVISOR }, orderBy: order_ASC) {
			id
			titles
			suffix
			first
			last
			picture {
				url
			}
		}
	}
`;

const Hero = ({ sx }) => {
	return (
		<Box
			component='section'
			sx={[
				{
					py: {
						xs: 3,
						md: 5,
					},
				},
				sx,
			]}
			display='flex'
			justifyContent='center'
		>
			<Box
				component='img'
				src='https://assets.ivyclinicians.io/images/about-team.jpeg'
				width='100%'
				height='auto'
				sx={{
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				}}
			/>
		</Box>
	);
};

const ContactUs = ({ sx }) => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Stack
			component='section'
			alignItems='center'
			sx={[
				{
					pb: {
						xs: 3,
						md: 5,
					},
				},
				sx,
			]}
		>
			<Typography
				variant={isSm ? 'h5' : 'h4'}
				component='p'
				color='primary.main'
				textAlign='center'
				gutterBottom
			>
				Have a question or feedback?
			</Typography>
			<Typography variant='body1' textAlign='center' gutterBottom>
				You can{' '}
				<RouteLink to={RouteLink.routes.FAQ_LIST}>
					check out our FAQs,
				</RouteLink>{' '}
				<SupportLink>send us a message,</SupportLink> or{' '}
				<RouteLink
					to='https://calendly.com/leon-adelman/zoom-chat'
					openInNewTab
				>
					schedule a meeting
				</RouteLink>{' '}
				with a hiring advocate.
			</Typography>
		</Stack>
	);
};

const AboutCompany = ({ sx }) => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			component='section'
			sx={[
				{
					position: 'relative',
				},
				sx,
			]}
		>
			<Box
				sx={{
					bgcolor: 'primary.verysubtle',
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
				}}
			/>

			<Box
				mx='auto'
				sx={{
					py: 5,
				}}
			>
				<Typography
					component='h1'
					variant={isSm ? 'h5' : 'h4'}
					color='primary.main'
					gutterBottom
				>
					Ivy Clinicians is led by{' '}
					<Typography
						variant={isSm ? 'h5' : 'h4'}
						color='secondary.main'
						component='span'
					>
						physicians, PAs, and nurse practitioners
					</Typography>{' '}
					dedicated to improving the healthcare job market.
				</Typography>
				<Typography variant='body1'>
					The clinician job market is too hard to navigate. For providers, it’s
					difficult to find out which group staffs an ER, who the right contact
					person is, and what the working conditions are at the site. For
					recruiters, finding clinician matches is difficult because providers
					don’t have a simple, safe way to indicate interest in particular jobs
					and to communicate their priorities. Ivy is designed to connect
					clinicians with in-house recruiters so both find what they’re looking
					for.
				</Typography>
			</Box>
		</Box>
	);
};

const WhyIvy = ({ sx }) => {
	return (
		<Box
			component='section'
			sx={[
				{
					pt: 5,
					pb: 10,
				},
				sx,
			]}
		>
			<Box
				sx={{
					mx: 'auto',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
						mx: 'auto',
						alignItems: 'center',
					}}
				>
					<Box
						flexBasis='calc(50% - 32px)'
						mt={{
							xs: 5,
							md: 0,
						}}
					>
						<Box
							component='img'
							src='https://assets.ivyclinicians.io/images/jtodd.jpeg'
							sx={{
								objectFit: {
									xs: 'contain',
									md: 'cover',
								},
								borderRadius: (theme) => `${theme.shape.borderRadius}px`,
								width: '100%',
								minHeight: {
									md: '500px',
								},
							}}
						/>
					</Box>
					{/*<Box sx={{*/}
					{/*    mt: {*/}
					{/*        xs: 5,*/}
					{/*        md: 0*/}
					{/*    },*/}
					{/*    backgroundImage: `url(https://assets.ivyclinicians.io/images/jtodd.jpeg)`,*/}
					{/*    backgroundSize: {*/}
					{/*        xs: 'contain',*/}
					{/*        md: 'cover'*/}
					{/*    },*/}
					{/*    backgroundPosition: 'center',*/}
					{/*    backgroundRepeat: 'no-repeat',*/}
					{/*    borderRadius: (theme) => `${theme.shape.borderRadius}px`,*/}
					{/*    minHeight: {*/}
					{/*        md: '500px'*/}
					{/*    },*/}
					{/*    width: '100%'*/}
					{/*}} flexBasis='calc(50% - 32px)' />*/}
					<Box
						flexBasis='calc(50% - 32px)'
						display='flex'
						flexDirection='column'
						justifyContent='center'
						height='100%'
						mt={{
							xs: 5,
							md: 0,
						}}
					>
						<Typography
							variant='h4'
							component='h2'
							color='primary.main'
							gutterBottom
							textAlign={{
								xs: 'center',
								md: 'left',
							}}
						>
							Why Ivy?
						</Typography>
						<Typography variant='body1'>
							Ivy’s marketplace technology matches the right clinician with the
							right employer. Clinicians, employers, and patients win when
							physicians, PAs, and nurse practitioners find jobs they love.
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const Difference = ({ sx }) => {
	return (
		<Box
			component='section'
			sx={[
				{
					position: 'relative',
				},
				sx,
			]}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: 'primary.verysubtle',
				}}
			/>
			<Box
				sx={{
					mx: 'auto',
					display: 'flex',
					flexDirection: {
						xs: 'column-reverse',
						md: 'row',
					},
					alignItems: {
						xs: 'initial',
						md: 'center',
					},
					py: 5,
				}}
			>
				<Box
					flexBasis='50%'
					mt={{
						xs: 5,
						md: 0,
					}}
				>
					<Box
						maxWidth={{
							md: '440px',
						}}
					>
						<Typography
							variant='h4'
							component='h2'
							color='primary.main'
							gutterBottom
							textAlign={{
								xs: 'center',
								md: 'left',
							}}
						>
							What makes Ivy different?
						</Typography>
						<Typography variant='body1' color='primary.main'>
							Clinicians go first on Ivy. Clinicians can search and connect with
							any employer in their specialty so they can take great care of
							patients.
						</Typography>
					</Box>
				</Box>
				<Box flexBasis='50%' display='flex' justifyContent='center'>
					<Box
						component='img'
						src={IVY_LOGO_TOP}
						width='300px'
						maxWidth='100%'
					/>
				</Box>
			</Box>
		</Box>
	);
};

const Teammate = ({
	name,
	suffixes,
	titles,
	bio,
	firstName,
	linkedin,
	picture,
}) => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			display='flex'
			flexDirection={{
				xs: 'column',
				md: 'row',
			}}
			alignItems={{
				xs: 'center',
				md: 'initial',
			}}
		>
			<Box
				component='img'
				src={picture}
				sx={{
					flex: '0 0 auto',
					height: '380px',
					width: '350px',
					maxWidth: '100%',
					objectFit: 'cover',
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					mx: {
						md: 0,
					},
				}}
			/>
			<Stack
				spacing={0.5}
				ml={{
					md: 5,
				}}
				mt={{
					xs: 3,
					md: 0,
				}}
				flex='1 1'
			>
				<Typography
					component='h3'
					variant={isSm ? 'h5' : 'h4'}
					color='secondary.main'
					textAlign={{
						xs: 'center',
						md: 'left',
					}}
				>
					{name}
				</Typography>
				<Typography
					component='p'
					variant={isSm ? 'h6' : 'h5'}
					color='secondary.main'
					display={!suffixes && 'none'}
					textAlign={{
						xs: 'center',
						md: 'left',
					}}
				>
					{suffixes}
				</Typography>
				<Typography
					component='p'
					variant={isSm ? 'h6' : 'h5'}
					color='primary.main'
					textAlign={{
						xs: 'center',
						md: 'left',
					}}
				>
					{titles.join(', ')}
				</Typography>
				<Stack spacing={2} py={1}>
					<Typography variant='body1'>{bio}</Typography>
					{!!linkedin && (
						<Typography variant='body1' fontWeight='bold'>
							Connect with{' '}
							<RouteLink to={linkedin} openInNewTab underline='hover'>
								{firstName}
							</RouteLink>
							.
						</Typography>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

const Advisor = ({ name, titles, picture, sx }) => {
	return (
		<Grid item md={4} sm={6} xs={12} sx={[{}, sx]}>
			<Box display='flex' justifyContent='center'>
				<Stack alignItems='center' spacing={1} width='100%' maxWidth='350px'>
					<Box
						sx={{
							width: '100%',
							// Force height to be same as width
							height: '0',
							pb: '100%',
							mb: 1,
							backgroundImage: `url(${picture})`,
							backgroundSize: 'cover',
							borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						}}
					/>
					<Typography
						component='h3'
						variant='h6'
						color='secondary.main'
						align='center'
					>
						{name}
					</Typography>
					<Typography
						component='p'
						variant='subtitle1'
						color='primary.main'
						align='center'
						whiteSpace='pre-wrap'
					>
						{titles.join('\n')}
					</Typography>
				</Stack>
			</Box>
		</Grid>
	);
};

const Team = ({ core, bod, formal, sx }) => {
	return (
		<Box
			component='section'
			sx={[
				{
					pt: 5,
				},
				sx,
			]}
		>
			<Box mx='auto'>
				<Typography
					variant='h3'
					component='h2'
					color='primary.main'
					align='center'
					mb={5}
				>
					Our Team
				</Typography>
				<Stack spacing={10}>
					{core.map(
						({ id, first, last, suffix, titles, bio, linkedin, picture }) => (
							<Teammate
								key={id}
								name={`${first} ${last}`}
								suffixes={suffix}
								titles={titles}
								bio={bio}
								firstName={first}
								linkedin={linkedin}
								picture={picture.url}
							/>
						),
					)}
				</Stack>
				<Box
					component='img'
					src='https://assets.ivyclinicians.io/images/cofounder-team.jpeg'
					width='100%'
					sx={{
						objectFit: 'contain',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
						my: 10,
					}}
				/>
				<Typography
					component='h2'
					variant='h3'
					color='primary.main'
					align='center'
					mb={5}
				>
					Board of Directors
				</Typography>
				<Grid container spacing={5}>
					{bod.map(({ id, first, last, suffix, titles, picture }) => (
						<Advisor
							key={id}
							picture={picture.url}
							name={`${first} ${last}${suffix ? `, ${suffix}` : ''}`}
							titles={titles}
						/>
					))}
				</Grid>
				<Typography
					component='h2'
					variant='h3'
					color='primary.main'
					align='center'
					mt={10}
					mb={5}
				>
					Board of Advisors
				</Typography>
				<Grid container spacing={5}>
					{formal.map(({ id, first, last, suffix, titles, picture }) => (
						<Advisor
							key={id}
							picture={picture.url}
							name={`${first} ${last}${suffix ? `, ${suffix}` : ''}`}
							titles={titles}
						/>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

const AboutUs = () => {
	const { data, loading, error } = useQuery(TEAMMATES_QUERY);

	return (
		<NavTemplate
			maxWidth='lg'
			showFooter
			pageTitle='About Us'
			pageDescription='Learn more about the team and vision behind Ivy Clinicians.'
			showSupportLauncher
			pathIsCanonical
			hideBackNav
		>
			<DataLoader variant='logo' data={data} loading={loading} error={error}>
				{() => (
					<Box sx={{ mb: 5 }}>
						<Hero />
						<ContactUs />
						<AboutCompany />
						<WhyIvy />
						<Difference />
						<Team core={data.core} bod={data.bod} formal={data.formal} />
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default AboutUs;
