import React from 'react';

import { Favorite as FavoriteIcon } from '@mui/icons-material';
import { Box, Stack, Typography, IconButton } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { EMRATYPES2TYPEVERBOSE, EMRATypes } from '@ivy/constants/emra';
import { buildInternalLink } from '@ivy/lib/util/route';

export interface FavCardDataObject {
	surveyId?: string;
	surveyTrainingSlug?: string;
	picture?: string;
	type: string;
	title?: string;
	locationInfo?: string;
}

interface FavCardProps {
	data: FavCardDataObject;
	onClick: () => void;
}

const getLink = (type: string, id?: string, slug?: string) => {
	if (!id || !slug) return '';

	switch (type) {
		case EMRATypes.RESIDENCY:
			return buildInternalLink(RouteLink.routes.EMRA_RESIDENCY_SHOW, {
				trainingId: [id, slug],
			});
		case EMRATypes.CLERKSHIP:
			return buildInternalLink(RouteLink.routes.EMRA_CLERKSHIP_SHOW, {
				trainingId: [id, slug],
			});
		case EMRATypes.FELLOWSHIP:
			return buildInternalLink(RouteLink.routes.EMRA_FELLOWSHIP_SHOW, {
				trainingId: [id, slug],
			});
	}
};

const FavCard = ({ data, onClick }: FavCardProps) => {
	const link = getLink(data.type, data.surveyId, data.surveyTrainingSlug);

	return (
		<Box>
			<Stack spacing={1}>
				<Box
					sx={{
						position: 'relative',
					}}
				>
					<RouteLink
						to={link}
						state={{
							backNav: {
								target: 'favorites',
							},
						}}
					>
						<Box
							component='img'
							src={data.picture}
							sx={{
								width: '100%',
								height: '224px',
								borderRadius: (theme) => `${theme.cardShape?.borderRadius}px`,
								objectFit: 'cover',
							}}
						/>
					</RouteLink>
					<IconButton
						size='small'
						onClick={(ev) => {
							ev.stopPropagation();
							onClick();
						}}
						sx={{
							position: 'absolute',
							top: '16px',
							right: '16px',
							color: '#008CFF',
							'&:hover': {
								color: '#4dafff',
								cursor: 'pointer',
							},
						}}
					>
						<FavoriteIcon />
					</IconButton>
				</Box>
				<Box>
					<Typography variant='caption'>
						{EMRATYPES2TYPEVERBOSE[data.type as EMRATypes]}
					</Typography>
					<RouteLink
						to={link}
						underline='hover'
						color='inherit'
						state={{
							backNav: {
								target: 'favorites',
							},
						}}
					>
						<Typography
							variant='h6'
							component='h2'
							sx={{ fontWeight: 'light' }}
						>
							{data.title}
						</Typography>
						<Typography variant='body1' color='text.icon'>
							{data.locationInfo}
						</Typography>
					</RouteLink>
				</Box>
			</Stack>
		</Box>
	);
};

export default FavCard;
