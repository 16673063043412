import React from 'react';

import { SvgIcon } from '@mui/material';

const CalendarIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='15'
			viewBox='0 0 14 15'
			fill='none'
			{...props}
		>
			<path
				d='M12.3335 1.49996H11.6668V0.166626H10.3335V1.49996H3.66683V0.166626H2.3335V1.49996H1.66683C0.933496 1.49996 0.333496 2.09996 0.333496 2.83329V13.5C0.333496 14.2333 0.933496 14.8333 1.66683 14.8333H12.3335C13.0668 14.8333 13.6668 14.2333 13.6668 13.5V2.83329C13.6668 2.09996 13.0668 1.49996 12.3335 1.49996ZM12.3335 13.5H1.66683V4.83329H12.3335V13.5Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default CalendarIcon;
