import { type Bounds, type Coordinates } from '@ivy/lib/services/maps';

export interface HasuraPoint {
	type: string;
	coordinates: number[];
}

export interface HasuraLineString {
	type: string;
	coordinates: number[][];
}

export const resolvePointToCenter = (point: HasuraPoint): Coordinates => {
	const pointCoordinates = point.coordinates;
	return { lng: pointCoordinates[0], lat: pointCoordinates[1] };
};

export const resolveLineStringToViewport = (
	viewport: HasuraLineString,
): Bounds => {
	const viewportCoordinates = viewport.coordinates;
	return {
		xMin: viewportCoordinates[0][0],
		yMin: viewportCoordinates[0][1],
		xMax: viewportCoordinates[1][0],
		yMax: viewportCoordinates[1][1],
	};
};
