import React, { useCallback } from 'react';

import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	type DialogProps,
	type SxProps,
	type Theme,
} from '@mui/material';

import DialogCloseButton from '@ivy/components/atoms/DialogCloseButton';
import { use100vh } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

export interface PopupProps extends Omit<DialogProps, 'onClose'> {
	disableCloseButton?: boolean;
	contentSx?: SxProps<Theme>;
	actionSx?: SxProps<Theme>;
	children?: React.ReactNode;
	title?: string;
	onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
	actions?: React.ReactNode;
	shrink?: boolean;
	contentRef?: React.Ref<HTMLDivElement>;
	disableBackdropClickClose?: boolean;
}

// https://github.com/mui/material-ui/issues/5750
// Background scrolling is not disabled for iOS
const Popup = ({
	disableCloseButton = false,
	contentSx,
	actionSx,
	children,
	title,
	onClose,
	actions,
	shrink = false,
	contentRef,
	disableBackdropClickClose = false,
	...props
}: PopupProps) => {
	const vh = use100vh();

	const handleClose = useCallback(
		(ev: React.SyntheticEvent, reason: string) => {
			if (onClose) {
				//prevent popup close when backdrop is clicked
				if (reason === 'backdropClick' && disableBackdropClickClose) return;

				onClose(ev, reason);
			}
		},
		[onClose, disableBackdropClickClose],
	);

	return (
		<Dialog
			maxWidth='sm'
			PaperProps={
				!shrink
					? {
							sx: {
								width: {
									xs: '100vw',
									sm: '550px',
									md: '600px',
								},
								minHeight: {
									xs: vh,
									sm: '0',
								},
								margin: {
									xs: 0,
									sm: 4,
								},
							},
					  }
					: undefined
			}
			onClose={handleClose}
			{...props}
		>
			<DialogCloseButton onClick={handleClose} hide={disableCloseButton} />
			{title && (
				<DialogTitle
					// MUI has this set as h2, but this should be h1
					// since aria-hidden is set on the rest of the app
					// when a popup is open
					// https://lists.w3.org/Archives/Public/w3c-wai-ig/2020JulSep/0153.html
					component='h1'
					sx={{
						borderBottomWidth: '1px',
						borderBottomStyle: 'solid',
						borderBottomColor: 'divider',
						pr: !disableCloseButton ? 5 : undefined,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
					}}
				>
					{title}
				</DialogTitle>
			)}
			<DialogContent
				ref={contentRef}
				sx={combineSx(
					{
						width: '100%',
						p: 3,
						marginTop: !disableCloseButton && !title ? 6 : 1,
					},
					contentSx,
				)}
			>
				{children}
			</DialogContent>
			{!!actions && (
				<DialogActions
					sx={combineSx(
						{
							borderTopWidth: '1px',
							borderTopStyle: 'solid',
							borderTopColor: 'divider',
						},
						actionSx,
					)}
				>
					{actions}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default Popup;
