import { useState, useEffect } from 'react';

const use100vw = () => {
	// Note we set minimum-scale to 1 so that window.innerWidth is correct on load
	// On Chrome inspector -> device view, the innerWidth changes upon load. If we didn't have the
	// minimum-scale, we would need to run the setMeasuredWidth function once in useEffect
	const [width, setWidth] = useState(() => window.innerWidth);

	useEffect(() => {
		const setMeasuredWidth = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', setMeasuredWidth);
		return () => window.removeEventListener('resize', setMeasuredWidth);
	}, [setWidth]);
	return width;
};

export default use100vw;
