import React from 'react';

import { Box, Stack } from '@mui/material';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import FileUpload from '@ivy/components/molecules/FileUpload';
import { type FileUpload_FileFragment } from '@ivy/gql/types/graphql';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

interface FileObj {
	id: string;
	filename: string;
	publicUrl: string;
}

interface UploadFileValues {
	cv: FileObj | null;
	picture: FileObj | null;
}

const cvValidation = yup.object({
	cv: yup
		.object({
			id: yup.string().required(),
		})
		.nullable()
		.label('CV'),
});

const pictureValidation = yup.object({
	picture: yup
		.object({
			id: yup.string().required(),
		})
		.nullable()
		.label('Picture'),
});

const initialValue = {
	cv: null,
	picture: null,
};

const UploadFile = ({
	formik,
	onNext,
	activeStep,
}: SubFormStepProps<ProfileCompleteFormValues, ExtraParams>) => {
	const { isSubmitting, values, errors, touched, setFieldValue } = formik;

	const isCV = activeStep === 'cv';
	const uploadProps = isCV
		? {
				key: 'cv',
				fileTypes: ['.docx', '.doc', '.pdf'],
				compressor: undefined,
				maxFileSize: 15,
		  }
		: {
				key: 'picture',
				fileTypes: ['.png', '.jpg', '.jpeg'],
				compressor: async (file) => {
					const { default: imageCompression } = await import(
						'browser-image-compression'
					);
					return imageCompression(file, {
						maxSizeMB: 2,
						maxWidthOrHeight: 500,
					});
				},
				maxFileSize: undefined,
		  };

	const value = values[uploadProps.key];
	const error = touched[uploadProps.key] && errors[uploadProps.key];
	const disabled = isSubmitting;
	const onChange = (nv: FileUpload_FileFragment[]) => {
		nv.length
			? setFieldValue(uploadProps.key, nv[0])
			: setFieldValue(uploadProps.key, null);
	};

	return (
		<Stack>
			<Box display='flex'>
				<FileUpload
					files={value ? [value] : []}
					onChange={onChange}
					disabled={disabled}
					formErrorMessage={error}
					boxSx={{
						px: { sm: 8, xs: 1.5 },
						py: { sm: 10, xs: 3 },
						width: { sm: '355px', xs: '100%' },
					}}
					sx={{ width: { sm: 'auto', xs: '100%' } }}
					{...uploadProps}
				/>
			</Box>
			<Box display={isCV ? 'block' : 'none'}>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Stack>
	);
};

export default UploadFile;
export { type UploadFileValues, cvValidation, pictureValidation, initialValue };
