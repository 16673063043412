import React, { useState, type ReactNode } from 'react';

import { Popper, type PopperProps, Fade, Box } from '@mui/material';

interface PopperArrowProps extends Omit<PopperProps, 'children'> {
	children?: ReactNode;
}

const PopperArrow = ({ children, ...props }: PopperArrowProps) => {
	const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
	return (
		<Popper
			{...props}
			transition
			disablePortal
			modifiers={[
				{
					name: 'flip',
					enabled: true,
					options: {
						altBoundary: true,
						rootBoundary: 'document',
						padding: 8,
					},
				},
				{
					name: 'preventOverflow',
					enabled: true,
					options: {
						altAxis: true,
						altBoundary: true,
						tether: true,
						rootBoundary: 'document',
						padding: 8,
					},
				},
				{
					name: 'arrow',
					enabled: true,
					options: {
						element: arrowRef,
						padding: 8,
					},
				},
				{
					name: 'offset',
					options: {
						offset: ({ placement }) => {
							if (placement === 'bottom' && arrowRef) {
								return [0, arrowRef.clientHeight / 2];
							} else {
								return [];
							}
						},
					},
				},
			]}
			sx={{
				// acts on the same level as tooltip
				zIndex: (theme) => theme.zIndex.tooltip,
				// Styles for poppper placement
				'&[data-popper-placement="bottom"] #PopperArrow_Arrow': {
					top: 0,
					left: 0,
					marginTop: '-1.5em',
					'&::before': {
						borderWidth: '1px 0 0 1px',
					},
				},
				'&[data-popper-placement="top"] #PopperArrow_Arrow': {
					bottom: 0,
					left: 0,
					marginBottom: '-1.5em',
					'&::before': {
						borderWidth: '0 1px 1px 0',
					},
				},
				'&[data-popper-placement="right"] #PopperArrow_Arrow': {
					left: 0,
					marginLeft: '-1.5em',
					'&::before': {
						borderWidth: '0 0 1px 1px',
					},
				},
				'&[data-popper-placement="left"] #PopperArrow_Arrow': {
					right: 0,
					marginRight: '-1.5em',
					'&::before': {
						borderWidth: '1px 1px 0 0',
					},
				},
			}}
		>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={700}>
					<Box>
						<Box
							id='PopperArrow_Arrow'
							component='span'
							sx={{
								position: 'absolute',
								fontSize: 7,
								width: '3em',
								height: '3em',
								color: 'background.paper',
								'&::before': {
									content: '""',
									margin: 'auto',
									display: 'block',
									width: '100%',
									height: '100%',
									backgroundColor: 'currentColor',
									transform: 'rotate(45deg)',
									borderStyle: 'solid', // used for promo styles
									borderColor: '#CDCFD2', // used for promo styles
								},
							}}
							ref={setArrowRef}
						/>
						{children}
					</Box>
				</Fade>
			)}
		</Popper>
	);
};

export default PopperArrow;
