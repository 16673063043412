export const wholeCurrencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatInteger = (value: number): string => {
	return wholeCurrencyFormatter.format(value).replace('$', '');
};

export const parseInteger = (stringNumber: string): number => {
	return Number.parseInt(stringNumber.replaceAll(/[^0-9.]/g, ''));
};

export const kFormatInteger = (value: number): string => {
	if (value >= 1000) {
		return `${formatInteger(Math.floor(value / 1000))}K`;
	}
	return formatInteger(value);
};
