import React from 'react';

import { SvgIcon } from '@mui/material';

const ReplyIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			{...props}
		>
			<path
				d='M5.33333 2L2 5.33333L5.33333 8.66667V6H6.66667C9.9884 6 12.6667 8.67827 12.6667 12V14H14V12C14 7.95773 10.7089 4.66667 6.66667 4.66667H5.33333V2Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default ReplyIcon;
