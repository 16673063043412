import React, { type ComponentType } from 'react';

import { reloadPage } from '@ivy/lib/util/route';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

const getSessionStorageKey = (name?: string) => {
	return `retry-lazy-refreshed${name ? `-${name}` : ''}`;
};

// A function to retry loading a chunk to avoid chunk load error for out of date code
// Pass a name if a route has multiple lazy imports so that any, if one import is successful and
// another isn't, the successful one won't reset the same sessionStorage key as the unsuccessful one.
// If the splitting is completely route based, then this is not necessary.
// codemzy.com/blog/fix-chunkloaderror-react
// https://github.com/floydjones1/react-lazy-retry/blob/main/src/index.ts
const lazyRetry = (
	componentImport: () => ComponentPromise,
	name?: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.LazyExoticComponent<React.ComponentType<any>> => {
	return React.lazy(
		() =>
			new Promise((resolve, reject) => {
				// check if the window has already been refreshed
				const hasRefreshed = JSON.parse(
					window.sessionStorage.getItem(getSessionStorageKey(name)) || 'false',
				);
				// try to import the component
				componentImport()
					.then((component) => {
						window.sessionStorage.setItem(getSessionStorageKey(name), 'false'); // success so reset the refresh
						resolve(component);
					})
					.catch((error) => {
						// Failed to get the chunk, we may have a ChunkLoadError from an out of date bundle
						if (!hasRefreshed) {
							// not been refreshed yet
							// sessionStorage persists over page reloads and restores, including cache clears and skipCache as used
							// by reloadPage(true) (tested on Firefox), so we can prevent ourselves from
							// ending up in an infinite loop if we aren't grabbing the latest chunk or some other error
							// is happening
							window.sessionStorage.setItem(getSessionStorageKey(name), 'true');
							return reloadPage(true);
						}
						reject(error); // Default error behaviour as already tried refresh
					});
			}),
	);
};

export default lazyRetry;
