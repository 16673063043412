// TODO: convert to fragments
import React, { useState } from 'react';

import { type ApolloClient, useApolloClient } from '@apollo/client';
import { InsertDriveFile, Close } from '@mui/icons-material';
import {
	useTheme,
	IconButton,
	Link,
	Box,
	FormHelperText,
	CircularProgress,
	Typography,
	Stack,
	type SxProps,
	type Theme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { gql, getFragmentData } from '@ivy/gql/types';
import { type FileUpload_FileFragment } from '@ivy/gql/types/graphql';
import { AppError } from '@ivy/lib/helpers/error';
import { combineSx } from '@ivy/lib/styling/sx';

const UPLOAD_CLOUD_ICON =
	'https://assets.ivyclinicians.io/images/upload-cloud.svg';

const FileUpload_FileFDoc = gql(/* GraphQL */ `
	fragment FileUpload_File on file {
		id
		filename
		publicUrl: public_url
	}
`);

const FileUpload_CreateFileMDoc = gql(/* GraphQL */ `
	mutation FileUpload_CreateFile(
		$filename: String!
		$contentType: String!
		$origin: String!
	) {
		created: create_file(
			filename: $filename
			content_type: $contentType
			origin: $origin
		) {
			id
			uploadUrl: upload_url
			file {
				id
				...FileUpload_File
			}
		}
	}
`);

const FileUpload_FinalizeFileMDoc = gql(/* GraphQL */ `
	mutation FileUpload_FinalizeFile($id: String!) {
		finalized: finalize_file(id: $id) {
			id
			file {
				id
				...FileUpload_File
			}
		}
	}
`);

export class ProcessError extends AppError {}

export class UploadError extends ProcessError {}

export class FileTooLargeError extends ProcessError {}

export const uploadFile = async (
	client: ApolloClient<object>,
	file: File,
): Promise<FileUpload_FileFragment> => {
	console.log('Creating file in API', file.name, file.type);
	const createResponse = await client.mutate({
		mutation: FileUpload_CreateFileMDoc,
		variables: {
			filename: file.name,
			contentType: file.type,
			origin: window.location.origin.toString(),
		},
	});
	console.log('File has been created in API');
	const { id, uploadUrl } = createResponse.data!.created;
	console.log(`Uploading file with id ${id} to ${uploadUrl}`);

	const uploadResponse = await fetch(uploadUrl, {
		method: 'POST',
		body: file,
	});

	if (!uploadResponse.ok) {
		console.error(
			`Upload returned status code ${uploadResponse.status}.`,
			uploadResponse,
		);
		throw new UploadError(
			`Upload failed with status code ${uploadResponse.status}.`,
		);
	}

	console.log('Finalizing file in API');
	const finalizeResponse = await client.mutate({
		mutation: FileUpload_FinalizeFileMDoc,
		variables: {
			id,
		},
	});
	console.log('File has been finalized in API');

	return getFragmentData(
		FileUpload_FileFDoc,
		finalizeResponse.data!.finalized.file!,
	);
};

export const processFile = async (
	client: ApolloClient<object>,
	file: File,
	{
		compressor,
		maxFileSize,
	}: { compressor?: (file: File) => Promise<File>; maxFileSize?: number },
) => {
	console.log('Handling file', file.name);
	console.log('File size is', file.size / 1024 / 1024);
	let finalFile;
	if (compressor) {
		console.log('Compressing file');
		finalFile = await compressor(file);
		console.log(
			'File compression complete, final size is',
			finalFile.size / 1024 / 1024,
		);
	} else {
		console.log('Skipping compression.');
		finalFile = file;
	}

	if (maxFileSize && finalFile.size / 1024 / 1024 > maxFileSize) {
		console.log('File exceeds maximum size', maxFileSize);
		// Technically MiB but users can't be expected to understand MB vs MiB
		throw new FileTooLargeError(`File size cannot exceed ${maxFileSize} MB.`);
	}

	return uploadFile(client, finalFile);
};

export interface FileUploadFile {
	id: string;
	publicUrl: string;
	filename: string;
	file?: File;
}

export interface FileUploadProps<
	T extends FileUploadFile = FileUpload_FileFragment,
> {
	files: T[];
	onChange: (newFiles: T[]) => void;
	compressor?: (file: File) => Promise<File>;
	maxFileSize?: number;
	fileTypes?: string[];
	disabled?: boolean;
	maxNoFiles?: number;
	formErrorMessage?: string;
	sx?: SxProps<Theme>;
	boxSx?: SxProps<Theme>;
	loading?: boolean;
	local?: boolean;
}

const FileUpload = <T extends FileUploadFile = FileUpload_FileFragment>({
	files = [],
	onChange,
	maxFileSize,
	fileTypes,
	compressor,
	disabled = false,
	maxNoFiles = 1,
	formErrorMessage,
	sx,
	boxSx,
	loading = false,
	local = false,
}: FileUploadProps<T>) => {
	const multiple = maxNoFiles > 1;
	const theme = useTheme();
	const [dragging, setDragging] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [processErrorMsg, setProcessErrorMsg] = useState('');
	const isDisabled = disabled || uploading || loading;
	const client = useApolloClient();

	const handleFile = async (file: File) => {
		return processFile(client, file, {
			compressor,
			maxFileSize,
		});
	};

	const handleRemoveFile = (id: string) => (ev: React.SyntheticEvent) => {
		// Don't trigger the dropzone click to open file browser functionality
		ev.preventDefault();
		ev.stopPropagation();
		if (!onChange) {
			return;
		}
		const idx = files.findIndex((file) => file.id === id);
		if (idx < 0) {
			return;
		}
		// Edge case if idx is 0 or length - 1, but this works out
		// Works with multiple and single
		onChange([...files.slice(0, idx), ...files.slice(idx + 1, files.length)]);
	};

	const { fileRejections, getRootProps, getInputProps } = useDropzone({
		accept: fileTypes,
		multiple,
		maxFiles: maxNoFiles, // This only sets your "select" limit, not the total limit
		disabled: isDisabled,
		onDragEnter: () => setDragging(true),
		onDragLeave: () => setDragging(false),
		onDrop: async (acceptedFiles) => {
			setDragging(false);
			console.log(
				'Files have been selected',
				acceptedFiles,
				multiple,
				maxNoFiles,
			);
			if (multiple) {
				if (files.length + acceptedFiles.length > maxNoFiles) {
					// Check maxNoFiles
					setProcessErrorMsg(
						`Maximum of ${maxNoFiles} files. Click 'X' to remove a file.`,
					);
					return;
				}
			} else if (acceptedFiles.length > 1) {
				// Not multiple - assume this is a replacement operations, ignore files.length
				setProcessErrorMsg('Maximum of 1 file.');
				return;
			}

			setProcessErrorMsg('');
			let uploaded;
			if (local) {
				uploaded = acceptedFiles.map((file) => ({
					id: uuidv4(),
					filename: file.name,
					url: URL.createObjectURL(file),
					file,
				}));
			} else {
				setUploading(true);
				try {
					uploaded = (
						await Promise.all(
							acceptedFiles.map(async (file) => {
								try {
									return await handleFile(file);
								} catch (e) {
									if (e instanceof FileTooLargeError) {
										// Don't populate the error, but set the error message and let the other files
										// process
										setProcessErrorMsg(e.message);
									} else {
										throw e;
									}
								}
							}),
						)
					).filter((el) => !!el);
				} catch (e) {
					console.error(e);
					setProcessErrorMsg('Unable to process file. Please try again.');
					captureException(e);
					return;
				} finally {
					setUploading(false);
				}
			}
			if (!onChange) {
				return;
			}
			if (multiple) {
				// Append
				onChange([...files, ...uploaded]);
			} else {
				// Replace
				onChange(uploaded);
			}
		},
	});

	// Order of orders is important
	// Display "File type must be one of..." > "Maximum of X files / File size too big" > "Form is invalid"
	const totalErrMsg =
		(fileRejections?.length ? fileRejections[0].errors[0].message : '') ||
		processErrorMsg ||
		formErrorMessage;

	return (
		<Box sx={sx}>
			<Box
				{...getRootProps()}
				sx={combineSx(
					{
						position: 'relative',
						borderWidth: '2px',
						borderRadius: `${(theme.inputShape || theme.shape).borderRadius}px`,
						borderColor: 'primary.main',
						borderStyle: 'dashed',
						backgroundColor: dragging || uploading ? '#EBEBF0' : '#FAFAFC',
						color: '#bdbdbd',
						outline: 'none',
						transition: 'border .24s ease-in-out',
					},
					boxSx,
				)}
			>
				{(uploading || loading) && (
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							zIndex: 1,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress color='primary' />
					</Box>
				)}
				<Box display='flex' flexDirection='column' alignItems='center'>
					<input {...getInputProps()} />
					<Stack direction='column' spacing={2} alignItems='center'>
						<Box
							component='img'
							src={UPLOAD_CLOUD_ICON}
							sx={{ height: '75.5px', width: '120px' }}
						/>
						<Typography variant='body2' align='center'>
							{dragging ? (
								'Drop here!'
							) : (
								<>
									Drag and drop files or{' '}
									<Link
										component={'span'}
										sx={{
											cursor: 'pointer',
											pointerEvents: 'none',
											textDecoration: 'none',
										}}
									>
										browse
									</Link>{' '}
								</>
							)}
						</Typography>
						{fileTypes && !!fileTypes.length && (
							<Typography variant='caption' align='center' color='#bdbdbd'>
								Supported formats: {fileTypes.join(', ')}
							</Typography>
						)}
					</Stack>
					<Stack
						spacing={1}
						sx={{
							maxWidth: '100%',
						}}
					>
						{files.map((file) => (
							<Box
								display='flex'
								alignItems='center'
								justifyContent='center'
								key={file.id}
							>
								<InsertDriveFile
									color='primary'
									sx={{
										flex: '0 0 auto',
									}}
								/>
								<Typography
									// Open the link but don't open the file browser
									onClick={(ev: React.SyntheticEvent) => ev.stopPropagation()}
									component={RouteLink}
									underline='hover'
									to={file.publicUrl}
									openInNewTab
									variant='body2'
									color='primary'
									noWrap
									sx={{
										mx: 0.5,
										flex: '1 1 0',
									}}
								>
									{file.filename}
								</Typography>
								<IconButton
									size='small'
									sx={{
										color: '#bdbdbd',
										flex: '0 0 auto',
									}}
									onClick={handleRemoveFile(file.id)}
									disabled={isDisabled}
								>
									<Close />
								</IconButton>
							</Box>
						))}
					</Stack>
				</Box>
			</Box>
			<FormHelperText error>{totalErrMsg}</FormHelperText>
		</Box>
	);
};

export default FileUpload;
