import React from 'react';

import RouteLink, {
	type RouteLinkProps,
} from '@ivy/components/atoms/RouteLink';
import { useCrisp } from '@ivy/components/providers/CrispProvider';
import { SUPPORT_EMAIL } from '@ivy/constants/support';

export type SupportLinkProps = RouteLinkProps;

// https://mui.com/material-ui/guides/composition/
const SupportLink = (props: SupportLinkProps) => {
	const crisp = useCrisp();

	return (
		<RouteLink
			onClick={crisp.handleOpenLauncher}
			to={`mailto:${SUPPORT_EMAIL}`}
			openInNewTab
			{...props}
		/>
	);
};

export default SupportLink;
