import React, { useMemo, useCallback, useRef, useState } from 'react';

import { Search, ErrorOutline } from '@mui/icons-material';
import {
	Box,
	Button,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';
import { type FormikProps } from 'formik-othebi';

import Popup from '@ivy/components/molecules/Popup';
import {
	type FacilitySearchInputFormValues,
	type Suggestion,
} from '@ivy/components/organisms/FacilitySearchInput/facilitySearchInputTypes';
import {
	PROFESSION2PROFVERBOSE,
	type Profession,
} from '@ivy/constants/clinician';
import { combineSx } from '@ivy/lib/styling/sx';

import PlaceInput from './PlaceInput';
import ProfessionSelect from './ProfessionSelect';

export interface FacilitySearchInputMobileProps {
	popupOpen: boolean;
	setPopupOpen: (nv: boolean) => void;
	formik: FormikProps<FacilitySearchInputFormValues>;
	search: string;
	setSearch: (nv: string) => void;
	suggestions: Suggestion[];
	loadingSuggestions?: boolean;
	disabled?: boolean;
	disabledSubmit?: boolean;
	hideProfession?: boolean;
	sx?: SxProps<Theme>;
	buttonId?: string;
	placeholder: string;
	endAdornment?: React.ReactNode;
}

const FacilitySearchInputMobile = ({
	disabledSubmit,
	popupOpen,
	setPopupOpen,
	formik,
	search,
	setSearch,
	suggestions,
	loadingSuggestions,
	disabled,
	sx,
	endAdornment,
	buttonId,
	hideProfession = false,
	placeholder,
}: FacilitySearchInputMobileProps) => {
	const [profSelectOpen, setProfSelectOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const [wrapper, setWrapper] = useState(null);

	const handleSearchChange = useCallback(
		(ev: React.SyntheticEvent, newValue: string) => {
			setSearch(newValue);
		},
		[setSearch],
	);

	const handleProfChange = useCallback(
		(nv: string) => {
			formik.setFieldTouched('profession', true, false);
			formik.setFieldValue('profession', nv);
		},
		[formik],
	);

	const handleLocationChange = useCallback(
		(ev: React.SyntheticEvent, suggestion: Suggestion | null) => {
			// Without this, input will focus after being blurred b/c of the handleClickLocationInput handler
			ev.stopPropagation();
			formik.setFieldTouched('location', true, false);
			formik.setFieldValue('location', suggestion ? suggestion.location : null);
			if (suggestion) {
				inputRef.current?.blur();
			}
		},
		[formik, inputRef],
	);

	const handleClickLocationInput = useCallback(() => {
		if (disabled) {
			return;
		}
		inputRef.current?.focus();
	}, [inputRef, disabled]);

	const handleOpenProfSelect = useCallback(
		(ev: React.SyntheticEvent) => {
			ev.preventDefault();
			ev.stopPropagation();
			if (disabled) {
				return;
			}
			setProfSelectOpen(true);
		},
		[disabled, setProfSelectOpen],
	);

	const handleCloseProfSelect = useCallback(() => {
		setProfSelectOpen(false);
	}, [setProfSelectOpen]);

	const handleClickInput = useCallback(() => {
		setPopupOpen(true);
	}, [setPopupOpen]);

	const handleClosePopup = useCallback(() => {
		setPopupOpen(false);
	}, [setPopupOpen]);

	const placeInputValue = useMemo(() => {
		// Memoizing this prevents a bug where we can't modify input after selecting
		return formik.values.location
			? {
					location: formik.values.location,
			  }
			: null;
	}, [formik.values.location]);

	// TODO: hover functionality
	return (
		<>
			<Box
				sx={combineSx(
					{
						display: 'inline-block',
						borderRadius: (theme) =>
							`${
								theme.buttonShape?.borderRadius || theme.shape.borderRadius
							}px`,
						overflow: 'hidden',
						border: (theme) => `1px solid ${theme.palette.divider}`,
						bgcolor: 'background.paper',
						cursor: 'pointer',
						WebkitTapHighlightColor: 'transparent',
						'&:hover': {
							bgcolor: 'light2.main',
						},
					},
					sx,
				)}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							flex: '1 1',
							display: 'inline-block',
							WebkitTapHighlightColor: 'transparent',
							overflow: 'hidden',
						}}
						onClick={handleClickInput}
					>
						<Box
							display='flex'
							alignItems='center'
							py={1.5}
							pr={endAdornment ? 0 : 4}
						>
							<Box mx={2}>
								<Search
									sx={{
										color: 'text.icon',
										position: 'relative',
										top: '3px',
									}}
								/>
							</Box>
							{formik.values.location && formik.values.profession ? (
								<Box overflow='hidden'>
									<Typography
										variant='body2'
										noWrap
										fontWeight='bold'
										lineHeight='1em'
										// Use padding instead of margin because margin is causing some cutoff on
										// letters like 'g'
										pb='0.35em'
									>
										{formik.values.location.label}
									</Typography>
									{/*<Divider orientation='vertical' flexItem sx={{*/}
									{/*    mx: 2*/}
									{/*}}/>*/}
									{!hideProfession && (
										<Typography
											variant='caption'
											component='p'
											noWrap
											lineHeight='1em'
										>
											{
												PROFESSION2PROFVERBOSE[
													formik.values.profession as Profession
												]
											}
										</Typography>
									)}
								</Box>
							) : (
								<Box>
									<Typography
										variant='body2'
										fontWeight='bold'
										lineHeight='1em'
										gutterBottom
									>
										Start your search
									</Typography>
									<Typography
										variant='caption'
										component='p'
										color='text.secondary'
										lineHeight='1em'
									>
										{placeholder}
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
					{!!endAdornment && (
						<Box
							sx={{
								// TODO: remove parent hover background
								//  https://stackoverflow.com/questions/17923922/hover-on-child-without-hover-effect-on-parent
								flex: '0 0 auto',
								mx: 1,
							}}
						>
							{endAdornment}
						</Box>
					)}
				</Box>
			</Box>
			<Popup
				open={popupOpen}
				title='Search'
				onClose={handleClosePopup}
				contentSx={(theme) => ({
					p: 1,
					[theme.breakpoints.up(360)]: {
						p: 3,
					},
				})}
				actions={
					<Button
						size='large'
						color='primary'
						variant='contained'
						startIcon={<Search />}
						disabled={disabled || formik.isSubmitting || disabledSubmit}
						onClick={formik.submitForm}
						sx={(theme) => ({
							width: '100%',
							// mx: 1,
							[theme.breakpoints.up(360)]: {
								mx: 2,
							},
						})}
						id={buttonId}
					>
						Search
					</Button>
				}
			>
				<Box mt={1}>
					<Box
						sx={(theme) => ({
							borderRadius: '999px',
							border: `1px solid ${theme.palette.divider}`,
							cursor: !disabled ? 'text' : undefined,
							WebkitTapHighlightColor: 'transparent',
							px: 2,
							[theme.breakpoints.up(360)]: {
								px: 4,
							},
						})}
						onClick={handleClickLocationInput}
					>
						<Box display='flex' alignItems='center' ref={setWrapper} py={3}>
							<Typography
								variant='body2'
								fontWeight='bold'
								flex='0 0 auto'
								mr={2}
							>
								Search
								{formik.touched.location && formik.errors.location && (
									<ErrorOutline
										sx={{
											color: 'error.main',
											fontSize: '16px',
											ml: 0.5,
											position: 'relative',
											top: '3px',
										}}
									/>
								)}
							</Typography>
							<Box flex='1 1'>
								<PlaceInput
									disabled={disabled || formik.isSubmitting}
									fullWidth
									ref={inputRef}
									popperAnchor={wrapper}
									TextFieldProps={{
										placeholder,
										InputProps: {
											sx: (theme) => ({
												padding: '0px !important',
												'& .MuiAutocomplete-input': {
													textAlign: 'right',
													p: '0px !important',
													...theme.typography.body2,
													...(formik.touched.location && formik.errors.location
														? {
																'::placeholder': {
																	color: 'error.main',
																	opacity: 1,
																},
														  }
														: undefined),
												},
												'& .MuiOutlinedInput-notchedOutline': {
													border: 'none',
												},
											}),
										},
									}}
									options={suggestions}
									inputValue={search}
									onInputChange={handleSearchChange}
									value={placeInputValue}
									onChange={handleLocationChange}
									noOptionsText={
										!search ? 'Start typing for results' : 'No results found'
									}
									loading={loadingSuggestions}
									isOptionEqualToValue={(option, value) =>
										option.location.id === value.location.id
									}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				{!hideProfession && (
					<Box mt={2}>
						<Box
							sx={(theme) => ({
								borderRadius: '999px',
								border: `1px solid ${theme.palette.divider}`,
								cursor: !disabled ? 'pointer' : undefined,
								px: 2,
								WebkitTapHighlightColor: 'transparent',
								[theme.breakpoints.up(360)]: {
									px: 4,
								},
							})}
							onClick={
								// Buggy behavior where can't close and this gets called when clicking out of the popper
								!profSelectOpen ? handleOpenProfSelect : undefined
							}
						>
							<Box display='flex' alignItems='center' py={3}>
								<Typography
									variant='body2'
									fontWeight='bold'
									flex='0 0 auto'
									mr={2}
								>
									Profession
									{formik.touched.profession && formik.errors.profession && (
										<ErrorOutline
											sx={{
												color: 'error.main',
												fontSize: '16px',
												ml: 0.5,
												position: 'relative',
												top: '3px',
											}}
										/>
									)}
								</Typography>
								<Box flex='1 1' display='flex' justifyContent='flex-end'>
									<ProfessionSelect
										disabled={disabled || formik.isSubmitting}
										value={formik.values.profession}
										onChange={handleProfChange}
										SelectProps={{
											onOpen: handleOpenProfSelect,
											onClose: handleCloseProfSelect,
											open: profSelectOpen,
										}}
										error={
											formik.touched.profession && !!formik.errors.profession
										}
										sx={{
											mr: -2,
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Popup>
		</>
	);
};

export default FacilitySearchInputMobile;
