import React from 'react';

import { SvgIcon } from '@mui/material';

const TwitterLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			{...props}
		>
			<path
				d='M28 8.286C27.118 8.67667 26.1694 8.93733 25.1687 9.06067C26.186 8.45667 26.9687 7.49333 27.336 6.35533C26.384 6.91333 25.3294 7.32333 24.2074 7.53933C23.3114 6.58933 22.0334 6 20.6174 6C17.8974 6 15.6927 8.18533 15.6927 10.88C15.6927 11.2613 15.7374 11.6327 15.8214 11.9933C11.7294 11.788 8.10005 9.84267 5.67205 6.89067C5.24538 7.612 5.00538 8.45667 5.00538 9.348C5.00538 11.042 5.87271 12.5333 7.19538 13.412C6.38805 13.3873 5.62805 13.1627 4.96271 12.8027C4.96271 12.8173 4.96271 12.8393 4.96271 12.86C4.96271 15.2273 6.66071 17.1987 8.91138 17.6473C8.50005 17.76 8.06538 17.8227 7.61738 17.8227C7.29938 17.8227 6.98938 17.7867 6.68938 17.7327C7.31605 19.6673 9.13405 21.0813 11.288 21.1233C9.60271 22.43 7.48005 23.2127 5.17205 23.2127C4.77338 23.2127 4.38338 23.19 3.99805 23.1433C6.17871 24.524 8.76805 25.3333 11.5474 25.3333C20.604 25.3333 25.5587 17.896 25.5587 11.444C25.5587 11.2327 25.552 11.022 25.542 10.814C26.5087 10.1313 27.342 9.27 28 8.286Z'
				fill='#03A9F4'
			/>
		</SvgIcon>
	);
};

export default TwitterLinkIcon;
