import React, { useLayoutEffect, useMemo } from 'react';

import { useMediaQuery, type Theme } from '@mui/material';
import { GlobalStyles, type GlobalStylesProps } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import DataLoader, {
	type DataLoaderProps,
} from '@ivy/components/molecules/DataLoader';
import { useCrisp } from '@ivy/components/providers/CrispProvider';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

const preprendSlash = (value: string) =>
	value.startsWith('/') ? value : `/${value}`;

export interface BaseTemplateProps<T> {
	/** The title of the page */
	pageTitle?: string | (string | undefined | null)[];
	/** Whether to remove the `| Ivy Clinicians` suffix appended to the page title */
	disableTitleSuffix?: boolean;
	/** Whether to ask crawlers not to index this page */
	pageNoIndex?: boolean;
	/** Page meta description */
	pageDescription?: string;
	/** Global CSS styles (can target `html` and `body`) */
	globalStyles?: GlobalStylesProps['styles'];
	/** `true` indicates this page is only hosted on the canonical route, and so the current route should be taken as the canonical URL. Otherwise, a string indicates the canonical URL. */
	pathIsCanonical?: boolean | string;
	/** Whether to show the live chat on the bottom right */
	showSupportLauncher?: boolean;
	/** Props passed to `DataLoader` if the page is fetching data */
	DataLoaderProps?: DataLoaderProps<T>;
	children?: React.ReactNode;
}

const BaseTemplate = <T,>({
	pageTitle,
	disableTitleSuffix = false,
	pageNoIndex,
	pageDescription,
	globalStyles,
	children,
	pathIsCanonical = false,
	showSupportLauncher = false,
	DataLoaderProps,
}: BaseTemplateProps<T>) => {
	const crisp = useCrisp();
	const wl = useWhitelabel();
	const location = useLocation();

	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

	useLayoutEffect(() => {
		if (isMd) {
			crisp.setShowLauncher(showSupportLauncher);
		} else {
			crisp.setShowLauncher(false);
		}
		return () => crisp.setShowLauncher(false);
	}, [crisp, showSupportLauncher, isMd]);

	const canonicalPath = useMemo(() => {
		if (!pathIsCanonical) {
			return null;
		}
		if (typeof pathIsCanonical === 'string') {
			return `${window.location.protocol}//${
				window.location.host
			}${preprendSlash(pathIsCanonical)}`;
		}
		return `${window.location.protocol}//${window.location.host}${preprendSlash(
			// function needs to capture change and location is the only waay!
			location.pathname,
		)}`;
	}, [pathIsCanonical, location]);

	const fullPageTitle = useMemo(() => {
		const parts: string[] = [];
		if (pageTitle) {
			if (Array.isArray(pageTitle)) {
				const filtered = pageTitle.filter((el) => el) as string[];
				parts.push(...filtered);
			} else {
				parts.push(pageTitle);
			}
		}
		if (!disableTitleSuffix) {
			parts.push(wl.productName);
		}
		if (!parts.length) {
			return undefined;
		}
		return parts.join(' | ');
	}, [pageTitle, disableTitleSuffix, wl.productName]);

	return (
		<DataLoader
			skipCheckData={!DataLoaderProps}
			variant='logo'
			fullscreen
			{...DataLoaderProps}
		>
			{(!!fullPageTitle || !!pageNoIndex || !!pageDescription) && (
				<Helmet>
					{!!fullPageTitle && <title>{fullPageTitle}</title>}
					{!!pageDescription && (
						<meta name='description' content={pageDescription} />
					)}
					{!!pageNoIndex && <meta name='robots' content='noindex' />}
					{!!canonicalPath && <link rel='canonical' href={canonicalPath} />}
				</Helmet>
			)}
			{!!globalStyles && <GlobalStyles styles={globalStyles} />}
			{children}
		</DataLoader>
	);
};

export default BaseTemplate;
