import React from 'react';

import { useMutation } from '@apollo/client';
import { Button, Typography, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import Dropdown from '@ivy/components/molecules/Dropdown';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

const REPORT_TEMPLATES = [
	{
		id: 'harassment',
		problem: 'Harassment',
		details:
			'Harassing, bullying, intimidating, or abusing an individual or group of people with the result of discouraging them from participating.',
	},
	{
		id: 'violence',
		problem: 'Threatening violence',
		details:
			'Encouraging, glorifying, or inciting violence or physical harm against individuals or groups of people, or places.',
	},
	{
		id: 'hate',
		problem: 'Hate',
		details:
			'Promoting hate or inciting violence based on identity or vulnerability.',
	},
	{
		id: 'personal_info',
		problem: 'Sharing personal information',
		details:
			'Sharing or threatening to share private, personal, or confidential information about someone.',
	},
	{
		id: 'impersonation',
		problem: 'Impersonation',
		details:
			'Impersonating an individual or entity in a misleading or deceptive way. This includes false attributions.',
	},
	{
		id: 'prohibit_transaction',
		problem: 'Prohibited transaction',
		details:
			'Soliciting or facilitating transactions or gifts of illegal or prohibited goods and services.',
	},
	{
		id: 'spam',
		problem: 'Spam',
		details:
			'Repeated, unwanted, or unsolicited manual or automated actions that negatively affect clinicians, communities, and the platform.',
	},
	{
		id: 'other',
		problem: 'Other',
		details: 'Describe the problem that has otherwise not been mentioned.',
	},
];

const REPORT_SCHEMA = yup.object({
	problem: yup
		.string()
		.oneOf(REPORT_TEMPLATES.map((el) => el.problem))
		.required()
		.label('Problem'),
});

const ReviewList_CreateReviewReportMDoc = gql(/* GraphQL */ `
	mutation ReviewList_CreateReviewReport($input: review_report_insert_input!) {
		insert_review_report_one(object: $input) {
			id
		}
	}
`);

interface ReportReviewPopupProps extends PopupProps {
	reviewId: string;
	reviewReplyId?: string;
}

const ReportReviewPopup = ({
	onClose,
	reviewId,
	reviewReplyId,
	...props
}: ReportReviewPopupProps) => {
	const currAcc = useCurrentAccount();
	const { enqueueSnackbar } = useSnackbar();
	const [createReviewReport, { error }] = useMutation(
		ReviewList_CreateReviewReportMDoc,
	);
	const formik = useFormik({
		initialValues: {
			problem: '',
			details: '',
		},
		validationSchema: REPORT_SCHEMA,
		onSubmit: async (values, actions) => {
			try {
				await createReviewReport({
					variables: {
						input: {
							reporter_id: currAcc?.id,
							review_id: reviewReplyId ? undefined : reviewId,
							review_reply_id: reviewReplyId,
							problem: values.problem,
							details: values.problem === 'Other' ? values.details : undefined,
							type: reviewReplyId ? 'REVIEW_REPLY' : 'REVIEW',
						},
					},
				});
				enqueueSnackbar('Your report has been submitted!', {
					variant: 'success',
				});
				actions.setSubmitting(false);
				onClose && onClose();
			} catch (e) {
				captureException(e, {
					extra: {
						values,
					},
				});
				actions.setSubmitting(false);
			}
		},
		validateOnBlur: false,
	});

	const template =
		!!formik.values.problem &&
		REPORT_TEMPLATES.find((el) => el.problem === formik.values.problem);

	return (
		<Popup
			title='Submit a report'
			onClose={onClose}
			{...props}
			actions={
				<>
					<Button
						variant='outlined'
						disabled={formik.isSubmitting}
						onClick={onClose}
						sx={{ mr: 'auto' }}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						disabled={formik.isSubmitting}
						onClick={formik.submitForm}
					>
						Submit
					</Button>
				</>
			}
		>
			<Typography variant='body1'>
				Thanks for looking out for yourself and your fellow clinicians by
				reporting things that break the rules. Let us know what's happening, and
				we'll look into it.
			</Typography>
			<Typography variant='body1' fontWeight='bold' gutterBottom mt={2}>
				Please select which option best describes the problem.
			</Typography>
			<Dropdown
				fullWidth
				required
				options={REPORT_TEMPLATES.map((el) => ({
					label: el.problem,
					value: el.problem,
				}))}
				value={formik.values.problem}
				onChange={(nv) => {
					formik.setFieldValue('problem', nv);
					formik.setFieldValue('details', '', false);
				}}
				onBlur={() => setTimeout(() => formik.setFieldTouched('problem'), 100)}
				{...getErrorState(formik, 'problem')}
			/>
			{!!template && (
				<>
					<Typography variant='h6' gutterBottom mt={2}>
						{template.problem}
					</Typography>
					{template.id === 'other' ? (
						<>
							<Typography variant='body1' gutterBottom mt={2}>
								{template.details}
							</Typography>
							<TextField
								fullWidth
								name='details'
								value={formik.values.details}
								onChange={formik.handleChange}
								onBlur={() =>
									setTimeout(() => formik.setFieldTouched('details', true), 100)
								}
								{...getErrorState(formik, 'details')}
							/>
						</>
					) : (
						<Typography variant='body1' gutterBottom mt={2}>
							{template.details}
						</Typography>
					)}
				</>
			)}
			{!!error && (
				<Typography variant='caption' color='error.main' component='p'>
					An error occurred, please try again.
				</Typography>
			)}
		</Popup>
	);
};

export default ReportReviewPopup;
