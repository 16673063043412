import React from 'react';

import { Stack, Typography, Box } from '@mui/material';
import { format as formatDate, parse } from 'date-fns';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { CalendarIcon } from '@ivy/components/icons';
import SectionHeader, {
	type SectionHeaderProps,
} from '@ivy/components/molecules/SectionHeader';
import { gql, type FragmentType, getFragmentData } from '@ivy/gql/types';

const EmraProgramEvents_TrainingFDoc = gql(/* GraphQL */ `
	fragment EmraProgramEvents_Training on training {
		id
		events(
			where: { event_date: { _gte: "now()" } }
			order_by: [{ event_date: asc }]
		) {
			id
			name
			url
			eventDate: event_date
		}
		eventsAgg: events_aggregate(where: { event_date: { _gte: "now()" } }) {
			aggregate {
				count
			}
		}
	}
`);

export interface EmraProgramEventsProps
	extends Omit<SectionHeaderProps, 'title'> {
	training: FragmentType<typeof EmraProgramEvents_TrainingFDoc>;
}

const EmraProgramEvents = ({
	training: rawTraining,
}: EmraProgramEventsProps) => {
	const training = getFragmentData(EmraProgramEvents_TrainingFDoc, rawTraining);

	if (!training?.eventsAgg?.aggregate?.count) {
		return null;
	}

	return (
		<SectionHeader
			title='Upcoming Events'
			sx={{
				h2: {
					fontWeight: 'light',
				},
			}}
		>
			<Stack spacing={3}>
				{training.events.map((event, indx) => {
					const parsedDate = parse(event.eventDate, 'yyyy-MM-dd', new Date());
					const thing = event ? formatDate(parsedDate, 'MMM d, yyyy') : null;
					return event.url ? (
						<Typography
							key={event.id}
							component='div'
							variant='body1'
							fontWeight='bold'
							noWrap
							sx={{ display: 'flex', flexDirection: 'column' }}
						>
							<RouteLink
								to={event.url}
								underline='hover'
								color='light3.main'
								openInNewTab
							>
								{event.name || ''}
							</RouteLink>
							<Typography
								component='span'
								color='text.icon'
								sx={{
									mt: 1,
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<CalendarIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
								{thing}
							</Typography>
						</Typography>
					) : (
						<Typography
							key={`training-facility-other-${indx}`}
							component='div'
							variant='body1'
							fontWeight='bold'
							noWrap
							sx={{ display: 'flex', flexDirection: 'column' }}
						>
							<Box sx={{ color: 'light3.main' }}>{event?.name || ''}</Box>
							<Typography
								component='span'
								color='text.icon'
								sx={{
									mt: 1,
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<CalendarIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
								{thing}
							</Typography>
						</Typography>
					);
				})}
			</Stack>
		</SectionHeader>
	);
};

export default EmraProgramEvents;
