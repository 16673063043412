import React from 'react';

import { SvgIcon } from '@mui/material';

const EmployerIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z'
				fill='#1EC86A'
				fillOpacity='0.1'
			/>
			<path
				d='M17 21.75C14.66 21.75 10 22.92 10 25.25V27H24V25.25C24 22.92 19.34 21.75 17 21.75ZM12.34 25C13.18 24.42 15.21 23.75 17 23.75C18.79 23.75 20.82 24.42 21.66 25H12.34ZM17 20C18.93 20 20.5 18.43 20.5 16.5C20.5 14.57 18.93 13 17 13C15.07 13 13.5 14.57 13.5 16.5C13.5 18.43 15.07 20 17 20ZM17 15C17.83 15 18.5 15.67 18.5 16.5C18.5 17.33 17.83 18 17 18C16.17 18 15.5 17.33 15.5 16.5C15.5 15.67 16.17 15 17 15ZM24.04 21.81C25.2 22.65 26 23.77 26 25.25V27H30V25.25C30 23.23 26.5 22.08 24.04 21.81ZM23 20C24.93 20 26.5 18.43 26.5 16.5C26.5 14.57 24.93 13 23 13C22.46 13 21.96 13.13 21.5 13.35C22.13 14.24 22.5 15.33 22.5 16.5C22.5 17.67 22.13 18.76 21.5 19.65C21.96 19.87 22.46 20 23 20Z'
				fill='#1EC86A'
			/>
		</SvgIcon>
	);
};

export default EmployerIcon;
