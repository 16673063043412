import React from 'react';

import { useQuery, gql } from '@apollo/client';
import {
	Box,
	Grid,
	Stack,
	Typography,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { parseJSON, format as formatDate } from 'date-fns';
import Shiitake from 'shiitake';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import LandingCTA from '@ivy/components/organisms/LandingCTA';
import NavTemplate from '@ivy/components/templates/NavTemplate';

const GET_BLOG_POSTS = gql`
	query listBlogPosts {
		latest: posts(first: 1, orderBy: createdAt_DESC) {
			id
			title
			slug
			actor
			createdAt
			content {
				text
			}
			picture {
				url(transformation: { image: { resize: { height: 500, fit: clip } } })
			}
		}
		posts(orderBy: createdAt_DESC) {
			id
			title
			slug
			actor
			createdAt
			picture {
				url(transformation: { image: { resize: { height: 200, fit: clip } } })
			}
		}
	}
`;

const BlogGrid = ({ posts }) => {
	return (
		<Grid
			component='section'
			container
			spacing={5}
			sx={{
				ml: '-40px !important',
			}}
		>
			{posts.map((post) => (
				<Grid item xs={12} sm={6} md={4} key={`blog-post-${post.id}`}>
					<RouteLink
						maxWidth='350px'
						mx='auto'
						to={RouteLink.routes.BLOG_SHOW.replace(':blogSlug', post.slug)}
						underline='none'
					>
						<Box
							component='img'
							src={post.picture.url}
							width='100%'
							height='200px'
							sx={{
								objectFit: 'cover',
								borderRadius: (theme) => `${theme.shape.borderRadius}px`,
							}}
						/>
						<Typography
							component='p'
							variant='subtitle1'
							color='secondary.main'
							mt={1}
						>
							{post.actor}
						</Typography>
						<Typography
							component='h2'
							variant='h6'
							color='primary.main'
							gutterBottom
						>
							{post.title}
						</Typography>
						<Typography component='p' variant='subtitle2' color='text.primary'>
							{formatDate(parseJSON(post.createdAt), 'MMM d, yyyy')}
						</Typography>
					</RouteLink>
				</Grid>
			))}
		</Grid>
	);
};

const LatestPreview = ({ post }) => {
	return (
		<RouteLink
			sx={{
				p: {
					xs: 3,
					sm: 5,
					md: 6,
				},
				boxShadow: '0px 2px 18px 0px rgb(38 130 197 / 38%)',
				borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				display: 'grid',
				gridTemplateColumns: {
					xs: '1fr',
					md: 'repeat(2, 1fr)',
				},
				gridAutoRows: {
					xs: 'repeat(2, 1fr)',
					md: '1fr',
				},
				gridColumnGap: {
					md: '16px',
				},
				gridRowGap: {
					xs: '16px',
					md: 'initial',
				},
			}}
			to={RouteLink.routes.BLOG_SHOW.replace(':blogSlug', post.slug)}
			underline='none'
			color='inherit'
		>
			<Stack
				spacing={1}
				pr={3}
				mb={{
					xs: 5,
					md: 0,
				}}
			>
				<Typography
					component='p'
					variant='h6'
					color='secondary.main'
					textTransform='uppercase'
				>
					{post.actor}
				</Typography>
				<Typography component='h2' variant='h5' color='primary.main'>
					{post.title}
				</Typography>
				<Typography variant='body1' fontWeight='bold'>
					{formatDate(parseJSON(post.createdAt), 'MMM d, yyyy')}
				</Typography>
				<Typography variant='body1' component='div'>
					<Shiitake lines={7} throttleRate={200} tagName='p'>
						{post.content.text.replaceAll('\\n', ' ')}
					</Shiitake>
				</Typography>
				<Box>
					<Typography variant='body1' color='secondary.main'>
						Read more
					</Typography>
				</Box>
			</Stack>
			<Box
				height={{
					xs: '300px',
					md: '100%',
				}}
			>
				<Box
					component='img'
					src={post.picture.url}
					sx={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					}}
				/>
			</Box>
		</RouteLink>
	);
};

const BlogList = () => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const { data, loading, error } = useQuery(GET_BLOG_POSTS);

	return (
		<NavTemplate
			maxWidth='lg'
			showFooter
			pageTitle='Blog'
			pageDescription='The Ivy Clinicians blog is your all-in-one resource for emergency medicine news and career tips.'
			showSupportLauncher
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<Box
						sx={{
							my: {
								xs: 3,
								md: 5,
							},
						}}
					>
						<Stack
							mx='auto'
							mb={{
								xs: 3,
								md: 5,
							}}
							spacing={5}
							px={3}
						>
							<Typography
								component='h1'
								variant={isSm ? 'h4' : 'h3'}
								color='secondary.main'
								align='center'
							>
								Ivy Clinicians Blog
							</Typography>
							<Box component='section'>
								<LatestPreview post={data.latest[0]} />
							</Box>
							<BlogGrid
								posts={data.posts.filter((el) => el.id !== data.latest[0].id)}
							/>
						</Stack>
						<LandingCTA />
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default BlogList;
