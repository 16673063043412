import React from 'react';

import { HelpOutline } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import SupportLink from '@ivy/components/atoms/SupportLink';
import { GradientIcon } from '@ivy/components/icons';

const SupportContact = ({
	variant,
	troubleText = 'Having trouble?',
	contactText = 'Contact support',
	sx,
	hideIcon = false,
}) => {
	return (
		<Stack alignItems='center' sx={sx}>
			<SupportLink sx={{ display: hideIcon ? 'none' : 'block' }}>
				<GradientIcon iconComponent={HelpOutline} fontSize='large' />
			</SupportLink>
			<Typography
				variant={variant || 'body2'}
				color='text.secondary'
				// For textoverflow purposes
				align='center'
				sx={{
					mt: 1,
				}}
			>
				{troubleText + ' '} <SupportLink>{contactText}.</SupportLink>
			</Typography>
		</Stack>
	);
};

export default SupportContact;
