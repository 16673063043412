import React from 'react';

import { CreateOutlined, Add } from '@mui/icons-material';
import {
	Typography,
	type Theme,
	type SxProps,
	Button,
	Stack,
	Card,
	useMediaQuery,
	Box,
	useTheme,
} from '@mui/material';

import { BlowhornIcon } from '@ivy/components/icons';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

interface ReportCallToActionProps {
	id: string;
	primary?: string;
	secondary?: string;
	count?: number;
	onClick?: (event: React.SyntheticEvent) => void;
	sx?: SxProps<Theme>;
}

const ReportCallToAction = ({
	primary,
	secondary,
	count,
	onClick,
	sx,
	id,
}: ReportCallToActionProps) => {
	const currAcc = useCurrentAccount();
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const restrictAction =
		currAcc &&
		!currAcc.isClinician &&
		!currAcc.superuser &&
		!currAcc.accessSalary;

	let headerText = primary || 'Unlock by adding your salary';
	let subheaderText =
		secondary ||
		'Do you want to contribute anonymously to help yourself and your colleagues out?';
	let showCount = !!count;

	if (restrictAction) {
		headerText = 'Clinicians only!';
		subheaderText =
			'Only registered clinicans who have already submitted a salary report can view this data.';
		showCount = false;
	}

	return (
		<Card
			sx={combineSx(
				{
					width: '100%',
					bgcolor: 'primary.translucent',
					borderRadius: `${theme.shape.borderRadius}px`,
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					p: 3,
				},
				sx,
			)}
		>
			<Stack id={id} spacing={2} direction={{ xs: 'column', sm: 'row' }}>
				<BlowhornIcon
					sx={{
						fontSize: '45px',
					}}
				/>
				<Stack spacing={2} sx={{ textAlign: 'left' }}>
					<Typography
						component='p'
						maxWidth='100%'
						sx={{ fontSize: '24px', fontWeight: 'bold' }}
					>
						{headerText}
					</Typography>
					<Typography variant='body1' maxWidth='100%'>
						{showCount && (
							<>
								<b>
									{count} {count === 1 ? 'clinician has' : 'clinicians have'}
								</b>{' '}
								already submitted their salary.{' '}
							</>
						)}
						{subheaderText}
					</Typography>
					{!restrictAction && (
						<Button variant='contained' size='large' onClick={onClick}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								{isSm ? (
									<>
										<Add fontSize='medium' sx={{ mr: 1 }} />
										Add Salary
									</>
								) : (
									<>
										<CreateOutlined fontSize='small' sx={{ mr: 1 }} />
										Submit your own salary anonymously
									</>
								)}
							</Box>
						</Button>
					)}
				</Stack>
			</Stack>
		</Card>
	);
};

export default ReportCallToAction;
