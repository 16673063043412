import { ApolloClient, createHttpLink } from '@apollo/client';

import config from '@ivy/config';
import cache from '@ivy/gql/cache';
import { gql } from '@ivy/gql/types';
import { getRefreshToken, storeAccessToken } from '@ivy/lib/storage/token';

const RefreshAuthMDoc = gql(/* GraphQL */ `
	mutation RefreshAuth($refreshToken: String!) {
		refreshAuth: refresh_auth(refresh_token: $refreshToken) {
			accessToken: access_token
		}
	}
`);

const renewTokenApiClient = new ApolloClient({
	link: createHttpLink({ uri: config.gqlUrl }),
	cache,
	credentials: 'include',
});

export const getNewToken = async (): Promise<string | null> => {
	const refreshToken = getRefreshToken();
	if (!refreshToken) {
		return null;
	}

	const response = await renewTokenApiClient.mutate({
		mutation: RefreshAuthMDoc,
		variables: { refreshToken },
	});

	if (response.data) {
		const accessToken = response.data.refreshAuth.accessToken;
		storeAccessToken(accessToken);
		return accessToken;
	}
	return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isJwtError = (err: any) => {
	return (
		// Hasura (returned on access token expired)
		err?.extensions?.code === 'invalid-jwt' ||
		// Hasura (returned on access token expired)
		err?.message === 'Could not verify JWT: JWTExpired' ||
		// Python (returned on refresh token expired or in some edge cases where the access token is valid
		// when it hits Hasura but invalid when it hits Python)
		err?.message === 'Signature has expired'
	);
};
