import React from 'react';

import { Button, type ButtonProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const FormButton = ({ sx, ...props }: ButtonProps) => {
	return (
		<Button
			sx={combineSx(
				{
					minWidth: '0px',
					height: { sm: '55px', xs: 'auto' },
					width: { sm: 'auto', xs: '100%' },
					py: { sm: 3, xs: 1 },
					px: { sm: 8, xs: 2 },
					mb: { sm: 0, xs: 1 },
					ml: { sm: 3, xs: 0 },
					whiteSpace: 'nowrap',
				},
				sx,
			)}
			{...props}
		/>
	);
};

export default FormButton;
