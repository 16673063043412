import React from 'react';

import {
	Typography,
	Box,
	Rating,
	Stack,
	type SxProps,
	type Theme,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { formatInteger } from '@ivy/lib/formatting/number';

interface ReviewSnippetProps {
	rating?: number | null;
	count?: number;
	redirect?: string;
	sx?: SxProps<Theme>;
}

const ReviewSnippet = ({
	rating,
	count = 0,
	redirect,
	sx,
}: ReviewSnippetProps) => (
	<Stack sx={sx}>
		<Stack direction='row' alignItems='center'>
			<Typography variant='subtitle2' color='text.icon'>
				{rating?.toFixed(1) || '0.0'}
			</Typography>
			<Rating
				size='small'
				defaultValue={rating || 0}
				readOnly
				precision={0.1}
				sx={{
					fontSize: '18px',
					ml: 1,
				}}
			/>
		</Stack>
		<Typography variant='caption' color='text.icon'>
			based on{' '}
			<Box
				component={redirect ? RouteLink : 'span'}
				to={redirect}
				underline={redirect ? 'hover' : 'none'}
			>
				{formatInteger(count)} {count === 1 ? 'review' : 'reviews'}
			</Box>
		</Typography>
	</Stack>
);

export default ReviewSnippet;
export { type ReviewSnippetProps };
