import { makeVar } from '@apollo/client';

import {
	type AuthProvider_CurrentAccountRolesSubscription,
	type AuthProvider_CurrentAccountQuery,
} from '@ivy/gql/types/graphql';

export type CurrAccBase =
	AuthProvider_CurrentAccountQuery['currentAccount'][number];

export type CurrAccRoles =
	AuthProvider_CurrentAccountRolesSubscription['currentAccount'][number];

export type CurrAcc =
	AuthProvider_CurrentAccountQuery['currentAccount'][number] &
		AuthProvider_CurrentAccountRolesSubscription['currentAccount'][number];

export const isAuthenticatedVar = makeVar<boolean>(false);
export const currentAccountBaseVar = makeVar<CurrAccBase | null>(null);
export const currentAccountRolesVar = makeVar<CurrAccRoles | null>(null);
