import React from 'react';

import { IconButton, type IconButtonProps } from '@mui/material';

import { PencilIcon } from '@ivy/components/icons';

export type EditButtonProps = IconButtonProps;

const EditButton = ({ ...props }) => {
	return (
		<IconButton {...props}>
			<PencilIcon />
		</IconButton>
	);
};

export default EditButton;
