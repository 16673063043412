import React from 'react';

import { Check } from '@mui/icons-material';
import { format as formatDate, parseJSON } from 'date-fns';

import {
	PAYMENTRATEDISPLAY,
	PROFESSION2PROFVERBOSE,
	WORKER_CLASSIFICATION_DISPLAY_SHORTHAND,
	Profession,
	CLINICIAN_SPECIALTIES,
} from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { ORGNIZATION_PLACEHOLDER_IMAGE } from '@ivy/constants/organization';
import { formatInteger } from '@ivy/lib/formatting/number';

const PROF = Object.freeze({
	[Profession.PHYSICIAN]: 'Physician',
	[Profession.PA]: 'PA',
	[Profession.NP]: 'NP',
});

const CHIPVAULT = {
	cme: {
		label: 'CME',
		color: 'rgba(41, 38, 197, 0.2)',
	},
	studentLoan: {
		label: 'Loan',
		color: 'rgba(38, 130, 197, 0.2)',
	},
	retirement: {
		label: '401k',
		color: 'rgba(38, 197, 73, 0.2)',
	},
	bonus: {
		label: 'Bonus',
		color: 'rgba(197, 152, 38, 0.2)',
	},
	locums: {
		label: CLINICIAN_SPECIALTIES.LOCUMS,
		color: 'rgba(38, 130, 197, 0.2)',
	},
	partner: {
		label: CLINICIAN_SPECIALTIES.CURRENT_PARTNER,
		color: 'rgba(38, 197, 73, 0.2)',
	},
	traveler: {
		label: CLINICIAN_SPECIALTIES.TRAVELER,
		color: 'rgba(41, 38, 197, 0.2)',
	},
	admin: {
		label: CLINICIAN_SPECIALTIES.ADMIN,
		color: 'rgba(197, 152, 38, 0.2)',
	},
	weekendsOnly: {
		label: CLINICIAN_SPECIALTIES.WEEKENDS_ONLY,
		color: 'rgba(38, 197, 73, 0.2)',
	},
	nocturnal: {
		label: CLINICIAN_SPECIALTIES.NOCTURNIST,
		color: 'rgba(197, 152, 38, 0.2)',
	},
	visaSponsorship: {
		label: CLINICIAN_SPECIALTIES.VISA_SPONSORSHIP,
		color: 'rgba(41, 38, 197, 0.2)',
	},
	partnershipTrack: {
		label: CLINICIAN_SPECIALTIES.PARTNERSHIP_TRACK,
		color: 'rgba(38, 197, 73, 0.2)',
	},
};

const getDisplayValue = (valueExists, amount, period = '', abrv = false) => {
	let displayAmount = amount;

	if (!valueExists) {
		return '-';
	} else if (period) {
		displayAmount = `$${formatInteger(amount)}/${period}`;
	}

	return amount ? (
		displayAmount
	) : abrv ? (
		'Yes'
	) : (
		<Check sx={{ mb: '-8px', color: 'secondary.main' }} />
	);
};

const formatYears = (val) => {
	let yrs = '';
	if (val <= 2) {
		yrs = '0 - 2';
	} else if (val <= 5) {
		yrs = '2 - 5';
	} else if (val <= 10) {
		yrs = '5 - 10';
	} else if (val <= 15) {
		yrs = '10 - 15';
	} else if (val <= 20) {
		yrs = '15 - 20';
	} else {
		yrs = '20+';
	}
	return `${yrs} years`;
};

const dataFormatter = (
	report,
): {
	id: string;
	baseAmt: string;
	nightDiffAmt: string;
	nightDiffAmtAbv: string;
	weekendDiffAmt: string;
	weekendDiffAmtAbv: string;
	facility: string;
	facilityId: string;
	facilitySlug: string;
	facilityPicture: string;
	org: string;
	orgId: string;
	orgSlug: string;
	orgPicture: string;
	location: string;
	city: string;
	state: string;
	profession: Profession;
	professionAbv: string;
	experience: string;
	worker: string;
	createdAt: string | number;
	cme: boolean;
	cmeAmt: string;
	bonus: boolean;
	bonusAmt: string;
	retirement: boolean;
	retirementAmt: string;
	studentLoan: boolean;
	studentLoanAmt: string;
	locums: boolean;
	partner: boolean;
	admin: boolean;
	traveler: boolean;
	weekendsOnly: boolean;
	nocturnal: boolean;
	visaSponsorship: boolean;
	partnershipTrack: boolean;
	shftsMonth: number;
	hrsShift: number;
} => {
	const reportData = report || {}; // incase report is null for some reason

	const {
		// general
		facility,
		organization,
		orgOther,
		profession,
		worker,
		createdAt,
		experience,
		shftsMonth,
		hrsShift,

		// salary info
		baseAmt,
		basePeriod,
		nightDiff,
		nightDiffAmt,
		weekendDiff,
		weekendDiffAmt,

		// bonuses
		cme,
		cmeAmt,
		bonus,
		bonusAmt,
		retirement,
		retirementAmt,
		studentLoan,
		studentLoanAmt,

		// details
		admin,
		locums,
		partner,
		traveler,
		nocturnal,
		weekendsOnly,
		visaSponsorship,
		partnershipTrack,
		id,
	} = reportData;

	const amt = baseAmt ? formatInteger(baseAmt) : 0;
	const period = basePeriod ? PAYMENTRATEDISPLAY[basePeriod] : false;
	const formattedBaseAmt = !(amt || period) ? '-' : `$${amt}/${period}`;

	return {
		id,
		baseAmt: formattedBaseAmt,
		nightDiffAmt: getDisplayValue(nightDiff, nightDiffAmt, 'hr'),
		nightDiffAmtAbv: getDisplayValue(nightDiff, nightDiffAmt, 'hr', true),
		weekendDiffAmt: getDisplayValue(weekendDiff, weekendDiffAmt, 'hr'),
		weekendDiffAmtAbv: getDisplayValue(weekendDiff, weekendDiffAmt, 'hr', true),
		facilityId: facility?.id,
		facilitySlug: facility?.slug,
		facility: facility?.name || '-',
		facilityPicture: facility?.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE,
		org: organization?.name || orgOther || '-',
		orgId: organization?.id,
		orgSlug: organization?.slug,
		orgPicture: organization?.logo?.publicUrl || ORGNIZATION_PLACEHOLDER_IMAGE,
		location: facility ? `${facility.city}, ${facility.state}` : '-',
		city: facility ? facility.city : '-',
		state: facility ? facility.state : '-',
		profession: profession ? PROFESSION2PROFVERBOSE[profession] : '-',
		professionAbv: profession ? PROF[profession] : '-',
		experience: experience ? formatYears(experience) : '-',
		worker: worker ? WORKER_CLASSIFICATION_DISPLAY_SHORTHAND[worker] : '-',
		createdAt: createdAt ? formatDate(parseJSON(createdAt), 'MMM yyyy') : '-',

		cme,
		cmeAmt: getDisplayValue(cme, cmeAmt, 'yr'),
		bonus,
		bonusAmt: getDisplayValue(bonus, bonusAmt),
		retirement,
		retirementAmt: getDisplayValue(retirement, retirementAmt),
		studentLoan,
		studentLoanAmt: getDisplayValue(studentLoan, studentLoanAmt, 'yr'),

		admin,
		locums,
		partner,
		traveler,
		weekendsOnly,
		nocturnal,
		visaSponsorship,
		partnershipTrack,

		shftsMonth: shftsMonth || '-',
		hrsShift: hrsShift || '-',
	};
};

export { dataFormatter, CHIPVAULT };
