const crawlerUserAgents = [
	'Prerender',
	'googlebot',
	'Google-InspectionTool',
	'Yahoo! Slurp',
	'bingbot',
	'bingpreview',
	'yandex',
	'baiduspider',
	'facebookexternalhit',
	'twitterbot',
	'rogerbot',
	'linkedinbot',
	'embedly',
	'quora link preview',
	'showyoubot',
	'outbrain',
	'pinterest/0.',
	'developers.google.com/+/web/snippet',
	'slackbot',
	'vkShare',
	'W3C_Validator',
	'redditbot',
	'Applebot',
	'WhatsApp',
	'flipboard',
	'tumblr',
	'bitlybot',
	'SkypeUriPreview',
	'nuzzel',
	'Discordbot',
	'Google Page Speed',
	'Qwantify',
	'pinterestbot',
	'Bitrix link preview',
	'XING-contenttabreceiver',
	'Chrome-Lighthouse',
	'TelegramBot',
	'SeznamBot',
	'screaming frog SEO spider',
	'AhrefsBot',
	'AhrefsSiteAudit',
	'Iframely',
	'petalbot',
	// https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers
	'storebot-google',
	'google-inspectiontool',
	'googleother',
	'apis-google',
	'adsbot-google',
	'mediapartners-google',
	'gogle-safety',
	'feedfetcher-google',
	'googleproducer',
	'google-read-aloud',
	'google-site-verification',
	// https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js
	'duplexweb-google',
	'google favicon',
	'google web preview',
	'googleweblight',
	'ivybot',
];

export const isCrawler = crawlerUserAgents.some(
	(agent) =>
		navigator.userAgent.toLowerCase().indexOf(agent.toLowerCase()) !== -1,
);
