import { createTheme, type ThemeOptions } from '@mui/material';

import StepIcon from '@ivy/components/atoms/StepIcon';

import { muiTheme } from './theme';

export const themeOptions: ThemeOptions = {
	components: {
		MuiTextField: {
			defaultProps: {
				InputLabelProps: {
					shrink: true,
				},
			},
			styleOverrides: {
				root: ({ theme }) => ({
					'& label': {
						position: 'static',
						transform: 'none',
						marginBottom: '4px',
						// Don't cut off long label on mobile
						whiteSpace: 'initial',
						...theme.typography.body2,
					},
					'& fieldset > legend': {
						width: 0,
					},
				}),
			},
		},
		MuiInputLabel: {
			defaultProps: {
				shrink: true,
				variant: 'standard',
			},
			styleOverrides: {
				root: ({ theme }) => ({
					position: 'static',
					transform: 'none',
					...theme.typography.body2,
				}),
			},
		},
		MuiStepLabel: {
			defaultProps: {
				StepIconComponent: StepIcon,
			},
			styleOverrides: {
				vertical: {
					marginLeft: '6px',
					'.MuiStepLabel-labelContainer': {
						'&:hover': {
							cursor: 'pointer',
							opacity: 0.6,
						},
					},
				},
			},
		},
	},
};

const bubbleTheme = createTheme(muiTheme, themeOptions);
export { bubbleTheme };
