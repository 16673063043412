export const CHAR_LIMIT = 1024;

export enum ReviewStatus {
	PENDING = 'PENDING',
	CLEARED_FLAG = 'CLEARED_FLAG',
	REJECTED = 'REJECTED',
	FLAGGED = 'FLAGGED',
	VALID = 'VALID',
}

export const REVIEW_STATUS_DISPLAY = Object.freeze({
	[ReviewStatus.PENDING]: 'Pending',
	[ReviewStatus.CLEARED_FLAG]: 'Admin Approved',
	[ReviewStatus.REJECTED]: 'Admin Rejected',
	[ReviewStatus.FLAGGED]: 'Flagged',
	[ReviewStatus.VALID]: 'Valid',
});
