import React, { useCallback, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import {
	Favorite,
	Language,
	FavoriteBorder,
	EditOutlined,
} from '@mui/icons-material';
import {
	Button,
	Stack,
	useMediaQuery,
	Typography,
	type Theme,
	type SxProps,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import TabTemplate, { IconTypes } from '@ivy/components/templates/TabTemplate';
import { type InfoSnippetProps } from '@ivy/components/templates/TabTemplate/TabTemplate';
import { EMRA2SLUG, EMRATypes } from '@ivy/constants/emra';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData } from '@ivy/gql/types';
import {
	Favorite_Constraint,
	type Favorite_Insert_Input,
} from '@ivy/gql/types/graphql';
import { formatAddress } from '@ivy/lib/formatting/location';
import { convertUriToHttpsUrl } from '@ivy/lib/formatting/url';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import EmraProgramOverview from './EmraProgramOverview';
import EmraProgramSocial from './EmraProgramSocials';
import FeaturedBanner from './FeaturedBanner';

const EmraProgramShow_TrainingQDoc = gql(/* GraphQL */ `
	query EmraProgramShow_Training(
		$isUser: Boolean!
		$accountId: uuid
		$trainingId: uuid!
	) {
		training: training_by_pk(id: $trainingId) {
			id
			type
			favorites(where: { account_id: { _eq: $accountId } }, limit: 1)
				@include(if: $isUser) {
				id
			}
			residency {
				id
				training {
					id
					name
					slug
					featured
					active
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...EmraProgramShow_TrainingSurvey
						}
					}
				}
			}
			fellowship {
				id
				training {
					id
					name
					slug
					featured
					active
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...EmraProgramShow_TrainingSurvey
						}
					}
				}
			}
			clerkship {
				id
				training {
					id
					name
					slug
					featured
					active
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...EmraProgramShow_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);

const EmraProgramShow_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramShow_TrainingSurvey on training_survey {
		id
		createdAt: created_at
		streetAddress1: street_address_1
		streetAddress2: street_address_2
		city
		state
		zipcode
		website
		...EmraProgramSocials_TrainingSurvey
	}
`);

const EmraProgramShow_FavoriteProgramMDoc = gql(/* GraphQL */ `
	mutation EmraProgramShow_FavoriteProgram(
		$constraint: favorite_constraint!
		$favArgs: [favorite_insert_input!]!
	) {
		insert_favorite(
			objects: $favArgs
			on_conflict: { constraint: $constraint, update_columns: [] }
		) {
			returning {
				id
			}
		}
	}
`);

const EmraProgramShow_DeleteFavoriteProgramMDoc = gql(/* GraphQL */ `
	mutation EmraProgramShow_DeleteFavoriteProgram($id: uuid!) {
		delete_favorite_by_pk(id: $id) {
			id
		}
	}
`);

interface FavoriteButtonProps {
	isLinked?: boolean | null;
	onClick: () => void;
	sx?: SxProps<Theme>;
}

const FavoriteButton = ({ isLinked, onClick, sx }: FavoriteButtonProps) => {
	return (
		<Button
			onClick={onClick}
			sx={combineSx(
				{
					borderRadius: '4px',
					background: 'white',
					boxShadow:
						'0px 4.4px 13px 0px rgba(0, 0, 0, 0.04), 0px 22px 80px 0px rgba(0, 0, 0, 0.07)',
					p: 2,
				},
				sx,
			)}
		>
			{isLinked ? <Favorite /> : <FavoriteBorder />}
			<Typography
				fontWeight='bold'
				sx={{
					ml: 1,
					lineHeight: '18px',
					letterSpacing: '1.95px',
				}}
			>
				{`${isLinked ? 'REMOVE FROM' : 'ADD TO'} FAVORITES`}
			</Typography>
		</Button>
	);
};

const EditButton = ({ onClick, sx }: FavoriteButtonProps) => {
	return (
		<Button
			onClick={onClick}
			sx={combineSx(
				{
					borderRadius: '4px',
					background: 'white',
					boxShadow:
						'0px 4.4px 13px 0px rgba(0, 0, 0, 0.04), 0px 22px 80px 0px rgba(0, 0, 0, 0.07)',
					p: 2,
				},
				sx,
			)}
		>
			<EditOutlined />
			<Typography
				fontWeight='bold'
				sx={{
					ml: 1,
					lineHeight: '18px',
					letterSpacing: '1.95px',
				}}
			>
				Edit
			</Typography>
		</Button>
	);
};

const EmraProgramShow = () => {
	const currAcc = useCurrentAccount();
	const { trainingId: rawTrainingId } = useParams();
	const trainingId = rawTrainingId?.substring(0, 36);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const redirect = useRedirect();
	const wl = useWhitelabel();
	const { openSignupPopup } = useAuthPopup();

	const isLteLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
	const { data, loading, error } = useQuery(EmraProgramShow_TrainingQDoc, {
		variables: {
			trainingId: trainingId,
			isUser: !!currAcc?.id,
			accountId: currAcc?.id,
		},
		// Don't use cache-and-network then cache-first -> infinite loop problem
		fetchPolicy: 'network-only',
	});

	const [favoriteProgram] = useMutation(EmraProgramShow_FavoriteProgramMDoc, {
		refetchQueries: [
			'EmraProgramShow_Training',
			'FavoritesShow_QueryFavorites',
		],
		awaitRefetchQueries: true,
	});

	const [deleteFavProgram] = useMutation(
		EmraProgramShow_DeleteFavoriteProgramMDoc,
		{
			refetchQueries: [
				'EmraProgramShow_Training',
				'FavoritesShow_QueryFavorites',
			],
			awaitRefetchQueries: true,
		},
	);

	const currentType: string = data?.training?.type;
	const program =
		data?.training &&
		currentType &&
		data.training[EMRA2SLUG[data.training.type]];

	useEffect(() => {
		if (currentType) {
			let path: string = RouteLink.routes.EMRA_RESIDENCY_SHOW;
			if (currentType === EMRATypes.CLERKSHIP) {
				path = RouteLink.routes.EMRA_CLERKSHIP_SHOW;
			} else if (currentType === EMRATypes.FELLOWSHIP) {
				path = RouteLink.routes.EMRA_FELLOWSHIP_SHOW;
			}

			const link = buildInternalLink(path, {
				trainingId: [program.id, program.training.slug],
			});
			if (pathname !== link) navigate(link, { replace: true });
		}
	}, [data, navigate, currentType, program, pathname]);

	const currentSurvey = getFragmentData(
		EmraProgramShow_TrainingSurveyFDoc,
		program?.latestSurvey?.survey.trainingSurvey,
	);

	const currentFavorite = data?.training?.favorites?.[0] ?? null;

	const currentPath: string =
		currentType === EMRATypes.CLERKSHIP
			? RouteLink.routes.EMRA_CLERKSHIP_SHOW
			: currentType === EMRATypes.FELLOWSHIP
			? RouteLink.routes.EMRA_FELLOWSHIP_SHOW
			: RouteLink.routes.EMRA_RESIDENCY_SHOW;

	const getConstraint = (type?: string): Favorite_Constraint => {
		let _constraint: Favorite_Constraint;

		switch (type) {
			case EMRATypes.RESIDENCY:
				_constraint = Favorite_Constraint.UqFavoriteResidencyIdAccountId;
				break;
			case EMRATypes.CLERKSHIP:
				_constraint = Favorite_Constraint.UqFavoriteClerkshipIdAccountId;
				break;
			case EMRATypes.FELLOWSHIP:
			default:
				_constraint = Favorite_Constraint.UqFavoriteFellowshipIdAccountId;
				break;
		}

		return _constraint;
	};

	const handleFavoriteClick = useCallback(() => {
		if (currAcc) {
			if (currentFavorite) {
				deleteFavProgram({ variables: { id: currentFavorite.id } });
			} else {
				const args: Favorite_Insert_Input = { type: currentType };
				const progId = data?.training?.id;
				switch (currentType) {
					case EMRATypes.RESIDENCY:
						args.residency_id = progId;
						break;
					case EMRATypes.CLERKSHIP:
						args.clerkship_id = progId;
						break;
					case EMRATypes.FELLOWSHIP:
						args.fellowship_id = progId;
						break;
				}
				favoriteProgram({
					variables: {
						constraint: getConstraint(currentType),
						favArgs: args,
					},
				});
			}
		} else {
			if (wl.menu.fullSso) {
				redirect(
					`${wl.menu.fullSso}?return_to=${encodeURIComponent(
						window.location.href,
					)}`,
				);
			} else {
				openSignupPopup();
			}
		}
	}, [
		currAcc,
		currentFavorite,
		deleteFavProgram,
		favoriteProgram,
		currentType,
		redirect,
		data,
		wl,
		openSignupPopup,
	]);

	const handleEditClick = useCallback(() => {
		let editLink = '';
		const progId = data?.training?.id;
		switch (currentType) {
			case EMRATypes.RESIDENCY:
				editLink = buildInternalLink(RouteUri.EMRA_RESIDENCY_EDIT, {
					trainingId: progId,
				});
				break;
			case EMRATypes.CLERKSHIP:
				editLink = buildInternalLink(RouteUri.EMRA_CLERKSHIP_EDIT, {
					trainingId: progId,
				});
				break;
			case EMRATypes.FELLOWSHIP:
				editLink = buildInternalLink(RouteUri.EMRA_FELLOWSHIP_EDIT, {
					trainingId: progId,
				});
				break;
		}
		redirect(editLink, { openInNewTab: true });
	}, [redirect, currentType, data]);

	const createPageDescription = (type: string, name: string) => {
		let descr = '';
		switch (type) {
			case EMRATypes.RESIDENCY:
				descr = `Explore ${name}, a comprehensive program offering program details, training schedules, and application and interview insights. Secure your future in this dynamic field today!`;
				break;
			case EMRATypes.CLERKSHIP:
				descr = `Begin your journey with ${name}. Learn about application procedures, interviews, and spot availability. Gain valuable hands-on experience in this exciting field!`;
				break;
			case EMRATypes.FELLOWSHIP:
				descr = `Elevate your career with ${name}. Explore fellowship overviews and training schedules. Join us to become a leader in this dynamic medical specialty!`;
				break;
		}
		return descr;
	};
	return (
		<TabTemplate
			title={program ? program.training.name : ''}
			pageTitle={program ? program.training.name : ''}
			pageDescription={
				program ? createPageDescription(currentType, program.training.name) : ''
			}
			pageNoIndex={!program?.training.active}
			showSupportLauncher
			defaultBackNav={{
				link: RouteUri.ROOT,
				target: 'home',
			}}
			primaryAction={
				<Stack
					spacing={3}
					sx={{
						width: { xs: '100%', md: 'auto' },
					}}
					direction={{ xs: 'column-reverse', lg: 'row' }}
					alignItems={{ xS: 'normal', lg: 'center' }}
				>
					{!isLteLg && program?.training.featured && (
						<FeaturedBanner
							svgSx={{
								position: 'absolute',
								top: 1,
								left: 1,
								width: '100%',
								height: '100%',
								filter: 'drop-shadow(0px 4.4px 13px rgba(0, 0, 0, 0.04))',
							}}
						/>
					)}
					<EditButton
						onClick={handleEditClick}
						sx={{ width: { xs: '100%', sm: 'auto', color: '#231F20' } }}
					/>
					<FavoriteButton
						isLinked={!!currentFavorite}
						onClick={handleFavoriteClick}
						sx={{ width: { xs: '100%', sm: 'auto', color: '#231F20' } }}
					/>
					{isLteLg && currentSurvey && (
						<EmraProgramSocial survey={currentSurvey} />
					)}
				</Stack>
			}
			actionSx={{
				marginTop: { md: 'auto !important', xs: 'initial' },
			}}
			infoArray={
				[
					currentSurvey?.streetAddress1
						? {
								icon: IconTypes.LOCATION,
								text: formatAddress(
									currentSurvey.streetAddress1,
									currentSurvey.streetAddress2,
									currentSurvey.city,
									currentSurvey.state,
									currentSurvey.zipcode,
								),
						  }
						: null,
					currentSurvey?.website
						? {
								icon: <Language />,
								text: (
									<RouteLink
										to={convertUriToHttpsUrl(currentSurvey.website)}
										openInNewTab
										underline='hover'
										color='light3.main'
									>
										{currentSurvey.website
											.replace('http://', '')
											.replace('https://', '')}
									</RouteLink>
								),
						  }
						: null,
				].filter((el) => el) as InfoSnippetProps[]
			}
			tabs={
				program
					? [
							{
								path: '/',
								index: true,
								label: '',
								element: <EmraProgramOverview trainingId={program.id} />,
								NavTemplateProps: {
									pathIsCanonical: buildInternalLink(currentPath, {
										trainingId: [program.id, program.training.slug],
									}),
									showFooter: true,
									TopBarProps: {
										sx: {
											zIndex: (theme) => theme.zIndex.appBar - 61,
										},
									}, // This is so that is the images have a higher z-index than the header
								},
							},
					  ]
					: []
			}
			DataLoaderProps={{
				data,
				loading,
				error,
				keys: ['training'],
			}}
		/>
	);
};

export default EmraProgramShow;
