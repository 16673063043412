import React from 'react';

import { Edit } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';

const DEFAULT_PADDING = 2;

const ProfileSection = ({
	children,
	title,
	titleTypographyProps,
	onEditClick,
	noPadding = false,
	editWrapperProps,
	iconButtonProps,
	image,
	contentSx,
	iconComponent,
	sx,
}) => {
	const Icon = iconComponent || Edit;
	return (
		<Grid item width='100%' sx={sx}>
			<Paper
				variant='outlined'
				sx={{
					overflow: 'hidden',
				}}
			>
				{image && (
					<Box
						component='img'
						src={image}
						sx={{
							objectFit: 'cover',
							width: '100%',
							height: {
								xs: '150px',
								sm: '200px',
							},
						}}
					/>
				)}
				<Box position='relative'>
					{!!onEditClick && (
						<Box
							position='absolute'
							top={0}
							right={0}
							margin={1}
							{...editWrapperProps}
						>
							<IconButton onClick={onEditClick} {...iconButtonProps}>
								<Icon />
							</IconButton>
						</Box>
					)}
					{!title ? undefined : typeof title === 'string' ? (
						<Typography
							variant={'h5'}
							component='h2'
							gutterBottom
							pt={DEFAULT_PADDING}
							pl={DEFAULT_PADDING}
							pr={onEditClick ? DEFAULT_PADDING + 4 : DEFAULT_PADDING}
							{...titleTypographyProps}
						>
							{title}
						</Typography>
					) : (
						<Box
							pt={DEFAULT_PADDING}
							pl={DEFAULT_PADDING}
							pr={onEditClick ? DEFAULT_PADDING + 4 : DEFAULT_PADDING}
							pb={1}
						>
							{title}
						</Box>
					)}
					<Box
						sx={[
							{
								pt: !image && !noPadding && !title && DEFAULT_PADDING,
								pl: noPadding ? 0 : DEFAULT_PADDING,
								pr: noPadding ? 0 : DEFAULT_PADDING,
								pb: noPadding ? 0 : DEFAULT_PADDING,
							},
							contentSx,
						]}
					>
						{children}
					</Box>
				</Box>
			</Paper>
		</Grid>
	);
};

export default ProfileSection;
