import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import * as yup from 'yup';

import Checklist from '@ivy/components/molecules/Checklist';
import { CLN_SKILLS } from '@ivy/constants/clinician';
import { gql } from '@ivy/gql/types';
import { type FormStepProps } from '@ivy/lib/forms/formFormatHelpers';

import {
	type ProfileCompleteFormValues,
	type ExtraParams,
} from './profileCompleteForm';

// TODO: use getFragmentData with this fragment
// We currently only register a fragment withe the codegen by writing this statement
gql(/* GraphQL */ `
	fragment SkillsStep_ClinicianSkills on clinician {
		id
		skillEkgInterpret: skill_ekg_interpret
		skillEkgStemi: skill_ekg_stemi
		skillEyeFb: skill_eye_fb
		skillEarFb: skill_ear_fb
		skillWoundRepair: skill_wound_repair
		skillOral: skill_oral
		skillPeds: skill_peds
		skillFractureBlocks: skill_fracture_blocks
		skillJointReduction: skill_joint_reduction
		skillNeonates: skill_neonates
		skillObgyn: skill_obgyn
		skillIntubation: skill_endotracheal_intubation
		skillChestTube: skill_chest_tube_placement
		skillChestNeedle: skill_chest_needle
		skillVenous: skill_central_venous_line_placement
		skillIOAccess: skill_io_access
		skillLumbar: skill_lumbar_puncture
		skillSedation: skill_procedural_sedation
		skillUSDiagnostics: skill_us_diagnostics
		skillUSProcedures: skill_us_procedures
	}
`);

interface SkillsStepValues {
	skills: {
		[k: string]: boolean;
	};
}

const validation = yup.object({
	skills: yup.object(
		CLN_SKILLS.reduce(
			(tot, { key }) => ({
				...tot,
				[key]: yup.boolean().required(),
			}),
			{},
		),
	),
});

const initialValues = {
	skills: Object.fromEntries(CLN_SKILLS.map((obj) => [obj.key, false])),
};

const SkillsStep = ({
	formik,
}: FormStepProps<ProfileCompleteFormValues, ExtraParams>) => {
	const { isSubmitting, values, setFieldValue } = formik;
	return (
		<Stack>
			<Typography
				variant='body2'
				color='text.icon'
				sx={{ mb: 5, display: { lg: 'none', xs: 'block' } }}
			>
				We put together a list of skills and procedures that will impress your
				future employer. Scroll through and{' '}
				<Box component='span' sx={{ fontWeight: 'bold' }}>
					tick what applies to you.
				</Box>
			</Typography>
			<Checklist
				categoryComponent='h2'
				value={values.skills}
				options={CLN_SKILLS}
				onChange={(_, nv) => setFieldValue('skills', nv)}
				disabled={isSubmitting}
				size='small'
			/>
		</Stack>
	);
};

export default SkillsStep;
export { type SkillsStepValues, validation, initialValues };
