import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const HandDotIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M17 3C14.791 3 13 4.791 13 7C13 9.209 14.791 11 17 11C19.209 11 21 9.209 21 7C21 4.791 19.209 3 17 3ZM17 5C18.105 5 19 5.895 19 7C19 8.105 18.105 9 17 9C15.895 9 15 8.105 15 7C15 5.895 15.895 5 17 5ZM6 11C5.709 11 5.43397 11.0539 5.16797 11.1309L1 12.1074V19.3086L5 18L13.1562 20.8535C13.4213 20.9465 13.6967 20.9922 13.9707 20.9922C14.3727 20.9922 14.7728 20.8952 15.1348 20.7012L22.4629 16.7832L21.2852 14.2676L14.1211 17.8809L13.4883 17.6953L14.0078 16.4277C14.4628 15.4127 14.0024 14.2204 12.9824 13.7754L7.11523 11.2207C6.76923 11.0817 6.395 11 6 11ZM6 13C6.114 13 6.23161 13.0224 6.34961 13.0664L12.1816 15.6074L12.168 15.6387L12.1543 15.6699L11.6367 16.9375L11.1855 18.0449L5.66016 16.1113L5.02148 15.8887L4.37891 16.0977L3 16.5508V13.6934L5.62305 13.0781L5.67578 13.0664L5.72461 13.0527C5.84461 13.0187 5.937 13 6 13Z'
				fill='currentColor'
			/>
			<path
				d='M12 19L12.5 16.5L13 15L6 12L2.5 13.5V18L5 16.5L12 19Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default HandDotIcon;
