import * as yup from 'yup';

export const PASSWORD_SCHEMA = yup
	.string()
	.matches(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
		'Must be at least 8 characters and contain an uppercase character, a lowercase character, and a number',
	)
	.required()
	.label('Password');

export const CONFIRM_PASSWORD_SCHEMA = yup.object({
	password: PASSWORD_SCHEMA,
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
		.required('Passwords must match'),
});
