import React from 'react';

import { Box, SvgIcon } from '@mui/material';
import { useElementSize } from 'usehooks-ts';

const GradientBlob = ({ ...props }) => {
	return (
		<Box {...props}>
			<SvgIcon
				width='630'
				height='534'
				viewBox='0 0 630 534'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={{
					height: 'auto',
					width: '100%',
				}}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M133.938 40.15C200.806 -9.03153 282.562 -1.92703 357.702 4.77174C419.399 10.272 469.375 36.8944 514.157 74.0465C565.961 117.024 626.4 158.781 629.609 230.349C633.166 309.692 595.611 396.32 531.277 455.892C467.837 514.637 381.655 535.615 302.642 533.117C230.492 530.837 178.341 487.528 127.967 443.142C71.6307 393.503 -0.322238 347.956 0.874098 267.221C2.12755 182.632 59.6463 94.7923 133.938 40.15Z'
					fill='url(#paint0_linear_368_49738)'
				/>
				<defs>
					<linearGradient
						id='paint0_linear_368_49738'
						x1='18.9052'
						y1='183.191'
						x2='430.292'
						y2='505.951'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#2682C5' />
						<stop offset='1' stopColor='#1EC86A' />
					</linearGradient>
				</defs>
			</SvgIcon>
		</Box>
	);
};

const DottedBlob = ({ ...props }) => {
	return (
		<Box {...props}>
			<SvgIcon
				width='628'
				height='535'
				viewBox='0 0 628 535'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={{
					height: 'auto',
					width: '100%',
				}}
			>
				<path
					opacity='0.3'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M110.111 61.014C171.181 4.79542 253.223 2.89049 328.645 1.30779C390.572 0.00826886 443.167 20.9889 491.754 53.0054C547.959 90.0417 612.613 124.918 623.652 195.702C635.889 274.177 608.062 364.401 550.65 430.67C494.035 496.018 410.673 526.322 331.863 532.505C259.899 538.151 203.312 500.823 148.374 462.23C86.9334 419.069 10.4191 381.689 2.75357 301.309C-5.27792 217.092 42.2601 123.475 110.111 61.014Z'
					stroke='#1EC86A'
					strokeWidth='2'
					strokeDasharray='34 34'
					fill='none'
				/>
			</SvgIcon>
		</Box>
	);
};

const ClippedPicture = ({ xlinkHref, ...props }) => {
	return (
		<Box {...props}>
			<SvgIcon
				width='587'
				height='607'
				viewBox='0 0 587 607'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				sx={{
					height: 'auto',
					width: '100%',
				}}
			>
				<mask
					id='mask0_335_78365'
					style={{ maskType: 'alpha' }}
					maskUnits='userSpaceOnUse'
					x='0'
					y='0'
					width='587'
					height='607'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M310.402 3.09039C398.001 14.2655 455.67 78.9524 508.548 138.533C551.966 187.454 570.838 243.904 577.973 304.577C586.226 374.764 602.02 450.286 551.87 506.924C496.272 569.716 403.691 608.216 310.402 606.764C218.409 605.332 136.688 558.746 77.7417 499.544C23.9163 445.485 15.6387 374.795 9.52091 304.577C2.679 226.048 -19.1859 139.299 41.0926 78.899C104.249 15.6159 213.077 -9.32558 310.402 3.09039Z'
						fill='#C4C4C4'
					/>
				</mask>
				<g mask='url(#mask0_335_78365)'>
					<rect
						x='-36.1016'
						y='-4.02734'
						width='655.194'
						height='625.599'
						fill='url(#pattern0)'
					/>
				</g>
				<defs>
					<pattern
						id='pattern0'
						patternContentUnits='objectBoundingBox'
						width='1'
						height='1'
					>
						<use
							xlinkHref='#image0_335_78365'
							transform='translate(-0.00200315) scale(0.00204899 0.00214592)'
						/>
					</pattern>
					<image
						id='image0_335_78365'
						width='490'
						height='466'
						xlinkHref={xlinkHref}
						clipPath='url(#blob-clip)'
					/>
				</defs>
			</SvgIcon>
		</Box>
	);
};

const BlobPicture = ({ picture, sx }) => {
	const [ref, { width }] = useElementSize();

	return (
		<Box
			ref={ref}
			sx={[
				{
					position: 'relative',
					height: !!width && `${(625 / 748.63) * width}px`,
					width: '748.63px',
				},
				sx,
			]}
		>
			<ClippedPicture
				sx={{
					position: 'absolute',
					left: '21.6%',
					right: '19.15%',
					top: '13.71%',
					bottom: '22.27%',
					zIndex: 2,
				}}
				xlinkHref={picture}
			/>
			<GradientBlob
				sx={{
					position: 'absolute',
					left: '15.23%',
					right: '21.29%',
					top: '22.79%',
					bottom: '20.95%',
					zIndex: 1,
				}}
			/>
			<DottedBlob
				sx={{
					position: 'absolute',
					left: '27.74%',
					right: '9.21%',
					top: '19.54%',
					bottom: '24.35%',
				}}
			/>
		</Box>
	);
};

export default BlobPicture;
