import React from 'react';

import { Tune } from '@mui/icons-material';
import {
	Button,
	type ButtonProps,
	Stack,
	type SxProps,
	type Theme,
} from '@mui/material';

import QuickFilterButton, {
	type QuickFilterButtonProps,
} from '@ivy/components/molecules/QuickFilterButton/QuickFilterButton';

import {
	type FilterDataStructure,
	type EntityFilterValues,
} from './useEntityFilters';

interface FiltersBarProps
	extends Pick<
		QuickFilterButtonProps<EntityFilterValues>,
		'onApply' | 'onDiscard' | 'onChange' | 'value'
	> {
	filters: FilterDataStructure[];
	onClickAll?: ButtonProps['onClick'];
	filtersCount: { [k: string]: number };
	sx?: SxProps<Theme>;
}

const FiltersBar = ({
	filters,
	onClickAll,
	onApply,
	onDiscard,
	onChange,
	value,
	filtersCount,
	sx,
}: FiltersBarProps) => {
	const allFiltersBadge = Object.entries(filtersCount).reduce(
		(tot, [k, v]) =>
			filters.reduce((acc, filter) => {
				acc[filter.key] = !!filter.quickBtnId;
				return acc;
			}, {})[k]
				? 0
				: tot + v,
		0,
	);

	const createQuickFilters = (fltrs: FilterDataStructure[]) => {
		return fltrs
			.filter((filter) => filter.quickBtnId)
			.map((filter) => {
				return (
					<QuickFilterButton
						key={filter.key}
						id={filter.quickBtnId}
						onApply={onApply}
						onDiscard={onDiscard}
						value={value}
						initialValue={filter.initialFilterValue}
						onChange={onChange}
						field={filter.key}
						label={filter.label || filter.title}
						title={filter.title}
						tooltipContent={filter.tooltipContent}
						renderInput={(v, oc) => (
							<filter.component
								options={filter.options || []}
								value={v}
								onChange={oc}
							/>
						)}
						badgeContent={filtersCount[filter.key]}
						PopoverProps={{
							PaperProps: {
								sx: {
									minWidth: '400px',
									maxWidth: '100%',
								},
							},
						}}
					/>
				);
			});
	};

	return (
		<Stack direction='row' spacing={2} sx={sx}>
			<Button
				id='btn-filters-all'
				variant='outlined'
				size='small'
				startIcon={<Tune />}
				sx={{
					color: 'text.primary',
					borderColor: 'divider',
					bgcolor: 'white',
					...(allFiltersBadge
						? {
								color: 'primary.main',
								bgcolor: 'primary.translucent',
								borderColor: 'primary.main',
						  }
						: {}),
				}}
				onClick={onClickAll}
			>
				All filters{!!allFiltersBadge && ` (${allFiltersBadge})`}
			</Button>
			{createQuickFilters(filters)}
		</Stack>
	);
};

export default FiltersBar;
