import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

const useFullPath = () => {
	const location = useLocation();
	return useMemo(() => {
		return `${location.pathname}${location.search}${location.hash}`;
	}, [location.pathname, location.search, location.hash]);
};

export default useFullPath;
