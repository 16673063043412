import React from 'react';

import LegalDocument from '@ivy/components/organisms/LegalDocument';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';

const CommunityGuidelinesPage = () => {
	return (
		<SpotlightTemplate
			pageTitle='Community Guidelines'
			pageDescription='Community Guidelines for the Ivy Clinicians platform.'
			pageNoIndex
		>
			<LegalDocument title='Community Guidelines' />
		</SpotlightTemplate>
	);
};

export default CommunityGuidelinesPage;
