import React, { useEffect, useRef, useState } from 'react';

import { Box, Stack, TextField, Typography } from '@mui/material';
import { useDebounce } from 'usehooks-ts';

import FormattedInput from '@ivy/components/atoms/FormattedInput';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import StateSelector from '@ivy/components/molecules/StateSelector';
import {
	getPlaceSuggestions,
	getPlaceDetails,
	useGmaps,
} from '@ivy/lib/services/maps';

export interface AddressAutocompleteValue {
	streetAddress1: string;
	streetAddress2: string;
	city: string;
	state: string;
	zipcode: string;
}

export interface AddressAutocompleteTouched {
	streetAddress1?: boolean;
	streetAddress2?: boolean;
	city?: boolean;
	state?: boolean;
	zipcode?: boolean;
}

export interface AddressAutocompleteError {
	streetAddress1?: string;
	streetAddress2?: string;
	city?: string;
	state?: string;
	zipcode?: string;
}

export interface AddressAutocompleteProps {
	value: AddressAutocompleteValue;
	touched: AddressAutocompleteTouched;
	error: AddressAutocompleteError;
	onChange?: (
		value: AddressAutocompleteValue,
		shouldValidate?: boolean,
	) => void;
	onBlur?: (
		touched: AddressAutocompleteTouched,
		shouldValidate?: boolean,
	) => void;
	spacing?: number;
	disabled?: boolean;
}

const AddressAutocomplete = ({
	value,
	touched,
	error,
	onChange,
	onBlur,
	disabled,
	spacing = 2,
}: AddressAutocompleteProps) => {
	const sessionTokenRef =
		useRef<google.maps.places.AutocompleteSessionToken | null>(null);
	const [searchPlace, setSearchPlace] = useState(value.streetAddress1);
	const [suggestions, setSuggestions] = useState<
		google.maps.places.AutocompletePrediction[]
	>([]);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const debouncedStreetAddress = useDebounce(searchPlace);
	const [resolving, setResolving] = useState(false);
	const isReadyGmaps = useGmaps();

	// If gmaps is loaded externally, then use the disabled flag to inform this component that gmaps hasn't
	// loaded yet. Otherwise, will get "Google Maps hasn't loaded yet" error.
	useEffect(() => {
		if (!isReadyGmaps) {
			return;
		}
		if (!debouncedStreetAddress) {
			setSuggestions([]);
			return;
		}
		const getSuggestions = async () => {
			setLoadingSuggestions(true);
			try {
				if (!sessionTokenRef.current) {
					sessionTokenRef.current =
						new google.maps.places.AutocompleteSessionToken();
				}
				const places = await getPlaceSuggestions({
					input: debouncedStreetAddress,
					componentRestrictions: {
						country: 'us',
					},
					types: ['address'],
					sessionToken: sessionTokenRef.current,
				});
				setSuggestions(places);
			} finally {
				setLoadingSuggestions(false);
			}
		};
		getSuggestions();
	}, [
		isReadyGmaps,
		debouncedStreetAddress,
		setLoadingSuggestions,
		sessionTokenRef,
		setSuggestions,
	]);

	useEffect(() => {
		setSearchPlace(value.streetAddress1);
	}, [value.streetAddress1]);

	return (
		<Stack spacing={spacing}>
			<Autocomplete<
				google.maps.places.AutocompletePrediction,
				false,
				false,
				true
			>
				freeSolo
				autoSelect
				autoHighlight
				blurOnSelect
				selectOnFocus
				fullWidth
				// Keep the "Add XYZ" even if there's an existing match since there are so many
				// similar addresses but the city/state might be different and unknown to GMaps
				alwaysShowAddOption
				disabled={disabled}
				inputValue={searchPlace}
				onInputChange={(_, newVal) => setSearchPlace(newVal)}
				options={suggestions}
				// Override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
				filterOptions={(ops) => ops}
				loading={loadingSuggestions}
				getOptionLabel={(option) =>
					typeof option === 'string'
						? option
						: option.structured_formatting.main_text
				}
				TextFieldProps={{
					label: 'Street Address',
					// Don't show an error when resolving a place to avoid flashing an error message from `onBlur`
					// finishing before the async operations in `onChange`
					error:
						!resolving && !!touched.streetAddress1 && !!error.streetAddress1,
					helperText:
						!resolving && touched.streetAddress1 && error.streetAddress1
							? error.streetAddress1
							: undefined,
				}}
				renderOption={(params, option) => (
					<li {...params} key={option.place_id}>
						<Box>
							<Typography variant='body1'>
								{option.structured_formatting.main_text}
							</Typography>
							<Typography variant='body2' color='text.secondary'>
								{option.structured_formatting.secondary_text.replace(
									', USA',
									'',
								)}
							</Typography>
						</Box>
					</li>
				)}
				// IMPORTANT: Either need to provide value or handle 'clear' reason with empty string value in
				// onInputChange to handle clicking "clear" when we have an initial value (e.g. editing a
				// residency form). Rationale is MUI will not call `onChange` if `value` is uncontrolled, so it
				// thinks it is not set at the beginning. In this case, `onInputChange` will be called but not
				// `onChange`.
				value={value.streetAddress1}
				onChange={async (_, newVal) => {
					if (!newVal) {
						setSearchPlace('');
						onChange?.({
							...value,
							streetAddress1: '',
						});
					} else if (Array.isArray(newVal)) {
						return;
					} else if (typeof newVal === 'string') {
						onChange?.({
							...value,
							streetAddress1: newVal,
						});
					} else {
						const { place_id } = newVal;
						setResolving(true);
						try {
							const placeDetails = await getPlaceDetails({
								placeId: place_id,
								fields: ['address_components'],
								sessionToken: sessionTokenRef.current ?? undefined,
							});
							sessionTokenRef.current = null;
							const streetAddress1 =
								placeDetails?.address_components?.find((el) =>
									el.types.includes('street_address'),
								)?.long_name || newVal.structured_formatting.main_text;
							const city =
								placeDetails?.address_components?.find((el) =>
									el.types.includes('locality'),
								)?.long_name ||
								placeDetails?.address_components?.find((el) =>
									el.types.includes('sublocality'),
								)?.long_name ||
								'';
							const state =
								placeDetails?.address_components?.find((el) =>
									el.types.includes('administrative_area_level_1'),
								)?.short_name || '';
							const zipcode =
								placeDetails?.address_components?.find((el) =>
									el.types.includes('postal_code'),
								)?.short_name || '';
							onChange?.(
								{
									...value,
									streetAddress1,
									city,
									state,
									zipcode,
								},
								false,
							);
							onBlur?.({
								...touched,
								streetAddress1: true,
								city: true,
								state: true,
								zipcode: true,
							});
						} finally {
							setResolving(false);
						}
					}
				}}
				onBlur={() => {
					onBlur?.({
						...touched,
						streetAddress1: true,
					});
				}}
			/>
			<TextField
				name='streetAddress2'
				label='Street Address 2 (Optional)'
				disabled={disabled}
				value={value.streetAddress2}
				onChange={(ev) =>
					onChange?.({
						...value,
						streetAddress2: ev.target.value,
					})
				}
				helperText={touched.streetAddress2 && error.streetAddress2}
				error={touched.streetAddress2 && !!error.streetAddress2}
				fullWidth
			/>
			<Stack
				direction={{
					xs: 'column',
					md: 'row',
				}}
				spacing={{
					xs: spacing,
					md: 2,
				}}
			>
				<TextField
					name='city'
					label='City'
					disabled={disabled}
					value={value.city}
					onChange={(ev) =>
						onChange?.({
							...value,
							city: ev.target.value,
						})
					}
					sx={{
						flexBasis: '33%',
					}}
					helperText={touched.city && error.city}
					error={touched.city && !!error.city}
				/>
				<StateSelector
					name='state'
					label='State/Province'
					disabled={disabled}
					value={value.state}
					onChange={(ev) =>
						onChange?.({
							...value,
							state: ev.target.value,
						})
					}
					sx={{
						flexBasis: '33%',
					}}
					error={touched.state && !!error.state}
					helperText={touched.state && error.state}
				/>
				<FormattedInput
					name='zipcode'
					label='ZIP/Postal Code'
					format={(v) => {
						// if input value is falsy (e.g. if the user deletes the input), then just return
						if (!v) return v;

						// clean the input for any non-digit values and cut off at 5 digits
						return v.replace(/[^\d]/g, '').slice(0, 5);
					}}
					disabled={disabled}
					value={value.zipcode}
					onChange={(ev) =>
						onChange?.({
							...value,
							zipcode: ev.target.value,
						})
					}
					sx={{
						flexBasis: '33%',
					}}
					helperText={touched.zipcode && error.zipcode}
					error={touched.zipcode && !!error.zipcode}
				/>
			</Stack>
		</Stack>
	);
};

export default AddressAutocomplete;
