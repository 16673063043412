import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const DoubleLocationIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M7 2C4.243 2 2 4.243 2 7C2 10.148 5.81891 14.511 6.25391 14.998L7 15.834L7.74609 14.998C8.18109 14.511 12 10.148 12 7C12 4.243 9.757 2 7 2ZM7 5C8.105 5 9 5.895 9 7C9 8.105 8.105 9 7 9C5.895 9 5 8.105 5 7C5 5.895 5.895 5 7 5ZM17 8C14.243 8 12 10.243 12 13C12 16.148 15.8189 20.511 16.2539 20.998L17 21.834L17.7461 20.998C18.1811 20.511 22 16.148 22 13C22 10.243 19.757 8 17 8ZM17 11C18.105 11 19 11.895 19 13C19 14.105 18.105 15 17 15C15.895 15 15 14.105 15 13C15 11.895 15.895 11 17 11Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default DoubleLocationIcon;
