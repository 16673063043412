import React, { useCallback } from 'react';

import {
	Box,
	Button,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { formatInteger } from '@ivy/lib/formatting/number';
import { combineSx } from '@ivy/lib/styling/sx';

interface EndCtaProps {
	facCount: number;
	orgCount: number;
	sx?: SxProps<Theme>;
}

const EndCta = ({ facCount, orgCount, sx }: EndCtaProps) => {
	const currAcc = useCurrentAccount();
	const { enqueueSnackbar } = useSnackbar();
	const { openSignupPopup } = useAuthPopup();

	const handleClick = useCallback(() => {
		if (currAcc) {
			enqueueSnackbar("You're already signed up!", {
				variant: 'info',
			});
		} else {
			openSignupPopup();
		}
	}, [currAcc, openSignupPopup, enqueueSnackbar]);

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					// TODO: update when ersponse to merge addition
					// Add container padding b/c full bleed ignored it
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: 'primary.translucent',
				}}
			/>
			<Box
				sx={{
					py: {
						xs: 8,
						md: 0,
					},
					display: 'flex',
					alignItems: 'center',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
					justifyContent: {
						md: 'space-between',
					},
					height: {
						xs: 'auto',
						md: '500px',
						lg: '540px',
					},
				}}
			>
				<Box
					sx={{
						flexBasis: {
							md: '50%',
						},
						ml: {
							xl: '-115px',
						},
						mr: {
							md: 5,
						},
						mb: {
							xs: 4,
							md: 0,
						},
					}}
				>
					<Box
						component='img'
						src='https://assets.ivyclinicians.io/images/cta-preview.png'
						sx={{
							width: {
								xs: '100%',
								sm: '400px',
								md: '600px',
								xl: '832px',
							},
						}}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexBasis: {
							md: '50%',
						},
						flexDirection: 'column',
						alignItems: {
							xs: 'center',
							md: 'initial',
						},
					}}
				>
					<Typography
						variant='h4'
						component='h2'
						sx={{
							textAlign: {
								xs: 'center',
								md: 'initial',
							},
						}}
					>
						Apply to{' '}
						<Box
							component='span'
							sx={{
								color: 'primary.main',
							}}
						>
							{formatInteger(facCount)} emergency departments
						</Box>{' '}
						and{' '}
						<Box
							component='span'
							sx={{
								color: 'primary.main',
							}}
						>
							{formatInteger(orgCount)} emergency medicine employers
						</Box>
					</Typography>
					<Box
						sx={{
							mt: {
								xs: 3,
								md: 5,
							},
						}}
					>
						<Button variant='contained' size='large' onClick={handleClick}>
							Sign up today
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default EndCta;
