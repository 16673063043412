import React, { useState } from 'react';

import {
	ExpandMore,
	ExpandLess,
	Summarize,
	Warning,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	type ButtonProps,
	Collapse,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemButton,
	Stack,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useQueryParam, BooleanParam, withDefault } from 'use-query-params';

import EmptySectionText from '@ivy/components/atoms/EmptySectionText';
import { ClinicianAvatar } from '@ivy/components/atoms/PlaceholderAvatar';
import RouteLink from '@ivy/components/atoms/RouteLink';
import Checklist from '@ivy/components/molecules/Checklist';
import LicenseList from '@ivy/components/molecules/LicenseList';
import ProfileSection from '@ivy/components/molecules/ProfileSection';
import EdTrainingTimeline from '@ivy/components/organisms/ClinicianProfile/EdTrainingTimeline';
import DocViewerPopup from '@ivy/components/organisms/DocViewerPopup';
import ProfileCompletePopup from '@ivy/components/organisms/ProfileCompletePopup';
import {
	CLN_SKILLS,
	TrainingType,
	EXP_LEVEL_DISPLAY,
} from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import {
	type ClinicianProfile_ClinicianFragment,
	type ClinicianProfile_JobApplicationFragment,
} from '@ivy/gql/types/graphql';
import { bulletJoin } from '@ivy/lib/formatting/string';
import { buildInternalLink } from '@ivy/lib/util/route';

const ClinicianProfile_ClinicianFDoc = gql(/* GraphQL */ `
	fragment ClinicianProfile_Clinician on clinician {
		id
		profileComplete: profile_complete
		fid
		npi
		profDegree: prof_degree
		creds
		isAPP @client
		cv {
			id
			filename
			publicUrl: public_url
		}
		schoolName: school_name
		gradYr: grad_yr
		currStudent: current_student
		...SkillsStep_ClinicianSkills
		...ClinicianAvatar_Clinician
		specialties(order_by: [{ primary: desc }, { name: asc }]) {
			id
			primary
			name
		}
		licenses(order_by: { state: asc }) {
			id
			state
		}
		fsmb: fsmb_bio {
			fid
			boardAction: board_action_flag
			licenses(
				where: { active: { _eq: true } }
				order_by: [
					{ expiry_date: desc_nulls_first }
					{ issue_date: desc_nulls_first }
					{ state: asc }
				]
			) {
				id
				state
				entity
				issue: issue_date
				expiry: expiry_date
				licenseNo: license_no
			}
		}
		training {
			id
			program
			field
			startYear: start_yr
			endYear: end_yr
			present
			type
		}
		appCerts: app_certs(order_by: { name: asc }) {
			id
			name
		}
		boardCerts: board_certs(order_by: { cert: { field: asc } }) {
			id
			eligible
			cert {
				id
				field
				board {
					id
					name
				}
			}
		}
		spExp: sp_exp {
			id
			field
			level
		}
		account {
			id
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
			picture {
				id
				filename
				publicUrl: public_url
			}
		}
	}
`);

interface ClinicianCvProps {
	clinician: ClinicianProfile_ClinicianFragment;
	onEditClick: ButtonProps['onClick'];
	showCv: boolean;
	onOpenCv: () => void;
	onCloseCv: () => void;
}

const ClinicianCv = ({
	clinician,
	onEditClick,
	showCv,
	onOpenCv,
	onCloseCv,
}: ClinicianCvProps) => {
	const { cv } = clinician;

	return (
		<Box pb={1}>
			<Stack spacing={1} alignItems='center'>
				{cv?.publicUrl ? (
					<Button
						variant='outlined'
						startIcon={<Summarize />}
						size='large'
						fullWidth
						onClick={onOpenCv}
						sx={{
							maxWidth: '200px',
						}}
					>
						View CV
					</Button>
				) : onEditClick ? (
					<>
						<Typography
							variant='body2'
							color='text.secondary'
							fontStyle='italic'
							align='center'
						>
							Upload your CV to share securely with recruiters.
						</Typography>
						<Button variant='contained' onClick={onEditClick}>
							Upload CV
						</Button>
					</>
				) : (
					<EmptySectionText variant='body2' text='CV has not been uploaded.' />
				)}
			</Stack>
			{!!cv?.publicUrl && showCv && (
				<DocViewerPopup url={cv.publicUrl} open onClose={onCloseCv} />
			)}
		</Box>
	);
};

interface ClinicianOverviewProps {
	clinician: ClinicianProfile_ClinicianFragment;
	email?: string | null;
}

const ClinicianOverview = ({ clinician, email }: ClinicianOverviewProps) => {
	const { account, specialties, npi, creds } = clinician;

	let formattedSpecialties;
	if (clinician.isAPP) {
		if (clinician.currStudent) {
			formattedSpecialties = 'Current Student';
		} else {
			formattedSpecialties = '';
		}
	} else {
		// Physician
		const specialtyNames = specialties.map((el) => el.name);
		const currTraining = clinician.training.find((el) => el.present);
		if (currTraining) {
			formattedSpecialties = bulletJoin([
				`Current ${
					currTraining.type === TrainingType.RESIDENCY ? 'Resident' : 'Fellow'
				}`,
				...specialtyNames,
			]);
		} else {
			formattedSpecialties = bulletJoin(specialtyNames);
		}
	}

	return (
		<Box>
			<Stack alignItems='center'>
				<ClinicianAvatar
					clinician={clinician}
					size={175}
					sx={{
						mb: 2,
					}}
					TypographyProps={{
						variant: 'h1',
					}}
				/>
				<Grid item>
					<Typography variant='h5' component='h1' align='center' gutterBottom>
						{`${account.pi?.fullName}, ${creds}`}
					</Typography>
					<Typography variant='body1' align='center' gutterBottom>
						{formattedSpecialties}
					</Typography>
					{!!npi && (
						<Typography variant='body1' align='center'>
							NPI {npi}
						</Typography>
					)}
				</Grid>
			</Stack>
			{!!email && (
				<>
					<Divider
						variant='middle'
						sx={{
							mt: 1,
							mb: 1,
							ml: 5,
							mr: 5,
						}}
					/>
					<Typography
						variant='body1'
						color='text.icon'
						align='center'
						fontWeight='bold'
						mt={2}
					>
						Email:{' '}
						<RouteLink to={`mailto:${email}`} openInNewTab>
							{email}
						</RouteLink>
					</Typography>
				</>
			)}
		</Box>
	);
};

interface ClinicianLicensesProps {
	clinician: ClinicianProfile_ClinicianFragment;
}

const ClinicianLicenses = ({ clinician }: ClinicianLicensesProps) => {
	const { licenses, fsmb, profDegree } = clinician;

	if (!licenses.length && !fsmb?.licenses.length) {
		return <EmptySectionText variant='body2' text={'No licenses listed.'} />;
	}

	return (
		<LicenseList
			licenses={fsmb?.licenses || licenses}
			profDegree={profDegree}
		/>
	);
};

interface ClinicianAPPCertsProps {
	clinician: ClinicianProfile_ClinicianFragment;
}

const ClinicianAPPCerts = ({ clinician }: ClinicianAPPCertsProps) => {
	const { appCerts } = clinician;

	if (!appCerts?.length) {
		return (
			<EmptySectionText variant='body2' text='No certifications listed.' />
		);
	}

	return (
		<List
			sx={{
				width: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
			}}
			dense
		>
			{appCerts.map(({ id, name }) => (
				<ListItem
					key={`app-cert-${id}`}
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemText
						primary={name}
						primaryTypographyProps={{
							variant: 'body1',
						}}
					/>
				</ListItem>
			))}
		</List>
	);
};

interface ClinicianSpExpProps {
	clinician: ClinicianProfile_ClinicianFragment;
}

const ClinicianSpExp = ({ clinician }: ClinicianSpExpProps) => {
	const { spExp } = clinician;

	if (!spExp?.length) {
		return <EmptySectionText variant='body2' text='No experience listed.' />;
	}

	return (
		<List
			sx={{
				width: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
			}}
			dense
		>
			{spExp.map(({ id, field, level }) => (
				<ListItem
					key={`sp-exp-${id}`}
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemText
						primary={field}
						secondary={EXP_LEVEL_DISPLAY[level]}
						primaryTypographyProps={{
							variant: 'body1',
						}}
					/>
				</ListItem>
			))}
		</List>
	);
};

interface ClinicianBoardCertsProps {
	clinician: ClinicianProfile_ClinicianFragment;
	isOwner?: boolean;
}

const ClinicianBoardCerts = ({
	clinician,
	isOwner = false,
}: ClinicianBoardCertsProps) => {
	const { boardCerts } = clinician;
	const boardAction = clinician.fsmb?.boardAction;

	if (!boardCerts?.length) {
		return (
			<EmptySectionText variant='body2' text='No certifications listed.' />
		);
	}

	return (
		<List
			sx={{
				width: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
			}}
			dense
		>
			{boardCerts.map(({ id, cert, eligible }) => (
				<ListItem
					key={`board-cert-${id}`}
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemText
						primary={cert.field}
						secondary={bulletJoin([
							eligible ? 'Board Eligible' : 'Board Certified',
							cert.board.name,
						])}
						primaryTypographyProps={{
							variant: 'body1',
						}}
					/>
				</ListItem>
			))}
			{!isOwner && boardAction && (
				<ListItem
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemText
						primary={
							<>
								<Warning
									fontSize='small'
									sx={{
										verticalAlign: 'middle',
									}}
								/>{' '}
								{`Clinician has a board action.`}
							</>
						}
						primaryTypographyProps={{
							variant: 'body1',
							color: 'warning.main',
						}}
					/>
				</ListItem>
			)}
		</List>
	);
};

interface ClinicianSkillsProps {
	clinician: ClinicianProfile_ClinicianFragment;
}

const ClinicianSkills = ({ clinician }: ClinicianSkillsProps) => {
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		setExpanded((prev) => !prev);
	};

	if (!CLN_SKILLS.some((option) => clinician[option.key])) {
		return <EmptySectionText variant='body2' text='No skills listed.' />;
	}

	return (
		<Box>
			<Typography variant='body1' mb={5} mt={2}>
				Can perform the following skills or procedures independently.
			</Typography>
			<Collapse
				in={expanded}
				collapsedSize={250}
				sx={{
					position: 'relative',
				}}
			>
				<Checklist
					categoryComponent='h3'
					value={CLN_SKILLS.reduce(
						(tot, option) => ({
							...tot,
							[option.key]: clinician[option.key],
						}),
						{},
					)}
					options={CLN_SKILLS}
					disabled
					size='small'
					sx={{
						mt: 2,
						'& .MuiCheckbox-root.Mui-disabled': {
							color: (theme) => theme.palette.primary.main,
						},
					}}
				/>
				<Box
					sx={{
						display: expanded ? 'none' : undefined,
						position: 'absolute',
						bottom: 0,
						left: 0,
						right: 0,
						height: '60px',
						zIndex: 1,
						backgroundImage:
							'linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)',
					}}
				/>
			</Collapse>
			<Box
				sx={{
					mt: expanded ? 5 : undefined,
					pt: 1,
					borderTop: (theme) => `1px solid ${theme.palette.divider}`,
				}}
			>
				<Button
					endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
					onClick={handleClick}
				>
					Show {expanded ? 'less' : 'more'}
				</Button>
			</Box>
		</Box>
	);
};

const ClinicianProfile_JobApplicationFDoc = gql(/* GraphQL */ `
	fragment ClinicianProfile_JobApplication on job_application {
		id
		createdAt: created_at
		createdAtDate @client
		contract {
			id
			facility {
				id
				name
				slug
				city
				state
				picture {
					id
					publicUrl: public_url
				}
			}
		}
	}
`);

interface ClinicianJobApplicationsProps {
	jobApps: readonly ClinicianProfile_JobApplicationFragment[];
}

const ClinicianJobApplications = ({
	jobApps,
}: ClinicianJobApplicationsProps) => {
	return (
		<List
			sx={{
				width: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
				borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				overflowX: 'hidden',
				overflowY: 'auto',
				// No false bottoms
				maxHeight: (54 + 4) * 3.5,
			}}
			dense
		>
			{jobApps.map((jobApp) => (
				<ListItemButton
					key={jobApp.id}
					component={RouteLink}
					to={buildInternalLink(RouteLink.routes.FACILITY_SHOW, {
						facilityId: [
							jobApp.contract.facility.id,
							jobApp.contract.facility.slug,
						],
					})}
					// TODO: not working, getting overriden
					state={{
						backNav: {
							target: 'candidate',
						},
					}}
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemAvatar>
						<Avatar
							src={
								jobApp.contract.facility.picture?.publicUrl ||
								FACILITY_PLACEHOLDER_IMAGE
							}
						/>
					</ListItemAvatar>
					<ListItemText
						primary={
							<>
								<Typography component='span' variant='body1' fontWeight='bold'>
									{jobApp.contract.facility.name}
								</Typography>
								<Typography component='span' variant='body1' color='text.icon'>
									{' - '}
									{jobApp.contract.facility.city},{' '}
									{jobApp.contract.facility.state}
								</Typography>
							</>
						}
						secondary={`Applied on ${format(
							jobApp.createdAtDate,
							'MMM d, yyyy',
						)}`}
					/>
				</ListItemButton>
			))}
		</List>
	);
};

interface ClinicianProfileProps {
	clinician: FragmentType<typeof ClinicianProfile_ClinicianFDoc>;
	jobApps?: FragmentType<typeof ClinicianProfile_JobApplicationFDoc>[];
	isOwner?: boolean;
	email?: ClinicianOverviewProps['email'];
}

const ClinicianProfile = ({
	clinician: rawClinician,
	jobApps: rawJobApps,
	email,
	isOwner = false,
}: ClinicianProfileProps) => {
	const [showCv, setShowCv] = useQueryParam(
		'showCv',
		withDefault(BooleanParam, false),
	);
	const clinician = getFragmentData(
		ClinicianProfile_ClinicianFDoc,
		rawClinician,
	);
	const jobApps = rawJobApps
		? getFragmentData(ClinicianProfile_JobApplicationFDoc, rawJobApps)
		: [];

	const [editFormId, setEditFormId] = useState<string | null>(null);

	return (
		<>
			{!!editFormId && (
				<ProfileCompletePopup
					open={!!editFormId}
					onSuccess={() => setEditFormId(null)}
					onClose={() => setEditFormId(null)}
					initialStep={editFormId}
					skipPrompt
				/>
			)}
			<Grid container item xs={12} spacing={2} justifyContent='center'>
				<Grid container item direction='column' spacing={2}>
					<ProfileSection
						onEditClick={isOwner && (() => setEditFormId('search'))}
					>
						<ClinicianOverview clinician={clinician} email={email} />
					</ProfileSection>
					{(clinician.cv?.publicUrl || isOwner) && (
						<ProfileSection
							title='Curriculum Vitae'
							onEditClick={isOwner && (() => setEditFormId('cv'))}
						>
							<ClinicianCv
								clinician={clinician}
								onEditClick={isOwner ? () => setEditFormId('cv') : undefined}
								showCv={showCv}
								onOpenCv={() => setShowCv(true)}
								onCloseCv={() => setShowCv(false)}
							/>
						</ProfileSection>
					)}
					{!!jobApps?.length && (
						<ProfileSection title={`Job Applications (${jobApps.length})`}>
							<ClinicianJobApplications jobApps={jobApps} />
						</ProfileSection>
					)}
					<ProfileSection
						title='Licenses'
						onEditClick={
							isOwner && !clinician.fid && (() => setEditFormId('licenses'))
						}
					>
						<ClinicianLicenses clinician={clinician} />
					</ProfileSection>
					{clinician.isAPP ? (
						<>
							<ProfileSection
								title='Years of Experience'
								onEditClick={isOwner && (() => setEditFormId('spExp'))}
							>
								<ClinicianSpExp clinician={clinician} />
							</ProfileSection>
							<ProfileSection
								title='Certifications & Courses'
								onEditClick={isOwner && (() => setEditFormId('appCerts'))}
							>
								<ClinicianAPPCerts clinician={clinician} />
							</ProfileSection>
						</>
					) : (
						<ProfileSection
							title={'Board Certifications'}
							onEditClick={isOwner && (() => setEditFormId('board'))}
						>
							<ClinicianBoardCerts clinician={clinician} isOwner={isOwner} />
						</ProfileSection>
					)}
					<ProfileSection
						title={'Education & Training'}
						onEditClick={isOwner && (() => setEditFormId('school'))}
					>
						<EdTrainingTimeline clinician={clinician} />
					</ProfileSection>
					{clinician.isAPP && (
						<ProfileSection
							title='Skills & Procedures'
							onEditClick={isOwner && (() => setEditFormId('skills'))}
						>
							<ClinicianSkills clinician={clinician} />
						</ProfileSection>
					)}
					<Grid item flex='1' />
				</Grid>
			</Grid>
		</>
	);
};

export default ClinicianProfile;
