import React from 'react';

import { Box, Typography } from '@mui/material';

const FieldLabel = ({
	primary,
	secondary,
	optional = false,
	gutterBottom = false,
	...props
}) => {
	return (
		<Box mb={gutterBottom ? 1.5 : 0} {...props}>
			<Typography
				variant='subtitle1'
				component='div'
				gutterBottom={!!secondary}
			>
				{primary}
				{optional && (
					<Typography
						variant='subtitle2'
						component='span'
						fontStyle='italic'
						color='text.secondary'
					>
						{' - Optional'}
					</Typography>
				)}
			</Typography>
			{!!secondary && (
				<Typography variant='body1' component='div'>
					{secondary}
				</Typography>
			)}
		</Box>
	);
};

export default FieldLabel;
