import React from 'react';

import { SvgIcon } from '@mui/material';

const StarIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M12.6745 18.6643L17.0677 20.9032C17.6906 21.2202 18.4207 20.7128 18.3022 20.0449L17.4065 14.9802C17.3593 14.7126 17.4492 14.4397 17.6471 14.2531L21.4833 10.6275C21.984 10.1545 21.7052 9.33526 21.0111 9.23782L15.7252 8.49276C15.4479 8.45378 15.2088 8.28588 15.0859 8.04377L12.757 3.46772C12.4399 2.84409 11.5487 2.84409 11.2316 3.46772L8.90202 8.04377C8.77909 8.28588 8.53923 8.45378 8.26265 8.49276L2.97677 9.23707C2.28268 9.33451 2.00459 10.1538 2.50454 10.6267L6.34678 14.2576C6.54016 14.4405 6.62936 14.7088 6.58289 14.9712L5.68567 20.0442C5.56724 20.712 6.29806 21.2195 6.92019 20.9024L11.3133 18.6635C11.7398 18.4461 12.2465 18.4461 12.6745 18.6643Z'
				fill='#2682C5'
			/>
		</SvgIcon>
	);
};

export default StarIcon;
