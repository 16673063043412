import { EMRATypes } from '@ivy/constants/emra';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql, getFragmentData } from '@ivy/gql/types';
import { type FavoritesShow_QueryFavoritesQuery } from '@ivy/gql/types/graphql';

import { type BaseFavItem } from './FavoritesList';

export const FavoritesShow_FavoriteFDoc = gql(/* GraphQL */ `
	fragment FavoritesShow_Favorite on favorite {
		id
		type
		residency {
			id
			training {
				id
				name
				slug
			}
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						city
						state
						trainingSites: training_sites(where: { primary: { _eq: true } }) {
							facility {
								picture {
									publicUrl: public_url
								}
							}
						}
					}
				}
			}
		}
		clerkship {
			id
			training {
				id
				name
				slug
			}
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						city
						state
						trainingSites: training_sites(where: { primary: { _eq: true } }) {
							facility {
								picture {
									publicUrl: public_url
								}
							}
						}
					}
				}
			}
		}
		fellowship {
			id
			training {
				id
				name
				slug
			}
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						city
						state
						trainingSites: training_sites(where: { primary: { _eq: true } }) {
							facility {
								picture {
									publicUrl: public_url
								}
							}
						}
					}
				}
			}
		}
	}
`);

export const dataResolver = (
	data: FavoritesShow_QueryFavoritesQuery['favorite'],
): BaseFavItem[] => {
	if (!data) return [];

	return data.map((rawData) => {
		const favObject = getFragmentData(FavoritesShow_FavoriteFDoc, rawData);
		let newObject: BaseFavItem = {
			id: favObject.id,
			type: favObject.type,
		};

		switch (favObject.type) {
			case EMRATypes.RESIDENCY:
				const resTrainingSurvey =
					favObject.residency?.latestSurvey?.survey?.trainingSurvey ?? null;

				newObject = {
					...newObject,
					picture:
						(resTrainingSurvey?.trainingSites &&
							resTrainingSurvey?.trainingSites?.length > 0 &&
							resTrainingSurvey?.trainingSites[0].facility?.picture
								?.publicUrl) ||
						FACILITY_PLACEHOLDER_IMAGE,
					title: favObject.residency?.training.name,
					locationInfo: `${resTrainingSurvey?.city}, ${resTrainingSurvey?.state}`,
					surveyId: favObject.residency?.id,
					surveyTrainingSlug: favObject.residency?.training.slug,
				};
				break;
			case EMRATypes.CLERKSHIP:
				const clerkTrainingSurvey =
					favObject.clerkship?.latestSurvey?.survey?.trainingSurvey ?? null;
				newObject = {
					...newObject,
					picture:
						(clerkTrainingSurvey?.trainingSites &&
							clerkTrainingSurvey?.trainingSites?.length > 0 &&
							clerkTrainingSurvey?.trainingSites[0].facility?.picture
								?.publicUrl) ||
						FACILITY_PLACEHOLDER_IMAGE,
					title: favObject.clerkship?.training.name,
					locationInfo: `${clerkTrainingSurvey?.city}, ${clerkTrainingSurvey?.state}`,
					surveyId: favObject.clerkship?.id,
					surveyTrainingSlug: favObject.clerkship?.training.slug,
				};
				break;
			case EMRATypes.FELLOWSHIP:
				const fellTrainingSurvey =
					favObject.fellowship?.latestSurvey?.survey?.trainingSurvey ?? null;
				newObject = {
					...newObject,
					picture:
						(fellTrainingSurvey?.trainingSites &&
							fellTrainingSurvey?.trainingSites?.length > 0 &&
							fellTrainingSurvey?.trainingSites[0].facility?.picture
								?.publicUrl) ||
						FACILITY_PLACEHOLDER_IMAGE,
					title: favObject.fellowship?.training.name,
					locationInfo: `${fellTrainingSurvey?.city}, ${fellTrainingSurvey?.state}`,
					surveyId: favObject.fellowship?.id,
					surveyTrainingSlug: favObject.fellowship?.training.slug,
				};
				break;
		}
		return newObject;
	});
};
