import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, type ButtonProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const ControlButton = ({ sx, children, ...props }: ButtonProps) => {
	return (
		<Button
			variant='outlined'
			sx={combineSx(
				{
					pl: 1,
					py: 1,
					pr: 2,
				},
				sx,
			)}
			{...props}
		>
			<AddCircleIcon sx={{ mr: 1 }} />
			{children}
		</Button>
	);
};

export default ControlButton;
