import React, { type FC, useCallback, useEffect, useState } from 'react';

import {
	Button,
	Typography,
	useMediaQuery,
	type Theme,
	Stack,
	type ButtonProps,
} from '@mui/material';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { isCrawler } from '@ivy/lib/util/userAgent';

interface PopupOptions {
	enforced?: boolean;
}

interface FCPopupProps extends PopupProps {
	onConfirm: () => void;
}

const defaults: PopupOptions = {
	enforced: false,
};

function useMobileBreakPopup(opts: PopupOptions = {}) {
	const { enforced } = { ...defaults, ...opts };
	const [isOpen, setIsOpen] = useState(false);
	const isMobileMenu = useMediaQuery(
		(theme: Theme) => theme.breakpoints.down('mobileMenu'),
		{ noSsr: true },
	);
	const [belowBreak, setBelowBreak] = useState(isMobileMenu);

	const openPopup = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const closePopup = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const handleClose = useCallback(() => {
		closePopup();
	}, [closePopup]);

	useEffect(() => {
		if (!enforced) setBelowBreak(isMobileMenu);

		if (isOpen || !enforced) return;

		if (belowBreak && !isMobileMenu) {
			setBelowBreak(false);
			openPopup();
		} else if (!belowBreak && isMobileMenu) {
			setBelowBreak(true);
			openPopup();
		}
		// Do not look for dependents other than these for the popup will automatically popup when isOpen changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enforced, belowBreak, isMobileMenu]);

	const popupProps: FCPopupProps = {
		open: !isCrawler && isOpen,
		shrink: true,
		onClose: handleClose,
		onConfirm: handleClose,
	};

	return {
		isOpen,
		openPopup,
		closePopup,
		popupProps,
		BreakPopup: BreakPopup,
	} as useEnforcerResults;
}

const BreakPopup: FC<FCPopupProps> = ({
	onConfirm,
	onClose,
	...popupProps
}) => {
	const buttonProps: ButtonProps = {
		size: 'large',
		sx: { flex: 1, py: 1.5 },
	};

	return (
		<Popup
			{...popupProps}
			contentSx={{
				textAlign: 'center',
				pt: { xs: 0, sm: 1, md: 'auto' },
				pb: { xs: 2, sm: 5, md: 'auto' },
				px: { xs: 2, sm: 5, md: 'auto' },
			}}
			PaperProps={{ sx: { maxWidth: '450px' } }}
			onClose={onClose}
		>
			<Typography variant='h4' sx={{ mb: 2 }}>
				Your tour has been adjusted
			</Typography>
			<Typography variant='caption' sx={{ mb: 5 }}>
				Your onboarding tour has been reset due to a device switch. Restart the
				tour to ensure a smooth experience tailored to your current device.
			</Typography>
			<Stack direction='column' spacing={1}>
				<Button onClick={onClose} variant='outlined' {...buttonProps}>
					Close
				</Button>
				<Button onClick={onConfirm} variant='contained' {...buttonProps}>
					Restart tour
				</Button>
			</Stack>
		</Popup>
	);
};

interface useEnforcerResults {
	isOpen: boolean;
	openPopup: () => void;
	closePopup: () => void;
	popupProps: PopupProps;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	BreakPopup?: any;
}

export default useMobileBreakPopup;
