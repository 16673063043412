// Polyfill `IntersectionObserver` for virtualized scrolling and `useAnalyticsObserver`
// Limitations: https://www.npmjs.com/package/intersection-observer
import 'intersection-observer';
import React from 'react';

import AdapterDateFns from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
// Polyfill `replaceAll` since not all of our users' browsers support it
import replaceAll from 'string.prototype.replaceall';

import AppProvider from '@ivy/components/providers/AppProvider';
import config from '@ivy/config';
import { registerYupMethods } from '@ivy/lib/forms/formikHelpers';

import App from './App';

replaceAll.shim();
LicenseInfo.setLicenseKey(config.muiLicense || '');
registerYupMethods();

const container = document.getElementById('root');
const root = createRoot(container!);

// TODO: add strict mode back
// https://github.com/apollographql/apollo-client/issues/9819
// https://github.com/google-map-react/google-map-react/issues/1170
root.render(
	<LocalizationProvider dateAdapter={AdapterDateFns}>
		<AppProvider>
			<App />
		</AppProvider>
	</LocalizationProvider>,
);
