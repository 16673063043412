import React, { useLayoutEffect } from 'react';

import { useMutation } from '@apollo/client';
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { ArrowRightIcon, LogoIcon } from '@ivy/components/icons';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';
import { isAuthenticatedVar } from '@ivy/gql/reactives';
import { gql } from '@ivy/gql/types';
import { useErrorReporting } from '@ivy/lib/hooks';
import { storeAccessToken, storeRefreshToken } from '@ivy/lib/storage/token';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

const FinalizeSignup_ConfirmEmailMDoc = gql(/* GraphQL */ `
	mutation FinalizeSignup_ConfirmEmail($token: String!) {
		finalize: finalize_signup(token: $token) {
			access: access_token
			refresh: refresh_token
		}
	}
`);

const ALREADY_VERIFIED_ERROR_MSG = 'Verification token has already been used.';

// TODO: Add a (return to your other screen) notification
const FinalizeSignup = () => {
	const wl = useWhitelabel();
	const { token } = useParams();
	const [finalize, { loading, error }] = useMutation(
		FinalizeSignup_ConfirmEmailMDoc,
		{
			variables: {
				token: token || '',
			},
			// Refetch the confirmed email field (if logged in)
			refetchQueries: ['AuthProvider_CurrentAccount'],
			awaitRefetchQueries: true,
		},
	);
	const hasError = !!error && error.message !== ALREADY_VERIFIED_ERROR_MSG;
	const finalized = !hasError && !loading;
	useErrorReporting(hasError ? error : undefined);

	// Start the mutation before the first render with useLayoutEffect so it doesn't shutter when changing from
	// no loading -> loading
	useLayoutEffect(() => {
		const finalizeAndLogin = async () => {
			const response = await finalize();
			const data = response.data;
			if (data) {
				// Tokens must be set before changing isAuthenticatedVar for logout listener to work properly
				// Convention is refresh before access
				storeRefreshToken(data.finalize.refresh);
				storeAccessToken(data.finalize.access);
				isAuthenticatedVar(true);
			}
		};
		finalizeAndLogin();
	}, [finalize]);

	return (
		<SpotlightTemplate
			pageTitle='Finish Registration'
			pageNoIndex
			pageDescription='Finish registering for Ivy Clinicians.'
			preventBackNav
		>
			<Stack height='100%' px={2}>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					flex='1 1'
				>
					<Stack alignItems='center'>
						<LogoIcon
							sx={{
								height: '200px',
								width: 'auto',
								mb: 3,
							}}
							variant='image'
						/>
						<Typography variant='h3' component='h1' mb={3} align='center'>
							{hasError
								? 'Uh oh!'
								: loading
								? 'Verifying your email'
								: 'Your email has been verified!'}
						</Typography>
						{loading && <CircularProgress color='primary' />}
						{hasError && (
							<Typography
								variant='subtitle1'
								component='p'
								align='center'
								color='text.icon'
							>
								{error.message}
							</Typography>
						)}
						{finalized && (
							<>
								<Typography
									variant='subtitle1'
									component='p'
									align='center'
									mb={2}
									color='text.icon'
								>
									Please return to the other window to continue with{' '}
									<b>{wl.productName}</b>.
								</Typography>
								<Typography
									variant='subtitle1'
									component='p'
									align='center'
									color='text.icon'
								>
									You may now close this browser window.
								</Typography>
							</>
						)}
					</Stack>
				</Box>
				{!loading && (
					<Typography
						variant='body1'
						align='center'
						fontWeight='bold'
						flex='0 0'
						py={4}
					>
						{/* Replace so they don't come back to this page */}
						<RouteLink to={RouteLink.routes.ROOT} replace underline='hover'>
							<Box component='span' sx={{ verticalAlign: 'middle' }}>
								Take me to {wl.productName}
							</Box>
							<ArrowRightIcon
								sx={{
									ml: 1,
									fontSize: 'inherit',
									color: 'inherit',
									verticalAlign: 'middle',
								}}
							/>
						</RouteLink>
					</Typography>
				)}
			</Stack>
		</SpotlightTemplate>
	);
};

export default FinalizeSignup;
