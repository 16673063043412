import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMediaQuery, type Theme } from '@mui/material';
import Joyride, {
	STATUS,
	ACTIONS,
	LIFECYCLE,
	type Step,
	type Props,
	type CallBackProps,
} from 'react-joyride';
import { useLocation } from 'react-router-dom';

import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import {
	StepActions,
	StepPreActions,
	type StepStructure,
	type TourStructure,
} from '@ivy/components/providers/TourProvider/MainTour/TourStructure';
import {
	clickElement,
	resolveTargetSelector,
} from '@ivy/components/providers/TourProvider/MainTour/util';

import BaseTooltip from './BaseTooltip';

const formatedSteps = (tourSteps: StepStructure[], signal: string): Step[] => {
	return tourSteps.map((step) => {
		return {
			...step,
			content: step.content,
			target:
				signal === step.signal && step.signalTarget
					? step.signalTarget
					: step.target,
		};
	});
};

interface MasterTourProps extends Omit<Props, 'steps' | 'stepIndex'> {
	steps: TourStructure;
	onClose?: (complete: boolean) => void;
	signal: string;
}

interface CapturedAction {
	action?: StepActions | null;
	directionNext: boolean;
}

const MasterTour = ({
	callback,
	steps,
	onClose,
	run,
	signal,
	...props
}: MasterTourProps) => {
	const redirect = useRedirect();
	const { pathname } = useLocation();
	const [currStepIndex, setCurrStepIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [observedSelector, setObservedSelector] = useState('');
	const [capturedAction, setCapturedAction] = useState<CapturedAction | null>(
		null,
	);
	const isMobileMenu = useMediaQuery(
		(theme: Theme) => theme.breakpoints.down('mobileMenu'),
		{
			noSsr: true,
		},
	);

	const currentSteps =
		isMobileMenu && steps.mobileTourSteps
			? steps.mobileTourSteps
			: steps.tourSteps;

	const registerObservable = (
		selector: string | HTMLElement,
		shouldLoad = false,
	) => {
		const selectorString = resolveTargetSelector(selector);
		const elements = document.querySelectorAll(selectorString);
		if (elements.length > 0) {
			return true;
		} else {
			if (shouldLoad) setLoading(true);
			setObservedSelector(selectorString);
			return false;
		}
	};

	const handleRedirect = useCallback(
		async (origin: string) => {
			if (pathname !== origin) await redirect(origin);
		},
		[pathname, redirect],
	);

	const performPreAction = useCallback(
		async ({
			actionType,
			target,
		}: {
			target?: string;
			actionType?: StepPreActions | null;
		}) => {
			if (!target) return;

			if (actionType === StepPreActions.CLICK) {
				clickElement(target);
			}
		},
		[],
	);

	const performAction = useCallback(
		async ({
			actionType,
			target,
			nextStepTarget,
			nextIndex,
			origin,
			observe,
			directionNext,
		}: {
			target: string | HTMLElement;
			nextStepTarget: string | HTMLElement;
			nextIndex: number;
			directionNext: boolean;
			actionType?: StepActions | null;
			origin?: string;
			observe?: boolean;
		}) => {
			if (actionType === StepActions.NAV_CLICK && origin) {
				await handleRedirect(origin);
				if (registerObservable(target, true)) {
					setCapturedAction({
						action: StepActions.CLICK,
						directionNext: directionNext,
					});
					await performAction({
						actionType: StepActions.CLICK,
						target: target,
						nextStepTarget: nextStepTarget,
						nextIndex: currStepIndex,
						observe: observe,
						directionNext: directionNext,
					});
				}
			} else if (actionType === StepActions.NAV && origin) {
				await handleRedirect(origin);
				if (registerObservable(nextStepTarget, true)) {
					setCurrStepIndex(nextIndex);
				}
			} else if (actionType === StepActions.CLICK) {
				clickElement(target);
				if (observe) {
					if (registerObservable(nextStepTarget)) {
						setCurrStepIndex(nextIndex);
					}
				} else {
					setCurrStepIndex(nextIndex);
				}
			} else {
				setCurrStepIndex(nextIndex);
			}
		},
		[currStepIndex, handleRedirect],
	);

	const handlePrevPreAction = async (stp: StepStructure) => {
		const { prevPreAction } = stp;
		await performPreAction({
			actionType: prevPreAction?.type,
			target: prevPreAction?.target,
		});
	};
	const handlePrevAction = async (stp: StepStructure) => {
		const { prevAction, target } = stp;
		setCapturedAction({ action: prevAction?.type, directionNext: false });
		await performAction({
			actionType: prevAction?.type,
			target: prevAction?.altTarget || target,
			nextStepTarget:
				currentSteps[currStepIndex - 1 + (prevAction?.jump || 0)].target,
			nextIndex: currStepIndex - 1 + (prevAction?.jump || 0),
			origin: prevAction?.origin,
			observe: prevAction?.observe,
			directionNext: false,
		});
	};

	const handleNextPreAction = async (stp: StepStructure) => {
		const { nextPreAction } = stp;
		await performPreAction({
			actionType: nextPreAction?.type,
			target: nextPreAction?.target,
		});
	};
	const handleNextAction = async (stp: StepStructure) => {
		const { nextAction, target } = stp;
		setCapturedAction({ action: nextAction?.type, directionNext: true });
		await performAction({
			actionType: nextAction?.type,
			target: nextAction?.altTarget || target,
			nextStepTarget: currentSteps[currStepIndex + 1].target,
			nextIndex: currStepIndex + 1,
			origin: nextAction?.origin,
			observe: nextAction?.observe,
			directionNext: true,
		});
	};

	const handleStartTour = async () => {
		const { origin } = steps;
		if (origin) await handleRedirect(origin);
	};

	const handleCloseAction = (complete = false) => {
		onClose?.(complete);
	};

	const handleCallback = async (data: CallBackProps) => {
		callback?.(data);
		const { action, status, lifecycle, step, index, size } = data;

		if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
			handleCloseAction();
		} else if (status === STATUS.RUNNING) {
			// Signals the start of a step's lifecycle
			if (lifecycle === LIFECYCLE.INIT) {
				if (action === ACTIONS.START && currStepIndex === 0) {
					await handleStartTour();
				}
			} else if (lifecycle === LIFECYCLE.READY) {
				if (action === ACTIONS.NEXT) {
					await handleNextPreAction(step);
				} else if (action === ACTIONS.PREV) {
					await handlePrevPreAction(step);
				}
				// Signals the end of the step's lifecycle. This is the last action before moving on.
			} else if (lifecycle === LIFECYCLE.COMPLETE) {
				if (action === ACTIONS.CLOSE) {
					handleCloseAction();
				} else if (action === ACTIONS.NEXT) {
					if (index < size - 1) {
						await handleNextAction(step);
					} else {
						handleCloseAction(true);
					}
				} else if (action === ACTIONS.PREV) {
					await handlePrevAction(step);
				}
			}
		}
	};

	const handleMutationCallbackAction = useCallback(
		async (action: CapturedAction | null) => {
			if (!action || !action.action) return;
			const currentStep = currentSteps[currStepIndex];
			const directionValue = action.directionNext ? 1 : -1;

			if (
				[StepActions.NAV, StepActions.CLICK, StepActions.NAV_CLICK].includes(
					action.action,
				)
			) {
				if (action.action === StepActions.NAV_CLICK) {
					setCapturedAction({
						action: StepActions.CLICK,
						directionNext: action.directionNext,
					});
					if (action.directionNext) {
						await performAction({
							actionType: StepActions.CLICK,
							target: currentStep.nextAction?.altTarget || currentStep.target,
							nextStepTarget:
								currentSteps[currStepIndex + directionValue].target,
							nextIndex: currStepIndex + directionValue,
							observe: currentStep.nextAction?.observe,
							directionNext: true,
						});
					} else {
						await performAction({
							actionType: StepActions.CLICK,
							target: currentStep.prevAction?.altTarget || currentStep.target,
							nextStepTarget:
								currentSteps[
									currStepIndex +
										directionValue +
										(currentStep.prevAction?.jump || 0)
								].target,
							nextIndex:
								currStepIndex +
								directionValue +
								(currentStep.prevAction?.jump || 0),
							observe: currentStep.prevAction?.observe,
							directionNext: false,
						});
					}
				} else {
					setObservedSelector('');
					setCapturedAction(null);
				}
				if (action.action === StepActions.NAV) setLoading(false);
				if (!action.directionNext) {
					const jumpValue = currentStep.prevAction?.jump || 0;
					setCurrStepIndex(currStepIndex + directionValue + jumpValue);
				} else {
					setCurrStepIndex(currStepIndex + directionValue);
				}
			}
		},
		[currStepIndex, performAction, currentSteps],
	);

	// Prepares all matching children elements for impressions or clicks
	const checkChildren = useCallback(
		(node: Element | Document) => {
			if (!observedSelector) return;
			const matchedNodes = node.querySelectorAll(observedSelector);
			matchedNodes.forEach(async (match) => {
				if (match instanceof HTMLElement) {
					await handleMutationCallbackAction(capturedAction);
				}
			});
		},
		[observedSelector, capturedAction, handleMutationCallbackAction],
	);

	const mutationCallback = useCallback(
		(mutationsList: MutationRecord[]) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					checkChildren(document);
				}
			}
		},
		[checkChildren],
	);

	useEffect(() => {
		// Use a mutation observer to continue adding intersection observers and click handlers
		const mutationObserver = new MutationObserver(mutationCallback);
		mutationObserver.observe(document, {
			childList: true,
			subtree: true,
		});
		// Clean up resources to prevent memory leaks
		return () => mutationObserver.disconnect();
	}, [mutationCallback, loading]);

	const madeSteps = useMemo(() => {
		return formatedSteps(currentSteps, signal);
	}, [currentSteps, signal]);

	return (
		<Joyride
			{...props}
			run={!loading ? run : false}
			steps={madeSteps}
			callback={handleCallback}
			continuous
			stepIndex={currStepIndex}
			spotlightClicks={false}
			disableOverlayClose
			disableCloseOnEsc
			scrollOffset={isMobileMenu ? 100 : 300}
			styles={{
				options: {
					zIndex: 10000,
				},
			}}
			tooltipComponent={BaseTooltip}
		/>
	);
};

export default MasterTour;
