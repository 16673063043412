import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='19'
			height='19'
			viewBox='0 0 19 19'
			fill='none'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.849609 9.39502C0.849609 5.79988 0.849609 4.00231 1.7063 2.70757C2.08886 2.12941 2.584 1.63427 3.16216 1.25171C4.4569 0.39502 6.25447 0.39502 9.84961 0.39502C13.4447 0.39502 15.2423 0.39502 16.5371 1.25171C17.1152 1.63427 17.6104 2.12941 17.9929 2.70757C18.8496 4.00231 18.8496 5.79988 18.8496 9.39502C18.8496 12.9902 18.8496 14.7877 17.9929 16.0825C17.6104 16.6606 17.1152 17.1558 16.5371 17.5383C15.2423 18.395 13.4447 18.395 9.84961 18.395C6.25447 18.395 4.4569 18.395 3.16216 17.5383C2.584 17.1558 2.08886 16.6606 1.7063 16.0825C0.849609 14.7877 0.849609 12.9902 0.849609 9.39502ZM14.5089 9.39525C14.5089 11.9686 12.4228 14.0547 9.84945 14.0547C7.27613 14.0547 5.19004 11.9686 5.19004 9.39525C5.19004 6.82193 7.27613 4.73584 9.84945 4.73584C12.4228 4.73584 14.5089 6.82193 14.5089 9.39525ZM9.84945 12.4782C11.5521 12.4782 12.9325 11.0979 12.9325 9.39525C12.9325 7.69255 11.5521 6.31224 9.84945 6.31224C8.14675 6.31224 6.76645 7.69255 6.76645 9.39525C6.76645 11.0979 8.14675 12.4782 9.84945 12.4782ZM14.6929 5.59657C15.2975 5.59657 15.7877 5.1064 15.7877 4.50174C15.7877 3.89709 15.2975 3.40692 14.6929 3.40692C14.0882 3.40692 13.5981 3.89709 13.5981 4.50174C13.5981 5.1064 14.0882 5.59657 14.6929 5.59657Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
