import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeleteOutline } from '@mui/icons-material';
import { Box, Stack, Grid, FormHelperText } from '@mui/material';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import DataLoader from '@ivy/components/molecules/DataLoader';
import Dropdown from '@ivy/components/molecules/Dropdown';
import { ExperienceLevel, EXP_LEVEL_DISPLAY } from '@ivy/constants/clinician';
import { gql } from '@ivy/gql/types';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ProfileCompleteFormValues } from '../profileCompleteForm';

interface SpecialtyExperienceObj {
	id: string;
	field: string;
	level: ExperienceLevel | string;
}

interface ExperienceValues {
	spExp: SpecialtyExperienceObj[];
}

interface ExpFormProps {
	choices?: string[];
	formik: FormikWithNamespace<SpecialtyExperienceObj>;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

const Experience_GetSpecialtiesQDoc = gql(/* GraphQL */ `
	query Experience_GetSpecialties {
		specialties: medicare_specialties {
			name
		}
	}
`);

const ExpForm = withFormikNamespace<
	ExpFormProps,
	SpecialtyExperienceObj[],
	SpecialtyExperienceObj
>(({ formik, arrayHelpers, idx, choices = [], hideRemove }) => {
	const {
		values,
		touched,
		errors,
		setFieldTouched,
		setFieldValue,
		isSubmitting,
	} = formik;

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			alignItems={{ sm: 'flex-end', xs: 'flex-start' }}
			flex={1}
			spacing={2}
		>
			<Autocomplete
				freeSolo
				blurOnSelect
				autoHighlight
				selectOnFocus
				disableClearable
				TextFieldProps={{
					label: 'Specialty',
					placeholder: 'Enter a specialty',
					...getErrorState(formik, 'field'),
				}}
				options={choices}
				getOptionLabel={(option: string) => option}
				inputValue={values.field}
				onInputChange={(_, newValue) => setFieldValue('field', newValue)}
				onBlur={() => setFieldTouched('field')}
				fullWidth
				sx={{ maxWidth: '300px' }}
			/>
			<Stack
				direction={{ sm: 'row', xs: 'column' }}
				alignItems={{ sm: 'flex-end', xs: 'flex-start' }}
			>
				<Dropdown
					label='Years of Experience'
					value={values.level}
					placeholder='Select from range'
					onChange={(nv) => setFieldValue('level', nv)}
					onBlur={() => setFieldTouched('level')}
					helperText={touched.level && errors.level}
					error={touched.level && !!errors.level}
					options={Object.values(ExperienceLevel).map((el) => ({
						label: EXP_LEVEL_DISPLAY[el],
						value: el,
					}))}
					fullWidth
					sx={{ width: '200px' }}
				/>
				<Box
					display={hideRemove ? 'none' : 'block'}
					sx={{ ml: { sm: 2, xs: 0 } }}
				>
					<ControlButton
						startIcon={<DeleteOutline />}
						onClick={() => arrayHelpers.remove(idx)}
						disabled={isSubmitting}
					>
						Remove
					</ControlButton>
				</Box>
			</Stack>
		</Stack>
	);
});

const MAX_NO_SP_EXP = 10;

const validation = yup.object({
	spExp: yup
		.array()
		.of(
			yup.object({
				id: yup.string().when('$strict', {
					is: (val: boolean) => val,
					then: (schema) => schema.required(),
					otherwise: (schema) => schema.nullable(),
				}),
				field: yup
					.string()
					.max(200)
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					})
					.label('Specialty'),
				level: yup
					.string()
					.when('$strict', {
						is: (val: boolean) => val,
						then: (schema) =>
							schema.oneOf(Object.values(ExperienceLevel)).required(),
						otherwise: (schema) =>
							schema.oneOf(['', ...Object.values(ExperienceLevel)]).nullable(),
					})
					.label('Experience'),
			}),
		)
		.required()
		.max(MAX_NO_SP_EXP, `At most ${MAX_NO_SP_EXP} entries`)
		.test('no-dups', 'No duplicates allowed', (value) => {
			// Items may be falsy during this validation step, so filter those out
			const filtered = value?.filter((el) => !!el.field) || [];
			return new Set(filtered.map((el) => el.field)).size === filtered.length;
		})
		.label('Experience'),
});

const initialValue = {
	spExp: [
		{
			id: uuidv4(),
			field: '',
			level: '',
		},
	],
};

interface ExperienceProps {
	formik: FormikWithNamespace<SpecialtyExperienceObj[]>;
	onNext: () => void;
}

const Experience = withFormikNamespace<
	ExperienceProps,
	ProfileCompleteFormValues,
	SpecialtyExperienceObj[]
>(({ formik, onNext }) => {
	const { values, errors } = formik;

	// Note we don't have a place to show errors.spExp if it is a string
	const { data, loading, error } = useQuery(Experience_GetSpecialtiesQDoc);

	const choices = useMemo(() => {
		if (!data) {
			return [];
		}
		return data.specialties
			.map((el) => el.name)
			.filter((el) => values.every((spExp) => spExp.field !== el));
	}, [data, values]);

	return (
		<Box>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				variant='circular'
			>
				{() => (
					<Box>
						<FieldArray
							name={formik.getFullName()}
							render={(arrayHelpers) => (
								<Grid container direction='column' spacing={1}>
									{values.map((spExpObj, idx) => (
										<Grid container item key={spExpObj.id}>
											<ExpForm
												namespace={idx}
												formik={formik}
												arrayHelpers={arrayHelpers}
												idx={idx}
												choices={choices}
												hideRemove={values.length < 2}
											/>
										</Grid>
									))}
									<Grid
										item
										display='flex'
										justifyContent={{ sm: 'flex-start', xs: 'center' }}
									>
										<ModifyButton
											onClick={() =>
												arrayHelpers.push({
													id: uuidv4(),
													field: '',
													level: '',
												})
											}
										>
											Add Training
										</ModifyButton>
									</Grid>
									<Grid item>
										{typeof errors === 'string' && (
											<FormHelperText error>{errors}</FormHelperText>
										)}
									</Grid>
								</Grid>
							)}
						/>
					</Box>
				)}
			</DataLoader>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Box>
	);
});

export default Experience;
export {
	type ExperienceValues,
	type ExperienceProps,
	validation,
	initialValue,
};
