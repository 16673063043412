import React from 'react';

import { ErrorOutline, Person, Search } from '@mui/icons-material';
import {
	Box,
	Divider,
	Stack,
	type SxProps,
	type Theme,
	ThemeProvider,
	Typography,
	useMediaQuery,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { WishListIcon } from '@ivy/components/icons';
import { BaseFormPopup, FormButton } from '@ivy/components/organisms/FormPopup';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { useTour, Tours } from '@ivy/components/providers/TourProvider';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import { bubbleTheme as bt } from '@ivy/theme/bubble';

import ClinicianGraphic from './ClinicianGraphic';
import OrgUserGraphic from './OrgUserGraphic';

interface ActionCompProps {
	onClick: (event?: React.SyntheticEvent) => void;
	isClinician?: boolean;
	onClose?: (event?: React.SyntheticEvent<Element, Event>) => void;
	sx?: SxProps<Theme>;
}

const ActionComp = ({ onClick, isClinician, onClose, sx }: ActionCompProps) => {
	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			sx={combineSx(
				{ alignItems: 'center', width: { sm: 'auto', xs: '100%' }, mt: 3 },
				sx,
			)}
		>
			<FormButton
				variant='outlined'
				size='large'
				onClick={onClose}
				sx={{
					mb: { sm: 0, xs: 1 },
					ml: 0,
				}}
			>
				Do this later
			</FormButton>
			<Stack
				display='flex'
				sx={{
					ml: { sm: 3, xs: 0 },
					alignItems: 'center',
					position: 'relative',
				}}
				width={{ sm: 'auto', xs: '100%' }}
			>
				<FormButton
					variant='contained'
					size='large'
					onClick={onClick}
					sx={{
						ml: 0,
						mb: { sm: 0, xs: 3 },
					}}
				>
					{isClinician ? 'Create your profile' : 'Start product tour'}
				</FormButton>
				<Typography
					variant='caption'
					sx={{ position: 'absolute', bottom: { sm: '-24px', xs: 0 } }}
				>
					🕛 Takes around 3-4 minutes
				</Typography>
			</Stack>
		</Stack>
	);
};

enum ICONS_MAP {
	PERSON = 'person',
	SEARCH = 'search',
	WISHLIST = 'wishlist',
	ERROR = 'error',
}

interface FeatureItemObj {
	icon: ICONS_MAP;
	text: React.ReactNode;
}

const FeatureListItem = ({ icon, text }: FeatureItemObj) => {
	const renderIcon = (iconType: ICONS_MAP) => {
		const iconProps = {
			sx: {
				color: 'text.icon',
				verticalAlign: 'middle',
				mr: 2,
			},
		};

		switch (iconType) {
			case ICONS_MAP.PERSON:
				return <Person {...iconProps} />;
			case ICONS_MAP.SEARCH:
				return <Search {...iconProps} />;
			case ICONS_MAP.WISHLIST:
				return <WishListIcon {...iconProps} />;
			case ICONS_MAP.ERROR:
				return <ErrorOutline {...iconProps} />;
			default:
				return null;
		}
	};

	return (
		<>
			<Stack direction='row'>
				{renderIcon(icon)}
				<Typography variant='body1'>{text}</Typography>
			</Stack>
			<Divider />
		</>
	);
};

const clinicianList: FeatureItemObj[] = [
	{
		icon: ICONS_MAP.PERSON,
		text: 'Complete your profile by importing your credentials & uploading your CV.',
	},
	{
		icon: ICONS_MAP.SEARCH,
		text: 'Search for facilities by location and filter by your preferences.',
	},
	{
		icon: ICONS_MAP.WISHLIST,
		text: 'Apply to get in touch with the employer about job opportunities.',
	},
];

const orgUserList: FeatureItemObj[] = [
	{
		icon: ICONS_MAP.PERSON,
		text: 'Complete your employer profile to engage more clinicians.',
	},
	{
		icon: ICONS_MAP.ERROR,
		text: 'Make sure your facility and recruiter information is up-to-date.',
	},
	{
		icon: ICONS_MAP.WISHLIST,
		text: (
			<Box component='span'>
				<Typography
					component={RouteLink}
					to={{
						pathname: RouteLink.routes.FOR_EMPLOYERS,
						hash: 'JobPostingPlan_Container',
					}}
					color='secondary.main'
					underline='hover'
				>
					Sign up for a paid plan
				</Typography>{' '}
				to start posting jobs.{' '}
				<Typography component='span' fontStyle='italic' variant='caption'>
					(optional)
				</Typography>
			</Box>
		),
	},
];

interface CompleteSignupPopupProps {
	open: boolean;
	onClose?: (event?: React.SyntheticEvent<Element, Event>) => void;
}

const CompleteSignupPopup = ({ open, onClose }: CompleteSignupPopupProps) => {
	const { openProfilePopup } = useAuthPopup();
	const { startTour } = useTour();
	const redirect = useRedirect();
	const currAcc = useCurrentAccount();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	const isClinician = currAcc?.isClinician;
	const userFirstName = currAcc?.pi?.firstName;

	const handleClick = () => {
		if (isClinician) {
			openProfilePopup();
		} else {
			onClose?.();
			startTour(Tours.ORG_USER_ONBOARDING);
		}
	};

	const handleClickLater = () => {
		if (isClinician) {
			onClose?.();
		} else {
			onClose?.();
			redirect(RouteLink.routes.MANAGEMENT_HOME);
		}
	};

	const renderFeatureList = () => {
		const list = isClinician ? clinicianList : orgUserList;
		return (
			<Stack spacing={4.5}>
				{list.map((item, index) => (
					<FeatureListItem
						key={`feature-item-${index}`}
						icon={item.icon}
						text={item.text}
					/>
				))}
			</Stack>
		);
	};

	const renderGraphic = () => {
		if (isClinician) {
			return (
				<ClinicianGraphic
					sx={{
						pt: { sm: 15, xs: 9 },
						pb: { sm: 15, xs: 0 },
						display: {
							lg: 'block',
							xs: 'none',
						},
					}}
				/>
			);
		} else {
			return (
				<OrgUserGraphic
					sx={{
						pt: { sm: 15, xs: 9 },
						pb: { sm: 15, xs: 0 },
						display: {
							lg: 'flex',
							xs: 'none',
						},
					}}
				/>
			);
		}
	};

	return (
		<ThemeProvider theme={bt}>
			<BaseFormPopup
				open={open}
				onClose={onClose}
				actions={
					isMobile ? (
						<ActionComp
							onClick={handleClick}
							onClose={handleClickLater}
							isClinician={isClinician}
						/>
					) : undefined
				}
				fullWidth
				contentSx={{
					display: 'flex',
					form: { flex: 1 },
					m: 0,
					overflow: 'hidden',
				}}
			>
				<Stack
					direction='row'
					sx={{
						margin: 'auto',
						height: '100%',
						width: '100%',
					}}
				>
					<Stack
						direction={{ sm: 'row', xs: 'column' }}
						sx={{
							position: 'relative',
							ml: { md: 5, xs: 1 },
							mr: { lg: 10, md: 5, xs: 3 },
						}}
					>
						<Stack
							direction='column'
							justifyContent='space-between'
							sx={{
								maxWidth: { lg: '730px', xs: 'none' },
								minWidth: { lg: '550px', xs: 0 },
								overflowX: 'auto',
								pt: { sm: 15, xs: 9 },
								pb: { sm: 15, xs: 0 },
								px: { md: 5, xs: 1 },
							}}
						>
							<Stack>
								<Typography
									variant='h3'
									component='h1'
									textAlign={{ sm: 'start', xs: 'center' }}
									sx={{ mb: { sm: 15, xs: 10 } }}
								>
									✅ You’re all signed up, {userFirstName}!{' '}
									{isClinician
										? 'Are you ready for the next steps?'
										: 'Ready to get set up with our product tour?'}
								</Typography>
								{renderFeatureList()}
							</Stack>
							<ActionComp
								onClick={handleClick}
								onClose={handleClickLater}
								isClinician={isClinician}
								sx={{ display: !isMobile ? 'flex' : 'none' }}
							/>
						</Stack>
						{renderGraphic()}
					</Stack>
				</Stack>
			</BaseFormPopup>
		</ThemeProvider>
	);
};

export default CompleteSignupPopup;
export { type CompleteSignupPopupProps };
