import React from 'react';

import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { FeaturedIcon } from '@ivy/components/icons';
import DisclaimerToast from '@ivy/components/molecules/DisclaimerToast';
import { type MapLocation } from '@ivy/components/organisms/Map/BaseMap';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { SLUG2EMRA, EMRAPlural, EMRATypes } from '@ivy/constants/emra';
import { RouteUri } from '@ivy/constants/routes';
import {
	ZOOM_FACILITY,
	calculateRecommendedViewport,
} from '@ivy/lib/services/maps';
import { buildInternalLink } from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import {
	emraFeaturedClusterIcon,
	emraFeaturedClusterShadowIcon,
	emraFeaturedIcon,
	emraFeaturedShadowIcon,
} from './emraMarkers';
import { ClerkshipMap, ResidencyMap, FellowshipMap } from './maps';

const DEFAULT_LOCATION = {
	xMax: -54.62522844177653,
	xMin: -139.00022844177653,
	yMax: 71.96352173279212,
	yMin: -22.030132556290866,
};

const EmraSearch = () => {
	const { type: rawType, slug, page: rawPage } = useParams();
	const type = SLUG2EMRA[rawType!];
	const wl = useWhitelabel();

	// redirect if is invalid
	if (!type) {
		return <Navigate replace to={{ pathname: RouteUri.ROOT }} />;
	}

	const foundType = type.toLowerCase();
	const baseRoute = RouteUri.EMRA_SEARCH_INDEX.replace(':type', foundType);
	const pageRoute = RouteUri.EMRA_SEARCH_PAGE.replace(':type', foundType);

	const pathIsCanonicalRes = () => {
		if (rawPage === '1') {
			// Make sure the canonical is /emergency-medicine/physician/raleigh-nc and not /emergency-medicine/physician/raleigh-nc/1
			// for the first page
			return buildInternalLink(RouteUri.SEARCH_INDEX, {
				type: foundType!,
				slug: slug!,
			});
		}
		return true;
	};

	const resolveTitle = (loc?: string) => {
		if (loc) {
			return `${EMRAPlural[type]} in ${loc}`;
		}
		return `Search ${EMRAPlural[type].toLowerCase()}`;
	};

	const resolveHelmet = (loc?: string) => {
		if (loc) {
			const title = `${EMRAPlural[type]} in ${loc} | ${wl.productName}`;
			const description = `Apply to ${EMRAPlural[type]} in ${loc}. Finding your next emergency medicine job has never been easier.`;

			return (
				<Helmet>
					{!!title && <title>{title}</title>}
					{!!description && <meta name='description' content={description} />}
				</Helmet>
			);
		}
		return null;
	};

	const createPageDescription = (pageType: keyof typeof EMRATypes) => {
		let descr = '';
		switch (pageType) {
			case EMRATypes.RESIDENCY:
				descr =
					"EMRA Match's residency search provides a streamlined way to explore options. Utilize filters for location, program size, and teaching styles to find a residency that aligns with your goals. Detailed program profiles offer essential information to guide your medical career path.";
				break;
			case EMRATypes.CLERKSHIP:
				descr =
					"Find your ideal medical clerkship with EMRA Match's focused search tool. Select opportunities based on specialty and location, accessing comprehensive descriptions of each program. Our platform simplifies the process of choosing your clerkship, ensuring a tailored experience.";
				break;
			case EMRATypes.FELLOWSHIP:
				descr =
					'Navigate your fellowship selection effortlessly with EMRA Match. Our search tool, designed specifically for fellowships, allows you to filter by specialty areas and research opportunities. Comprehensive program details assist you in making an informed decision for your professional advancement.';
				break;
		}
		return descr;
	};

	const renderMap = (pageType: keyof typeof EMRATypes) => {
		const mapProps = {
			resolveTitle: resolveTitle,
			resolveHelmet: resolveHelmet,
			baseRoute: baseRoute,
			pageRoute: pageRoute,
			maxZoom: ZOOM_FACILITY,
			clustering: true,
			hideProfession: true,
			defaultLocation: {
				id: `precise-emra`,
				type: 'precise',
				label: '',
				center: {
					lat: (DEFAULT_LOCATION.yMin + DEFAULT_LOCATION.yMax) / 2,
					lng: (DEFAULT_LOCATION.xMin + DEFAULT_LOCATION.xMax) / 2,
				},
				viewport: calculateRecommendedViewport({
					xMin: DEFAULT_LOCATION.xMin,
					xMax: DEFAULT_LOCATION.xMax,
					yMin: DEFAULT_LOCATION.yMin,
					yMax: DEFAULT_LOCATION.yMax,
				}),
			} as MapLocation,
			featuredIcon: emraFeaturedIcon,
			featuredShadowIcon: emraFeaturedShadowIcon,
			featuredClusterIcon: emraFeaturedClusterIcon,
			featuredClusterShadowIcon: emraFeaturedClusterShadowIcon,
			badgeIcon: <FeaturedIcon />,
			badgeSxProps: {
				background: 'linear-gradient(180deg, #98367C 0%, #008D98 100%)',
			},
		};

		switch (pageType) {
			case EMRATypes.RESIDENCY:
				return <ResidencyMap types={['place', 'residency']} {...mapProps} />;
			case EMRATypes.CLERKSHIP:
				return <ClerkshipMap types={['place', 'clerkship']} {...mapProps} />;
			case EMRATypes.FELLOWSHIP:
				return <FellowshipMap types={['place', 'fellowship']} {...mapProps} />;
		}
	};

	return (
		<NavTemplate
			fullscreen
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
			}}
			pathIsCanonical={pathIsCanonicalRes()}
			pageTitle='Search Results'
			pageDescription={createPageDescription(type)}
		>
			{renderMap(type)}
			<DisclaimerToast
				sx={(theme) => ({
					// Beneath `SearchPreview` on desktop, not shown on mobile
					[theme.breakpoints.down('md')]: {
						display: 'none',
					},
					zIndex: 1,
				})}
			>
				💡 Looking to add or update program information?{' '}
				<RouteLink
					to={{
						pathname: RouteLink.routes.EMRA_TRAINING_SURVEY_CREATE,
						whitelabel: 'emra',
					}}
					openInNewTab
				>
					Click here.
				</RouteLink>
			</DisclaimerToast>
		</NavTemplate>
	);
};

export default EmraSearch;
