import React from 'react';

import { SvgIcon } from '@mui/material';

const YoutubeLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g id='youtube'>
				<path
					id='bg'
					d='M20.25 0H3.75C1.67893 0 0 1.67893 0 3.75V20.25C0 22.3211 1.67893 24 3.75 24H20.25C22.3211 24 24 22.3211 24 20.25V3.75C24 1.67893 22.3211 0 20.25 0Z'
					fill='#FF0000'
				/>
				<path
					id='youtube_2'
					d='M17.7 9.07505C17.55 8.55005 17.175 8.17505 16.65 8.02505C15.75 7.80005 11.925 7.80005 11.925 7.80005C11.925 7.80005 8.175 7.80005 7.2 8.02505C6.675 8.17505 6.3 8.55005 6.15 9.07505C6 10.05 6 12 6 12C6 12 6 13.95 6.225 14.925C6.375 15.45 6.75 15.825 7.275 15.975C8.175 16.2 12 16.2 12 16.2C12 16.2 15.75 16.2 16.725 15.975C17.25 15.825 17.625 15.45 17.775 14.925C18 13.95 18 12 18 12C18 12 18 10.05 17.7 9.07505ZM10.8 13.8V10.2L13.95 12L10.8 13.8Z'
					fill='white'
				/>
			</g>
		</SvgIcon>
	);
};

export default YoutubeLinkIcon;
