export const formatCityState = (city: string, state: string) => {
	return `${city}, ${state}`;
};

export const formatAddress = (
	streetAddress1: string,
	streetAddress2: string | null | undefined,
	city: string,
	state: string,
	zipcode: string,
) => {
	return `${streetAddress1}${
		streetAddress2 ? ', ' + streetAddress2 : ''
	}, ${city}, ${state} ${zipcode}`;
};
