import React from 'react';

import { Box, Radio, Typography } from '@mui/material';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
} from '@mui/material';

import { monthNames } from '@ivy/lib/formatting/date';

export interface MonthAvailabilityProps {
	label?: string;
	description?: string;
	error?: boolean;
	helperText?: string;
	availableLabel?: string;
	notAvailableLabel?: string;
	otherLabel?: string;
	value: (boolean | null | undefined)[];
	onChange: (
		ev: React.ChangeEvent<HTMLInputElement>,
		value: (boolean | null | undefined)[],
	) => void;
	disabled?: boolean;
}

const MonthAvailability = ({
	value,
	onChange,
	label,
	description,
	availableLabel,
	notAvailableLabel,
	otherLabel,
	error,
	helperText,
	disabled = false,
}: MonthAvailabilityProps) => {
	const handleChange =
		(idx: number, v: boolean | null) =>
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const nv = [...value];
			if (ev.target.checked) {
				nv[idx] = v;
				onChange(ev, nv);
			} else {
				nv[idx] = undefined;
				onChange(ev, nv);
			}
		};

	return (
		<Box
			sx={{
				maxWidth: '600px',
			}}
		>
			<Box>
				{!!label && (
					<Typography variant='body1' fontWeight='bold' mb={1}>
						{label}
					</Typography>
				)}
				{!!description && (
					<Typography variant='body1' mb={1}>
						{description}
					</Typography>
				)}
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Month</TableCell>
							<TableCell>{availableLabel || 'Available'}</TableCell>
							<TableCell>{notAvailableLabel || 'Not Available'}</TableCell>
							<TableCell>{otherLabel || 'Contact for Availability'}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{monthNames.map((month, idx) => (
							<TableRow key={month}>
								<TableCell>{month}</TableCell>
								<TableCell>
									<Radio
										checked={value?.[idx] === true}
										onChange={handleChange(idx, true)}
										disabled={disabled}
									/>
								</TableCell>
								<TableCell>
									<Radio
										checked={value?.[idx] === false}
										onChange={handleChange(idx, false)}
										disabled={disabled}
									/>
								</TableCell>
								<TableCell>
									<Radio
										checked={value?.[idx] === null}
										onChange={handleChange(idx, null)}
										disabled={disabled}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{!!helperText && (
				<Typography
					variant='caption'
					color={error ? 'error.main' : undefined}
					mt={1}
				>
					{helperText}
				</Typography>
			)}
		</Box>
	);
};

export default MonthAvailability;
