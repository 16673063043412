import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import config from '@ivy/config';
import WHITELABEL_CONFIGURATIONS, {
	type WhitelabelConfigName,
} from '@ivy/lib/whitelabel/whitelabelConfig';

const useWhitelabel = (name?: WhitelabelConfigName) => {
	const wl = useMemo(() => {
		if (name) {
			return WHITELABEL_CONFIGURATIONS.find((el) => el.name === name)!;
		}
		return WHITELABEL_CONFIGURATIONS.find(
			(el) => el.name === config.whitelabel,
		)!;
	}, [name]);

	const location = useLocation();
	const fullSso = useMemo(() => {
		if (!wl.menu.sso) {
			return undefined;
		}
		return `${wl.menu.sso}?return_to=${encodeURIComponent(
			window.location.href,
		)}`;
		// Notice we depend on location to tell us when window.location.href changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wl.menu.sso, location]);

	return {
		...wl,
		menu: {
			...wl.menu,
			fullSso,
		},
		default: WHITELABEL_CONFIGURATIONS.find((el) => el.name === 'default')!,
	};
};

export default useWhitelabel;
