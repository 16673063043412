import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import {
	Box,
	Chip,
	Stack,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';

import FacilitySuggestions from '@ivy/components/organisms/FacilitySuggestions';
import config from '@ivy/config';
import {
	Profession,
	PROFESSION2PROFSUCCINT,
	PROFESSION2PROFVERBOSE,
} from '@ivy/constants/clinician';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const FeaturedJobs_RankedFacilitiesQDoc = gql(/* GraphQL */ `
	query FeaturedJobs_RankedFacilities(
		$x: float8!
		$y: float8!
		$profession: profession!
		$limit: Int!
	) {
		rankedFacilities: ranked_facility_dist(
			args: { x: $x, y: $y }
			where: {
				_and: [
					{ profession: { _eq: $profession } }
					{ has_posting: { _eq: true } }
				]
			}
			order_by: { distance: asc }
			limit: $limit
		) {
			...FacilitySuggestions_RankedFacility
		}
	}
`);

interface LatLng {
	lat: number;
	lng: number;
}

export interface FeaturedJobsProps {
	sx?: SxProps<Theme>;
}

const FeaturedJobs = ({ sx }: FeaturedJobsProps) => {
	const currAcc = useCurrentAccount();
	const [prof, setProf] = useState(
		currAcc?.clinician?.profession || Profession.PHYSICIAN,
	);
	const [coords, setCoords] = useState<LatLng | null>(null);
	const { data, loading } = useQuery(FeaturedJobs_RankedFacilitiesQDoc, {
		variables: {
			x: coords?.lng,
			y: coords?.lat,
			profession: prof,
			limit: 30,
		},
		skip: !coords,
	});
	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	useEffect(() => {
		const determineLocation = async () => {
			const resp = await fetch(
				`https://ipinfo.io/json?token=${config.ipApiKey}`,
			);
			if (!resp.ok) {
				console.error(
					`IP lookup failed with status code ${resp.status}.`,
					resp,
				);
				throw new Error(`IP lookup failed with status code ${resp.status}.`);
			}
			let [lat, lng] = (await resp.json()).loc.split(',');
			lat = Number.parseFloat(lat);
			lng = Number.parseFloat(lng);
			setCoords({
				lat,
				lng,
			});
		};
		determineLocation();
	}, []);

	return (
		<Box component='section' sx={sx}>
			<Typography variant='h2' align='center' mb={5}>
				Featured jobs
			</Typography>
			<Stack
				direction='row'
				spacing={{
					xs: 2,
					sm: 6,
				}}
				justifyContent='center'
				mb={{
					xs: 3,
					sm: 10,
				}}
			>
				{Object.values(Profession).map((el) => (
					<Chip
						key={el}
						color='primary'
						label={
							isSm ? PROFESSION2PROFSUCCINT[el] : PROFESSION2PROFVERBOSE[el]
						}
						sx={{
							fontWeight: 'bold',
							borderStyle: el !== prof ? 'none' : undefined,
							color: el !== prof ? 'text.icon' : undefined,
						}}
						clickable={el !== prof}
						variant={el !== prof ? 'outlined' : 'filled'}
						onClick={() => setProf(el)}
					/>
				))}
			</Stack>
			<FacilitySuggestions
				rankedFacilities={data?.rankedFacilities}
				// Show loading if no results / error
				loading={loading || !data?.rankedFacilities?.length}
				backNavTarget='home'
			/>
		</Box>
	);
};

export default FeaturedJobs;
