import { type UnpreparedIcon } from './common';

// Note PNG is better than SVG for performance, so could switch to that if need be
// https://www.youtube.com/watch?v=rU9OcRrNkVg

export const defaultPrimaryIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-default.svg',
	size: {
		width: 30,
		height: 41,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 15,
		y: 41,
	},
} as UnpreparedIcon;

export const defaultPrimaryShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-default-shadow.svg',
	size: {
		width: 50,
		height: 61,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 25,
		y: 55,
	},
} as UnpreparedIcon;

export const defaultFeaturedIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-featured.svg',
	size: {
		width: 30,
		height: 42,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 15,
		y: 42,
	},
} as UnpreparedIcon;

export const defaultFeaturedShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-featured-shadow.svg',
	size: {
		width: 50,
		height: 62,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 25,
		y: 55,
	},
} as UnpreparedIcon;

export const defaultClusterIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-cluster.svg',
	size: {
		width: 40,
		height: 40,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 20,
		y: 40,
	},
} as UnpreparedIcon;

export const defaultClusterShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-cluster-shadow.svg',
	size: {
		width: 60,
		height: 60,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 30,
		y: 50,
	},
} as UnpreparedIcon;

export const defaultFeaturedClusterIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-featured-cluster.svg',
	size: {
		width: 40,
		height: 40,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 20,
		y: 40,
	},
} as UnpreparedIcon;

export const defaultFeaturedClusterShadowIcon = {
	url: 'https://assets.ivyclinicians.io/markers/marker-featured-cluster-shadow.svg',
	size: {
		width: 60,
		height: 60,
	},
	origin: {
		x: 0,
		y: 0,
	},
	anchor: {
		x: 30,
		y: 50,
	},
} as UnpreparedIcon;
