import React, { useState, useEffect } from 'react';

import { Add, Tune, Search } from '@mui/icons-material';
import {
	Box,
	type SxProps,
	type Theme,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
	useMediaQuery,
} from '@mui/material';

import Badge from '@ivy/components/atoms/Badge';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { useBillingContext } from '@ivy/components/providers/BillingProvider';
import { useDebounce } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

interface SearchBarProps {
	sx?: SxProps<Theme>;
	searchTerm?: string;
	onTextChange: (newValue: string) => void;
	setFiltersPopupOpen: (newValue: boolean) => void;
	filtersActive?: boolean;
}

const SearchBar = ({
	filtersActive,
	onTextChange,
	setFiltersPopupOpen,
	sx,
	searchTerm = '',
}: SearchBarProps) => {
	const theme = useTheme();
	const debouncedSearch = useDebounce(searchTerm, 1000);
	const { subscription } = useBillingContext();
	const [initialized, setInitialized] = useState(false);

	const hideQuickFilters = useMediaQuery(theme.breakpoints.down('md'));

	const handleFilterPopupClicked = () => {
		setFiltersPopupOpen(true);
	};

	const isMobile = useMediaQuery(theme.breakpoints.down('gridBreak'), {
		noSsr: true,
	});

	useEffect(() => {
		// This exists to prevent the initial search from triggering a second search on mount since we already hit the API with the initial search term
		if (!initialized) {
			setInitialized(true);
			return;
		}
		onTextChange(debouncedSearch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, onTextChange]);

	return (
		<Box display='flex' flexDirection='row' sx={sx}>
			<TextField
				placeholder='Search job postings'
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							{hideQuickFilters ? (
								<IconButton
									size='small'
									onClick={handleFilterPopupClicked}
									sx={{
										color: 'text.icon',
										display: {
											md: 'none',
										},
										border: () => `1px solid ${theme.palette.divider}`,
										...(filtersActive && {
											color: 'primary.main',
											bgcolor: 'primary.translucent',
											borderColor: 'primary.main',
										}),
									}}
								>
									<Badge
										color='primary'
										badgeContent={filtersActive ? 1 : 0}
										variant='dot'
									>
										<Tune />
									</Badge>
								</IconButton>
							) : (
								<Search />
							)}
						</InputAdornment>
					),
					sx: {
						pl: 1,
						borderRadius: '999px !important',
						bgcolor: 'white',
					},
				}}
				value={searchTerm}
				onChange={(env) => onTextChange(env.target.value)}
				variant='outlined'
				sx={combineSx(
					{
						width: isMobile ? '100%' : '500px',
						maxWidth: '100%',
					},
					sx,
				)}
			/>
			<Box display='flex' sx={{ ml: 3 }}>
				<IconButton
					disabled={
						!subscription ||
						!subscription.active ||
						(subscription?.usedSlots && subscription?.slots
							? subscription?.usedSlots >= subscription?.slots
							: false)
					}
					component={RouteLink}
					to={RouteLink.routes.JOB_POSTING_CREATE}
					state={{
						backNav: {
							target: 'jobs postings',
						},
					}}
					sx={{
						display: { xs: 'inline-flex', gridBreak: 'none' },
						backgroundColor: theme.palette.secondary.main,
						'&:hover': {
							backgroundColor: theme.palette.secondary.main,
						},
						'&.Mui-disabled': {
							backgroundColor: theme.palette.light3.main,
						},
						color: theme.palette.secondary.contrastText,
						margin: 'auto',
					}}
				>
					<Add />
				</IconButton>
			</Box>
		</Box>
	);
};

export default SearchBar;
