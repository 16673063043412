import React from 'react';

import {
	Stack,
	Typography,
	Box,
	type SxProps,
	type Theme,
} from '@mui/material';

import SupportLink from '@ivy/components/atoms/SupportLink';
import { combineSx } from '@ivy/lib/styling/sx';

import ResendVerification, {
	type ResendVerificationProps,
} from './ResendVerification';

export interface ConfirmEmailSectionProps {
	email: string;
	sx?: SxProps<Theme>;
	disabled?: ResendVerificationProps['disabled'];
}

const ConfirmEmailSection = ({
	email,
	sx,
	disabled,
}: ConfirmEmailSectionProps) => {
	return (
		<Stack
			spacing={5}
			sx={combineSx(
				{
					alignItems: 'center',
					maxWidth: '600px',
					mx: 'auto',
				},
				sx,
			)}
		>
			<Typography component='h1' variant='h3' align='center'>
				Check your email for a link to complete signing up.
			</Typography>
			<Typography variant='body1' color='text.icon' align='center'>
				{/* TODO: To make sure you can receive replies from recruiters about your job app... */}
				We've sent a link to <b>{email}</b> to verify your email. It may take a
				minute to arrive. If you don't see it after a couple minutes,{' '}
				<b>check your spam folder</b> or{' '}
				<SupportLink>contact support.</SupportLink>
			</Typography>
			<Box
				component='img'
				src='https://assets.ivyclinicians.io/images/email-verification.svg'
				sx={{
					maxWidth: '100%',
				}}
			/>
			<Box sx={{ textAlign: 'center' }}>
				<ResendVerification
					align='center'
					email={email}
					variant='body1'
					color='text.icon'
					text={"Don't see it?"}
					disabled={disabled}
				/>
			</Box>
		</Stack>
	);
};

export default ConfirmEmailSection;
