import React, { createContext, useContext, useMemo, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import isEqual from 'lodash/isEqual';

import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const STORAGE_AB_TEST_KEY = 'ivy_ab_test';

interface GroupConfiguration {
	group: string;
}

interface ConnectionConfiguration extends GroupConfiguration {
	menuItem: string;
	searchInstructions: string;
	alreadyConnectedText: string;
	noConnectionsText: string;
	pastTense: string;
	pastTenseAlt: string;
	popupTitle: string;
	noun: {
		singular: string;
		plural: string;
	};
	buttonText: {
		short: string;
		normal: {
			singular: string;
			plural: string;
		};
	};
}

interface ABConfigurationOptions {
	connection: ConnectionConfiguration[];
}

interface ABConfiguration {
	connection: ConnectionConfiguration;
}

const AB_CONFIG_OPTIONS: ABConfigurationOptions = {
	connection: [
		{
			group: 'b',
			menuItem: 'Applications',
			searchInstructions: 'Select the EM practices you want to contact.',
			alreadyConnectedText: 'You contacted this EM practice',
			noConnectionsText: "You haven't contacted any EM practices yet.",
			pastTense: 'Contacted',
			pastTenseAlt: 'contacted',
			noun: {
				singular: 'Application',
				plural: 'Applications',
			},
			popupTitle: 'Contact',
			buttonText: {
				short: 'Contact',
				normal: {
					singular: 'Contact this EM practice',
					plural: 'Contact these EM practices',
				},
			},
		},
		{
			group: 'c',
			menuItem: 'Applications',
			searchInstructions: 'Select the EM practices you want to apply to.',
			alreadyConnectedText: 'You applied to this EM practice',
			noConnectionsText: "You haven't applied to any EM practices yet.",
			pastTense: 'Applied',
			pastTenseAlt: 'applied to',
			noun: {
				singular: 'Application',
				plural: 'Applications',
			},
			popupTitle: 'Apply to',
			buttonText: {
				short: 'Apply',
				normal: {
					singular: 'Apply to this EM practice',
					plural: 'Apply to these EM practices',
				},
			},
		},
	],
};

export const ABContext = createContext<ABConfiguration>(undefined!);

// Mutation is designed to update the cache of current_account
const ABProvider_PickABTestsMDoc = gql(/* GraphQL */ `
	mutation ABProvider_PickABTests($abTests: [ab_test_insert_input!]!) {
		insert_ab_test(
			objects: $abTests
			on_conflict: {
				constraint: uq_ab_test_account_id_test
				update_columns: [group]
			}
		) {
			returning {
				id
				currentAccount: current_account {
					id
					abTests: ab_tests {
						id
						test
						group
					}
				}
			}
		}
	}
`);

const getLocalStorageABConfig = () => {
	const rawStorage = localStorage.getItem(STORAGE_AB_TEST_KEY);
	return rawStorage ? JSON.parse(rawStorage) : {};
};

const ABProvider = ({ children }) => {
	const currAcc = useCurrentAccount();
	const [pickABTest] = useMutation(ABProvider_PickABTestsMDoc);

	const config = useMemo(() => {
		// Older tests that no longer exist in AB_CONFIG_OPTIONS are not included
		return Object.entries(AB_CONFIG_OPTIONS).reduce((tot, [test, options]) => {
			const validGroups = options.map((el) => el.group);

			// Try to determine what group the user belongs to
			let group;

			// First, check the currAcc object
			const currAccGroup = currAcc?.abTests.find((el) => el.test === test)
				?.group;
			// For each step, we check if the group still exists or if it was eliminated from the test
			if (currAccGroup && validGroups.includes(currAccGroup)) {
				group = currAccGroup;
			} else {
				// Then, check local storage
				const storedGroup = getLocalStorageABConfig()[test];
				if (storedGroup && validGroups.includes(storedGroup)) {
					group = storedGroup;
				} else {
					group = options[Math.floor(Math.random() * options.length)].group;

					// Update local storage
					localStorage.setItem(
						STORAGE_AB_TEST_KEY,
						JSON.stringify({
							...getLocalStorageABConfig(),
							[test]: group,
						}),
					);
				}
			}

			return {
				...tot,
				[test]: options.find((el) => el.group === group),
			};
		}, {});
	}, [currAcc]) as ABConfiguration;

	useEffect(() => {
		if (!currAcc) {
			// App is initializing or not logged in
			return;
		}
		const computedConfig: { [k: string]: string } = Object.entries(
			config,
		).reduce(
			(tot, [k, v]) => ({
				...tot,
				[k]: v.group,
			}),
			{},
		);

		const accConfig = currAcc.abTests
			// If tests no longer exist, we remove those from our comparison but keep for historical archiving
			// in case we add the test back
			.filter((el) => Object.keys(AB_CONFIG_OPTIONS).includes(el.test))
			.reduce(
				(tot, abTest) => ({
					...tot,
					[abTest.test]: abTest.group,
				}),
				{},
			);

		if (!isEqual(accConfig, computedConfig)) {
			// This will usually run for new accounts or if a new config key was added
			pickABTest({
				variables: {
					abTests: Object.entries(computedConfig).map(([k, v]) => ({
						test: k,
						group: v,
					})),
				},
			});
		}
	}, [currAcc, config, pickABTest]);

	return <ABContext.Provider value={config}>{children}</ABContext.Provider>;
};

export default ABProvider;

export const useABTest = () => useContext(ABContext);
