import React, {
	useState,
	useCallback,
	useLayoutEffect,
	useEffect,
	useRef,
	memo,
} from 'react';

import { Tune } from '@mui/icons-material';
import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
	Stack,
	useMediaQuery,
} from '@mui/material';

import Badge from '@ivy/components/atoms/Badge';
import { BlobDoubleLayer } from '@ivy/components/atoms/Blobs';
import { useDebounce } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

const keyframes = {
	'@keyframes landing-load': {
		'0%': {
			transform: 'translateY(60px)',
			opacity: 0,
		},
		'100%': {
			transform: 'translateY(0px)',
			opacity: 1,
		},
	},
};

const generateKeyframes = (timing) => ({
	...keyframes,
	animation: `landing-load 600ms ease-in-out ${timing}ms`,
	animationFillMode: 'forwards',
	transform: 'translateY(60px)',
	opacity: 0,
});

const HeroCurve = ({ fill, ...props }) => {
	return (
		<SvgIcon
			width='100%'
			height='100%'
			viewBox='0 0 200 200'
			xmlns='http://www.w3.org/2000/svg'
			preserveAspectRatio='none'
			{...props}
		>
			<defs>
				<linearGradient id='Gradient2' x1='0' x2='0' y1='0' y2='1'>
					<stop offset='0%' stopColor='red' />
					<stop offset='50%' stopColor='black' stopOpacity='0' />
					<stop offset='100%' stopColor='blue' />
				</linearGradient>
			</defs>
			<path
				d='M0 0H200V189.78C57.3437 -104.929 34.4267 362.021 0 135.624V0Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};

const Spiral = ({ ...props }) => {
	return (
		<SvgIcon
			width='915'
			height='863'
			viewBox='0 0 915 863'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.1'
				d='M456.002 281.5C418.502 189.5 186.502 234.383 186.502 429.5C186.502 624.617 346.984 678.202 522.493 678.202C698.002 678.202 858.37 575.375 858.37 342.325C858.37 109.274 664.502 1 413.002 1C161.502 1 1.60171 126.951 1.60156 376.475C1.60142 626 128.18 861.715 486.841 861.715C845.501 861.715 913.912 575.375 913.912 575.375'
				stroke='#1EC86A'
				strokeWidth='1.50113'
				strokeLinecap='round'
			/>
		</SvgIcon>
	);
};

const Graphics = () => {
	return (
		<>
			<BlobDoubleLayer
				sx={{
					display: {
						xs: 'none',
						gridBreak: 'initial',
					},
					position: 'absolute',
					width: '276.11px',
					height: '269.42px',
					right: '121.76px',
					top: {
						lg: 'calc(160px - 110px)',
						gridBreak: 'calc(160px - 130px)',
						xs: 'calc(160px - 64px)',
					},
				}}
			/>
			<Spiral
				sx={{
					display: {
						gridBreak: 'none',
					},
					position: 'absolute',
					width: '330px',
					height: '322px',
					right: '121.76px',
					top: {
						xs: '32px',
					},
					fill: 'none',
				}}
			/>
		</>
	);
};

const BG = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				position: 'absolute',
				width: '100vw',
				left: '50%',
				right: '50%',
				ml: '-50vw',
				mr: '-50vw',
				top: 0,
				bottom: 0,
				filter: {
					gridBreak: 'none',
					xs: 'drop-shadow(0px 16px 40px rgba(142, 149, 155, 0.3))',
				},
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: 'calc(95% - 1vw)',
					bgcolor: theme.palette.light4.main,
				}}
			/>
			<Box
				sx={{
					width: '100%',
					height: 'calc(5% + 1vw)',
					margin: {
						gridBreak: 0,
						xs: '-1px',
					},
					bgcolor: {
						gridBreak: theme.palette.light4.main,
						xs: 'none',
					},
				}}
			>
				<HeroCurve
					sx={{
						width: '100%',
						height: '100%',
						display: {
							gridBreak: 'none',
							xs: 'block',
						},
					}}
					fill={theme.palette.light4.main}
				/>
			</Box>
		</Box>
	);
};

interface HeroProps {
	sx?: SxProps<Theme>;
	searchTerm?: string;
	onTextChange: (newValue: string) => void;
	setFiltersPopupOpen: (newValue: boolean) => void;
	filtersActive?: boolean;
	onClickPopup?: () => void;
	count?: number;
}

const HeroGraphics = memo(Graphics);
const HeroBG = memo(BG);

const Hero = ({
	filtersActive,
	onTextChange,
	setFiltersPopupOpen,
	sx,
	onClickPopup,
	count,
	searchTerm = '',
}: HeroProps) => {
	const theme = useTheme();
	const [heroRef, setRef] = useState<HTMLDivElement | null>(null);
	const [search, setSearch] = useState(searchTerm);
	const debouncedSearch = useDebounce(search, 1000);
	const [initialized, setInitialized] = useState(false);
	const heroNode = useCallback((node) => node && setRef(node), []);
	const searchRef = useRef<HTMLDivElement>(null);

	const handleTextChange = useCallback(
		(ev) => {
			setSearch(ev.target.value);
		},
		[setSearch],
	);

	const handleFilterPopupClicked = () => {
		setFiltersPopupOpen(true);
	};

	const isMobile = useMediaQuery(theme.breakpoints.down('gridBreak'), {
		noSsr: true,
	});
	const isSm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
	const heroPadding = 64 + 56 + (isSm ? 30 : 48);

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (!heroRef || !searchRef.current || !isMobile) {
				return;
			} else if (!isMobile) {
				searchRef.current.style.top = '0px';
				return;
			}

			const searchThreshold = isSm ? 20 : 60;
			const threshold = heroRef?.clientHeight - (heroPadding + searchThreshold);

			if (
				document.body.scrollTop > threshold ||
				document.documentElement.scrollTop > threshold
			) {
				searchRef.current.style.top = `${searchThreshold}px`;
			} else {
				searchRef.current.style.top = '0px';
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isMobile, isSm, heroRef, theme, heroPadding]);

	useEffect(() => {
		// This exists to prevent the initial search from triggering a second search on mount since we already hit the API with the initial search term
		if (!initialized) {
			setInitialized(true);
			return;
		}
		onTextChange(debouncedSearch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch, onTextChange]);

	return (
		<Box
			ref={heroNode}
			component='section'
			sx={combineSx(
				{
					position: {
						gridBreak: 'relative',
						xs: 'sticky',
					},
					mt: {
						xs: '-64px',
						mobileMenu: '-104px',
					},
					pt: {
						xs: '64px',
						mobileMenu: '104px',
					},
					pb: {
						gridBreak: 0,
						xs: 6,
					},
					top: {
						gridBreak: '0',
						xs: `calc(-${heroRef?.clientHeight || 0}px + ${heroPadding}px)`,
					},
					px: {
						gridBreak: 3,
						xs: 0,
					},
				},
				sx,
			)}
		>
			<HeroBG />
			<Box
				sx={{
					position: {
						gridBreak: 'relative',
						xs: 'sticky',
					},
					height: '100%',
					[theme.breakpoints.between('md', 'gridBreak')]: {
						display: 'flex',
						justifyContent: 'center',
						// alignItems: 'center',
					},
				}}
			>
				<HeroGraphics />
				<Box
					sx={{
						pt: {
							xs: 4,
							sm: 8,
							// Position in true center by offsetting the NavBar
							// Use md here, not mobileMenu
							md: '-104px',
						},
						pb: {
							gridBreak: 0,
							sm: 6,
							xs: 0,
						},
						height: {
							xs: '100%',
							md: 'auto',
						},
						position: 'relative',
						display: 'flex',
						flexDirection: 'column',
						alignItems: {
							sm: 'left',
						},
						maxWidth: { gridBreak: '800px', xs: '900px' },
					}}
				>
					<Stack
						direction={{
							mobileMenu: 'row',
							xs: 'column-reverse',
						}}
						alignItems='center'
						sx={{
							mb: {
								sm: 5,
								xs: 4,
							},
							alignItems: 'flex-start',
						}}
					>
						<Typography
							component='h1'
							variant='h2'
							sx={{
								textAlign: 'left',
								flex: '0 0 auto',
								maxWidth: '540px',
								...generateKeyframes(0),
							}}
						>
							Compare Emergency Medicine Salaries
						</Typography>
					</Stack>
					<Typography
						variant='h6'
						component='p'
						sx={{
							mb: {
								sm: 5,
								xs: 5,
							},
							textAlign: {
								sm: 'left',
							},
							flex: '0 0 auto',
							color: 'text.icon',
							...generateKeyframes(200),
						}}
					>
						To access{' '}
						{count
							? `${count} reported clinician ${
									count === 1 ? 'salary' : 'salaries'
							  }`
							: 'reported clinician salaries'}
						,{' '}
						<Box
							component='span'
							sx={{
								color: theme.palette.primary.main,
								textDecorationColor: 'rgba(38, 130, 197, 0.4)',
								cursor: 'pointer',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
							onClick={onClickPopup}
						>
							enter your current salary anonymously
						</Box>{' '}
						(takes 1 min). Your info helps your peers get paid fairly.
					</Typography>
					<Box
						sx={{
							...generateKeyframes(400),
						}}
					>
						<TextField
							id='input-salary-search'
							ref={searchRef}
							placeholder='Search by location or employer'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											size='small'
											onClick={handleFilterPopupClicked}
											sx={{
												color: 'text.icon',
												display: {
													gridBreak: 'none',
													xs: 'block',
												},
												border: () => `1px solid ${theme.palette.divider}`,
												...(filtersActive && {
													color: 'primary.main',
													bgcolor: 'primary.translucent',
													borderColor: 'primary.main',
												}),
											}}
										>
											<Badge
												color='primary'
												badgeContent={filtersActive ? 1 : 0}
												variant='dot'
											>
												<Tune />
											</Badge>
										</IconButton>
									</InputAdornment>
								),
								sx: {
									pl: 1,
									borderRadius: '999px !important',
									bgcolor: 'white',
									display: {
										gridBreak: 'none',
										xs: 'flex',
									},
								},
							}}
							value={search}
							onChange={handleTextChange}
							variant='outlined'
							sx={{
								width: isMobile ? '100%' : '600px',
								maxWidth: '100%',
								transition: 'top 0.4s',
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Hero;
