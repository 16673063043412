import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const useHashScroller = (disabled = false) => {
	const location = useLocation();
	// Scroll to hash if it exists
	useEffect(() => {
		const hash = location.hash;
		if (hash && !disabled) {
			// Add a delay to allow the page to finish rendering
			const delayToAllowRendering = 400; // Adjust time as necessary
			const timeoutId = setTimeout(() => {
				const element = document.querySelector(hash);

				if (element) {
					// Get the element's position relative to the viewport
					const rect = element.getBoundingClientRect();

					// Check if the element is already in view
					const isInView =
						rect.top <
							(window.innerHeight || document.documentElement.clientHeight) &&
						rect.bottom > 0;

					if (!isInView) {
						// Log the pre-scroll position
						const scrollToElement = (attempts = 0, maxAttempts = 2) => {
							// Scroll into view
							element.scrollIntoView({ behavior: 'smooth', block: 'start' });

							// After scroll, verify the element's position using requestAnimationFrame
							requestAnimationFrame(() => {
								const newRect = element.getBoundingClientRect();

								// Check if the element is within 50px of the top of the viewport
								const threshold = 50;
								if (newRect.top > threshold && attempts < maxAttempts) {
									// Retry scrolling after a short delay
									setTimeout(() => {
										scrollToElement(attempts + 1);
									}, delayToAllowRendering); // Delay between retries
								}
							});
						};

						// Call the scroll function with initial attempt count
						scrollToElement();
					}
				}
			}, delayToAllowRendering); // Delay before running the hash check

			// Clean up the timeout when the component unmounts or dependencies change
			return () => clearTimeout(timeoutId);
		}
	}, [location, disabled]);
};

export default useHashScroller;
