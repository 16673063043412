import React from 'react';

import { SvgIcon } from '@mui/material';

const StarIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M13.1286 17.7733L12.6894 18.6351L13.1286 17.7733C12.4159 17.4101 11.5716 17.4095 10.8593 17.7725C10.8593 17.7725 10.8593 17.7725 10.8593 17.7725L6.73083 19.8766L7.56756 15.1456C7.67296 14.5506 7.47066 13.9441 7.0339 13.5311L7.03361 13.5308L3.48313 10.1756L8.40208 9.48299L8.40219 9.48297C8.98909 9.40027 9.51683 9.04151 9.79352 8.49679C9.79357 8.49669 9.79362 8.49659 9.79367 8.4965L11.9943 4.17383L14.1942 8.4965C14.1943 8.49661 14.1943 8.49672 14.1944 8.49683C14.4707 9.04075 14.9972 9.40013 15.5857 9.48297C15.5858 9.48298 15.5858 9.48299 15.5859 9.48301C15.586 9.48301 15.586 9.48302 15.5861 9.48303L20.5048 10.1763L16.961 13.5256C16.9608 13.5258 16.9607 13.5259 16.9605 13.526C16.9604 13.5261 16.9603 13.5262 16.9602 13.5263C16.5172 13.9447 16.3162 14.5562 16.4217 15.154L16.4218 15.1543L17.2571 19.8773L13.1286 17.7733Z'
				stroke='#2682C5'
				strokeWidth='2'
			/>
		</SvgIcon>
	);
};

export default StarIcon;
