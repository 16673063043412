import React from 'react';

import {
	Box,
	Grid,
	type SxProps,
	type Theme,
	Typography,
	Card,
	CardContent,
	CardActionArea,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';
import { StyledArrowRightIcon } from '@ivy/views/emra/components/icons';

interface InfoObject {
	title: string;
	subtext: string;
	link: string;
}

const infoArr: InfoObject[] = [
	{
		title: 'Residency',
		subtext:
			'Whether you’re narrowing down your options based on geographic location, length of program, or primary training site – EMRA Match facilitates your search and guarantees the most updated information you can trust.',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'residency',
			slug: 'search',
		}),
	},
	{
		title: 'Clerkship',
		subtext:
			'EMRA Match provides information about who writes SLOEs, flexible interview dates, whether IMG students are considered, whether USMLE Step 1 or COMLEX scores are required, and more. All to help you make the best decision.',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'clerkship',
			slug: 'search',
		}),
	},
	{
		title: 'Fellowship',
		subtext:
			'Whether you’re narrowing down your options based on geographic location, length of program, fellowship type, or other filters – EMRA Match facilitates your search and guarantees the most updated information you can trust.',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'fellowship',
			slug: 'search',
		}),
	},
];

interface InfoElementProps extends InfoObject {
	onClick?: () => void;
	sx?: SxProps<Theme>;
}

const InfoElement = ({
	title,
	subtext,
	onClick,
	link,
	sx,
}: InfoElementProps) => {
	return (
		<Card
			sx={combineSx(
				{
					borderRadius: '16px',
					boxShadow:
						'0px 2.933px 6.519px 0px rgba(0, 0, 0, 0.03), 0px 13.867px 25.481px 0px rgba(0, 0, 0, 0.04), 0px 36px 80px 0px rgba(0, 0, 0, 0.07)',
				},
				sx,
			)}
		>
			<CardActionArea
				component='div'
				onClick={onClick}
				sx={{
					px: 5,
					py: { md: 10, xs: 5 },
				}}
			>
				<CardContent sx={{ p: 0 }}>
					<Typography
						component='h4'
						variant='h5'
						fontWeight='light'
						sx={{ mb: 4 }}
					>
						{title}
					</Typography>
					<Typography
						variant='body1'
						sx={{
							lineHeight: '25px',
							letterSpacing: '-0.3px',
						}}
					>
						{subtext}
					</Typography>
				</CardContent>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<RouteLink to={link} underline='hover' color='inherit'>
						<StyledArrowRightIcon sx={{ width: '80px', height: '54px' }} />
					</RouteLink>
				</Box>
			</CardActionArea>
		</Card>
	);
};

interface HowCanWeHelpProps {
	sx?: SxProps<Theme>;
}

const HowCanWeHelp = ({ sx }: HowCanWeHelpProps) => {
	const navigate = useNavigate();
	const handleClick = (link: string) => navigate(link);

	return (
		<Box component='section' sx={combineSx({ position: 'relative' }, sx)}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					top: '0',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#F1F1F1',
					display: { xs: 'block', md: 'none' },
				}}
			/>
			<Typography
				variant='h3'
				textAlign={{ xs: 'center', md: 'left' }}
				sx={{ mb: 10 }}
			>
				How can we help?
			</Typography>
			<Grid
				container
				direction={{ md: 'row', xs: 'column' }}
				columnSpacing={{ xs: 0, md: 10 }}
				rowSpacing={{ xs: 3, md: 0 }}
			>
				{infoArr.map((el) => (
					<Grid item key={el.title} xs={12} md={4}>
						<InfoElement onClick={() => handleClick(el.link)} {...el} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default HowCanWeHelp;
