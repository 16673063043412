import React from 'react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import CheckboxListSelect from '@ivy/components/molecules/CheckboxListSelect';
import StarRating from '@ivy/components/molecules/StarRating';
import { YNOptions } from '@ivy/components/molecules/YNOptions';
import {
	CmsEnrollmentProviderTypeCode,
	CmsHospitalType,
	CmsPosProviderSubtypeCode,
} from '@ivy/constants/facility';
import {
	ADULT_TRAUMA_OPTS,
	OWNERSHIP_OPTS,
	ACCREDIATIONS_OPTS,
} from '@ivy/constants/filterOptions';

import {
	prepareEDVisitsFilters,
	prepareFilters,
	prepareSimpleFilter,
} from '../../filters/apiHelpers';
import { type FilterDataStructure } from '../../filters/useEntityFilters';
import VolumeFilterOptions from '../../filters/VolumeFilterOptions';

export const filters: FilterDataStructure[] = [
	{
		quickBtnId: 'btn-filters-edvolume',
		key: 'volume',
		label: 'ED visits',
		title: 'Annual ED Visits',
		apiResolver: prepareEDVisitsFilters,
		component: VolumeFilterOptions,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-emresidency',
		key: 'residency',
		title: 'EM Residency Training Site',
		label: 'EM residency site',
		apiResolver: (val) =>
			val === null
				? {}
				: val
				? {
						primary_residencies: {},
				  }
				: {
						_not: {
							primary_residencies: {},
						},
				  },
		component: YNOptions,
	},
	{
		key: 'adultTraumaLvl',
		title: 'Adult Trauma Designation',
		apiResolver: prepareFilters(
			'adult_trauma_lvl',
			ADULT_TRAUMA_OPTS,
			Number.parseInt,
		),
		options: ADULT_TRAUMA_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'freestanding',
		title: 'Freestanding',
		apiResolver: prepareSimpleFilter('freestanding_er'),
		component: YNOptions,
	},
	{
		key: 'criticalAccess',
		title: 'Critical Access',
		apiResolver: (val) =>
			val === null
				? {}
				: val
				? {
						_or: [
							{
								cms_facility: {
									hospital_type: {
										_eq: CmsHospitalType.CAH,
									},
								},
							},
							{
								cms_facility_pos: {
									provider_subtype: {
										_eq: CmsPosProviderSubtypeCode.CAH,
									},
								},
							},
							{
								cms_facility_enrollment: {
									provider_type_code: {
										_eq: CmsEnrollmentProviderTypeCode.CAH,
									},
								},
							},
						],
				  }
				: {
						// Either CMS DNE or is not listed as the right code
						_not: {
							_or: [
								{
									cms_facility: {
										hospital_type: {
											_eq: CmsHospitalType.CAH,
										},
									},
								},
								{
									cms_facility_pos: {
										provider_subtype: {
											_eq: CmsPosProviderSubtypeCode.CAH,
										},
									},
								},
								{
									cms_facility_enrollment: {
										provider_type_code: {
											_eq: CmsEnrollmentProviderTypeCode.CAH,
										},
									},
								},
							],
						},
				  },
		component: YNOptions,
	},
	{
		key: 'rural',
		title: 'Rural Emergency Hospital',
		apiResolver: (val) =>
			val === null
				? {}
				: val
				? {
						_or: [
							{
								cms_facility: {
									hospital_type: {
										_eq: CmsHospitalType.REH,
									},
								},
							},
							{
								cms_facility_pos: {
									provider_subtype: {
										_eq: CmsPosProviderSubtypeCode.REH,
									},
								},
							},
							{
								cms_facility_enrollment: {
									provider_type_code: {
										_eq: CmsEnrollmentProviderTypeCode.REH,
									},
								},
							},
						],
				  }
				: {
						// Either CMS DNE or is not listed as the right code
						_not: {
							_or: [
								{
									cms_facility: {
										hospital_type: {
											_eq: CmsHospitalType.REH,
										},
									},
								},
								{
									cms_facility_pos: {
										provider_subtype: {
											_eq: CmsPosProviderSubtypeCode.REH,
										},
									},
								},
								{
									cms_facility_enrollment: {
										provider_type_code: {
											_eq: CmsEnrollmentProviderTypeCode.REH,
										},
									},
								},
							],
						},
				  },
		component: YNOptions,
	},
	{
		key: 'ownership',
		title: 'Employer Ownership Structure',
		apiResolver: (val) =>
			!Array.isArray(val) || val.length === 0
				? {}
				: {
						contracts: {
							// Include this here explicitly. It's not redudant with the logic in search_ranked_facilities
							// because this makes the ownership structure filters check only active
							// contracts.
							active: {
								_eq: true,
							},
							org: prepareFilters('ownership_structure', OWNERSHIP_OPTS)(val),
						},
				  },
		options: OWNERSHIP_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-accreditations',
		key: 'accreditations',
		tooltipContent: (
			<>
				<RouteLink
					to='https://www.acep.org/patient-care/accreditation-programs'
					openInNewTab
				>
					Click here
				</RouteLink>{' '}
				to learn more about the ACEP accreditation programs.
			</>
		),
		title: 'ACEP Accreditation',
		options: ACCREDIATIONS_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
		apiResolver: prepareFilters(
			'accreditations.accreditation',
			ACCREDIATIONS_OPTS,
			undefined,
			undefined,
			true,
		),
	},
	{
		key: 'cmsRating',
		title: 'Min. Medicare Hospital Quality Rating',
		apiResolver: prepareSimpleFilter('cms_facility.overall_rating', '_gte'),
		component: StarRating,
	},
];
