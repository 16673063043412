import React from 'react';

import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import { useCrisp } from '@ivy/components/providers/CrispProvider';

import DottedBlob from './DottedBlob';

const FooterFaq = ({ sx }) => {
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const crisp = useCrisp();

	return (
		<Box
			sx={[
				{
					p: 10,
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					backgroundColor: '#E7F5FF',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					position: 'relative',
					overflow: 'hidden',
				},
				sx,
			]}
		>
			<DottedBlob
				stroke='#1EC86A'
				sx={{
					position: 'absolute',
					left: 0,
					width: {
						xs: '551px',
					},
					height: {
						xs: '578px',
					},
					mt: {
						xs: 20,
						md: 12,
					},
					ml: {
						xs: -50,
						md: 'initial',
					},
				}}
			/>
			<DottedBlob
				stroke='#005694'
				sx={{
					position: 'absolute',
					width: {
						xs: '157px',
						md: '281px',
					},
					height: {
						xs: '165px',
						md: '301px',
					},
					mt: {
						xs: 10,
						md: 'initial',
					},
					right: 0,
					mr: {
						xs: -10,
						md: 'initial',
					},
				}}
			/>
			<Typography
				variant={isSm ? 'h5' : 'h4'}
				component='h2'
				sx={{
					textAlign: 'center',
					color: theme.palette.primary.main,
				}}
			>
				Didn't find what you were looking for?
			</Typography>
			<Button
				variant='contained'
				size='large'
				onClick={crisp.handleOpenLauncher}
				sx={{ mt: 2 }}
			>
				Contact us
			</Button>
		</Box>
	);
};

export default FooterFaq;
