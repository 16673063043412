import { type ReactNode } from 'react';

import { type Step } from 'react-joyride';

import {
	type Tours,
	type TourSignals,
} from '@ivy/components/providers/TourProvider/Tours';

import type { ApolloClient } from '@apollo/client';

export enum StepActions {
	CLICK = 'CLICK',
	NAV = 'NAV',
	NAV_CLICK = 'NAV_CLICK',
}

export enum StepPreActions {
	CLICK = 'CLICK',
}

interface PreActionStructure {
	type: StepPreActions | null;
	target: string;
}

interface ActionStructure {
	type: StepActions | null;
	origin?: string;
	altTarget?: string;
	observe?: boolean;
	jump?: number;
}

interface StepStructure extends Omit<Step, 'content'> {
	content: ReactNode | null;
	nextAction?: ActionStructure;
	prevAction?: ActionStructure;
	prevPreAction?: PreActionStructure;
	nextPreAction?: PreActionStructure;
	signal?: TourSignals;
	signalTarget?: string | HTMLElement;
}

interface TourStructure {
	key: Tours;
	tourSteps: StepStructure[];
	mobileTourSteps: StepStructure[];
	origin?: string;
	onComplete?: (
		client: ApolloClient<object>,
		params: { accountId: string },
	) => void;
}

export { type TourStructure, type StepStructure };
