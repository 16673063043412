import React, { useCallback, useMemo } from 'react';

import {
	Box,
	Grid,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

import LabeledCheckbox, {
	type LabeledCheckboxProps,
} from '@ivy/components/atoms/LabeledCheckbox';

export interface ChecklistOption<T extends { [k: string]: boolean }> {
	label: string;
	key: keyof T;
	category: string;
	footer?: boolean;
	disabled?: (value: T, options: ChecklistOption<T>[]) => boolean;
	changeHook?: (value: T, options: ChecklistOption<T>[]) => T;
}

export interface ChecklistProps<T extends { [k: string]: boolean }> {
	value: T;
	onChange?: (event: React.SyntheticEvent, newValue: T) => void;
	options: ChecklistOption<T>[];
	sx?: SxProps<Theme>;
	disabled?: boolean;
	size?: LabeledCheckboxProps['size'];
	categoryComponent?: React.ElementType;
}

const Checklist = <T extends { [k: string]: boolean }>({
	value,
	onChange,
	options,
	disabled,
	sx,
	size,
	categoryComponent = 'p',
}: ChecklistProps<T>) => {
	const categories = useMemo(() => {
		return options
			.map((option) => option.category)
			.filter((option, idx, self) => self.indexOf(option) === idx);
	}, [options]);

	const handleChange = useCallback(
		(key: keyof T) => (ev: React.SyntheticEvent, newValue: boolean) => {
			let totalValue = {
				...value,
				[key]: newValue,
			};
			options
				.filter((option) => option.changeHook)
				.forEach(({ changeHook }) => {
					totalValue = changeHook!(totalValue, options);
				});
			onChange?.(ev, totalValue);
		},
		[value, options, onChange],
	);

	return (
		<Stack spacing={10} sx={sx}>
			{categories.map((category) => (
				<Box key={category}>
					<Typography variant='h6' component={categoryComponent} mb={3}>
						{category}
					</Typography>
					<Grid container spacing={2}>
						{options
							.filter(
								(option) => option.category === category && !option.footer,
							)
							.map((option) => (
								<Grid item xs={12} md={6} key={option.key as string}>
									<LabeledCheckbox
										disabled={option.disabled?.(value, options) || disabled}
										label={option.label}
										value={value[option.key]}
										onChange={handleChange(option.key)}
										size={size}
									/>
								</Grid>
							))}
						{options
							.filter((option) => option.category === category && option.footer)
							.map((option) => (
								<Grid item xs={12} key={option.key as string}>
									<Box
										sx={{
											mt: 5,
											px: 2,
											py: 1,
											bgcolor: 'primary.translucent',
											borderRadius: (theme) => `${theme.shape.borderRadius}px`,
										}}
									>
										<LabeledCheckbox
											disabled={option.disabled?.(value, options) || disabled}
											label={option.label}
											value={value[option.key]}
											onChange={handleChange(option.key)}
											size={size}
										/>
									</Box>
								</Grid>
							))}
					</Grid>
				</Box>
			))}
		</Stack>
	);
};

export default Checklist;
