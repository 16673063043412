import { useMemo } from 'react';

/**
 * Memoizes a value using the stringified version of it to detect changes. This is suitable for Objects that are
 * equal in terms of their contents but not equal in terms of their pointers, which can commonly happen across
 * renders.
 *
 * @param value the value to memoize
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStringifiedMemo = <T>(value: T): T => {
	const stringified = JSON.stringify(value);
	return useMemo(() => {
		return value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stringified]);
};

export default useStringifiedMemo;
