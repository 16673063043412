import React from 'react';

import UnitInput from '@ivy/components/molecules/UnitInput';
import { PaymentRatePeriod } from '@ivy/constants/clinician';

const WAGE_UNITS = [
	{
		label: 'per hour',
		value: PaymentRatePeriod.HOUR,
	},
	{
		label: 'per year',
		value: PaymentRatePeriod.YEAR,
	},
];

const WageInput = ({ ...props }) => {
	return (
		<UnitInput startAdornmentNode='$' unitOptions={WAGE_UNITS} {...props} />
	);
};

export default WageInput;
