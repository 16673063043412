import React, { useMemo } from 'react';

import { Box, type SxProps, Typography, type Theme } from '@mui/material';

import Gallery from '@ivy/components/organisms/Gallery';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { combineSx } from '@ivy/lib/styling/sx';

const EmraProgramGallery_TrainingSurveyFDoc = gql(/* GraphQL */ `
	fragment EmraProgramGallery_TrainingSurvey on training_survey {
		id
		type
		pictures(order_by: { order: asc }) {
			id
			picture {
				id
				filename
				publicUrl: public_url
			}
		}
	}
`);

interface EmraProgramGalleryProps {
	survey: FragmentType<typeof EmraProgramGallery_TrainingSurveyFDoc>;
	sx?: SxProps<Theme>;
}

const EmraProgramGallery = ({
	survey: rawSurvey,
	sx,
}: EmraProgramGalleryProps) => {
	const survey = getFragmentData(
		EmraProgramGallery_TrainingSurveyFDoc,
		rawSurvey,
	);

	const pictures = useMemo(() => {
		return survey?.pictures.map((el) => el.picture) || [];
	}, [survey]);

	if (!pictures.length) {
		return null;
	}

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					maxWidth: { xs: 'none', md: '700px' },
				},
				sx,
			)}
		>
			<Typography
				variant='h5'
				component='h2'
				sx={{ mb: 5, fontWeight: 'light' }}
			>
				Gallery
			</Typography>
			<Gallery pictures={pictures} />
		</Box>
	);
};

export default EmraProgramGallery;
