import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const ArrowRightIcon = ({ sx, ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			sx={combineSx({ fill: 'none' }, sx)}
			{...props}
		>
			<circle cx='8' cy='8' r='7.5' stroke='currentColor' />
			<path
				d='M6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4L6 4.94Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default ArrowRightIcon;
