import { ApolloClient, from, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';

import config from '@ivy/config';
import cache from '@ivy/gql/cache';

import authLink from './authLink';
import errorLink from './errorLink';
import wsLink from './wsLink';

const httpLink = new HttpLink({
	uri: config.gqlUrl,
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	from([
		errorLink,
		// Auth link should come after errorLink so that any forwards from errorLink will use the fresh
		// access token it may have fetched when retrying a request
		authLink,
		// https://www.apollographql.com/docs/react/api/link/introduction/
		// SentryLink should come before errorLink so that breadcrumbs are attached upon captureEvent in errorLink
		// https://github.com/DiederikvandenB/apollo-link-sentry/blob/master/src/SentryLink.ts
		// It seems to have a workaround anyway so that's always the case
		new SentryLink({
			attachBreadcrumbs: {
				// Note that Sentry requests over 200 KB are dropped, so we need to optimize the size
				// We already know the query from the name and can look it up offline
				includeQuery: false,
				// Variables are important to determine faulty inputs but may need to be redacted
				includeVariables: true,
				// The return values from some queries/mutations are way too large
				includeFetchResult: false,
				// Useful for debugging
				includeError: true,
			},
		}),
		httpLink,
	]),
);

const apiClient = new ApolloClient({
	// link: from([cancelLink, errorLink, authLink, httpLink]),
	link: splitLink,
	cache,
	credentials: 'include',
	// queryDeduplication: false,
	connectToDevTools: config.nodeEnv === 'development',
});

export default apiClient;
