import React from 'react';

import { Box, Stack, TextField, Typography } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import WageInput from '@ivy/components/molecules/WageInput';
import { PaymentRatePeriod } from '@ivy/constants/clinician';

import { type SalaryFormValues } from './SalaryReportForm';

export const validation = yup.object({
	studentLoan: yup.object({
		included: yup.boolean().required(),
		pay: yup
			.number()
			.integer('Must be a whole number')
			.min(1, 'Must be at least $1')
			.max(10000000, 'Must be less than $10,000,000')
			.typeError('Please enter a whole number')
			.nullable()
			.label('Student Loan')
			.when(['included', 'unknown'], {
				is: (included, unknown) => included && !unknown,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
		period: yup.string().oneOf(Object.values(PaymentRatePeriod)).required(),
		unknown: yup.bool().nullable(),
	}),
	cme: yup.object({
		included: yup.boolean().required(),
		pay: yup
			.number()
			.integer('Must be a whole number')
			.min(1, 'Must be at least $1')
			.max(10000000, 'Must be less than $10,000,000')
			.typeError('Please enter a whole number')
			.nullable()
			.label('CME Funds')
			.when(['included', 'unknown'], {
				is: (included, unknown) => included && !unknown,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
		period: yup.string().oneOf(Object.values(PaymentRatePeriod)).required(),
		unknown: yup.bool().nullable(),
	}),
	retirement: yup.object({
		included: yup.boolean().required(),
		value: yup
			.string()
			.label('Retirement')
			.when('included', {
				is: (included) => included,
				then: (schema) => schema.required(),
				otherwise: (schema) => schema.nullable(),
			}),
	}),
});

export const initialValue = {
	studentLoan: {
		included: false,
		pay: null,
		period: PaymentRatePeriod.YEAR,
		unknown: false,
	},
	cme: {
		included: false,
		pay: null,
		period: PaymentRatePeriod.YEAR,
		unknown: false,
	},
	retirement: {
		included: false,
		value: '',
	},
};

interface BenefitsStepProps {
	formik: FormikProps<SalaryFormValues>;
}

const BenefitsStep = ({ formik }: BenefitsStepProps) => {
	return (
		<Box>
			<Stack width='100%' spacing={2}>
				<Typography variant='body1' gutterBottom>
					Select the benefits that are offered.
				</Typography>
				<Stack spacing={2}>
					<Box>
						<LabeledCheckbox
							label='Student loan repayment'
							sx={{ p: 0, mr: 1, mb: 1 }}
							value={formik.values.studentLoan.included}
							disabled={formik.isSubmitting}
							onChange={(_, nv) => {
								formik.setFieldTouched('studentLoan.pay', false, false);
								formik.setFieldTouched('studentLoan.unknown', false, false);
								formik.setFieldValue('studentLoan.included', nv, false);
								formik.setFieldValue('studentLoan.pay', null, false);
								formik.setFieldValue('studentLoan.unknown', false);
							}}
						/>
						{formik.values.studentLoan?.included && (
							<Box pl={3} mb={2}>
								<WageInput
									placeholder='0'
									fullWidth
									fixedUnit
									valueAmount={formik.values.studentLoan?.pay}
									onChangeAmount={(nv) =>
										formik.setFieldValue('studentLoan.pay', nv)
									}
									valueUnit={formik.values.studentLoan?.period}
									onChangeUnit={(nv) =>
										formik.setFieldValue('studentLoan.period', nv)
									}
									disabled={
										formik.isSubmitting || formik.values.studentLoan.unknown
									}
									onBlur={() => formik.setFieldTouched('studentLoan.pay')}
									error={
										formik.touched.studentLoan?.pay &&
										!!formik.errors.studentLoan?.pay
									}
									helperText={
										formik.touched.studentLoan?.pay &&
										formik.errors.studentLoan?.pay
									}
								/>
								<LabeledCheckbox
									label='Not sure of the amount'
									sx={{ p: 0, mr: 1, mt: 1 }}
									value={formik.values.studentLoan.unknown}
									disabled={formik.isSubmitting}
									onChange={(_, nv) => {
										formik.setFieldTouched('studentLoan.pay', false, false);
										formik.setFieldTouched('studentLoan.period', false, false);
										formik.setFieldTouched('studentLoan.unknown', true, false);
										formik.setFieldValue('studentLoan.pay', null, false);
										formik.setFieldValue('studentLoan.unknown', nv);
									}}
								/>
							</Box>
						)}
					</Box>
					<Box>
						<LabeledCheckbox
							label='CME allowance'
							sx={{ p: 0, mr: 1, mb: 1 }}
							value={formik.values.cme.included}
							disabled={formik.isSubmitting}
							onChange={(_, nv) => {
								formik.setFieldTouched('cme.pay', false, false);
								formik.setFieldTouched('cme.unknown', false, false);
								formik.setFieldValue('cme.included', nv, false);
								formik.setFieldValue('cme.pay', null, false);
								formik.setFieldValue('cme.unknown', false);
							}}
						/>
						{formik.values.cme?.included && (
							<Box pl={3} mb={2}>
								<WageInput
									placeholder='0'
									fullWidth
									fixedUnit
									valueAmount={formik.values.cme?.pay}
									onChangeAmount={(nv) => formik.setFieldValue('cme.pay', nv)}
									valueUnit={formik.values.cme?.period}
									onChangeUnit={(nv) => formik.setFieldValue('cme.period', nv)}
									disabled={formik.isSubmitting || formik.values.cme.unknown}
									onBlur={() => formik.setFieldTouched('cme.pay')}
									error={formik.touched.cme?.pay && !!formik.errors.cme?.pay}
									helperText={formik.touched.cme?.pay && formik.errors.cme?.pay}
								/>
								<LabeledCheckbox
									label='Not sure of the amount'
									sx={{ p: 0, mr: 1, mt: 1 }}
									value={formik.values.cme.unknown}
									disabled={formik.isSubmitting}
									onChange={(_, nv) => {
										formik.setFieldTouched('cme.pay', false, false);
										formik.setFieldTouched('cme.period', false, false);
										formik.setFieldTouched('cme.unknown', true, false);
										formik.setFieldValue('cme.pay', null, false);
										formik.setFieldValue('cme.unknown', nv);
									}}
								/>
							</Box>
						)}
					</Box>
					<Box>
						<LabeledCheckbox
							label='Employer retirement contributions'
							sx={{ p: 0, mr: 1, mb: 1 }}
							value={formik.values.retirement.included}
							disabled={formik.isSubmitting}
							onChange={(_, nv) => {
								formik.setFieldTouched('retirement.pay', false, false);
								formik.setFieldTouched('retirement.unknown', false, false);
								formik.setFieldValue('retirement.included', nv, false);
								formik.setFieldValue('retirement.pay', null, false);
								formik.setFieldValue('retirement.unknown', false);
							}}
						/>
						{formik.values.retirement?.included && (
							<Box pl={3}>
								<TextField
									fullWidth
									name='retirement.value'
									placeholder='Enter'
									value={formik.values.retirement?.value}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={formik.isSubmitting}
									error={
										formik.touched.retirement?.value &&
										!!formik.errors.retirement?.value
									}
									helperText={
										formik.touched.retirement?.value &&
										formik.errors.retirement?.value
									}
									inputProps={{
										maxLength: 150,
									}}
								/>
							</Box>
						)}
					</Box>
				</Stack>
			</Stack>
		</Box>
	);
};

export default BenefitsStep;
