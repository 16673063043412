import React, { useState, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Card,
	CardContent,
	Chip,
	CardHeader,
	IconButton,
	Typography,
	type Theme,
	useMediaQuery,
	Stack,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import ReviewPopup from '@ivy/components/organisms/ReviewPopup';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

interface ReviewsPromoProps {
	banner: boolean;
	onClose: () => void;
}

const STARBUCKS_LOGO =
	'https://assets.ivyclinicians.io/images/starbucks-logo.svg';

const ReviewsPromo = ({ banner, onClose }: ReviewsPromoProps) => {
	const currAcc = useCurrentAccount();
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;
	const location = useLocation();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	const isReviewsPage = location.pathname === RouteUri.REVIEW;
	const [showReview, setShowReview] = useState(false);

	const handleCloseReview = useCallback(() => {
		setShowReview(false);
	}, [setShowReview]);

	const handleOpenReview = useCallback(() => {
		if (isClinicianOrAnon) setShowReview(true);
	}, [setShowReview, isClinicianOrAnon]);

	return (
		<>
			<Card
				sx={{
					bgcolor: 'white',
					borderRadius: (theme) =>
						`${theme.cardShape?.borderRadius || theme.shape.borderRadius}px`,
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					border: '1px solid #CDCFD2',
					width: banner ? 'auto' : '484px',
					mx: banner ? { mobileMenu: 3, xs: 2 } : 0,
				}}
			>
				<CardHeader
					avatar={
						<Chip
							label='NEW'
							sx={(theme) => ({
								bgcolor: theme.palette.tertiary.main,
								fontWeight: 'bold',
								color: '#fff',
							})}
						/>
					}
					action={
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					}
					title={
						<Typography variant='body1' fontWeight='bold' color='text.icon'>
							Employer & facility reviews
						</Typography>
					}
					sx={{
						display: banner ? 'none' : 'flex',
						px: { mobileMenu: 3, xs: 2 },
						borderBottom: '1px solid #CDCFD2',
					}}
				/>
				<CardContent sx={{ position: 'relative', p: { mobileMenu: 3, xs: 2 } }}>
					<Stack
						direction='row'
						sx={{
							mb: 2,
							mr: 5,
						}}
						justifyContent={banner ? 'flex-start' : 'space-between'}
						alignItems='center'
					>
						<Typography variant='h6'>
							{banner
								? 'Submit a Job Review and Get a Free Coffee!'
								: 'Free Starbucks Coffee on Us!'}
						</Typography>
						<Box
							component='img'
							src={STARBUCKS_LOGO}
							sx={{ width: '35px', height: '35px', ml: 2 }}
						/>
					</Stack>
					<Typography variant={isMobile ? 'body2' : 'body1'} color='text.icon'>
						Be one of the first 100 clinicians to submit an EM job review and
						recieve a <b>$5 Starbucks gift card</b>.{' '}
						<>
							{isReviewsPage ? (
								<Box
									component='span'
									onClick={handleOpenReview}
									sx={(theme) => ({
										color: theme.palette.primary.main,
										textDecorationColor: 'rgba(38, 130, 197, 0.4)',
										cursor: 'pointer',
										fontWeight: 'bold',
										textDecoration: 'none',
										'&:hover': {
											textDecoration: 'underline',
										},
									})}
								>
									Click here to submit a job review!
								</Box>
							) : (
								<RouteLink
									to={RouteUri.REVIEW}
									sx={(theme) => ({
										color: theme.palette.primary.main,
										textDecorationColor: 'rgba(38, 130, 197, 0.4)',
										cursor: 'pointer',
										fontWeight: 'bold',
										textDecoration: 'none',
										'&:hover': {
											textDecoration: 'underline',
										},
									})}
								>
									Click here to get started!
								</RouteLink>
							)}
						</>
					</Typography>
					<IconButton
						onClick={onClose}
						sx={{
							position: 'absolute',
							top: 8,
							right: 8,
							display: banner ? 'inline-flex' : 'none',
							mb: 'auto',
						}}
					>
						<CloseIcon />
					</IconButton>
				</CardContent>
			</Card>
			{showReview && (
				<ReviewPopup
					open
					onSuccess={handleCloseReview}
					onClose={handleCloseReview}
					refetchQueries={['Review_Review']}
				/>
			)}
		</>
	);
};

export default ReviewsPromo;
