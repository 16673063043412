import { gql } from '@apollo/client';

export const createFragment = (name, parent, fields, definitions) => {
	if (!definitions || !definitions.length) {
		return {
			name,
			fragment: gql`
                fragment ${name} on ${parent} {
                    ${fields}
                }
            `,
		};
	}
	const fragmentString = `
        fragment ${name} on ${parent} {
            ${fields}
        }
    `;
	return {
		name,
		fragment: gql(
			[...definitions.map(() => '\n'), `\n${fragmentString}`],
			...definitions,
		),
	};
};
