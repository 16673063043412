import React, { useState, useCallback } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';

import Screen from '@ivy/components/atoms/Screen';
import config from '@ivy/config';
import { appendLinkerParam } from '@ivy/lib/gtm';
import {
	appendQuery,
	checkOutsideLink,
	getOutsideTo,
	populateLocationStateDefaults,
	type WhitelabelTo,
} from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';
import WHITELABEL_CONFIGURATIONS, {
	type WhitelabelConfigName,
} from '@ivy/lib/whitelabel/whitelabelConfig';

import RedirectContext, { type RedirectOptions } from './RedirectContext';

const DELAY = 2000;

const openExternalLink = (link: string, openInNewTab?: boolean) => {
	if (openInNewTab) {
		window.open(link, '_blank');
	} else {
		window.location.href = link;
	}
};

const RedirectProvider = ({ children }) => {
	const theme = useTheme();
	const [showSplashScreen, setShowSplashScreen] =
		useState<WhitelabelConfigName | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const currentWl = useWhitelabel();

	const redirect = useCallback(
		async (to: WhitelabelTo, options?: Partial<RedirectOptions>) => {
			const outsideLink = checkOutsideLink(to);
			if (outsideLink || options?.openInNewTab) {
				if (options?.state) {
					// Need to manually append locationState since we cannot pass it through `navigate`
					const newSearchParams = new URLSearchParams();
					newSearchParams.append(
						'locationState',
						JSON.stringify(
							populateLocationStateDefaults(
								options.state,
								location,
								currentWl.name,
							),
						),
					);
					to = appendQuery(to, newSearchParams.toString());
				}
				if (typeof to === 'string') {
					openExternalLink(to, options?.openInNewTab);
				} else if (to.whitelabel && to.whitelabel !== config.whitelabel) {
					// Add the _gl cross domain linker query param if available so we can track the Google Analytics
					// user across all whitelabels.
					// Note GTM is set up to append the linkerParam to all <a> tags when clicked, but not on JS
					// redirects. Thus, we do this manually.
					let outsideTo = getOutsideTo(to);
					try {
						outsideTo = (await appendLinkerParam(outsideTo)) || outsideTo;
					} catch (e) {
						// Do nothing
					}
					if (to.showSplashScreen) {
						setShowSplashScreen(to.whitelabel);
						setTimeout(() => {
							openExternalLink(outsideTo, options?.openInNewTab);
							// The back functionality in Mobile Safari shows the splash screen, so we need to close it
							setTimeout(() => setShowSplashScreen(null), DELAY);
						}, DELAY);
					} else {
						openExternalLink(outsideTo, options?.openInNewTab);
					}
				} else {
					openExternalLink(getOutsideTo(to), options?.openInNewTab);
				}
			} else {
				navigate(to, {
					state: populateLocationStateDefaults(
						options?.state,
						location,
						currentWl.name,
					),
				});
			}
		},
		[location, currentWl.name, navigate],
	);

	const destWl = showSplashScreen
		? WHITELABEL_CONFIGURATIONS.find((el) => el.name === showSplashScreen)!
		: null;

	return (
		<RedirectContext.Provider value={redirect}>
			{children}
			{!!destWl && (
				<Screen fullscreen>
					<Box
						sx={{
							maxWidth: '650px',
						}}
					>
						<Typography variant='h2'>
							You are now being redirected to{' '}
							<Box
								component='img'
								alt={destWl.productName}
								src={destWl.logo}
								sx={{
									position: 'relative',
									top: `calc(0.4 * ${theme.typography.h2.fontSize})`,
									height: `calc(2 * ${theme.typography.h2.fontSize})`,
								}}
							/>
						</Typography>
						<Box
							sx={{
								mt: 2,
							}}
						>
							<ThreeDots
								height='80'
								width='80'
								radius='9'
								color={destWl.theme.palette.primary.main}
								visible={true}
							/>
						</Box>
					</Box>
				</Screen>
			)}
		</RedirectContext.Provider>
	);
};

export default RedirectProvider;
