import React from 'react';

import { HelpOutline } from '@mui/icons-material';
import { Box, type SxProps, type Theme, Typography } from '@mui/material';

import Tooltip from '@ivy/components/atoms/Tooltip';

export interface SectionHeaderProps {
	title?: string;
	children?: React.ReactNode;
	noBackground?: boolean;
	tooltipContent?: React.ReactNode;
	disablePadding?: boolean;
	sx?: SxProps<Theme>;
}

const SectionHeader = (props: SectionHeaderProps) => {
	const { title, noBackground, disablePadding, tooltipContent, sx, children } =
		props;

	return (
		<Box component='section' sx={sx}>
			{!!title && (
				<Box display='flex' alignItems='center' mb={{ md: 5, xs: 3 }}>
					<Typography
						component='h2'
						variant='h5'
						fontWeight='bold'
						align='center'
					>
						{title}
					</Typography>
					{tooltipContent && (
						<Tooltip title={tooltipContent}>
							<HelpOutline fontSize='small' sx={{ ml: 1 }} />
						</Tooltip>
					)}
				</Box>
			)}

			<Box
				sx={(theme) => ({
					borderRadius: `${(theme.cardShape || theme.shape).borderRadius}px`,
					boxShadow: noBackground
						? undefined
						: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					p: noBackground || disablePadding ? undefined : 3,
				})}
			>
				{children}
			</Box>
		</Box>
	);
};

export default SectionHeader;
