import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Stack, useMediaQuery, type Theme } from '@mui/material';
import { useScript } from 'usehooks-ts';

import DataLoader from '@ivy/components/molecules/DataLoader';
import ReviewSalaryCta from '@ivy/components/organisms/ReviewSalaryCta';
import config from '@ivy/config';
import { Profession } from '@ivy/constants/clinician';
import { EMRA2SLUG, EMRATypes } from '@ivy/constants/emra';
import { RouteUri } from '@ivy/constants/routes';
import { gql } from '@ivy/gql/types';
import { buildInternalLink } from '@ivy/lib/util/route';
import EmraProgramForm from '@ivy/views/emra/components/EmraProgramForm';

import EmrafiedLogo from './EmrafiedLogo';
import EmraProgramFeatures from './EmraProgramFeatures';
import EmraProgramGallery from './EmraProgramGallery';
import EmraProgramPrimarySite from './EmraProgramPrimarySite';
import EmraProgramSocials from './EmraProgramSocials';
import EmraProgramTrainingSites from './EmraProgramTrainingSites';
import EmraProgramEvents from './EmraProgramUpcomingEvents';
import FeaturedBanner from './FeaturedBanner';

const EmraProgramOverview_TrainingQDoc = gql(/* GraphQL */ `
	query EmraProgramOverview_Training(
		$trainingId: uuid!
		$profession: profession!
	) {
		training: training_by_pk(id: $trainingId) {
			id
			type
			residency {
				id
				emrafied
				training {
					id
					featured
				}
				latestSurvey: latest_survey {
					id
					survey {
						trainingSurvey: training_survey {
							id
							...EmraProgramFeatures_TrainingSurvey
							...EmraProgramPrimarySite_TrainingSurvey
							...EmraProgramSocials_TrainingSurvey
							...EmraProgramGallery_TrainingSurvey
							...EmraProgramTrainingSites_TrainingSurvey
							...ReviewSalaryCta_TrainingSurvey
						}
					}
				}
			}
			fellowship {
				id
				training {
					id
					featured
				}
				latestSurvey: latest_survey {
					id
					survey {
						trainingSurvey: training_survey {
							id
							...EmraProgramFeatures_TrainingSurvey
							...EmraProgramPrimarySite_TrainingSurvey
							...EmraProgramSocials_TrainingSurvey
							...EmraProgramGallery_TrainingSurvey
							...EmraProgramTrainingSites_TrainingSurvey
							...ReviewSalaryCta_TrainingSurvey
						}
					}
				}
			}
			clerkship {
				id
				training {
					id
					featured
				}
				latestSurvey: latest_survey {
					id
					survey {
						trainingSurvey: training_survey {
							id
							...EmraProgramFeatures_TrainingSurvey
							...EmraProgramPrimarySite_TrainingSurvey
							...EmraProgramSocials_TrainingSurvey
							...EmraProgramGallery_TrainingSurvey
							...EmraProgramTrainingSites_TrainingSurvey
							...ReviewSalaryCta_TrainingSurvey
						}
					}
				}
			}
			...EmraProgramEvents_Training
		}
	}
`);

interface EmraProgramOverviewProps {
	trainingId: string;
}

const COLUMN_1_WIDTH = {
	xs: '100%',
	lg: '70%',
};
const COLUMN_2_WIDTH = '30%';
const COLUMN_SPACING = {
	lg: 6,
};
const ROW_SPACING = {
	xs: 8,
	lg: 15,
};
const ROW_SPACING_HALF = Object.entries(ROW_SPACING).reduce(
	(tot, [k, v]) => ({
		...tot,
		[k]: v / 2,
	}),
	{},
);

const EmraProgramOverview = ({ trainingId }: EmraProgramOverviewProps) => {
	const disableAds = config.env !== 'production';
	const [insRendered, setInsRendered] = useState(false);
	// Needs HTML to render first with the <ins> tag before the script is loaded
	useScript('https://servedby.aqua-adserver.com/asyncjs.php', {
		shouldPreventLoad: !insRendered || disableAds,
	});
	const isLteLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
	const { data, loading, error } = useQuery(EmraProgramOverview_TrainingQDoc, {
		variables: {
			trainingId: trainingId,
			profession: Profession.PHYSICIAN,
		},
		fetchPolicy: 'network-only',
	});

	const currentTraining = data?.training;
	const currentType = data?.training?.type;
	const program =
		data?.training &&
		currentType &&
		data.training[EMRA2SLUG[data.training.type]];

	const currentSurvey = program?.latestSurvey?.survey?.trainingSurvey;

	const resolveFormPath = () => {
		let editLink = '';
		const progId = data?.training?.id;
		switch (currentType) {
			case EMRATypes.RESIDENCY:
				editLink = buildInternalLink(RouteUri.EMRA_RESIDENCY_EDIT, {
					trainingId: progId,
				});
				break;
			case EMRATypes.CLERKSHIP:
				editLink = buildInternalLink(RouteUri.EMRA_CLERKSHIP_EDIT, {
					trainingId: progId,
				});
				break;
			case EMRATypes.FELLOWSHIP:
				editLink = buildInternalLink(RouteUri.EMRA_FELLOWSHIP_EDIT, {
					trainingId: progId,
				});
				break;
		}
		return editLink;
	};

	return (
		<DataLoader variant='circular' data={data} loading={loading} error={error}>
			{() =>
				!!currentSurvey && (
					<Box
						sx={{
							pt: ROW_SPACING_HALF,
							position: 'relative',
						}}
					>
						{program?.training.featured && (
							<FeaturedBanner
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
									display: isLteLg ? 'flex' : 'none',
									alignItems: 'center',
									justifyContent: 'center',
									width: '40px',
									height: '80px',
								}}
								iconSx={{
									m: 'auto',
									width: '32px',
									height: '32px',
								}}
								svgSx={{
									top: 1,
									left: 1,
									width: '100%',
									height: '100%',
									position: 'absolute',
									filter: 'drop-shadow(0px 4.4px 13px rgba(0, 0, 0, 0.04))',
								}}
								isMobile
							/>
						)}
						<Box
							sx={{
								display: 'flex',
							}}
						>
							<Stack
								sx={{
									pr: COLUMN_SPACING,
									width: COLUMN_1_WIDTH,
									maxWidth: {
										xs: '100%',
										lg: 'none',
									},
								}}
								spacing={ROW_SPACING}
							>
								<EmraProgramFeatures
									survey={currentSurvey}
									type={currentType}
								/>
								<EmraProgramPrimarySite survey={currentSurvey} />
								{isLteLg && currentTraining && (
									<Stack spacing={ROW_SPACING}>
										<EmraProgramEvents training={currentTraining} />
									</Stack>
								)}
								{isLteLg && (
									<Stack spacing={ROW_SPACING}>
										<EmraProgramTrainingSites survey={currentSurvey} />
									</Stack>
								)}
								<EmraProgramGallery survey={currentSurvey} />
								{isLteLg && (
									<Stack spacing={ROW_SPACING}>
										<ReviewSalaryCta survey={currentSurvey} />
									</Stack>
								)}
							</Stack>
							<Stack
								sx={{
									display: {
										xs: 'none',
										lg: 'initial',
									},
									width: COLUMN_2_WIDTH,
								}}
								spacing={ROW_SPACING}
							>
								<EmraProgramSocials showCta={!isLteLg} survey={currentSurvey} />
								{currentTraining && (
									<EmraProgramEvents training={currentTraining} />
								)}
								<EmraProgramTrainingSites survey={currentSurvey} />
								{currentType === EMRATypes.RESIDENCY &&
									currentTraining?.residency?.emrafied && <EmrafiedLogo />}
								{!disableAds && (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<ins
											data-revive-zoneid='10883'
											data-revive-id='fa8c6c9da7f33852f7097c4a94da1070'
											ref={() => setInsRendered(true)}
										></ins>
									</Box>
								)}
							</Stack>
						</Box>
						<Box sx={{ position: 'relative', mt: ROW_SPACING }}>
							<EmraProgramForm toPath={resolveFormPath()} />
						</Box>
					</Box>
				)
			}
		</DataLoader>
	);
};

export default EmraProgramOverview;
