import React from 'react';

import Check from '@mui/icons-material/CheckCircle';
import Warning from '@mui/icons-material/Warning';
import { type StepIconProps, Box } from '@mui/material';

const StepIcon = (props: StepIconProps) => {
	const { active, completed, error } = props;

	return (
		<Box
			component='div'
			sx={(theme) => ({
				color: theme.palette.light1.main,
				display: 'flex',
				height: 22,
				alignItems: 'center',
				'& .Step-errorIcon': {
					color: theme.palette.error.main,
					zIndex: 1,
					fontSize: 12,
				},
				'& .Step-completedIcon': {
					color: theme.palette.primary.main,
					zIndex: 1,
					fontSize: 12,
				},
				'& .Step-whole': {
					width: 12,
					height: 12,
					borderRadius: '50%',
					borderColor: theme.palette.primary.main,
					borderStyle: 'solid',
					borderWidth: '3px',
				},
				'& .Step-circle': {
					width: 12,
					height: 12,
					borderRadius: '50%',
					backgroundColor: 'currentColor',
				},
			})}
		>
			{error ? (
				<Warning className='Step-errorIcon' />
			) : completed ? (
				<Check className='Step-completedIcon' />
			) : active ? (
				<Box component='div' className='Step-whole' />
			) : (
				<Box component='div' className='Step-circle' />
			)}
		</Box>
	);
};

export default StepIcon;
