import { useEffect } from 'react';

import TagManager from 'react-gtm-module';

const useGtm = (gtmId?: string, disabled = false) => {
	useEffect(() => {
		if (disabled || !gtmId) {
			return;
		}
		TagManager.initialize({
			gtmId,
		});
	}, [disabled, gtmId]);
};

export default useGtm;
