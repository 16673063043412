import React from 'react';

const YesIcon = ({ ...props }) => {
	return (
		<svg
			width='32'
			height='34'
			viewBox='0 0 32 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect y='15.3521' width='7' height='16' fill='currentColor' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 1.85324C17.5 5.35446 8.5 14.8532 8.5 14.8532V31.3532C8.5 31.8521 12.5 33.3521 14 33.3532C15.4738 33.3544 25.6355 32.8385 25.9905 32.8205C26.1568 32.8413 26.3269 32.852 26.5 32.852C28.433 32.852 30 31.5089 30 29.852C30 29.1797 29.7419 28.559 29.3061 28.0587C30.8922 27.5176 32 26.2854 32 24.852C32 23.666 31.618 22.4296 30.7487 21.651C31.5137 21.1007 32 20.2751 32 19.3521C32 18.4086 31.492 17.2427 30.6976 16.4136C31.4842 15.9629 32 15.2077 32 14.3521C32 12.9713 30.6569 11.8521 29 11.8521C28.9224 11.8521 28.8455 11.8545 28.7694 11.8593C28.6834 11.8552 28.5937 11.8532 28.5 11.8532C28.0789 11.8532 27.3625 11.8927 26.533 11.9385L26.5329 11.9385L26.5327 11.9385L26.5326 11.9385C24.4075 12.0557 21.5411 12.2137 21 11.8532C20.6892 11.6462 20.7446 10.5125 20.8184 9.00163C20.9232 6.85534 21.0653 3.94773 20.2477 1.85324C19.7865 0.671737 16.5 -1.64799 17 1.85324Z'
				fill='currentColor'
			/>
		</svg>
	);
};

export default YesIcon;
