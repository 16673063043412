import React, { useCallback, useState } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';
import { useTheme } from '@mui/material';

import DataLoader from '@ivy/components/molecules/DataLoader';
import ReviewPopup from '@ivy/components/organisms/ReviewPopup';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

import CompareSalary from './CompareSalary';
import FeaturedList from './FeaturedList';
import Hero from './Hero';
import NearbyList from './NearbyList';
import SupportCommunity from './SupportCommunity';

const SECTION_Y_SPACING = {
	xs: 8,
	md: 15,
	lg: 21,
};

export const Review_ReviewQDoc = gql(/* GraphQL */ `
	query Review_Review {
		featuredFacilities: facility(
			where: { ranking_review: { _is_null: false } }
			order_by: { ranking_review: asc }
		) {
			id
			...FeauredList_Facility
		}
		featuredOrgs: organization(
			where: { ranking_review: { _is_null: false } }
			order_by: { ranking_review: asc }
		) {
			id
			...FeauredList_Organization
		}
		salaryAgg: salary_report_fmt_aggregate {
			aggregate {
				count
			}
		}
	}
`);

const Review = () => {
	const currAcc = useCurrentAccount();
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;
	const theme = useTheme();
	const { data, loading, error, networkStatus } = useQuery(Review_ReviewQDoc);
	const [showReview, setShowReview] = useState(false);

	const handleCloseReview = useCallback(() => {
		setShowReview(false);
	}, [setShowReview]);

	const handleOpenReview = useCallback(() => {
		if (isClinicianOrAnon) setShowReview(true);
	}, [setShowReview, isClinicianOrAnon]);

	return (
		<NavTemplate
			backgroundColor={theme.palette.light4.main}
			showFooter
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
			}}
			pageTitle='Employer & Facility Reviews'
			pageDescription='Submit your facility or employer review today. Access the collective wisdom of fellow emergency clinicians and make informed career decisions.'
			showSupportLauncher
			pathIsCanonical
			hideBackNav
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<>
						<Hero onClick={handleOpenReview} />
						<FeaturedList
							facilities={data!.featuredFacilities!}
							orgs={data!.featuredOrgs!}
							loading={[NetworkStatus.loading].includes(networkStatus)}
							sx={{
								bgcolor: theme.palette.background.paper,
							}}
						/>
						<SupportCommunity
							onClick={handleOpenReview}
							sx={{
								height: {
									lg: '1080px',
								},
								mt: SECTION_Y_SPACING,
							}}
						/>
						<NearbyList
							sx={{
								bgcolor: theme.palette.background.paper,
							}}
						/>
						<CompareSalary
							count={data!.salaryAgg!.aggregate?.count}
							sx={{
								height: {
									lg: '1080px',
								},
								mt: SECTION_Y_SPACING,
							}}
						/>
						{showReview && (
							<ReviewPopup
								open
								onSuccess={handleCloseReview}
								onClose={handleCloseReview}
								refetchQueries={['Review_Review']}
							/>
						)}
					</>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default Review;
