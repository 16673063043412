import React from 'react';

import { Grid, Skeleton, type SxProps, type Theme } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface CardGridSkeletonProps {
	size?: number;
	sx?: SxProps<Theme>;
}

const CardGridSkeleton = ({ sx, size = 0 }: CardGridSkeletonProps) => (
	<Grid container spacing={3} sx={combineSx({ justifyContent: 'center' }, sx)}>
		{[...Array(size).keys()].map((el, index) => {
			const photoPresent = index < 3;
			return (
				<Grid item key={el} xs={12} md={4}>
					<Skeleton
						variant='rectangular'
						sx={(theme) => ({
							width: '100%',
							height: {
								md: photoPresent ? '278.4px' : '129.4px',
								xs: photoPresent ? '310.4px' : '163px',
								borderRadius: `${theme.shape.borderRadius}px`,
							},
						})}
					/>
				</Grid>
			);
		})}
	</Grid>
);

export default CardGridSkeleton;
