import React, { useCallback, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import {
	Skeleton,
	Box,
	CircularProgress,
	Link,
	List,
	ListItemButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
	type ListItemButtonProps,
} from '@mui/material';
import { useFormik, setNestedObjectValues } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData } from '@ivy/gql/types';
import {
	ProfileCompleteForm_ClinicianFragmentDoc,
	type ClinicianSearch_SearchFsmbQuery,
	type ClinicianSearch_FsmbBioFragment,
} from '@ivy/gql/types/graphql';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { useNotifyError } from '@ivy/lib/hooks';
import { useInfiniteScroll } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

import { type ProfileStepValues } from './ProfileStep';

const generateSecondaryText = (fsmbBio: ClinicianSearch_FsmbBioFragment) => {
	const lines: string[] = [];
	if (fsmbBio?.school) {
		lines.push(
			`${fsmbBio.school}${
				fsmbBio.gradYr ? `, Class of ${fsmbBio.gradYr}` : ''
			}`,
		);
	}
	if (fsmbBio?.cmsSpecialties?.length) {
		lines.push(fsmbBio.cmsSpecialties.map((el) => el.specialty).join(', '));
	}
	const licenses = fsmbBio?.licenses.map((el) => el.state) || [];
	if (licenses.length) {
		lines.push(`Licenses: ${licenses.join(', ')}`);
	}
	if (fsmbBio?.npi) {
		lines.push(`NPI: ${fsmbBio.npi}`);
	}
	return lines.join('\n');
};

interface SearchListItemProps extends ListItemButtonProps {
	fsmbBio: ClinicianSearch_FsmbBioFragment;
}

const SearchListItem = React.forwardRef<HTMLDivElement, SearchListItemProps>(
	({ fsmbBio, sx, ...props }, ref) => {
		return (
			<ListItemButton
				ref={ref}
				sx={combineSx(
					{
						p: 2,
					},
					sx,
				)}
				{...props}
			>
				<ListItemText
					primary={`${fsmbBio?.firstName}${
						fsmbBio?.middleName ? ` ${fsmbBio?.middleName} ` : ' '
					}${fsmbBio?.lastName}`}
					primaryTypographyProps={{
						fontWeight: 'bold',
					}}
					secondary={generateSecondaryText(fsmbBio)}
					secondaryTypographyProps={{
						whiteSpace: 'pre-wrap',
					}}
				/>
			</ListItemButton>
		);
	},
);

interface SearchResultsProps {
	onChange: (nv: ClinicianSearch_FsmbBioFragment) => void;
	loading: boolean;
	networkStatus: NetworkStatus;
	onFetchPage: () => void;
	data: ClinicianSearch_SearchFsmbQuery;
}

const SearchResults = ({
	onChange,
	loading,
	networkStatus,
	onFetchPage,
	data,
}: SearchResultsProps) => {
	const atMaxPage =
		!!data && data?.agg?.aggregate?.count === data.search.length;

	const observe = useInfiniteScroll({
		hasMore: !atMaxPage,
		fetchNextPage: onFetchPage,
	});

	if (loading && !data.search.length) {
		return (
			<Stack direction='column' spacing={1}>
				<Skeleton variant='rectangular' width={250} height={100} />
				<Skeleton variant='rectangular' width={250} height={100} />
			</Stack>
		);
	}

	return (
		<List
			sx={{
				// Avoid false buttoms
				height: '350px',
				overflow: 'auto',
				border: (theme) => `1px solid ${theme.palette.divider}`,
				width: { sm: '350px', xs: 'auto' },
				borderRadius: 1,
				py: 0,
			}}
		>
			{data?.search.map(({ fsmbBio: rawFsmbBio }, idx: number, self) => {
				const fsmbBio = getFragmentData(
					ClinicianSearch_FsmbBioFDoc,
					rawFsmbBio,
				);

				if (!fsmbBio) {
					return null;
				}

				return (
					<SearchListItem
						fsmbBio={fsmbBio}
						key={idx}
						ref={idx === self.length - 1 ? observe : undefined}
						onClick={() => onChange(fsmbBio)}
					/>
				);
			})}
			{networkStatus === NetworkStatus.fetchMore && (
				<Box display='flex' justifyContent='center' py={2}>
					<CircularProgress color='primary' />
				</Box>
			)}
		</List>
	);
};

const ClinicianSearch_ImportCredentialsMDoc = gql(/* GraphQL */ `
	mutation ClinicianSearch_ImportCredentials($fid: String!) {
		importCreds: import_credentials(fid: $fid) {
			clinician {
				id
				...ProfileCompleteForm_Clinician
			}
		}
	}
`);

const ClinicianSearch_UnlinkCredentialsMDoc = gql(/* GraphQL */ `
	mutation ClinicianSearch_UnlinkCredentials {
		unlinkCreds: unlink_credentials {
			clinician {
				id
				fsmbBio: fsmb_bio {
					fid
				}
			}
		}
	}
`);

const ClinicianSearch_FsmbBioFDoc = gql(/* GraphQL */ `
	fragment ClinicianSearch_FsmbBio on fsmb_bio {
		fid
		npi
		firstName: first_name
		middleName: middle_name
		lastName: last_name
		cred: practitioner_type
		school: medical_school_name
		gradYr: graduation_year
		city
		state
		cmsSpecialties: cms_specialties(
			order_by: [{ primary: desc }, { specialty: asc }]
		) {
			id
			specialty
			primary
		}
		licenses(
			where: { active: { _eq: true } }
			order_by: [
				{ expiry_date: desc_nulls_first }
				{ issue_date: desc_nulls_first }
				{ state: asc }
			]
		) {
			id
			state
		}
	}
`);

const ClinicianSearch_SearchFSMBQDoc = gql(/* GraphQL */ `
	query ClinicianSearch_SearchFSMB(
		$first: String
		$last: String
		$profDegree: String!
		$limit: Int!
		$offset: Int!
	) {
		search: search_fsmb_bio(
			args: { first: $first, last: $last, npi: null }
			where: {
				fsmb_bio: {
					_or: [
						{ practitioner_type: { _eq: $profDegree } }
						{ practitioner_type: { _eq: "" } }
					]
				}
			}
			order_by: [
				{ lev_last: asc }
				{ lev_first: asc }
				{ graduation_year: desc_nulls_last }
				{ fid: asc }
			]
			limit: $limit
			offset: $offset
		) {
			fid
			fsmbBio: fsmb_bio {
				fid
				...ClinicianSearch_FsmbBio
			}
		}
		agg: search_fsmb_bio_aggregate(
			args: { first: $first, last: $last, npi: null }
			where: {
				fsmb_bio: {
					_or: [
						{ practitioner_type: { _eq: $profDegree } }
						{ practitioner_type: { _eq: "" } }
					]
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`);

const SEARCH_PAGE_SIZE = 20;

const credValidation = yup.object({
	first: yup.string().required().label('First Name'),
	last: yup.string().required().label('Last Name'),
});

export interface ClinicianSearchValues {
	fsmbBio: ClinicianSearch_FsmbBioFragment | null;
}

export const initialValue: ClinicianSearchValues = {
	fsmbBio: null,
};

export const validation = yup.object({
	fsmbBio: yup
		.object({
			fid: yup.string().required(),
		})
		.nullable(),
});

const ClinicianSearch = ({
	formik,
	onNext,
}: SubFormStepProps<ProfileCompleteFormValues, ExtraParams>) => {
	const currAcc = useCurrentAccount();
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState({
		first: currAcc?.pi?.firstName || '',
		last: currAcc?.pi?.lastName || '',
	});
	const [active, setActive] = useState(true);
	const searchFormik = useFormik({
		initialValues: {
			...search,
		},
		validationSchema: credValidation,
		onSubmit: (vals, actions) => {
			setPage(0);
			setSearch({
				first: vals.first,
				last: vals.last,
			});
			actions.setTouched({});
			actions.setSubmitting(false);
		},
	});

	const { data, loading, error, fetchMore, networkStatus } = useQuery(
		ClinicianSearch_SearchFSMBQDoc,
		{
			variables: {
				first: search.first,
				last: search.last,
				profDegree: currAcc?.clinician?.profDegree,
				limit: SEARCH_PAGE_SIZE,
				offset: page * SEARCH_PAGE_SIZE,
			},
			notifyOnNetworkStatusChange: true,
		},
	);
	useNotifyError(error);

	const [startImport, { loading: importing, error: importError }] = useMutation(
		ClinicianSearch_ImportCredentialsMDoc,
	);
	useNotifyError(importError, true);

	const [startUnlink, { loading: unlinking, error: unlinkError }] = useMutation(
		ClinicianSearch_UnlinkCredentialsMDoc,
	);
	useNotifyError(unlinkError, true);

	const handleSearch = useCallback(() => {
		setActive(true);
		searchFormik.handleSubmit();
	}, [searchFormik]);

	const handleResetSearch = useCallback(() => {
		setActive(false);
		searchFormik.setTouched({});
	}, [searchFormik]);

	const handleImportCredentials = useCallback(
		async (newFsmbBio: ClinicianSearch_FsmbBioFragment) => {
			const res = await startImport({
				variables: { fid: newFsmbBio.fid },
			});

			const clinician = getFragmentData(
				ProfileCompleteForm_ClinicianFragmentDoc,
				res.data?.importCreds.clinician,
			);

			if (clinician) {
				const {
					gradYr,
					schoolName,
					specialties,
					fsmbBio: rawFsmbBio,
				} = clinician;
				const fsmbBio = getFragmentData(
					ClinicianSearch_FsmbBioFDoc,
					rawFsmbBio,
				);
				const specs = clinician.isAPP
					? []
					: specialties.length
					? specialties.map((el) => ({
							id: el.id,
							name: el.name,
							primary: el.primary,
					  }))
					: [
							{
								id: uuidv4(),
								name: 'Emergency Medicine',
								primary: true,
							},
					  ];

				// If we set each new field with formik.setFieldValue, we run into a problem where formik
				// runs validation at the end on old values (so it thinks schoolName and gradYr were never set).
				// Using setTimeout doesn't work on Mobile Safari as a fix (unless you give it an actual delay, which
				// is sketchy).
				// https://github.com/jaredpalmer/formik/issues/2083
				// Thus, we opted to use setValues and not validate setTouched.
				const updates: Partial<ProfileStepValues> = {};
				// Only set truthy attributes to get the touched state correct
				if (schoolName) {
					updates.schoolName = schoolName;
					updates.gradYr = gradYr != null ? new Date(gradYr, 0) : null;
					// If the grad year is missing, don't assume they are a current student. Ask them to fill this
					// section out.
					updates.currStudent = false;
				}
				if (specs.length) {
					updates.specialties = specs;
				}
				if (fsmbBio) {
					updates.fsmbBio = fsmbBio;
				}
				formik.setValues({
					...formik.values,
					...updates,
				});
				formik.setTouched(
					{
						...formik.touched,
						// Mirror the shape of updates since specialties is some array of objects
						...setNestedObjectValues(updates, true),
					},
					false,
				);
			}
		},
		[startImport, formik],
	);

	const handleUnlinkCredentials = useCallback(async () => {
		await startUnlink();
		formik.setFieldValue('fsmbBio', null, true);
		setActive(false);
	}, [startUnlink, formik, setActive]);

	const handleFetchPage = useCallback(async () => {
		await fetchMore({
			variables: {
				offset: (page + 1) * SEARCH_PAGE_SIZE,
			},
		});
		setPage(page + 1);
	}, [fetchMore, page, setPage]);

	const handleKeyUp = useCallback(
		(ev: React.KeyboardEvent<HTMLDivElement>) => {
			if (ev.key === 'Enter') handleSearch();
		},
		[handleSearch],
	);

	return importing || unlinking ? (
		<CircularProgress color='primary' />
	) : (
		<Stack
			flexDirection={{
				sm: 'row',
				xs: 'column',
			}}
		>
			<Stack flexWrap='wrap' flexDirection='column'>
				{formik.values.fsmbBio ? (
					<Box
						sx={{
							borderRadius: 1,
							border: (theme) => `1px solid ${theme.palette.divider}`,
							overflow: 'hidden',
						}}
					>
						<SearchListItem fsmbBio={formik.values.fsmbBio} selected />
					</Box>
				) : active && data?.agg?.aggregate?.count ? (
					<SearchResults
						onChange={handleImportCredentials}
						loading={loading}
						networkStatus={networkStatus}
						onFetchPage={handleFetchPage}
						data={data}
					/>
				) : (
					<Box>
						<Stack direction={{ sm: 'row', xs: 'column' }} spacing={3}>
							<TextField
								fullWidth
								label='First name'
								name='first'
								placeholder='Enter your first name'
								value={searchFormik.values.first}
								onChange={searchFormik.handleChange}
								onBlur={searchFormik.handleBlur}
								error={
									searchFormik.touched.first && !!searchFormik.errors.first
								}
								helperText={
									searchFormik.touched.first && searchFormik.errors.first
								}
							/>
							<TextField
								fullWidth
								label='Last name'
								name='last'
								placeholder='Enter your last name'
								value={searchFormik.values.last}
								onChange={searchFormik.handleChange}
								onBlur={searchFormik.handleBlur}
								error={searchFormik.touched.last && !!searchFormik.errors.last}
								helperText={
									searchFormik.touched.last && searchFormik.errors.last
								}
								onKeyUp={(ev) => {
									if (searchFormik.values.first && searchFormik.values.last)
										handleKeyUp(ev);
								}}
							/>
						</Stack>
					</Box>
				)}
				<Box
					sx={{
						mt: 2,
					}}
				>
					{formik.values.fsmbBio ? (
						<Typography variant='caption'>
							Is this not you?{' '}
							<Link
								component={'span'}
								sx={{ cursor: 'pointer', textDecoration: 'none' }}
								onClick={handleUnlinkCredentials}
							>
								Search for other credentials.
							</Link>{' '}
						</Typography>
					) : // Note strict equality check to 0
					active ? (
						data?.agg?.aggregate?.count === 0 ? (
							<Typography variant='caption'>
								<Box component='span' color='error.main'>
									No credentials found for {search.first} {search.last}.
								</Box>{' '}
								Try a different name or{' '}
								<Link
									component={'span'}
									sx={{ cursor: 'pointer', textDecoration: 'none' }}
									onClick={onNext}
								>
									skip to the next question.
								</Link>
							</Typography>
						) : (
							<Typography variant='caption'>
								Having trouble finding yourself?{' '}
								<Link
									component={'span'}
									sx={{ cursor: 'pointer', textDecoration: 'none' }}
									onClick={handleResetSearch}
								>
									Search a different name.
								</Link>{' '}
							</Typography>
						)
					) : (
						<Typography variant='caption'>
							Having trouble finding yourself?{' '}
							<Link
								component={'span'}
								sx={{ cursor: 'pointer', textDecoration: 'none' }}
								onClick={onNext}
							>
								Skip to the next question.
							</Link>
						</Typography>
					)}
				</Box>
			</Stack>
			<Box
				sx={{
					ml: { sm: 3, xs: 0 },
				}}
			>
				{formik.values.fsmbBio || (active && data?.agg?.aggregate?.count) ? (
					<NavButton
						variant='text'
						onClick={onNext}
						sx={{
							ml: { sm: 0, xs: -1 },
							mt: { sm: 0, xs: 1 },
						}}
					>
						{formik.values.fsmbBio ? 'Next ⏎' : 'Skip ⏎'}
					</NavButton>
				) : (
					<NavButton
						variant='text'
						onClick={handleSearch}
						disabled={importing}
						sx={{
							mt: { sm: 3, xs: 1 },
						}}
					>
						Search ⏎
					</NavButton>
				)}
			</Box>
		</Stack>
	);
};

export default ClinicianSearch;
