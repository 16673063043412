import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { Button, TextField, Stack, Box, FormHelperText } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { isAuthenticatedVar } from '@ivy/gql/reactives';
import { storeAccessToken, storeRefreshToken } from '@ivy/lib/storage/token';

const validationSchema = yup.object({
	email: yup.string().email('Must be a valid email').required().label('Email'),
	password: yup.string().required().label('Password'),
});

const LOGIN_MUTATION = gql`
	mutation logIn($email: String!, $password: String!) {
		logIn: log_in(email: $email, password: $password) {
			access: access_token
			refresh: refresh_token
		}
	}
`;

const LoginForm = ({ currAccLoading }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [login, { error }] = useMutation(LOGIN_MUTATION);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async ({ email, password }, actions) => {
			try {
				const response = await login({
					variables: {
						email,
						password,
					},
				});
				enqueueSnackbar('You have logged in successfully.', {
					variant: 'success',
				});
				const data = response.data;
				// Set submitting false here to avoid memory leak
				actions.setSubmitting(false);
				// Tokens must be set before changing isAuthenticatedVar for logout listener to work properly
				// Convention is refresh before access
				storeRefreshToken(data.logIn.refresh);
				storeAccessToken(data.logIn.access);
				isAuthenticatedVar(true);
			} catch (e) {
				console.error(e);
				actions.setSubmitting(false);
				// Don't capture password for security purposes
				captureException(e, {
					extra: {
						email,
					},
				});
			}
		},
	});

	const handleKeyUp = (ev) => {
		if (ev.key === 'Enter') {
			formik.handleSubmit();
		}
	};

	return (
		<Stack
			alignItems='center'
			spacing={1}
			pt={0.5}
			pb={0.5}
			pl={1}
			pr={1}
			maxWidth='350px'
		>
			<TextField
				fullWidth
				name='email'
				label='Email'
				type='email'
				disabled={formik.isSubmitting || currAccLoading}
				value={formik.values.email}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.email && !!formik.errors.email}
				helperText={formik.touched.email && formik.errors.email}
			/>
			<Box>
				<TextField
					fullWidth
					name='password'
					label='Password'
					type='password'
					disabled={formik.isSubmitting || currAccLoading}
					value={formik.values.password}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.password && !!formik.errors.password}
					helperText={formik.touched.password && formik.errors.password}
					onKeyUp={handleKeyUp}
				/>
				<Box
					display='inline-block'
					sx={{
						float: 'right',
					}}
				>
					<Button
						component={RouteLink}
						color='primary'
						to={RouteLink.routes.FORGOT_PASSWORD}
						forwardState
						// Forward redirect
						forwardQuery
						sx={{
							fontSize: 'caption.fontSize',
						}}
					>
						Forgot password?
					</Button>
				</Box>
			</Box>
			<FormHelperText error sx={{ display: !error && 'none' }}>
				{error?.message}
			</FormHelperText>
			<Button
				id='LoginForm_LoginBtn'
				color='primary'
				variant='contained'
				size='large'
				fullWidth
				onClick={formik.handleSubmit}
				disabled={formik.isSubmitting || currAccLoading}
				sx={{ maxWidth: '300px' }}
			>
				Log in
			</Button>
		</Stack>
	);
};

export default LoginForm;
