import React from 'react';

import {
	Box,
	Divider,
	TextField,
	Typography,
	Popper,
	type PopperProps,
} from '@mui/material';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';

import Autocomplete, {
	type AutocompleteProps,
} from '@ivy/components/molecules/Autocomplete';
import { type Suggestion } from '@ivy/components/organisms/FacilitySearchInput/facilitySearchInputTypes';
import { combineSx } from '@ivy/lib/styling/sx';

const PopperDownwards = React.forwardRef<HTMLDivElement, PopperProps>(
	({ popperOptions, anchorEl, ...props }, ref) => {
		// Force the popper to always be on the bottom against MUI's will
		const totalOptions: PopperProps['popperOptions'] = {
			...(popperOptions || {}),
			placement: 'bottom',
			modifiers: [
				...(popperOptions?.modifiers?.filter((el) => el.name !== 'flip') || []),
				{
					name: 'flip',
					enabled: false,
				},
			],
		};
		return (
			<Popper
				placement='bottom'
				{...props}
				ref={ref}
				anchorEl={anchorEl}
				popperOptions={totalOptions}
				style={{
					width:
						anchorEl && 'offsetWidth' in anchorEl
							? anchorEl.offsetWidth
							: undefined,
				}}
			/>
		);
	},
);

export interface PlaceInputProps<T>
	extends AutocompleteProps<T, false, false, false> {
	ref?:
		| React.RefCallback<HTMLInputElement | null>
		| React.MutableRefObject<HTMLInputElement | null>;
	popperAnchor: PopperProps['anchorEl'];
}

// TODO: Warning that happens when change from raleigh search -> durham, as raleigh is listed as location even though
//   no longer an option
// https://github.com/mui/material-ui/issues/29727
// Should get the warning message, "The value provided to autocomplete is invalid, none of the options match with..."
const PlaceInput = React.forwardRef<
	HTMLInputElement,
	PlaceInputProps<Suggestion>
>(({ TextFieldProps, popperAnchor, sx, ...props }, ref) => {
	// TODO: add some loading indicator overlay without the disabled bug mentioned below
	return (
		<Autocomplete
			openOnFocus
			selectOnFocus
			clearOnBlur
			autoHighlight
			// This caused a bug in Firefox/Safari where, next time we try to focus on the
			// autocomplete, we can't clear it or type in it.
			// disabled={disabled || geocoding}
			forcePopupIcon={false}
			PopperComponent={(popperProps) => (
				<PopperDownwards {...popperProps} anchorEl={popperAnchor} />
			)}
			renderInput={(params) => {
				if (ref) {
					if (isFunction(ref)) {
						// @ts-ignore
						ref(params.inputProps.ref.current);
					} else {
						// @ts-ignore
						ref.current = params.inputProps.ref.current;
					}
				}
				return <TextField {...merge(params, TextFieldProps)} />;
			}}
			isOptionEqualToValue={(option, value) =>
				option.location.id === value.location.id
			}
			getOptionLabel={(option) => {
				return option.location.label;
			}}
			// Prevent MUI from filtering out our suggestions since we also suggest based on city/state, which may
			// not be in the option label
			// Also override MUI applying its own secondary filtering that isn't as advanced (can't ignore articles, punctuation, etc...)
			filterOptions={(options) => options}
			renderOption={(params, option) => {
				return (
					<React.Fragment key={option.location.id}>
						{!!option.section && (
							<Box position='relative'>
								<Typography
									variant='caption'
									sx={(theme) => ({
										// We don't want it taking up full width like a block element,
										// so use inline-block
										display: 'inline-block',
										// zIndex doesn't apply to static elements, so make it relative so that
										// it's above the divider
										position: 'relative',
										zIndex: 1,
										pl: 2,
										pr: 1,
										backgroundColor: theme.palette.background.paper,
									})}
								>
									{option.section}
								</Typography>
								<Divider
									sx={{
										position: 'absolute',
										width: '100%',
										top: '50%',
									}}
								/>
							</Box>
						)}
						<Box component='li' display='flex' {...params}>
							{option?.icon}
							<Box>
								<Typography
									variant='body1'
									sx={{
										ml: option?.icon ? 1 : undefined,
									}}
								>
									{option.location.label}
								</Typography>
								<Typography variant='body2' color='text.secondary'>
									{option.secondary}
								</Typography>
							</Box>
						</Box>
					</React.Fragment>
				);
			}}
			// Remove the clear button - don't use the prop since it will no longer allow "null" as a value
			// Instead, we override the styling
			// disableClearable
			sx={combineSx(
				{
					'& .MuiAutocomplete-endAdornment': {
						display: 'none',
					},
				},
				sx,
			)}
			{...props}
		/>
	);
});

export default PlaceInput;
