import { type UrlOptionsSrc } from 'imagekit-javascript/src/interfaces/UrlOptions';
import { IKCore } from 'imagekitio-react';

import config from '@ivy/config';

export const imagekit = new IKCore({
	urlEndpoint: config.imageKitUrl,
});

const recodeUriComponents = (path: string | string[]) => {
	// We need to recode download%20%2816%29.jpeg -> download%20(16).jpeg for ImageKit to work. It doesn't
	// like how the public url from GCP Storage is escaped.
	return (Array.isArray(path) ? path : path.split('/'))
		.map((el) => encodeURIComponent(decodeURIComponent(el)))
		.join('/');
};

export const getImageKitUrl = (
	url: string,
	options: Partial<UrlOptionsSrc>,
) => {
	let relativePath: string | string[];
	let endpoint: string;
	if (url.includes('https://assets.ivyclinicians.io')) {
		relativePath = url.replace('https://assets.ivyclinicians.io', '');
		endpoint = 'ivy-assets';
	} else {
		relativePath = url
			.replace('https://storage.googleapis.com/', '')
			.split('/');
		endpoint = relativePath.shift() || '';
	}
	return imagekit.url({
		// Using src instead of path so that transformations are applied as a querystring instead of path param
		src: `${config.imageKitUrl}/${endpoint}/${recodeUriComponents(
			relativePath,
		)}`,
		...options,
	});
};
