import React from 'react';

import { Badge as MuiBadge } from '@mui/material';

const Badge = ({ rootProps, badgeProps, overlap, ...props }) => {
	return (
		<MuiBadge
			slotProps={{
				root: rootProps,
				badge: {
					sx: [
						{
							// width: '38px',
							transform: overlap === 'pill' && 'scale(1) translate(25%, -40%)',
							...(overlap === 'inline'
								? {
										position: 'static',
										transform: 'none',
										transformOrigin: 'none',
										top: 'auto',
										right: 'auto',
								  }
								: {
										boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
								  }),
						},
						badgeProps?.sx,
					],
					...badgeProps,
				},
			}}
			overlap={['inline', 'pill'].includes(overlap) ? undefined : overlap}
			{...props}
		/>
	);
};

export default Badge;
