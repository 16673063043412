import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

export const ACQ_KEYS = [
	'referral',
	'utm_id',
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_term',
	'utm_content',
];
const SESSION_ACQ_KEY = '_ivy_session_acquisition';

const useAcqParams = () => {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (ACQ_KEYS.some((el) => searchParams.has(el))) {
			// Replace entirety of acquisition params, not just some
			ACQ_KEYS.forEach((key) => {
				const val = searchParams.get(key) || '';
				localStorage.setItem(key, val);
				sessionStorage.setItem(SESSION_ACQ_KEY, 'true');
			});
		}

		// TODO: may want to remove these values from the search params, although they could mess up GA
		// Only run this once on mount, never again, even if search params change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export const getAcqParams = () => {
	const params = ACQ_KEYS.reduce((tot, key) => {
		const val = localStorage.getItem(key);
		if (val) {
			tot[key] = val;
		}
		return tot;
	}, {});
	return {
		...params,
		acq_session: Object.keys(params).length
			? sessionStorage.getItem(SESSION_ACQ_KEY) === 'true'
			: null,
	};
};

export default useAcqParams;
