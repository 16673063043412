import React from 'react';

import { SvgIcon } from '@mui/material';

const StructureIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			{...props}
		>
			<mask id='path-1-inside-1_2678_81634' fill='white'>
				<rect x='8.35156' y='1.5' width='7.29704' height='7.5' rx='0.5' />
			</mask>
			<rect
				x='8.35156'
				y='1.5'
				width='7.29704'
				height='7.5'
				rx='0.5'
				stroke='#586069'
				strokeWidth='3'
				mask='url(#path-1-inside-1_2678_81634)'
			/>
			<mask id='path-2-inside-2_2678_81634' fill='white'>
				<rect x='3.24219' y='14.25' width='7.29704' height='7.5' rx='0.5' />
			</mask>
			<rect
				x='3.24219'
				y='14.25'
				width='7.29704'
				height='7.5'
				rx='0.5'
				stroke='#586069'
				strokeWidth='3'
				mask='url(#path-2-inside-2_2678_81634)'
			/>
			<mask id='path-3-inside-3_2678_81634' fill='white'>
				<rect x='13.459' y='14.25' width='7.29704' height='7.5' rx='0.5' />
			</mask>
			<rect
				x='13.459'
				y='14.25'
				width='7.29704'
				height='7.5'
				rx='0.5'
				stroke='#586069'
				strokeWidth='3'
				mask='url(#path-3-inside-3_2678_81634)'
			/>
			<path
				d='M11.9986 9V11.625H6.89062M6.89062 11.625V15.375M6.89062 11.625H17.1065V15'
				stroke='#586069'
				strokeWidth='1.5'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};

export default StructureIcon;
