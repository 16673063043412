import React from 'react';

import { SvgIcon } from '@mui/material';

const MultimediaLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			{...props}
		>
			<path
				d='M19.9832 12.0167C20.8932 12.9267 20.8932 14.4067 19.9832 15.3167L15.3165 19.9834C14.4352 20.8647 12.8979 20.8647 12.0165 19.9834L10.6025 21.3981C11.4212 22.2161 12.5092 22.6667 13.6665 22.6667C14.8239 22.6667 15.9119 22.2161 16.7305 21.3974L21.3972 16.7307C22.2159 15.9127 22.6665 14.8241 22.6665 13.6667C22.6665 12.5094 22.2159 11.4207 21.3972 10.6021L19.9832 12.0167Z'
				fill='#C5CAE9'
			/>
			<path
				d='M21.3335 15.6666C20.8188 15.6666 20.3228 15.5886 19.8562 15.444L15.4448 19.8553C15.5888 20.3226 15.6668 20.8186 15.6668 21.3333C15.6668 21.6353 15.6355 21.9293 15.5842 22.2166C16.0028 22.0093 16.3908 21.7373 16.7308 21.3973L21.3975 16.7306C21.7375 16.3906 22.0102 16.0026 22.2168 15.584C21.9295 15.6353 21.6355 15.6666 21.3335 15.6666Z'
				fill='#7986CB'
			/>
			<path
				d='M8 30C6.39733 30 4.89067 29.376 3.75733 28.2427C2.624 27.1094 2 25.6027 2 24C2 22.3974 2.624 20.8907 3.75733 19.7574L6.424 17.0907C7.55733 15.9574 9.064 15.3334 10.6667 15.3334C12.2693 15.3334 13.776 15.9574 14.9093 17.0907C16.0427 18.224 16.6667 19.7307 16.6667 21.3334C16.6667 22.936 16.0427 24.4427 14.9093 25.576L12.2427 28.2427C11.1093 29.376 9.60267 30 8 30ZM10.6667 17.3334C9.598 17.3334 8.594 17.7494 7.838 18.5047L5.17133 21.1714C4.416 21.9274 4 22.9314 4 24C4 25.0687 4.416 26.0727 5.17133 26.8287C5.92667 27.5847 6.93133 28 8 28C9.06867 28 10.0727 27.584 10.8287 26.8287L13.4953 24.162C14.2507 23.406 14.6667 22.402 14.6667 21.3334C14.6667 20.2647 14.2507 19.2607 13.4953 18.5047C12.7393 17.7494 11.7353 17.3334 10.6667 17.3334Z'
				fill='#9FA8DA'
			/>
			<path
				d='M21.3335 16.6667C19.7308 16.6667 18.2242 16.0427 17.0908 14.9093C15.9575 13.776 15.3335 12.2693 15.3335 10.6667C15.3335 9.064 15.9575 7.55733 17.0908 6.424L19.7575 3.75733C20.8908 2.624 22.3975 2 24.0002 2C25.6028 2 27.1095 2.624 28.2428 3.75733C29.3762 4.89067 30.0002 6.39733 30.0002 8C30.0002 9.60267 29.3762 11.1093 28.2428 12.2427L25.5762 14.9093C24.4428 16.0427 22.9362 16.6667 21.3335 16.6667ZM24.0002 4C22.9315 4 21.9275 4.416 21.1715 5.17133L18.5048 7.838C17.7495 8.594 17.3335 9.598 17.3335 10.6667C17.3335 11.7353 17.7495 12.7393 18.5048 13.4953C19.2602 14.2513 20.2648 14.6667 21.3335 14.6667C22.4022 14.6667 23.4062 14.2507 24.1622 13.4953L26.8288 10.8287C27.5842 10.0727 28.0002 9.06867 28.0002 8C28.0002 6.93133 27.5842 5.92733 26.8288 5.17133C26.0728 4.416 25.0688 4 24.0002 4Z'
				fill='#9FA8DA'
			/>
			<path
				d='M8.00195 18.3334C8.00195 18.3407 8.00329 18.348 8.00329 18.3554C8.73729 17.6974 9.67329 17.3334 10.6666 17.3334C11.416 17.3334 12.132 17.542 12.7546 17.9254C12.8033 17.8167 12.8706 17.7154 12.9593 17.626L14.1393 16.446C13.1306 15.7267 11.93 15.3334 10.6666 15.3334C9.96995 15.3334 9.29329 15.458 8.65595 15.6847'
				fill='#7986CB'
			/>
			<path
				d='M16.4462 14.1393L17.6262 12.9599C17.7135 12.8726 17.8162 12.8053 17.9262 12.7566C17.5428 12.1326 17.3335 11.4166 17.3335 10.6666C17.3335 9.67328 17.6968 8.73794 18.3548 8.00394C17.4355 8.00061 16.5162 8.21861 15.6842 8.65794C15.4582 9.29528 15.3335 9.97061 15.3335 10.6666C15.3335 11.9306 15.7268 13.1313 16.4462 14.1393Z'
				fill='#7986CB'
			/>
			<path
				d='M12.0165 19.9833C11.5758 19.5426 11.3331 18.9566 11.3331 18.3333C11.3331 17.7099 11.5758 17.1239 12.0165 16.6833L16.6831 12.0166C17.1378 11.5619 17.7358 11.3346 18.3331 11.3346C18.9305 11.3346 19.5285 11.5619 19.9831 12.0166L21.3331 11.9999L21.3971 10.6019C19.7078 8.91328 16.9585 8.91328 15.2691 10.6019L10.6025 15.2686C8.91246 16.9579 8.91246 19.7073 10.6025 21.3973L11.9998 21.3333L12.0165 19.9833Z'
				fill='#C5CAE9'
			/>
		</SvgIcon>
	);
};

export default MultimediaLinkIcon;
