import type React from 'react';

import { type ApolloClient } from '@apollo/client';
import { type FormikHelpers } from 'formik-othebi';
import { type OptionalObjectSchema } from 'yup/lib/object';

import ClinicianSignupStep, {
	validation as clinicianValidation,
	initialValue as clinicianInitialValues,
	submit as clinicianSubmit,
	type ClinicianFormValues,
} from './ClinicianSignupStep';
import OrganizationSignupStep, {
	validation as orgValidation,
	initialValue as orgInitialValues,
	submit as orgSubmit,
	type OrganizationFormValues,
} from './OrganizationSignupStep';
import SelectStep, {
	validation as selectValidation,
	initialValue as selectInitialValue,
	type SelectFormValues,
	PopupType,
} from './SelectStep';
import VerificationStep from './VerificationStep';

interface SubmitFunction {
	(
		values: OnboardingFormValues,
		actions: FormikHelpers<OnboardingFormValues>,
		client: ApolloClient<object>,
		onSuccess: (text?: string) => void,
		onError: (
			error: unknown,
			options?: { [key: string]: object },
			text?: string,
		) => void,
	): void;
}

interface FormStepObj {
	submit?: SubmitFunction;
	component: React.ElementType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	validation?: OptionalObjectSchema<any>;
	nextStep?: (values?: OnboardingFormValues) => string;
	finalStep?: boolean;
	googleAnalyticsTag?: string;
}

type OnboardingFormValues = SelectFormValues &
	ClinicianFormValues &
	OrganizationFormValues;

const onboardingFormSteps: { [key: string]: FormStepObj } = {
	select: {
		component: SelectStep,
		validation: selectValidation,
		nextStep: (values) =>
			values?.tab === PopupType.CLINICIAN ? 'clinician' : 'organization',
		finalStep: false,
	},
	clinician: {
		submit: clinicianSubmit,
		component: ClinicianSignupStep,
		validation: clinicianValidation,
		finalStep: true,
		googleAnalyticsTag: 'form-signup--btn-submit__clinician',
	},
	organization: {
		submit: orgSubmit,
		component: OrganizationSignupStep,
		validation: orgValidation,
		nextStep: () => 'verify',
		finalStep: true,
		googleAnalyticsTag: 'form-signup--btn-submit__organization',
	},
	verify: {
		component: VerificationStep,
	},
};

const onboardingInitialValues = {
	...selectInitialValue,
	...clinicianInitialValues,
	...orgInitialValues,
};

export {
	type OnboardingFormValues,
	type FormStepObj,
	type SubmitFunction,
	onboardingFormSteps,
	onboardingInitialValues,
};
