import React, { useState, useEffect, useMemo, Suspense } from 'react';

import { useQuery, NetworkStatus } from '@apollo/client';
import { Box, Tabs, Tab, Grid, type SxProps, type Theme } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { PracticeIcon, StructureIcon } from '@ivy/components/icons';
import config from '@ivy/config';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import {
	ORGNIZATION_PLACEHOLDER_IMAGE,
	OWNERSHIP_DESCRIPTION_SUCCINT,
} from '@ivy/constants/organization';
import { getFragmentData, gql } from '@ivy/gql/types';
import { formatInteger } from '@ivy/lib/formatting/number';
import { buildInternalLink } from '@ivy/lib/util/route';

import CardGridSkeleton from './CardGridSkeleton';
import SnippetCard from './SnippetCard';

const NearbyList_ProxDistanceQDoc = gql(/* GraphQL */ `
	query NearbyList_ProxDistance($x: float8!, $y: float8!, $limit: Int!) {
		facRes: facility_dist(
			args: { x: $x, y: $y }
			order_by: { distance: asc }
			limit: $limit
		) {
			...NearbyList_FacilityDistanceResult
		}
		orgRes: org_dist(
			args: { x: $x, y: $y }
			order_by: { distance: asc }
			limit: $limit
		) {
			...NearbyList_OrganizationDistanceResult
		}
	}
`);

const NearbyList_FacilityDistanceResultFDoc = gql(/* GraphQL */ `
	fragment NearbyList_FacilityDistanceResult on facility_dist_result {
		id
		distance
		facility {
			id
			name
			slug
			ranking_review
			adultTraumaLvl: adult_trauma_lvl
			freestandingEr: freestanding_er
			facilityType @client
			cmsFacilityPos: cms_facility_pos {
				id
				providerSubtype: provider_subtype
			}
			cmsFacilityEnrollment: cms_facility_enrollment {
				id
				providerTypeCode: provider_type_code
			}
			cmsFacility: cms_facility {
				id
				hospitalType: hospital_type
			}
			picture {
				id
				publicUrl: public_url
			}
			contracts(
				where: { active: { _eq: true } }
				order_by: { created_at: asc }
			) {
				id
				org {
					id
					ownershipStructure: ownership_structure
					name
				}
			}
			reviewsAgg: reviews_aggregate(
				where: {
					active: { _eq: true }
					visible: { _eq: true }
					has_active_contract: { _eq: true }
				}
			) {
				agg: aggregate {
					avg {
						ratingJob: rating_job
					}
					count
				}
			}
		}
	}
`);

const NearbyList_OrganizationDistanceResultFDoc = gql(/* GraphQL */ `
	fragment NearbyList_OrganizationDistanceResult on org_dist_result {
		id
		distance
		organization {
			id
			slug
			name
			ranking_review
			logo {
				id
				publicUrl: public_url
			}
			coverPhoto: cover_photo {
				id
				publicUrl: public_url
			}
			ownershipStructure: ownership_structure
			contractsAgg: contracts_aggregate(where: { active: { _eq: true } }) {
				aggregate {
					count
				}
			}
			reviewsAgg: reviews_aggregate(
				where: { active: { _eq: true }, visible: { _eq: true } }
			) {
				agg: aggregate {
					avg {
						ratingOrg: rating_org
					}
					count
				}
			}
		}
	}
`);

interface LatLng {
	lat: number;
	lng: number;
}

export interface NearbyListProps {
	sx?: SxProps<Theme>;
}

const GRID_SIZE = 9;
const MILE_TO_METERS = 1609.34;

const generateDistanceText = (distance: number) => {
	const miles = distance / MILE_TO_METERS;
	const rounded = miles < 1 ? Math.round(miles * 10) / 10 : Math.round(miles);
	return `${rounded} ${rounded === 1 ? 'mile' : 'miles'}`;
};

const NearbyList = ({ sx }: NearbyListProps) => {
	const [value, setValue] = React.useState('org');
	const [coords, setCoords] = useState<LatLng | null>(null);
	const { data, networkStatus } = useQuery(NearbyList_ProxDistanceQDoc, {
		variables: {
			x: coords?.lng,
			y: coords?.lat,
			limit: GRID_SIZE,
		},
		skip: !coords,
	});

	useEffect(() => {
		const determineLocation = async () => {
			const resp = await fetch(
				`https://ipinfo.io/json?token=${config.ipApiKey}`,
			);
			if (!resp.ok) {
				console.error(
					`IP lookup failed with status code ${resp.status}.`,
					resp,
				);
				throw new Error(`IP lookup failed with status code ${resp.status}.`);
			}
			let [lat, lng] = (await resp.json()).loc.split(',');
			lat = Number.parseFloat(lat);
			lng = Number.parseFloat(lng);
			setCoords({
				lat,
				lng,
			});
		};
		determineLocation();
	}, []);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const facilities = useMemo(
		() =>
			data?.facRes.map(
				(el) => getFragmentData(NearbyList_FacilityDistanceResultFDoc, el)!,
			) || [],
		[data],
	);

	const orgs = useMemo(
		() =>
			data?.orgRes.map(
				(el) => getFragmentData(NearbyList_OrganizationDistanceResultFDoc, el)!,
			) || [],
		[data],
	);

	const listItems = value === 'fac' ? facilities : orgs;

	return (
		<Box component='section' sx={sx}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#fff',
				}}
			/>
			<Tabs
				value={value}
				onChange={handleChange}
				sx={{
					pt: {
						xs: 8,
						md: 15,
						lg: 21,
					},
					mb: 5,
				}}
			>
				<Tab value='org' label='Employers nearby' />
				<Tab value='fac' label='Facilities nearby' />
			</Tabs>
			<Suspense fallback={<CardGridSkeleton size={GRID_SIZE} />}>
				{[
					NetworkStatus.loading,
					NetworkStatus.error,
					NetworkStatus.setVariables,
				].includes(networkStatus) && <CardGridSkeleton size={GRID_SIZE} />}
				<Box
					sx={{
						flex: '1 1 auto',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Grid
						container
						spacing={3}
						sx={{
							justifyContent: 'center',
						}}
					>
						<>
							{value === 'fac'
								? facilities?.map((item, index) => (
										<Grid item key={item.id} xs={12} md={4}>
											<SnippetCard
												title={item?.facility?.name}
												subtitle={
													!item?.facility?.contracts.length
														? null
														: item.facility.contracts[0].org.name
												}
												rating={
													item?.facility?.reviewsAgg?.agg?.avg?.ratingJob || 0
												}
												ratingCount={item?.facility?.reviewsAgg?.agg?.count}
												image={
													index < 3
														? item?.facility?.picture?.publicUrl ||
														  FACILITY_PLACEHOLDER_IMAGE
														: undefined
												}
												captionText={generateDistanceText(item.distance)}
												infoArray={[
													{
														iconComp: (
															<PracticeIcon sx={{ fontSize: '24px' }} />
														),
														text: item.facility?.facilityType,
													},
													{
														iconComp: (
															<StructureIcon
																sx={{ fontSize: '24px', fill: 'none' }}
															/>
														),
														text: !item.facility?.contracts.length
															? null
															: item.facility?.contracts.length === 1
															? OWNERSHIP_DESCRIPTION_SUCCINT[
																	item.facility?.contracts[0].org
																		.ownershipStructure
															  ] || 'N/A'
															: 'Multiple',
													},
												]}
												to={buildInternalLink(
													RouteLink.routes.FACILITY_REVIEW_LIST,
													{
														facilityId: [
															item.facility!.id,
															item.facility!.slug,
														],
													},
												)}
												state={{
													backNav: {
														target: 'reviews',
													},
												}}
											/>
										</Grid>
								  ))
								: orgs.map((item, index) => (
										<Grid item key={item.id} xs={12} md={4}>
											<SnippetCard
												logo={
													item.organization?.logo?.publicUrl ||
													ORGNIZATION_PLACEHOLDER_IMAGE
												}
												title={item?.organization?.name}
												rating={
													item?.organization?.reviewsAgg?.agg?.avg?.ratingOrg ||
													0
												}
												ratingCount={item?.organization?.reviewsAgg?.agg?.count}
												image={
													index < 3
														? item?.organization?.coverPhoto?.publicUrl ||
														  ORGNIZATION_PLACEHOLDER_IMAGE
														: undefined
												}
												captionText={`${
													item.distance / MILE_TO_METERS < 1
														? Math.round(
																(item.distance * 10) / MILE_TO_METERS,
														  ) / 10
														: Math.round(item.distance / MILE_TO_METERS)
												} miles`}
												infoArray={[
													{
														iconComp: (
															<PracticeIcon sx={{ fontSize: '24px' }} />
														),
														text: item.organization?.contractsAgg.aggregate
															?.count
															? `${formatInteger(
																	item.organization?.contractsAgg.aggregate
																		.count,
															  )} ${
																	item.organization?.contractsAgg.aggregate
																		?.count === 1
																		? 'facility'
																		: 'facilities'
															  }`
															: 'N/A',
													},
													{
														iconComp: (
															<StructureIcon
																sx={{ fontSize: '24px', fill: 'none' }}
															/>
														),
														text:
															OWNERSHIP_DESCRIPTION_SUCCINT[
																item.organization?.ownershipStructure
															] || 'N/A',
													},
												]}
												to={buildInternalLink(
													RouteLink.routes.ORG_REVIEW_LIST,
													{
														orgId: [
															item.organization!.id,
															item.organization!.slug,
														],
													},
												)}
												state={{
													backNav: {
														target: 'reviews',
													},
												}}
											/>
										</Grid>
								  ))}
							{/* Left justify the remainder with empty grid items */}
							{[
								...new Array(GRID_SIZE - (listItems.length % GRID_SIZE)).keys(),
							].map((el, idx) => (
								<Grid
									item
									key={idx}
									xs={12}
									md={4}
									sx={{
										padding: '0px !important',
									}}
								/>
							))}
						</>
					</Grid>
				</Box>
			</Suspense>
		</Box>
	);
};

export default NearbyList;
