import React from 'react';

import {
	SvgIcon,
	Box,
	Typography,
	type Theme,
	type SxProps,
} from '@mui/material';

import { FeaturedIcon } from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';

interface FeaturedTagProps {
	isMobile?: boolean;
	sx?: SxProps<Theme>;
	svgSx?: SxProps<Theme>;
	iconSx?: SxProps<Theme>;
}

const FeaturedBanner = ({ isMobile, sx, svgSx, iconSx }: FeaturedTagProps) => {
	if (isMobile)
		return (
			<Box
				sx={combineSx(
					{
						color: 'white',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						position: 'relative',
					},
					sx,
				)}
			>
				<SvgIcon
					width='100%'
					height='100%'
					viewBox='0 0 40 79'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					sx={svgSx}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M38 0H40V2V64V76.9465C40 78.3876 38.5219 79.3557 37.2008 78.7798L21.2796 71.8398C20.7815 71.6227 20.2164 71.6176 19.7144 71.8257L2.76603 78.8531C1.44908 79.3992 0 78.4313 0 77.0056V64V2V0H2H38Z'
						fill='url(#paint0_linear_1523_4383)'
					/>
					<defs>
						<linearGradient
							id='paint0_linear_1523_4383'
							x1='20'
							y1='0'
							x2='20'
							y2='79.0077'
							gradientUnits='userSpaceOnUse'
						>
							<stop stopColor='#98367C' />
							<stop offset='1' stopColor='#008D98' />
						</linearGradient>
					</defs>
				</SvgIcon>
				<FeaturedIcon sx={[{ zIndex: 1 }, iconSx]} />
			</Box>
		);

	return (
		<Box
			sx={combineSx(
				{
					py: 1,
					px: 3,
					color: 'white',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					position: 'relative',
				},
				sx,
			)}
		>
			<SvgIcon
				width='100%'
				height='100%'
				viewBox='0 0 178 40'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				sx={svgSx}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M177.206 40V0L77.9998 0L15.196 0L2.00386 0C0.574387 0 -0.39357 1.45619 0.159805 2.77422L7.26848 19.706C7.48126 20.2128 7.47607 20.7849 7.2541 21.2878L0.234253 37.1924C-0.349258 38.5145 0.618851 40 2.06396 40L15.196 40H77.9998L177.206 40Z'
					fill='url(#paint0_linear_1487_4168)'
				/>
				<defs>
					<linearGradient
						id='paint0_linear_1487_4168'
						x1='177.206'
						y1='20'
						x2='0.00177002'
						y2='20'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#98367C' />
						<stop offset='1' stopColor='#008D98' />
					</linearGradient>
				</defs>
			</SvgIcon>
			<FeaturedIcon sx={[{ zIndex: 1 }, iconSx]} />
			<Typography
				fontWeight='bold'
				sx={{
					ml: 1,
					zIndex: 1,
					lineHeight: '18px',
					letterSpacing: '1.95px',
				}}
			>
				FEATURED
			</Typography>
		</Box>
	);
};

export default FeaturedBanner;
