import { type ChecklistOption } from '@ivy/components/molecules/Checklist';
import { type ProfileCompleteForm_ClinicianFragment } from '@ivy/gql/types/graphql';

export enum ProfDegree {
	MD = 'MD',
	DO = 'DO',
	PA = 'PA',
	NP = 'NP',
}

export enum Profession {
	PHYSICIAN = 'PHYSICIAN',
	NP = 'NP',
	PA = 'PA',
}

export const PROFESSION2PROFSUCCINT: Readonly<{ [k in Profession]: string }> =
	Object.freeze({
		[Profession.PHYSICIAN]: 'Physician',
		[Profession.PA]: 'PA',
		[Profession.NP]: 'NP',
	});

export const PROFESSION2PROFSUCCINTLOWER: Readonly<{
	[k in Profession]: string;
}> = Object.freeze({
	[Profession.PHYSICIAN]: 'physician',
	[Profession.PA]: 'PA',
	[Profession.NP]: 'NP',
});

export const PROFESSION2PROFVERBOSE: Readonly<{ [k in Profession]: string }> =
	Object.freeze({
		[Profession.PHYSICIAN]: 'Physician',
		[Profession.PA]: 'Physician Assistant',
		[Profession.NP]: 'Nurse Practitioner',
	});

export const PROFVERBOSE2PROFESSION: Readonly<{ [k: string]: Profession }> =
	Object.freeze(
		Object.entries(PROFESSION2PROFVERBOSE).reduce(
			(tot, [k, v]) => ({
				...tot,
				[v]: k,
			}),
			{},
		),
	);

export const PROFESSION2DEGREE: Readonly<{ [k in Profession]: ProfDegree[] }> =
	Object.freeze({
		[Profession.PHYSICIAN]: [ProfDegree.MD, ProfDegree.DO],
		[Profession.PA]: [ProfDegree.PA],
		[Profession.NP]: [ProfDegree.NP],
	});

export const DEGREE2PROFESSION = Object.freeze(
	Object.entries(PROFESSION2DEGREE).reduce(
		(tot, [k, v]) => ({
			...tot,
			// https://github.com/microsoft/TypeScript/issues/44063
			...v.reduce(
				(totV, degree) => ({
					...totV,
					[degree]: k,
				}),
				{},
			),
		}),
		{},
	) as { [k in ProfDegree]: Profession },
);

export const DEGREE2PROFVERBOSE: Readonly<{ [k in ProfDegree]: string }> =
	Object.freeze(
		Object.values(ProfDegree).reduce(
			(tot, v) => ({
				...tot,
				[v]: PROFESSION2PROFVERBOSE[DEGREE2PROFESSION[v]],
			}),
			{},
		) as { [k in ProfDegree]: string },
	);

export const DEGREE2LICENSE: Readonly<{ [k in ProfDegree]: string }> =
	Object.freeze({
		[ProfDegree.MD]: 'Medical',
		[ProfDegree.DO]: 'Medical',
		[Profession.PA]: 'PA',
		[Profession.NP]: 'APRN',
	});

export const PROFESSION2SLUG: Readonly<{ [k in Profession]: string }> =
	Object.freeze({
		[Profession.PHYSICIAN]: 'physician',
		[Profession.PA]: 'physician-assistant',
		[Profession.NP]: 'nurse-practitioner',
	});

export const SLUG2PROFESSION: Readonly<{ [k: string]: Profession }> =
	Object.freeze(
		Object.entries(PROFESSION2SLUG).reduce(
			(tot, [k, v]) => ({
				...tot,
				[v]: k,
			}),
			{},
		),
	);

export enum TrainingType {
	RESIDENCY = 'RESIDENCY',
	FELLOWSHIP = 'FELLOWSHIP',
	CLERKSHIP = 'CLERKSHIP',
	APP = 'APP',
}

export const ClinicianSkillCategory = Object.freeze({
	EKG: 'EKG',
	PROC: 'Procedures & Evaluation',
	ORTHO: 'Orthopaedics',
	POP: 'Evaluation & Management of Special Populations',
	ADV: 'Advanced Procedures',
	US: 'Ultrasound',
});

interface SkillChecklistOption
	extends ChecklistOption<{
		[k in keyof ProfileCompleteForm_ClinicianFragment]?: boolean;
	}> {
	apiKey: string;
}

export const CLN_SKILLS: SkillChecklistOption[] = [
	{
		label: 'EKG interpretation',
		key: 'skillEkgInterpret',
		apiKey: 'skill_ekg_interpret',
		category: ClinicianSkillCategory.EKG,
	},
	{
		label: 'STEMI identification',
		key: 'skillEkgStemi',
		apiKey: 'skill_ekg_stemi',
		category: ClinicianSkillCategory.EKG,
	},
	{
		label: 'Eye foreign body removal',
		key: 'skillEyeFb',
		apiKey: 'skill_eye_fb',
		category: ClinicianSkillCategory.PROC,
	},
	{
		label: 'Ear foreign body removal',
		key: 'skillEarFb',
		apiKey: 'skill_ear_fb',
		category: ClinicianSkillCategory.PROC,
	},
	{
		label: 'Wound repair of scalp, face (including lips), and finger injuries',
		key: 'skillWoundRepair',
		apiKey: 'skill_wound_repair',
		category: ClinicianSkillCategory.PROC,
	},
	{
		label: 'Evaluation and repair of oral, dental, and tongue injuries',
		key: 'skillOral',
		apiKey: 'skill_oral',
		category: ClinicianSkillCategory.PROC,
	},
	{
		label: 'I can also perform these procedures on pediatrics',
		key: 'skillPeds',
		apiKey: 'skill_peds',
		category: ClinicianSkillCategory.PROC,
		footer: true,
		changeHook: (value, options) => {
			if (
				options
					.filter(
						(el) => el.category === ClinicianSkillCategory.PROC && !el.footer,
					)
					.every((el) => !value[el.key])
			) {
				return {
					...value,
					skillPeds: false,
				};
			}
			return value;
		},
		disabled: (value, options) =>
			options
				.filter(
					(el) => el.category === ClinicianSkillCategory.PROC && !el.footer,
				)
				.every((el) => !value[el.key]),
	},
	{
		label: 'Anesthetic blocks for fracture reductions',
		key: 'skillFractureBlocks',
		apiKey: 'skill_fracture_blocks',
		category: ClinicianSkillCategory.ORTHO,
	},
	{
		label: 'Joint reduction',
		key: 'skillJointReduction',
		apiKey: 'skill_joint_reduction',
		category: ClinicianSkillCategory.ORTHO,
	},
	{
		label: 'Neonates',
		key: 'skillNeonates',
		apiKey: 'skill_neonates',
		category: ClinicianSkillCategory.POP,
	},
	{
		label: 'OBGYN',
		key: 'skillObgyn',
		apiKey: 'skill_obgyn',
		category: ClinicianSkillCategory.POP,
	},
	{
		label: 'Endotracheal intubation',
		key: 'skillIntubation',
		apiKey: 'skill_endotracheal_intubation',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Chest tube placement (Thoracostomy)',
		key: 'skillChestTube',
		apiKey: 'skill_chest_tube_placement',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Chest needle decompression',
		key: 'skillChestNeedle',
		apiKey: 'skill_chest_needle',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Central venous line placement',
		key: 'skillVenous',
		apiKey: 'skill_central_venous_line_placement',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Intraosseous (IO) access',
		key: 'skillIOAccess',
		apiKey: 'skill_io_access',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Lumbar puncture',
		key: 'skillLumbar',
		apiKey: 'skill_lumbar_puncture',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Procedural sedation',
		key: 'skillSedation',
		apiKey: 'skill_procedural_sedation',
		category: ClinicianSkillCategory.ADV,
	},
	{
		label: 'Ultrasound diagnostic evaluation',
		key: 'skillUSDiagnostics',
		apiKey: 'skill_us_diagnostics',
		category: ClinicianSkillCategory.US,
	},
	{
		label: 'Ultrasound guided procedures',
		key: 'skillUSProcedures',
		apiKey: 'skill_us_procedures',
		category: ClinicianSkillCategory.US,
	},
];

export const CLINICIAN_SPECIALTIES = Object.freeze({
	PARTNERSHIP_TRACK: 'Partnership track',
	LOCUMS: 'Locums',
	CURRENT_PARTNER: 'Current partner',
	NOCTURNIST: 'Nocturnist',
	VISA_SPONSORSHIP: 'Visa sponsorship',
	TRAVELER: 'Traveler',
	ADMIN: 'Admin',
	WEEKENDS_ONLY: 'Weekends only',
});

export const ClinicianSpecialTypes = [
	{
		label: CLINICIAN_SPECIALTIES.PARTNERSHIP_TRACK,
		key: 'partnershipTrack',
		apiKey: 'partnership_track',
	},
	{
		label: CLINICIAN_SPECIALTIES.LOCUMS,
		key: 'locums',
		apiKey: 'locums',
	},
	{
		label: CLINICIAN_SPECIALTIES.CURRENT_PARTNER,
		key: 'partner',
		apiKey: 'partner',
	},
	{
		label: CLINICIAN_SPECIALTIES.NOCTURNIST,
		key: 'nocturnal',
		apiKey: 'nocturnal',
	},
	{
		label: CLINICIAN_SPECIALTIES.VISA_SPONSORSHIP,
		key: 'visaSponsorship',
		apiKey: 'visa_sponsorship',
	},
	{
		label: CLINICIAN_SPECIALTIES.TRAVELER,
		key: 'traveler',
		apiKey: 'traveler',
	},
	{
		label: CLINICIAN_SPECIALTIES.ADMIN,
		key: 'admin',
		apiKey: 'admin',
	},
	{
		label: CLINICIAN_SPECIALTIES.WEEKENDS_ONLY,
		key: 'weekendsOnly',
		apiKey: 'weekends_only',
	},
];

export enum TermLength {
	LONG = 'LONGTERM',
	SHORT = 'SHORTTERM',
	EITHER = 'EITHER',
}

export const TERM_LENGTH_DISPLAY = Object.freeze({
	[TermLength.LONG]: 'Long-term',
	[TermLength.SHORT]: 'Short-term',
	[TermLength.EITHER]: 'Long and short-term opportunities',
});

export enum WorkSchedule {
	FULL = 'FULLTIME',
	PART = 'PARTTIME',
	EITHER = 'EITHER',
}

export const WORK_SCHEDULE_DISPLAY = Object.freeze({
	[WorkSchedule.FULL]: 'Full-time',
	[WorkSchedule.PART]: 'Part-time',
	[WorkSchedule.EITHER]: 'Full and part-time opportunities',
});

export enum WorkerClassification {
	EMPLOYEE = 'EMPLOYEE',
	CONTRACTOR = 'CONTRACTOR',
	PARTNERSHIP = 'PARTNERSHIP',
}

export const WORKER_CLASSIFICATION_DISPLAY = Object.freeze({
	[WorkerClassification.EMPLOYEE]: 'Employee (W-2)',
	[WorkerClassification.CONTRACTOR]: 'Independent Contractor (1099)',
	[WorkerClassification.PARTNERSHIP]: 'Partnership (K-1)',
});

export const WORKER_CLASSIFICATION_DISPLAY_SHORTHAND = Object.freeze({
	[WorkerClassification.EMPLOYEE]: 'W-2',
	[WorkerClassification.CONTRACTOR]: '1099',
	[WorkerClassification.PARTNERSHIP]: 'K-1',
});

export enum PaymentRatePeriod {
	YEAR = 'YEAR',
	HOUR = 'HOUR',
}

export const PAYMENTRATEDISPLAY = Object.freeze({
	[PaymentRatePeriod.YEAR]: 'yr',
	[PaymentRatePeriod.HOUR]: 'hr',
});

export const FieldType = Object.freeze({
	SPECIALTY: 'SPECIALTY',
	SUBSPECIALTY: 'SUBSPECIALTY',
});

export const FIELD_TYPE_DISPLAY = Object.freeze({
	[FieldType.SPECIALTY]: 'Specialty',
	[FieldType.SUBSPECIALTY]: 'Subspecialty',
});

export enum ExperienceLevel {
	INEXPERIENCED = 'INEXPERIENCED',
	MODERATE = 'MODERATE',
	EXPERIENCED = 'EXPERIENCED',
	SEASONED = 'SEASONED',
}
export const EXP_LEVEL_DISPLAY = Object.freeze({
	[ExperienceLevel.INEXPERIENCED]: 'Less than 1 year',
	[ExperienceLevel.MODERATE]: '1 to 5 years',
	[ExperienceLevel.EXPERIENCED]: '5 to 10 years',
	[ExperienceLevel.SEASONED]: '10+ years',
});

export const SCHOOL_FOR_DEGREE = {
	[ProfDegree.MD]: 'Medical School',
	[ProfDegree.DO]: 'Osteopathic Medical School',
	[ProfDegree.PA]: 'Physician Assistant Program',
	[ProfDegree.NP]: 'Nurse Practitioner Program',
};

export const CLINICIAN_PLACEHOLDER_IMAGE =
	'https://assets.ivyclinicians.io/placeholders/the-doctor-luke-fildes.png';
