import React, { useEffect } from 'react';

import { useMutation, useSubscription } from '@apollo/client';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';

import DataLoader from '@ivy/components/molecules/DataLoader';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import ConfirmEmailSection from '@ivy/components/organisms/ConfirmEmailSection';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';
import { usePrevious } from '@ivy/lib/hooks';

// When this subscription has confirmedEmail change from false -> true, it will also update currAcc through the
// Apollo cache so this popup won't show again.
const ConfirmEmailPopup_ConfirmedEmailSDoc = gql(/* GraphQL */ `
	subscription ConfirmEmailPopup_ConfirmedEmail($accountId: uuid!) {
		accounts: current_account(where: { id: { _eq: $accountId } }) {
			id
			ci: contact_info {
				id
				confirmedEmail: confirmed_email
				confirmationSentAt: confirmation_sent_at
			}
		}
	}
`);

const ConfirmEmailPopup_ResendVerificationMDoc = gql(/* GraphQL */ `
	mutation ConfirmEmailPopup_ResendVerification($email: String!) {
		resend: resend_verification(email: $email) {
			success
		}
	}
`);

export interface ConfirmEmailPopupProps extends PopupProps {
	onSuccess?: () => void;
	currAccLoading?: boolean;
}

const ConfirmEmailPopup = ({
	onClose,
	onSuccess,
	currAccLoading,
	open,
	...props
}: ConfirmEmailPopupProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const currAcc = useCurrentAccount();
	const email = currAcc?.ci?.email;
	// If the user confirms their email on a separate tab, window, or even browser profile (no shared local storage),
	// we will still receive an update here via websockets.
	const { data, loading, error } = useSubscription(
		ConfirmEmailPopup_ConfirmedEmailSDoc,
		{
			variables: {
				accountId: currAcc?.id,
			},
			skip: !currAcc?.id || !open,
		},
	);
	const confirmedEmail = data?.accounts[0]?.ci?.confirmedEmail;
	const prevConfirmedEmail = usePrevious(confirmedEmail);
	// Ignore the error from this, may have automatically requested to resend too many times
	const [resend, { loading: resending }] = useMutation(
		ConfirmEmailPopup_ResendVerificationMDoc,
	);

	useEffect(() => {
		if (!currAccLoading && !currAcc) {
			onClose?.();
		}
	}, [currAccLoading, currAcc, onClose]);

	useEffect(() => {
		if (confirmedEmail) {
			// If this popup opens but the user has already confirmed their email, simply exit onSuccess without
			// any message.
			// Note strict comparison to false as, once upon a time, prevConfirmedEmail will have been undefined
			// if not logged in yet.
			if (prevConfirmedEmail === false) {
				enqueueSnackbar('Your email has been confirmed.', {
					variant: 'success',
				});
			}
			onSuccess?.();
		}
	}, [confirmedEmail, prevConfirmedEmail, enqueueSnackbar, onSuccess]);

	useEffect(() => {
		// When a user confirms their email in a separate tab, this effect runs again since confirmedEmail is set
		// to true and confirmationSentAt is set to null, so we need to check if confirmedEmail changed to prevent
		// calling resend unnecessarily.
		const acc = data?.accounts[0];
		if (!open || !acc || !email || acc.ci?.confirmedEmail) {
			return;
		}
		const confSentAt = acc.ci?.confirmationSentAt
			? parseJSON(acc.ci.confirmationSentAt)
			: null;
		const nw = new Date();
		// If confirmation email sent more than 1 hour ago (or never sent at all), try to automatically resend
		// Note that, because of the subscription, the confSentAt time will update and this effect will run again,
		// but we will not send another email as not enough time has passed.
		if (!confSentAt || nw.getTime() - confSentAt.getTime() > 60 * 60 * 1000) {
			resend({
				variables: {
					email: email,
				},
			});
		}
	}, [open, data, email, resend]);

	if (!currAcc || !email || data?.accounts[0].ci?.confirmedEmail || !open) {
		return null;
	}

	return (
		<DataLoader
			variant='circular'
			fullscreen
			transparent
			data={data}
			loading={loading}
			error={error}
		>
			{() => (
				<Popup onClose={onClose} open={open} {...props}>
					<ConfirmEmailSection email={email} disabled={resending} />
				</Popup>
			)}
		</DataLoader>
	);
};

export default ConfirmEmailPopup;
