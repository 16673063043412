import React, { useMemo } from 'react';

import {
	Link as MuiLink,
	type LinkProps,
	type LinkTypeMap,
	type SxProps,
	type Theme,
} from '@mui/material';
import {
	Link as RouterLink,
	useLocation,
	useSearchParams,
} from 'react-router-dom';

import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import config from '@ivy/config';
import { RouteUri, LinkUri } from '@ivy/constants/routes';
import { combineSx } from '@ivy/lib/styling/sx';
import {
	checkOutsideLink,
	getOutsideTo,
	appendQuery,
	type WhitelabelTo,
	type LocationState,
	populateLocationStateDefaults,
} from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

export type RouteLinkProps<
	C extends React.ElementType = LinkTypeMap['defaultComponent'],
> = LinkProps<
	C,
	{
		component?: C;
		openInNewTab?: boolean;
		to?: WhitelabelTo;
		forwardState?: boolean;
		forwardQuery?: boolean;
		disabled?: boolean;
		state?: LocationState;
		sx?: SxProps<Theme>;
	}
>;

// https://mui.com/material-ui/guides/composition/#with-typescript
// https://stackoverflow.com/questions/46152782/how-to-extend-props-for-material-ui-components-using-typescript
const RouteLinkWithRef = React.forwardRef(
	<C extends React.ElementType = LinkTypeMap['defaultComponent']>(
		{
			component,
			sx,
			disabled = false,
			openInNewTab = false,
			to = '',
			state,
			forwardState = false,
			forwardQuery = false,
			...props
		}: RouteLinkProps<C>,
		ref,
	) => {
		const [searchParams] = useSearchParams();
		const location = useLocation();
		const redirect = useRedirect();
		const outsideLink = checkOutsideLink(to);
		// Used as a memo dependency for better equality comparison as state may not be a primitive value
		const wl = useWhitelabel();
		const stringifiedState =
			state &&
			JSON.stringify(populateLocationStateDefaults(state, location, wl.name));

		const totalTo = useMemo(() => {
			const newSearchParams = new URLSearchParams(
				forwardQuery ? searchParams : undefined,
			);
			if (openInNewTab) {
				// Can pass `location.state` to new tabs by way of the `locationState` search param
				if (stringifiedState) {
					newSearchParams.append('locationState', stringifiedState);
				} else if (forwardState && location.state) {
					newSearchParams.append(
						'locationState',
						JSON.stringify(
							populateLocationStateDefaults(location.state, location, wl.name),
						),
					);
				}
			}
			return appendQuery(to, newSearchParams.toString());
		}, [
			to,
			forwardQuery,
			searchParams,
			openInNewTab,
			stringifiedState,
			forwardState,
			location,
			wl.name,
		]);

		const linkProps: LinkProps = {
			target: openInNewTab ? '_blank' : undefined,
			rel: openInNewTab ? 'noreferrer' : undefined,
			sx: combineSx(
				{
					fontSize: 'inherit', // Changing component to 'button' can decrease font size
					color: disabled ? 'text.disabled' : undefined,
				},
				sx,
			),
			...props,
		};

		if (outsideLink) {
			return (
				<MuiLink
					ref={ref}
					component={component as React.ElementType}
					href={disabled ? undefined : getOutsideTo(totalTo)}
					onClick={
						typeof to !== 'string' &&
						to.whitelabel &&
						to.whitelabel !== config.whitelabel &&
						to.showSplashScreen &&
						!openInNewTab
							? (ev) => {
									ev.stopPropagation();
									ev.preventDefault();
									redirect(totalTo);
							  }
							: undefined
					}
					{...linkProps}
				/>
			);
		}

		return (
			<MuiLink
				ref={ref}
				component={
					(component as React.ElementType) ||
					(disabled ? undefined : RouterLink)
				}
				to={disabled ? undefined : totalTo}
				state={
					disabled
						? undefined
						: forwardState
						? populateLocationStateDefaults(location.state, location, wl.name)
						: populateLocationStateDefaults(state, location, wl.name)
				}
				{...linkProps}
			/>
		);
	},
);

type RouteLinkType = typeof RouteLinkWithRef & {
	routes: typeof RouteUri;
	links: typeof LinkUri;
};

const RouteLink: RouteLinkType = RouteLinkWithRef as unknown as RouteLinkType;
RouteLink.routes = RouteUri;
RouteLink.links = LinkUri;

export default RouteLink;
