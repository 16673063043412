import React from 'react';

import { AccountType } from '@ivy/constants/account';

import AccountTypeBoundary from './AccountTypeBoundary';

const OrgBoundary = (props) => {
	return <AccountTypeBoundary accountType={AccountType.ORG} {...props} />;
};

export default OrgBoundary;
