import React, { useState, useLayoutEffect, useCallback } from 'react';

import {
	Box,
	Chip,
	useTheme,
	type Theme,
	type SxProps,
	Typography,
	SvgIcon,
	useMediaQuery,
	Stack,
} from '@mui/material';
import { useElementSize } from 'usehooks-ts';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { ScrollDownIcon } from '@ivy/components/icons';
import FacilitySearchInput from '@ivy/components/organisms/FacilitySearchInput';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { type CompleteLocation } from '@ivy/lib/services/maps';
import { combineSx } from '@ivy/lib/styling/sx';

const heroImg = 'https://assets.ivyclinicians.io/images/reviews_hero.png';
const heroBubbles =
	'https://assets.ivyclinicians.io/images/reviews_hero_snippet.png';

const HeroImages = ({ sx }: { sx?: SxProps<Theme> }) => {
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					justifyContent: 'center',
					position: 'relative',
					mb: { md: 11, xs: 0 },
				},
				sx,
			)}
		>
			<Box
				component='img'
				src={heroImg}
				sx={{
					width: {
						xs: '80%',
						sm: '60%',
						md: '300px',
						lg: '500px',
						xl: '650px',
						xxl: '660px',
					},
				}}
			/>
			<Box
				component='img'
				src={heroBubbles}
				sx={{
					position: 'absolute',
					width: {
						xs: '50%',
						sm: '45%',
						md: '235px',
						xl: '520px',
						xxl: '540px',
					},
					bottom: { xl: '-20px', md: '0px', xs: '13%' },
					mr: {
						md: '50px',
						xl: '100px',
					},
				}}
			/>
		</Box>
	);
};

const HeroCurve = ({ fill, sx }: { fill?: string; sx: SxProps<Theme> }) => {
	return (
		<SvgIcon
			width='100%'
			height='100%'
			viewBox='0 0 200 200'
			xmlns='http://www.w3.org/2000/svg'
			preserveAspectRatio='none'
			sx={sx}
		>
			<defs>
				<linearGradient id='Gradient2' x1='0' x2='0' y1='0' y2='1'>
					<stop offset='0%' stopColor='red' />
					<stop offset='50%' stopColor='black' stopOpacity='0' />
					<stop offset='100%' stopColor='blue' />
				</linearGradient>
			</defs>
			<path
				d='M0 0H200V189.78C57.3437 -104.929 34.4267 362.021 0 135.624V0Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};

const Search = ({ sx }: { sx?: SxProps<Theme> }) => {
	const redirect = useRedirect();

	const handleSubmit = (location: CompleteLocation) => {
		if (!('url' in location)) {
			return;
		}
		redirect(
			// TODO: find a better way to construct the URL with the id and slug
			location.url.replace('/view', '/review'),
			{
				state: {
					// This will actually only be useful on mobile where we don't open in a new tab
					// On desktop, where we open in a new tab, this info is lost, so we set a default backNav
					backNav: {
						target: 'reviews',
					},
				},
			},
		);
	};

	return (
		<FacilitySearchInput
			mobileBp='sm'
			onSubmit={handleSubmit}
			sx={sx}
			hideProfession
			types={['facility', 'org']}
		/>
	);
};

interface HeroProps {
	onClick?: () => void;
	sx?: SxProps<Theme>;
}

const Hero = ({ onClick, sx }: HeroProps) => {
	const [ref, { height: heroHeight }] = useElementSize();
	const currAcc = useCurrentAccount();
	const baseTheme = useTheme();
	const [scrolling, setScrolling] = useState(false);
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);

	useLayoutEffect(() => {
		const windowOffset = heroHeight - window.innerHeight;

		const handleScroll = () => {
			const offset = Math.max(windowOffset, 0);
			if (
				document.body.scrollTop > offset ||
				document.documentElement.scrollTop > offset
			) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [setScrolling, heroHeight]);

	const handleClickScrollDown = useCallback(() => {
		if (scrolling) {
			return;
		}
		window.scrollBy({
			top: heroHeight + 30,
			behavior: 'smooth',
		});
	}, [scrolling, heroHeight]);

	const subtext = isMobile
		? 'Gain valuable insights as you explore employer reviews and ratings.'
		: 'Gain valuable insights into emergency medicine employers before taking the next step in your career. Explore reviews and find employers that align with the qualities most important to your job search.';

	return (
		<>
			<Box
				component='section'
				sx={combineSx(
					{
						position: 'relative',
						height: {
							xs: 'calc(100vh - 64px)',
							mobileMenu: 'calc(100vh - 104px)',
						},
						minHeight: { md: '720px', xs: '840px' },
					},
					sx,
				)}
				ref={ref}
			>
				<Box
					sx={{
						// Full bleed
						width: '100vw',
						position: 'absolute',
						left: '50%',
						right: '50%',
						ml: '-50vw',
						mr: '-50vw',
						height: '100%',
						zIndex: -1,
						bgcolor: 'light4.main',
					}}
				/>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: { md: 'normal', xs: 'flex-start' },
						height: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexflow: 'row nowrap',
							alignItems: 'center',
							pt: { md: 0, xs: 4 },
						}}
					>
						<Stack
							sx={{
								alignItems: { md: 'flex-start', xs: 'center' },
								justifyContent: 'center',
								flex: 1,
								flexBasis: { md: '60%' },
							}}
						>
							<Stack
								direction={{
									mobileMenu: 'row',
									xs: 'column-reverse',
								}}
								alignItems='center'
								sx={{
									alignItems: 'flex-start',
								}}
							>
								<Stack
									justifyContent='center'
									alignItems='center'
									sx={{ position: 'relative' }}
								>
									<Typography component='h1' variant='h2' mb={4}>
										Discover the best workplace through{' '}
										<Box
											component='span'
											sx={{
												position: 'relative',
												whiteSpace: 'nowrap',
											}}
										>
											reviews!
											<SvgIcon
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 292 21'
												fill='none'
												sx={{
													userSelect: 'none',
													position: 'absolute',
													width: '100%',
													height: 'auto',
													left: 0,
													top: '90%',
													display: { md: 'inline-block', xs: 'none' },
												}}
											>
												<path
													d='M1 20C6.14842 7.0031 100.767 5.21686 148 8.65898C150.777 8.86136 153.39 9.08182 155.812 9.31914C158.626 9.59489 161.529 9.88581 164.5 10.1847C207.566 14.5176 264.713 20.5222 267.015 6.23894C269.476 -9.02952 241.94 15.0635 291 3.92028'
													stroke='#6226C5'
													strokeWidth='2'
													fill='none'
												/>
											</SvgIcon>
										</Box>
									</Typography>
								</Stack>
								<Chip
									label='BETA'
									sx={{
										bgcolor: '#6226C5',
										fontWeight: 'bold',
										color: '#fff',
										ml: {
											mobileMenu: 5,
											xs: 'auto',
										},
										mb: {
											mobileMenu: 0,
											xs: 3,
										},
										mt: { lg: 2, xs: 0 },
									}}
								/>
							</Stack>
							<Typography
								variant={isMobile ? 'body1' : 'h6'}
								sx={{ mb: { md: 0, xs: 4 } }}
								color='text.icon'
							>
								{subtext}
								{isClinicianOrAnon && (
									<>
										{' '}
										<Box
											component='span'
											sx={(theme) => ({
												color: theme.palette.primary.main,
												textDecorationColor: 'rgba(38, 130, 197, 0.4)',
												cursor: 'pointer',
												fontWeight: 'bold',
												'&:hover': {
													textDecoration: 'underline',
												},
											})}
											onClick={onClick}
										>
											Submit your job review here.
										</Box>
									</>
								)}
							</Typography>
							<Search
								sx={{
									mt: { md: 8, xs: 0 },
									mb: { md: 0, xs: 4 },
									width: { xxl: '75%', xl: '85%', lg: '95%', xs: '100%' },
								}}
							/>
							<HeroImages
								sx={{ display: { md: 'none', xs: 'flex' }, width: '100%' }}
							/>
							{isClinicianOrAnon && (
								<Typography
									variant='h6'
									sx={(theme) => ({
										color: theme.palette.primary.main,
										textDecorationColor: 'rgba(38, 130, 197, 0.4)',
										cursor: 'pointer',
										textDecoration: 'none',
										'&:hover': {
											textDecoration: 'underline',
										},
										textAlign: 'center',
										display: { md: 'initial', xs: 'none' },
									})}
									mt={{ md: 5, xs: 3 }}
									component={RouteLink}
									to={RouteUri.SALARY}
									state={{
										backNav: {
											target: 'reviews',
										},
									}}
								>
									Looking for salary information?
								</Typography>
							)}
						</Stack>
						<HeroImages
							sx={{
								display: { md: 'flex', xs: 'none' },
								flexBasis: { md: '40%' },
							}}
						/>
					</Box>
					<ScrollDownIcon
						sx={{
							position: 'absolute',
							bottom: 16,
							left: '50%',
							transform: 'translateX(-50%)',
							width: '63px',
							height: 'auto',
							color: 'text.icon',
							shapeRendering: 'geometricPrecision',
							opacity: scrolling ? 0 : 0.5,
							transition: 'opacity 0.3s linear',
							...(!scrolling && {
								cursor: 'pointer',
								WebkitTapHighlightColor: 'transparent',
								userSelect: 'none',
								WebkitUserSelect: 'none',
							}),
							animation: 'fade-in 2.4s, move-up-down 1.25s linear infinite',
							'@keyframes fade-in': {
								'0%': {
									opacity: 0,
								},
								'50%': {
									opacity: 0,
								},
							},
							'@keyframes move-up-down': {
								'0%, 100%': {
									bottom: 16,
								},
								'50%': {
									bottom: 28,
								},
							},
						}}
						onClick={handleClickScrollDown}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					position: 'relative',
					height: '80px',
					display: { sm: 'block', xs: 'none' },
				}}
			>
				<HeroCurve
					sx={{
						// Full bleed
						width: '100vw',
						position: 'absolute',
						left: '50%',
						right: '50%',
						ml: '-50vw',
						mr: '-50vw',
						height: '100%',
						zIndex: -1,
						backgroundColor: baseTheme.palette.background.paper,
					}}
					fill={baseTheme.palette.light4.main}
				/>
			</Box>
		</>
	);
};

export default Hero;
