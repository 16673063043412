import { useState, useEffect } from 'react';

import mixpanel from 'mixpanel-browser';

import { useCurrentAccount } from '@ivy/gql/hooks';
import { useFullPath } from '@ivy/lib/hooks/index';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

export interface MixpanelOptions {
	disabled?: boolean;
	debug?: boolean;
	apiHost?: string;
}

const useMixpanel = (mixpanelToken?: string, options?: MixpanelOptions) => {
	const wl = useWhitelabel();
	const [init, setInit] = useState(false);
	const currAcc = useCurrentAccount();
	const fullPath = useFullPath();

	useEffect(() => {
		if (init) {
			return;
		}
		// Must use a bad token to disable mixpanel so we don't need to check everywhere if it has loaded or not
		// Some methods on `mixpanel` simply don't exist if `init` hasn't been called and will error out
		mixpanel.init(
			options?.disabled ? '_badtoken' : mixpanelToken || '_badtoken',
			{
				// Doesn't work with SPA
				track_pageview: false,
				persistence: 'localStorage',
				debug: options?.debug,
				api_host: options?.apiHost,
				ignore_dnt: true,
			},
		);
		mixpanel.register({
			platform: wl.name,
			user_agent: navigator.userAgent,
		});
		setInit(true);
	}, [
		init,
		setInit,
		options?.disabled,
		mixpanelToken,
		options?.debug,
		options?.apiHost,
		wl.name,
	]);

	useEffect(() => {
		if (!init || !currAcc?.id) {
			return;
		}
		// Identify if already logged in or if log in later
		mixpanel.identify(currAcc.id);
	}, [init, currAcc?.id]);

	useEffect(() => {
		if (!init) {
			return;
		}
		mixpanel.track_pageview();
	}, [init, fullPath]);
};

export default useMixpanel;
