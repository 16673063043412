import { useRef } from 'react';

import { useInView } from 'react-cool-inview';

const useInfiniteScroll = ({ hasMore, fetchNextPage }) => {
	const fetching = useRef(false);

	const { observe } = useInView({
		rootMargin: '50px 0px',
		threshold: 0.5,
		onChange: async ({ unobserve, inView }) => {
			if (inView && hasMore && !fetching.current) {
				unobserve();
				fetching.current = true;
				await fetchNextPage();
				fetching.current = false;
				observe();
			}
		},
	});

	return observe;
};

export default useInfiniteScroll;
