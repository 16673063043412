import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='21'
			height='20'
			viewBox='0 0 21 20'
			fill='none'
			{...props}
		>
			<path
				d='M18.7167 5.94946V14.2828C18.7167 14.8353 18.4973 15.3652 18.1066 15.7559C17.7159 16.1466 17.1859 16.3661 16.6334 16.3661H4.13341C3.58088 16.3661 3.05097 16.1466 2.66027 15.7559C2.26957 15.3652 2.05008 14.8353 2.05008 14.2828V5.94946'
				stroke='currentColor'
				strokeWidth='1.4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M18.7167 5.94954C18.7167 5.39701 18.4973 4.86711 18.1066 4.47641C17.7159 4.0857 17.1859 3.86621 16.6334 3.86621H4.13341C3.58088 3.86621 3.05097 4.0857 2.66027 4.47641C2.26957 4.86711 2.05008 5.39701 2.05008 5.94954L9.27925 10.4634C9.61035 10.6704 9.99295 10.7801 10.3834 10.7801C10.7739 10.7801 11.1565 10.6704 11.4876 10.4634L18.7167 5.94954Z'
				stroke='currentColor'
				strokeWidth='1.4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
