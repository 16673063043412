import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { parseJSON, format as formatDate } from 'date-fns';

import DataLoader from '@ivy/components/molecules/DataLoader';

const LEGAL_DOC_QUERY = gql`
	query legalDocument($title: String!) {
		legal(where: { title: $title }) {
			id
			title
			publishedAt
			content {
				html
			}
		}
	}
`;

const LegalDocument = ({ title }) => {
	const { data, loading, error } = useQuery(LEGAL_DOC_QUERY, {
		variables: {
			title,
		},
	});

	return (
		<DataLoader
			data={data}
			loading={loading}
			error={error}
			fullscreen
			variant='logo'
		>
			{() => (
				<Box>
					<Typography variant='h3' component='h1' gutterBottom align='center'>
						Ivy Clinicians {data.legal.title}
					</Typography>
					<Typography
						variant='subtitle1'
						component='p'
						gutterBottom
						align='center'
					>
						Last updated{' '}
						{formatDate(parseJSON(data.legal.publishedAt), 'MMM d, yyyy')}
					</Typography>
					<Typography
						variant='body1'
						component='div'
						p={2}
						dangerouslySetInnerHTML={{
							__html: data.legal.content.html,
						}}
					/>
				</Box>
			)}
		</DataLoader>
	);
};

export default LegalDocument;
