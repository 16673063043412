import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import {
	Box,
	useMediaQuery,
	Typography,
	useTheme,
	Tabs,
	Tab,
} from '@mui/material';
import PropTypes from 'prop-types';

import RouteLink from '@ivy/components/atoms/RouteLink';
import BlobPicture from '@ivy/components/molecules/BlobPicture';
import DataLoader from '@ivy/components/molecules/DataLoader';
import NavTemplate from '@ivy/components/templates/NavTemplate';

import FaqAccordion from './FaqAccordion';
import FooterFaq from './FooterFaq';
import GradientBlob from './GradientBlob';

const GET_FAQS_QUERY = gql`
    ${FaqAccordion.fragments.faq.fragment}
    query getFaqs {
        clinicianFaqs: faqs(orderBy: order_ASC, where: {audience: CLINICIAN, category: WHY_IVY}) {
            ...${FaqAccordion.fragments.faq.name}
        }
        employerFaqs: faqs(orderBy: order_ASC, where: {audience: EMPLOYER, category: WHY_IVY}) {
            ...${FaqAccordion.fragments.faq.name}
        }
    }
`;

const TabPanel = ({ children, value, index, ...other }) => {
	return (
		<Box
			sx={{
				display: value !== index && 'none',
			}}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ py: 3 }}>{children}</Box>}
		</Box>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

const Faq = () => {
	const { data, loading, error } = useQuery(GET_FAQS_QUERY);
	const [value, setValue] = useState(0);
	const [expanded, setExpanded] = useState('');
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const isMd = useMediaQuery(theme.breakpoints.down('md'));
	const isLg = useMediaQuery(theme.breakpoints.down('lg'));

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<NavTemplate
			fullscreen
			showFooter
			pageTitle='Frequently Asked Questions'
			pageDescription='View answers to frequently asked questions about Ivy Clinicians.'
			showSupportLauncher
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				data={data}
				loading={loading}
				error={error}
				fullscreen
			>
				{() => (
					<Box sx={{ mb: 5 }}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: {
									xs: 'column-reverse',
									md: 'row',
								},
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography
								variant={isMd ? 'h5' : isLg ? 'h3' : 'h1'}
								component='h1'
								sx={{
									whiteSpace: 'pre',
									pb: 5,
									ml: {
										md: '2%',
										lg: '2%',
									},
								}}
							>
								Need a hand?{'\n'}We've got you.
							</Typography>
							<BlobPicture
								picture='https://assets.ivyclinicians.io/images/jtodd.jpeg'
								sx={{
									maxWidth: {
										xs: '400px',
										md: '700px',
									},
									width: '100%',
								}}
							/>
						</Box>
						<Box
							sx={{
								mx: 'auto',
								px: 2,
								maxWidth: '879px',
								pb: {
									xs: 8,
									md: 27,
								},
							}}
						>
							<GradientBlob
								sx={{
									display: {
										xs: 'none',
										lg: 'initial',
									},
									fontSize: '624px',
									position: 'absolute',
									left: '-520px',
									top: '453px',
								}}
							/>
							<Tabs
								onChange={handleTabChange}
								value={value}
								centered={isSm}
								sx={{
									mb: {
										// Subtract 3 since tabs having py: 3
										xs: 2,
										md: 7,
									},
								}}
							>
								<Tab
									label="I'm a clinician"
									value={0}
									{...a11yProps(0)}
									sx={{
										p: 0,
										mr: {
											xs: 2,
											sm: 8,
											md: 14,
										},
										alignItems: 'flex-start',
										fontSize: {
											xs: '18px',
											md: '24px',
										},
									}}
								/>
								<Tab
									label="I'm an employer"
									value={1}
									{...a11yProps(1)}
									sx={{
										p: 0,
										alignItems: 'flex-start',
										fontSize: {
											xs: '18px',
											md: '24px',
										},
									}}
								/>
							</Tabs>
							<TabPanel value={value} index={0}>
								{data.clinicianFaqs.map((faq) => (
									<FaqAccordion
										key={`clinician-faq-${faq.id}`}
										faq={faq}
										expanded={expanded === `${faq.id}`}
										onChange={handleAccordionChange(`${faq.id}`)}
										labelComponent='h2'
										sx={{
											mb: 2,
										}}
									/>
								))}
							</TabPanel>
							<TabPanel value={value} index={1}>
								{data.employerFaqs.map((faq) => (
									<FaqAccordion
										key={`employer-faq-${faq.id}`}
										faq={faq}
										expanded={expanded === `${faq.id}`}
										onChange={handleAccordionChange(`${faq.id}`)}
										labelComponent='h2'
										sx={{
											mb: 2,
										}}
									/>
								))}
							</TabPanel>
							<Typography
								variant='body1'
								sx={{ fontSize: '20px' }}
								component={RouteLink}
								to={RouteLink.routes.FAQ_ALL}
							>
								See all FAQs {'>'}
							</Typography>
						</Box>
						<FooterFaq />
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default Faq;
