import React from 'react';

import { TextField, type TextFieldProps } from '@mui/material';

export type FormattedInputProps = TextFieldProps & {
	format: (val: string) => string;
	onChange: (
		ev: Parameters<NonNullable<TextFieldProps['onChange']>>[0],
		formatted: string,
	) => void;
};

// TODO: switch to react-input-mask
const FormattedInput = ({
	onChange,
	format,
	...props
}: FormattedInputProps) => {
	const handleChange = (
		ev: Parameters<NonNullable<TextFieldProps['onChange']>>[0],
	) => {
		const caret = ev.target.selectionStart || 0;
		const element = ev.target;
		const { value } = element;
		const formatted = format(value);
		const diff = formatted.length - value.toString().length;

		// TODO: this is unnerving with the callback race condition, but it should take place before the next repaint
		window.requestAnimationFrame(() => {
			element.selectionStart = caret + diff;
			element.selectionEnd = caret + diff;
		});
		ev.target.value = formatted;
		onChange && onChange(ev, formatted);
	};

	return <TextField onChange={handleChange} {...props} />;
};

export default FormattedInput;
