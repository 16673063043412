import React from 'react';

import { useQuery } from '@apollo/client';
import {
	Box,
	TextField,
	Stack,
	type TextFieldProps as MuiTextFieldProps,
	type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import LabeledCheckbox from '@ivy/components/atoms/LabeledCheckbox';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import { type ProfDegree } from '@ivy/constants/clinician';
import { escapeLike } from '@ivy/gql/queryUtil';
import { gql } from '@ivy/gql/types';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { useDebounce } from '@ivy/lib/hooks';
import { yearsFromNow } from '@ivy/lib/validation/date';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

interface SchoolValues {
	schoolName: string;
	gradYr: Date | null;
	currStudent: boolean;
}

interface SchoolOptionProps {
	id: string;
	prof_degree: string;
	name: string;
}

const School_SearchSchoolsQDoc = gql(/* GraphQL */ `
	query School_SearchSchools(
		$search: String!
		$profDegree: professionaldegree!
	) {
		schools: school(
			where: { name: { _ilike: $search }, prof_degree: { _eq: $profDegree } }
			order_by: { name: asc }
			limit: 10
		) {
			id
			name
			prof_degree
		}
	}
`);

interface SchoolAutocompleteProps<T>
	extends Omit<
		MuiAutocompleteProps<
			T,
			boolean | undefined,
			boolean | undefined,
			boolean | undefined
		>,
		'options' | 'renderInput'
	> {
	value: string;
	profDegree: ProfDegree | string;
	disabled: boolean;
	TextFieldProps: MuiTextFieldProps;
}

const SchoolAutocomplete = ({
	value,
	profDegree,
	disabled,
	TextFieldProps,
	...props
}: SchoolAutocompleteProps<SchoolOptionProps>) => {
	const debouncedValue = useDebounce(value, 250);
	const { data } = useQuery(School_SearchSchoolsQDoc, {
		variables: {
			search: escapeLike(debouncedValue),
			profDegree,
		},
		skip: disabled || !profDegree,
	});

	const choices: SchoolOptionProps[] = data?.schools || [];

	return (
		<Autocomplete
			freeSolo
			blurOnSelect
			autoHighlight
			selectOnFocus
			disableClearable
			options={choices}
			getOptionLabel={(option) =>
				typeof option === 'string' ? option : option.name
			}
			inputValue={value}
			disabled={disabled}
			TextFieldProps={TextFieldProps}
			{...props}
		/>
	);
};

const validation = yup.object({
	schoolName: yup.string().max(200).required().label('School'),
	currStudent: yup.boolean().required(),
	gradYr: yup
		.date()
		// In case of null or InvalidDate object
		.typeError('Must be a valid date')
		.min(new Date(1900, 0), 'Must be after 1900')
		.max(new Date(yearsFromNow(0), 0), 'Cannot be in the future')
		.when('currStudent', {
			is: (val: boolean) => val,
			then: (schema) => schema.nullable(),
			otherwise: (schema) => schema.required(),
		})
		.label('Graduation year'),
});

const initialValue = {
	schoolName: '',
	currStudent: false,
	gradYr: null,
};

const School = ({
	formik,
	onNext,
	extra,
}: SubFormStepProps<ProfileCompleteFormValues, ExtraParams>) => {
	const { clinician } = extra;
	const { profDegree, isAPP } = clinician;
	const {
		values,
		touched,
		errors,
		setFieldTouched,
		setFieldValue,
		isSubmitting,
	} = formik;

	return (
		<Stack spacing={1}>
			<SchoolAutocomplete
				TextFieldProps={{
					label: 'School',
					placeholder: 'Enter a school',
					error: touched.schoolName && !!errors.schoolName,
					helperText: touched.schoolName && errors.schoolName,
					InputLabelProps: {
						shrink: true,
					},
				}}
				profDegree={profDegree}
				value={values.schoolName}
				onInputChange={(_: React.SyntheticEvent, nv: string) =>
					setFieldValue('schoolName', nv)
				}
				onBlur={() => setFieldTouched('schoolName')}
				disabled={isSubmitting}
				fullWidth
				sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
			/>
			<DatePicker
				views={['year']}
				label='Year of Graduation'
				value={values.gradYr}
				onChange={(newVal) => setFieldValue('gradYr', newVal)}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						error={touched.gradYr && !!errors.gradYr}
						helperText={touched.gradYr && errors.gradYr}
						sx={{ maxWidth: '200px' }}
						onBlur={() => setFieldTouched('gradYr')}
					/>
				)}
				disabled={isSubmitting || values.currStudent}
				minDate={new Date(1900, 0)}
				maxDate={new Date(yearsFromNow(0), 0)}
			/>
			{/* Student APPs are allowed but physicians must have graduated and be at least current residents */}
			{isAPP && (
				<LabeledCheckbox
					label='Still attending'
					value={values.currStudent}
					onChange={(_, nv) => {
						setFieldValue('gradYr', null);
						setFieldValue('currStudent', nv);
					}}
					disabled={isSubmitting}
					size='small'
				/>
			)}
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Stack>
	);
};

export default School;
export { type SchoolValues, validation, initialValue };
