import { formatInteger } from '@ivy/lib/formatting/number';

export const formatWage = (
	isExact,
	exact,
	minAmnt,
	maxAmnt,
	unit,
	truncate = false,
) => {
	if (!isExact && minAmnt === maxAmnt) {
		isExact = true;
		exact = minAmnt;
	}
	if (isExact) {
		const willTrunc = truncate && exact >= 1000;
		return `$${formatInteger(willTrunc ? Math.floor(exact / 1000) : exact)}${
			willTrunc ? 'K' : ''
		}${unit ? `/${unit}` : ''}`;
	}
	const willTrunc = truncate && minAmnt >= 1000 && maxAmnt >= 1000;
	return `$${formatInteger(willTrunc ? Math.floor(minAmnt / 1000) : minAmnt)}${
		willTrunc ? 'K' : ''
	} - $${formatInteger(willTrunc ? Math.floor(maxAmnt / 1000) : maxAmnt)}${
		willTrunc ? 'K' : ''
	}${unit ? `/${unit}` : ''}`;
};
