import React from 'react';

import { AccountType } from '@ivy/constants/account';

import AccountTypeBoundary from './AccountTypeBoundary';

const ClinicianBoundary = (props) => {
	return <AccountTypeBoundary accountType={AccountType.CLINICIAN} {...props} />;
};

export default ClinicianBoundary;
