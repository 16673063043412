import React from 'react';

import { type FormikProps } from 'formik-othebi';

import ConfirmEmailSection from '@ivy/components/organisms/ConfirmEmailSection';

import { type OnboardingFormValues } from './onboardingForm';

export interface VerificationStepProps {
	formik: FormikProps<OnboardingFormValues>;
}

const VerificationStep = ({ formik }: VerificationStepProps) => {
	// TODO: If the user clicks the verification email in the same browser profile, we'll log in automatically on the
	//  window they were signing up in using localStorage events.
	//  However, if the user opens the verification email in a separate browser profile, nothing
	//  will happen in this window. We can improve this functionality by using websockets to automatically set the
	//  access + refresh tokens. This pales in comparison to the clinician workflow, where they can verify their
	//  email in a different browser profile and then continue working in the original window.
	const email =
		formik.values.email ||
		`${formik.values.emailPrefix}@${formik.values.domain}`;
	return <ConfirmEmailSection email={email} />;
};

export default VerificationStep;
