import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Divider, Typography, Stack } from '@mui/material';

import EmptySectionText from '@ivy/components/atoms/EmptySectionText';
import { ClinicianBoundary, withBoundary } from '@ivy/components/boundaries';
import DataLoader from '@ivy/components/molecules/DataLoader';
import PaperSection from '@ivy/components/molecules/PaperSection';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

import JobApplicationListItem from './JobApplicationListItem';

const JobApplicationList_JobApplicationsQDoc = gql(/* GraphQL */ `
	query JobApplicationList_JobApplications($accountId: uuid!) {
		jobApps: job_application(
			where: { batch: { candidate: { clinician_id: { _eq: $accountId } } } }
			order_by: { created_at: desc }
		) {
			id
			...JobApplicationListItem_JobApplication
		}
	}
`);

const JobApplicationList = () => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(
		JobApplicationList_JobApplicationsQDoc,
		{
			variables: {
				accountId: currAcc?.id,
			},
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		},
	);

	const jobApps = data?.jobApps || [];

	return (
		<NavTemplate
			pageTitle='Applications'
			pageNoIndex
			showSupportLauncher
			maxWidth='lg'
		>
			<DataLoader data={data} loading={loading} error={error} variant='logo'>
				{() => (
					<Box sx={{ my: 5 }}>
						<PaperSection
							title='Applications'
							actions={
								<Typography
									variant='body1'
									color='text.secondary'
									sx={{ display: !jobApps.length ? 'none' : undefined }}
								>
									{jobApps.length}{' '}
									{jobApps.length === 1 ? 'Application' : 'Applications'}
								</Typography>
							}
						>
							<EmptySectionText
								disabled={!!jobApps.length}
								text="You haven't applied to any EM practices yet."
								actionText={'Start Your Search'}
								actionLink={EmptySectionText.routes.ROOT}
							/>
							<Stack spacing={1}>
								{jobApps.map((jobApp, idx, self) => (
									<React.Fragment key={jobApp.id}>
										<JobApplicationListItem jobApp={jobApp} />
										{idx !== self.length - 1 && <Divider />}
									</React.Fragment>
								))}
							</Stack>
						</PaperSection>
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default withBoundary(JobApplicationList, ClinicianBoundary);
