import React from 'react';

import { SvgIcon } from '@mui/material';

const PinIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			{...props}
		>
			<path
				d='M9.80469 1.52832L8.86198 2.47103L9.59245 3.2015L5.45964 6.5166L4.47135 5.52832L3.52865 6.47103L6.0625 9.00488L2 13.0661V13.9997H2.93359L6.99479 9.93717L9.52865 12.471L10.4714 11.5283L9.54036 10.5973L12.8099 6.41895L13.5286 7.1377L14.4714 6.19499L9.80469 1.52832Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default PinIcon;
