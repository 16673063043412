import React from 'react';

import { Box, Typography, type SxProps, type Theme } from '@mui/material';

import EmptySectionText from '@ivy/components/atoms/EmptySectionText';
import Gallery from '@ivy/components/organisms/Gallery';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { combineSx } from '@ivy/lib/styling/sx';

import EditButton, { type EditButtonProps } from './EditButton';

const FacilityGallery_FacilityFDoc = gql(/* GraphQL */ `
	fragment FacilityGallery_Facility on facility {
		picture {
			id
			filename
			publicUrl: public_url
		}
	}
`);

export interface FacilityGalleryProps {
	facility: FragmentType<typeof FacilityGallery_FacilityFDoc>;
	onEditClick?: EditButtonProps['onClick'];
	sx?: SxProps<Theme>;
}

const FacilityGallery = ({
	facility: rawFacility,
	onEditClick,
	sx,
}: FacilityGalleryProps) => {
	const facility = getFragmentData(FacilityGallery_FacilityFDoc, rawFacility);
	const galleryPhoto = facility?.picture;

	return (galleryPhoto && !onEditClick) || onEditClick ? (
		<Box
			component='section'
			sx={combineSx(
				{
					maxWidth: { xs: 'none', md: '700px' },
				},
				sx,
			)}
		>
			<Box
				display='flex'
				alignItems='center'
				mb={{
					xs: 3,
					md: 5,
				}}
			>
				<Typography component='h2' variant='h5'>
					Gallery
				</Typography>
				{!!onEditClick && (
					<EditButton
						onClick={onEditClick}
						sx={{
							ml: 2,
						}}
					/>
				)}
			</Box>
			{galleryPhoto ? (
				<Gallery pictures={[galleryPhoto]} />
			) : onEditClick ? (
				<EmptySectionText
					text='No photos listed in your gallery.'
					actionText='Upload photo'
					onClickAction={onEditClick}
					sx={{ alignItems: 'flex-start' }}
				/>
			) : undefined}
		</Box>
	) : null;
};

export default FacilityGallery;
