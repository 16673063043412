import React, { useState, useCallback } from 'react';

import { Box, useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import {
	ClinicianOrUnauthBoundary,
	withBoundary,
} from '@ivy/components/boundaries';
import SalaryList, {
	FiltersProvider,
	useFilterContext,
} from '@ivy/components/organisms/SalaryList';
import SalaryReportPopup from '@ivy/components/organisms/SalaryReportPopup';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { type SalaryList_SearchSalaryReportsQuery } from '@ivy/gql/types/graphql';
import { useDesktopEnforcerPopup } from '@ivy/lib/hooks';

import Hero from './Hero';

const SalaryShow = () => {
	const { filtersCount } = useFilterContext();
	const { EnforcerPopup, popupProps } = useDesktopEnforcerPopup();

	const [searchParams, setSearchParams] = useSearchParams();
	const [searchTerm, setSearchTerm] = useState(() => {
		if (searchParams.has('search')) {
			return searchParams.get('search') || '';
		}
		return '';
	});

	const theme = useTheme();
	const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
	const [showSalaryReport, setShowSalaryReport] = useState(false);
	const [data, setData] = useState<SalaryList_SearchSalaryReportsQuery>();

	const handleChangeSearch = useCallback(
		(term) => {
			setSearchTerm(term);
			setSearchParams(term ? { search: term } : {});
		},
		[setSearchTerm, setSearchParams],
	);

	const handleOpenSalaryReport = useCallback(() => {
		setShowSalaryReport(true);
	}, [setShowSalaryReport]);

	const handleCloseSalaryReport = useCallback(() => {
		setShowSalaryReport(false);
	}, [setShowSalaryReport]);

	const filtersActive = Object.values(filtersCount).some((el) => !!el);
	const reportCount = data?.agg.aggregate?.count || 0;

	return (
		<NavTemplate
			fullscreen
			showFooter
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
				// position: 'static',
				// disableShrink: true,
			}}
			containerSx={{
				px: {
					gridBreak: 0,
					xs: 2,
				},
			}}
			pageTitle='Compare Emergency Medicine Salaries'
			pageDescription='Take a minute to submit your salary anonymously to get access to all the salaries we have in our database. Each submission encourages others to contribute which means more accurate data for everyone.'
			showSupportLauncher
			pathIsCanonical
		>
			<Hero
				searchTerm={searchTerm}
				onTextChange={handleChangeSearch}
				setFiltersPopupOpen={setFiltersPopupOpen}
				filtersActive={filtersActive}
				onClickPopup={handleOpenSalaryReport}
				count={reportCount}
				sx={{
					mb: {
						gridBreak: 0,
						xs: 3,
					},
					zIndex: 2,
				}}
			/>
			<Box
				sx={{
					mx: 'auto',
					position: 'relative',
					mb: 10,
				}}
			>
				<SalaryList
					searchTerm={searchTerm}
					filtersPopupOpen={filtersPopupOpen}
					setFiltersPopupOpen={setFiltersPopupOpen}
					onClickAddSalary={handleOpenSalaryReport}
					onCompletedQuery={setData}
					onTextChange={handleChangeSearch}
					sx={{
						'#salary-control': {
							backgroundColor: theme.palette.light4.main,
							position: 'sticky',
							top: '64px',
							zIndex: 2,
							'& .container': {
								px: 3,
							},
						},
					}}
					tableProps={{
						autoHeight: true,
						sx: {
							'& .MuiDataGrid-main': {
								overflow: 'unset',
								'& .MuiDataGrid-columnHeaders': {
									position: 'sticky',
									top: '175px',
									zIndex: 2,
									backgroundColor: theme.palette.light4.main,
									borderRadius: 0,
									px: 2,
									'& .MuiDataGrid-columnHeadersInner': {
										maxWidth: '1712px',
									},
								},
								'& .MuiDataGrid-virtualScroller': {
									marginTop: reportCount > 0 ? '0 !important' : undefined,
									'& .MuiDataGrid-row': {
										px: 2,
									},
								},
							},
							'& .MuiDataGrid-footerContainer': {
								px: 3,
							},
						},
					}}
				/>
				{showSalaryReport && (
					<SalaryReportPopup
						open
						onClose={handleCloseSalaryReport}
						refetchQueries={['SalaryList_SearchSalaryReports']}
					/>
				)}
			</Box>
			<EnforcerPopup {...popupProps} />
		</NavTemplate>
	);
};

export const SalaryShowWithProvider = () => {
	return (
		<FiltersProvider>
			<SalaryShow />
		</FiltersProvider>
	);
};

export default withBoundary(SalaryShowWithProvider, ClinicianOrUnauthBoundary);
