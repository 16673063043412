import React from 'react';

import { SvgIcon } from '@mui/material';

const FacebookLinkIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='21'
			height='15'
			viewBox='0 0 21 15'
			fill='none'
			{...props}
		>
			<path
				d='M11.2882 14.3749L7.22605 14.299C5.9108 14.2726 4.59228 14.3254 3.30282 14.0513C1.34126 13.6418 1.20229 11.6339 1.05688 9.94964C0.856519 7.5818 0.934085 5.17098 1.31219 2.82291C1.52565 1.50539 2.36568 0.719218 3.66476 0.633667C8.05011 0.323192 12.4646 0.359987 16.8403 0.504835C17.3024 0.518113 17.7677 0.590689 18.2234 0.6733C20.4727 1.07622 20.5275 3.35161 20.6733 5.26707C20.8187 7.20229 20.7573 9.14745 20.4794 11.0695C20.2564 12.6609 19.8298 13.9954 18.0294 14.1243C15.7737 14.2927 13.5697 14.4284 11.3076 14.3852C11.3077 14.3749 11.2947 14.3749 11.2882 14.3749ZM8.90006 10.346C10.6 9.34856 12.2674 8.36777 13.9576 7.37704C12.2545 6.37963 10.5902 5.39884 8.90006 4.40812V10.346Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FacebookLinkIcon;
