import type React from 'react';
import { createContext } from 'react';

import { type AuthPopupType } from './constants';

export interface AuthPopupProviderCallbacks {
	onSuccess?: () => void;
	disableCloseOnSuccess?: boolean;
	onFailure?: () => void;
	disableCloseOnFailure?: boolean;
}

export interface AuthPopupContextValue {
	disableCompletionPopup: boolean;
	authPopup: AuthPopupType | null;
	setAuthPopup: React.Dispatch<React.SetStateAction<AuthPopupType | null>>;
	openLoginPopup: (
		disableCompletionPopup?: boolean,
		cb?: AuthPopupProviderCallbacks | null,
	) => void;
	openSignupPopup: (
		disableCompletionPopup?: boolean,
		cb?: AuthPopupProviderCallbacks | null,
	) => void;
	openProfilePopup: (cb?: AuthPopupProviderCallbacks | null) => void;
	openSignupCompletePopup: (cb?: AuthPopupProviderCallbacks | null) => void;
	openConfirmEmailPopup: (cb?: AuthPopupProviderCallbacks | null) => void;
	closeAuthPopup: () => void;
}

export default createContext<AuthPopupContextValue>(undefined!);
