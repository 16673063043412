import React from 'react';

import { East } from '@mui/icons-material';
import { Box, Button, Stack, type SxProps, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
interface EmraProgramFormProps {
	toPath: string;
	sx?: SxProps;
}

const EmraProgramForm = ({ toPath, sx }: EmraProgramFormProps) => {
	return (
		<Box sx={sx}>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					background: 'linear-gradient(180deg, #98367C 0%, #008D98 150%)',
				}}
			/>
			<Stack
				alignItems='center'
				justifyContent='center'
				sx={{ py: { md: 5, xs: 20 } }}
				spacing={3}
			>
				<Typography
					variant='h3'
					component='p'
					color='primary.contrastText'
					textAlign='center'
				>
					Are You an EM Program Coordinator?
				</Typography>
				<Typography
					variant='body1'
					color='primary.contrastText'
					textAlign='center'
				>
					Submit or edit information about your training program on our form
					following the link below:
				</Typography>
				<Button
					component={RouteLink}
					variant='outlined'
					openInNewTab
					color='whiteButton'
					to={toPath}
					sx={{
						px: 7,
						py: 2,
						fontWeight: 'bold',
						lineHeight: '18px',
						letterSpacing: '1.95px',
						'&:hover': {
							backgroundColor: '#008CFF',
							borderColor: '#008CFF',
							color: '#fff',
						},
					}}
				>
					PROGRAM FORM
					<East sx={{ ml: 2 }} />
				</Button>
			</Stack>
		</Box>
	);
};

export default EmraProgramForm;
