import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeleteOutline } from '@mui/icons-material';
import { Box, Stack, FormHelperText, Grid } from '@mui/material';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import Autocomplete from '@ivy/components/molecules/Autocomplete';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { gql } from '@ivy/gql/types';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ProfileCompleteFormValues } from '../profileCompleteForm';

interface SpecialtyObj {
	id: string;
	name: string;
	primary: boolean;
}

interface SpecialtyValues {
	specialties: SpecialtyObj[];
}

interface SpecialtyFormProps {
	choices: string[];
	formik: FormikWithNamespace<SpecialtyObj>;
	isPrimary: boolean;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

const SpecialtyForm = withFormikNamespace<
	SpecialtyFormProps,
	SpecialtyObj[],
	SpecialtyObj
>(({ choices, formik, isPrimary, arrayHelpers, idx, hideRemove }) => {
	const { values, setFieldValue, setFieldTouched, isSubmitting } = formik;

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			alignItems='flex-end'
			flex={1}
		>
			<Autocomplete
				freeSolo
				blurOnSelect
				autoHighlight
				selectOnFocus
				options={choices}
				getOptionLabel={(option: string) => option}
				inputValue={values?.name || ''}
				TextFieldProps={{
					label: isPrimary ? 'Primary specialty' : 'Secondary specialty',
					placeholder: 'Search for specialty',
					...getErrorState(formik, 'name'),
				}}
				onInputChange={(_, newValue) => setFieldValue('name', newValue)}
				onBlur={() => setFieldTouched('name')}
				disabled={isSubmitting}
				fullWidth
				sx={{ maxWidth: { sm: '375px', xs: 'none' } }}
			/>
			{/* Add button wrapper to keep hover as a circle instead of a stretched oval */}
			<Box
				display={hideRemove ? 'none' : 'block'}
				sx={{
					width: { sm: 'auto', xs: '100%' },
					ml: { sm: 2, xs: 0 },
					mt: { sm: 0, xs: 1 },
				}}
			>
				<ControlButton
					startIcon={<DeleteOutline />}
					onClick={() => arrayHelpers.remove(idx)}
					disabled={isSubmitting}
				>
					Remove
				</ControlButton>
			</Box>
		</Stack>
	);
});

interface SpecialtyProps {
	formik: FormikWithNamespace<SpecialtyObj[]>;
	onNext: (event: React.SyntheticEvent) => void;
}

const Specialty_GetSpecialtiesQDoc = gql(/* GraphQL */ `
	query Specialty_GetSpecialties {
		specialties: medicare_specialties {
			name
		}
	}
`);

const MAX_NO_SPECIALTY = 5;

const validation = yup.object({
	specialties: yup
		.array()
		.of(
			yup
				.object({
					id: yup.string(),
					name: yup.string().max(200).required().label('Specialty name'),
					primary: yup.boolean().required(),
				})
				.required(),
		)
		.when('$isAPP', {
			is: (isAPP: boolean) => isAPP,
			then: (schema) => schema,
			otherwise: (schema) =>
				schema.min(1, 'At least one specialty is required'),
		})
		.max(MAX_NO_SPECIALTY, `At most ${MAX_NO_SPECIALTY} specialties`)
		.test('no-dups', 'No duplicates allowed', (value) => {
			// Yup is dumb and transforms empty strings '' that are required to undefined, so filter those out
			const filtered = value?.filter((el) => !!el.name) || [];
			return (
				new Set(filtered.map((el) => el?.name?.toLowerCase())).size ===
				filtered.length
			);
		})
		.required(),
});

const initialValue = {
	specialties: [
		{
			id: uuidv4(),
			name: 'Emergency Medicine',
			primary: true,
		},
	],
};

const Specialty = withFormikNamespace<
	SpecialtyProps,
	ProfileCompleteFormValues,
	SpecialtyObj[]
>(({ formik, onNext }) => {
	const { values, errors } = formik;

	const {
		data: specialtiesData,
		loading: specialtiesLoading,
		error: specialtiesError,
	} = useQuery(Specialty_GetSpecialtiesQDoc);

	const choices =
		useMemo(() => {
			return specialtiesData?.specialties
				.map((el) => el.name)
				.filter((el) => values.every((specialty) => specialty.name !== el));
		}, [specialtiesData, values]) || [];

	return (
		<Stack>
			<Box>
				<DataLoader
					data={specialtiesData}
					loading={specialtiesLoading}
					error={specialtiesError}
					variant='circular'
				>
					{() => (
						<Stack>
							<FieldArray
								name={formik.getFullName()}
								render={(arrayHelpers) => (
									<Grid container direction='column' spacing={1}>
										{values.map((clnSpecialty, idx) => (
											<Grid container item key={clnSpecialty.id}>
												<SpecialtyForm
													namespace={idx}
													formik={formik}
													arrayHelpers={arrayHelpers}
													idx={idx}
													choices={choices}
													isPrimary={idx === 0}
													hideRemove={values.length < 2}
												/>
											</Grid>
										))}
										<Grid
											item
											display='flex'
											justifyContent={{ sm: 'flex-start', xs: 'center' }}
										>
											<ModifyButton
												disabled={values.length >= MAX_NO_SPECIALTY}
												onClick={() =>
													arrayHelpers.push({
														id: uuidv4(),
														name: '',
														primary: false,
													})
												}
											>
												Add Specialty
											</ModifyButton>
										</Grid>
										<Grid item>
											{typeof errors === 'string' && (
												<FormHelperText error>{errors}</FormHelperText>
											)}
										</Grid>
									</Grid>
								)}
							/>
						</Stack>
					)}
				</DataLoader>
			</Box>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Stack>
	);
});

export default Specialty;
export { type SpecialtyValues, type SpecialtyProps, validation, initialValue };
