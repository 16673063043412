import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';

const AcepFooter = () => {
	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: 'light1.main',
				}}
			/>
			<Box py={18}>
				<Typography variant='body1' fontWeight='bold' mb={2}>
					© 2023 American College of Emergency Physicians & Ivy Clinicians
				</Typography>
				<Stack direction='row' spacing={5} mb={2}>
					<RouteLink
						to='https://www.acep.org/who-we-are/about-us/about-us/contact/privacy-policy-and-copyright-notice/#terms'
						openInNewTab
					>
						Terms of Use
					</RouteLink>
					<RouteLink
						to='https://www.acep.org/who-we-are/about-us/about-us/contact/privacy-policy-and-copyright-notice'
						openInNewTab
					>
						Privacy Policy
					</RouteLink>
				</Stack>
				<Typography variant='body2' fontStyle='italic' color='text.icon'>
					<b>Disclaimer:</b> ACEP Open Book is designed to provide emergency
					physicians with important information regarding potential employers.
					It is not intended to replace an individual’s personal research and
					dialogue with employers. The survey from which this information was
					obtained was created with the purpose of providing physicians with
					information regarding an employer’s policies. That data has been
					submitted by employer groups whose representatives have attested to
					the accuracy of their responses. It does not undergo independent
					verification by ACEP. The presence or absence of adherence to an ACEP
					policy does not indicate an employer’s stance on, support for, or
					opposition to the policy itself. The College recognizes that employers
					may have various reasons for their adherence status, including
					business considerations and operational limitations. ACEP does not
					discriminate against employers based on their responses to the survey.
					College policies and guidelines may evolve over time to align with
					changes in regulatory requirements, industry standards, and societal
					expectations. Employers should be aware that adherence requirements
					require revision in response to these developments.
				</Typography>
			</Box>
		</Box>
	);
};

export default AcepFooter;
