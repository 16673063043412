import React from 'react';

const ShieldIcon = (props) => {
	return (
		<svg
			width='95'
			height='116'
			viewBox='0 0 95 116'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M47.5 0.982361L0.625 21.8157V53.0657C0.625 81.9719 20.625 109.003 47.5 115.566C74.375 109.003 94.375 81.9719 94.375 53.0657V21.8157L47.5 0.982361ZM47.5 32.2324C54.7917 32.2324 62.0833 37.9615 62.0833 45.2532V53.0657C65.2083 53.0657 68.3333 56.1907 68.3333 59.8365V78.0657C68.3333 81.1907 65.2083 84.3157 61.5625 84.3157H32.9167C29.7917 84.3157 26.6667 81.1907 26.6667 77.5449V59.3157C26.6667 56.1907 29.7917 53.0657 32.9167 53.0657V45.2532C32.9167 37.9615 40.2083 32.2324 47.5 32.2324ZM47.5 38.4824C43.3333 38.4824 39.6875 41.0865 39.6875 45.2532V53.0657H55.3125V45.2532C55.3125 41.0865 51.6667 38.4824 47.5 38.4824Z'
				fill='#1B8ADE'
			/>
		</svg>
	);
};

export default ShieldIcon;
