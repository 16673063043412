import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { RunningToWorkIcon } from '@ivy/components/icons';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { SLUG2PROFESSION } from '@ivy/constants/clinician';
import {
	Accreditation,
	accreditationNames,
	SLUG2ACCREDITATION,
} from '@ivy/constants/facility';
import { RouteUri } from '@ivy/constants/routes';
import { oxfordJoin } from '@ivy/lib/formatting/string';
import {
	type LocationType,
	ZOOM_FACILITY,
	ZOOM_MAX_LOCALITY,
} from '@ivy/lib/services/maps';
import { buildInternalLink } from '@ivy/lib/util/route';

import FacilityMap from '../FacilityMap';

const DEFAULT_LOCATION = {
	id: 'place-ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
	type: 'place' as LocationType,
	label: 'United States',
	placeId: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
	center: {
		lat: 38.7945952,
		lng: -106.5348379,
	},
	viewport: {
		xMin: -173.2992296,
		xMax: -66.3193754,
		yMin: 15.7760139,
		yMax: 72.7087158,
	},
};

const AccreditationSearch = () => {
	const {
		profession: rawProfession,
		accreditation: rawAccreditation,
		slug,
		page: rawPage,
	} = useParams();
	const profession = SLUG2PROFESSION[rawProfession!];
	const showAll = rawAccreditation === 'all';
	const accreditations = showAll
		? Object.values(Accreditation)
		: [Accreditation[SLUG2ACCREDITATION[rawAccreditation!]]];

	const pathIsCanonicalRes = () => {
		if (rawPage === '1') {
			// Make sure the canonical is /emergency-medicine/physician/raleigh-nc and not /emergency-medicine/physician/raleigh-nc/1
			// for the first page
			return buildInternalLink(RouteUri.ACCREDITATION_SEARCH_INDEX, {
				accreditation: rawAccreditation!,
				profession: rawProfession!,
				slug: slug!,
			});
		}
		return true;
	};

	// redirect if profession is invalid
	if (!profession) {
		return <Navigate replace to={{ pathname: RouteUri.ROOT }} />;
	}

	return (
		<NavTemplate
			fullscreen
			TopBarProps={{
				sx: {
					borderStyle: 'none',
				},
			}}
			pathIsCanonical={pathIsCanonicalRes()}
			pageTitle={
				showAll
					? 'ACEP Accreditation Programs Map'
					: `ACEP ${oxfordJoin(
							accreditations.map((el) => accreditationNames[el]),
							'and',
					  )} Map`
			}
			pageDescription={
				showAll
					? `View a map of all ACEP accredited emergency departments.  Accreditations include the ${oxfordJoin(
							Object.values(accreditationNames),
							'and',
					  )}.`
					: `View a map of all ACEP ${oxfordJoin(
							accreditations.map((el) => accreditationNames[el]),
							'and',
					  )} emergency departments.`
			}
		>
			<FacilityMap
				baseRoute={buildInternalLink(RouteUri.ACCREDITATION_SEARCH_INDEX, {
					accreditation: rawAccreditation!,
				})}
				pageRoute={buildInternalLink(RouteUri.ACCREDITATION_SEARCH_PAGE, {
					accreditation: rawAccreditation!,
				})}
				maxViewportZoom={ZOOM_MAX_LOCALITY}
				maxZoom={ZOOM_FACILITY}
				clustering
				showAlts
				badgeIcon={<RunningToWorkIcon sx={{ fontSize: '1em' }} />}
				defaultLocation={DEFAULT_LOCATION}
				defaultFilters={{
					accreditations,
				}}
				// Don't restrict facility results to crawlers based on the `placeId` of `DEFAULT_LOCATION`
				disableCrawlerPlaceIdRestriction
			/>
		</NavTemplate>
	);
};

export default AccreditationSearch;
