import { createContext } from 'react';

import { type Status } from 'react-joyride';

import { type TourStructure } from '@ivy/components/providers/TourProvider/MainTour';
import { type Tours } from '@ivy/components/providers/TourProvider/Tours';

export interface TourState {
	run: boolean;
	currTour: TourStructure | null;
}

export interface TourContextValue {
	tourStatus: Status | null;
	startTour: (type: Tours) => void;
	stopTour: (complete: boolean) => void;
	signalTour: (signal: string) => void;
}

export default createContext<TourContextValue>(undefined!);
