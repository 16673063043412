import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const ScrollDownIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='65'
			height='26'
			viewBox='0 0 65 26'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M1 1L32.5 14L64 1'
				stroke='currentColor'
				strokeWidth={2}
				fill='none'
			/>
			<path
				d='M1 12L32.5 25L64 12'
				stroke='currentColor'
				strokeWidth={2}
				fill='none'
			/>
		</SvgIcon>
	);
};

export default ScrollDownIcon;
