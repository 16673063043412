import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';

import { createFragment } from '@ivy/gql/fragment';

const FaqAccordion = ({ faq, sx, labelComponent, ...props }) => {
	return (
		<Accordion
			variant='outlined'
			sx={[
				{
					bgcolor: 'light4.main',
				},
				sx,
			]}
			{...props}
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				sx={{
					minHeight: '70px',
				}}
			>
				<Typography
					variant='body1'
					component={labelComponent}
					sx={{ fontSize: '20px' }}
				>
					{faq.question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography
					component='div'
					sx={{ fontSize: '20px' }}
					dangerouslySetInnerHTML={{
						__html: faq.ans.html,
					}}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

FaqAccordion.fragments = {
	faq: createFragment(
		'FaqFragment',
		'Faq',
		`
        id
        category
        audience
        order
        question
        ans {
            html
        }
    `,
	),
};

export default FaqAccordion;
