import { useState, useEffect } from 'react';

const use100vh = () => {
	// Note we set minimum-scale to 1 so that window.innerHeight is correct on load
	// On Chrome inspector -> device view, the innerHeight changes upon load. If we didn't have the
	// minimum-scale, we would need to run the setMeasuredHeight function once in useEffect
	const [height, setHeight] = useState(() => window.innerHeight);

	useEffect(() => {
		const setMeasuredHeight = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener('resize', setMeasuredHeight);
		return () => window.removeEventListener('resize', setMeasuredHeight);
	}, [setHeight]);
	return height;
};

export default use100vh;
