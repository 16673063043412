import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

const HospitalIcon = ({ sx, ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='48'
			height='48'
			viewBox='0 0 48 48'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			sx={combineSx({ fill: 'none' }, sx)}
			{...props}
		>
			<path
				d='M24 8.5V13.5'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M21.5 11H26.5'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43.4992 18.8V15.5C43.4992 13.3 41.6992 11.5 39.4992 11.5H31.6992'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M35 44.5008H39.5C41.7 44.5008 43.5 42.7008 43.5 40.5008V25.3008'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 11.5H8.5C6.3 11.5 4.5 13.3 4.5 15.5V40.5C4.5 42.7 6.3 44.5 8.5 44.5H28.5V36.5C28.5 35.9 28.1 35.5 27.5 35.5H20.5C19.9 35.5 19.5 35.9 19.5 36.5V40'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M27.5 18.5H20.5C18.3 18.5 16.5 16.7 16.5 14.5V7.5C16.5 5.3 18.3 3.5 20.5 3.5H27.5C29.7 3.5 31.5 5.3 31.5 7.5V14.5C31.5 16.7 29.7 18.5 27.5 18.5Z'
				stroke='currentColor'
				strokeWidth='4'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M13 26H11C10.4 26 10 25.6 10 25V23C10 22.4 10.4 22 11 22H13C13.6 22 14 22.4 14 23V25C14 25.6 13.6 26 13 26Z'
				fill='currentColor'
			/>
			<path
				d='M21 26H19C18.4 26 18 25.6 18 25V23C18 22.4 18.4 22 19 22H21C21.6 22 22 22.4 22 23V25C22 25.6 21.6 26 21 26Z'
				fill='currentColor'
			/>
			<path
				d='M29 26H27C26.4 26 26 25.6 26 25V23C26 22.4 26.4 22 27 22H29C29.6 22 30 22.4 30 23V25C30 25.6 29.6 26 29 26Z'
				fill='currentColor'
			/>
			<path
				d='M37 26H35C34.4 26 34 25.6 34 25V23C34 22.4 34.4 22 35 22H37C37.6 22 38 22.4 38 23V25C38 25.6 37.6 26 37 26Z'
				fill='currentColor'
			/>
			<path
				d='M13 32H11C10.4 32 10 31.6 10 31V29C10 28.4 10.4 28 11 28H13C13.6 28 14 28.4 14 29V31C14 31.6 13.6 32 13 32Z'
				fill='currentColor'
			/>
			<path
				d='M21 32H19C18.4 32 18 31.6 18 31V29C18 28.4 18.4 28 19 28H21C21.6 28 22 28.4 22 29V31C22 31.6 21.6 32 21 32Z'
				fill='currentColor'
			/>
			<path
				d='M29 32H27C26.4 32 26 31.6 26 31V29C26 28.4 26.4 28 27 28H29C29.6 28 30 28.4 30 29V31C30 31.6 29.6 32 29 32Z'
				fill='currentColor'
			/>
			<path
				d='M37 32H35C34.4 32 34 31.6 34 31V29C34 28.4 34.4 28 35 28H37C37.6 28 38 28.4 38 29V31C38 31.6 37.6 32 37 32Z'
				fill='currentColor'
			/>
			<path
				d='M13 38H11C10.4 38 10 37.6 10 37V35C10 34.4 10.4 34 11 34H13C13.6 34 14 34.4 14 35V37C14 37.6 13.6 38 13 38Z'
				fill='currentColor'
			/>
			<path
				d='M37 38H35C34.4 38 34 37.6 34 37V35C34 34.4 34.4 34 35 34H37C37.6 34 38 34.4 38 35V37C38 37.6 37.6 38 37 38Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default HospitalIcon;
