import React from 'react';

import {
	Box,
	useTheme,
	type Theme,
	type SxProps,
	Typography,
	Stack,
	Button,
} from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

const compareImg =
	'https://assets.ivyclinicians.io/images/reviews_compare_salary.png';
const compareBubbles =
	'https://assets.ivyclinicians.io/images/reviews_compare_salary_snippet.png';

const CompareSalaryImages = ({ sx }: { sx?: SxProps<Theme> }) => {
	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					justifyContent: 'center',
					position: 'relative',
				},
				sx,
			)}
		>
			<Box
				component='img'
				src={compareImg}
				sx={{
					width: {
						xs: '100%',
						sm: '400px',
						md: '600px',
						xl: '655px',
					},
				}}
			/>
			<Box
				component='img'
				src={compareBubbles}
				sx={{
					display: { lg: 'block', xs: 'none' },
					position: 'absolute',
					width: {
						xs: '70%',
						sm: '400px',
						md: '600px',
						xl: '740px',
					},
					bottom: '25%',
				}}
			/>
		</Box>
	);
};

interface CompareSalaryProps {
	count?: number;
	onClick?: () => void;
	sx?: SxProps<Theme>;
}

const CompareSalary = ({ count, sx }: CompareSalaryProps) => {
	const currAcc = useCurrentAccount();
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;
	const theme = useTheme();

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: 'primary.translucent',
				}}
			/>
			<Stack
				sx={{
					maxWidth: '736px',
					margin: 'auto',
					py: { md: 17, xs: 10 },
					height: '100%',
				}}
				alignItems='center'
			>
				<Stack
					sx={{
						flexBasis: {
							md: '50%',
						},
						alignItems: 'center',
						justifyContent: 'center',
						margin: 'auto',
					}}
					spacing={4}
				>
					<Typography variant='h2' textAlign='center'>
						Are you being paid fairly?
					</Typography>
					<CompareSalaryImages
						sx={{ display: { lg: 'none', xs: 'flex' }, my: '-20px' }}
					/>
					<Typography variant='body1' textAlign='center' color='text.icon'>
						Compare Emergency Medicine Salaries on Ivy. To access{' '}
						{count
							? `${count} reported clinician ${
									count === 1 ? 'salary' : 'salaries'
							  }`
							: 'reported clinician salaries'}
						,{' '}
						<RouteLink
							to={RouteLink.routes.SALARY}
							sx={
								isClinicianOrAnon
									? {
											color: theme.palette.primary.main,
											textDecorationColor: 'rgba(38, 130, 197, 0.4)',
											cursor: 'pointer',
											fontWeight: 'bold',
											'&:hover': {
												textDecoration: 'underline',
											},
									  }
									: {}
							}
						>
							enter your current salary anonymously
						</RouteLink>{' '}
						(takes 1 min). Your info helps your peers get paid fairly.
					</Typography>
					{isClinicianOrAnon ? (
						<Button
							component={RouteLink}
							to={RouteLink.routes.SALARY}
							color='primary'
							variant='contained'
							sx={{
								fontSize: 'h6.fontSize',
								px: 5,
								py: 2,
							}}
						>
							Share your salary
						</Button>
					) : (
						<Typography variant='body1' textAlign='center' color='text.icon'>
							* This service is avabile to clinicians only
						</Typography>
					)}
				</Stack>
				<CompareSalaryImages
					sx={{ display: { lg: 'flex', xs: 'none' }, mt: 4 }}
				/>
			</Stack>
		</Box>
	);
};

export default CompareSalary;
