import React, {
	type FC,
	useCallback,
	useEffect,
	useState,
	useMemo,
} from 'react';

import {
	Button,
	Typography,
	useMediaQuery,
	type Theme,
	Box,
} from '@mui/material';

import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { isCrawler } from '@ivy/lib/util/userAgent';

export const LAPTOP_IMG =
	'https://assets.ivyclinicians.io/images/coffee-laptop-illustration.svg';
const SESSION_KEY = '_ivy_desktop_enforcer_confirmation';

interface PopupOptions {
	startOpen?: boolean;
}

interface FCPopupProps extends PopupProps {
	onConfirm: () => void;
}

const defaults: PopupOptions = {
	startOpen: false,
};

function useDesktopEnforcerPopup(opts: PopupOptions = {}) {
	const sessionState = useMemo(() => {
		const state = sessionStorage.getItem(SESSION_KEY);
		if (state) return JSON.parse(state);
		return false;
	}, []);

	const { startOpen } = { ...defaults, ...opts };
	const [isOpen, setIsOpen] = useState(startOpen || false);
	const [enacted, setEnacted] = useState(false);
	const [enforced, setEnforced] = useState(sessionState);
	const isGridBreak = useMediaQuery(
		(theme: Theme) => theme.breakpoints.down('gridBreak'),
		{ noSsr: true },
	);

	const openPopup = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const closePopup = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const handleClose = useCallback(() => {
		setEnacted(true);
		closePopup();
	}, [setEnacted, closePopup]);

	const handleConfirm = useCallback(() => {
		closePopup();
		setEnforced(true);
		sessionStorage.setItem(SESSION_KEY, 'true');
	}, [setEnforced, closePopup]);

	useEffect(() => {
		if (enforced || enacted) return;

		if (isGridBreak) {
			if (!isOpen) openPopup();
		} else {
			if (isOpen) closePopup();
		}
	}, [closePopup, isOpen, enforced, enacted, openPopup, isGridBreak]);

	const popupProps: FCPopupProps = {
		open: !isCrawler && isOpen,
		shrink: true,
		onClose: handleClose,
		onConfirm: handleConfirm,
	};

	return {
		isOpen,
		openPopup,
		closePopup,
		popupProps,
		EnforcerPopup,
	} as useEnforcerResults;
}

const EnforcerPopup: FC<FCPopupProps> = ({ onConfirm, ...popupProps }) => {
	return (
		<Popup sx={{ textAlign: 'center' }} {...popupProps}>
			<Typography variant='h4' sx={{ mb: 2 }}>
				🖥 Head to desktop for a better experience
			</Typography>
			<Typography variant='caption' sx={{ mb: 5 }}>
				This page contains a lot of data and is optimized for desktop.
			</Typography>
			<Box
				component='img'
				src={LAPTOP_IMG}
				sx={{
					height: '250px',
					mb: 5,
				}}
			/>
			<Button
				onClick={onConfirm}
				variant='contained'
				sx={{ py: 2, width: '100%' }}
			>
				Gotcha
			</Button>
		</Popup>
	);
};

interface useEnforcerResults {
	isOpen: boolean;
	openPopup: () => void;
	closePopup: () => void;
	popupProps: PopupProps;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	EnforcerPopup?: any;
}

export default useDesktopEnforcerPopup;
