import { formatRelative } from 'date-fns';
import enUS from 'date-fns/locale/en-US';

import { titlelize } from './string';

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const formatRelativeLocale = {
	lastWeek: "'Last' EEEE 'at' h:mm aa",
	yesterday: "'Yesterday' 'at' h:mm aa",
	today: "'Today' 'at' h:mm aa",
	tomorrow: "'Tomorrow' 'at' h:mm aa",
	nextWeek: "'Next' EEEE 'at' h:mm aa",
	other: "MMM d, yyyy 'at' h:mm aa",
};

const locale = {
	...enUS,
	formatRelative: (token) => formatRelativeLocale[token],
};

export const formatRelativeDatetime = (d: Date) => {
	return formatRelative(d, new Date(), {
		locale,
	});
};

// TODO: this is useless, just implement manually with day of week vs. 1/2/22
export const formatRelativeSuccint = (d: Date, onlyHistorical = false) => {
	// TODO: also shorten the year from 2022 to 22
	const relative = formatRelative(d, new Date());
	if (relative.includes('today at ')) {
		return relative.replace('today at ', '');
	}
	let dNoAt;
	const atIndex = relative.indexOf(' at ');
	if (atIndex > -1) {
		dNoAt = relative.substring(0, atIndex);
	} else {
		dNoAt = relative;
	}

	if (!onlyHistorical) {
		return titlelize(dNoAt);
	}
	return titlelize(dNoAt.replace('last ', ''));
};
