import React, { useCallback, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Button, FormHelperText } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import FacilityRecruiterForm, {
	type FacilityRecruiterFormValues,
} from '@ivy/components/forms/FacilityRecruiterForm';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { type EditRecruiterPopup_ContractFragment } from '@ivy/gql/types/graphql';

const EditRecruiterPopup_ContractFDoc = gql(/* GraphQL */ `
	fragment EditRecruiterPopup_Contract on facility_contract {
		id
		recruiter {
			id
			account {
				id
				pi: personal_info {
					id
					firstName: first_name
					lastName: last_name
					fullName: full_name
				}
			}
		}
		org {
			id
			defaultRecruiter: default_recruiter {
				id
				account {
					id
					pi: personal_info {
						id
						fullName: full_name
					}
				}
			}
		}
	}
`);

const EditRecruiterPopup_InviteNewRecruiterMDoc = gql(/* GraphQL */ `
	mutation EditRecruiterPopup_InviteNewRecruiter($input: InviteOrgUserInput!) {
		invite: invite_org_user(input: $input) {
			success
			accountId: account_id
		}
	}
`);

const EditRecruiterPopup_UpdateBatchRecruiterMDoc = gql(/* GraphQL */ `
	mutation EditRecruiterPopup_UpdateBatchRecruiter(
		$contractIds: [uuid!]!
		$recruiterId: uuid!
	) {
		contracts: update_facility_contract(
			where: { id: { _in: $contractIds } }
			_set: { recruiter_id: $recruiterId }
		) {
			returning {
				id
				recruiter {
					id
					account {
						id
						pi: personal_info {
							id
							firstName: first_name
							lastName: last_name
						}
					}
				}
			}
		}
	}
`);

const UPDATE_RECRUITER_SCHEMA = yup.object({
	addNewRecruiter: yup.boolean().required(),
	recruiter: yup
		.object({
			id: yup.string().required(),
		})
		.when('addNewRecruiter', {
			is: (val) => val,
			then: (schema) => schema.nullable().oneOf([null]),
			otherwise: (schema) =>
				schema.typeError('Recruiter is required').required(),
		})
		.label('Recruiter'),
	firstName: yup
		.string()
		.max(50)
		.when('addNewRecruiter', {
			is: (val) => val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema,
		})
		.label('First name'),
	lastName: yup
		.string()
		.max(50)
		.when('addNewRecruiter', {
			is: (val) => val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema,
		})
		.label('Last name'),
	email: yup
		.string()
		.email('Must be a valid email')
		.when('addNewRecruiter', {
			is: (val) => val,
			then: (schema) => schema.required(),
			otherwise: (schema) => schema,
		})
		.label('Email'),
});

interface EditRecruiterPopupProps extends Omit<PopupProps, 'onClose'> {
	contracts: FragmentType<typeof EditRecruiterPopup_ContractFDoc>[];
	refetchQueries?: string[];
	onClose?: (
		event?: React.SyntheticEvent,
		reason?: string,
		contractIds?: string[],
	) => void;
}

const EditRecruiterPopup = ({
	contracts: rawContracts,
	refetchQueries,
	onClose,
	...props
}: EditRecruiterPopupProps) => {
	const contracts = getFragmentData(
		EditRecruiterPopup_ContractFDoc,
		rawContracts,
	);
	const { enqueueSnackbar } = useSnackbar();
	const editingRecruitersHash = contracts.reduce(
		(tot, { recruiter }) => ({
			...tot,
			[recruiter.id]: recruiter,
		}),
		{},
	);
	const editingRecruiters: EditRecruiterPopup_ContractFragment['recruiter'][] =
		Object.values(editingRecruitersHash);
	// const [updateRecruiter, { error }] = useMutation(UPDATE_BATCH_RECRUITERS, {
	//     refetchQueries,
	//     awaitRefetchQueries: true,
	// });
	const client = useApolloClient();
	const [error, setError] = useState<boolean>(false);
	const formik = useFormik({
		initialValues: {
			addNewRecruiter: false,
			recruiter: editingRecruiters.length === 1 ? editingRecruiters[0] : null,
			firstName: '',
			lastName: '',
			email: '',
		} as FacilityRecruiterFormValues,
		validationSchema: UPDATE_RECRUITER_SCHEMA,
		onSubmit: async (values, actions) => {
			setError(false);
			let newRecruiterId;
			try {
				if (values.addNewRecruiter) {
					const resp = await client.mutate({
						mutation: EditRecruiterPopup_InviteNewRecruiterMDoc,
						variables: {
							input: {
								first_name: values.firstName,
								last_name: values.lastName,
								email: values.email,
							},
						},
						// Refetch so this user will appear in future queries
						refetchQueries: ['FacilityRecruiterForm_OrgUsers'],
						awaitRefetchQueries: true,
					});
					newRecruiterId = resp.data!.invite!.accountId;
					enqueueSnackbar(
						`${values.email} has been invited to join you on Ivy Clinicians.`,
						{
							variant: 'success',
						},
					);
				} else {
					newRecruiterId = values.recruiter?.id;
				}
				const resp = await client.mutate({
					mutation: EditRecruiterPopup_UpdateBatchRecruiterMDoc,
					variables: {
						contractIds: contracts.map((el) => el.id),
						recruiterId: newRecruiterId,
					},
					refetchQueries,
					awaitRefetchQueries: true,
				});
				actions.setSubmitting(false);
				enqueueSnackbar(
					`The recruiter for ${
						contracts.length > 1 ? 'these facilities' : 'this facility'
					} has been successfully updated.`,
					{
						variant: 'success',
					},
				);
				onClose?.(
					undefined,
					undefined,
					resp.data?.contracts?.returning.map((el) => el.id),
				);
			} catch (e) {
				setError(true);
				actions.setSubmitting(false);
				console.error(e);
				captureException(e, {
					extra: {
						contracts,
						values,
					},
				});
			}
		},
	});

	const handleClickSave = useCallback(() => {
		formik.handleSubmit();
	}, [formik]);

	const defaultRecruiterName = contracts
		.map((el) => el.org.defaultRecruiter?.account.pi?.fullName)
		.filter((el) => el != null)[0];

	return (
		<Popup
			title='Change Recruiter'
			onClose={onClose}
			actions={
				<>
					<Button
						variant='outlined'
						disabled={formik.isSubmitting}
						onClick={onClose}
						sx={{ mr: 'auto' }}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={handleClickSave}
					>
						Save
					</Button>
				</>
			}
			{...props}
		>
			<FacilityRecruiterForm
				formik={formik}
				plural={contracts.length > 1}
				defaultRecruiterName={defaultRecruiterName}
			/>
			<FormHelperText error sx={{ display: !error ? 'none' : undefined }}>
				{formik.values.addNewRecruiter
					? 'An error occurred, please make sure a user with the same email does not already exist and try again.'
					: 'An error occurred, please try again.'}
			</FormHelperText>
		</Popup>
	);
};

export default EditRecruiterPopup;
