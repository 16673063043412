import React from 'react';

import { SvgIcon } from '@mui/material';

const FeaturedIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='24'
			height='26'
			viewBox='0 0 24 26'
			fill='currentColor'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6.96875 14.1448L12.7094 12.3081L15.2807 5.8328L17.8521 12.3081L23.5927 14.1448L17.8521 16.7162L15.2807 22.4568L12.7093 16.7162L6.96875 14.1448Z'
				fill='currentColor'
			/>
			<path
				d='M0.0239258 5.8186L3.44741 4.72326L4.98088 0.861645L6.51437 4.72326L9.93784 5.8186L6.51437 7.35208L4.98088 10.7756L3.44741 7.35208L0.0239258 5.8186Z'
				fill='currentColor'
			/>
			<path
				d='M1.83936 21.9053L3.86452 21.2573L4.77165 18.973L5.67878 21.2573L7.70394 21.9053L5.67878 22.8124L4.77165 24.8376L3.86452 22.8124L1.83936 21.9053Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default FeaturedIcon;
