import React, { useMemo } from 'react';

import {
	Box,
	Button,
	Stack,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import {
	FaFacebook,
	FaInstagram,
	FaLinkedinIn,
	FaTwitter,
} from 'react-icons/fa';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useCrisp } from '@ivy/components/providers/CrispProvider';
import { IVY_LOGO_WHITE } from '@ivy/constants/brand';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { getFooterMenuItems } from '@ivy/lib/helpers/menu';
import { combineSx } from '@ivy/lib/styling/sx';

export interface FooterProps {
	sx?: SxProps<Theme>;
}

const Footer = ({ sx }: FooterProps) => {
	const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const crisp = useCrisp();
	const currAcc = useCurrentAccount();

	const footerMenuItems = useMemo(
		() => getFooterMenuItems({ crisp }, currAcc),
		[crisp, currAcc],
	);

	return (
		<Box
			component='footer'
			sx={combineSx(
				{
					// Full bleed
					width: '100vw',
					position: 'relative',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
				},
				sx,
			)}
		>
			<Box
				sx={{
					p: 5,
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
					backgroundImage: 'linear-gradient(180deg,#1fc173 0%,#0090b9 100%)',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
				}}
			>
				<Box
					component='img'
					src={IVY_LOGO_WHITE}
					sx={{
						height: '100px',
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: {
							xs: 'center',
							md: 'initial',
						},
					}}
				>
					<Stack
						direction={isSm ? 'column' : 'row'}
						flexWrap='wrap'
						justifyContent={{
							xs: 'center',
							md: 'flex-end',
						}}
						alignItems='center'
						spacing={isSm ? 0 : 1}
						mt={{
							xs: 2,
							md: 0,
						}}
					>
						{footerMenuItems.map((item, idx) => {
							return (
								<Button
									{...(item.route && {
										component: RouteLink,
										to: item.route,
										openInNewTab: item.openInNewTab,
									})}
									onClick={item.onClick}
									color='inherit'
									key={`${item.label}-${idx}`}
									sx={{
										color: 'primary.contrastText',
										whiteSpace: 'nowrap',
									}}
									size='large'
								>
									{item.shortLabel || item.label}
								</Button>
							);
						})}
					</Stack>
					<Stack
						direction='row'
						spacing={3}
						justifyContent='flex-end'
						pr={2}
						mt={{
							xs: 2,
							md: 1,
						}}
					>
						<Typography
							component={RouteLink}
							to='https://www.facebook.com/IvyClinicians'
							openInNewTab
							variant='h5'
							color='primary.contrastText'
						>
							<FaFacebook />
						</Typography>
						<Typography
							component={RouteLink}
							to='https://www.instagram.com/ivyclinicians/'
							openInNewTab
							variant='h5'
							color='primary.contrastText'
						>
							<FaInstagram />
						</Typography>
						<Typography
							component={RouteLink}
							to='https://www.linkedin.com/company/ivy-clinicians/'
							openInNewTab
							variant='h5'
							color='primary.contrastText'
						>
							<FaLinkedinIn />
						</Typography>
						<Typography
							component={RouteLink}
							to='https://twitter.com/IvyClinicians'
							openInNewTab
							variant='h5'
							color='primary.contrastText'
						>
							<FaTwitter />
						</Typography>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
};

export default Footer;
