import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import DataLoader from '@ivy/components/molecules/DataLoader';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { gql } from '@ivy/gql/types';

import Community from './Community';
import EndCta from './EndCta';
import FeaturedJobs from './FeaturedJobs';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import LearnMore from './LearnMore';
import WhyIvy from './WhyIvy';

const SECTION_Y_SPACING = {
	xs: 12,
	md: 20,
	lg: 24,
};

const ForClinicians_FacilityCountQDoc = gql(/* GraphQL */ `
	query ForClinicians_FacilityCount {
		fac: facility_aggregate(where: { contracts: { active: { _eq: true } } }) {
			aggregate {
				count
			}
		}
		org: organization_aggregate(
			where: { contracts: { active: { _eq: true } } }
		) {
			aggregate {
				count
			}
		}
		salary: search_relevant_salary_report_aggregate(args: { search: null }) {
			aggregate {
				count
			}
		}
	}
`);

const ForClinicians = () => {
	const theme = useTheme();
	const { data, loading, error } = useQuery(ForClinicians_FacilityCountQDoc);

	const [searchParams, setSearchParams] = useSearchParams();
	const { openLoginPopup } = useAuthPopup();

	// TODO: move to provider level across entire app
	useEffect(() => {
		if (!searchParams.has('showLogin')) {
			return;
		}
		openLoginPopup();
		searchParams.delete('showLogin');
		setSearchParams(searchParams, {
			replace: true,
		});
	}, [searchParams, setSearchParams, openLoginPopup]);

	return (
		<NavTemplate
			backgroundColor={theme.palette.light4.main}
			showFooter
			TopBarProps={{
				transparentUntilScroll: true,
				sx: {
					borderStyle: 'none',
				},
			}}
			pageTitle='Search EM Jobs'
			pageDescription='Find your next emergency medicine job in one simple search. Apply to positions at over 1,500 employers and 5,000 emergency departments on Ivy Clinicians.'
			showSupportLauncher
			pathIsCanonical
			hideBackNav
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<>
						<Hero facCount={data?.fac.aggregate?.count || 0} />
						<WhyIvy
							facCount={data?.fac.aggregate?.count || 0}
							orgCount={data?.org.aggregate?.count || 0}
							sx={{
								mt: Object.entries(SECTION_Y_SPACING).reduce(
									(curr, [k, v]) => ({
										...curr,
										// Weird padding issues in Hero section to adjust for
										// TODO: fix with Ziwa's curved edge bottom
										[k]: Math.max(v - 6.5, 0),
									}),
									{},
								),
							}}
						/>
						<FeaturedJobs
							sx={{
								mt: SECTION_Y_SPACING,
							}}
						/>
						<EndCta
							facCount={data?.fac.aggregate?.count || 0}
							orgCount={data?.org.aggregate?.count || 0}
							sx={{
								mt: SECTION_Y_SPACING,
							}}
						/>
						<HowItWorks
							sx={{
								mt: SECTION_Y_SPACING,
							}}
						/>
						<Community
							salaryCount={data?.salary.aggregate?.count || 0}
							sx={{
								mt: SECTION_Y_SPACING,
							}}
						/>
						<LearnMore
							sx={{
								mt: SECTION_Y_SPACING,
								mb: Object.entries(SECTION_Y_SPACING).reduce(
									(curr, [k, v]) => ({
										...curr,
										// Footer already has 40px margin and 24px padding, so subtract 64px
										[k]: Math.max(v - 8, 0),
									}),
									{},
								),
							}}
						/>
					</>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default ForClinicians;
