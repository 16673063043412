import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { DeleteOutline } from '@mui/icons-material';
import {
	Box,
	FormHelperText,
	TextField,
	Stack,
	Grid,
	Typography,
	Checkbox,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FieldArray, type FieldArrayRenderProps } from 'formik-othebi';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import {
	NavButton,
	ModifyButton,
	ControlButton,
} from '@ivy/components/atoms/FormButtons';
import withFormikNamespace, {
	type FormikWithNamespace,
} from '@ivy/components/forms/withFormikNamespace';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { FIELD_TYPE_DISPLAY } from '@ivy/constants/clinician';
import { gql } from '@ivy/gql/types';
import { bulletJoin } from '@ivy/lib/formatting/string';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

interface BoardCertsValues {
	boardCerts: BoardCertObj[];
}

interface BoardCertOption {
	id: string;
	field: string;
	fieldType: keyof typeof FIELD_TYPE_DISPLAY;
	board: {
		id: string;
		name: string;
	};
}

interface BoardCertObj {
	id: string;
	eligible: boolean;
	cert?: {
		id: string;
		field: string;
		fieldType: keyof typeof FIELD_TYPE_DISPLAY;
		board: {
			id: string;
			name: string;
		};
	} | null;
}

interface CertFormProps {
	choices?: BoardCertOption[];
	formik: FormikWithNamespace<BoardCertObj>;
	arrayHelpers: FieldArrayRenderProps;
	idx: number;
	hideRemove: boolean;
}

const CertForm = withFormikNamespace<
	CertFormProps,
	BoardCertObj[],
	BoardCertObj
>(({ formik, arrayHelpers, idx, choices = [], hideRemove }) => {
	const {
		values,
		setFieldValue,
		isSubmitting,
		touched,
		errors,
		setFieldTouched,
	} = formik;

	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			alignItems={{ sm: 'flex-start', xs: 'flex-end' }}
			flex={1}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				sx={{ flex: 1, width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
			>
				<Autocomplete
					// No free solo here, unlike APP Certs
					// Will get warning that XX is not an option, but that's because we filter it out to prevent dups below.
					blurOnSelect
					autoHighlight
					selectOnFocus
					renderInput={({ ...params }) => (
						<TextField
							{...params}
							label={'Board Certification'}
							placeholder='Search for board'
							helperText={touched.cert && errors.cert}
							error={touched.cert && !!errors.cert}
						/>
					)}
					options={choices}
					getOptionLabel={(option) => `${option.field} - ${option.board.name}`}
					renderOption={(params, option) => (
						<Box
							{...params}
							key={`board-cert-select${option.id}`}
							component='li'
						>
							<Box>
								<Typography variant='body1'>{option.field}</Typography>
								<Typography variant='body2' color='text.secondary'>
									{bulletJoin([
										FIELD_TYPE_DISPLAY[option.fieldType],
										option.board.name,
									])}
								</Typography>
							</Box>
						</Box>
					)}
					value={values.cert}
					onChange={(_, newVal) => setFieldValue('cert', newVal)}
					onBlur={() => setFieldTouched('cert')}
					disabled={isSubmitting}
					isOptionEqualToValue={(option, value) =>
						!!value && option.id === value.id
					}
					fullWidth
					sx={{ width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
				/>
				<Stack direction='row' alignItems='center' sx={{ mt: 0.5 }}>
					<Checkbox
						checked={values.eligible}
						onChange={(ev) => setFieldValue('eligible', ev.target.checked)}
						sx={{ p: 1, ml: -1 }}
					/>
					<Typography>I am board eligible</Typography>
				</Stack>
			</Stack>
			<Box
				display={hideRemove ? 'none' : 'block'}
				sx={{
					width: { sm: 'auto', xs: '100%' },
					ml: { sm: 2, xs: 0 },
					mt: { sm: 3, xs: 1 },
				}}
			>
				<ControlButton
					startIcon={<DeleteOutline />}
					onClick={() => arrayHelpers.remove(idx)}
					disabled={isSubmitting}
				>
					Remove
				</ControlButton>
			</Box>
		</Stack>
	);
});

interface BoardCertsProps
	extends Omit<
		SubFormStepProps<ProfileCompleteFormValues, ExtraParams>,
		'formik'
	> {
	formik: FormikWithNamespace<BoardCertObj[]>;
}

const BoardCerts_GetBoardCertificationsQDoc = gql(/* GraphQL */ `
	query BoardCerts_GetBoardCertifications {
		boardCerts: board_cert(order_by: { field: asc }) {
			id
			field
			fieldType: field_type
			board {
				id
				name
			}
		}
	}
`);

const MAX_NO_BOARD_CERTS = 10;

const validation = yup.object({
	boardCerts: yup
		.array()
		.of(
			yup
				.object({
					id: yup.string().when('$strict', {
						is: (val: boolean) => val,
						then: (schema) => schema.required(),
						otherwise: (schema) => schema.nullable(),
					}),
					eligible: yup.boolean().required(),
					cert: yup
						.object({
							id: yup.string().when('$strict', {
								is: (val: boolean) => val,
								then: (schema) => schema.required(),
								otherwise: (schema) => schema.nullable(),
							}),
						})
						.when('$strict', {
							is: (val: boolean) => val,
							then: (schema) => schema.required(),
							otherwise: (schema) => schema.nullable(),
						})
						.label('Certification'),
				})
				.required(),
		)
		.max(MAX_NO_BOARD_CERTS, `At most ${MAX_NO_BOARD_CERTS} certifications`)
		.test('no-dups', 'No duplicates allowed', (value) => {
			// Cert may be null, filter those out
			const filtered = value?.filter((el) => !!el.cert) || [];
			return (
				new Set(filtered.map((el) => el?.cert?.id)).size === filtered.length
			);
		})
		.required(),
});

const initialValue = {
	boardCerts: [
		{
			id: uuidv4(),
			eligible: false,
			cert: null,
		},
	],
};

const ClinicianBoardCertsForm = withFormikNamespace<
	BoardCertsProps,
	ProfileCompleteFormValues,
	BoardCertObj[]
>(({ formik, onNext }) => {
	const { values, errors } = formik;

	// Note we don't have a place to show errors.boardCerts if it is a string
	const { data, loading, error } = useQuery(
		BoardCerts_GetBoardCertificationsQDoc,
	);
	const choices = useMemo(() => {
		return data?.boardCerts.filter((el) =>
			values.every(
				(boardCert) => !boardCert.cert || boardCert.cert.id !== el.id,
			),
		);
	}, [data, values]);

	return (
		<Box>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				variant='circular'
			>
				{() => (
					<Stack>
						<FieldArray
							name={formik.getFullName()}
							render={(arrayHelpers) => (
								<Grid container direction='column' spacing={1}>
									{values.map((boardCertObj, idx) => (
										<Grid container item key={boardCertObj.id}>
											<CertForm
												choices={choices}
												formik={formik}
												arrayHelpers={arrayHelpers}
												namespace={idx}
												idx={idx}
												hideRemove={values.length < 2}
											/>
										</Grid>
									))}
									<Grid
										item
										display='flex'
										justifyContent={{ sm: 'flex-start', xs: 'center' }}
									>
										<ModifyButton
											onClick={() =>
												arrayHelpers.push({
													id: uuidv4(),
													eligible: false,
													cert: null,
												})
											}
										>
											Add Certification
										</ModifyButton>
									</Grid>
									<Grid item>
										{typeof errors === 'string' && (
											<FormHelperText error>{errors}</FormHelperText>
										)}
									</Grid>
								</Grid>
							)}
						/>
					</Stack>
				)}
			</DataLoader>
			<Box>
				<NavButton variant='text' onClick={onNext}>
					Next ⏎
				</NavButton>
			</Box>
		</Box>
	);
});

export default ClinicianBoardCertsForm;
export {
	type BoardCertsValues,
	type BoardCertsProps,
	validation,
	initialValue,
};
