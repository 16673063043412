import React, { useState, useRef, useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import {
	type Theme,
	ThemeProvider,
	linearProgressClasses,
	useMediaQuery,
	type SxProps,
	Box,
	Typography,
	Stack,
	Stepper,
	Step,
	StepLabel,
	LinearProgress,
	type LinearProgressProps,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
	type FormikHelpers,
	type FormikProps,
	type FormikState,
	type FormikConfig,
	Form,
	FormikProvider,
} from 'formik-othebi';
import { useSnackbar } from 'notistack';
import { type AnyObject } from 'yup/lib/types';

import {
	type FormStepObj,
	createFormSteps,
	extractInitialValues,
} from '@ivy/lib/forms/formFormatHelpers';
import { useForm } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import { bubbleTheme as bt } from '@ivy/theme/bubble';

import BasePage from './BasePage';
import FormButton from './FormButton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface StepFormatValues<T extends Record<string, any>, K = void> {
	keys: string[];
	values: FormStepObj<T, K>[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeaderProps<T extends Record<string, any>, K = void> {
	steps: StepFormatValues<T, K>;
	activeStep: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = <T extends Record<string, any>, K>({
	steps,
	activeStep,
}: HeaderProps<T, K>) => (
	<Stepper
		activeStep={activeStep}
		sx={{
			mb: 7,
			height: '24px',
			maxWidth: '470px',
			display: steps.values.length > 1 ? { sm: 'flex', xs: 'none' } : 'none',
			'& > :first-of-type': { pl: 0 },
		}}
	>
		{steps.values.map((item) => (
			<Step key={item.title}>
				<StepLabel>{item.title}</StepLabel>
			</Step>
		))}
	</Stepper>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ActionProps<T extends Record<string, any>, K = void> {
	onBack?: (event: React.SyntheticEvent) => void;
	steps: StepFormatValues<T, K>;
	formik: FormikProps<T>;
	activeStep: number;
	onClickLater?: (event: React.SyntheticEvent) => void;
	isMobile: boolean;
	sx?: SxProps<Theme>;
	proceedText?: string;
	prevStep?: boolean;
	strictDisable?: boolean;
	footerComp?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ActionComp = <T extends Record<string, any>, K>({
	activeStep,
	steps,
	formik,
	isMobile,
	onBack,
	onClickLater,
	sx,
	proceedText = 'Next',
	prevStep = false,
	strictDisable = true,
	footerComp,
}: ActionProps<T, K>) => {
	return (
		<Stack
			direction={{ sm: 'row', xs: 'column' }}
			sx={combineSx(
				{
					width: {
						xs: '100%',
						sm: 'auto',
					},
					justifyContent: 'flex-end',
				},
				sx,
			)}
		>
			{footerComp}
			{steps.values.length > 1 && isMobile && (
				<LinearProgressWithLabel
					value={activeStep}
					length={steps.keys.length}
					sx={{
						height: '8px',
						borderRadius: '32px',
						[`& .${linearProgressClasses.bar}`]: { borderRadius: '32px' },
					}}
				/>
			)}
			<FormButton
				variant='outlined'
				size='large'
				disabled={formik.isSubmitting}
				onClick={onBack}
				sx={{
					display: prevStep ? 'flex' : 'none',
					mt: 'auto',
				}}
			>
				Back
			</FormButton>
			{onClickLater && (
				<FormButton
					variant='outlined'
					size='large'
					disabled={formik.isSubmitting}
					onClick={onClickLater}
					sx={{
						mt: 'auto',
					}}
				>
					Do this later
				</FormButton>
			)}
			<FormButton
				variant='contained'
				size='large'
				disabled={
					(!formik.dirty && strictDisable && !formik.isValid) ||
					formik.isSubmitting
				}
				onClick={formik.submitForm}
				sx={{
					mt: 'auto',
				}}
			>
				{proceedText}
			</FormButton>
		</Stack>
	);
};

const LinearProgressWithLabel = ({
	value,
	length,
	...props
}: LinearProgressProps & { value: number; length: number }) => {
	const val = value + 1;
	const progress = val * (100 / length);
	const text = `${val}/${length}`;
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
			<Box sx={{ mr: 1 }}>
				<Typography variant='body2' color='text.secondary'>
					{text}
				</Typography>
			</Box>
			<Box sx={{ width: '100%' }}>
				<LinearProgress variant='determinate' value={progress} {...props} />
			</Box>
		</Box>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatSteps = <T extends Record<string, any>, K>(
	steps: {
		[key: string]: FormStepObj<T, K>;
	},
	extra?: K,
): StepFormatValues<T, K> => {
	const updatedSteps = Object.keys(steps)
		.filter((key) => {
			const obj = steps[key];
			if (obj && obj.shouldShow && extra) {
				return obj.shouldShow(extra);
			}
			return true;
		})
		.reduce((result: { [key: string]: FormStepObj<T, K> }, key) => {
			result[key] = steps[key];
			return result;
		}, {});

	return {
		keys: Object.keys(updatedSteps),
		values: Object.values(updatedSteps),
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface MasterPageProps<T extends Record<string, any>, K = void> {
	onSuccess?: () => void;
	steps: FormStepObj<T, K>[];
	defaultValues?: FormikConfig<T>['initialValues'];
	initialStep?: string;
	extra: K;
	refetchQueries?: string[];
	strictDisable?: boolean;
	onClickLater?: (event: React.SyntheticEvent) => void;
	formikContext?: AnyObject;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MasterPage = <T extends Record<string, any>, K>({
	onSuccess,
	initialStep = '',
	defaultValues,
	steps,
	extra,
	refetchQueries,
	strictDisable = true,
	onClickLater,
	formikContext,
}: MasterPageProps<T, K>) => {
	const formSteps = createFormSteps(steps);
	const extractedInitialValues = extractInitialValues(steps);
	const { enqueueSnackbar } = useSnackbar();
	const formRef = useRef<HTMLFormElement>(null);
	const client = useApolloClient();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md'),
	);

	const currSteps = formatSteps<T, K>(formSteps, extra);
	const foundIndex = currSteps.keys.indexOf(initialStep);
	const initialIndex = foundIndex !== -1 ? foundIndex : 0;
	const [currStepKey, setCurrStepKey] = useState(initialIndex);

	const defaultFormValues = useMemo(() => {
		return Object.assign(
			{},
			extractedInitialValues,
			Object.entries(defaultValues ?? {}).reduce(
				(curr, [k, v]) =>
					v !== undefined
						? {
								...curr,
								[k]: v,
						  }
						: curr,
				{},
			),
		);
	}, [defaultValues, extractedInitialValues]) as T;

	const stepObj = currSteps.values[currStepKey];
	const schema = stepObj.validate;

	const handleNext = (values: T) => {
		if (stepObj.closeStep?.(values, extra)) {
			onSuccess?.();
		} else if (stepObj.nextStep) {
			setCurrStepKey(currSteps.keys.indexOf(stepObj.nextStep(values, extra)));
		}
	};
	const handleBack = (
		resetForm: (nextState?: Partial<FormikState<T>> | undefined) => void,
		values: T,
	) => {
		if (stepObj.prevStep) {
			setCurrStepKey(currSteps.keys.indexOf(stepObj.prevStep(values, extra)));
			resetForm({ values });
		}
		window.scrollTo(0, 0);
	};
	const handleSuccess = (
		shouldResetForm: boolean,
		values: T,
		actions: FormikHelpers<T>,
		successText = '',
	) => {
		if (successText) enqueueSnackbar(successText, { variant: 'success' });
		handleNext(values);
		actions.setTouched({});
		actions.setSubmitting(false);
		if (shouldResetForm) {
			actions.resetForm?.({ values });
		}
		window.scrollTo(0, 0);
	};
	const handleError = (
		actions: FormikHelpers<T>,
		e: unknown,
		context?: { [key: string]: object },
		errorText = '',
	) => {
		if (errorText) enqueueSnackbar(errorText, { variant: 'error' });
		actions.setSubmitting(false);
		if (e instanceof Error) {
			console.error(e);
			if (!errorText)
				enqueueSnackbar(e.message, {
					variant: 'error',
				});
			captureException(e, context);
		}
	};

	const formik = useForm<T>({
		initialValues: defaultFormValues,
		onSubmit: async (values, actions) => {
			if (formRef?.current) formRef.current?.scrollIntoView();

			if (stepObj.submit) {
				await stepObj.submit(
					stepObj.validate || null,
					values,
					actions,
					client,
					(txt) => handleSuccess(true, values, actions, txt),
					(e, context, txt) => handleError(actions, e, context, txt),
					extra,
					refetchQueries,
				);
			} else {
				if (stepObj.intercept) {
					stepObj.intercept(values, actions, extra, () =>
						handleSuccess(false, values, actions),
					);
				} else {
					handleSuccess(false, values, actions);
				}
			}
		},
		context: {
			strict: false,
			...formikContext,
		},
		validationSchema: schema,
	});

	const proceedText = stepObj.proceedText?.(formik.values, extra);

	return (
		<ThemeProvider theme={bt}>
			<FormikProvider value={formik}>
				<BasePage
					actions={
						isMobile ? (
							<ActionComp
								formik={formik}
								activeStep={currStepKey}
								isMobile={isMobile}
								steps={currSteps}
								onBack={() => handleBack(formik.resetForm, formik.values)}
								prevStep={
									!!stepObj.prevStep && !!stepObj.prevStep(formik.values, extra)
								}
								proceedText={proceedText}
								strictDisable={strictDisable}
								onClickLater={onClickLater}
								footerComp={
									stepObj.footerComp ? stepObj.footerComp(extra) : undefined
								}
							/>
						) : undefined
					}
				>
					<Stack
						sx={{ height: '100%' }}
						direction={{ xs: 'column', md: 'row' }}
					>
						<Stack
							sx={{
								flex: 1,
								py: { xl: 10, sm: 5, xs: 3 },
								px: { xl: 15, sm: 5, xs: 3 },
								pb: { xl: 10, sm: 5, xs: 3 },
								maxWidth: '100%',
							}}
						>
							<Header activeStep={currStepKey} steps={currSteps} />
							<Box
								sx={{
									flex: 1,
									height: { sm: 'calc(100% - 104px)', xs: 'auto' },
								}}
							>
								<Box
									component={Form}
									sx={{
										height: '100%',
										display: 'flex',
										flexFlow: 'column nowrap',
									}}
									ref={formRef}
								>
									<Box
										sx={{
											flex: 1,
											overflowY: 'auto',
											mx: { xl: -15, sm: -5, xs: -3 },
											px: { xl: 15, sm: 5, xs: 3 },
											pb: 6,
											mb: 'auto',
										}}
									>
										<stepObj.component
											formik={formik}
											initialStep={initialStep}
											extra={extra}
										/>
									</Box>
									<ActionComp
										formik={formik}
										activeStep={currStepKey}
										steps={currSteps}
										isMobile={isMobile}
										onBack={() => handleBack(formik.resetForm, formik.values)}
										sx={{
											display: !isMobile ? 'flex' : 'none',
											mt: 5,
										}}
										prevStep={
											!!stepObj.prevStep &&
											!!stepObj.prevStep(formik.values, extra)
										}
										proceedText={proceedText}
										strictDisable={strictDisable}
										onClickLater={onClickLater}
										footerComp={
											stepObj.footerComp ? stepObj.footerComp(extra) : undefined
										}
									/>
								</Box>
							</Box>
						</Stack>
						{!!stepObj.sideContent && (
							<stepObj.sideContent formik={formik} extra={extra} />
						)}
					</Stack>
				</BasePage>
			</FormikProvider>
		</ThemeProvider>
	);
};

export default MasterPage;
export { type MasterPageProps };
