import React, { useState, useLayoutEffect, useCallback } from 'react';

import { East } from '@mui/icons-material';
import {
	Box,
	Button,
	type Theme,
	type SxProps,
	Typography,
	Stack,
} from '@mui/material';
import { useElementSize } from 'usehooks-ts';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { ScrollDownIcon } from '@ivy/components/icons';
import { combineSx } from '@ivy/lib/styling/sx';
import { buildInternalLink } from '@ivy/lib/util/route';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

const BACKGROUND_IMG =
	'https://assets.ivyclinicians.io/images/emra-physicians-hero-background.jpeg';

interface HeroProps {
	sx?: SxProps<Theme>;
}

interface ButtonLink {
	text: string;
	link: string;
}

const buttonArr: ButtonLink[] = [
	{
		text: 'RESIDENCY',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'residency',
			slug: 'search',
		}),
	},
	{
		text: 'CLERKSHIP',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'clerkship',
			slug: 'search',
		}),
	},
	{
		text: 'FELLOWSHIP',
		link: buildInternalLink(RouteLink.routes.EMRA_SEARCH_INDEX, {
			type: 'fellowship',
			slug: 'search',
		}),
	},
];

const Hero = ({ sx }: HeroProps) => {
	const [ref, { height: heroHeight }] = useElementSize();
	const [scrolling, setScrolling] = useState(false);
	const wl = useWhitelabel();

	useLayoutEffect(() => {
		const windowOffset = heroHeight - window.innerHeight;

		const handleScroll = () => {
			const offset = Math.max(windowOffset, 0);
			if (
				document.body.scrollTop > offset ||
				document.documentElement.scrollTop > offset
			) {
				setScrolling(true);
			} else {
				setScrolling(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [setScrolling, heroHeight]);

	const handleClickScrollDown = useCallback(() => {
		if (scrolling) {
			return;
		}
		window.scrollBy({
			top: heroHeight + 30,
			behavior: 'smooth',
		});
	}, [scrolling, heroHeight]);

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
					height: {
						xs: 'calc(100vh - 64px)',
						mobileMenu: 'calc(100vh - 104px)',
					},
					minHeight: { md: '720px', sm: '840px' },
				},
				sx,
			)}
			ref={ref}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					mx: '-50vw',
					height: '107%',
					zIndex: -1,
					top: '-104px',
					display: 'inline-block',
				}}
			>
				<svg
					width='0'
					height='0'
					xmlns='http://www.w3.org/2000/svg'
					style={{ position: 'absolute' }}
				>
					<defs>
						<clipPath
							id='EmraLanding_HeroWave'
							clipPathUnits='objectBoundingBox'
						>
							<path d='M0.0 0.0H1.0V0.9539C1.0 0.9539 0.7643 1.0577 0.4958 0.9539C0.2273 0.8501 0.0 0.9539 0.0 0.9539V0.0Z' />
						</clipPath>
					</defs>
				</svg>
				<Box
					sx={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						minHeight: '750px',
						position: 'relative',
						backgroundImage: `linear-gradient( to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)), url(${BACKGROUND_IMG})`,
						clipPath: 'url(#EmraLanding_HeroWave)',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
			</Box>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					alignItems: { md: 'normal', xs: 'flex-start' },
					justifyContent: { md: 'flex-start', xs: 'center' },
					height: '100%',
				}}
			>
				<Stack
					sx={{
						display: 'flex',
						flexflow: 'row nowrap',
						alignItems: 'center',
						pt: { md: 0, xs: 4 },
					}}
				>
					<Stack
						sx={{
							alignItems: { md: 'flex-start', xs: 'center' },
							justifyContent: 'center',
							flexBasis: { md: '60%' },
							flex: 1,
							mt: { xs: '3vh', sm: '15%', md: '-200px' },
						}}
					>
						<Box
							sx={{
								maxWidth: '780px',
								mb: { md: 10, xs: 4 },
							}}
						>
							<Typography
								component='h1'
								textAlign={{ xs: 'center', md: 'left' }}
								mb={4}
								fontWeight='medium'
								sx={{
									fontSize: { xs: '32px', sm: '46px', md: '64px' },
									lineHeight: { xs: '35.2px', sm: '1', md: 'normal' },
									letterSpacing: { md: '-1.28px', sm: '1', xs: ' -0.64px;' },
								}}
							>
								Stay on Top of Your Emergency Medicine Career
							</Typography>
							<Typography
								component='h2'
								textAlign={{ xs: 'center', md: 'left' }}
								sx={{
									fontSize: { xs: '14px', sm: '18px', md: '24px' },
									lineHeight: { xs: 'normal', sm: '32px' },
									letterSpacing: '-0.48px',
								}}
							>
								Our goal is to provide students with ready access to easily
								filterable information that will help them determine which
								programs will be the best fit for them.
							</Typography>
						</Box>
						<Typography
							variant='body1'
							fontWeight='bold'
							color='#999'
							sx={{
								fontSize: '16px',
								letterSpacing: '0.32px',
								mb: 2,
							}}
						>
							EXPLORE WHAT WE OFFER
						</Typography>
						<Stack
							spacing={{ md: 2, sm: 1.5, xs: 1 }}
							direction={{ md: 'row', xs: 'column' }}
							sx={{
								width: '100%',
							}}
						>
							{buttonArr.map((el) => (
								<Button
									key={el.text}
									component={RouteLink}
									variant='contained'
									to={el.link}
									sx={{
										flex: 1,
										px: 3,
										py: 2,
										fontWeight: 'bold',
										lineHeight: '18px',
										letterSpacing: '1.95px',
										'&:hover': {
											backgroundColor: '#008CFF',
											color: '#fff',
										},
									}}
									color='whiteButton'
								>
									{el.text} <East sx={{ ml: 2 }} />
								</Button>
							))}
						</Stack>
						<Box
							sx={{
								position: 'relative',
								width: '100%',
							}}
						>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								sx={{
									width: '100%',
									position: 'absolute',
									mt: { md: 10, xs: 4 },
									justifyContent: { md: 'flex-start', xs: 'center' },
									alignItems: 'center',
								}}
							>
								<Typography
									variant='body1'
									fontStyle='italic'
									color='text.icon'
									textAlign={{
										xs: 'center',
										md: 'left',
									}}
								>
									Powered by
								</Typography>
								<Stack spacing={1} direction='row'>
									<RouteLink to={'https://www.emra.org/'}>
										<Box
											component='img'
											alt='EMRA'
											src={
												'https://assets.ivyclinicians.io/images/emra-logo.png'
											}
											sx={{
												ml: 1,
												height: '1.5rem',
												position: 'relative',
												top: 'calc(0.2 * 1.5rem)',
											}}
										/>
									</RouteLink>
									<RouteLink to={wl.default.baseUrl}>
										<Box
											component='img'
											alt={wl.default.productName}
											src={wl.default.logo}
											sx={{
												ml: 1,
												height: '1.5rem',
												position: 'relative',
												top: 'calc(0.2 * 1.5rem)',
											}}
										/>
									</RouteLink>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</Stack>
				<ScrollDownIcon
					sx={{
						position: 'absolute',
						bottom: 16,
						left: '50%',
						transform: 'translateX(-50%)',
						width: '63px',
						height: 'auto',
						color: 'text.icon',
						shapeRendering: 'geometricPrecision',
						opacity: scrolling ? 0 : 0.5,
						transition: 'opacity 0.3s linear',
						...(!scrolling && {
							cursor: 'pointer',
							WebkitTapHighlightColor: 'transparent',
							userSelect: 'none',
							WebkitUserSelect: 'none',
						}),
						animation: 'fade-in 2.4s, move-up-down 1.25s linear infinite',
						'@keyframes fade-in': {
							'0%': {
								opacity: 0,
							},
							'50%': {
								opacity: 0,
							},
						},
						'@keyframes move-up-down': {
							'0%, 100%': {
								bottom: 16,
							},
							'50%': {
								bottom: 28,
							},
						},
					}}
					onClick={handleClickScrollDown}
				/>
			</Box>
		</Box>
	);
};

export default Hero;
