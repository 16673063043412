import React from 'react';

import { Navigate } from 'react-router-dom';

import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';

const OrgOrUnauthBoundary = ({ children }: { children?: React.ReactNode }) => {
	const currAcc = useCurrentAccount();
	if (!currAcc || currAcc.isOrgUser) return children;

	return <Navigate to={RouteUri.ROOT} replace />;
};

export default OrgOrUnauthBoundary;
