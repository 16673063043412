import { createOneToOneOptions } from '@ivy/lib/formatting/filter';

export const INTERVIEW_POLICY_OPTIONS = [
	'All rotators guaranteed return interview invitation',
	'All students interviewed during their rotation',
	'Your rotation is considered your interview',
	'No guaranteed interview for rotators',
	'Decline to answer',
];
export const INTERVIEW_POLICY_OPTS = createOneToOneOptions(
	INTERVIEW_POLICY_OPTIONS,
);

export const SLOE_OPTIONS = ['Group', 'Individual'];
export const SLOE_OPTS = createOneToOneOptions(SLOE_OPTIONS);

export const USMLE_COMLEX_SCORES_OPTIONS = [
	'Yes, USMLE Step 1 required with passing score',
	'Yes, USMLE Step 1 required, all scores accepted',
	'Yes, USMLE Step 1 or COMLEX Level 1',
	'No',
];
export const USMLE_COMLEX_SCORES_OPTS = createOneToOneOptions(
	USMLE_COMLEX_SCORES_OPTIONS,
);

export const SUBSPECIALTY_ROTATION_OPTIONS = [
	'Emergency Medicine',
	'Diversity Externship Scholarship',
	'EMS',
	'Global Health',
	'Pediatric EM',
	'Research',
	'Toxicology',
	'Ultrasound',
	'Wilderness',
	'We do not offer non-adult EM rotations',
];
export const SUBSPECIALTY_ROTATION_OPTS = createOneToOneOptions(
	SUBSPECIALTY_ROTATION_OPTIONS,
);

export const VEHICLE_REC_OPTIONS = ['Yes', 'No', 'Maybe'];
export const VEHICLE_REC_OPTS = createOneToOneOptions(VEHICLE_REC_OPTIONS);
