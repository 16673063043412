import React from 'react';

import {
	Delete,
	AccountBalance,
	CreditCard,
	KeyboardArrowLeft,
} from '@mui/icons-material';
import { Radio } from '@mui/material';
import {
	Box,
	Button,
	type SxProps,
	type Theme,
	Stack,
	Typography,
	Link,
	IconButton,
} from '@mui/material';
import { PaymentElement } from '@stripe/react-stripe-js';

import { Amex, MasterCard, Visa, Discover } from '@ivy/components/icons';
import { type BillingProvider_CustomerFragment } from '@ivy/gql/types/graphql';

const renderCCBrand = (brand = '') => {
	let brandLogo: React.ReactNode = undefined;
	switch (brand) {
		case 'visa':
			brandLogo = <Visa sx={{ height: '24px', width: '24px' }} />;
			break;
		case 'mastercard':
			brandLogo = <MasterCard sx={{ height: '24px', width: '24px' }} />;
			break;
		case 'amex':
			brandLogo = <Amex sx={{ height: '24px', width: '24px' }} />;
			break;
		case 'discover':
			brandLogo = <Discover sx={{ height: '24px', width: '24px' }} />;
			break;
		default:
			brandLogo = <CreditCard sx={{ height: '24px', width: '24px' }} />;
			break;
	}
	return brandLogo;
};

interface PaymentDisplayProps {
	data?: BillingProvider_CustomerFragment['paymentMethods'];
	editMode?: boolean;
	fixedEntry?: boolean;
	onChange?: (val: string) => void;
	onCancel?: () => void;
	onSubmit?: () => void;
	onDelete?: (val: string) => void;
	onEdit?: (val: boolean) => void;
	defaultPaymentId?: string;
	sx?: SxProps<Theme>;
	showFormButtons?: boolean;
	showDefault?: boolean;
	loading?: boolean;
}

const PaymentDisplay = ({
	data,
	editMode,
	fixedEntry,
	defaultPaymentId,
	onSubmit,
	onCancel,
	onDelete,
	onChange,
	onEdit,
	sx,
	showFormButtons,
	showDefault,
	loading,
}: PaymentDisplayProps) => {
	const parsedMethods = data || [];

	const handleEdit = (val: boolean) => {
		onEdit?.(val);
	};

	const handleChange = (val: string) => {
		onChange?.(val);
	};

	const handleDelete = (val: string) => {
		onDelete?.(val);
	};

	const handleSubmit = () => {
		onSubmit?.();
	};

	const handleCancel = () => {
		handleEdit(false);
		onCancel?.();
	};

	return (
		<Box sx={sx}>
			{editMode ? (
				<Stack spacing={3}>
					<Box display='flex'>
						<Link
							variant='caption'
							fontWeight='bold'
							underline='hover'
							onClick={handleCancel}
							sx={{
								display: fixedEntry ? 'none' : 'flex',
								alignItems: 'center',
								mr: 'auto',
								cursor: 'pointer',
								color: 'secondary.main',
							}}
						>
							<KeyboardArrowLeft />
							{' Return to payment methods'}
						</Link>
					</Box>
					<PaymentElement
						options={{
							fields: {
								billingDetails: {
									name: 'never',
									email: 'never',
								},
							},
						}}
					/>
					<Stack
						direction={{ xs: 'column', md: 'row' }}
						spacing={2}
						sx={{
							display: showFormButtons ? 'block' : 'none',
						}}
					>
						<Button
							variant='contained'
							onClick={handleSubmit}
							disabled={loading}
						>
							Add payment method
						</Button>
					</Stack>
				</Stack>
			) : (
				<Stack spacing={3}>
					{!parsedMethods || !parsedMethods.length ? (
						<Typography>No payment method added</Typography>
					) : (
						<Stack spacing={2}>
							{parsedMethods?.map((el) => (
								<Box key={el.id}>
									<Stack
										display='flex'
										direction='row'
										spacing={2}
										alignItems='center'
									>
										<Stack
											direction='row'
											spacing={0.6}
											sx={{ width: '150px' }}
										>
											<Radio
												checked={defaultPaymentId === el.id}
												onChange={() => handleChange(el.id)}
												value={el.id}
												sx={{ p: 0, display: onChange ? 'block' : 'none' }}
											/>
											{el.type === 'card' ? (
												<>
													{renderCCBrand(el.card?.brand)}
													<Typography variant='body2'>
														{`.... .... .... ${el.card?.last4}`}
													</Typography>
												</>
											) : (
												<>
													<AccountBalance
														sx={{ height: '24px', width: '24px' }}
													/>
													<Typography variant='body2'>
														{`.... .... .... ${el.usBank?.last4}`}
													</Typography>
												</>
											)}
										</Stack>
										{showDefault && defaultPaymentId === el.id && (
											<Typography variant='caption'>{`(default)`}</Typography>
										)}
										<IconButton
											sx={{
												display:
													onDelete && defaultPaymentId !== el.id
														? 'flex'
														: 'none',
												width: '32px',
												height: '32px',
												justifyContent: 'center',
												alignItems: 'center',
											}}
											onClick={() => handleDelete(el.id)}
										>
											<Delete
												sx={{
													fontSize: '14px',
												}}
											/>
										</IconButton>
									</Stack>
								</Box>
							))}
						</Stack>
					)}
					<Box display='flex'>
						<Link
							variant='caption'
							fontWeight='bold'
							underline='hover'
							onClick={() => handleEdit(true)}
							sx={{
								display: fixedEntry ? 'none' : 'flex',
								alignItems: 'center',
								mr: 'auto',
								cursor: 'pointer',
								color: 'secondary.main',
							}}
						>
							Add new payment method
						</Link>
					</Box>
				</Stack>
			)}
		</Box>
	);
};

export default PaymentDisplay;
