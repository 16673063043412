import React from 'react';

import {
	Box,
	CardContent,
	Card,
	Typography,
	type SxProps,
	type Theme,
} from '@mui/material';

const TESTIMONIAL_IMAGE =
	'https://assets.ivyclinicians.io/images/omar-nava.jpeg';

interface GraphicProps {
	sx?: SxProps<Theme>;
}

const ClinicianGraphic = ({ sx }: GraphicProps) => {
	return (
		<Box sx={sx}>
			<Card
				sx={{
					bgcolor: 'white',
					boxShadow: '0px 16px 40px rgba(142, 149, 155, 0.3)',
					borderRadius: '15.732px',
					maxWidth: '590px',
					mb: 'auto',
					ml: 17,
				}}
			>
				<CardContent
					sx={{
						display: 'flex',
						p: 5,
						justifyContent: 'center',
						flexDirection: 'column',
						'&:last-child': {
							pb: 5,
						},
					}}
				>
					<Box
						component='img'
						src={TESTIMONIAL_IMAGE}
						sx={{
							mb: 5,
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							borderRadius: '18.521px',
						}}
					/>
					<Typography
						variant='body1'
						fontStyle='italic'
						color='text.icon'
						sx={{ mb: 3 }}
					>
						"One quick search, a few filters, and I can see all the viable job
						options available in areas I like. Contacting the right person at
						each practice to get more info is simple. Once you try Ivy, you’ll
						never use any other job board again."
					</Typography>
					<Typography
						variant='h6'
						component='p'
						color='text.icon'
						sx={{ opacity: 0.5 }}
					>
						- Omar Nava, MPAS, CAQ, EMPA-C
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ClinicianGraphic;
