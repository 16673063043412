import React from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography, Stack, Rating, useTheme } from '@mui/material';
import { type FormikProps } from 'formik-othebi';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type ReviewFormValues } from '../reviewForm';

interface JobValues {
	jobRating: number;
}

interface JobProps {
	formik: FormikProps<ReviewFormValues>;
	onNext: (event: React.SyntheticEvent) => void;
}

const validation = yup.object({
	jobRating: yup
		.number()
		.typeError('Job rating is required')
		.required()
		.min(1, 'Please add a rating for this job')
		.max(5)
		.label('Job rating'),
});

const initialValue = {
	jobRating: 0,
};

const Job = ({ formik, onNext }: JobProps) => {
	const theme = useTheme();
	const { values, setFieldValue, setFieldTouched } = formik;

	const errorState = getErrorState(formik, 'jobRating');
	return (
		<Box>
			<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ width: '100%' }}>
				<Stack
					direction='column'
					sx={{ width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
				>
					<Rating
						value={values.jobRating}
						onChange={(event, newValue) => {
							setFieldValue('jobRating', newValue, false);
							setFieldTouched('jobRating');
						}}
						size='large'
					/>
					{!!errorState.error && (
						<Typography
							variant='caption'
							color='error.main'
							sx={{ mx: 1.5, mt: 0.5 }}
						>
							{errorState.helperText}
						</Typography>
					)}
					<Stack
						direction='row'
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							width: '100%',
							mt: 2,
							color: theme.palette.primary.main,
						}}
					>
						<InfoOutlined
							fontSize='small'
							sx={{ verticalAlign: 'middle', mr: 1 }}
						/>
						<Typography variant='caption' color={theme.palette.primary.main}>
							PS: We will ask you to individually rate your employer and
							facility on the next page
						</Typography>
					</Stack>
				</Stack>
				<Box
					sx={{
						ml: { sm: 3, xs: 0 },
					}}
				>
					<NavButton
						variant='text'
						onClick={onNext}
						sx={{ mt: { sm: 0, xs: 1 } }}
					>
						Next ⏎
					</NavButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default Job;
export { type JobValues, type JobProps, validation, initialValue };
