export const BULLET = '•';
export const SPACED_BULLET = ' • ';

export const titlelize = (value: string, skip: string[] = []) => {
	// eslint-disable-next-line no-useless-escape
	const prettyLabel = value.toLowerCase().split(/[\s\(\)]+/);
	for (let i = 0; i < prettyLabel.length; i++) {
		if (skip.includes(prettyLabel[i])) {
			continue;
		}
		prettyLabel[i] =
			prettyLabel[i].charAt(0).toUpperCase() + prettyLabel[i].substring(1);
	}
	return prettyLabel.join(' ');
};

export const bulletJoin = (value: (string | null | undefined)[]) => {
	return value.filter((el) => !!el).join(` ${BULLET} `);
};

export const sepJoin = (value: (string | null | undefined)[], sep: string) => {
	return value.filter((el) => !!el).join(sep);
};

export const capitalize = (value: string) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

const VOWELS = ['a', 'e', 'i', 'o', 'u'];

export const getArticle = (value: string) => {
	if (VOWELS.includes(value[0].toLowerCase())) {
		return 'an';
	}
	return 'a';
};

export const oxfordJoin = (parts: string[], conjunction = 'and') => {
	if (parts.length <= 1) {
		return parts.join('');
	}
	if (parts.length === 2) {
		return `${parts[0]} ${conjunction} ${parts[1]}`;
	}
	return `${parts.slice(0, parts.length - 1).join(', ')}, ${conjunction} ${
		parts[parts.length - 1]
	}`;
};
