import React from 'react';

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	type SxProps,
	type Theme,
	Typography,
} from '@mui/material';

export interface CheckboxListSelectProps<T> {
	label?: string;
	description?: React.ReactNode;
	row?: boolean;
	options: {
		label: string;
		value: T;
		disabling?: boolean;
	}[];
	value: T[];
	onChange: (ev: React.ChangeEvent<HTMLInputElement>, nv: T[]) => void;
	onBlur?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	error?: boolean;
	helperText?: string;
	disabled?: boolean;
	required?: boolean;
	sx?: SxProps<Theme>;
}

const CheckboxListSelect = <T,>({
	label,
	description,
	row,
	options,
	value,
	onChange,
	onBlur,
	error,
	helperText,
	disabled,
	required,
	sx,
}: CheckboxListSelectProps<T>) => {
	const handleChange =
		(key: T) => (ev: React.ChangeEvent<HTMLInputElement>) => {
			if (ev.target.checked) {
				const match = options.find((el) => el.value === key);
				if (!match) {
					return;
				}
				if (match.disabling) {
					onChange?.(ev, [key]);
				} else {
					onChange?.(ev, [...value, key]);
				}
			} else {
				onChange?.(
					ev,
					value.filter((el) => el !== key),
				);
			}
			onBlur?.(ev);
		};

	return (
		<FormControl component='fieldset' required={required} sx={sx}>
			{!!label && (
				<FormLabel
					component='legend'
					required={required}
					sx={{
						mb: 1,
					}}
				>
					<Typography component='span' variant='body1'>
						{label}
					</Typography>
				</FormLabel>
			)}
			{!!description && (
				<Typography
					variant='body1'
					sx={{
						mb: 1,
					}}
				>
					{description}
				</Typography>
			)}
			<FormGroup row={row}>
				{options.map(({ label: optionLabel, value: key }, idx, self) => (
					<FormControlLabel
						control={
							// Cast null/undefined to false to avoid uncontrolled -> controlled errors
							<Checkbox
								checked={value.includes(key)}
								onChange={handleChange(key)}
								disabled={
									disabled ||
									(value.some(
										(el) => options.find((opt) => opt.value === el)?.disabling,
									) &&
										!value.includes(key))
								}
							/>
						}
						label={optionLabel}
						key={key as React.Key}
						sx={{
							mb: idx !== self.length - 1 ? 1 : undefined,
						}}
					/>
				))}
			</FormGroup>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default CheckboxListSelect;
