import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import {
	Box,
	Divider,
	Paper,
	Stack,
	type SxProps,
	type Theme,
	Typography,
	useMediaQuery,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { useElementSize } from 'usehooks-ts';

import AdBox from '@ivy/components/molecules/AdBox';
import MapCard from '@ivy/components/organisms/MapCard';
import useBannerAdList from '@ivy/components/providers/AdProvider/useBannerAdList';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { type Profession } from '@ivy/constants/clinician';
import { useStringifiedMemo } from '@ivy/lib/hooks';
import { combineSx } from '@ivy/lib/styling/sx';
import { isCrawler } from '@ivy/lib/util/userAgent';

import { type MapListItemObject } from './common';
import EntityCheckbox from './EntityCheckbox';
import ListFooter from './ListFooter';
import MobileCheckbox from './MobileCheckbox';
import NearbyCarousel, { type NearbyMapListItemObject } from './NearbyCarousel';

export interface EntityResultsListProps<T, V> {
	entities: T[];
	nearbyEntities?: V[];
	numNearbyRows?: number;
	nearbyTitle?: string;
	onHover?: (entityId: string) => void;
	onLeave?: (entityId: string) => void;
	sx?: SxProps<Theme>;
	selected?: (T | V)[];
	onSelect?: (entity: T | V) => void;
	adSpacing?: number;
	region?: string;
	profession: Profession;
	showAlts?: boolean;
	slug: string;
	baseRoute: string;
	pageRoute: string;
	pageTitle?: string;
	pageSize: number;
	pageTitleComponent?: React.ElementType;
	selectable?: boolean;
	badgeIcon?: JSX.Element;
	badgeSxProps?: SxProps<Theme>;
	dataTSResolver?: (prof: Profession, id: string) => string;
	slotId?: string;
	highlightFeatured?: boolean;
}

// Bottom Padding (to avoid floater blocking pagination bar) + RenderAlts + Pagination
// 121 px to get elements above the floater, and 16 more padding for good measure
const bottomPadding = 121 + 16;

// We only need to use this on desktop because, on mobile, the whole list is hidden when you're moving the map
// around, so there isn't that much of a performance hit from re-rendering entire lists
// Without react-window, we get IMMENSE LAG
const EntityResultsList = <
	T extends MapListItemObject,
	V extends NearbyMapListItemObject,
>({
	entities,
	nearbyEntities,
	numNearbyRows = 1,
	nearbyTitle,
	onHover,
	onLeave,
	selected,
	onSelect,
	region,
	profession,
	showAlts,
	slug,
	adSpacing = 2,
	baseRoute,
	pageRoute,
	pageTitle,
	pageSize,
	pageTitleComponent,
	selectable,
	badgeIcon,
	badgeSxProps,
	dataTSResolver,
	slotId,
	highlightFeatured = false,
	sx,
}: EntityResultsListProps<T, V>) => {
	// Use full path - need to include search params
	const ref = useRef<VariableSizeList>(null);
	// capture initial size of title size then update when title changes
	const [titleRef, { height: titleSize }] = useElementSize();
	const [wrapperRef, { width: wrapperWidth }] = useElementSize();
	const { page: rawPage } = useParams();
	const page = rawPage && !isNaN(parseInt(rawPage)) ? parseInt(rawPage) : 1;
	const pageMax = Math.ceil(entities.length / pageSize);
	const showPagination = pageMax > 1;
	const entitiesPage = entities.slice((page - 1) * pageSize, page * pageSize);
	const isGteLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	const isLtSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const hasNearby = !!nearbyEntities?.length;
	// Find the index after which the "Nearby" section should appear
	const nearbyIndex = useMemo(() => {
		if (!hasNearby) {
			return -1;
		}
		// Find the index in the entire `entities` array that is the last position of a featured entity
		// Reverse modifies the array, so slice beforehand
		const reverseIdx = entities
			.slice()
			.reverse()
			.findIndex((el) => el.featured);
		const entitiesIdx =
			reverseIdx >= 0 ? entities.length - reverseIdx - 1 : reverseIdx;
		if (entitiesIdx === -1) {
			// No immediate needs at all, so the nearby carousel will go first
			return page === 1 ? 0 : -1;
		}
		if (entitiesIdx < (page - 1) * pageSize || entitiesIdx >= page * pageSize) {
			// If we are not on the right page, don'ts how the nearby carousel
			return -1;
		}
		// Put nearby carousel after the last featured item on the current page
		return (entitiesIdx % pageSize) + 1;
	}, [hasNearby, entities, page, pageSize]);
	// Number of items is # of entities + footer + nearby section
	const itemCount = entitiesPage.length + 1 + (nearbyIndex >= 0 ? 1 : 0);
	const redirect = useRedirect();

	const [nearbyPage, setNearbyPage] = useState(0);
	const [nearbyPageSize, setNearbyPageSize] = useState<number | null>(null);
	const handleChangeNearbyPage = useCallback(
		(newPage: number) => {
			setNearbyPage(newPage);
		},
		[setNearbyPage],
	);
	// Use layout effect to fit into this workflow: (1) width is initially 0, (2) ref is set, (3) effect runs to
	// calculate children width, (4) browser paints
	useLayoutEffect(() => {
		const optValue = numNearbyRows * Math.floor(wrapperWidth / 230);
		if (optValue === nearbyPageSize) {
			return;
		}
		if (!optValue) {
			setNearbyPageSize(null);
		} else {
			setNearbyPageSize(optValue);
		}
		setNearbyPage(0);
	}, [
		numNearbyRows,
		wrapperWidth,
		nearbyPageSize,
		setNearbyPageSize,
		setNearbyPage,
	]);
	useEffect(() => {
		// Any time the root page changes, go back to page 1
		setNearbyPage(0);
	}, [page, setNearbyPage]);
	const memoEntityIds = useStringifiedMemo(entities.map((el) => el.id));
	const memoNearbyIds = useStringifiedMemo(nearbyEntities?.map((el) => el.id));
	useEffect(() => {
		// Only reset the `nearbyPage` if the list of `nearbyEntities` or their order has changed
		// For example, if a user logs in after selecting a facility, we wouldn't want to reset the `nearbyPage`.
		setNearbyPage(0);
	}, [memoEntityIds, memoNearbyIds]);

	const { adList, refetch } = useBannerAdList(
		{
			type: 'banners',
			slotId: slotId || '',
			device: isGteLg ? 'desktop' : 'mobile',
			category: {
				disjunctions: [
					['SPECIALTY_EM', 'SPECIALTY_ALL'],
					[`PROFESSION_${profession}`, 'PROFESSION_ALL'],
					region ? [`LOCATION_${region}`, 'LOCATION_ALL'] : ['LOCATION_ALL'],
				],
			},
		},
		adSpacing,
		entitiesPage.length + (hasNearby ? 1 : 0),
		{
			// Lazy since this will be manually called in changePage below
			lazy: true,
			// Skip ads
			skip: !slotId,
		},
	);

	useEffect(() => {
		// Scroll back to the top whenever page or list of entities change
		if (ref.current) {
			// If the page size of the last page is different, need to update itemCount
			// before we scroll/reset
			ref.current.setState(
				(prev) => ({
					...prev,
					itemCount: itemCount,
				}),
				() => {
					ref.current!.scrollToItem(0, 'start');
					// Get rid of height cache when change page b/c last page could be smaller than previous pages
					// https://react-window.vercel.app/#/api/VariableSizeList
					// This is now implemented in the effect below that runs on `itemCount` changing and thus
					// `getItemHeight` changing.
					// ref.current!.resetAfterIndex(0);
				},
			);
		}
		// Fetch new ads
		refetch();
	}, [page, entities, itemCount, ref, refetch]);

	const getItemHeight = useCallback(
		(index: number) => {
			if (index === itemCount - 1) {
				return (
					bottomPadding +
					(slug !== 'search' ? 102 : 0) +
					(showPagination ? 32 : 0)
				);
			}

			let baseItemHeight: number;
			if (index === nearbyIndex) {
				if (nearbyPageSize !== null && nearbyEntities?.length !== undefined) {
					// If there is only a single page, then "shrink wrap" the results to the min number of rows needed
					const hasMultiplePages = nearbyEntities.length > nearbyPageSize;
					const numPageEntities =
						Math.min((nearbyPage + 1) * nearbyPageSize, nearbyEntities.length) -
						nearbyPage * nearbyPageSize;
					const numEntitiesPerRow = Math.ceil(nearbyPageSize / numNearbyRows);
					const numPageRows = Math.ceil(numPageEntities / numEntitiesPerRow);
					baseItemHeight =
						// Spacing
						8 +
						// Header
						20 +
						// Rows of `NearbyCard` with shrink wrap check
						216 * (hasMultiplePages ? numNearbyRows : numPageRows) +
						// Spacing between `NearbyCard` rows
						8 * ((hasMultiplePages ? numNearbyRows : numPageRows) - 1) +
						// Pagination
						48 +
						// Spacing
						8;
				} else {
					baseItemHeight = 0;
				}
			} else {
				// 220px card on desktop, 431 on sm
				baseItemHeight = isLtSm ? 431 : 220;
			}
			// const baseItemHeight = index === nearbyIndex ? nearbyPageSize != null && nearbyEntities?.length != null && nearbyEntities?.length > Math.ceil(nearbyPageSize / 2) ? 600 : 380 : isLtSm ? 431 : 220;
			return (
				baseItemHeight +
				(adList[index] ? 130 : 0) +
				(index === 0 ? titleSize : 0) +
				// +1 for divider if not NearbyCarousel (doesn't get one)
				(index !== nearbyIndex ? 1 : 0)
			);
		},
		[
			itemCount,
			slug,
			showPagination,
			nearbyIndex,
			nearbyPageSize,
			nearbyEntities?.length,
			nearbyPage,
			numNearbyRows,
			isLtSm,
			adList,
			titleSize,
		],
	);

	useEffect(() => {
		// Re-render the list with ads after they have been loaded in
		// Change item heights on breakpoints
		ref.current?.resetAfterIndex(0);
	}, [ref, getItemHeight]);

	// Opt: Add h2 section titles for Immediate Needs, Nearby, and Inquire
	const ListItem = ({
		index,
		style,
	}: {
		index: number;
		style?: React.CSSProperties;
	}) => {
		if (index === itemCount - 1) {
			return (
				<div style={style}>
					<ListFooter
						showAlts={showAlts}
						profession={profession}
						slug={slug}
						showPagination={showPagination}
						pageMax={pageMax}
						page={page}
						baseRoute={baseRoute}
						pageRoute={pageRoute}
						sx={{
							px: 2,
						}}
					/>
				</div>
			);
		}
		let entity: (typeof entitiesPage)[number] | null = null;
		let adIsFeatured = false;
		if (index !== nearbyIndex) {
			// Need to adjust index by 1 to get the right index into the `entitiesPage` since we inserted
			// the nearby carousel
			const entitiesIndex =
				index - (nearbyIndex >= 0 && index > nearbyIndex ? 1 : 0);
			entity = entitiesPage[entitiesIndex];
			if (
				entity.featured &&
				(index + 1 === nearbyIndex ||
					!entitiesPage[entitiesIndex + 1] ||
					entitiesPage[entitiesIndex + 1].featured)
			) {
				// The ad after the entity should have a bgcolor if it's after a featured entity and
				// either the next result is the nearby carousel (index + 1 === nearbyIndex), it's at the end of the
				// page (!nextEntity), or the next result is also featured (nextEntity.featured).
				adIsFeatured = true;
			}
		}
		const ad = adList[index];

		return (
			<div style={style}>
				<Box
					sx={{
						// This is because the titleSize is rounded, so a small white line may appear between the first
						// and second search result if they're both featured unless we push things to the top and bottom
						// of the div
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					{index === 0 && !!titleSize && (
						<Stack spacing={1} sx={{ p: 2 }}>
							<Typography variant='h5' component={pageTitleComponent ?? 'h1'}>
								{pageTitle}
							</Typography>
							<Typography variant='body1' fontWeight='bold' color='text.icon'>
								{entities.length} {entities.length === 1 ? 'result' : 'results'}
							</Typography>
						</Stack>
					)}
					{index === nearbyIndex &&
						!!nearbyEntities?.length &&
						!!nearbyPageSize && (
							<NearbyCarousel
								title={nearbyTitle}
								openInNewTab={!isLtSm}
								nearbyEntities={nearbyEntities}
								page={nearbyPage}
								pageSize={nearbyPageSize}
								numRows={numNearbyRows}
								onChangePage={handleChangeNearbyPage}
								selectable={selectable}
								onSelect={onSelect}
								selected={selected}
							/>
						)}
					{!!entity && (
						<Box
							sx={{
								bgcolor:
									entity.featured && highlightFeatured
										? 'light3.main'
										: undefined,
							}}
						>
							<Box position='relative'>
								<Box
									sx={{
										display: selectable ? undefined : 'none',
										// Still take up height and width but hide
										visibility: entity.disabled ? 'hidden' : undefined,
										position: 'absolute',
										top: 0,
										left: 0,
										zIndex: 1,
										// Add buffer room to prevent mouse clicks on entity card
										p: isLtSm ? 3 : 2,
									}}
								>
									<EntityCheckbox
										CheckboxComponent={isLtSm ? MobileCheckbox : undefined}
										entity={entity}
										WrapperComponent={isLtSm ? Paper : undefined}
										checked={!!selected?.some((el) => el.id === entity!.id)}
										onClick={
											!entity.disabled && onSelect
												? () => onSelect(entity!)
												: undefined
										}
									/>
								</Box>
								{/* TODO: don't open job posting link in new tab on mobile */}
								<MapCard
									selected={selected?.some((el) => el.id === entity!.id)}
									entity={entity}
									onMouseEnter={onHover ? () => onHover(entity!.id) : undefined}
									onMouseLeave={onLeave ? () => onLeave(entity!.id) : undefined}
									actionAreaSx={{
										pr: 2,
										pl: selectable && !isLtSm ? 7 : 2,
									}}
									sx={{
										height: isLtSm ? '431px' : '220px',
										opacity: entity.disabled ? 0.5 : undefined,
										bgcolor:
											entity.featured && highlightFeatured
												? 'light3.main'
												: undefined,
									}}
									data-ts-product={
										dataTSResolver
											? dataTSResolver(profession, entity.id)
											: undefined
									}
									data-tr-entity-id={entity.id}
									data-tr-featured={entity.featured}
									data-tr-nearby={false}
									openInNewTab={!isLtSm}
									onClick={() => {
										if (!entity!.pathname) {
											return;
										}
										redirect(entity!.pathname, {
											openInNewTab: !isLtSm,
											state: {
												backNav: {
													target: 'search',
												},
											},
										});
									}}
									badgeIcon={badgeIcon}
									badgeSxProps={badgeSxProps}
								/>
							</Box>
							<Divider sx={{ mx: 2 }} />
						</Box>
					)}
					{!!ad && (
						<Box
							sx={{
								bgcolor:
									adIsFeatured && highlightFeatured ? 'light3.main' : undefined,
								px: 2,
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: 129,
								}}
							>
								<AdBox winner={ad} />
							</Box>
							<Divider />
						</Box>
					)}
				</Box>
			</div>
		);
	};

	return (
		<Box
			ref={wrapperRef}
			sx={combineSx(
				{
					height: '100%',
					position: 'relative',
					pb: 2,
					overflow: 'auto',
				},
				sx,
			)}
		>
			<Stack
				spacing={1}
				sx={{
					p: 2,
					visibility: 'hidden',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					zIndex: -1,
				}}
				ref={titleRef}
			>
				<Typography variant='h5' component={pageTitleComponent ?? 'h1'}>
					{pageTitle}
				</Typography>
				<Typography variant='body1' fontWeight='bold' color='text.icon'>
					{entities.length} {entities.length === 1 ? 'result' : 'results'}
				</Typography>
			</Stack>
			<Box height='100%'>
				{!entities.length ? (
					<AutoSizer>
						{({ height, width }: { height: number; width: number }) => (
							<Box
								display='flex'
								height={height}
								width={width}
								overflow='auto'
								flexDirection='column'
							>
								<Typography
									variant='h5'
									component='h1'
									sx={{
										p: 2,
									}}
								>
									{pageTitle}
								</Typography>
								{hasNearby && !!nearbyEntities?.length && !!nearbyPageSize && (
									<Box flex={0}>
										<NearbyCarousel
											title={nearbyTitle}
											openInNewTab={!isLtSm}
											nearbyEntities={nearbyEntities}
											page={nearbyPage}
											pageSize={nearbyPageSize}
											numRows={numNearbyRows}
											onChangePage={handleChangeNearbyPage}
											selectable={selectable}
											onSelect={onSelect}
											selected={selected}
										/>
									</Box>
								)}
								<Box
									display='flex'
									alignItems='center'
									justifyContent='center'
									flex={1}
									sx={{
										// Padding for SelectionFloater
										pb: `${bottomPadding}px`,
									}}
								>
									<Typography
										variant='body2'
										align='center'
										color='text.secondary'
										sx={{
											px: 2,
											py: 6,
										}}
									>
										Zoom out or change your filters using the button above.
									</Typography>
								</Box>
							</Box>
						)}
					</AutoSizer>
				) : (
					<AutoSizer>
						{({ height, width }: { height: number; width: number }) => (
							<VariableSizeList
								ref={ref}
								height={
									isCrawler
										? Array.from({ length: itemCount }, (_, i) =>
												getItemHeight(i),
										  ).reduce((acc, val) => acc + val, 0)
										: height
								}
								width={width}
								itemSize={getItemHeight}
								itemCount={itemCount}
							>
								{ListItem}
							</VariableSizeList>
						)}
					</AutoSizer>
				)}
			</Box>
		</Box>
	);
};

export default React.memo(EntityResultsList, (prev, next) =>
	isEqual(prev, next),
) as typeof EntityResultsList;
