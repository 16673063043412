import { createContext } from 'react';

import { type ApolloError } from '@apollo/client';

import { type SiteSettingsProvider_SiteFragment } from '@ivy/gql/types/graphql';

export interface SiteSettingsValue<T> {
	toggleSiteSettings: (val: boolean) => void;
	currSiteSettings: T | null;
	loading: boolean;
	error?: ApolloError;
}

export default createContext<
	SiteSettingsValue<SiteSettingsProvider_SiteFragment>
>(undefined!);
