const appendLinkerParam = (url: string): Promise<string | null> => {
	return new Promise((resolve) => {
		// GA doesn't expose the method to generate the linker param anymore, so we trick it by creating a link
		const tempLink = document.createElement('a');
		tempLink.setAttribute('type', 'hidden');
		tempLink.setAttribute('href', url);
		document.body.appendChild(tempLink);

		// This is hacky but a bit necessary. setTimeout should make this run after all synchronous events
		// are completed (i.e. GTM's event handler has had a chance to change the href on mousedown).
		// We could use MutationObserver to listen for when the href changes, but the href may not change
		// if the GTM library fails to load (e.g. adblocker).
		const evListener = () => {
			setTimeout(() => {
				const hrefWithLinkerParam = tempLink.getAttribute('href');
				// Manually removeEventListener in case of old browser memory leaks
				tempLink.removeEventListener('mousedown', evListener);
				tempLink.remove();
				resolve(hrefWithLinkerParam);
			});
		};
		tempLink.addEventListener('mousedown', evListener);
		tempLink.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
	});
};

export default appendLinkerParam;
