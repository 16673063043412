import React from 'react';

import { type ApplyToJobExtra } from '@ivy/components/organisms/ApplyToJobPopup/applyToJobForm';
import { renameLabel, SubStep } from '@ivy/components/organisms/FormPopup';
import {
	formatForm,
	type FormStepProps,
	type SubStepObj,
} from '@ivy/lib/forms/formFormatHelpers';

import CvSubStep, {
	type CvSubStepValues,
	validation as cvValidation,
} from './CvSubStep';
import MessageSubStep, {
	type MessageSubStepValues,
	validation as messageValidation,
} from './MessageSubStep';

type MessageAndCvStepValues = MessageSubStepValues & CvSubStepValues;

const steps: SubStepObj<MessageAndCvStepValues, ApplyToJobExtra>[] = [
	{
		key: 'Message',
		label: () => renameLabel('Write your message', undefined, true),
		component: MessageSubStep,
		validate: messageValidation,
		initialValue: {
			message: '',
		},
	},
	{
		key: 'CV',
		label: (values) =>
			renameLabel('Attach your CV', values.cv?.filename, false),
		component: CvSubStep,
		validate: cvValidation,
		initialValue: {
			cv: null,
		},
	},
];

const { formSteps, initialValues, validation } = formatForm(steps);
const MessageAndCvStep = ({
	...props
}: FormStepProps<MessageAndCvStepValues, ApplyToJobExtra>) => (
	<SubStep {...props} subSteps={formSteps} />
);

export default MessageAndCvStep;
export { type MessageAndCvStepValues, validation, initialValues, formSteps };
