import { Crisp } from 'crisp-sdk-web';
import mixpanel from 'mixpanel-browser';

import { reloadPage } from '@ivy/lib/util/route';

export const ACCESS_TOKEN_IDENTIFIER = '_ivy_access_token';
export const REFRESH_TOKEN_IDENTIFIER = '_ivy_refresh_token';

const getToken = (identifier) => localStorage.getItem(identifier);
export const getAccessToken = () => getToken(ACCESS_TOKEN_IDENTIFIER);
export const getRefreshToken = () => getToken(REFRESH_TOKEN_IDENTIFIER);

const storeToken = (identifier: string, token: string) =>
	localStorage.setItem(identifier, token);
export const storeAccessToken = (token: string) =>
	storeToken(ACCESS_TOKEN_IDENTIFIER, token);
export const storeRefreshToken = (token: string) =>
	storeToken(REFRESH_TOKEN_IDENTIFIER, token);
export const storeTokens = (accessToken: string, refreshToken: string) => {
	// Convention is refresh before access
	storeRefreshToken(refreshToken);
	storeAccessToken(accessToken);
};

const removeToken = (identifier: string) => localStorage.removeItem(identifier);
export const removeAccessToken = () => removeToken(ACCESS_TOKEN_IDENTIFIER);
export const removeRefreshToken = () => removeToken(REFRESH_TOKEN_IDENTIFIER);

export const logOut = () => {
	try {
		// If Crisp is not loaded, this won't error
		Crisp.session.reset(false);
	} catch (e) {
		// Do nothing
	}
	try {
		mixpanel.reset();
	} catch (e) {
		// Do nothing
	}

	removeAccessToken();
	removeRefreshToken();

	return reloadPage();
};
