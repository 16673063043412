import React from 'react';

import { Tune } from '@mui/icons-material';
import { IconButton, type IconButtonProps } from '@mui/material';

import Badge from '@ivy/components/atoms/Badge';
import FacilitySearchInput, {
	type FacilitySearchInputProps,
} from '@ivy/components/organisms/FacilitySearchInput';

export interface SearchBarProps<HideProfession extends boolean>
	extends FacilitySearchInputProps<HideProfession> {
	onClickFilters: IconButtonProps['onClick'];
	filtersBadgeCount: number;
}

const SearchBar = <HideProfession extends boolean = false>({
	onClickFilters,
	filtersBadgeCount,
	...props
}: SearchBarProps<HideProfession>) => {
	return (
		<FacilitySearchInput
			mobileBp='md'
			mobileEndAdornment={
				<IconButton
					size='small'
					onClick={onClickFilters}
					sx={{
						color: 'text.icon',
						display: {
							md: 'none',
						},
						border: (theme) => `1px solid ${theme.palette.divider}`,
						...(filtersBadgeCount
							? {
									color: 'primary.main',
									bgcolor: 'primary.translucent',
									borderColor: 'primary.main',
							  }
							: {}),
					}}
				>
					<Badge color='primary' badgeContent={filtersBadgeCount}>
						<Tune />
					</Badge>
				</IconButton>
			}
			{...props}
		/>
	);
};

export default SearchBar;
