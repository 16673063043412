import React from 'react';

import { useQuery } from '@apollo/client';
import { Info } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';

import EmptySectionText from '@ivy/components/atoms/EmptySectionText';
import { ClinicianBoundary, withBoundary } from '@ivy/components/boundaries';
import DataLoader from '@ivy/components/molecules/DataLoader';
import PaperSection from '@ivy/components/molecules/PaperSection';
import ClinicianProfile from '@ivy/components/organisms/ClinicianProfile';
import { useAuthPopup } from '@ivy/components/providers/AuthPopupProvider';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const EditClinician_ClinicianQDoc = gql(/* GraphQL */ `
	query EditClinician_Clinician($id: uuid!) {
		clinician: clinician_by_pk(id: $id) {
			id
			...ClinicianProfile_Clinician
		}
	}
`);

const EditProfile = () => {
	const currAcc = useCurrentAccount();
	const { data, loading, error } = useQuery(EditClinician_ClinicianQDoc, {
		variables: {
			id: currAcc?.id,
		},
		// Fetch on network to reflect any updates from profile completion, etc...
		fetchPolicy: 'network-only',
	});

	return (
		<NavTemplate
			maxWidth='md'
			pageTitle='Edit Profile & CV'
			pageNoIndex
			pageDescription='Edit your clinician profile and upload your CV.'
			showSupportLauncher
		>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				keys={['clinician']}
				variant='logo'
			>
				{() => (
					<Box sx={{ mt: 2 }}>
						<Paper
							variant='outlined'
							sx={{
								bgcolor: 'primary.translucent',
								p: 1,
								mb: 2,
							}}
						>
							<Typography variant='body2'>
								<Info
									fontSize='small'
									sx={{
										color: 'text.icon',
										verticalAlign: 'middle',
										mr: 1,
									}}
								/>
								This is how employers you've applied to will see you.
							</Typography>
						</Paper>
						<ClinicianProfile clinician={data!.clinician!} isOwner={true} />
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

const ProfileIncomplete = () => {
	const { openProfilePopup } = useAuthPopup();

	return (
		<NavTemplate>
			<PaperSection title='Clinician Profile' sx={{ mt: 2 }}>
				<EmptySectionText
					text='Set up your clinician profile to get started.'
					actionText={'Create profile'}
					onClickAction={() => openProfilePopup()}
				/>
			</PaperSection>
		</NavTemplate>
	);
};

const EditClinician = () => {
	const currAcc = useCurrentAccount();

	if (!currAcc?.clinician?.profileComplete) {
		return <ProfileIncomplete />;
	}

	return <EditProfile />;
};

export default withBoundary(EditClinician, ClinicianBoundary);
