import { captureException } from '@sentry/react';
import * as yup from 'yup';

import FacilityDescriptionForm, {
	type FacilityDescriptionFormValues,
} from '@ivy/components/forms/FacilityDescriptionForm';
import FacilityGalleryForm, {
	type FacilityGalleryFormValues,
} from '@ivy/components/forms/FacilityGalleryForm';
import { gql } from '@ivy/gql/types';

const FacilityEditForms_UpdateDescriptionMDoc = gql(/* GraphQL */ `
	mutation FacilityEditForms_UpdateDescription(
		$facilityId: uuid!
		$description: String!
	) {
		update_facility_by_pk(
			pk_columns: { id: $facilityId }
			_set: { description: $description }
		) {
			id
		}
	}
`);

const FacilityGalleryForm_UpdateGalleryMDoc = gql(/* GraphQL */ `
	mutation FacilityGalleryForm_UpdateGallery(
		$facilityId: uuid!
		$pictureId: uuid!
	) {
		change_facility_picture(args: { fid: $facilityId, file_id: $pictureId }) {
			id
		}
	}
`);

export const forms = {
	gallery: {
		title: 'Edit Gallery',
		component: FacilityGalleryForm,
		getFormikProps: ({ picture }) => ({
			initialValues: {
				picture,
			} as FacilityGalleryFormValues,
			validationSchema: yup.object({
				picture: yup
					.object({
						id: yup.string().required(),
					})
					// In case of null values
					.typeError('Gallery photo is required')
					.required()
					.label('Gallery photo'),
			}),
		}),
		onSubmit:
			({ client, facility, onError, onSuccess }) =>
			async (values, actions) => {
				try {
					const response = await client.mutate({
						mutation: FacilityGalleryForm_UpdateGalleryMDoc,
						variables: {
							facilityId: facility.id,
							pictureId: values.picture ? values.picture.id : null,
						},
						refetchQueries: ['FacilityShow_Facility'],
						awaitRefetchQueries: true,
					});
					actions.setSubmitting(false);
					onSuccess(response);
				} catch (e) {
					actions.setSubmitting(false);
					onError(e);
					captureException(e, {
						extra: {
							values,
						},
					});
					return;
				}
			},
	},
	description: {
		title: 'Edit Description',
		component: FacilityDescriptionForm,
		getFormikProps: ({ description }) => ({
			initialValues: {
				description,
			} as FacilityDescriptionFormValues,
			validationSchema: yup.object({
				description: yup.string().max(1500).label('Description'),
			}),
		}),
		onSubmit:
			({ client, facility, onError, onSuccess }) =>
			async (values, actions) => {
				try {
					const response = await client.mutate({
						mutation: FacilityEditForms_UpdateDescriptionMDoc,
						variables: {
							facilityId: facility.id,
							description: values.description.trim(),
						},
						refetchQueries: ['FacilityShow_Facility'],
						awaitRefetchQueries: true,
					});
					actions.setSubmitting(false);
					onSuccess(response);
				} catch (e) {
					actions.setSubmitting(false);
					onError(e);
					captureException(e, {
						extra: {
							values,
						},
					});
					return;
				}
			},
	},
};
