import React from 'react';

import { Box, Button, FormHelperText } from '@mui/material';
import { Formik } from 'formik-othebi';

import Popup from '@ivy/components/molecules/Popup';

const EditSectionPopup = ({
	formikProps,
	onSubmit,
	open,
	formTitle,
	onClose,
	renderForm,
}) => {
	return (
		<Formik
			{...formikProps}
			onSubmit={onSubmit}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{(formik) => (
				<Popup
					open={open}
					title={formTitle}
					onClose={onClose}
					actions={
						<Box width='100%'>
							{/* Included because there could be lots of scrolling. Easy to miss an error and get confused why form is not subitting. */}
							<FormHelperText
								component='div'
								error
								sx={{
									display: formik.isValid && 'none',
									ml: 'auto',
									mr: 'auto',
									textAlign: 'center',
								}}
							>
								Please correct the errors above
							</FormHelperText>
							<Box display='flex' justifyContent='space-between'>
								<Button
									variant='outlined'
									color='primary'
									onClick={onClose}
									disabled={formik.isSubmitting}
								>
									Cancel
								</Button>
								<Button
									variant='contained'
									color='primary'
									onClick={formik.handleSubmit}
									disabled={formik.isSubmitting}
								>
									Save
								</Button>
							</Box>
						</Box>
					}
				>
					{renderForm(formik)}
				</Popup>
			)}
		</Formik>
	);
};

export default EditSectionPopup;
