import React from 'react';

import { gql, useMutation } from '@apollo/client';
import {
	Button,
	TextField,
	Typography,
	Stack,
	FormHelperText,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useFormik } from 'formik-othebi';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { CONFIRM_PASSWORD_SCHEMA } from '@ivy/lib/validation/password';

const validationSchema = CONFIRM_PASSWORD_SCHEMA;

const RESET_PASSWORD_MUTATION = gql`
	mutation resetPassword($token: String!, $password: String!) {
		reset: reset_password(token: $token, password: $password) {
			success
		}
	}
`;

const ResetPasswordForm = ({ token }) => {
	const [sendReset, { error }] = useMutation(RESET_PASSWORD_MUTATION);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const formik = useFormik({
		initialValues: {
			password: '',
			confirmPassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: async ({ password }, actions) => {
			try {
				await sendReset({
					variables: {
						token,
						password,
					},
				});
				enqueueSnackbar('You can now log in with your new password', {
					variant: 'success',
				});
				actions.setSubmitting(false);
				// Replace b/c token is now expired, so don't want to go back
				navigate(RouteLink.routes.ROOT, { replace: true });
			} catch (e) {
				actions.setSubmitting(false);
				console.error(e);
				// Token may pose security if unauthorized user gains access to Sentry
				captureException(e, {
					extra: {
						token,
					},
				});
			}
		},
	});

	return (
		<Stack
			alignItems='center'
			spacing={1}
			py={0.5}
			px={1}
			width='100%'
			maxWidth='350px'
		>
			<Typography variant='body1' align='center'>
				Enter your new password
			</Typography>
			<TextField
				fullWidth
				required
				name='password'
				label='Password'
				type='password'
				value={formik.values.password}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched.password && !!formik.errors.password}
				helperText={formik.touched.password && formik.errors.password}
			/>
			<TextField
				fullWidth
				required
				name='confirmPassword'
				label='Confirm Password'
				type='password'
				value={formik.values.confirmPassword}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched.confirmPassword && !!formik.errors.confirmPassword
				}
				helperText={
					formik.touched.confirmPassword && formik.errors.confirmPassword
				}
			/>
			<FormHelperText error sx={{ display: !error && 'none' }}>
				{error?.message}
			</FormHelperText>
			<Button
				variant='contained'
				disabled={formik.isSubmitting}
				onClick={formik.handleSubmit}
				fullWidth
				size='large'
				sx={{ maxWidth: '300px' }}
			>
				Change password
			</Button>
		</Stack>
	);
};

export default ResetPasswordForm;
