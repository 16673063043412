import React from 'react';

import { SvgIcon } from '@mui/material';

const BuildingIcon = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			{...props}
		>
			<path
				d='M4 3C2.90694 3 2 3.90694 2 5V21H16H22V9C22 7.90694 21.0931 7 20 7H16V5C16 3.90694 15.0931 3 14 3H4ZM4 5H14V19H4V5ZM6 7V9H8V7H6ZM10 7V9H12V7H10ZM16 9H20V19H16V17H18V15H16V13H18V11H16V9ZM6 11V13H8V11H6ZM10 11V13H12V11H10ZM6 15V17H8V15H6ZM10 15V17H12V15H10Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default BuildingIcon;
