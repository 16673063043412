import React from 'react';

import RouteLink from '@ivy/components/atoms/RouteLink';
import Map, { type MapWrapperProps } from '@ivy/components/organisms/Map';
import { type BaseMapItemObject } from '@ivy/components/organisms/Map/BaseMap';
import { clerkshipFilters } from '@ivy/components/organisms/Map/filters/filterData';
import { TrainingType } from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { getFragmentData, gql } from '@ivy/gql/types';
import {
	type Map_GetClerkshipQuery,
	type Map_GeoSearchClerkshipQuery,
} from '@ivy/gql/types/graphql';
import { buildInternalLink } from '@ivy/lib/util/route';

import { Map_TrainingSurveyFDoc } from './ResidencyMap';

// Clerkship
export const Map_GeoSearchClerkshipQDoc = gql(/* GraphQL */ `
	query Map_GeoSearchClerkship(
		$xMin: float8!
		$xMax: float8!
		$yMin: float8!
		$yMax: float8!
		$filters: clerkship_bool_exp!
	) {
		clerkship: search_clerkship_by_geography(
			args: { x_min: $xMin, x_max: $xMax, y_min: $yMin, y_max: $yMax }
			where: { clerkship: $filters }
			order_by: [
				{ clerkship: { training: { featured: desc } } }
				{ distance: asc }
			]
		) {
			id
			clerkship {
				id
				training {
					id
					slug
				}
				latestSurvey: latest_survey {
					id
					survey {
						id
						trainingSurvey: training_survey {
							id
							...Map_TrainingSurvey
						}
					}
				}
			}
		}
	}
`);
export const Map_ClerkshipQDoc = gql(/* GraphQL */ `
	query Map_GetClerkship($id: uuid!) {
		clerkship: clerkship_by_pk(id: $id) {
			id
			training {
				id
				slug
			}
			latestSurvey: latest_survey {
				id
				survey {
					id
					trainingSurvey: training_survey {
						id
						...Map_TrainingSurvey
					}
				}
			}
		}
	}
`);

const resolver = (
	rawData: Map_GeoSearchClerkshipQuery['clerkship'][0]['clerkship'],
): BaseMapItemObject => {
	const clerkship = getFragmentData(
		Map_TrainingSurveyFDoc,
		rawData!.latestSurvey!.survey!.trainingSurvey,
	);

	let newObject: BaseMapItemObject = {
		id: rawData!.id,
		disabled: false,
		location: null,
		picture: FACILITY_PLACEHOLDER_IMAGE,
		stats: [],
	};

	newObject.stats = [
		{
			label: 'Participates in VSLO:',
			value:
				typeof clerkship.clerkshipSurvey?.vsloParticipant === 'boolean'
					? clerkship.clerkshipSurvey.vsloParticipant
						? 'Yes'
						: 'No'
					: 'N/A',
		},
		{
			label: 'USMLE Step 1/COMLEX Required:',
			value: clerkship.clerkshipSurvey?.comlexRequired,
		},
		{
			label: 'Rotation Types:',
			value: clerkship.clerkshipSurvey?.rotationTypes.join(', '),
		},
	];

	if (clerkship.clerkshipSurvey?.residency?.id) {
		newObject.stats.push({
			label: 'Residency:',
			value: clerkship.clerkshipSurvey.residency.training.name,
			isLink: true,
			linkProps: {
				to: {
					pathname: buildInternalLink(RouteLink.routes.EMRA_RESIDENCY_SHOW, {
						trainingId: [
							clerkship.clerkshipSurvey?.residency?.id,
							clerkship.clerkshipSurvey?.residency?.training.slug,
						],
					}),
				},
				state: {
					backNav: {
						target: 'search',
					},
				},
				openInNewTab: true,
			},
		});
	}

	const clerkPathname = buildInternalLink(
		RouteLink.routes.EMRA_CLERKSHIP_SHOW,
		{
			trainingId: [rawData!.id, rawData!.training.slug],
		},
	);
	const clerkshipFeatured =
		clerkship.clerkshipSurvey?.clerkship?.training.featured;

	newObject = {
		...newObject,
		title: clerkship.clerkshipSurvey?.clerkship?.training.name,
		location: clerkship.location,
		locationInfo: `${clerkship.city}, ${clerkship.state}`,
		picture:
			(clerkship.trainingSites.length > 0 &&
				clerkship.trainingSites[0].facility?.picture?.publicUrl) ||
			FACILITY_PLACEHOLDER_IMAGE,
		pathname: clerkPathname,
		linkProps: {
			to: {
				pathname: clerkPathname,
			},
			state: {
				backNav: {
					target: 'search',
				},
			},
		},
		featured: clerkshipFeatured,
		badgeText: clerkshipFeatured ? 'Featured' : undefined,
	};
	return newObject;
};

const handleResolver = (data: Map_GeoSearchClerkshipQuery | undefined) =>
	data ? data.clerkship.map(({ clerkship }) => resolver(clerkship)) : undefined;

const handlePreviewResolver = (data: Map_GetClerkshipQuery | undefined) =>
	data ? resolver(data?.clerkship) : undefined;

type ClerkshipMapProps = Omit<
	MapWrapperProps<Map_GeoSearchClerkshipQuery, Map_GetClerkshipQuery>,
	| 'entityType'
	| 'queryDoc'
	| 'queryPreviewDoc'
	| 'filters'
	| 'dataResolver'
	| 'dataNearbyResolver'
	| 'dataPreviewResolver'
	| 'resolver'
	| 'nearbyResolver'
	| 'previewResolver'
>;

const ClerkshipMap = ({ ...props }: ClerkshipMapProps) => {
	return (
		<Map
			entityType={TrainingType.CLERKSHIP}
			queryDoc={Map_GeoSearchClerkshipQDoc}
			queryPreviewDoc={Map_ClerkshipQDoc}
			filters={clerkshipFilters}
			resolver={handleResolver}
			previewResolver={handlePreviewResolver}
			{...props}
		/>
	);
};

export default ClerkshipMap;
