import React from 'react';

import { Box, Slider, type SliderProps } from '@mui/material';

interface optionObject {
	label: string;
	value: string;
}

export interface SliderFilterOptionProps
	extends Omit<SliderProps, 'onChange' | 'value'> {
	options: optionObject[];
	value: string[];
	onChange: (ev: React.SyntheticEvent, nv: string[]) => void;
}

const SliderFilterOption = ({
	value,
	onChange,
	options,
}: SliderFilterOptionProps) => {
	const handleChange = (ev: Event, nv: number | number[]) => {
		const event: Event = ev;
		const unknownEvent: unknown = event;
		const newVal = Array.isArray(nv) ? nv.map((val) => `${val}`) : [`${nv}`];

		onChange(unknownEvent as React.SyntheticEvent, newVal);
	};

	const createOptions = (opts: optionObject[]) => {
		return opts.map((option) => ({
			label: option.label,
			value: Number.parseInt(option.value),
		}));
	};

	const defaultValue = (arr: string[]) => {
		if (!arr) {
			return [
				Number.parseInt(options[0].value),
				Number.parseInt(options[options.length - 1].value),
			];
		}
		return arr.map((val) => Number.parseInt(val));
	};

	return (
		<Box display='flex' sx={{ px: 1 }}>
			<Slider
				value={defaultValue(value)}
				onChange={handleChange}
				marks={createOptions(options)}
				step={null}
				min={Number.parseInt(options[0].value)}
				max={Number.parseInt(options[options.length - 1].value)}
			/>
		</Box>
	);
};

export default SliderFilterOption;
