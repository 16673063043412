import { type ApolloError } from '@apollo/client';

import { type Role } from '@ivy/constants/account';

export const createRoleHeaders = (role: Role) => ({
	'x-hasura-role': role,
});

export const createRoleContext = (role: Role) => ({
	headers: createRoleHeaders(role),
});

export const isDataException = (error: ApolloError) => {
	return error.graphQLErrors.some(
		(el) => el.extensions?.code === 'data-exception',
	);
};
