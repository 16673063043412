import React from 'react';

import { LinearProgress, CircularProgress } from '@mui/material';

import Screen from '@ivy/components/atoms/Screen';
import LoadingLogo from '@ivy/components/molecules/LoadingLogo';

export interface LoadingScreenProps {
	variant?: 'logo' | 'circular' | 'linear';
	fullscreen?: boolean;
	transparent?: boolean;
}

const LoadingScreen = ({
	variant,
	fullscreen = false,
	transparent = false,
}: LoadingScreenProps) => {
	if (variant === 'logo') {
		return (
			<Screen fullscreen={fullscreen} transparent={transparent}>
				<LoadingLogo />
			</Screen>
		);
	}
	if (variant === 'circular') {
		return (
			<Screen fullscreen={fullscreen} transparent={transparent}>
				<CircularProgress color='primary' />
			</Screen>
		);
	}
	return (
		<Screen fullscreen={fullscreen} transparent={transparent}>
			<LinearProgress color='primary' />
		</Screen>
	);
};

export default LoadingScreen;
