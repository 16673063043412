import { createOneToOneOptions } from '@ivy/lib/formatting/filter';

export const SUBSPECIALTY_OPTIONS = [
	'Addiction Medicine',
	'Adminstration/Quality/Patient Safety',
	'Aerospace Medicine',
	'Cardiovascular Emergency Medicine',
	'Critical Care - Anesthesia',
	'Critical Care - Medicine',
	'Critical Care - Neuro',
	'Critical Care - Resuscitation',
	'Critical Care - Surgery',
	'Disaster Medicine',
	'Education',
	'Emergency Medical Services',
	'Forensic Emergency Medicine',
	'Geriatrics Emergency Medicine',
	'Global Health',
	'Health Policy',
	'Hyperbaric & Undersea Medicine',
	'Informatics',
	'Injury Control',
	'Neurovascular & Stroke Medicine',
	'Observation Medicine',
	'Occupations & Environmental Medicine',
	'Pain Management',
	'Palliative Care',
	'Pediatric Emergency Medicine',
	'Population Health & Social Emergency Medicine',
	'Research',
	'Simulation',
	'Social Medicine',
	'Sports Medicine',
	'Tactical Medicine',
	'Telemedicine',
	'Toxicology',
	'Ultrasound',
	'Wilderness',
	"Women's Health",
];
export const SUBSPECIALTY_OPTS = createOneToOneOptions(SUBSPECIALTY_OPTIONS);

export const ADVANCED_DEGREE_OPTIONS = [
	'MPH',
	'MPA',
	'MPP',
	'MHA',
	'MSHA',
	'MBA',
	'MS',
	'Other',
];
export const ADVANCED_DEGREE_OPTS = createOneToOneOptions(
	ADVANCED_DEGREE_OPTIONS,
);

export const MOONLIGHTING_OPTIONS = [
	'Yes, external moonlighting allowed',
	'Yes, internal only',
	'No',
];
export const MOONLIGHTING_OPTS = createOneToOneOptions(MOONLIGHTING_OPTIONS);

const PROGRAM_LENGTH_YRS_OPTIONS = ['1', '2', '3', '4', '5'];
export const PROGRAM_LENGTH_YRS_OPTS = createOneToOneOptions(
	PROGRAM_LENGTH_YRS_OPTIONS,
);

const SHIFTS_PER_MONTH = ['5', '10', '15', '20', '25'];
export const FELLOWSHIP_SHIFTS_PER_MONTH_OPTS =
	createOneToOneOptions(SHIFTS_PER_MONTH);
