import React from 'react';

import { Button, Stack, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { LinkUri, RouteUri } from '@ivy/constants/routes';

const EmptySectionText = ({
	variant,
	disablePadding,
	text,
	actionText,
	actionLink,
	onClickAction,
	sx,
	disabled = false,
	...typographyProps
}) => {
	return (
		<Stack
			spacing={2}
			sx={[
				{
					display: disabled && 'none',
					justifyContent: 'center',
					alignItems: 'center',
					py: !disablePadding && 1,
					px: !disablePadding && 1,
				},
				sx,
			]}
		>
			<Typography
				variant={variant}
				align='center'
				fontStyle='italic'
				color='text.secondary'
				{...typographyProps}
			>
				{text}
			</Typography>
			{!!actionText && (
				<Button
					variant='contained'
					{...(actionLink
						? {
								component: RouteLink,
								to: actionLink,
						  }
						: {
								onClick: onClickAction,
						  })}
				>
					{actionText}
				</Button>
			)}
		</Stack>
	);
};

EmptySectionText.routes = RouteUri;
EmptySectionText.links = LinkUri;

export default EmptySectionText;
