import React from 'react';

import { type Theme } from '@mui/material';

import Dropdown, {
	type DropdownProps,
} from '@ivy/components/molecules/Dropdown';
import { Profession, PROFESSION2PROFVERBOSE } from '@ivy/constants/clinician';

const ProfessionSelect = ({
	value,
	SelectProps,
	error = false,
	...props
}: Omit<DropdownProps<false>, 'multiple'>) => {
	return (
		<Dropdown
			value={value}
			options={Object.entries(Profession).map(([k, v]) => ({
				label: PROFESSION2PROFVERBOSE[k as Profession],
				value: v,
			}))}
			SelectProps={{
				displayEmpty: true,
				renderValue: (val) => {
					if (!val) {
						return 'Select a profession';
					}
					return PROFESSION2PROFVERBOSE[val as Profession];
				},
				...SelectProps,
			}}
			InputProps={{
				inputProps: {
					sx: (theme: Theme) => ({
						p: 0,
						...theme.typography.body2,
						color: error ? 'error.main' : !value && 'text.disabled',
					}),
				},
				sx: {
					padding: '0px !important',
					'& .MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
				},
			}}
			{...props}
		/>
	);
};

export default ProfessionSelect;
