import React from 'react';

import { SvgIcon } from '@mui/material';

const StyledArrowRightIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='81'
			height='55'
			viewBox='0 0 81 55'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M55.9564 0.629276C55.1994 -0.175071 53.9336 -0.213427 53.1293 0.543606C52.3249 1.30064 52.2866 2.56639 53.0436 3.37074L74.3417 26H2C0.895431 26 0 26.8954 0 28C0 29.1046 0.895431 30 2 30H73.3276L53.0572 51.1149C52.2923 51.9118 52.3181 53.1778 53.1149 53.9428C53.9118 54.7077 55.1778 54.6819 55.9428 53.8851L79.9428 28.8851C80.6804 28.1167 80.6864 26.9049 79.9564 26.1293L55.9564 0.629276Z'
				fill='url(#paint0_linear_560_5592)'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_560_5592'
					x1='-2.5'
					y1='31'
					x2='262.416'
					y2='23.8754'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#C1D82F' />
					<stop offset='1' stopColor='#005488' />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};

export default StyledArrowRightIcon;
