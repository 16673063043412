import React, { useMemo, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import {
	Box,
	useMediaQuery,
	Typography,
	useTheme,
	Tabs,
	Tab,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useElementSize } from 'usehooks-ts';

import DataLoader from '@ivy/components/molecules/DataLoader';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { getArticle } from '@ivy/lib/formatting/string';

import DottedBlob from './DottedBlob';
import FaqAccordion from './FaqAccordion';
import FooterFaq from './FooterFaq';
import GradientBlob from './GradientBlob';

const FaqAudience = Object.freeze({
	CLINICIAN: 'CLINICIAN',
	EMPLOYER: 'EMPLOYER',
});

const GET_FAQS_QUERY = gql`
    ${FaqAccordion.fragments.faq.fragment}
    query getFaqs {
        faqs(orderBy: order_ASC) {
            ...${FaqAccordion.fragments.faq.name}
        }
    }
`;

const GET_FAQS_CONSTANTS = [
	{ id: 0, categoryName: 'WHY_IVY', label: 'Why Ivy' },
	{ id: 1, categoryName: 'BASICS', label: 'Basics' },
	{ id: 2, categoryName: 'SUPPORT', label: 'Customer Support' },
	{ id: 3, categoryName: 'PRIVACY', label: 'Data & Privacy' },
	{ id: 4, categoryName: 'FINANCE', label: 'Finance' },
	{ id: 5, categoryName: 'DEI', label: 'Diversity & Inclusion' },
];

const TabPanel = ({ children, value, index, ...other }) => {
	return (
		<Box
			sx={{
				display: value !== index && 'none',
			}}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ py: 3 }}>{children}</Box>}
		</Box>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

//use sx instead of in-line styles
const FaqAll = () => {
	const [vertTabsRef, { width: vertTabsWidth }] = useElementSize();
	const { data, loading, error } = useQuery(GET_FAQS_QUERY);
	const [horizontalValue, setHorizontalValue] = useState(FaqAudience.CLINICIAN);
	const [verticalValue, setVerticalValue] = useState(
		GET_FAQS_CONSTANTS[0].categoryName,
	);
	const [expanded, setExpanded] = useState('');
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	const faqData = useMemo(() => {
		/*
        This creates the following structure
        {
            CLINICIAN: [
                {
                    categoryName: 'WHY_IVY'
                    label: 'Why Ivy?',
                    faqs: [...]
                },
                ...
            ],
            EMPLOYER: [
                {
                    categoryName: 'WHY_IVY'
                    label: 'Why Ivy?',
                    faqs: [...]
                },
                ...
            ]
        }
         */
		if (!data) {
			return null;
		}
		return Object.values(FaqAudience).reduce(
			(total, audience) => ({
				...total,
				[audience]: GET_FAQS_CONSTANTS.map(({ categoryName, label }) => ({
					label,
					categoryName,
					faqs: data.faqs.filter(
						(faq) => faq.audience === audience && faq.category === categoryName,
					),
				})),
			}),
			{},
		);
	}, [data]);

	const handleHorizontalTabChange = (event, newValue) => {
		setHorizontalValue(newValue);
	};

	const handleVerticalTabChange = (event, newValue) => {
		setVerticalValue(newValue);
	};

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<NavTemplate
			fullscreen
			showFooter
			pageTitle='Frequently Asked Questions - All'
			pageDescription='View answers to frequently asked questions about Ivy Clinicians.'
			showSupportLauncher
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				data={data}
				loading={loading}
				error={error}
				fullscreen
			>
				{() => (
					<Box sx={{ mb: 5 }}>
						<Typography
							variant='h1'
							align='center'
							sx={{
								width: '100%',
								mt: {
									xs: 5,
									md: 10,
								},
							}}
						>
							All FAQs
						</Typography>
						<Box
							sx={{
								position: 'relative',
								display: 'flex',
								justifyContent: 'center',
								overflow: 'hidden',
							}}
						>
							<GradientBlob
								sx={(theme) => ({
									fontSize: '624px',
									position: 'absolute',
									top: '38px',
									left: '-550px',
									[theme.breakpoints.down(1347)]: {
										display: 'none',
									},
								})}
							/>
							<DottedBlob
								stroke='#1EC86A'
								sx={{
									position: 'absolute',
									left: {
										xs: '1300px',
										xxl: 'initial',
									},
									right: {
										xs: 'initial',
										xxl: '120px',
									},
									top: '114px',
									width: '485px',
									height: '413px',
								}}
							/>
							<Box
								maxWidth='1195px'
								sx={{
									mt: {
										xs: 5,
										md: 10,
									},
									mx: {
										xs: 2,
										md: 5,
									},
								}}
							>
								<Tabs
									onChange={handleHorizontalTabChange}
									value={horizontalValue}
									centered={isXs}
									sx={{
										pb: {
											md: 10,
										},
										ml: {
											xs: 0,
											md: `${vertTabsWidth}px`,
										},
									}}
								>
									{Object.keys(faqData).map((audience, idx, self) => (
										<Tab
											key={`audience-tab-${audience}`}
											label={`I'm ${getArticle(
												audience,
											)} ${audience.toLowerCase()}`}
											value={audience}
											sx={{
												p: 0,
												mr:
													idx !== self.length - 1
														? {
																xs: 8,
																md: 14,
														  }
														: undefined,
												alignItems: 'flex-start',
												fontSize: {
													xs: '18px',
													md: '24px',
												},
											}}
										/>
									))}
								</Tabs>
								<Box
									sx={{
										display: 'flex',
									}}
								>
									<Tabs
										ref={vertTabsRef}
										orientation='vertical'
										onChange={handleVerticalTabChange}
										value={verticalValue}
										sx={{
											flex: '0 0',
											minWidth: 'max-content',
											overflow: 'initial',
											display: {
												xs: 'none',
												md: 'flex',
											},
											pr: 8,
										}}
										TabIndicatorProps={{
											sx: {
												left: 0,
											},
										}}
									>
										{faqData[horizontalValue].map(
											({ categoryName, label }, idx, self) => (
												<Tab
													key={`category-tab-${categoryName}`}
													label={label}
													value={categoryName}
													sx={{
														fontSize: '20px',
														alignItems: 'flex-start',
														mb: idx !== self.length - 1 ? 3 : 0,
													}}
												/>
											),
										)}
									</Tabs>
									<Box
										sx={{
											flex: '1 1',
										}}
									>
										{faqData[horizontalValue].map(
											({ label, categoryName, faqs }) => (
												<Box
													key={`tab-panel-${categoryName}`}
													sx={{
														display: {
															md: verticalValue !== categoryName && 'none',
														},
													}}
												>
													<Typography
														variant='h6'
														component='h2'
														sx={(theme) => ({
															mt: 4,
															mb: 2,
															[theme.breakpoints.up('md')]: {
																// Don't break the page structure with display: none
																// Instead, hide this way
																// https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
																position: 'absolute',
																overflow: 'hidden',
																clip: 'rect(0 0 0 0)',
																height: '1px',
																width: '1px',
																margin: '-1px',
																padding: 0,
																border: 0,
															},
														})}
													>
														{label}
													</Typography>
													<Box>
														{faqs.map((faq, idx, self) => (
															<FaqAccordion
																key={`faq-accordion-${faq.id}`}
																sx={{
																	mb: idx !== self.length - 1 ? 3 : 0,
																}}
																faq={faq}
																expanded={expanded === `${faq.id}`}
																onChange={handleAccordionChange(`${faq.id}`)}
																labelComponent='h3'
															/>
														))}
													</Box>
												</Box>
											),
										)}
									</Box>
								</Box>
							</Box>
						</Box>
						<FooterFaq
							sx={{
								mt: 15,
							}}
						/>
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default FaqAll;
