import React from 'react';

import {
	Box,
	Checkbox,
	type CheckboxProps,
	type SxProps,
	type Theme,
	Typography,
	type TypographyProps,
} from '@mui/material';

import { combineSx } from '@ivy/lib/styling/sx';

export interface LabeledCheckboxProps {
	label: string;
	value: boolean;
	onChange: (event: React.SyntheticEvent, newValue: boolean) => void;
	LabelProps?: TypographyProps;
	sx?: SxProps<Theme>;
	disabled?: boolean;
	size?: CheckboxProps['size'];
}

const LabeledCheckbox = ({
	label,
	value,
	onChange,
	LabelProps,
	sx,
	disabled,
	size,
}: LabeledCheckboxProps) => {
	const handleChange = (ev: React.SyntheticEvent) => {
		if (disabled) {
			return;
		}
		onChange(ev, !value);
	};

	return (
		<Box
			sx={combineSx(
				{
					display: 'flex',
					cursor: !disabled ? 'pointer' : undefined,
					WebkitTapHighlightColor: 'transparent',
					userSelect: 'none',
					// TODO: is this necessary?
					WebkitUserSelect: 'none',
					// TODO: could apply opacity when disabled
				},
				sx,
			)}
			onClick={handleChange}
		>
			<Box
				sx={{
					mr: 1,
				}}
			>
				<Checkbox
					checked={value}
					sx={{
						p: 0,
					}}
					disabled={disabled}
					size={size}
				/>
			</Box>
			<Box>
				<Typography
					variant={size === 'small' ? 'body2' : 'body1'}
					color='text.icon'
					{...LabelProps}
					sx={combineSx(
						{
							position: 'relative',
							top: '2px',
						},
						LabelProps?.sx,
					)}
				>
					{label}
				</Typography>
			</Box>
		</Box>
	);
};

export default LabeledCheckbox;
