import React from 'react';

import {
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
} from '@mui/material';
import { format as formatDate, parseISO } from 'date-fns';

import { DEGREE2LICENSE } from '@ivy/constants/clinician';
import { stateAbbreviationsToName } from '@ivy/constants/location';
import { bulletJoin } from '@ivy/lib/formatting/string';

const LicenseList = ({
	licenses,
	profDegree,
	primaryTypographyProps,
	secondaryTypographyProps,
}) => {
	return (
		<List
			sx={{
				width: '100%',
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
			}}
		>
			{licenses.map((license) => (
				<ListItem
					key={`license-${license.id}`}
					sx={{
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
					}}
				>
					<ListItemAvatar>
						<Avatar
							sx={{
								bgcolor: 'secondary.main',
								fontSize: '1rem',
							}}
						>
							{license.state}
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={
							license.entity ||
							`${stateAbbreviationsToName[license.state] || license.state} ${
								DEGREE2LICENSE[profDegree]
							} License`
						}
						primaryTypographyProps={primaryTypographyProps}
						secondary={bulletJoin([
							license.licenseNo,
							!!license.issue &&
								!!license.expiry &&
								`${formatDate(parseISO(license.issue), 'yyyy')} - ${formatDate(
									parseISO(license.expiry),
									'yyyy',
								)}`,
						])}
						secondaryTypographyProps={secondaryTypographyProps}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default LicenseList;
