import { useMemo, useCallback } from 'react';

import { useGridApiRef, type GridState } from '@mui/x-data-grid-premium';

const useGridStateStorage = (name: string, initial?: Partial<GridState>) => {
	const apiRef = useGridApiRef();

	const initialState = useMemo(() => {
		const state = localStorage.getItem(name);
		if (state) {
			return JSON.parse(state);
		}
		return initial;
	}, [name, initial]);

	const handleStateChange = useCallback(() => {
		localStorage.setItem(name, JSON.stringify(apiRef.current.exportState()));
	}, [name, apiRef]);

	return {
		initialState,
		handleStateChange,
		apiRef,
	};
};

export default useGridStateStorage;
