import React from 'react';

import { SvgIcon } from '@mui/material';

const BlobDoubleLayer = ({ ...props }) => {
	return (
		<SvgIcon
			width='330'
			height='322'
			viewBox='0 0 330 322'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.1'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M320.859 134.651C337.768 170.129 326.607 212.201 309.277 247.475C293.023 280.559 265.813 308.673 230.376 318.822C197.786 328.156 167.388 307.153 134.918 297.411C95.3231 285.533 41.8081 293.286 22.8364 256.558C3.70699 219.525 37.968 178.887 53.771 140.317C69.7993 101.197 74.2764 48.8028 113.946 34.188C153.373 19.6627 189.874 57.8981 227.672 76.2501C261.953 92.8951 304.464 100.25 320.859 134.651Z'
				fill='#1EC86A'
			/>
			<path
				opacity='0.1'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M272.985 155.655C277.385 192.967 257.428 227.586 234.265 254.152C212.539 279.069 183.272 296.11 152.046 294.361C123.33 292.752 104.28 264.374 80.6267 245.545C51.7835 222.585 6.60036 213.091 0.861861 174.012C-4.92438 134.607 33.2917 108.411 56.0816 78.3918C79.1964 47.9445 96.4506 1.9251 132.253 0.940224C167.836 -0.0386248 187.321 45.812 213.029 74.0744C236.346 99.7078 268.719 119.475 272.985 155.655Z'
				fill='#2682C5'
			/>
		</SvgIcon>
	);
};

export default BlobDoubleLayer;
