import React from 'react';

import { ApolloProvider } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { type Theme, ThemeProvider, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { SnackbarProvider, type SnackbarProviderProps } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import ABProvider from '@ivy/components/providers/ABProvider';
import AdProvider from '@ivy/components/providers/AdProvider';
import AuthPopupProvider from '@ivy/components/providers/AuthPopupProvider';
import AuthProvider, {
	useAuthContext,
} from '@ivy/components/providers/AuthProvider';
import CrispProvider from '@ivy/components/providers/CrispProvider';
import RedirectProvider from '@ivy/components/providers/RedirectProvider';
import SiteSettingsProvider from '@ivy/components/providers/SiteSettingsProvider';
import TourProvider from '@ivy/components/providers/TourProvider';
import config from '@ivy/config';
import client from '@ivy/gql/client/client';
import { useGtm } from '@ivy/lib/gtm';
import { useMixpanel, useSentry } from '@ivy/lib/hooks';
import { isCrawler } from '@ivy/lib/util/userAgent';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

// Create a custom emotion cache for prerendering
const emotionCache = createCache({
	key: 'css',
	// MUI says this should be true, but we find it messes up the actual styling
	// We don't believe this to be a problem since we only rely on MUI for the styling
	// https://mui.com/material-ui/guides/interoperability/
	prepend: false,
	// https://stackoverflow.com/questions/73954622/prerender-io-does-not-cache-my-sites-css
	speedy: navigator.userAgent.toLowerCase().indexOf('prerender') === -1,
});

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
	'&.SnackbarItem-variantSuccess': {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.success.contrastText,
	},
	'&.SnackbarItem-variantError': {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
	},
	'&.SnackbarItem-variantWarning': {
		backgroundColor: theme.palette.warning.main,
		color: theme.palette.warning.contrastText,
	},
	'&.SnackbarItem-variantInfo': {
		backgroundColor: theme.palette.info.main,
		color: theme.palette.info.contrastText,
	},
}));

type AnchoredSnackbarProviderProps = Omit<
	SnackbarProviderProps,
	'anchorOrigin'
>;

const AnchoredSnackbarProvider = (props: AnchoredSnackbarProviderProps) => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('sm'),
	);
	return (
		<StyledSnackbarProvider
			{...props}
			anchorOrigin={
				isMobile
					? {
							horizontal: 'center',
							vertical: 'top',
					  }
					: {
							horizontal: 'left',
							vertical: 'bottom',
					  }
			}
		/>
	);
};

interface ScriptsProps {
	children?: React.ReactNode;
}

const Scripts = ({ children }: ScriptsProps) => {
	const { initialized } = useAuthContext();
	const disableScripts = config.env !== 'production' || isCrawler;
	useSentry(initialized, config.sentryDsn, config.env, disableScripts);
	useGtm(config.gtmId, disableScripts);
	useMixpanel(config.mixpanelToken, {
		// Don't disable during initialization or an invalid token will be set and disrupt future operations
		disabled: disableScripts,
		debug: config.nodeEnv === 'development',
		apiHost: config.mixpanelApiHost,
	});

	return (
		<CrispProvider
			appReady={initialized}
			crispWebsiteId={config.crispId || ''}
			disabled={disableScripts}
		>
			{children}
		</CrispProvider>
	);
};

const WhitelabelThemeProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const whitelabel = useWhitelabel();
	return <ThemeProvider theme={whitelabel.theme}>{children}</ThemeProvider>;
};

export interface AppProviderProps {
	children?: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
	return (
		<BrowserRouter>
			<QueryParamProvider adapter={ReactRouter6Adapter}>
				<ApolloProvider client={client}>
					{/* Theme changes based on routes, so nest inside Browser Route */}
					<CacheProvider value={emotionCache}>
						<WhitelabelThemeProvider>
							{/* CssBaseline must be nested inside ThemeProvider */}
							<CssBaseline />
							<AnchoredSnackbarProvider maxSnack={3}>
								{/* Auth must be nested inside browser router */}
								<AuthProvider>
									{/* Scripts must be nested inside AuthProvider */}
									<Scripts>
										{/* RedirectProvider just needs BrowserRouter */}
										<RedirectProvider>
											<ABProvider>
												<SiteSettingsProvider>
													<AdProvider
														apiKey={config.topsortApiKey}
														// Temporarily disabled overall
														disabled={
															isCrawler || config.env !== 'production' || true
														}
													>
														<TourProvider>
															{/* Auth Popups must be nested within Scripts & AuthProvider */}
															<AuthPopupProvider>{children}</AuthPopupProvider>
														</TourProvider>
													</AdProvider>
												</SiteSettingsProvider>
											</ABProvider>
										</RedirectProvider>
									</Scripts>
								</AuthProvider>
							</AnchoredSnackbarProvider>
						</WhitelabelThemeProvider>
					</CacheProvider>
				</ApolloProvider>
			</QueryParamProvider>
		</BrowserRouter>
	);
};

export default AppProvider;
