import React, { useCallback, useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import FeaturedBadge from '@ivy/components/atoms/FeaturedBadge';
import RouteLink from '@ivy/components/atoms/RouteLink';
import { BriefcaseIcon, RunningToWorkIcon } from '@ivy/components/icons';
import ReviewSnippet from '@ivy/components/molecules/ReviewSnippet';
import ApplyToJobButton from '@ivy/components/organisms/ApplyToJobButton';
import ApplyToJobPopup from '@ivy/components/organisms/ApplyToJobPopup';
import ReviewPopup from '@ivy/components/organisms/ReviewPopup';
import SalaryReportPopup from '@ivy/components/organisms/SalaryReportPopup';
import TabTemplate, { IconTypes } from '@ivy/components/templates/TabTemplate';
import { DEGREE2PROFESSION, Profession } from '@ivy/constants/clinician';
import { RouteUri } from '@ivy/constants/routes';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql, getFragmentData } from '@ivy/gql/types';
import {
	type ApplyToJobButton_ContractFragment,
	ApplyToJobButton_ContractFragmentDoc,
} from '@ivy/gql/types/graphql';
import { formatInteger } from '@ivy/lib/formatting/number';
import { oxfordJoin } from '@ivy/lib/formatting/string';
import { usePrevious } from '@ivy/lib/hooks';
import { buildInternalLink, getRelativeRoute } from '@ivy/lib/util/route';

import EmployerListPopover from './EmployerListPopover';
import FacilityJobs from './FacilityJobs';
import FacilityProfile from './FacilityProfile';
import FacilityReviews from './FacilityReviews';
import FacilitySalary from './FacilitySalary';

const FacilityShow_FacilityQDoc = gql(/* GraphQL */ `
	query FacilityShow_Facility($facilityId: uuid!) {
		facility: facility_by_pk(id: $facilityId) {
			id
			slug
			name
			picture {
				id
				filename
				publicUrl: public_url
			}
			streetAddr1: street_address_1
			streetAddr2: street_address_2
			city
			state
			contracts(
				where: { active: { _eq: true } }
				order_by: { created_at: asc }
			) {
				id
				...EmployerListPopover_Contract
				...ApplyToJobButton_Contract
				lastApplied: last_applied
				lastAppliedDate @client
				isOwner: is_contract_owner
				org {
					id
					name
					slug
					...ReviewPopup_Organization
				}
			}
			reviewsAgg: reviews_aggregate(
				where: {
					active: { _eq: true }
					visible: { _eq: true }
					has_active_contract: { _eq: true }
				}
			) {
				aggregate {
					avg {
						rating: rating_job
					}
					count
				}
			}
			postings {
				id
				professionsCt: professions_aggregate {
					aggregate {
						count
					}
				}
			}
			salaryAgg: salary_reports_fmt_aggregate {
				aggregate {
					count
				}
			}
			...SalaryReportPopup_Facility
			...ReviewPopup_Facility
		}
	}
`);

const FacilityShow = () => {
	// We want to store popup state on this component so that if user clicks connect -> login, then the connect
	// popup is shown as the component did not unmount.  If we were to try to store the state in the connect button,
	// then that would be the case.
	// TODO: better solution is using context attached to ApplyToJobPopup. Do this when re-configuring for
	//  hospitalists
	const [selectedContracts, setSelectedContracts] = useState<
		readonly ApplyToJobButton_ContractFragment[]
	>([]);
	const [showSalaryReport, setShowSalaryReport] = useState(false);
	const [showReview, setShowReview] = useState(false);
	const { facilityId: rawFacilityId } = useParams();
	const facilityId = rawFacilityId?.substring(0, 36);
	const [empAnchor, setEmpAnchor] = useState(null);
	const currAcc = useCurrentAccount();
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;

	// Used to determine the nearby facilities to show to clinicians
	const profession = currAcc?.isClinician
		? DEGREE2PROFESSION[currAcc.clinician!.profDegree]
		: Profession.PHYSICIAN;

	const { data, loading, error, refetch } = useQuery(
		FacilityShow_FacilityQDoc,
		{
			variables: {
				facilityId: facilityId,
			},
			// Reflect updates to the connection status (via job posting page) or job posting additions/deletions
			// Don't use cache-and-network then cache-first -> infinite loop problem
			fetchPolicy: 'network-only',
		},
	);

	// Either a UUID or undefined
	const currAccId = currAcc?.id;
	// Initially undefined
	const prevCurrAccId = usePrevious(currAccId);
	// Contract owner Id
	const ownerContractId = data?.facility?.contracts.find(
		(contract) => contract.isOwner,
	)?.id;

	useEffect(() => {
		// When a user logs in, they might have already submitted a job application or salaries, so the page needs
		// to be refreshed to reflect that
		if (currAccId !== prevCurrAccId) {
			refetch();
		}
	}, [currAccId, prevCurrAccId, refetch]);

	const handleOpenPopup = useCallback(
		(selected: readonly ApplyToJobButton_ContractFragment[]) => () => {
			setSelectedContracts(selected);
		},
		[setSelectedContracts],
	);

	const handleClosePopup = useCallback(() => {
		setSelectedContracts([]);
	}, [setSelectedContracts]);

	const handleCloseSalaryReport = useCallback(() => {
		setShowSalaryReport(false);
	}, [setShowSalaryReport]);

	const handleOpenSalaryReport = useCallback(() => {
		setShowSalaryReport(true);
	}, [setShowSalaryReport]);

	const handleCloseReview = useCallback(() => {
		setShowReview(false);
	}, [setShowReview]);

	const handleOpenReview = useCallback(() => {
		setShowReview(true);
	}, [setShowReview]);

	const handleClickShowEmployers = useCallback(
		(ev) => {
			ev.stopPropagation();
			setEmpAnchor(ev.currentTarget);
		},
		[setEmpAnchor],
	);

	const handleCloseShowEmployers = useCallback(() => {
		setEmpAnchor(null);
	}, [setEmpAnchor]);

	useEffect(() => {
		// Scenario can happen where anon user logs in and has already sent some connections.
		// In this case, we should either remove them or close the popup if all removed
		// Also replace each contract with it's updated value
		if (!data) {
			return;
		}
		setSelectedContracts((prev) =>
			prev
				.map((staleContract) =>
					getFragmentData(
						ApplyToJobButton_ContractFragmentDoc,
						data.facility?.contracts.find(
							(newContract) => newContract.id === staleContract.id,
						),
					),
				)
				.filter(
					(newContract): newContract is NonNullable<typeof newContract> =>
						!!newContract && !newContract.lastApplied,
				),
		);
	}, [data, setSelectedContracts]);

	return (
		<React.Fragment>
			<TabTemplate
				title={data?.facility ? data.facility.name : ''}
				pageTitle={data?.facility ? [data.facility.name] : undefined}
				pageDescription={
					data?.facility
						? `Explore salaries, reviews, and job opportunities for emergency physicians, nurse practitioners, and physician assistants ${
								data.facility.contracts.length
									? `with ${oxfordJoin(
											data.facility.contracts.map((el) => el.org.name),
											'and',
									  )} `
									: ''
						  }at ${data.facility.name}.`
						: undefined
				}
				showSupportLauncher
				image={data?.facility?.picture?.publicUrl}
				defaultBackNav={{
					link: RouteUri.ROOT,
					target: 'search',
				}}
				middleInfo={
					<ReviewSnippet
						rating={data?.facility?.reviewsAgg.aggregate?.avg?.rating}
						count={data?.facility?.reviewsAgg.aggregate?.count}
						sx={{ minWidth: '140px' }}
						redirect={
							data?.facility
								? buildInternalLink(RouteUri.FACILITY_REVIEW_LIST, {
										facilityId: [data.facility.id, data.facility.slug],
								  })
								: undefined
						}
					/>
				}
				infoArray={[
					{
						icon: <BriefcaseIcon />,
						text: data?.facility?.contracts.map((el, index) => (
							<React.Fragment key={el.org.id}>
								<RouteLink
									to={buildInternalLink(RouteLink.routes.ORG_SHOW, {
										orgId: [el.org.id, el.org.slug],
									})}
									underline='hover'
								>
									{el.org.name}
								</RouteLink>
								{index < (data?.facility?.contracts?.length || 0) - 1 && (
									<Typography component='span' mr={1}>
										,
									</Typography>
								)}
							</React.Fragment>
						)),
					},
					{
						icon: IconTypes.LOCATION,
						text: `${data?.facility?.streetAddr1}, ${data?.facility?.city}, ${data?.facility?.state}`,
					},
				]}
				tabs={
					data?.facility
						? [
								{
									path: getRelativeRoute(
										RouteUri.FACILITY_SHOW,
										RouteUri.FACILITY_INDEX,
									),
									index: true,
									label: 'Overview',
									element: facilityId ? (
										<FacilityProfile
											facilityId={facilityId}
											profession={profession}
											// Could be comparing undefined === undefined, so check to make sure that's not happening
											// org.id may be null if permissions block access
											onClickApply={handleOpenPopup}
										/>
									) : null,
									NavTemplateProps: {
										pathIsCanonical: buildInternalLink(RouteUri.FACILITY_SHOW, {
											facilityId: [data.facility.id, data.facility.slug],
										}),
										// Don't index if it's inactive (no contracts) with no salaries and no reviews.
										// No need to look at jobs as inactive facility will not have jobs, and if it's
										// active, we don't care if it has jobs or not.
										pageNoIndex:
											!data.facility.contracts.length &&
											!data.facility.reviewsAgg.aggregate?.count &&
											!data.facility.salaryAgg.aggregate?.count,
										TopBarProps: {
											sx: {
												zIndex: (theme) => theme.zIndex.appBar - 61,
											},
										}, // This is so that is the images have a higher z-index than the header
									},
									action:
										isClinicianOrAnon && data?.facility?.contracts.length ? (
											<ApplyToJobButton
												variant='normal'
												btnId='pg-facility--btn-connect__all'
												size='large'
												contracts={data!.facility!.contracts}
												onClickApply={handleOpenPopup}
												onClickShowEmployers={handleClickShowEmployers}
												sx={(theme) => ({
													px: { md: 3, xs: 1 },
													py: { md: 2, xs: 1 },
													my: 'auto',
													flex: { md: '0 1 auto', xs: 1 },
													...theme.typography.body1,
													fontWeight: 'bold',
												})}
											/>
										) : ownerContractId ? (
											<Button
												variant='contained'
												startIcon={<Add />}
												component={RouteLink}
												to={{
													pathname: RouteLink.routes.JOB_POSTING_CREATE,
													search: `contractId=${encodeURIComponent(
														ownerContractId,
													)}`,
												}}
												state={{
													backNav: {
														target: 'facility',
													},
												}}
												sx={{
													px: { md: 3, xs: 1 },
													py: { md: 2, xs: 1 },
													my: 'auto',
													flex: { md: 'none', xs: 1 },
												}}
											>
												<Typography variant='body1' fontWeight='bold'>
													Create Job Posting
												</Typography>
											</Button>
										) : undefined,
								},
								{
									path: getRelativeRoute(
										RouteUri.FACILITY_JOB_LIST,
										RouteUri.FACILITY_INDEX,
									),
									label: `Jobs (${formatInteger(
										data.facility.postings?.reduce(
											(sum, el) =>
												sum + (el.professionsCt.aggregate?.count ?? 0),
											0,
										) ?? 0,
									)})`,
									labelContent:
										(data.facility.postings?.length || 0) > 0 ? (
											<FeaturedBadge
												sx={{ ml: 1 }}
												icon={<RunningToWorkIcon style={{ fontSize: '1em' }} />}
											/>
										) : undefined,
									element: facilityId ? (
										<FacilityJobs
											facilityId={facilityId}
											onClickApply={handleOpenPopup}
											onClickShowEmployers={handleClickShowEmployers}
										/>
									) : null,
									// This is a valid route if someone goes to it directly, but if there are no
									// postings, we hide the tab so that don't give the impression there are 0 jobs
									// at this facility. In reality, there likely are job openings that we just
									// don't know about, or the recruiter will still want people to apply to keep
									// their CV on file for future openings.
									hidden: !data.facility.postings?.length,
									NavTemplateProps: {
										// Use an array to nest the page title
										pageTitle: ['Jobs'],
										fullscreen: false,
										showFooter: true,
										pathIsCanonical: RouteUri.FACILITY_JOB_LIST.replace(
											':facilityId',
											`${data.facility.id}-${data.facility.slug}`,
										),
										// Don't index if no job postings
										pageNoIndex: !data.facility.postings?.length,
									},
									// Opt: Enable if they have requested to add a facility to their group
									// Opt: Disable if they have requested to remove a facility from their group
									action: ownerContractId ? (
										<Button
											variant='contained'
											startIcon={<Add />}
											component={RouteLink}
											to={{
												pathname: RouteLink.routes.JOB_POSTING_CREATE,
												search: `contractId=${encodeURIComponent(
													ownerContractId,
												)}`,
											}}
											state={{
												backNav: {
													target: 'facility',
												},
											}}
											sx={{
												px: { md: 3, xs: 1 },
												py: { md: 2, xs: 1 },
												my: 'auto',
												flex: { md: 'none', xs: 1 },
											}}
										>
											<Typography variant='body1' fontWeight='bold'>
												Create Job Posting
											</Typography>
										</Button>
									) : undefined,
								},
								{
									path: getRelativeRoute(
										RouteUri.FACILITY_REVIEW_LIST,
										RouteUri.FACILITY_INDEX,
									),
									label: `Reviews (${formatInteger(
										data?.facility?.reviewsAgg?.aggregate?.count || 0,
									)})`,
									element: facilityId ? (
										<FacilityReviews facilityId={facilityId} />
									) : null,
									NavTemplateProps: {
										// Use an array to nest the page title
										pageTitle: ['Reviews'],
										fullscreen: false,
										showFooter: true,
										pathIsCanonical: buildInternalLink(
											RouteUri.FACILITY_REVIEW_LIST,
											{
												facilityId: [data.facility.id, data.facility.slug],
											},
										),
										// Don't index if no reviews
										pageNoIndex: !data.facility.reviewsAgg.aggregate?.count,
									},
									action: isClinicianOrAnon ? (
										<Button
											variant='contained'
											startIcon={<Add />}
											onClick={handleOpenReview}
											sx={{
												px: { md: 3, xs: 1 },
												py: { md: 2, xs: 1 },
												my: 'auto',
												flex: { md: 'none', xs: 1 },
											}}
										>
											<Typography variant='body1' fontWeight='bold'>
												Add review
											</Typography>
										</Button>
									) : undefined,
								},
								{
									path: getRelativeRoute(
										RouteUri.FACILITY_SALARY_LIST,
										RouteUri.FACILITY_INDEX,
									),
									label: `Salaries (${formatInteger(
										data?.facility?.salaryAgg?.aggregate?.count || 0,
									)})`,
									element: facilityId ? (
										<FacilitySalary
											facilityId={facilityId}
											onClickSalary={handleOpenSalaryReport}
										/>
									) : null,
									NavTemplateProps: {
										// Use an array to nest the page title
										pageTitle: ['Salaries'],
										fullscreen: false,
										showFooter: true,
										pathIsCanonical: buildInternalLink(
											RouteUri.FACILITY_SALARY_LIST,
											{
												facilityId: [data.facility.id, data.facility.slug],
											},
										),
										// Don't index if no reviews
										pageNoIndex: !data.facility.salaryAgg.aggregate?.count,
									},
									action: isClinicianOrAnon ? (
										<Button
											variant='contained'
											startIcon={<Add />}
											onClick={handleOpenSalaryReport}
											sx={{
												px: { md: 3, xs: 1 },
												py: { md: 2, xs: 1 },
												my: 'auto',
												flex: { md: 'none', xs: 1 },
												display: { gridBreak: 'none', xs: 'inline-flex' },
											}}
										>
											<Typography variant='body1' fontWeight='bold'>
												Add Salary
											</Typography>
										</Button>
									) : undefined,
								},
						  ]
						: []
				}
				DataLoaderProps={{
					data,
					loading,
					error,
					keys: ['facility'],
				}}
			/>
			{!!selectedContracts.length && (
				<ApplyToJobPopup
					contracts={selectedContracts}
					open
					onClose={handleClosePopup}
					onComplete={handleClosePopup}
					refetchQueries={['FacilityShow_Facility']}
				/>
			)}
			{showSalaryReport && !!data?.facility && (
				<SalaryReportPopup
					open
					facility={data.facility}
					onClose={handleCloseSalaryReport}
					refetchQueries={[
						'FacilityShow_Facility',
						'FacilitySalary_Facility',
						'SalaryList_SearchSalaryReports',
					]}
				/>
			)}
			{showReview && !!data?.facility && (
				<ReviewPopup
					open
					facility={data.facility}
					organization={data.facility.contracts[0]?.org}
					onSuccess={handleCloseReview}
					onClose={handleCloseReview}
					refetchQueries={[
						'FacilityShow_Facility',
						'FacilityReviews_Facility',
						'ReviewList_SearchReview',
					]}
				/>
			)}
			{empAnchor && !!data?.facility && (
				<EmployerListPopover
					anchorEl={empAnchor}
					contracts={data.facility.contracts}
					open={!!empAnchor}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onClickApply={handleOpenPopup}
					onClose={handleCloseShowEmployers}
				/>
			)}
		</React.Fragment>
	);
};

export default FacilityShow;
