import React from 'react';

import { Box, TextField, Typography, Stack } from '@mui/material';
import * as yup from 'yup';

import { NavButton } from '@ivy/components/atoms/FormButtons';
import { ProfDegree } from '@ivy/constants/clinician';
import { type SubFormStepProps } from '@ivy/lib/forms/formFormatHelpers';

import {
	type ExtraParams,
	type ProfileCompleteFormValues,
} from '../profileCompleteForm';

interface CredentialsValues {
	creds: string;
}

const DegreeExamples = Object.freeze({
	[ProfDegree.MD]: 'e.g. MD, PhD, FACEP',
	[ProfDegree.DO]: 'e.g. DO, PhD, FACEP',
	[ProfDegree.PA]: 'e.g. PA-C, DMSc, DFAAPA',
	// https://www.apea.com/blog/How-Should-Nurse-Practitioners-List-Their-Credentials-26/#:~:text=Common%20state%20designations%20for%20NPs,after%20they%20become%20nurse%20practitioners.
	[ProfDegree.NP]: 'e.g. MSN, ENP-C, FAANP',
});

const validation = yup.object({
	creds: yup.string().required().label('Credentials'),
});

const initialValue = {
	creds: '',
};

const Credentials = ({
	formik,
	onNext,
	extra,
}: SubFormStepProps<ProfileCompleteFormValues, ExtraParams>) => {
	const { clinician } = extra;
	const { account, profDegree } = clinician;
	const { values, touched, errors, handleChange, handleBlur } = formik;

	return (
		<Box>
			<Stack direction={{ sm: 'row', xs: 'column' }} sx={{ width: '100%' }}>
				<Stack
					direction='column'
					sx={{ width: '100%', maxWidth: { sm: '375px', xs: 'none' } }}
				>
					<TextField
						name='creds'
						placeholder={`Enter your credentials (${DegreeExamples[profDegree]})`}
						value={values.creds}
						onChange={handleChange}
						onBlur={handleBlur}
						error={touched.creds && !!errors.creds}
						helperText={touched.creds && errors.creds}
						fullWidth
					/>
					<Typography
						variant='body2'
						sx={{
							color: 'text.icon',
							mt: 1,
						}}
					>
						<Box component='span' sx={{ fontWeight: 'bold' }}>
							Preview:
						</Box>{' '}
						{account?.pi?.fullName}, {values.creds}
					</Typography>
				</Stack>
				<Box
					sx={{
						ml: { sm: 3, xs: 0 },
					}}
				>
					<NavButton variant='text' onClick={onNext}>
						Next ⏎
					</NavButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default Credentials;
export { type CredentialsValues, validation, initialValue };
