import React from 'react';

import {
	type Breakpoint,
	type SxProps,
	type Theme,
	useMediaQuery,
} from '@mui/material';

import AdBox from '@ivy/components/molecules/AdBox';
import { useBannerAd } from '@ivy/components/providers/AdProvider';
import { type AdAuctionCategory } from '@ivy/gql/types/graphql';

export interface BannerAdProps {
	slotId: string;
	category?: AdAuctionCategory;
	bp: Breakpoint | number;
	hideMobile?: boolean;
	hideDesktop?: boolean;
	sx?: SxProps;
}

/**
 * An `AdBox` that runs its own ad auction and displays the winner. This should ONLY be used
 * if there is only one such ad slot on the page. If multiple ad slots exist on the page, then
 * a manual auction should be run in order to prevent the same ad from displaying more than once.
 */
const BannerAd = ({
	slotId,
	category,
	bp,
	hideDesktop,
	hideMobile,
	sx,
}: BannerAdProps) => {
	const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(bp));
	const { data } = useBannerAd(
		[
			{
				type: 'banners',
				slotId,
				slots: 1,
				category,
				device: isDesktop ? 'desktop' : 'mobile',
			},
		],
		{
			skip: (isDesktop && hideDesktop) || (!isDesktop && hideMobile),
		},
	);

	if (!data?.[0]?.winners[0]) {
		return null;
	}

	return <AdBox winner={data[0].winners[0]} sx={sx} />;
};

export default BannerAd;
