import { type ObjectSchema } from 'yup';

export const filterValidData = <T extends object>(
	data: T,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema: ObjectSchema<any>,
): T => {
	// Filter out any empty invalid data
	const validData = Object.entries(data).reduce((acc, [key, currField]) => {
		if (!schema.fields[key]) {
			acc[key] = currField;
			return acc;
		}

		if (Array.isArray(currField)) {
			const nestedSchema = schema.fields[key].innerType;
			const res = currField.filter((obj) => {
				try {
					nestedSchema.validateSync(obj, {
						abortEarly: false,
						context: { strict: true },
					});
					return true;
				} catch (error) {
					return false; // filter out invalid
				}
			});
			acc[key] = res;
		} else {
			schema.validateSyncAt(key, data, {
				abortEarly: false,
				context: { strict: true },
			});
			acc[key] = currField;
		}
		return acc;
	}, {});

	return validData as T;
};
