import React from 'react';

import {
	Avatar,
	type AvatarProps,
	Box,
	type SxProps,
	type Theme,
	Typography,
	type TypographyProps,
} from '@mui/material';

import { PlaceholderIcon } from '@ivy/components/icons';
import { CLINICIAN_PLACEHOLDER_IMAGE } from '@ivy/constants/clinician';
import { FACILITY_PLACEHOLDER_IMAGE } from '@ivy/constants/facility';
import { gql, getFragmentData, type FragmentType } from '@ivy/gql/types';
import { formatInitials } from '@ivy/lib/formatting/account';
import { getImageKitUrl } from '@ivy/lib/services/imageKit';
import { combineSx } from '@ivy/lib/styling/sx';

export const PlaceholderAvatar = ({ src, iconComponent, ...props }) => {
	return (
		<Avatar src={src} {...props}>
			{!src && !!iconComponent && (
				<PlaceholderIcon iconComponent={iconComponent} />
			)}
		</Avatar>
	);
};

const ClinicianAvatar_ClinicianFDoc = gql(/* GraphQL */ `
	fragment ClinicianAvatar_Clinician on clinician {
		id
		account {
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
			}
			picture {
				id
				filename
				publicUrl: public_url
			}
		}
	}
`);

interface ClinicianAvatarProps extends AvatarProps {
	clinician: FragmentType<typeof ClinicianAvatar_ClinicianFDoc>;
	TypographyProps: TypographyProps<'p'>;
	size?: number;
}

export const ClinicianAvatar = ({
	clinician: rawClinician,
	sx,
	TypographyProps,
	size,
	...props
}: ClinicianAvatarProps) => {
	const clinician = getFragmentData(
		ClinicianAvatar_ClinicianFDoc,
		rawClinician,
	);
	const displayFallback = !clinician.account.picture?.publicUrl;
	return (
		<Avatar
			sx={combineSx(
				{
					position: 'relative',
				},
				size
					? {
							width: size,
							height: size,
					  }
					: undefined,
				sx,
			)}
			{...props}
		>
			<Box
				component='img'
				src={
					displayFallback
						? CLINICIAN_PLACEHOLDER_IMAGE
						: getImageKitUrl(clinician.account.picture!.publicUrl, {
								transformation: [
									{
										// Double the width/height to account for retina displays
										width: ((size || 40) * 2)?.toString(),
										height: ((size || 40) * 2)?.toString(),
										focus: 'auto',
									},
								],
						  })
				}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					textAlign: 'center',
					objectFit: 'cover',
					color: 'transparent',
					textIndent: '10000px',
					// filter: displayFallback && 'blur(3px) brightness(0.7)'
					filter: displayFallback ? 'blur(3px)' : undefined,
				}}
			/>
			<Typography
				component='p'
				{...TypographyProps}
				sx={combineSx(
					{
						zIndex: 1,
						display: !displayFallback ? 'none' : undefined,
					},
					TypographyProps?.sx,
				)}
			>
				{formatInitials(clinician.account)}
			</Typography>
		</Avatar>
	);
};

export const FacilityAvatar = ({ facility, ...props }) => {
	return (
		<Avatar
			src={facility.picture?.publicUrl || FACILITY_PLACEHOLDER_IMAGE}
			{...props}
		/>
	);
};

const AccountAvatar_AccountFDoc = gql(/* GraphQL */ `
	fragment AccountAvatar_Account on account {
		id
		pi: personal_info {
			id
			firstName: first_name
			lastName: last_name
		}
		picture {
			id
			filename
			publicUrl: public_url
		}
	}
`);

const CurrentAccountAvatar_CurrentAccountFDoc = gql(/* GraphQL */ `
	fragment CurrentAccountAvatar_CurrentAccount on current_account {
		id
		pi: personal_info {
			id
			firstName: first_name
			lastName: last_name
		}
		picture {
			id
			filename
			publicUrl: public_url
		}
	}
`);

export interface AccountAvatarProps {
	size?: number;
	account:
		| FragmentType<typeof AccountAvatar_AccountFDoc>
		| FragmentType<typeof CurrentAccountAvatar_CurrentAccountFDoc>;
	TypographyProps?: TypographyProps<'p'>;
	sx?: SxProps<Theme>;
}

export const AccountAvatar = ({
	account: rawAccount,
	TypographyProps,
	size,
	sx,
}: AccountAvatarProps) => {
	const account =
		rawAccount['__typename'] === 'account'
			? getFragmentData(
					AccountAvatar_AccountFDoc,
					rawAccount as FragmentType<typeof AccountAvatar_AccountFDoc>,
			  )
			: getFragmentData(
					CurrentAccountAvatar_CurrentAccountFDoc,
					rawAccount as FragmentType<
						typeof CurrentAccountAvatar_CurrentAccountFDoc
					>,
			  );
	const initials = formatInitials(account);
	return (
		<Avatar
			src={
				account.picture?.publicUrl
					? getImageKitUrl(account.picture.publicUrl, {
							transformation: [
								{
									// Double the width/height to account for retina displays
									width: ((size || 40) * 2)?.toString(),
									height: ((size || 40) * 2)?.toString(),
									// focus: 'face' is too close up, but 'auto' is good on focusing on people if they
									// are out of the center
									focus: 'auto',
								},
							],
					  })
					: undefined
			}
			sx={combineSx(
				size
					? {
							width: size,
							height: size,
					  }
					: undefined,
				sx,
			)}
		>
			{TypographyProps ? (
				<Typography component='p' {...TypographyProps}>
					{initials}
				</Typography>
			) : (
				<>{initials}</>
			)}
		</Avatar>
	);
};
