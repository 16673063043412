import React from 'react';

import { useMutation } from '@apollo/client';
import { Box, Typography, Button } from '@mui/material';
import { captureException } from '@sentry/react';
import * as yup from 'yup';

import Dropdown from '@ivy/components/molecules/Dropdown';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { Role } from '@ivy/constants/account';
import { EMRATYPESVERBOSE2TYPES, EMRATypes } from '@ivy/constants/emra';
import { gql } from '@ivy/gql/types';
import { type SiteSettingsProvider_SiteFragment } from '@ivy/gql/types/graphql';
import { createRoleContext } from '@ivy/gql/util';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';
import { useForm } from '@ivy/lib/hooks';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

import { resolvePlatform } from './SiteSettingsProvider';

const SettingsModal_UpdateSiteMDoc = gql(/* GraphQL */ `
	mutation settingsModal_UpdateSite(
		$platform: platform!
		$emraFeaturedTrainingType: trainingtype!
	) {
		update_site(
			where: { platform: { _eq: $platform } }
			_set: { emra_featured_training_type: $emraFeaturedTrainingType }
		) {
			returning {
				id
			}
		}
	}
`);

interface SettingsModalProps extends Omit<PopupProps, 'onClose' | 'children'> {
	onClose: () => void;
	loading: boolean;
	data: SiteSettingsProvider_SiteFragment | null;
}

const validationSchema = yup.object({
	emraFeaturedTrainingType: yup
		.string()
		.nullable()
		.oneOf(Object.values(EMRATypes))
		.label('Featured program type'),
});

const SettingsModal = ({
	onClose,
	loading,
	data,
	...props
}: SettingsModalProps) => {
	const wl = useWhitelabel();
	const [settingsModal_UpdateSite, { loading: loadingUpdate }] = useMutation(
		SettingsModal_UpdateSiteMDoc,
		{
			context: createRoleContext(Role.EMRA_ADMIN),
		},
	);

	const formik = useForm({
		initialValues: {
			emraFeaturedTrainingType: data?.emraFeaturedTrainingType,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, actions) => {
			try {
				await settingsModal_UpdateSite({
					variables: {
						emraFeaturedTrainingType: values.emraFeaturedTrainingType,
						platform: resolvePlatform(wl.name),
					},
				});
				actions.setSubmitting(false);
				onClose && onClose();
			} catch (e) {
				actions.setSubmitting(false);
				console.error(e);
				captureException(e, {
					extra: {
						values,
					},
				});
			}
		},
	});

	const handleClose = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Popup
			onClose={handleClose}
			title='Site Settings'
			{...props}
			actions={
				<>
					<Button
						variant='outlined'
						disabled={loading || loadingUpdate || formik.isSubmitting}
						onClick={handleClose}
						sx={{ mr: 'auto' }}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						disabled={loading || loadingUpdate || formik.isSubmitting}
						onClick={formik.submitForm}
					>
						Save
					</Button>
				</>
			}
		>
			<Box>
				<Typography variant='body1' gutterBottom>
					EMRA Featured Program Type
				</Typography>
				<Dropdown
					placeholder='choose a default tab'
					fullWidth
					disabled={formik.isSubmitting}
					options={Object.entries(EMRATYPESVERBOSE2TYPES).map(
						([label, value]) => ({
							label,
							value,
						}),
					)}
					onChange={(nv) =>
						formik.setFieldValue('emraFeaturedTrainingType', nv)
					}
					onBlur={() => formik.setFieldTouched('emraFeaturedTrainingType')}
					value={formik.values.emraFeaturedTrainingType || undefined}
					{...getErrorState(formik, 'emraFeaturedTrainingType')}
				/>
			</Box>
		</Popup>
	);
};

export default SettingsModal;
