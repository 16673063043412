import React, { useRef, useState, useCallback, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
	Box,
	Button,
	Typography,
	SvgIcon,
	useTheme,
	useMediaQuery,
	type SvgIconProps,
} from '@mui/material';

import FeaturedBadge from '@ivy/components/atoms/FeaturedBadge';
import { RunningToWorkIcon } from '@ivy/components/icons';
import Popup, { type PopupProps } from '@ivy/components/molecules/Popup';
import { useCurrentAccount } from '@ivy/gql/hooks';
import { gql } from '@ivy/gql/types';

const SearchInstructions_HideMDoc = gql(/* GraphQL */ `
	mutation SearchInstructions_Hide($accountId: uuid!) {
		update_current_account(
			where: { id: { _eq: $accountId } }
			_set: { hide_search_instructions: true }
		) {
			returning {
				id
				hide_search_instructions
			}
		}
	}
`);

const BackdropBlob = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='463'
			height='362'
			viewBox='0 0 463 362'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.2'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M168.233 2.31579C224.38 -7.32571 264.218 31.1473 312.23 50.8191C362.683 71.4913 430.818 75.2665 451.772 118.49C474.503 165.379 460.875 226.881 418.621 270.279C379.391 310.572 311.069 307.975 252.562 323.096C194.621 338.07 135.257 375.229 83.3851 356.917C29.9493 338.053 15.3181 283.866 5.39074 235.837C-4.02465 190.286 0.145071 142.259 30.1287 99.2619C61.1479 54.7793 108.746 12.531 168.233 2.31579Z'
				fill='#1EC86A'
			/>
		</SvgIcon>
	);
};

const BackdropBlobMobile = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='793'
			height='928'
			viewBox='0 0 793 928'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				opacity='0.2'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M350.932 9.1153C482.945 -20.3865 636.933 22.4396 723.097 127.197C805.578 227.476 752.467 371.159 755.697 501.224C759.081 637.507 846.07 806.782 742.149 894.398C637.388 982.722 484.396 872.392 350.932 842.483C261.408 822.42 168.281 815.134 102.374 751.026C35.4488 685.928 -2.16652 594.8 0.0965646 501.224C2.30939 409.726 61.1287 337.677 114.17 263.277C184.087 165.206 233.749 35.3029 350.932 9.1153Z'
				fill='#1EC86A'
			/>
		</SvgIcon>
	);
};

const INSTRUCTION_STEPS = [
	{
		title: 'Getting Started',
		description:
			"Select the facilities you're interested in working at. When you apply to an employer, you'll be able to send an email with your CV to the recruiter at the selected EM practice.",
		desktopImage:
			'https://assets.ivyclinicians.io/gifs/search%3Aapply%3Afloating%20selection%20bar%3Aoptimized.gif',
		mobileImage:
			'https://assets.ivyclinicians.io/gifs/search%3Aapply%3Amobile%3Afloating%20selection%20bar.gif',
	},
	{
		title: 'Immediate Needs',
		description: (
			<>
				Facilities labeled{' '}
				<Box
					sx={{
						display: 'inline-block',
					}}
				>
					<FeaturedBadge
						icon={<RunningToWorkIcon style={{ fontSize: '1em' }} />}
						label='Immediate Needs'
					/>
				</Box>{' '}
				are hiring right now!
				<br />
				<br />
				Other facilities often have openings that aren't listed. Try applying to
				all the employers in an area to see who's hiring. They'll often keep
				your CV on file for future openings.
			</>
		),
		desktopImage:
			'https://assets.ivyclinicians.io/gifs/search%3Aimmediate%3Acropped.gif',
		mobileImage:
			'https://assets.ivyclinicians.io/gifs/search%3Aimmediate%3Amobile%3Acropped.gif',
	},
];

export const HIDE_SEARCH_INSTRUCTIONS_KEY = '_ivy_hide_search_instructions';

const SearchInstructions = ({ open, onClose, ...props }: PopupProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const currAcc = useCurrentAccount();
	const [hideSearchInstructions, { loading, error }] = useMutation(
		SearchInstructions_HideMDoc,
	);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
		noSsr: true,
	});
	const [stepIdx, setStepIdx] = useState(0);
	const step = INSTRUCTION_STEPS[stepIdx];

	const handleNext = useCallback(() => {
		containerRef.current?.scrollTo({ top: 0 });
		setStepIdx((prev) => Math.min(prev + 1, INSTRUCTION_STEPS.length - 1));
	}, [containerRef, setStepIdx]);

	const handleSubmit = useCallback(async () => {
		localStorage.setItem(HIDE_SEARCH_INSTRUCTIONS_KEY, 'true');
		onClose && onClose();
	}, [onClose]);

	useEffect(() => {
		// Record that the instructions have been viewed on the account object for two cases
		// (1) The user already has an account
		// (2) An anonymous user searches, hides this pop up, and then signs up
		if (
			!open &&
			currAcc &&
			!currAcc.hideSearchInstructions &&
			localStorage.getItem(HIDE_SEARCH_INSTRUCTIONS_KEY) === 'true'
		) {
			hideSearchInstructions({
				variables: {
					accountId: currAcc.id,
				},
			});
		}
	}, [open, currAcc, hideSearchInstructions]);

	return (
		<Popup
			disableCloseButton
			onClose={onClose}
			open={open}
			shrink
			actions={
				<Button
					size='large'
					fullWidth
					variant='contained'
					onClick={
						stepIdx < INSTRUCTION_STEPS.length - 1 ? handleNext : handleSubmit
					}
					disabled={loading}
				>
					{stepIdx < INSTRUCTION_STEPS.length - 1 ? 'Next' : 'Got it!'}
				</Button>
			}
			contentRef={containerRef}
			{...props}
		>
			<Typography variant='h4' component='h1' mb={2} align='center'>
				{step.title}
			</Typography>
			<Typography component='div' variant='body1'>
				{step.description}
			</Typography>
			<Box display='flex' justifyContent='center' pb={2}>
				<Box
					position='relative'
					sx={{
						position: 'relative',
						mt: isMobile ? 3 : 5,
						maxWidth: isMobile ? '300px' : 'none',
					}}
				>
					{isMobile ? (
						<BackdropBlobMobile
							sx={{
								width: '100%',
								height: 'auto',
							}}
						/>
					) : (
						<BackdropBlob
							sx={{
								width: '463px',
								maxWidth: '100%',
								height: 'auto',
							}}
						/>
					)}
					<Box
						position='absolute'
						top={isMobile ? '7.5%' : '22.5%'}
						display='flex'
						justifyContent='center'
					>
						{INSTRUCTION_STEPS.map((el, idx) => (
							<Box
								key={idx}
								component='img'
								src={isMobile ? el.mobileImage : el.desktopImage}
								sx={{
									maxWidth: isMobile ? '90%' : '100%',
									maxHeight: '100%',
									width: isMobile ? 250 : undefined,
									filter:
										'drop-shadow(0px 13.2717px 33.1793px rgba(142, 149, 155, 0.3))',
									borderRadius: `${theme.shape.borderRadius}px`,
									zIndex: 1,
									// Preload all gifs for faster loading speed when switched
									display: idx !== stepIdx ? 'none' : undefined,
								}}
							/>
						))}
					</Box>
				</Box>
			</Box>
			<Typography
				variant='caption'
				component='p'
				mt={2}
				color='error.main'
				display={!error ? 'none' : undefined}
				align='center'
			>
				An error occurred, please try again.
			</Typography>
		</Popup>
	);
};

export default SearchInstructions;
