import React from 'react';

import { useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
	Box,
	TextField,
	Typography,
	Stack,
	type SxProps,
	type Theme,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { type FormikProps } from 'formik-othebi';

import SupportLink from '@ivy/components/atoms/SupportLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import { useCurrentOrgId } from '@ivy/gql/hooks';
import { gql, getFragmentData } from '@ivy/gql/types';
import { type FacilityRecruiterForm_RecruiterFragment } from '@ivy/gql/types/graphql';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

const FacilityRecruiterForm_RecruiterFDoc = gql(/* GraphQL */ `
	fragment FacilityRecruiterForm_Recruiter on org_user {
		id
		account {
			id
			pi: personal_info {
				id
				firstName: first_name
				lastName: last_name
				fullName: full_name
			}
		}
	}
`);

const FacilityRecruiterForm_OrgUsersFDoc = gql(/* GraphQL */ `
	query FacilityRecruiterForm_OrgUsers($orgId: uuid!) {
		orgUsers: org_user(
			where: {
				org_id: { _eq: $orgId }
				account: { access_info: { active: { _eq: true } } }
			}
			order_by: { account: { personal_info: { first_name: asc } } }
		) {
			...FacilityRecruiterForm_Recruiter
		}
	}
`);

export interface FacilityRecruiterFormValues {
	addNewRecruiter: boolean;
	recruiter: FacilityRecruiterForm_RecruiterFragment | null;
	firstName: string;
	lastName: string;
	email: string;
}

export interface FacilityRecruiterFormProps {
	formik: FormikProps<FacilityRecruiterFormValues>;
	sx?: SxProps<Theme>;
	plural?: boolean;
	defaultRecruiterName?: string | null;
}

const FacilityRecruiterForm = ({
	formik,
	sx,
	plural = false,
	defaultRecruiterName,
}: FacilityRecruiterFormProps) => {
	const currentOrgId = useCurrentOrgId();
	// TODO: convert to async search
	const { data, loading, error } = useQuery(
		FacilityRecruiterForm_OrgUsersFDoc,
		{
			variables: {
				orgId: currentOrgId,
			},
		},
	);
	const choices: readonly ('new' | FacilityRecruiterForm_RecruiterFragment)[] =
		data
			? getFragmentData(FacilityRecruiterForm_RecruiterFDoc, data.orgUsers)
			: [];

	return (
		<Box sx={sx}>
			<DataLoader
				data={data}
				loading={loading}
				error={error}
				variant='circular'
			>
				{() => (
					<Stack spacing={1}>
						<Typography variant='body1' gutterBottom>
							Select the point of contact that will receive emails from
							interested clinicians for{' '}
							{plural ? 'these facilities' : 'this facility'}.
						</Typography>
						<Autocomplete
							openOnFocus
							selectOnFocus
							clearOnBlur
							autoHighlight
							fullWidth
							// TODO: hide clearable but still allow null
							// disableClearable
							renderInput={({ ...params }) => (
								<TextField
									{...params}
									fullWidth
									label='Recruiter'
									{...getErrorState(formik, 'recruiter')}
									required
								/>
							)}
							filterOptions={(options, params) => {
								let filtered = createFilterOptions<(typeof choices)[0]>({
									stringify: (el) =>
										el === 'new' ? el : el.account.pi?.fullName || '',
								})(options, params).filter((el) => el !== 'new');
								// Put 'new' at the beginning
								filtered = ['new', ...filtered];
								return filtered;
							}}
							getOptionLabel={(option) =>
								option === 'new'
									? 'Add New Colleague'
									: option.account.pi?.fullName || ''
							}
							renderOption={(params, option) =>
								option === 'new' ? (
									<Box component='li' {...params} key={'recruiter-select-new'}>
										<Typography variant='body1' fontWeight='bold'>
											<Add fontSize='small' sx={{ verticalAlign: 'bottom' }} />{' '}
											Add new colleague
										</Typography>
									</Box>
								) : (
									// Override key to avoid dup names
									<Box
										component='li'
										{...params}
										key={`recruiter-select-${option.account.id}`}
									>
										<Typography variant='body1'>
											{option.account.pi?.fullName || ''}
										</Typography>
									</Box>
								)
							}
							options={choices}
							// Find in choices since, for initial value, facility query won't have the last name, only the first name + id
							value={
								formik.values.addNewRecruiter ? 'new' : formik.values.recruiter
							}
							onChange={(_, newVal) => {
								if (newVal === 'new') {
									formik.setValues(
										{
											...formik.values,
											addNewRecruiter: true,
											recruiter: null,
										},
										false,
									);
								} else {
									formik.setValues(
										{
											...formik.values,
											addNewRecruiter: false,
											recruiter: newVal,
										},
										false,
									);
								}
								formik.setTouched({
									...formik.touched,
									addNewRecruiter: true,
									recruiter: true,
								});
							}}
							onBlur={() =>
								formik.setTouched({
									...formik.touched,
									addNewRecruiter: true,
									recruiter: true,
								})
							}
							disabled={formik.isSubmitting || defaultRecruiterName != null}
							isOptionEqualToValue={(option, value) => {
								if (option === value) {
									return true;
								}
								if (option === 'new' || value === 'new') {
									return false;
								}
								return option?.id === value?.id;
							}}
							sx={{
								mt: 1.5,
								mb: 1.5,
							}}
						/>
						{defaultRecruiterName != null && (
							// Explicit comparison to null/undefined to avoid empty strings
							<Typography variant='caption'>
								All applications for your organization are configured to go to{' '}
								<b>{defaultRecruiterName}</b>. To change this,{' '}
								<SupportLink>contact support</SupportLink>.
							</Typography>
						)}
						{formik.values.addNewRecruiter && (
							<>
								<TextField
									name='email'
									label='Email'
									type='email'
									value={formik.values.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={formik.isSubmitting}
									helperText={formik.touched.email && formik.errors.email}
									error={formik.touched.email && !!formik.errors.email}
									required
									fullWidth
								/>
								<TextField
									name='firstName'
									label='First Name'
									value={formik.values.firstName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={formik.isSubmitting}
									helperText={
										formik.touched.firstName && formik.errors.firstName
									}
									error={formik.touched.firstName && !!formik.errors.firstName}
									required
									fullWidth
								/>
								<TextField
									name='lastName'
									label='Last Name'
									value={formik.values.lastName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									disabled={formik.isSubmitting}
									helperText={formik.touched.lastName && formik.errors.lastName}
									error={formik.touched.lastName && !!formik.errors.lastName}
									required
									fullWidth
								/>
							</>
						)}
					</Stack>
				)}
			</DataLoader>
		</Box>
	);
};

export default FacilityRecruiterForm;
