import React from 'react';

import { useQuery, gql } from '@apollo/client';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { parseJSON, format as formatDate } from 'date-fns';
import { useParams } from 'react-router-dom';

import RouteLink from '@ivy/components/atoms/RouteLink';
import DataLoader from '@ivy/components/molecules/DataLoader';
import LandingCTA from '@ivy/components/organisms/LandingCTA';
import NavTemplate from '@ivy/components/templates/NavTemplate';
import { combineSx } from '@ivy/lib/styling/sx';

const VIEW_BLOG_POST = gql`
	query viewBlogPost($slug: String!) {
		post(where: { slug: $slug }) {
			id
			title
			slug
			actor
			createdAt
			publishedBy {
				name
			}
			content {
				html
			}
			picture {
				url(transformation: { image: { resize: { width: 768, fit: clip } } })
			}
			objectPosition
		}
	}
`;

const Introduction = ({ sx, post }) => {
	return (
		<Box sx={sx}>
			<Box>
				<Typography
					component='p'
					variant='h5'
					color='secondary.main'
					textTransform='uppercase'
					gutterBottom
				>
					{post.actor}
				</Typography>
				<Typography
					component='h1'
					variant='h3'
					color='primary.main'
					gutterBottom
				>
					{post.title}
				</Typography>
				<Typography variant='body1'>{post.publishedBy.name}</Typography>
				<Typography variant='body2' color='text.secondary'>
					{formatDate(parseJSON(post.createdAt), 'MMM d, yyyy')}
				</Typography>
			</Box>
			<Box
				component='img'
				src={post.picture.url}
				width='100%'
				sx={{
					mt: 5,
					objectPosition: post.objectPosition || 'center',
					borderRadius: (theme) => `${theme.shape.borderRadius}px`,
				}}
			/>
		</Box>
	);
};

// TODO: convert to a theme and use ThemeProvider
// Copied from Substack
const blogBodyTypography = (theme) => ({
	fontSize: '18px',
	lineHeight: '28.8px',
	fontWeight: 400,
	[theme.breakpoints.up('md')]: {
		fontSize: '20px',
		lineHeight: '32px',
	},
});

const blogTypography = (theme) => ({
	...blogBodyTypography(theme),
	'& h1': {
		fontSize: '36px',
		lineHeight: '41.76px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '40px',
			lineHeight: '46.4px',
		},
	},
	'& h2': {
		fontSize: '29.25px',
		lineHeight: '33.93px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '32.5px',
			lineHeight: '37.7px',
		},
	},
	'& h3': {
		fontSize: '24.75px',
		lineHeight: '28.71px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '27.5px',
			lineHeight: '31.9px',
		},
	},
	'& h4': {
		fontSize: '20.25px',
		lineHeight: '23.49px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '22.5px',
			lineHeight: '26.1px',
		},
	},
	'& h5': {
		fontSize: '14.94px',
		lineHeight: '17.3304px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '16.6px',
			lineHeight: '19.256px',
		},
	},
	'& h6': {
		fontSize: '12.06px',
		lineHeight: '13.9896px',
		fontWeight: 600,
		[theme.breakpoints.up('md')]: {
			fontSize: '13.4px',
			lineHeight: '15.544px',
		},
	},
	'& p': blogBodyTypography(theme),
	'& ul': blogBodyTypography(theme),
	'& ol': blogBodyTypography(theme),
	'& li': blogBodyTypography(theme),
});

const Content = ({ sx, post }) => {
	const theme = useTheme();
	return (
		<Box
			dangerouslySetInnerHTML={{
				__html: post.content.html,
			}}
			sx={combineSx(
				{
					// For long URLs that cause problems on mobile
					wordBreak: 'break-word',
					'& img': {
						// If the image is too small, center it
						display: 'block',
						mx: 'auto',
						// If the image is too large, shrink it
						maxWidth: '100%',
						height: 'auto',
					},
					...blogTypography(theme),
				},
				sx,
			)}
		/>
	);
};

const BlogShow = () => {
	const { blogSlug } = useParams();
	const { data, loading, error } = useQuery(VIEW_BLOG_POST, {
		variables: {
			slug: blogSlug,
		},
	});

	return (
		<NavTemplate
			maxWidth='md'
			defaultBackNav={{
				target: 'blog',
				link: RouteLink.routes.BLOG_LIST,
			}}
			pageTitle={
				data
					? data.post.actor
						? `${data.post.title} | ${data.post.actor}`
						: data.post.title
					: 'View Blog Post'
			}
			pageDescription='Get the latest emergency medicine news and career tips. Read this blog post and others on Ivy Clinicians.'
			showFooter
			showSupportLauncher
			pathIsCanonical
		>
			<DataLoader
				variant='logo'
				fullscreen
				data={data}
				loading={loading}
				error={error}
			>
				{() => (
					<Box
						sx={{
							mb: 5,
						}}
					>
						<Stack
							component='article'
							sx={{
								mt: {
									xs: 1,
									mobileMenu: 0,
								},
								mb: 10,
							}}
							spacing={3}
						>
							<Box>
								<Introduction
									post={data.post}
									sx={{
										mt: 3,
									}}
								/>
							</Box>
							<Content post={data.post} />
						</Stack>
						<LandingCTA />
					</Box>
				)}
			</DataLoader>
		</NavTemplate>
	);
};

export default BlogShow;
