import React from 'react';

import { Box, Typography } from '@mui/material';

import EmptyIcon from '@ivy/components/icons/EmptyIcon';

interface EmptyListPlaceholderProps {
	type: string;
	filtering?: boolean;
	emptyText?: string;
}

const EmptyListPlaceholder = ({
	type,
	filtering,
	emptyText,
}: EmptyListPlaceholderProps) => {
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<EmptyIcon
				sx={{
					fontSize: '120px',
				}}
			/>
			<Typography variant='body2' color='text.icon'>
				{emptyText || (filtering ? 'No matches found' : `No ${type} yet!`)}
			</Typography>
		</Box>
	);
};

export default EmptyListPlaceholder;
