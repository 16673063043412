import React from 'react';

import LegalDocument from '@ivy/components/organisms/LegalDocument';
import SpotlightTemplate from '@ivy/components/templates/SpotlightTemplate';

const PrivacyPolicy = () => {
	return (
		<SpotlightTemplate
			pageTitle='Privacy Policy'
			pageDescription='Privacy policy for the Ivy Clinicians platform.'
			pageNoIndex
		>
			<LegalDocument title='Privacy Policy' />
		</SpotlightTemplate>
	);
};

export default PrivacyPolicy;
