import React, { useCallback, useEffect, useState } from 'react';

import { Crisp } from 'crisp-sdk-web';

import { useCurrentAccount } from '@ivy/gql/hooks';

import CrispContext from './CrispContext';

export interface CrispProviderProps {
	appReady: boolean;
	crispWebsiteId: string;
	disabled?: boolean;
	children?: React.ReactNode;
}

const CrispProvider = ({
	appReady,
	crispWebsiteId,
	disabled = false,
	children,
}: CrispProviderProps) => {
	const currAcc = useCurrentAccount();
	const [init, setInit] = useState(false);
	const [showLauncher, setShowLauncher] = useState(false);

	useEffect(() => {
		if (disabled || !appReady || !crispWebsiteId || init) {
			return;
		}

		Crisp.configure(crispWebsiteId, {
			safeMode: true,
		});
		setInit(true);
	}, [disabled, appReady, crispWebsiteId, init, setInit]);

	useEffect(() => {
		if (!init || !currAcc) {
			return;
		}
		Crisp.user.setEmail(currAcc!.ci!.email!);
		Crisp.user.setNickname(currAcc!.pi!.fullName!);
		if (currAcc.picture?.publicUrl) {
			Crisp.user.setAvatar(currAcc.picture.publicUrl);
		}
		const customData: {
			account_id: string;
			account_type: string;
			org_id?: string;
		} = {
			account_id: currAcc.id,
			account_type: currAcc.type,
		};
		if (currAcc.isOrgUser) {
			Crisp.user.setCompany(currAcc.orgUser!.org!.name, {});
			customData.org_id = currAcc.orgUser!.org!.id;
		}
		Crisp.session.setData(customData);
	}, [init, currAcc]);

	useEffect(() => {
		if (!init) {
			return;
		}
		if (showLauncher) {
			Crisp.chat.show();
		} else {
			Crisp.chat.hide();

			// This logic makes it so that, even if the chat is hidden but we request it
			// to be open, it will show itself and then, afterwards, hide itself again.
			// Useful if we're hidden on mobile or on a page where the launcher isn't shown but is
			// available from the menu or footer
			Crisp.chat.onChatOpened(() => {
				Crisp.chat.show();
			});
			Crisp.chat.onChatClose(() => {
				Crisp.chat.hide();
			});
			return () => {
				Crisp.chat.onChatOpened(() => {});
				Crisp.chat.onChatClose(() => {});
			};
		}
	}, [init, showLauncher]);

	const handleOpenLauncher = useCallback(
		(ev: React.MouseEvent) => {
			ev.stopPropagation();
			// Don't open up the mailto link if Javascript is working
			ev.preventDefault();
			if (init) {
				Crisp.chat.open();
			}
		},
		[init],
	);

	return (
		<CrispContext.Provider
			value={{
				handleOpenLauncher,
				setShowLauncher,
			}}
		>
			{children}
		</CrispContext.Provider>
	);
};

export default CrispProvider;
