import React from 'react';

import { SvgIcon, type SvgIconProps } from '@mui/material';

const SignIcon = ({ ...props }: SvgIconProps) => {
	return (
		<SvgIcon
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect opacity='0.6' width='24' height='24' fill='url(#pattern0)' />
			<defs>
				<pattern
					id='pattern0'
					patternContentUnits='objectBoundingBox'
					width='1'
					height='1'
				>
					<use xlinkHref='#image0_1166_193368' transform='scale(0.0104167)' />
				</pattern>
				<image
					id='image0_1166_193368'
					width='96'
					height='96'
					xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAEyklEQVR4nO2dT4hVVRzHP73nn0yNkVCjzEmbTElqEVNCBhJBm8DAINqUi0KEUNykCNpQLYRqUVDQIjRyZYsgAtu4MKqVUkE0UQ2aTotCGXMya8acFuc+eEzvnd85555z7nt3fh84m5l55/f7fX+/e/7de9+AoiiKoiiKoiiKoiiKMle4oWoHSjIPuA1YDQwCdwCrgBXAMmCgrd0ENIEbK/G0C/OqdsCRW4GNwL1F2wCsKX7e9OhnOr5r5ejFBCwEhoGHgE1FWxWp70akfqLRKwlYCzxWtMeBmxPZ6bkEVMl9wCHgLDCTsfX7vFeKW4B9wHfkFb29+cwZtWEQeAv4k+qEb7X5iWPtKdYARzGrj6qFb7WFSSP2JNV4uAg4AOwh/bp7AjgPnCvaeWC8+HmrXQKuAJcT++JNigRsAo4A90Tudwz4BvgeM4eMAmcww5pSsAuYovwwMQWcBF4FngCW5wyiH2kA71FO9HHgHWArsDSv+/1NA/iQMNEngPeBR9FNUjBv4i/8GLAbc0CmlGA7fsKPAtvQao/CIPAHbsL/BYzQY2vwfucT3MQ/BdxZkY+xeQr4FpgEvgIeqcqRB4HryOJ/RH3G+RH+H9+/wHNVOONS/e9Sn5PHTuK3J+HZnM4sR95snaA+E61N/EqS8ILgzD+YQ7g64CJ+9iQcFhw5nMOJDPiIH5yEkGFiWPj9sYA+60IDU4BJr4QJ7FVQp4OzkKsg6XDULDrvZnia+qx8WpRJQvR9wgLB6BT1SwCEJ+GLFM5cFYwOpDDaA4Qk4VIKR84JRtelMJqQ1R5/65uE01E9LfhMMLozhdFEjGBuaW7x+Mxe3BOwLaazLV4TjH6ewmgC2qt5Etgc+NlubSSms+087GD8mVTGI9FJwJhXwssxnZ1NE/Pohy0BF4G7UzpRAlv1xrgSklV+Owc6GJ7dfqD3VkQuQ0eZJGQRH4ywvyMHc5zeeRbTZwUTkoRs4rfYgVswh3I71oGQNbxvErLTxKxzpUCuYZ6Wq4rQXWxfJGEIs9uTAhmlmnezyogfujrKzpO43R/eldmvGOL3zZWwDzmIs+S7TRlT/L5JwhHkIHJcyinEb0+Cz9lRVhYDP2IP4I3EPqQUf4YKlpq+vIg9gJMJbacWP+nxQizWYQ9iPJHdOV/5LZZgD+RqApta+W3cjj2YK5HtqfizkI4nfoloS4edWSxAXgV9GsmWit8BF1H2ZrIz58TfiHkm1BbYdcq/J6Did6CBeVlBCu7jknZ0wu3CfuTgpoH7S9jQyu/CMG4vZr9ewoZWfhcWAT8jB3gGc04Ugla+hVeQA7xG+AmoVr6FIeRnRGeAg4H9a+ULuHw1wZeEPRGhlS+wEnninQTuCuhbK9+BnciBvhTQr4rvyDHsgY5hzoV8UPE9+Al7sLs9+1PxPbmMPeC1nv2lFL/vJ9xOSIduvsOPVr4nv2IP3PcteRXfkxPYg/8aeB5Yj9uDWCq+Jy5PwbXaKYf+VHxPVuJ2DDGDuUUpEUv8Wk643XgbN1F+c+hLKz+ApZgNlyTM3w59qfiBbAAuIAskfVGfil+C9cg74xVCHyp+SRZjbjl2+98AQ8LnVfxIDABPAx9g9gIXMWI9IHxOxVcURVEURVEURVEURVHmBP8B6cfGgrYh1fcAAAAASUVORK5CYII='
				/>
			</defs>
		</SvgIcon>
	);
};

export default SignIcon;
