import React from 'react';

import { Box, Chip, Stack, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { useTour } from '@ivy/components/providers/TourProvider';
import {
	StepActions,
	type StepStructure,
} from '@ivy/components/providers/TourProvider/MainTour';
import { StepPreActions } from '@ivy/components/providers/TourProvider/MainTour/TourStructure';
import { Profession, PROFESSION2SLUG } from '@ivy/constants/clinician';
import { buildInternalLink } from '@ivy/lib/util/route';

import StepTitle from '../StepTitle';

enum TourSignals {
	FACILITY_TAB_VISIBLE = 'facilityTabVisible',
}

const ONBOARDING_SVG =
	'https://assets.ivyclinicians.io/images/onboarding-start-illustration.svg';

const PlanStatusContent = () => {
	const redirect = useRedirect();
	const { stopTour } = useTour();
	return (
		<Typography
			variant='body2'
			textAlign='center'
			sx={{
				maxWidth: {
					md: '448px',
					sm: '340px',
					xs: '280px',
				},
			}}
		>
			If you're on one of our paid plans, you can create job postings to promote
			your openings. Job postings let clinicians know which of your sites are
			actively hiring and appear first in search results, as well as in searches
			for nearby areas.{' '}
			<Typography
				component='span'
				variant='inherit'
				fontWeight='medium'
				onClick={async () => {
					await redirect({
						pathname: RouteLink.routes.FOR_EMPLOYERS,
						hash: '#JobPostingPlan_Container',
					});
					stopTour(true);
				}}
				sx={(theme) => ({
					cursor: 'pointer',
					color: theme.palette.tertiary.main,
				})}
			>
				Check out our prices for job posts here.
			</Typography>
		</Typography>
	);
};

const steps: StepStructure[] = [
	{
		target: 'body',
		title: (
			<StepTitle
				title='Ready to see how it works?'
				subtitle='We put together a product tour to get you up an running in no time.'
				sx={{
					maxWidth: {
						md: '325px',
						sm: '300px',
						xs: '280px',
					},
				}}
			/>
		),
		content: (
			<Stack justifyContent='center' spacing={{ xs: 1, md: 2 }}>
				<Chip
					label='🕐 Takes around 2 minutes'
					sx={{
						bgcolor: '#e8f3f1',
						mx: 'auto',
					}}
				/>
				<Box
					component='img'
					src={ONBOARDING_SVG}
					sx={{
						maxHeight: '300px',
						height: '35vh',
					}}
				/>
			</Stack>
		),
		nextAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_HOME,
		},
		placement: 'center',
		disableBeacon: true,
	},
	{
		target: 'body',
		title: (
			<StepTitle
				title='Welcome to your dashboard 💁‍♀️'
				subtitle='Your go-to place to get an overview of the current situation and what’s new.'
				sx={{
					maxWidth: {
						md: '370px',
						sm: '320px',
						xs: '280px',
					},
				}}
			/>
		),
		content: null,
		placement: 'center',
		disableBeacon: true,
	},
	{
		target: '#SidebarDrawer_Drawer > .MuiPaper-root',
		title: (
			<StepTitle
				title='Everything you need in the navbar'
				subtitle='Everything you need is right here—use this menu to explore and navigate Ivy.'
			/>
		),
		content: null,
		placement: 'auto',
		disableBeacon: true,
	},
	{
		target: '#Dashboard_EmployerProfileTab',
		title: (
			<StepTitle
				title="We'll start by visiting your employer profile"
				subtitle='Click this button to be redirected to the employer profile page.'
			/>
		),
		content: null,
		nextAction: {
			type: StepActions.NAV,
			origin: buildInternalLink(RouteLink.routes.ORG_SHOW, {
				orgId: 'current',
			}),
			observe: true,
		},
		placement: 'auto',
		disableBeacon: true,
	},
	{
		target: '#OrgShow_EditProfileBtn',
		title: (
			<StepTitle
				title='Start by editing you profile'
				subtitle='Your employer profile is how clinicians see your group on Ivy. Update your logo and description to showcase your organization and attract top talent.'
				chip='Free feature'
			/>
		),
		content: null,
		prevAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_HOME,
			observe: true,
		},
		placement: 'auto',
		disableBeacon: true,
	},
	{
		target: '#OrgShow_FacilitiesJobsTab',
		title: (
			<StepTitle
				title='Facilities & Jobs'
				subtitle='This tab showcases all of your facilities for clinicians to see.'
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.CLICK,
			observe: true,
			altTarget: '#OrgShow_FacilitiesJobsTab',
		},
		prevAction: {
			type: StepActions.CLICK,
			observe: true,
			altTarget: '#OrgShow_OverviewTab',
		},
		disableBeacon: true,
	},
	{
		target: '#OrgShow_ManageFacilitiesBtn',
		title: (
			<StepTitle
				title='Manage facilities'
				subtitle={
					<>
						Keep your list of facilities up-to-date on Ivy's{' '}
						<b>free employer directory</b> so that clinicians searching for jobs
						in your area can easily find you.
					</>
				}
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_FACILITY,
		},
		disableBeacon: true,
	},
	{
		target: 'body',
		title: (
			<StepTitle
				title='Add and manage your facilities'
				subtitle="Make sure your facilities and recruiters are accurate so that you don't miss out on any potential candidates. Even for sites that aren't actively hiring, you can still build a network of interested clinicians to get ahead of future vacancies."
				chip='Free feature'
			/>
		),
		prevAction: {
			type: StepActions.NAV,
			origin: buildInternalLink(RouteLink.routes.ORG_FACILITY_LIST, {
				profession: PROFESSION2SLUG[Profession.PHYSICIAN],
				orgId: 'current',
				slug: 'search',
			}),
		},
		placement: 'center',
		content: null,
		disableBeacon: true,
	},
	{
		target: 'table',
		signalTarget: 'tbody > tr:first-of-type',
		signal: TourSignals.FACILITY_TAB_VISIBLE,
		title: (
			<StepTitle
				title='Assign recruiters to your facilities'
				subtitle='Clinicians interested in opportunities at one of your facilities can email the recruiter assigned to that site. You can update the point of contact for each facility here.'
				chip='Free feature'
			/>
		),
		placement: 'auto',
		content: null,
		disableBeacon: true,
	},
	{
		target: '#Dashboard_JobPostingTab',
		title: (
			<StepTitle
				title='Manage your job postings'
				subtitle='Click this button to be redirected to the job postings page.'
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.NAV,
			origin: buildInternalLink(RouteLink.routes.JOB_POSTING_LIST_TAB, {
				tab: 'active',
			}),
		},
		disableBeacon: true,
	},
	{
		target: '#PlanStatusPanel_Container',
		title: (
			<StepTitle
				title='Upgrade to post jobs'
				chip='Paid feature'
				chipSx={{
					bgcolor: '#E0D4F3',
				}}
			/>
		),
		prevAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_FACILITY,
			observe: true,
		},
		placement: 'auto',
		content: <PlanStatusContent />,
		disableBeacon: true,
	},
];

const mobileSteps: StepStructure[] = [
	{
		target: 'body',
		title: (
			<StepTitle
				title='Ready to see how it works?'
				subtitle='We put together a product tour to get you up an running in no time.'
				sx={{
					maxWidth: {
						md: '325px',
						sm: '300px',
						xs: '280px',
					},
				}}
			/>
		),
		content: (
			<Stack justifyContent='center' spacing={{ xs: 1, md: 2 }}>
				<Chip
					label='🕐 Takes around 2 minutes'
					sx={{
						bgcolor: '#e8f3f1',
						mx: 'auto',
					}}
				/>
				<Box
					component='img'
					src={ONBOARDING_SVG}
					sx={{
						maxHeight: '300px',
						height: '25vh',
					}}
				/>
			</Stack>
		),
		nextAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_HOME,
		},
		placement: 'center',
		disableBeacon: true,
	},
	{
		target: 'body',
		title: (
			<StepTitle
				title='Welcome to your dashboard 💁‍♀️'
				subtitle='Your go-to place to get an overview of the current situation and what’s new.'
				sx={{
					maxWidth: {
						md: '370px',
						sm: '320px',
						xs: '280px',
					},
				}}
			/>
		),
		content: null,
		placement: 'center',
		disableBeacon: true,
	},
	{
		target: '#DashboardTemplate_MobileMenu',
		title: (
			<StepTitle
				title='Access the navbar to navigate the application'
				subtitle='Click here to access the navigation menu.'
			/>
		),
		content: null,
		nextAction: {
			type: StepActions.CLICK,
			observe: true,
		},
		placement: 'auto',
		disableBeacon: true,
	},
	{
		target: '#MobileMenu_Drawer > .MuiPaper-root',
		title: (
			<StepTitle
				title='Everything you need in the navbar'
				subtitle="Everything you need is right here—use this menu to explore and navigate Ivy. We'll start by visiting your employer profile"
			/>
		),
		content: null,
		nextAction: {
			type: StepActions.NAV,
			origin: buildInternalLink(RouteLink.routes.ORG_SHOW, {
				orgId: 'current',
			}),
			observe: true,
		},
		prevAction: {
			type: StepActions.CLICK,
			altTarget: '#MobileMenu_Drawer > .MuiBackdrop-root',
		},
		placement: 'center',
		disableBeacon: true,
	},
	{
		target: '#OrgShow_EditProfileBtn',
		title: (
			<StepTitle
				title='Start by editing you profile'
				subtitle='Your employer profile is how clinicians see your group on Ivy. Update your logo and description to showcase your organization and attract top talent.'
				chip='Free feature'
			/>
		),
		content: null,
		prevAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_HOME,
			jump: -2,
			observe: true,
		},
		placement: 'auto',
		disableBeacon: true,
	},
	{
		target: '#OrgShow_FacilitiesJobsTab',
		title: (
			<StepTitle
				title='Facilities & Jobs'
				subtitle='This tab showcases all of your facilities for clinicians to see.'
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.CLICK,
			observe: true,
			altTarget: '#OrgShow_FacilitiesJobsTab',
		},
		prevAction: {
			type: StepActions.CLICK,
			observe: true,
			altTarget: '#OrgShow_OverviewTab',
		},
		disableBeacon: true,
	},
	{
		target: '#OrgShow_ManageFacilitiesBtn',
		title: (
			<StepTitle
				title='Manage facilities'
				subtitle={
					<>
						Keep your list of facilities up-to-date on Ivy's{' '}
						<b>free employer directory</b> so that clinicians searching for jobs
						in your area can easily find you.
					</>
				}
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_FACILITY,
		},
		disableBeacon: true,
	},
	{
		target: 'body',
		title: (
			<StepTitle
				title='Add and manage your facilities'
				subtitle="Make sure your facility list is accurate so that you don't miss out on any potential candidates. Even for facilities without current openings, you can still build a network of interested clinicians to get ahead of future vacancies."
				chip='Free feature'
			/>
		),
		prevAction: {
			type: StepActions.NAV,
			origin: buildInternalLink(RouteLink.routes.ORG_FACILITY_LIST, {
				profession: PROFESSION2SLUG[Profession.PHYSICIAN],
				orgId: 'current',
				slug: 'search',
			}),
		},
		placement: 'center',
		content: null,
		disableBeacon: true,
	},
	{
		target: 'table',
		signalTarget: 'tbody > tr:first-of-type',
		signal: TourSignals.FACILITY_TAB_VISIBLE,
		title: (
			<StepTitle
				title='Assign recruiters to your facilities'
				subtitle='Clinicians interested in opportunities at one of your facilities can email the recruiter assigned to that site. You can update the point of contact for each facility here.'
				chip='Free feature'
			/>
		),
		nextAction: {
			type: StepActions.CLICK,
			observe: true,
			altTarget: '#DashboardTemplate_MobileMenu',
		},
		placement: 'auto',
		content: null,
		disableBeacon: true,
	},
	{
		target: '#MobileMenu_Drawer > .MuiPaper-root',
		title: (
			<StepTitle
				title='Visit the navbar'
				subtitle="To continue to other pages, let's visit the navbar."
			/>
		),
		placement: 'center',
		content: null,
		prevAction: {
			type: StepActions.CLICK,
			altTarget: '#MobileMenu_Drawer > .MuiBackdrop-root',
		},
		disableBeacon: true,
	},
	{
		target: '#Menu_JobPostingMenuItem',
		title: (
			<StepTitle
				title='Manage your job postings'
				subtitle='Click this button to be redirected to the job postings page'
			/>
		),
		placement: 'auto',
		content: null,
		nextAction: {
			type: StepActions.CLICK,
			altTarget: '#Menu_JobPostingMenuItem',
			observe: true,
		},
		disableBeacon: true,
	},
	{
		target: '#PlanStatusPanel_Container',
		title: (
			<StepTitle
				title='Upgrade to post jobs'
				chip='Paid feature'
				chipSx={{
					bgcolor: '#E0D4F3',
				}}
			/>
		),
		nextPreAction: {
			type: StepPreActions.CLICK,
			target: '#MobileMenu_Drawer > .MuiBackdrop-root',
		},
		prevAction: {
			type: StepActions.NAV,
			origin: RouteLink.routes.MANAGEMENT_FACILITY,
			jump: -2,
			observe: true,
			altTarget: '#DashboardTemplate_MobileMenu',
		},
		placement: 'auto',
		content: <PlanStatusContent />,
		disableBeacon: true,
	},
];

export { steps, mobileSteps };
