import CheckboxListSelect from '@ivy/components/molecules/CheckboxListSelect';
import { YNOptions } from '@ivy/components/molecules/YNOptions';
import {
	RESIDENCY_PROGRAM_OPTS,
	PRIMARY_TRAINING_SITE_OPTS,
	STEP_1_CUTOFF_OPTS,
	USMLE_COMLEX_1_OPTS,
	USMLE_COMLEX_2_OPTS,
	PERCENT_OSTEOPATH_OPTS,
	SHIFT_LENGTH_OPTS,
	SHIFTS_PER_MONTH_OPTS,
	VISA_SPONSORSHIP_OPTS,
	PGY1_PERCENT_OPTS,
	PERCENT_IMG_OPTS,
	AVAILABLE_INTERN_POSITIONS_OPTS,
	SECONDARY_TRAINING_SITE_OPTS,
	ELECTIVE_WEEKS_OPTS,
	CRITICAL_CARE_TRAINING_WEEKS_OPTS,
	MILITARY_BRANCH_OPTS,
	SLOE_FOR_INTERVIEW_OPTS,
	SPECIALTY_OPTS,
} from '@ivy/constants/filterOptions';

import {
	prepareBoundsFilters,
	prepareFilters,
	prepareSimpleFilter,
	prepareProgramEDVisitsFilters,
} from '../../filters/apiHelpers';
import SliderFilterOption from '../../filters/SliderFilterOption';
import { type FilterDataStructure } from '../../filters/useEntityFilters';
import VolumeFilterOptions from '../../filters/VolumeFilterOptions';

export const filters: FilterDataStructure[] = [
	{
		quickBtnId: 'btn-filters-specialties',
		key: 'specialties',
		title: 'Specialties',
		apiResolver: prepareFilters(
			'latest_survey.survey.specialty',
			SPECIALTY_OPTS,
			undefined,
			true,
		),
		options: SPECIALTY_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-programLength',
		key: 'programLength',
		title: 'Program Length',
		label: 'Program length',
		apiResolver: prepareFilters(
			'latest_survey.survey.program_length',
			RESIDENCY_PROGRAM_OPTS,
			Number.parseInt,
		),
		options: RESIDENCY_PROGRAM_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-primaryTrainingSite',
		key: 'primarySite',
		title: 'Primary Training Site',
		label: 'Primary training site',
		apiResolver: prepareFilters(
			'latest_survey.survey.primary_training_site',
			PRIMARY_TRAINING_SITE_OPTS,
		),
		options: PRIMARY_TRAINING_SITE_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-secondaryTrainingSite',
		key: 'secondarySite',
		title: 'Secondary Training Site',
		tooltipContent:
			'Does the program have an additional site where residents do more than 25% of their shifts?',
		label: 'Secondary training site',
		apiResolver: prepareFilters(
			'latest_survey.survey.secondary_training_site',
			SECONDARY_TRAINING_SITE_OPTS,
		),
		options: SECONDARY_TRAINING_SITE_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		quickBtnId: 'btn-filters-edvolume',
		key: 'volume',
		label: 'ED visits',
		title: 'Annual ED Visits',
		apiResolver: prepareProgramEDVisitsFilters,
		component: VolumeFilterOptions,
		initialFilterValue: [],
	},
	{
		key: 'step1CutOff',
		title: 'Step 1 Cut Off',
		tooltipContent:
			'What is the minimum USMLE Step 2 CK score needed to be granted an interview?',
		apiResolver: prepareFilters(
			'latest_survey.survey.step_1_cutoff',
			STEP_1_CUTOFF_OPTS,
		),
		options: STEP_1_CUTOFF_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'percentDO',
		title: 'Percent DO',
		tooltipContent:
			'What percentage of current residents completed medical school at an Osteopathic Medical School? OR What percentage of current residents attended an Osteopathic Medical School?',
		apiResolver: prepareFilters(
			'latest_survey.survey.percent_osteopath',
			PERCENT_OSTEOPATH_OPTS,
		),
		options: PERCENT_OSTEOPATH_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'shiftLength',
		title: 'Shift Length (Hours)',
		tooltipContent:
			'What is the most common shift length for which residents are scheduled?',
		apiResolver: prepareFilters(
			'latest_survey.survey.shift_length',
			SHIFT_LENGTH_OPTS,
		),
		options: SHIFT_LENGTH_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'shiftsPerMonth',
		title: 'Shifts Per Month',
		tooltipContent: 'How many shifts do PGY1 residents do per four week block?',
		apiResolver: prepareFilters(
			'latest_survey.survey.shifts_per_month',
			SHIFTS_PER_MONTH_OPTS,
		),
		options: SHIFTS_PER_MONTH_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'pgy1Percent',
		title: 'PGY1 Percent Off-Service',
		tooltipContent: 'What is the off-service percentage for PGY1 residents?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.pgy_1_percent_off_service',
			PGY1_PERCENT_OPTS,
			Number.parseInt,
		),
		options: PGY1_PERCENT_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
	{
		key: 'percentIMG',
		title: 'Percent IMG',
		tooltipContent:
			'What percentage of current residents completed medical school outside of the United States, not including spots funded by outside sources?',
		apiResolver: prepareFilters(
			'latest_survey.survey.percent_img',
			PERCENT_IMG_OPTS,
		),
		options: PERCENT_IMG_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'moonlighting',
		title: 'Moonlighting',
		tooltipContent:
			'Does the program allow moonlighting at any point during residency training?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.moonlighting'),
		component: YNOptions,
	},
	{
		key: 'internPositions',
		title: 'Available Intern Positions',
		tooltipContent: 'How many PGY1 spots are available?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.available_intern_positions',
			AVAILABLE_INTERN_POSITIONS_OPTS,
			Number.parseInt,
		),
		options: AVAILABLE_INTERN_POSITIONS_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
	{
		key: 'usmleStep1ComlexLevel1',
		title: 'USMLE Step 1 / COMLEX Level 1',
		tooltipContent: 'What is the USMLE/COMLEX 1 preference?',
		apiResolver: prepareFilters(
			'latest_survey.survey.usmle_comlex_1_preference',
			USMLE_COMLEX_1_OPTS,
		),
		options: USMLE_COMLEX_1_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'usmleStep2ComlexLevel2',
		title: 'USMLE Step 2 / COMLEX Level 2',
		tooltipContent: 'What is the USMLE/COMLEX 2 preference?',
		apiResolver: prepareFilters(
			'latest_survey.survey.usmle_comlex_2_preference',
			USMLE_COMLEX_2_OPTS,
		),
		options: USMLE_COMLEX_2_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'step1Failures',
		title: 'Step 1 Failures',
		tooltipContent:
			'Has an interview been granted to an applicant who failed USMLE STEP 1 or COMLEX LEVEL 1 in the last three years?',
		apiResolver: prepareSimpleFilter('latest_survey.survey.step_1_failures'),
		component: YNOptions,
	},
	{
		key: 'electiveWeeks',
		title: 'Elective Weeks',
		tooltipContent:
			'How many weeks of elective time do residents have during training?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.elective_weeks',
			ELECTIVE_WEEKS_OPTS,
			Number.parseInt,
		),
		options: ELECTIVE_WEEKS_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
	{
		key: 'criticalCare',
		title: 'Critical Care Training Weeks',
		tooltipContent:
			'How many weeks of designated critical care do residents experience during their training?',
		apiResolver: prepareBoundsFilters(
			'latest_survey.survey.critical_care_weeks',
			CRITICAL_CARE_TRAINING_WEEKS_OPTS,
			Number.parseInt,
		),
		options: CRITICAL_CARE_TRAINING_WEEKS_OPTS,
		component: SliderFilterOption,
		isSlider: true,
	},
	{
		key: 'militaryProgram',
		title: 'Military Program',
		tooltipContent: 'Does the program participate in the Military match?',
		apiResolver: prepareFilters(
			'latest_survey.survey.shifts_per_month',
			MILITARY_BRANCH_OPTS,
		),
		options: MILITARY_BRANCH_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'visaSponsorship',
		title: 'Visa Sponsorship',
		tooltipContent: 'What Visas are sponsored?',
		apiResolver: prepareFilters(
			'latest_survey.survey.visa_sponsorship',
			VISA_SPONSORSHIP_OPTS,
		),
		options: VISA_SPONSORSHIP_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'sloesInterview',
		title: 'SLOEs for Interview',
		tooltipContent: 'How many SLOEs are required for an interview offer?',
		apiResolver: prepareFilters(
			'latest_survey.survey.sloes_for_interview',
			SLOE_FOR_INTERVIEW_OPTS,
			Number.parseInt,
		),
		options: SLOE_FOR_INTERVIEW_OPTS,
		component: CheckboxListSelect,
		initialFilterValue: [],
	},
	{
		key: 'secondLook',
		title: 'In-Person Second Looks',
		tooltipContent: 'Will there be in-person second looks?',
		apiResolver: prepareSimpleFilter(
			'latest_survey.survey.in_person_second_looks',
		),
		component: YNOptions,
	},
	{
		key: 'APICinterviewRec',
		title: 'CORD’s APIC Interview Season Recommendations',
		tooltipContent:
			"Is the program following CORD's APIC interview season recommendations?",
		apiResolver: prepareSimpleFilter(
			'latest_survey.survey.cord_apic_interview',
		),
		component: YNOptions,
	},
	{
		key: 'emrafield',
		title: 'EMRAfied',
		tooltipContent: 'Does the program pay for EMRA dues?',
		apiResolver: prepareSimpleFilter('emrafied'),
		component: YNOptions,
	},
];
