import React, { useEffect, useMemo } from 'react';

import { Close } from '@mui/icons-material';
import {
	Paper,
	IconButton,
	type PaperProps,
	useTheme,
	useMediaQuery,
	type SxProps,
	type Theme,
} from '@mui/material';
import isEqual from 'lodash/isEqual';

import MapCard from '@ivy/components/organisms/MapCard';
import { useRedirect } from '@ivy/components/providers/RedirectProvider';
import { type Profession } from '@ivy/constants/clinician';
import { combineSx } from '@ivy/lib/styling/sx';

import useMapContext from './MapProvider/useMapContext';

export interface SearchPreviewProps extends PaperProps {
	sx?: SxProps<Theme>;
	entityId: string;
	profession: Profession;
	onClose: React.MouseEventHandler<HTMLButtonElement>;
	badgeIcon?: JSX.Element;
	badgeSxProps?: SxProps<Theme>;
}

const SearchPreview = React.memo(
	({
		sx,
		entityId,
		profession,
		onClose,
		badgeIcon,
		badgeSxProps,
		...props
	}: SearchPreviewProps) => {
		const redirect = useRedirect();
		const theme = useTheme();
		const openInNewTab = useMediaQuery(theme.breakpoints.up('md'), {
			noSsr: true,
		});

		const { dataPreviewResolver, setQueryPreviewOptions } = useMapContext();
		useEffect(() => {
			setQueryPreviewOptions({
				variables: {
					id: entityId,
					profession,
				},
			});
		}, [entityId, profession, setQueryPreviewOptions]);

		const rawData = useMemo(() => {
			return dataPreviewResolver();
		}, [dataPreviewResolver]);

		const handleClose: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
			// Don't click on the entity card
			ev.stopPropagation();
			onClose?.(ev);
		};

		if (!rawData) {
			return null;
		}

		// No need to show loading circular - the load will be quick from cache
		return (
			<Paper
				sx={combineSx(
					{
						position: 'relative',
					},
					sx,
				)}
				{...props}
			>
				<IconButton
					size='small'
					sx={{
						m: 0.5,
						position: 'absolute',
						top: 0,
						right: 0,
						zIndex: 1,
					}}
					onClick={handleClose}
				>
					<Close
						sx={{
							bgcolor: 'rgba(0, 0, 0, 0.7)',
							color: 'primary.contrastText',
							borderRadius: '50%',
						}}
					/>
				</IconButton>
				<MapCard
					entity={rawData}
					data-tr-entity-id={rawData.id}
					data-tr-featured={rawData.featured}
					sx={{
						width: '100%',
						height: {
							sm: '220px',
						},
					}}
					wagePosition='left'
					openInNewTab={openInNewTab}
					onClick={() => {
						if (!rawData.pathname) {
							return;
						}
						redirect(rawData.pathname, {
							openInNewTab,
							state: {
								backNav: {
									target: 'search',
								},
							},
						});
					}}
					badgeIcon={badgeIcon}
					badgeSxProps={badgeSxProps}
				/>
			</Paper>
		);
	},
	(prev, next) => isEqual(prev, next),
);

export default SearchPreview;
