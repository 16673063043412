import React from 'react';

import {
	Box,
	Button,
	Divider,
	SwipeableDrawer,
	type SwipeableDrawerProps,
	Stack,
	type SxProps,
	type Theme,
	CircularProgress,
} from '@mui/material';
import isEqual from 'lodash/isEqual';

import LoadingOverlay from '@ivy/components/molecules/LoadingOverlay';

import { type MapListItemObject } from './common';
import EntityResultsList, {
	type EntityResultsListProps,
} from './EntityResultsList';
import { type NearbyMapListItemObject } from './NearbyCarousel';

interface MobileSearchResultsProps<T, V>
	extends Omit<EntityResultsListProps<T, V>, 'sx'> {
	sx?: SxProps<Theme>;
	open: boolean;
	onOpen: SwipeableDrawerProps['onOpen'];
	onClose: SwipeableDrawerProps['onClose'];
	SwipeAreaProps?: SwipeableDrawerProps['SwipeAreaProps'];
	loading?: boolean;
}

const MobileSearchResults = <
	T extends MapListItemObject,
	V extends NearbyMapListItemObject,
>({
	sx,
	open,
	onOpen,
	onClose,
	SwipeAreaProps,
	loading,
	entities,
	...props
}: MobileSearchResultsProps<T, V>) => {
	const height = 73;
	return (
		<SwipeableDrawer
			anchor='bottom'
			open={open}
			onClose={onClose}
			onOpen={onOpen}
			swipeAreaWidth={height}
			disableSwipeToOpen={false}
			variant='temporary'
			// Note only touch enabled on mobile Safari, can't click inside swipe area - instead, you get a little jiggle to let
			// you know you should swipe up
			allowSwipeInChildren={true}
			SwipeAreaProps={SwipeAreaProps}
			PaperProps={{
				sx: {
					// Since overflow is visible here and not 'auto' or 'scroll', the scrolling needs to happen
					// in a nested div
					overflow: 'visible',
					height: `calc(90% - ${height}px)`,
				},
			}}
			sx={sx}
		>
			<Stack
				sx={{
					bgcolor: 'background.paper',
					position: 'absolute',
					top: -1 * height,
					borderTopLeftRadius: '24px',
					borderTopRightRadius: '24px',
					visibility: 'visible',
					right: 0,
					left: 0,
					height,
					boxShadow:
						'0px -5px 5px -2px rgb(0 0 0 / 10%), 0px -10px 10px 1px rgb(0 0 0 / 7%), 0px -16px 16px 1px rgb(0 0 0 / 6%)',
				}}
				alignItems='center'
			>
				<Box
					sx={{
						width: 40,
						height: 4,
						bgcolor: 'dark4.main',
						borderRadius: 3,
						mt: 1,
					}}
				/>
				<Button
					onClick={open ? onClose : onOpen}
					// endIcon={open ? <ExpandMore /> : <ExpandLess />}
					sx={{
						my: 1,
					}}
					size='large'
				>
					{open ? (
						'Show map'
					) : loading ? (
						<CircularProgress color='primary' size={25} />
					) : entities.length ? (
						`Show ${entities.length} ${
							entities.length === 1 ? 'result' : 'results'
						}`
					) : (
						// Opt: If we have nearby results, we could display the count of those instead
						'No results found'
					)}
				</Button>
			</Stack>
			<Divider />
			{open && (
				<LoadingOverlay
					loading={loading}
					sx={{
						// Move above FacilityCheckbox
						zIndex: 2,
					}}
				/>
			)}
			{open && (
				// Use conditional render for performance
				// Opt: This resets the nearby page to 0 on mobile, which may be annoying if something is selected
				// or potentially desired if nothing is selected yet.
				<EntityResultsList entities={entities} {...props} />
			)}
		</SwipeableDrawer>
	);
};

export default React.memo(MobileSearchResults, (prev, next) =>
	isEqual(prev, next),
) as typeof MobileSearchResults;
