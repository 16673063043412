import React from 'react';

import { SvgIcon } from '@mui/material';

const SelectAllIcon = ({ ...props }) => {
	return (
		<SvgIcon
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M1.66634 0.333344C0.929675 0.333344 0.333008 0.93001 0.333008 1.66668V9.66668C0.333008 10.4033 0.929675 11 1.66634 11H9.66634C10.403 11 10.9997 10.4033 10.9997 9.66668V1.66668C10.9997 0.93001 10.403 0.333344 9.66634 0.333344H1.66634ZM1.66634 1.66668H9.66634V9.66668H1.66634V1.66668ZM12.333 3.00001V12.3333H2.99967V13.6667H12.333C13.0697 13.6667 13.6663 13.07 13.6663 12.3333V3.00001H12.333ZM7.86165 3.19532L4.99967 6.0573L3.47103 4.52866L2.52832 5.47136L4.99967 7.94272L8.80436 4.13803L7.86165 3.19532Z'
				fill='currentColor'
			/>
		</SvgIcon>
	);
};

export default SelectAllIcon;
