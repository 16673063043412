/**
 * Options:
 *  1. Selecting from a list of values (client side search)
 *  	- Use `value` and `onChange`
 *  2. Selecting from a list of values (server side search)
 *  	- Use `inputValue`, `onInputChange` to control the search
 *  	- Use `value` and `onChange` to control selection
 *  	- Override `filterOptions` to get rid of MUI client side searching
 *  3. Freesolo with string options
 *  	- Use `inputValue` and `onInputChange` (not using the "Add <your input>" feature)
 *  		or
 *  	- Use `value` and `onChange` (using the "Add <your input>" feature)
 *  4. Freesolo with complex objects as the options
 *  	- Use `inputValue` and `onInputChange` to control the search but
 *  	  not to select the final value
 *  	- Use `value` and `onChange` to handle the selection, whether it's
 *  	  a complex object or simple string provided by the user.
 *  	- Manually provide generic types
 *  	- Value will be either a complex object or freesolo string. It's form will
 *        end up looking like `value={selectedObject ?? userInputString}`
 *  	- Do not forget to pass `value`. See `AddressAutocomplete` for an example of a bug
 *  	  that can occur if omitted.
 *
 *  tldr; ensure controlled inputs by providing both a value and on change handler
 */
import React from 'react';

import {
	createFilterOptions,
	Autocomplete as MuiAutocomplete,
	TextField,
	type AutocompleteProps as MuiAutocompleteProps,
	type ChipTypeMap,
	type TextFieldProps,
	type AutocompleteValue,
	type AutocompleteChangeDetails,
	type AutocompleteOwnerState,
} from '@mui/material';

export interface AutocompleteProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
	ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
		MuiAutocompleteProps<
			T,
			Multiple,
			DisableClearable,
			FreeSolo,
			ChipComponent
		>,
		'renderInput'
	> {
	renderInput?: MuiAutocompleteProps<
		T,
		Multiple,
		DisableClearable,
		FreeSolo,
		ChipComponent
	>['renderInput'];
	TextFieldProps?: TextFieldProps;
	alwaysShowAddOption?: boolean;
}

class AddOption {
	label: string;

	constructor(label: string) {
		this.label = label;
	}
}

/**
 * Extends MaterialUI's Autocomplete component with better freeSolo capabilities, including an
 * "Add <inputValue>" option.
 */
const Autocomplete = <
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined,
>({
	freeSolo,
	TextFieldProps,
	renderInput = (params) => <TextField {...params} {...TextFieldProps} />,
	getOptionLabel = (option) =>
		option == null
			? ''
			: typeof option === 'string'
			? option
			: typeof option === 'object' && 'label' in option
			? `${option.label}`
			: `${option}`,
	renderOption = (props2, option) => (
		<li {...props2}>{getOptionLabel(option)}</li>
	),
	filterOptions = createFilterOptions(),
	renderTags,
	getOptionDisabled,
	groupBy,
	isOptionEqualToValue,
	onChange,
	onHighlightChange,
	alwaysShowAddOption = false,
	...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
	if (!freeSolo) {
		return (
			<MuiAutocomplete
				freeSolo={freeSolo}
				renderInput={renderInput}
				getOptionLabel={getOptionLabel}
				renderOption={renderOption}
				filterOptions={filterOptions}
				renderTags={renderTags}
				getOptionDisabled={getOptionDisabled}
				groupBy={groupBy}
				isOptionEqualToValue={isOptionEqualToValue}
				onChange={onChange}
				onHighlightChange={onHighlightChange}
				{...props}
			/>
		);
	}
	type TAdd = T | AddOption;
	return (
		<MuiAutocomplete<TAdd, Multiple, DisableClearable, FreeSolo>
			freeSolo={freeSolo}
			renderInput={renderInput}
			getOptionLabel={(option) => {
				if (option instanceof AddOption) {
					return option.label;
				}
				return getOptionLabel(option as T);
			}}
			renderOption={(args, option, state) => {
				if (option instanceof AddOption) {
					return <li {...args}>Add "{option.label}"</li>;
				}
				return renderOption(args, option as T, state);
			}}
			filterOptions={(options, params) => {
				const filtered = filterOptions(
					options.filter((el) => !(el instanceof AddOption)) as T[],
					params,
				);
				const { inputValue } = params;
				if (
					inputValue !== '' &&
					(alwaysShowAddOption ||
						!filtered.some(
							(option) =>
								getOptionLabel(option).toLowerCase() ===
								inputValue.toLowerCase(),
						))
				) {
					return [...filtered, new AddOption(inputValue)] as TAdd[];
				}
				return filtered;
			}}
			renderTags={
				renderTags
					? (value, getTagProps, ownerState, ...args) =>
							renderTags(
								value.filter((el) => !(el instanceof AddOption)) as T[],
								getTagProps,
								ownerState as AutocompleteOwnerState<
									T,
									Multiple,
									DisableClearable,
									FreeSolo
								>,
								...args,
							)
					: undefined
			}
			getOptionDisabled={
				getOptionDisabled
					? (option) =>
							option instanceof AddOption
								? false
								: getOptionDisabled(option as T)
					: undefined
			}
			groupBy={
				groupBy
					? (options) =>
							options instanceof AddOption
								? options.label
								: groupBy(options as T)
					: undefined
			}
			isOptionEqualToValue={
				isOptionEqualToValue
					? (option, value) => {
							if (option instanceof AddOption && value instanceof AddOption) {
								return true;
							} else if (
								option instanceof AddOption ||
								value instanceof AddOption
							) {
								return false;
							}
							return isOptionEqualToValue(option as T, value as T);
					  }
					: undefined
			}
			onChange={
				onChange
					? (event, value, reason, details) => {
							return onChange(
								event,
								(value instanceof AddOption
									? value.label
									: value) as AutocompleteValue<
									T,
									Multiple,
									DisableClearable,
									FreeSolo
								>,
								reason,
								details as AutocompleteChangeDetails<T> | undefined,
							);
					  }
					: undefined
			}
			onHighlightChange={
				onHighlightChange
					? (ev, option, reason) => {
							if (option instanceof AddOption) {
								return;
							}
							return onHighlightChange(ev, option as T, reason);
					  }
					: undefined
			}
			{...props}
		/>
	);
};

export default Autocomplete;
export { createFilterOptions };
