import React from 'react';

import { useParams } from 'react-router-dom';

import NavTemplate from '@ivy/components/templates/NavTemplate';
import { RouteUri } from '@ivy/constants/routes';
import { SitemapTitles } from '@ivy/constants/sitemap';
import { buildInternalLink } from '@ivy/lib/util/route';

import SitemapList from './SitemapList';

const SitemapShow = () => {
	const { category, page: rawPage } = useParams();
	const resolveTitle = () => {
		return category ? SitemapTitles[category] ?? '' : '';
	};

	const pathIsCanonicalRes = () => {
		if (rawPage === '1') {
			return buildInternalLink(RouteUri.SITEMAP_INDEX, {
				category: category!,
			});
		} else {
			return true;
		}
	};

	return (
		<NavTemplate
			showFooter
			pageTitle={resolveTitle()}
			pageDescription="View the facilites Ivy Clinicians has on it's platform"
			showSupportLauncher
			pathIsCanonical={pathIsCanonicalRes()}
		>
			<SitemapList />
		</NavTemplate>
	);
};

export default SitemapShow;
