import { useEffect, useState } from 'react';

import { init, setTag, setUser, BrowserTracing, Replay } from '@sentry/react';
import { withoutGraphQLFetch } from 'apollo-link-sentry';
import isPlainObject from 'lodash/isPlainObject';

import { useCurrentAccount } from '@ivy/gql/hooks';
import useWhitelabel from '@ivy/lib/whitelabel/useWhitelabel';

const integrations = [new BrowserTracing(), new Replay()];

const redactFields = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	obj: { [k: string]: any },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fields: string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	redactedValue?: any,
) => {
	return Object.entries(obj).reduce(
		(tot, [k, v]) => ({
			...tot,
			[k]: isPlainObject(v)
				? redactFields(v, fields, redactedValue)
				: fields.includes(k)
				? redactedValue
				: v,
		}),
		{},
	);
};

const useSentry = (appReady, sentryDsn, env, disabled = false) => {
	const [initialized, setInitialized] = useState(false);
	const currAcc = useCurrentAccount();
	const wl = useWhitelabel();

	useEffect(() => {
		if (disabled) {
			return;
		}
		init({
			dsn: sentryDsn,
			integrations,

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: env === 'production' ? 0.05 : 1.0,
			environment: env,
			replaysSessionSampleRate: env === 'production' ? 0 : 1.0,
			replaysOnErrorSampleRate: 1.0,
			beforeBreadcrumb: withoutGraphQLFetch((breadcrumb, _hint) => {
				// Redact sensitive fields
				if (
					breadcrumb.data?.variables &&
					typeof breadcrumb.data.variables === 'string' &&
					breadcrumb.data.variables.includes('password')
				) {
					try {
						const jsonVar = JSON.parse(breadcrumb.data.variables);
						breadcrumb.data.variables = JSON.stringify(
							redactFields(jsonVar, ['password']),
						);
					} catch (e) {
						// Do nothing
					}
				}
				return breadcrumb;
			}),
			beforeSend(event, _hint) {
				// Redact sensitive fields
				if (event.extra) {
					event.extra = redactFields(event.extra, ['password']);
				}
				return event;

				// Apollo returns errors as objects and not subclsses of Error, so we perform some additional logic to
				// capture them properly
				// https://github.com/getsentry/sentry-javascript/issues/2292
				// Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
				// Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
				// const excValue = event.exception?.values?.[0]?.value;
				// if (excValue && (excValue.startsWith('Non-Error exception captured') || excValue.startsWith('Object captured as exception'))) {
				// 	if (!event.extra?.__serialized__) {
				// 		return null;
				// 	}
				// 	const realErrMsg = (event.extra.__serialized__['error'] && event.extra.__serialized__['error']['message']) || event.extra.__serialized__['message'];
				// 	// this is a useless error message that masks the actual error.  Lets try to set it properly
				// 	event.exception!.values![0].value = realErrMsg;
				// 	event.message = realErrMsg;
				// }
				// return event;
			},
		});
		setInitialized(true);
	}, [disabled, setInitialized, sentryDsn, env]);

	useEffect(() => {
		if (!initialized) {
			return;
		}
		setTag('whitelabel', wl.name);
	}, [initialized, wl.name]);

	useEffect(() => {
		if (!appReady || !initialized || !currAcc?.id) {
			return;
		}
		setUser({
			id: currAcc.id,
			email: currAcc.ci?.email || undefined,
			full_name: currAcc.pi?.fullName,
			// Can expand in future with explicit role in org for org users
			role: currAcc.type,
			...(currAcc.isOrgUser
				? {
						org_id: currAcc.orgUser?.org.id,
						org_name: currAcc.orgUser?.org.name,
				  }
				: {}),
		});
	}, [
		currAcc?.id,
		currAcc?.ci?.email,
		currAcc?.pi?.fullName,
		currAcc?.type,
		currAcc?.isOrgUser,
		currAcc?.orgUser?.org.id,
		currAcc?.orgUser?.org.name,
		appReady,
		initialized,
	]);
};

export default useSentry;
