import { ApolloLink } from '@apollo/client';

import { getAccessToken } from '@ivy/lib/storage/token';

export const getAuthContext = ({ headers, ...other }) => {
	const accessToken = getAccessToken();
	if (accessToken) {
		return {
			headers: {
				...headers,
				Authorization: `Bearer ${accessToken}`,
			},
			...other,
		};
	}
	return {
		headers,
		...other,
	};
};

const authLink = new ApolloLink((operation, forward) => {
	operation.setContext(getAuthContext);
	return forward(operation);
});

export default authLink;
