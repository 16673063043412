import React from 'react';

import {
	Box,
	SvgIcon,
	type SxProps,
	type Theme,
	Typography,
	Stack,
	Button,
} from '@mui/material';

import { useCurrentAccount } from '@ivy/gql/hooks';
import { combineSx } from '@ivy/lib/styling/sx';

const supportImg =
	'https://assets.ivyclinicians.io/images/reviews_support_community_snippet.png';

const Spiral = ({ ...props }) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='189'
			height='58'
			viewBox='0 0 189 58'
			fill='none'
			{...props}
		>
			<path
				d='M0.872981 2.81515C-2.7305 18.7979 167.458 -6.68953 164.732 3.73354C162.006 14.1566 51.8441 28.5035 47.096 38.0645C42.3479 47.6254 189.067 23.858 187.729 32.3295C186.391 40.801 132.209 42.2827 127.102 50.7016C121.995 59.1205 188.852 59.3697 184.802 50.861'
				stroke='#6226C5'
				strokeWidth='1.07208'
				fill='none'
			/>
		</SvgIcon>
	);
};

interface SupportCommunityProps {
	onClick?: () => void;
	sx?: SxProps<Theme>;
}

const SupportCommunity = ({ onClick, sx }: SupportCommunityProps) => {
	const currAcc = useCurrentAccount();
	const isClinicianOrAnon = !currAcc || currAcc?.isClinician;
	const handleOnClick = () => {
		if (isClinicianOrAnon && onClick) onClick();
	};

	return (
		<Box
			component='section'
			sx={combineSx(
				{
					position: 'relative',
				},
				sx,
			)}
		>
			<Box
				sx={{
					// Full bleed
					width: '100vw',
					position: 'absolute',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					height: '100%',
					zIndex: -1,
					bgcolor: '#E0D4F3',
				}}
			/>
			<Stack
				sx={{
					py: 11,
					alignItems: 'center',
					width: '100%',
					height: '100%',
					overflow: 'hidden',
				}}
				direction={{ md: 'row', xs: 'column' }}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						height: { md: '100%', xs: '30%' },
						pb: { md: 5, xs: 11 },
						width: { md: 'auto', xs: '100%' },
						flexBasis: { md: '50%' },
						mr: { xl: 15, lg: 10, md: 5 },
						justifyContent: 'center',
					}}
				>
					<Box
						component='img'
						src={supportImg}
						sx={{
							position: 'absolute',
							bottom: { md: 'auto', xs: '-14%' },
							width: '100%',
							maxWidth: '730px',
						}}
					/>
				</Box>
				<Stack
					spacing={5}
					sx={{
						alignItems: 'flex-start',
						justifyContent: 'center',
						flex: 1,
						flexBasis: { md: '50%' },
					}}
				>
					<Typography
						variant='h2'
						textAlign={{ md: 'left', xs: 'center' }}
						sx={{ width: '100%' }}
					>
						Support your community{' '}
						<Box
							component='span'
							sx={{ display: { md: 'inline', xs: 'none' } }}
						>
							🫶
						</Box>
					</Typography>
					<Typography
						variant='body1'
						textAlign={{ md: 'left', xs: 'center' }}
						color='text.icon'
					>
						Reviewing your job allows you to share valuable insights and
						experiences, benefiting fellow healthcare professionals. Honest
						reviews create transparency and help clinicians make informed
						decisions about potential employers.
					</Typography>
					<Stack
						sx={{ width: '100%' }}
						alignItems={{ md: 'flex-start', xs: 'center' }}
					>
						{isClinicianOrAnon ? (
							<Box
								sx={{
									position: 'relative',
									display: 'flex',
									flexFlow: 'column nowrap',
								}}
							>
								<Button
									color='tertiary'
									variant='contained'
									sx={{
										fontSize: 'h6.fontSize',
										px: 5,
										py: 2,
									}}
									onClick={handleOnClick}
								>
									Add job review
								</Button>
								<Spiral
									sx={{
										positon: 'absolute',
										height: '57px',
										width: '100%',
										mt: 3,
									}}
								/>
							</Box>
						) : (
							<Typography
								variant='body1'
								textAlign={{ md: 'left', xs: 'center' }}
								color='text.icon'
							>
								* This service is avabile to clinicians only
							</Typography>
						)}
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};

export default SupportCommunity;
