import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import RouteLink from '@ivy/components/atoms/RouteLink';

const LandingCTA = ({ sx }) => {
	return (
		<Box
			component='section'
			display='flex'
			sx={[
				{
					// Full bleed
					width: '100vw',
					position: 'relative',
					left: '50%',
					right: '50%',
					ml: '-50vw',
					mr: '-50vw',
					flexDirection: {
						xs: 'column',
						md: 'row',
					},
				},
				sx,
			]}
		>
			<Box
				flexBasis={{
					xs: 'auto',
					md: '50%',
				}}
				bgcolor='secondary.light'
				height={{ xs: '250px', md: '300px' }}
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Typography component='p' variant='h5' color='#0e6d3d' gutterBottom>
					I'm a clinician
				</Typography>
				<Button
					size='large'
					variant='contained'
					color='secondary'
					component={RouteLink}
					to={RouteLink.routes.ROOT}
				>
					Connect me with jobs I'll love
				</Button>
			</Box>
			<Box
				flexBasis={{
					xs: 'auto',
					md: '50%',
				}}
				bgcolor='primary.subtle'
				height={{ xs: '250px', md: '300px' }}
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Typography
					component='p'
					variant='h5'
					color='primary.dark'
					gutterBottom
				>
					I'm an employer
				</Typography>
				<Button
					size='large'
					variant='contained'
					color='primary'
					component={RouteLink}
					to={RouteLink.routes.FOR_EMPLOYERS}
				>
					Start hiring
				</Button>
			</Box>
		</Box>
	);
};

export default LandingCTA;
