import React from 'react';

import { Box, Stack, Typography, FormHelperText } from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import * as yup from 'yup';

import { type FormStepProps } from '@ivy/lib/forms/formFormatHelpers';
import { getErrorState } from '@ivy/lib/forms/formikHelpers';

import { type CheckoutFormValues, type ExtraParams } from '../CheckoutForm';

interface CreateAccountStepValues {
	signup?: boolean;
}

const validation = yup.object({
	signup: yup
		.boolean()
		.required()
		.oneOf(
			[true],
			'You must create an account in order proceed with checkout.',
		),
});

const initialValues = {
	signup: true,
};

const CreateAccountStep = ({
	formik,
}: FormStepProps<CheckoutFormValues, ExtraParams>) => {
	const errorState = getErrorState(formik, 'signup');

	return (
		<Stack spacing={5} sx={{ maxWidth: { xs: 'auto', md: '800px' } }}>
			<Stack direction='column' spacing={3}>
				<Typography variant='h2'>Why should you create an account?</Typography>
				<Typography variant='body1'>
					Thousands of clinicians are using Ivy Clinicians to find their next
					job. Watch this video to learn how you can use Ivy to build your
					network and find your next hire.
				</Typography>
			</Stack>
			<Box
				sx={(theme) => ({
					width: '100%',
					maxWidth: '550px',
					border: `8px black solid`,
					borderRadius: `${theme.shape.borderRadius}px`,
				})}
			>
				<LiteYouTubeEmbed id='XVoT_wyB8MA' title='Onboarding' />
			</Box>
			{!!errorState.error && (
				<FormHelperText sx={{ mt: 'auto' }} error>
					{errorState.helperText}
				</FormHelperText>
			)}
		</Stack>
	);
};

export default CreateAccountStep;
export { type CreateAccountStepValues, validation, initialValues };
